import { compose } from "recompose"
import withPageBuilder from "@alkemy/pagebuilder/lib/hoc/withPageBuilder"
import withFields from "@alkemy/pagebuilder/lib/hoc/withFields"

import { BannerCarousel, withWrapper, withPadder } from "@alkemy/components"

export default compose(
  withPageBuilder({
    niceName: "Lista Banner",
  }),
  withFields([
    {
      prop: "wrapped",
      type: "boolean",
      label: "Contenuto Boxed",
    },
    {
      prop: "banners",
      type: "repeater",
      label: "Lista Banner",
      model: [
        {
          prop: "img",
          label: "Immagine",
        },
        {
          prop: "darkColor",
          type: "boolean",
          label: "Testo Scuro",
        },
        {
          prop: "title",
          label: "Titolo",
        },
        {
          prop: "paragraph",
          type: "WYSIWYG",
          label: "paragrafo",
        },
      ],
    },
  ]),
  withWrapper,
  withPadder
)(BannerCarousel)
