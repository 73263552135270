"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitle = exports.StyledTextWrapper = exports.StyledOffersFormStep = exports.StyledInputWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledOffersFormStep = exports.StyledOffersFormStep = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledOffersFormStep",
  componentId: "sc-1hbe3gl-0"
})(["\n  width: 95%;\n  margin: auto;\n  transition: all 0.6s linear 0s;\n  padding: 50px 25px;\n  border-radius: 35px;\n  box-shadow: 0 0 35px 0 ", ";\n"], function (props) {
  return props.theme.color.shadowBlue;
});
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-1hbe3gl-1"
})(["\n  margin-bottom: 12px;\n  color: ", ";\n  font-family: ", ";\n  font-size: 18px;\n  text-align: center;\n  @media (min-width: 1440px) {\n    font-size: 24px;\n    margin-bottom: 30px;\n  }\n"], function (props) {
  return props.theme.color.orange;
}, function (props) {
  return props.theme.font.medium;
});
var StyledTextWrapper = exports.StyledTextWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTextWrapper",
  componentId: "sc-1hbe3gl-2"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  text-align: center;\n  @media (min-width: 1440px) {\n    font-size: 18px;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.regular;
});
var StyledInputWrapper = exports.StyledInputWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledInputWrapper",
  componentId: "sc-1hbe3gl-3"
})([""]);