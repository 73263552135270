"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitle = exports.StyledText = exports.StyledNewsBlock = exports.StyledLinkWrapper = exports.StyledImage = exports.StyledIconWrapper = exports.StyledDate = exports.StyledContentWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledImage = exports.StyledImage = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledImage",
  componentId: "sc-pd0y6s-0"
})(["\n  width: 34.37%;\n  height: 100%;\n  float: left;\n\n  background-image: url(", ");\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n\n  @media (min-width: 768px) {\n    width: 100%;\n    height: 220px;\n    float: none;\n    border-radius: 15px;\n  }\n"], function (props) {
  return props.imgUrl + '?width=700&auto=format';
});
var StyledContentWrapper = exports.StyledContentWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContentWrapper",
  componentId: "sc-pd0y6s-1"
})(["\n  width: 65.63%;\n  height: 100%;\n  padding: 13px;\n  float: left;\n\n  @media (min-width: 768px) {\n    width: 100%;\n    padding: 15px 0 0;\n    float: none;\n  }\n"]);
var StyledDate = exports.StyledDate = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDate",
  componentId: "sc-pd0y6s-2"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 12px;\n  margin-bottom: 12px;\n\n  @media (min-width: 768px) {\n    margin-bottom: 40px;\n  }\n"], function (props) {
  return props.theme.font.italic;
}, function (props) {
  return props.theme.color.darkGray;
});
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-pd0y6s-3"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 18px;\n  margin-bottom: 12px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n\n  @media (min-width: 768px) {\n    margin-bottom: 25px;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledText = exports.StyledText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledText",
  componentId: "sc-pd0y6s-4"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 12px;\n  line-height: 1.5;\n\n  @media (min-width: 768px) {\n    margin-bottom: 30px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledLinkWrapper = exports.StyledLinkWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledLinkWrapper",
  componentId: "sc-pd0y6s-5"
})(["\n  display: none;\n  text-decoration: none;\n  color: ", ";\n  font-family: ", ";\n  cursor: pointer;\n\n  &:visited {\n    color: ", ";\n  }\n\n  @media (min-width: 768px) {\n    margin-bottom: 60px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    float: right;\n    pointer-events: all !important;\n  }\n"], function (props) {
  return props.theme.color.red;
}, function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.red;
});
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-pd0y6s-6"
})(["\n  @media (min-width: 768px) {\n    width: 15px;\n    margin-left: 15px;\n  }\n"]);
var StyledNewsBlock = exports.StyledNewsBlock = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledNewsBlock",
  componentId: "sc-pd0y6s-7"
})(["\n  width: 100%;\n  height: 160px;\n  overflow: hidden;\n  -webkit-font-smoothing: antialiased;\n  display: block;\n  text-decoration: none;\n  background-color: ", ";\n  box-shadow: 0 0 35px 0 ", ";\n\n  @media (min-width: 768px) {\n    //width: 330px;\n    height: auto;\n    box-shadow: none;\n  }\n\n  ", "\n\n  ", "\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.color.shadowBlue;
}, function (props) {
  return props.isPreview && (0, _styledComponents.css)(["\n      @media (max-width: 767px) {\n        width: 240px;\n        height: auto;\n        margin: 0 auto;\n        border-radius: 15px;\n\n        display: flex;\n        flex-direction: column;\n\n        ", " {\n          width: 100%;\n          height: 100px;\n        }\n\n        ", " {\n          width: 100%;\n          height: 185px;\n        }\n      }\n    "], StyledImage, StyledContentWrapper);
}, function (props) {
  return props.isRelevant && (0, _styledComponents.css)(["\n      @media (min-width: 768px) {\n        display: flex;\n        flex-direction: row;\n        flex-wrap: wrap;\n        background-color: transparent;\n      }\n\n      ", " {\n        @media (min-width: 768px) {\n          height: 240px;\n          flex-basis: 47.41%;\n          margin-right: 5.18%;\n        }\n\n        @media (min-width: 1440px) {\n          height: 320px;\n          flex-basis: 65.81%;\n          margin-right: 4.27%;\n        }\n      }\n\n      ", " {\n        @media (min-width: 768px) {\n          height: 240px;\n          padding-top: 40px;\n          flex-basis: 47.41%;\n          position: relative;\n        }\n\n        @media (min-width: 1440px) {\n          height: 320px;\n          padding-top: 50px;\n          flex-basis: 29.92%;\n        }\n      }\n\n      ", " {\n        @media (min-width: 768px) {\n          margin-bottom: 15px;\n          font-size: 10px;\n        }\n\n        @media (min-width: 1440px) {\n          margin-bottom: 35px;\n        }\n      }\n\n      ", " {\n        @media (min-width: 768px) {\n          margin-bottom: 25px;\n          font-size: 18px;\n        }\n      }\n\n      ", " {\n        @media (min-width: 768px) {\n          margin-bottom: 15px;\n          font-size: 14px;\n          line-height: 1.6;\n        }\n\n        @media (min-width: 1440px) {\n          width: 85.7%;\n        }\n      }\n\n      ", " {\n        @media (min-width: 768px) {\n          margin-bottom: 0;\n          position: absolute;\n          bottom: 0;\n          right: 0;\n        }\n      }\n    "], StyledImage, StyledContentWrapper, StyledDate, StyledTitle, StyledText, StyledLinkWrapper);
});