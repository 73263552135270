import {compose} from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import {withWrapper, withPadder, SectionFaqs} from '@alkemy/components'

export default compose(
  withPageBuilder({
    niceName: 'FAQ Section',
  }),
  withFields([
    {
      prop: 'wrapped',
      type: 'boolean',
      label: 'Contenuto Boxed',
    },
    {
      prop: 'paddingLeftRight',
      label: 'Larghezza Componente',
      type: 'multiple',
      options: ['200:Small', '170:Medium', '100:Large', '0:Nessuno'],
    },
    {
      prop: 'paddingTop',
      label: 'Distanza dal Top',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'paddingBottom',
      label: 'Distanza dal Bottom',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'firstNotSelected',
      type: 'boolean',
      label: 'Primo Elemento Chiuso',
    },
    {
      prop: 'items',
      type: 'repeater',
      label: 'FAQ',
      model: [
        {
          prop: 'groupName',
          label: 'Titolo Raggruppamento',
        },
        {
          prop: 'childs',
          type: 'repeater',
          model: [
            {
              prop: 'title',
              label: 'Domanda',
            },
            {
              prop: 'text',
              type: 'WYSIWYG',
              label: 'Risposta',
            },
          ],
        },
      ],
    },
  ]),
  withWrapper,
  withPadder,
)(SectionFaqs)
