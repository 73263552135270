"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitle = exports.StyledText = exports.StyledNewsBlock = exports.StyledLinkWrapper = exports.StyledImage = exports.StyledIconWrapper = exports.StyledDate = exports.StyledContentWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledNewsBlock = exports.StyledNewsBlock = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledNewsBlock",
  componentId: "sc-1pq4gcq-0"
})(["\n  width: 100%;\n  overflow: hidden;\n  -webkit-font-smoothing: antialiased;\n  display: flex;\n  flex-direction: column;\n  text-decoration: none;\n  background-color: ", ";\n  min-height: 388px;\n  padding: 0 32px 32px;\n  box-shadow: 0 0 50px 0 rgba(81, 129, 186, 0.25);\n  border-radius: 20px;\n  transition: all 0.2s;\n  &:hover {\n    box-shadow: 0 0 50px 0 rgba(81, 129, 186, 0.34);\n  }\n  ", " {\n    flex-direction: row-reverse;\n    min-height: 100%;\n    padding: 40px 0 40px 40px;\n  }\n\n  ", "\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.isSingle && (0, _styledComponents.css)(["\n      ", " {\n        max-width: 971px;\n        margin-left: auto;\n        margin-right: auto;\n      }\n    "], function (props) {
    return props.theme.mediaQueries.tablet;
  });
});
var StyledImage = exports.StyledImage = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledImage",
  componentId: "sc-1pq4gcq-1"
})(["\n  mask-image: url('/static/images/news-mask.svg');\n  mask-repeat: no-repeat;\n  mask-position: bottom;\n  mask-size: contain;\n  background-image: url(", ");\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  padding-bottom: 80%;\n  margin-bottom: 10px;\n  flex: 0;\n  ", " {\n    background-image: url(", ");\n    mask-image: url('/static/images/news-mask-dt.svg');\n    mask-position: right;\n    padding: 0;\n    flex: 1;\n    margin: 0;\n  }\n"], function (props) {
  return props.imgMobileUrl + '?auto=format';
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.imgUrl + '?width=700&auto=format';
});
var StyledContentWrapper = exports.StyledContentWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContentWrapper",
  componentId: "sc-1pq4gcq-2"
})(["\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n\n  ", " {\n    flex: 0 0 230px;\n  }\n\n  ", "\n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.isSingle && (0, _styledComponents.css)(["\n      ", " {\n        flex: 0 0 400px;\n      }\n    "], function (props) {
    return props.theme.mediaQueries.tablet;
  });
});
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-1pq4gcq-3"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 16px;\n  line-height: 1.5;\n  margin-bottom: 4px;\n\n  ", " {\n    font-size: 20px;\n  }\n\n  ", "\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.isSingle && (0, _styledComponents.css)(["\n      ", " {\n        font-size: 24px;\n      }\n    "], function (props) {
    return props.theme.mediaQueries.tablet;
  });
});
var StyledDate = exports.StyledDate = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDate",
  componentId: "sc-1pq4gcq-4"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 12px;\n  line-height: 1.5;\n  text-transform: uppercase;\n  margin-bottom: 16px;\n\n  ", " {\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkGray;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledText = exports.StyledText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledText",
  componentId: "sc-1pq4gcq-5"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 14px;\n  line-height: 1.5;\n  margin-bottom: 18px;\n\n  ", " {\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledLinkWrapper = exports.StyledLinkWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledLinkWrapper",
  componentId: "sc-1pq4gcq-6"
})(["\n  display: flex;\n  align-items: center;\n  text-decoration: none;\n  color: ", ";\n  font-family: ", ";\n  cursor: pointer;\n  margin-top: auto;\n  font-size: 14px;\n  line-height: 1.5;\n\n  ", " {\n    font-size: 16px;\n  }\n"], function (props) {
  return props.theme.color.magenta;
}, function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-1pq4gcq-7"
})(["\n  width: 15px;\n  margin-left: 10px;\n  ", " {\n    margin-left: 20px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});