"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledSectionFaqs = exports.StyledListItem = exports.StyledList = exports.StyledIconWrapper = exports.StyledGroupNameCenter = exports.StyledGroupName = exports.StyledGroup = exports.StyledFlexRow = exports.StyledFlexCol = exports.StyledCollapseTitle = exports.StyledCollapseIcon = exports.StyledCollapse = exports.StyledButtonLabel = exports.StyledButton = exports.StyledBlockTitle = exports.StyledBlockText = exports.StyledBlockSide = exports.StyledBlockContent = exports.StyledBlockCenter = exports.StyledBlock = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _styles = require("../shared/styles");
var StyledSectionFaqs = exports.StyledSectionFaqs = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSectionFaqs",
  componentId: "sc-173rknx-0"
})(["\n  padding: 0 10px;\n  ul,\n  p {\n    padding: 0;\n  }\n"]);
var StyledFlexRow = exports.StyledFlexRow = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledFlexRow",
  componentId: "sc-173rknx-1"
})(["\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n  flex-wrap: wrap;\n  flex-direction: column;\n\n  margin-left: -35px;\n  margin-right: -35px;\n\n  ", " {\n    flex-direction: row;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledFlexCol = exports.StyledFlexCol = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledFlexCol",
  componentId: "sc-173rknx-2"
})(["\n  display: ", ";\n  position: relative;\n  flex-grow: ", ";\n  flex-basis: ", ";\n  padding-left: 35px;\n  padding-right: 35px;\n\n  ", " {\n  }\n"], function (props) {
  return props.width ? 'none' : 'block';
}, function (props) {
  return props.width ? '0' : '1';
}, function (props) {
  return props.width || '1px';
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var StyledBlock = exports.StyledBlock = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBlock",
  componentId: "sc-173rknx-3"
})(["\n  position: relative;\n  margin-top: 20px;\n  margin-bottom: 20px;\n\n  background-color: #ffffff;\n  box-shadow: 0 4px 20px 0 ", ";\n  border-radius: 24px;\n\n  ", " {\n    max-width: 320px;\n    margin: 10px auto;\n  }\n"], function (props) {
  return props.theme.color.shadowBlue;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var StyledBlockCenter = exports.StyledBlockCenter = (0, _styledComponents.default)(StyledBlock).withConfig({
  displayName: "styles__StyledBlockCenter",
  componentId: "sc-173rknx-4"
})([""]);
var StyledBlockSide = exports.StyledBlockSide = (0, _styledComponents.default)(StyledBlock).withConfig({
  displayName: "styles__StyledBlockSide",
  componentId: "sc-173rknx-5"
})(["\n  ", " {\n  }\n"], function (props) {
  return props.theme.mediaQueries.desktop;
});
var StyledBlockContent = exports.StyledBlockContent = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBlockContent",
  componentId: "sc-173rknx-6"
})(["\n  display: flex;\n  flex-direction: column;\n\n  position: relative;\n  padding: ", " 35px;\n  max-height: ", ";\n\n  opacity: ", ";\n  overflow: hidden;\n  transition: max-height 0.5s, opacity 0.5s, padding 0.5s;\n  p {\n    padding: 0 22px;\n  }\n  ", " {\n  }\n"], function (props) {
  return props.closed ? '26px' : '40px';
}, function (props) {
  return props.closed ? '0' : '1800px';
}, function (props) {
  return props.closed ? '0' : '1';
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var StyledBlockTitle = exports.StyledBlockTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBlockTitle",
  componentId: "sc-173rknx-7"
})(["\n  margin-top: 20px;\n  margin-bottom: 20px;\n  text-align: center;\n  font-family: ", ";\n\n  color: ", ";\n  font-size: 20px;\n  text-transform: uppercase;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.orange;
});
var StyledBlockText = exports.StyledBlockText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBlockText",
  componentId: "sc-173rknx-8"
})(["\n  display: ", ";\n  flex-grow: 1;\n\n  font-family: ", ";\n  color: ", ";\n  font-size: 16px;\n  line-height: 24px;\n\n  margin-bottom: 40px;\n"], function (props) {
  return props.hide ? 'none' : 'block';
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledButton = exports.StyledButton = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledButton",
  componentId: "sc-173rknx-9"
})(["\n  align-self: center;\n\n  cursor: pointer;\n  outline: none;\n  border-radius: 31.5px;\n  border: solid 2px ", ";\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  line-height: 38px;\n\n  text-align: center;\n  text-decoration: none;\n\n  padding-left: 35px;\n  padding-right: 35px;\n"], function (props) {
  return props.theme.color.blue;
}, function (props) {
  return props.theme.color.blue;
}, function (props) {
  return props.theme.font.regular;
});
var StyledButtonLabel = exports.StyledButtonLabel = _styledComponents.default.span.withConfig({
  displayName: "styles__StyledButtonLabel",
  componentId: "sc-173rknx-10"
})([""]);
var StyledCollapse = exports.StyledCollapse = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCollapse",
  componentId: "sc-173rknx-11"
})(["\n  position: absolute;\n  top: 10px;\n  left: 25px;\n  right: 25px;\n  z-index: 1;\n  cursor: pointer;\n\n  ", " {\n  }\n"], function (props) {
  return props.theme.mediaQueries.desktop;
});
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-173rknx-12"
})(["\n  float: right;\n  width: 20px;\n  margin-top: 2px;\n"]);
var StyledCollapseIcon = exports.StyledCollapseIcon = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCollapseIcon",
  componentId: "sc-173rknx-13"
})(["\n  width: 32px;\n  height: 32px;\n  margin: 0 30px;\n  position: absolute;\n  right: 0;\n  top: 0;\n  margin: 0;\n  transform: rotate(", "deg);\n  transition: transform 0.5s;\n"], function (props) {
  return props.closed ? '-45' : '0';
});
var StyledCollapseTitle = exports.StyledCollapseTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCollapseTitle",
  componentId: "sc-173rknx-14"
})(["\n  display: block;\n  opacity: ", ";\n  flex-grow: 1;\n\n  font-family: ", ";\n  color: ", ";\n  font-size: 16px;\n  line-height: 32px;\n\n  margin-bottom: 40px;\n\n  margin: 0;\n  padding-left: 12px;\n  transition: opacity 0.5s;\n\n  > div {\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    padding-right: 40px;\n  }\n"], function (props) {
  return props.hide ? '0' : '1';
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledGroupName = exports.StyledGroupName = (0, _styledComponents.default)(StyledBlockTitle).withConfig({
  displayName: "styles__StyledGroupName",
  componentId: "sc-173rknx-15"
})(["\n  color: ", ";\n  font-size: 18px;\n"], function (props) {
  return props.theme.color.darkBlue;
});
var StyledGroupNameCenter = exports.StyledGroupNameCenter = (0, _styledComponents.default)(StyledBlockTitle).withConfig({
  displayName: "styles__StyledGroupNameCenter",
  componentId: "sc-173rknx-16"
})(["\n  color: ", ";\n  font-size: 18px;\n\n  padding-top: 60px;\n  padding-bottom: 10px;\n  margin-bottom: 40px;\n\n  border-bottom: solid 2px ", ";\n\n  ", " {\n  }\n"], function (props) {
  return props.theme.color.darkGray;
}, function (props) {
  return props.theme.color.gray;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var StyledList = exports.StyledList = _styledComponents.default.ul.withConfig({
  displayName: "styles__StyledList",
  componentId: "sc-173rknx-17"
})(["\n  list-style: none;\n  padding: 0;\n  margin-bottom: 0;\n"]);
var StyledListItem = exports.StyledListItem = _styledComponents.default.li.withConfig({
  displayName: "styles__StyledListItem",
  componentId: "sc-173rknx-18"
})(["\n  font-family: ", ";\n  cursor: pointer;\n\n  &:not(:last-child) {\n    margin-bottom: 20px;\n  }\n"], function (props) {
  return props.theme.font.regular;
});
var StyledGroup = exports.StyledGroup = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledGroup",
  componentId: "sc-173rknx-19"
})(["\n  padding: 22px 50px;\n"]);