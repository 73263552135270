import React, { Component } from 'react'
import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'
import axios from 'axios'

import {
  WorkWithUsBlockList,
  withWrapper,
  withPadder,
} from '@alkemy/components'

const makeList = data => {
  let arr = []

  data.map(item => {
    arr.push({
      title: item.title.rendered,
      desc: item.acf.workOffer.description,
      image: item.acf.workOffer.logo,
      place: item.acf.workOffer.sede,
      href: item.link,
    })
  })

  return arr
}

class BuildedWorkWithUsBlockList extends Component {
  constructor(props) {
    super(props)

    this.fetchData = this.fetchData.bind(this)
    this.loadMore = this.loadMore.bind(this)
    this.handleLoadMore = this.handleLoadMore.bind(this)

    this.state = {
      page: 3,
      hasLoadMore: true,
      list: [],
    }
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      return true
    }

    return null
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      this.handleLoadMore(prevState.list, this.state.list)
    }
  }

  handleLoadMore(prevList, currentList) {
    this.setState({
      list: [...prevList, ...currentList],
    })
  }

  handleOnClick() {
    const { page } = this.state

    this.LoadMore()
  }

  loadMore() {
    let list
    const { page } = this.state
    const postPerPage = 4

    axios(
      `${process.env.WP_API}/wp/v2/work-offer?per_page=${postPerPage}&page=${page}`,
    ).then(res => {
      list = makeList(res.data)
      this.setState({
        list,
        page: page + 1,
        hasLoadMore: list.length >= postPerPage,
      })
    })
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    let list
    const postPerPage = this.props.hasSlider ? 100 : 8

    axios(
      `${process.env.WP_API}/wp/v2/work-offer?per_page=${postPerPage}`,
    ).then(res => {
      list = makeList(res.data)
      this.setState({
        list,
        hasLoadMore: list.length >= postPerPage,
      })
    })
  }

  render() {
    const { title, desc, hasSlider } = this.props
    const { list, page, hasLoadMore } = this.state

    return (
      <WorkWithUsBlockList
        title={title}
        desc={desc}
        hasSlider={hasSlider}
        list={list}
        handleOnClick={this.loadMore}
        hasLoadMore={hasLoadMore}
      />
    )
  }
}

export default compose(
  withPageBuilder(),
  withFields([
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'desc',
      label: 'Testo',
    },
    {
      prop: 'hasSlider',
      label: 'Slider',
      type: 'boolean',
    },
  ]),
  withPadder,
  withWrapper,
)(BuildedWorkWithUsBlockList)
