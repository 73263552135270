"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledGradientText = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledGradientText = exports.StyledGradientText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledGradientText",
  componentId: "sc-7gu6gx-0"
})([""]);