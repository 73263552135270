import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { ListWithRoundImg, withWrapper } from '@alkemy/components'


export default compose(
  withPageBuilder({
    niceName: 'ListWithRoundImg',
  }),
  withFields([
    {
      prop: 'img',
      label: 'Immagine',
    },
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'list',
      label: 'Lista',
      type: 'repeater',
      model: [
        {
          prop: 'text',
        },
      ],
    },
    {
      prop: 'boldText',
      label: 'Testo',
    },
  ]),
  withWrapper,
)(ListWithRoundImg)
