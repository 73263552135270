"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WrapperMain = exports.Wrapper = exports.Title = exports.MotivazioniWrapper = exports.MainTitle = exports.BodyWrapper = exports.BlobImage = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var WrapperMain = exports.WrapperMain = _styledComponents.default.div.withConfig({
  displayName: "styles__WrapperMain",
  componentId: "sc-xcxw3h-0"
})(["\n  width: 100%;\n  background-image: linear-gradient(to top, #fff, #fff 61%, #fff 27%, #f2f2f2);\n\n  ", " {\n    margin-bottom: 0;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var Wrapper = exports.Wrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-xcxw3h-1"
})(["\n  width: 100%;\n  margin: 70px auto 0 auto;\n  padding: 50px;\n  position: relative;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 50px;\n\n  ", " {\n    padding: 22px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var BlobImage = exports.BlobImage = _styledComponents.default.img.withConfig({
  displayName: "styles__BlobImage",
  componentId: "sc-xcxw3h-2"
})(["\n  position: absolute;\n  transform: rotate(120deg);\n  width: 1000px;\n  top: -450px;\n  right: -300px;\n  z-index: 1;\n"]);
var BodyWrapper = exports.BodyWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__BodyWrapper",
  componentId: "sc-xcxw3h-3"
})(["\n  width: 100%;\n  max-width: 1000px;\n  margin: 30px auto 0 auto;\n  z-index: 10;\n  display: grid;\n  grid-template-rows: auto 1fr;\n  flex-direction: column;\n  gap: 20px;\n  position: relative;\n\n  ", " {\n    width: 100%;\n    margin-bottom: 102px !important;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var MotivazioniWrapper = exports.MotivazioniWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__MotivazioniWrapper",
  componentId: "sc-xcxw3h-4"
})(["\n  width: 100%;\n  margin: 0 auto 95px auto;\n  z-index: 10;\n  display: grid;\n  grid-template-columns: 1fr;\n  justify-content: center;\n  align-items: center;\n  gap: 20px;\n  padding: 0 50px;\n  max-width: 900px;\n\n  ", " {\n    padding: 0 !important;\n    margin: 0 auto !important;\n    position: relative;\n    max-width: 400px;\n  }\n\n  ", " {\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  return props.theme.mediaQueries.ipad;
});
var Title = exports.Title = _styledComponents.default.h2.withConfig({
  displayName: "styles__Title",
  componentId: "sc-xcxw3h-5"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 30px;\n  font-weight: bold;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.33;\n  letter-spacing: normal;\n  margin: 0;\n  white-space: nowrap;\n  margin-bottom: 16px !important;\n\n  ", " {\n    white-space: break-spaces;\n    font-size: 18px !important;\n    margin-bottom: 0;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var MainTitle = exports.MainTitle = (0, _styledComponents.default)(Title).withConfig({
  displayName: "styles__MainTitle",
  componentId: "sc-xcxw3h-6"
})(["\n  font-size: 35px;\n  line-height: 1.26;\n  text-align: center;\n  margin-bottom: 16px;\n  font-family: ", " !important;\n\n  ", " {\n    font-size: 22px !important;\n    line-height: 1.36;\n    margin: 0 auto 16px auto;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.mediaQueries.phone;
});