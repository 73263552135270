const abbiategrasso = require("./stores/abbiategrasso.json")
const arcisate = require("./stores/arcisate.json")
const saronno = require("./stores/saronno.json")
const dalmine = require("./stores/dalmine.json")
const rho = require("./stores/rho.json")
const chiari = require("./stores/chiari.json")
const casalpusterlengo = require("./stores/casalpusterlengo.json")
const bustoarsizio = require("./stores/busto-arsizio.json")
const terni = require("./stores/terni.json")
const nettuno = require("./stores/nettuno.json")
const casteldazzano = require("./stores/casteldazzano.json")
const lovere = require("./stores/lovere.json")
const roncadelle = require("./stores/roncadelle.json")

module.exports = {
  "/punti-vendita/abbiategrasso-new/": abbiategrasso,
  "/punti-vendita/arcisate-new/": arcisate,
  "/punti-vendita/saronno/": saronno,
  "/punti-vendita/dalmine/": dalmine,
  "/punti-vendita/rho/": rho,
  "/punti-vendita/chiari/": chiari,
  "/punti-vendita/casalpusterlengo1/": casalpusterlengo,
  "/punti-vendita/busto-arsizio/": bustoarsizio,
  "/punti-vendita/terni/": terni,
  "/punti-vendita/nettuno/": nettuno,
  "/punti-vendita/castel_dazzano/": casteldazzano,
  "/punti-vendita/lovere/": lovere,
  "/punti-vendita/roncadelle/": roncadelle,
}
