const abbiategrasso = require("./stores-detail/abbiategrasso.json")
const abbiategrassoNew = require("./stores-detail/abbiategrasso-new.json")
const arcisate = require("./stores-detail/arcisate.json")
const arcisateNew = require("./stores-detail/arcisate-new.json")
const arona = require("./stores-detail/arona.json")
const casalpusterlengo1 = require("./stores-detail/casalpusterlengo1.json")
const casteldazzano = require("./stores-detail/casteldazzano.json")
const chiari = require("./stores-detail/chiari.json")
const legnano = require("./stores-detail/legnano.json")
const lovere = require("./stores-detail/lovere.json")
const luragoderba = require("./stores-detail/lurago-derba.json")
const magenta = require("./stores-detail/magenta.json")
const monterotondo = require("./stores-detail/monterotondo.json")
const narni = require("./stores-detail/narni.json")
const nave = require("./stores-detail/nave.json")
const negrar = require("./stores-detail/negrar.json")
const nettuno = require("./stores-detail/nettuno.json")
const oleggio = require("./stores-detail/oleggio.json")
const olgiateolona = require("./stores-detail/olgiateolona.json")
const omegna = require("./stores-detail/omegna.json")
const orte = require("./stores-detail/orte.json")
const palestrina = require("./stores-detail/palestrina.json")
const parabiago = require("./stores-detail/parabiago.json")
const pontirolonuovo = require("./stores-detail/pontirolo-nuovo.json")
const terni = require("./stores-detail/terni.json")
const bustoarsizio = require("./stores-detail/busto-arsizio.json")
const roncadelle = require("./stores-detail/roncadelle.json")
const saronno = require("./stores-detail/saronno.json")
const dalmine = require("./stores-detail/dalmine.json")
const rho = require("./stores-detail/rho.json")

module.exports = {
  "/punti-vendita/abbiategrasso/": abbiategrasso,
  "/punti-vendita/abbiategrasso-new/": abbiategrassoNew,
  "/punti-vendita/arcisate/": arcisate,
  "/punti-vendita/arcisate-new/": arcisateNew,
  "/punti-vendita/arona/": arona,
  "/punti-vendita/casalpusterlengo1/": casalpusterlengo1,
  "/punti-vendita/castel_dazzano/": casteldazzano,
  "/punti-vendita/chiari/": chiari,
  "/punti-vendita/legnano/": legnano,
  "/punti-vendita/lovere/": lovere,
  "/punti-vendita/lurago-derba/": luragoderba,
  "/punti-vendita/magenta/": magenta,
  "/punti-vendita/monterotondo/": monterotondo,
  "/punti-vendita/narni/": narni,
  "/punti-vendita/nave/": nave,
  "/punti-vendita/negrar/": negrar,
  "/punti-vendita/nettuno/": nettuno,
  "/punti-vendita/oleggio/": oleggio,
  "/punti-vendita/olgiate-olona/": olgiateolona,
  "/punti-vendita/omegna/": omegna,
  "/punti-vendita/orte/": orte,
  "/punti-vendita/palestrina/": palestrina,
  "/punti-vendita/parabiago/": parabiago,
  "/punti-vendita/pontirolo-nuovo/": pontirolonuovo,
  "/punti-vendita/terni/": terni,
  "/punti-vendita/roncadelle/": roncadelle,
  "/punti-vendita/busto-arsizio/": bustoarsizio,
  "/punti-vendita/saronno/": saronno,
  "/punti-vendita/dalmine/": dalmine,
  "/punti-vendita/rho/": rho,
}
