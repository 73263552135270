"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitle = exports.StyledPressReleaseWrapper = exports.StyledPressReleaseList = exports.StyledPressReleaseItem = exports.StyledButtonWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledPressReleaseList = exports.StyledPressReleaseList = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledPressReleaseList",
  componentId: "sc-j01n08-0"
})(["\n  width: 100%;\n  margin: 0 auto;\n  -webkit-font-smoothing: antialiased;\n\n  @media (min-width: 768px) {\n    max-width: 540px;\n  }\n\n  @media (min-width: 1440px) {\n    max-width: 970px;\n  }\n"]);
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-j01n08-1"
})(["\n  text-align: center;\n  font-family: ", ";\n  color: ", ";\n  line-height: 1.1;\n  margin: 30px 0;\n  padding: 0 50px;\n  font-size: 20px;\n  \n  @media (min-width: 768px) {\n    margin-bottom: 75px;\n  \n  @media (min-width: 1440px) {\n    margin-top: 0;\n    margin-bottom: 90px;\n    font-size: 30px;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledPressReleaseWrapper = exports.StyledPressReleaseWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledPressReleaseWrapper",
  componentId: "sc-j01n08-2"
})([""]);
var StyledPressReleaseItem = exports.StyledPressReleaseItem = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledPressReleaseItem",
  componentId: "sc-j01n08-3"
})(["\n  margin-bottom: 25px;\n\n  @media (min-width: 768px) {\n    margin-bottom: 40px;\n  }\n\n  @media (min-width: 1440px) {\n    margin-bottom: 60px;\n  }\n"]);
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-j01n08-4"
})(["\n  margin: 0 auto 50px;\n  width: 170px;\n\n  @media (min-width: 768px) {\n    margin-top: 75px;\n  }\n\n  @media (min-width: 1440px) {\n    width: 170px;\n    margin-top: 100px;\n  }\n"]);