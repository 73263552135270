import React from 'react'
import { compose } from 'recompose'
import axios from 'axios'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import OpenModal from '../components/OpenModal'
import { Contents } from '@alkemy/common'
import { Wrapper, OffersFormBusiness } from '@alkemy/components'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'
import { normalizeList } from '../lib/utils'

const getOffers = async arrayId => {
  const idQuery =
    arrayId && arrayId.length > 0
      ? arrayId.map(id => `&include[]=${id}`).join('')
      : ''
  const url = `${process.env.WP_API}/wp/v2/pages?per_page=50&_embed&filter[orderby]=menu_order&_fields[]=id&_fields[]=acf&_fields[]=slug&_fields[]=offers_category&_fields[]=offerTags_category&_fields[]=link${idQuery}`
  let data = await axios.get(url).then(x => x.data)

  return data
}

const makeOffer = ({
  acf,
  slug,
  offers_category,
  offerTags_category,
  link,
}) => ({
  list: acf && acf.bonus_list && normalizeList(acf.bonus_list),
  partners_list:
    acf && acf.partners_list && acf.partners_list.map(i => i.partner_image.url),
  title: acf && acf.sidebar_title,
  tag: [...offerTags_category],
  isBusiness: false,
  icon: acf && acf.icon,
  isGreenEnergy: acf && acf.is_green_energy,
  cta: {
    ctaLabel: 'SCOPRI',
    ctaUrl: link,
  },
})

const OffersFormPB = () => (
  <Wrapper>
    <OpenModal>
      {({ openStoreLocatorModal }) => (
        <OffersFormBusiness
          makeOffer={makeOffer}
          getOffers={getOffers}
          formItems={Contents.business.form}
          resultItems={Contents.business.offerte}
          offersModals={Contents.business.offersModals}
          sectionTitle={Contents.business.content.sectionTitle}
          api={`${process.env.LARAVEL_API}/business`}
          storeURL={`${process.env.WP_API}/wp/v2/shop?per_page=40`}
          blobColors={['#7E2889', '#1587C8']}
          openStoreLocatorModal={openStoreLocatorModal}
        />
      )}
    </OpenModal>
  </Wrapper>
)

export default compose(
  withPageBuilder(),
  withFields([
    {
      prop: 'modals',
      type: 'repeater',
      label: 'Contenuto Tooltip',
      model: [
        {
          prop: 'id',
          type: 'multiple',
          label: 'Tipologia needs',
          options: ['6:CAMBIO FORNITORE', '5:NUOVA ATTIVAZIONE', '7:SUBENTRO'],
          initialValue: '1',
        },
        {
          prop: 'title',
          label: 'Titolo',
        },
        {
          prop: 'text',
          label: 'Testo',
          type: 'WYSIWYG',
        },
      ],
    },
  ]),
)(OffersFormPB)
