"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledVivipediaSearchBox = exports.StyledSmallText = exports.StyledInput = exports.StyledIconWrapper = exports.StyledErrorMessage = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledVivipediaSearchBox = exports.StyledVivipediaSearchBox = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledVivipediaSearchBox",
  componentId: "sc-1rkm4cq-0"
})(["\n  max-width: 240px;\n  margin: 60px auto 80px!important;\n  position: relative;\n  \n  @media (min-width: 768px) {\n    max-width: 540px;\n    margin: 60px auto 100px!important;\n  }\n  \n  @media (min-width: 1440px) {\n    max-width: 770px;\n    margin: 60px auto 120px!important;\n  }\n"]);
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-1rkm4cq-1"
})(["\n  width: 30px;\n  height: 30px;\n  position: absolute;\n  left: -4px;\n  top: 0;\n  \n  @media (min-width: 768px) {\n    width: 55px;\n    height: 55px;\n  }\n  \n  path {\n    fill: ", ";\n    transition: fill .2s ease-in 0s;\n  }\n"], function (props) {
  return props.theme.color.gray;
});
var StyledInput = exports.StyledInput = _styledComponents.default.input.withConfig({
  displayName: "styles__StyledInput",
  componentId: "sc-1rkm4cq-2"
})(["\n  width: 100%;\n  padding-bottom: 12px;\n  padding-left: 35px;\n  border: none;\n  border-bottom: 1px solid ", ";\n  -webkit-appearance: none;\n  outline: none;\n  font-family: ", ";\n  font-size: 16px;\n  line-height: 24px;\n  transition: all .2s ease-in 0s;\n  color: ", ";\n  \n  @media (min-width: 768px) {\n    padding-bottom: 30px;\n    padding-left: 100px;\n    line-height: 44px;\n    font-size: 32px;\n  }\n  \n  &::placeholder {\n    color: ", ";\n    font-family: ", ";\n    transition: color .2s ease-in 0s;\n  }\n  \n  &:focus {\n    border-bottom: 1px solid ", ";\n    \n    &::placeholder {\n      color: ", "\n    }\n    \n    & + ", " {\n      path {\n        fill: ", ";\n      }\n    }\n  }\n"], function (props) {
  return props.theme.color.darkGray;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.color.gray;
}, function (props) {
  return props.theme.font.light;
}, function (props) {
  return props.theme.color.lightBlue;
}, function (props) {
  return props.theme.color.lightBlue;
}, StyledIconWrapper, function (props) {
  return props.theme.color.lightBlue;
});
var StyledSmallText = exports.StyledSmallText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSmallText",
  componentId: "sc-1rkm4cq-3"
})(["\n  display: none;\n  position: absolute;\n  font-family: ", ";\n  color: ", ";\n  right: 0;\n  bottom: -30px;\n  \n  @media (min-width: 768px) {\n    display: block;\n    font-size: 14px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledErrorMessage = exports.StyledErrorMessage = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledErrorMessage",
  componentId: "sc-1rkm4cq-4"
})(["\n  font-family: ", ";\n  color: ", ";\n  text-align: center;\n  font-size: 18px;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});