import {compose} from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import {ListOfIcons, withWrapper} from '@alkemy/components'

export default compose(
  withPageBuilder(),
  withFields([
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'items',
      type: 'repeater',
      label: 'List',
      model: [
        {
          prop: 'label',
          label: 'Label',
        },
        {
          prop: 'icon',
          type: 'multiple',
          label: 'Icona',
          options: [
            'Gas:Gas',
            'OrionPlug:Plug',
            'Iban:Iban',
            'Id:Id',
            'Bill:Bill',
            'OrionDownload:Download',
          ],
          initialValue: 'Gas',
        },
        {
          prop: 'href',
          label: 'Link',
        },
        {
          prop: 'targetBlank',
          type: 'boolean',
          label: 'Apri in nuova finestra',
        },
      ],
    },
  ]),
  withWrapper,
)(ListOfIcons)
