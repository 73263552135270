"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TextWrapper = exports.Number = exports.MotivazioneGrid = exports.Motivazione = exports.IconWrapper = exports.Description = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var IconWrapper = exports.IconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__IconWrapper",
  componentId: "sc-1sbnplq-0"
})(["\n  position: absolute;\n  z-index: -1;\n  padding: 0 !important;\n\n  img,\n  svg {\n    width: 245px;\n    height: 245px;\n  }\n\n  ", " {\n    position: relative;\n    img,\n    svg {\n      width: 104px !important;\n      height: 104px !important;\n    }\n\n    ", "\n  }\n\n  ", "\n"], function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  switch (props.position) {
    case 'left':
      return "\n            left: 0;\n            top: -6px;\n          ";
    case 'right':
      return "\n            right: 0;\n            top: 15px;\n          ";
    case 'center':
      return "\n            right: auto;\n            left: 0;\n            bottom: auto;\n            top: 9px;\n          ";
  }
}, function (props) {
  switch (props.position) {
    case 'left':
      return "\n          left: -200px;\n          top: 0;\n        ";
    case 'right':
      return "\n          right: -70%;\n          top: 2%;\n        ";
    case 'center':
      return "\n          right: -161px;\n          bottom: -146px;\n        ";
  }
});
var Number = exports.Number = _styledComponents.default.span.withConfig({
  displayName: "styles__Number",
  componentId: "sc-1sbnplq-1"
})(["\n  font-size: 125px;\n  font-weight: 600;\n  color: rgba(250, 100, 0, 0.3);\n\n  ", " {\n    font-size: 60px;\n    margin-bottom: 0;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var TextWrapper = exports.TextWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__TextWrapper",
  componentId: "sc-1sbnplq-2"
})(["\n  display: flex;\n  flex-direction: column;\n  justiy-content: space-between;\n  align-items: flex-start;\n  font-family: ", ";\n  color: ", ";\n  margin: 0;\n\n  ", " {\n    gap: 8px;\n    max-width: 211px;\n    white-space: pre-wrap;\n  }\n\n  * {\n    margin: 0;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var Motivazione = exports.Motivazione = _styledComponents.default.div.withConfig({
  displayName: "styles__Motivazione",
  componentId: "sc-1sbnplq-3"
})(["\n  width: 100%;\n  max-width: 400px;\n  position: relative;\n\n  ", " {\n    display: flex;\n    width: 100%;\n    justify-content: flex-start;\n    align-items: center;\n    margin-bottom: 0;\n  }\n\n  ", "\n"], function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  if (props.position && props.position === 'center') {
    return "\n        margin-bottom: 95px;\n      ";
  }
});
var Description = exports.Description = _styledComponents.default.p.withConfig({
  displayName: "styles__Description",
  componentId: "sc-1sbnplq-4"
})(["\n  font-size: 16px;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.5;\n  letter-spacing: normal;\n  margin: 0;\n\n  ", " {\n    font-size: 14px;\n    line-height: 1.57;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var MotivazioneGrid = exports.MotivazioneGrid = _styledComponents.default.div.withConfig({
  displayName: "styles__MotivazioneGrid",
  componentId: "sc-1sbnplq-5"
})(["\n  position: relative;\n\n  ", " {\n    display: grid;\n    grid-template-columns: auto auto;\n    grid-template-rows: 1fr;\n    justify-content: center;\n\n    &:nth-child(even) {\n      justify-content: flex-start;\n\n      ", " {\n        grid-column-start: 2;\n        grid-column-end: 3;\n      }\n\n      ", " {\n        grid-row-start: 1;\n      }\n    }\n\n    &:nth-child(odd) {\n      ", " {\n        margin-top: 75px;\n      }\n    }\n  }\n\n  ", " {\n    &:first-child {\n      padding-left: 100px;\n    }\n\n    &:nth-child(odd) {\n      justify-self: flex-end;\n    }\n\n    &:nth-child(even) {\n      justify-self: flex-start;\n    }\n  }\n\n  ", " {\n    &:nth-child(odd) {\n      justify-self: flex-start;\n    }\n\n    &:nth-child(even) {\n      justify-self: flex-end;\n    }\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
}, IconWrapper, Motivazione, TextWrapper, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.ipad;
});