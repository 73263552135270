"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Image = exports.IconContainer = exports.Icon = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _ = require("../../");
var IconContainer = exports.IconContainer = _styledComponents.default.div.withConfig({
  displayName: "style__IconContainer",
  componentId: "sc-3646wm-0"
})(["\n  position: relative;\n  border-radius: 50%;\n\n  > canvas {\n    z-index: -1;\n    border-radius: 50%;\n  }\n"]);
var Icon = exports.Icon = (0, _styledComponents.default)(_.Icons).withConfig({
  displayName: "style__Icon",
  componentId: "sc-3646wm-1"
})(["\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 48px;\n  height: 48px;\n  z-index: 10;\n\n  ", " {\n    margin: 5px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var Image = exports.Image = _styledComponents.default.img.withConfig({
  displayName: "style__Image",
  componentId: "sc-3646wm-2"
})(["\n  height: 100%;\n  width: 100%;\n"]);