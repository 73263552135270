import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'
import * as SVGIcon from '@alkemy/components/lib/components/Icons/SVG'
import * as SVGIconIllustrate from '@alkemy/components/lib/components/Icons/illustrate'
import styled from 'styled-components'
import OpenModal from '../components/OpenModal'
import { Icons, ProductPageInfoCards, withWrapper } from '@alkemy/components'

const iconOptionsOld = Object.keys(SVGIcon).map(item => `${item}:${item}`)
const iconOptionsNew = Object.keys(SVGIconIllustrate).map(
  item => `${item}:${item}`,
)

const IconWrapper = styled.div`
  width: 35px;
  height: 35px;
  padding: 5px;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 20px;
  flex: 0 35px;
  svg {
    width: 100% !important;
    height: 100% !important;
  }
  > div {
    width: 100% !important;
    height: 100% !important;
    > svg {
      width: 100% !important;
      height: 100% !important;
    }
    > div {
      > svg {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
`
const OptionContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  > span {
    flex: 1;
  }
`

const optionsComponent = props => (
  <OptionContainer style={{ height: '40px' }}>
    <IconWrapper>
      <Icons icon={props.data.value} style={{ width: '100%' }} color="#000" />
    </IconWrapper>
    <span>{props.data.label}</span>
  </OptionContainer>
)

const PPICards = props => {
  return (
    <>
      {props.buttonType === 'salesPoint' ||
      props.buttonType === 'salesPointCall' ||
      props.buttonType === 'scrivici' ? (
        <OpenModal>
          {({ openStoreLocatorModal }) => (
            <ProductPageInfoCards
              handleOnClick={openStoreLocatorModal}
              {...props}
            />
          )}
        </OpenModal>
      ) : (
        <ProductPageInfoCards {...props} />
      )}
    </>
  )
}

export default compose(
  withPageBuilder({
    niceName: 'Product Page Info Cards',
  }),
  withFields([
    {
      prop: 'title',
      label: 'titolo',
    },
    {
      prop: 'text',
      label: 'testo',
      type: 'WYSIWYG',
    },
    {
      prop: 'buttonType',
      label: 'Tipo pulsante',
      type: 'select',
      options: [
        'salesPoint:Sales point',
        'salesPointCall:Sales point + Call',
        'scrivici:Scrivici',
        'tichiamiamonoi:Ti chiamiamo noi',
        'customLink:Custom link',
      ],
    },
    {
      prop: 'link',
      label: 'link',
    },
    {
      prop: 'linkLabel',
      label: 'label link',
    },
    {
      prop: 'salesPointCallText',
      label: 'Sales point + Call Text',
    },
    {
      prop: 'icon',
      type: 'select',
      label: 'Icona',
      optionsComponent,
      options: [...iconOptionsNew, ...iconOptionsOld],
      initialValue: 'Gas',
    },
    {
      prop: 'background',
      label: 'Background',
    },
  ]),
  withWrapper,
)(PPICards)
