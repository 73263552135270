"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitleStep = exports.StyledHeadingStep = exports.StyledGridForniture = exports.StyledGrid = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _styles = require("../../styles");
var StyledGrid = exports.StyledGrid = (0, _styledComponents.default)(_styles.FlexCenter).withConfig({
  displayName: "styles__StyledGrid",
  componentId: "sc-b64y83-0"
})(["\n  flex-direction: column;\n  justify-content: flex-start;\n  max-width: 1200px;\n  margin-left: auto;\n  margin-right: auto;\n  min-height: calc(100vh - 78px);\n  padding: 60px 20px;\n  * {\n    box-sizing: border-box;\n  }\n"]);
var StyledGridForniture = exports.StyledGridForniture = (0, _styledComponents.default)(_styles.FlexCenter).withConfig({
  displayName: "styles__StyledGridForniture",
  componentId: "sc-b64y83-1"
})(["\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  place-content: center center;\n  max-width: 1200px;\n  margin-left: auto;\n  margin-right: auto;\n  min-height: calc(100vh - 78px);\n  padding: 60px 20px;\n  * {\n    box-sizing: border-box;\n  }\n"]);
var StyledTitleStep = exports.StyledTitleStep = _styledComponents.default.h2.withConfig({
  displayName: "styles__StyledTitleStep",
  componentId: "sc-b64y83-2"
})(["\n  font-family: ", ";\n  font-size: 18px;\n  color: ", ";\n  text-align: center;\n\n  ", " {\n    font-size: 30px;\n    line-height: 1.33;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledHeadingStep = exports.StyledHeadingStep = _styledComponents.default.h1.withConfig({
  displayName: "styles__StyledHeadingStep",
  componentId: "sc-b64y83-3"
})(["\n  font-family: ", ";\n  font-size: 25px;\n  color: ", ";\n  text-align: left;\n  width: 100%;\n  margin: 0 0 16px 0;\n\n  ", " {\n    font-size: 56px;\n    line-height: 1.33;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});