"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledInput = exports.StyledIconWrapper = exports.StyledFormTextInput = exports.StyledErrorWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledFormTextInput = exports.StyledFormTextInput = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledFormTextInput",
  componentId: "sc-1klb98w-0"
})(["\n  width: 100%;\n"]);
var StyledInput = exports.StyledInput = _styledComponents.default.input.withConfig({
  displayName: "styles__StyledInput",
  componentId: "sc-1klb98w-1"
})(["\n  width: 100%;\n  padding: 25px 6px 14px;\n  -webkit-appearance: none;\n  border: none;\n  border-bottom: 1px solid ", ";\n  font-family: ", ";\n  font-size: 18px;\n  transition: all 0.2s linear 0s;\n  outline: none;\n\n  &::placeholder {\n    color: ", ";\n    transition: color 0.2s linear 0s;\n  }\n\n  &:focus {\n    border-bottom: 1px solid ", ";\n\n    &::placeholder {\n      color: ", ";\n    }\n  }\n"], function (props) {
  return props.theme.color.gray;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-1klb98w-2"
})([""]);
var StyledErrorWrapper = exports.StyledErrorWrapper = _styledComponents.default.span.withConfig({
  displayName: "styles__StyledErrorWrapper",
  componentId: "sc-1klb98w-3"
})(["\n  color: ", ";\n"], function (props) {
  return props.theme.color.red;
});