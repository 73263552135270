"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledLogo = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledLogo = exports.StyledLogo = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledLogo",
  componentId: "sc-n018tq-0"
})(["\n  display: block;\n  position:relative;\n  width: 160px;\n  height: 88px;\n  @media (max-width: 767px) {\n    top:-24px;\n  }\n  background-image: url('", "/images/common/", ".svg');\n  background-color: transparent;\n  background-position: left center;\n  background-repeat: no-repeat;\n  background-size: contain;\n  transition: all .4s linear 0s;\n\n\n\n  ", " {\n     width: 200px;\n     height: 88px;\n     top: -16px;\n  }\n"], function (props) {
  return props.theme.assetsUrl;
}, function (_ref) {
  var white = _ref.white;
  return white ? 'logo-white' : 'logo-color';
}, function (props) {
  return props.theme.mediaQueries.ipad;
});