"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Wrapper = exports.Title = exports.Separator = exports.FlexWrapper = exports.ConfiguratoreWrapper = exports.CarouselCaretRight = exports.CarouselCaretLeft = exports.CarouselButton = exports.Carousel = exports.CardWrapper = exports.CardName = exports.Card = exports.ButtonWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var Wrapper = exports.Wrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-9alow5-0"
})(["\n  height: 129px;\n  width: 280px;\n  border-radius: 16px;\n  position: relative;\n  margin-bottom: 30px;\n  margin-top: -96px;\n  margin-left: auto;\n  margin-right: auto;\n  z-index: 10;\n\n  ", " {\n    max-width: 1110px;\n    width: 100%;\n    height: 112px;\n    margin-top: -56px;\n    border-radius: 32px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var ConfiguratoreWrapper = exports.ConfiguratoreWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__ConfiguratoreWrapper",
  componentId: "sc-9alow5-1"
})(["\n  height: 100%;\n  padding: 7px 0;\n  border-radius: 16px;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  box-shadow: inset 0 0 7px 0 rgba(0, 0, 0, 0.29);\n  width: 100%;\n  background-color: ", ";\n  color: ", ";\n  font-family: ", ";\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.5;\n  letter-spacing: normal;\n  gap: 11px;\n\n  ", " {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    padding: 0 44px;\n    gap: 24px;\n    border-radius: 32px;\n    box-shadow: 0 10px 50px 0 rgba(81, 129, 186, 0.32),\n      inset 0 0 7px 0 rgba(0, 0, 0, 0.2);\n  }\n\n  ", "\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.isSticky && (0, _styledComponents.css)(["\n      ", " {\n        position: fixed;\n        bottom: 0;\n        left: 0;\n        right: 0;\n        width: 100%;\n        height: 60px;\n        max-width: 1140px;\n        margin-left: auto;\n        margin-right: auto;\n        border-radius: 0;\n        box-shadow: none;\n\n        &::after {\n          content: '';\n          position: fixed;\n          width: 300%;\n          height: 60px;\n          background-color: ", ";\n          bottom: 0;\n          left: 0;\n          z-index: -1;\n          box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);\n        }\n\n        ", " {\n          max-width: unset;\n          font-size: 22px;\n        }\n        ", " {\n          max-width: 270px;\n        }\n      }\n    "], function (props) {
    return props.theme.mediaQueries.tablet;
  }, function (props) {
    return props.theme.color.white;
  }, Title, ButtonWrapper);
});
var Title = exports.Title = _styledComponents.default.h3.withConfig({
  displayName: "styles__Title",
  componentId: "sc-9alow5-2"
})(["\n  margin: 0;\n  font-family: ", ";\n  font-size: 12px;\n  line-height: 1.5;\n  font-weight: normal;\n  letter-spacing: normal;\n  color: ", ";\n\n  ", " {\n    max-width: 170px;\n    font-size: 24px;\n    line-height: 1.33;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var FlexWrapper = exports.FlexWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__FlexWrapper",
  componentId: "sc-9alow5-3"
})(["\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  gap: 24px;\n  ", " {\n    width: 100%;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var CardWrapper = exports.CardWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__CardWrapper",
  componentId: "sc-9alow5-4"
})(["\n  width: min-content;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n"]);
var Card = exports.Card = _styledComponents.default.div.withConfig({
  displayName: "styles__Card",
  componentId: "sc-9alow5-5"
})(["\n  width: 64px;\n  height: 64px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 2px;\n  border-radius: 8px;\n  transform: scale(1);\n  font-size: 17px;\n  position: relative;\n  transition: all 0.7s cubic-bezier(0.2, 0.8, 0.2, 1);\n  cursor: pointer;\n  opacity: 1;\n\n  &:hover {\n    opacity: 0.4;\n  }\n\n  ", " \n  \n  ", "\n\n  ", " {\n    > ", " {\n      font-family: ", ";\n      font-size: 9px;\n    }\n\n    ", "\n  }\n\n  ", " {\n    width: 105px;\n    height: 124px;\n    gap: 6px;\n    border-radius: 16px;\n  }\n"], function (props) {
  if (props.disabled) {
    return (0, _styledComponents.css)(["\n        opacity: 0.4;\n        pointer-events: none;\n        cursor: auto;\n      "]);
  }
}, function (props) {
  if (props.active) {
    return (0, _styledComponents.css)(["\n        background: linear-gradient(137deg, #6ec18a 23%, #0e89ca 130%);\n        transform: scale(1.05);\n        &:hover {\n          opacity: 1;\n        }\n        > ", " {\n          color: ", ";\n        }\n\n        ", " {\n          padding: 0 4px;\n        }\n      "], CardName, function (props) {
      return props.theme.color.white;
    }, function (props) {
      return props.theme.mediaQueries.phone;
    });
  } else {
    return (0, _styledComponents.css)(["\n        > ", " {\n          color: ", ";\n        }\n      "], CardName, function (props) {
      return props.theme.color.darkGray;
    });
  }
}, function (props) {
  return props.theme.mediaQueries.phone;
}, CardName, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.active && (0, _styledComponents.css)(["\n        height: 64px;\n        width: 64px;\n      "]);
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var CardName = exports.CardName = _styledComponents.default.span.withConfig({
  displayName: "styles__CardName",
  componentId: "sc-9alow5-6"
})(["\n  font-family: ", ";\n  font-size: 12px;\n  font-weight: 600;\n  text-transform: uppercase;\n  text-align: center;\n  color: ", ";\n\n  ", " {\n    font-size: 9px;\n    display: ", ";\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  return props.hide ? 'none' : 'block';
});
var Separator = exports.Separator = _styledComponents.default.div.withConfig({
  displayName: "styles__Separator",
  componentId: "sc-9alow5-7"
})(["\n  display: inline;\n  width: 1px;\n  height: 80px;\n  opacity: 0.8;\n  border-style: solid;\n  border-width: 0px;\n  border-image-source: linear-gradient(\n    to bottom,\n    rgba(255, 255, 255, 0),\n    #586777 48%,\n    rgba(255, 255, 255, 0)\n  );\n  border-image-slice: 1;\n  border-left: 1px solid;\n  opacity: ", ";\n\n  ", " {\n    height: 44px;\n    opacity: ", ";\n  }\n"], function (props) {
  return props.active ? '0' : '0.8';
}, function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  return props.active ? '0' : '0.6';
});
var ButtonWrapper = exports.ButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__ButtonWrapper",
  componentId: "sc-9alow5-8"
})(["\n  max-width: 140px;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  ", " {\n    /* position: absolute;\n    bottom: -50%;\n    left: 50%;\n    transform: translate(-50%, -50%); */\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var Carousel = exports.Carousel = _styledComponents.default.div.withConfig({
  displayName: "styles__Carousel",
  componentId: "sc-9alow5-9"
})(["\n  display: flex;\n  overflow-x: scroll;\n  scroll-snap-type: x mandatory;\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n\n  & > * {\n    flex: 0 0 280px;\n    width: 280px;\n    scroll-snap-align: center;\n  }\n"]);
var CarouselButton = exports.CarouselButton = (0, _styledComponents.default)('button').withConfig({
  displayName: "styles__CarouselButton",
  componentId: "sc-9alow5-10"
})(["\n  width: 40px;\n  height: 40px;\n  padding: 12px;\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  transition: transform 0.1s ease-in-out;\n  background: white;\n  border-radius: 50%;\n  border: none;\n  z-index: 1;\n  cursor: pointer;\n\n  > div {\n    height: 100%;\n    width: 100%;\n  }\n"]);
var CarouselCaretLeft = exports.CarouselCaretLeft = (0, _styledComponents.default)(CarouselButton).withConfig({
  displayName: "styles__CarouselCaretLeft",
  componentId: "sc-9alow5-11"
})(["\n  left: -10px;\n  visibility: ", ";\n  > div {\n    transform: rotate(180deg);\n  }\n"], function (_ref) {
  var hasItemsOnLeft = _ref.hasItemsOnLeft;
  return hasItemsOnLeft ? "all" : "hidden";
});
var CarouselCaretRight = exports.CarouselCaretRight = (0, _styledComponents.default)(CarouselButton).withConfig({
  displayName: "styles__CarouselCaretRight",
  componentId: "sc-9alow5-12"
})(["\n  right: -10px;\n  visibility: ", ";\n"], function (_ref2) {
  var hasItemsOnRight = _ref2.hasItemsOnRight;
  return hasItemsOnRight ? "all" : "hidden";
});