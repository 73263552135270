import {compose} from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import {withWrapper, withPadder, AccordionFaqList} from '@alkemy/components'

export default compose(
  withPageBuilder({
    niceName: 'FAQ List',
  }),
  withFields([
    {
      prop: 'wrapped',
      type: 'boolean',
      label: 'Contenuto Boxed',
    },
    {
      prop: 'paddingLeftRight',
      label: 'Larghezza Componente',
      type: 'multiple',
      options: ['200:Small', '170:Medium', '100:Large', '0:Nessuno'],
    },
    {
      prop: 'paddingTop',
      label: 'Distanza dal Top',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'paddingBottom',
      label: 'Distanza dal Bottom',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'faqs',
      type: 'repeater',
      label: 'FAQ',
      model: [
        {
          prop: 'question',
          label: 'Domanda',
        },
        {
          prop: 'answer',
          label: 'Risposta',
          type: 'WYSIWYG',
        },
      ],
    },
  ]),
  withWrapper,
  withPadder,
)(AccordionFaqList)
