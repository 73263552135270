import { compose } from "recompose"
import withPageBuilder from "@alkemy/pagebuilder/lib/hoc/withPageBuilder"
import withFields from "@alkemy/pagebuilder/lib/hoc/withFields"

import { withWrapper, MultipleLaunchBlock } from "@alkemy/components"

export default compose(
  withPageBuilder({
    niceName: "Griglia Anteprime",
  }),
  withFields([
    {
      prop: "wrapped",
      type: "boolean",
      label: "Contenuto Boxed",
    },
    {
      prop: "blocks",
      label: "Launch Blocks",
      type: "repeater",
      model: [
        {
          prop: "full",
          type: "boolean",
          label: "Blocco FullWith",
        },
        {
          prop: "title",
          label: "Titolo",
        },
        {
          prop: "text",
          type: "WYSIWYG",
          label: "Testo",
        },
        {
          prop: "img",
          label: "Immagine",
        },
        {
          prop: "ctaLink",
          label: "Link",
        },
      ],
    },
  ]),
  withWrapper
)(MultipleLaunchBlock)
