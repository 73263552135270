import React from 'react'
import PropTypes from 'prop-types'

export default (ComposedComponent) => {
  return class WithIntl extends React.Component {
    static async getInitialProps(ctx) {
      const { query } = ctx
      const { country, locale } = query

      let composedInitialProps = {}
      if (ComposedComponent.getInitialProps) {
        composedInitialProps = await ComposedComponent.getInitialProps(ctx)
      }

      return {
        country,
        locale,
        ...composedInitialProps,
      }
    }

    render() {
      const { locale } = this.props

      return (
        <ComposedComponent {...this.props} />
      )
    }
  }
}
