import { compose } from "recompose"
import withPageBuilder from "@alkemy/pagebuilder/lib/hoc/withPageBuilder"
import withFields from "@alkemy/pagebuilder/lib/hoc/withFields"
import ListOfNewsEventsCards from "../components/ListOfNewsEventsCards"
import { withWrapper } from "@alkemy/components"

export default compose(
  withPageBuilder({
    niceName: "News e Eventi",
  }),
  withFields([
    {
      prop: "wrapped",
      type: "boolean",
      label: "Contenuto Boxed",
    },
  ]),
  withWrapper
)(() => <ListOfNewsEventsCards />)
