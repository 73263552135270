"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledModalOverlay = exports.StyledLogo = exports.StyledHeader = exports.StyledContent = exports.StyledClose = exports.StyledBody = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledModalOverlay = exports.StyledModalOverlay = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledModalOverlay",
  componentId: "sc-tk05tb-0"
})(["\n    position: ", ";\n    top: ", ";\n    left: ", ";\n    right: ", ";\n    bottom: ", ";\n    \n    z-index: 999999;\n    \n    opacity: ", ";\n    \n    background-image: linear-gradient(203deg, #7e2889, #1587c8);\n    \n    overflow: hidden;\n    \n    display: flex;\n    flex-wrap: wrap;\n    flex-direction: column;\n    \n    transition: all .8s;\n"], function (props) {
  return props.open ? 'fixed' : 'fixed';
}, function (props) {
  return props.open ? '0' : '0';
}, function (props) {
  return props.open ? '0' : '0';
}, function (props) {
  return props.open ? '0' : '50%';
}, function (props) {
  return props.open ? '0' : '100%';
}, function (props) {
  return props.open ? '1' : '0';
});
var StyledHeader = exports.StyledHeader = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledHeader",
  componentId: "sc-tk05tb-1"
})(["\n    display: flex;\n    justify-content: space-between;\n    padding: 0 15px;\n    \n    width: 100%;\n"]);
var StyledLogo = exports.StyledLogo = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledLogo",
  componentId: "sc-tk05tb-2"
})(["\n    align-self: flex-start;\n    width: 90px;\n    \n    padding-top: 8px;\n    \n    >div, >img {\n        width: 100%;\n        height: 40px;  \n    }\n    \n    a {\n      width: 100%;\n    }\n"]);
var StyledClose = exports.StyledClose = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledClose",
  componentId: "sc-tk05tb-3"
})(["\n    position: absolute;\n    top: 15px;\n    right: 15px;\n    width: 34px;\n    height: 34px;\n    \n    cursor: pointer;\n    \n    > img {\n        width: 100%;   \n    }\n"]);
var StyledBody = exports.StyledBody = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBody",
  componentId: "sc-tk05tb-4"
})(["\n    display: flex;\n    flex-grow: 1;\n"]);
var StyledContent = exports.StyledContent = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContent",
  componentId: "sc-tk05tb-5"
})(["\n    align-self: center;\n    padding: 40px;\n    \n    width: 100%;\n    \n    overflow-y: auto;\n    \n    display: flex;\n    justify-content: center;\n"]);