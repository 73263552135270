"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledOffersFormTargets = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledOffersFormTargets = exports.StyledOffersFormTargets = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledOffersFormTargets",
  componentId: "sc-iockhk-0"
})(["\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-evenly;\n"]);