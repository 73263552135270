"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledVivipediaLaunchList = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledVivipediaLaunchList = exports.StyledVivipediaLaunchList = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledVivipediaLaunchList",
  componentId: "sc-1tz3ppt-0"
})(["\n  margin: 60px 0 20px;\n  display: flex;\n  flex-direction: column;\n  \n  @media (min-width: 768px) {\n    margin: 120px 0 100px;\n    flex-direction: row;\n    justify-content: center;\n  } \n  \n  @media (min-width: 1440px) {\n    margin: 120px 0 160px;\n  }\n"]);