export const withStartGradientColors = () => ({
  prop: 'startColor',
  label: 'Colore di partenza',
  type: 'multiple',
  options: [
    '#F49593:Rosa',
    '#F7BF28:Giallo',
    '#E81C69:Fucsia',
    '#F09724:Arancione',
    '#1587C8:Blu',
    '#7E2889:Viola',
    '#6EC18A:Verde',
  ],
  initialValue: '#F49593',
})

export const withStopGradientColors = () => ({
  prop: 'stopColor',
  label: 'Colore di arrivo',
  type: 'multiple',
  options: [
    '#F49593:Rosa',
    '#F7BF28:Giallo',
    '#E81C69:Fucsia',
    '#F09724:Arancione',
    '#1587C8:Blu',
    '#7E2889:Viola',
    '#6EC18A:Verde',
  ],
  initialValue: '#F49593',
})
