"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitle = exports.StyledSectionHighlights = exports.StyledIcon = exports.StyledHighlightsWrapper = exports.StyledHighlight = exports.StyledFlexRow = exports.StyledContent = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledSectionHighlights = exports.StyledSectionHighlights = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSectionHighlights",
  componentId: "sc-hcdrln-0"
})(["\n  padding: 0 10px;\n"]);
var StyledFlexRow = exports.StyledFlexRow = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledFlexRow",
  componentId: "sc-hcdrln-1"
})(["\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n  flex-wrap: wrap;\n\n  flex-direction: row;\n"]);
var StyledHighlightsWrapper = exports.StyledHighlightsWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledHighlightsWrapper",
  componentId: "sc-hcdrln-2"
})(["\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n  flex-wrap: wrap;\n\n  margin-left: 0px;\n  margin-right: 0px;\n  ", "\n  flex-direction: column;\n\n  @media (min-width: 768px) {\n    flex-direction: row;\n  }\n"], function (props) {
  return props.cols === '1' && (0, _styledComponents.css)(["\n      margin: 0;\n    "]);
});
var StyledHighlight = exports.StyledHighlight = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledHighlight",
  componentId: "sc-hcdrln-3"
})(["\n    position: relative;\n    flex-grow: 0;\n    flex-basis: 100%;\n    width: 100%;\n    padding: ", ";\n    \n    ", "\n    \n    \n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-wrap: wrap;\n    flex-wrap: wrap;\n    \n    flex-direction: column;\n    \n    @media (max-width: 600px) {\n        padding:0 20px;\n    }\n    ", " {\n      ", "\n      \n      padding: 0px 0px;\n      ", "\n      ", "\n      ", "\n    } \n    \n    ", " {\n        ", "\n    }\n"], function (props) {
  return props.refund ? '20px 0!important' : '20px 67.5px';
}, function (props) {
  return props.refund && (0, _styledComponents.css)(["\n        text-align: center !important;\n      "]);
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return !props.slider && "\n          flex-grow: 1;\n          flex-basis: 50%;\n          max-width: 50%;\n        ";
}, function (props) {
  return props.cols === '1' && (0, _styledComponents.css)(["\n          padding: 0 20px;\n        "]);
}, function (props) {
  return !props.topIcon && (0, _styledComponents.css)(["\n          flex-direction: row;\n        "]);
}, function (props) {
  return props.cols && !props.slider && (0, _styledComponents.css)(["\n          flex-basis: calc(100% / ", ");\n          max-width: calc(100% / ", ");\n        "], function (props) {
    return props.cols;
  }, function (props) {
    return props.cols;
  });
}, function (props) {
  return props.theme.mediaQueries.desktop;
}, function (props) {
  return !props.topIcon && (0, _styledComponents.css)(["\n            flex-direction: row;\n            padding: 60.5px 44px 25.5px;\n            ", "\n          "], function (props) {
    return props.cols === '1' && (0, _styledComponents.css)(["\n                padding: 0px 0px 25.5px;\n              "]);
  });
});
var StyledIcon = exports.StyledIcon = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIcon",
  componentId: "sc-hcdrln-4"
})(["\n  align-self: center;\n  width: 80px;\n  margin-bottom: 12px;\n  svg {\n    width: 100%;\n    height: 100%;\n  }\n  ", " {\n    width: 135px;\n  }\n\n  ", " {\n    align-self: ", ";\n\n    margin-right: 35px;\n\n    flex-grow: 0;\n    flex-basis: 135px;\n  }\n\n  ", "\n\n  ", "\n  height: auto;\n\n  justify-content: center;\n  justify-items: center;\n  align-items: center;\n  align-content: center;\n  display: flex;\n  ", "\n\n  ", "\n\n  > img {\n    width: 100%;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.desktop;
}, function (props) {
  return props.center ? 'center' : 'flex-start';
}, function (props) {
  return props.smallIcon && (0, _styledComponents.css)(["\n      width: 65px;\n      height: 65px;\n      flex-basis: 65px;\n      justify-content: flex-start;\n      max-height: 65px;\n      > div {\n        height: 100%;\n      }\n      ", " {\n        width: 65px;\n        height: 65px;\n        max-height: 65px;\n        flex-basis: 65px;\n      }\n      ", " {\n        width: 65px;\n        height: 65px;\n        max-height: 65px;\n        flex-basis: 65px;\n      }\n    "], function (props) {
    return props.theme.mediaQueries.tablet;
  }, function (props) {
    return props.theme.mediaQueries.desktop;
  });
}, function (props) {
  return props.topIcon && (0, _styledComponents.css)(["\n      ", " {\n        margin-right: 0;\n      }\n    "], function (props) {
    return props.theme.mediaQueries.desktop;
  });
}, function (props) {
  return props.cols === '1' && (0, _styledComponents.css)(["\n      margin: 0px !important;\n    "]);
}, function (props) {
  return props.slider && "\n      margin: 0 auto !important;\n    ";
});
var StyledContent = exports.StyledContent = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContent",
  componentId: "sc-hcdrln-5"
})(["\n  display: flex;\n  flex-flow: column;\n  align-content: center;\n  justify-content: flex-start;\n  justify-items: center;\n  padding: 20px;\n\n  ", " {\n    flex: 1;\n  }\n  ", "\n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.cols === '1' && (0, _styledComponents.css)(["\n      width: 100%;\n      text-align: ", ";\n      @media (max-width: 767px) {\n        > div {\n          text-align: center;\n        }\n      }\n    "], function (props) {
    return props.center ? 'center' : 'left';
  });
});
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-hcdrln-6"
})(["\n  font-size: 16px;\n  font-family: ", ";\n  color: ", ";\n  text-align: ", ";\n  text-transform: ", ";\n  font-weight: 400;\n  margin: 12px 0 20px;\n\n  ", " {\n    text-align: ", ";\n    font-size: 20px;\n  }\n\n  ", " {\n    text-align: ", ";\n    margin: 7px 0;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.orange;
}, function (props) {
  return props.center ? 'center' : 'left';
}, function (props) {
  return props.uppercase ? 'uppercase' : 'none';
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.center ? 'center' : 'left';
}, function (props) {
  return props.theme.mediaQueries.desktop;
}, function (props) {
  return props.center ? 'center' : 'left';
});