"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Wrapper = exports.TitleWrapper = exports.Title = exports.InnerWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var Wrapper = exports.Wrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-1ek6pp8-0"
})(["\n  background-image: linear-gradient(\n    to bottom,\n    #fff,\n    #fff 61%,\n    #fff 27%,\n    #f2f2f2 120%\n  );\n  margin-top: 50px;\n  width: 100%;\n  padding-bottom: 75px;\n\n  ", " {\n    margin-top: 0;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var InnerWrapper = exports.InnerWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__InnerWrapper",
  componentId: "sc-1ek6pp8-1"
})(["\n  max-width: 1130px;\n  width: 100%;\n  margin: 0 auto;\n  font-family: ", ";\n  color: ", ";\n  position: relative;\n  margin: 0 auto;\n\n  ", " {\n    width: 90%;\n  }\n"], function (props) {
  return props.theme.font.light;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var TitleWrapper = exports.TitleWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__TitleWrapper",
  componentId: "sc-1ek6pp8-2"
})(["\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  ", " {\n    margin-bottom: 20px;\n  }\n\n  &:after {\n    content: '';\n    width: 100%;\n    height: 1px;\n    border-top: 1px solid #cbcbcb;\n    position: absolute;\n    top: 50%;\n    left: 0;\n    z-index: 1;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var Title = exports.Title = _styledComponents.default.h2.withConfig({
  displayName: "styles__Title",
  componentId: "sc-1ek6pp8-3"
})(["\n  text-align: center;\n  z-index: 9;\n  white-space: nowrap;\n  padding: 0 20px;\n  ", "\n  font-size: 24px;\n\n  ", " {\n    font-size: 18px;\n    padding: 0 5px;\n  }\n"], function (props) {
  return props.titleBackground && "\n    background-color: ".concat(props.titleBackground, ";\n  ");
}, function (props) {
  return props.theme.mediaQueries.phone;
});