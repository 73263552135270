"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitle = exports.StyledSectionTitle = exports.StyledSectionPhotoText = exports.StyledImageWrapper = exports.StyledContentWrapper = exports.StyledButtonWrapper = exports.StyledBlobWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledSectionPhotoText = exports.StyledSectionPhotoText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSectionPhotoText",
  componentId: "sc-xsd5ux-0"
})(["\n  display: flex;\n  position: relative;\n  flex-wrap: wrap;\n  flex-direction: column;\n  padding-top: 80px;\n  -webkit-font-smoothing: antialiased;\n  \n  ", " {\n      flex-direction: row;\n      padding-bottom: 140px;\n      margin-top: 120px;\n  }\n  ", " {\n      padding-bottom: 160px;\n      margin-top: 150px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var StyledBlobWrapper = exports.StyledBlobWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBlobWrapper",
  componentId: "sc-xsd5ux-1"
})(["\n    position: absolute;\n    display: none;\n    width: 405px;\n    height: 460px;\n    z-index: -1;\n    top: 0;\n    left: 50%;\n    transform: translate(-50%, -10%);\n    \n    ", " {\n      display: block\n    }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledImageWrapper = exports.StyledImageWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledImageWrapper",
  componentId: "sc-xsd5ux-2"
})(["\n    width: 100%;\n    flex-basis: 100%;\n    \n    height: 240px;\n    overflow:hidden;\n    \n    border-radius: 0;\n    \n    background-size: auto 100%;\n    background-position: left top;\n    background-repeat: norepeat;\n    background-image: url('", "');\n    \n    ", " {\n        border-radius: 20px;\n        width: 50%;\n        flex-basis: 50%;\n        height: 280px;   \n    }\n    ", " {\n        width: 66%;\n        flex-basis: 66%;\n        height: 320px;\n    }   \n"], function (props) {
  return props.src;
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var StyledContentWrapper = exports.StyledContentWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContentWrapper",
  componentId: "sc-xsd5ux-3"
})(["\n    width: 100%;\n    flex-basis: 100%;\n    padding: 40px 10px;\n    \n    ", " {\n        width: 50%;\n        flex-basis: 50%;\n        height: 280px;  \n        \n        padding: 10px 0 0 50px;\n    }\n    ", " {\n        width: 34%;\n        flex-basis: 34%;\n        height: 320px;\n        \n        padding: 40px 0 0 60px;\n    }\n    \n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-xsd5ux-4"
})(["\n  color: ", ";\n  font-family: ", ";  \n  font-size:  18px;\n  font-weight: 500; \n  font-style: normal;\n  font-stretch: normal;\n  letter-spacing: normal;\n  text-align: center;\n  text-transform: uppercase;\n\n  ", "{   \n     text-align: left;\n  }  \n  \n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-xsd5ux-5"
})(["\n  flex-basis: 100%;\n\n  > a {\n    width: 300px;\n    margin: 60px auto 0;\n    padding: 0 25px;\n    background: white;\n    border: 1px solid ", ";\n    color: ", ";\n    justify-content: space-between;\n    text-align: left;\n    \n    svg {\n      width: 20px;\n      height: 20px;\n    }\n    \n    path {\n      fill: ", ";\n    }\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledSectionTitle = exports.StyledSectionTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSectionTitle",
  componentId: "sc-xsd5ux-6"
})(["\n  flex-basis: 100%;\n  text-align: center;\n  margin-bottom: 30px;\n  font-family: ", ";\n  color: ", ";\n  font-size: 22px;\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.white;
});