"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTeaser = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledTeaser = exports.StyledTeaser = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTeaser",
  componentId: "sc-1v1x7cy-0"
})(["\n  font-family: ", ";\n"], function (_ref) {
  var theme = _ref.theme;
  return theme.font.boldItalic;
});