import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'
import ListOfNewsEventsCardsHome from '../components/ListOfNewsEventsCardsHome'
import { withWrapper } from '@alkemy/components'

export default compose(
  withPageBuilder({
    niceName: 'News e Eventi Home',
  }),
  withFields([
    {
      prop: 'wrapped',
      type: 'boolean',
      label: 'Contenuto Boxed',
    },
  ]),
  withWrapper,
)(() => <ListOfNewsEventsCardsHome />)
