import React, {Component} from 'react'
import {connect} from 'react-redux'
import * as actionsSearchModal from '../redux/searchModal/actions'
import {getSearchModalStatus} from '../redux/searchModal/reducer'
import {SearchModal} from '@alkemy/components'
import styled from 'styled-components'
import {Icons} from '@alkemy/components'

class WithSearchModal extends Component {
  render() {
    const {isVisible, closeSearchModal} = this.props

    return (
      <React.Fragment>
        {isVisible && (
          <FullScreen>
            <CloseWrapper onClick={closeSearchModal}>
              <Icons icon="Cross" color="#FFF" />
            </CloseWrapper>
            <SearchModal measures={{headerHeight: 0}} isFullScreen />
          </FullScreen>
        )}
      </React.Fragment>
    )
  }
}
const CloseWrapper = styled.div`
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 999;
  cursor: pointer;
`
const FullScreen = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
`
const mapStateToProps = state => ({
  isVisible: getSearchModalStatus(state) === 'OPEN',
})

export default connect(
  mapStateToProps,
  actionsSearchModal,
)(WithSearchModal)
