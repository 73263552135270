"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledPrevArrow = exports.StyledNextArrow = exports.StyledIconWrapper = exports.StyledEditorialSliderItem = exports.StyledEditorialSlider = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledEditorialSlider = exports.StyledEditorialSlider = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledEditorialSlider",
  componentId: "sc-l6zdvm-0"
})(["\n  max-width: 770px;\n  margin: 0 auto 60px;\n  position: relative;\n\n  .slick-dots {\n    bottom: -42px;\n\n    @media (min-width: 768px) {\n      bottom: -48px;\n    }\n\n    @media (min-width: 1440px) {\n      bottom: -52px;\n    }\n\n    li {\n      width: 12px;\n      height: 12px;\n      margin: 0;\n      border-radius: 50%;\n      border: 2px solid ", ";\n      position: relative;\n\n      &:before {\n        content: '';\n        width: 100%;\n        height: 100%;\n        position: absolute;\n        top: 50%;\n        left: 50%;\n        background-color: ", ";\n        transition: all 0.35s ease-in-out 0s;\n        transform: translate(-50%, -50%) scale(0);\n        border-radius: 50%;\n      }\n\n      &.slick-active {\n        &:before {\n          transform: translate(-50%, -50%) scale(1);\n        }\n      }\n\n      &:not(:last-child) {\n        margin-right: 5px;\n\n        @media (min-width: 768px) {\n          margin-right: 8px;\n        }\n      }\n    }\n\n    button {\n      display: none;\n    }\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledEditorialSliderItem = exports.StyledEditorialSliderItem = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledEditorialSliderItem",
  componentId: "sc-l6zdvm-1"
})(["\n  padding-bottom: 53.47%;\n  background-image: url(", ");\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n"], function (props) {
  return props.imgUrl + '?width=1440&auto=format';
});
var StyledPrevArrow = exports.StyledPrevArrow = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledPrevArrow",
  componentId: "sc-l6zdvm-2"
})(["\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  left: -75px;\n  cursor: pointer;\n"]);
var StyledNextArrow = exports.StyledNextArrow = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledNextArrow",
  componentId: "sc-l6zdvm-3"
})(["\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  right: -75px;\n  cursor: pointer;\n"]);
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-l6zdvm-4"
})(["\n  width: 55px;\n  height: 55px;\n"]);