"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledIconBg = exports.StyledExitPop = exports.StyledClose = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledExitPop = exports.StyledExitPop = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledExitPop",
  componentId: "sc-457sl8-0"
})(["\n  position: fixed;\n  bottom: 16px;\n  right: 20px;\n  max-width: 390px;\n  min-width: 280px;\n  width: calc(100% - 40px);\n  min-height: 130px;\n  padding: 24px;\n  padding-right: 48px;\n\n  background-image: linear-gradient(202deg, #73889c, #3f5063);\n  border-radius: 35px;\n  box-shadow: 0 0 50px 0 rgba(81, 129, 186, 0.25);\n\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  line-height: 1.5;\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  gap: 12px;\n\n  z-index: 999999;\n  overflow: hidden;\n\n  transform: translateY(calc(100% + 16px));\n  transition: all 0.2s ease-in;\n  ", "\n\n  ", " {\n    gap: 8px;\n    min-height: 216px;\n  }\n\n  .title {\n    font-family: ", ";\n    text-transform: uppercase;\n  }\n  .description {\n    ", " {\n      display: none;\n    }\n  }\n  .link {\n    color: ", ";\n    text-decoration: underline;\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.open && (0, _styledComponents.css)(["\n      transform: translateY(0);\n    "]);
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.font.bold;
}, function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  return props.theme.color.white;
});
var StyledClose = exports.StyledClose = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledClose",
  componentId: "sc-457sl8-1"
})(["\n  position: absolute;\n  right: 0;\n  top: 0;\n  width: 69px;\n  height: 66px;\n  background-image: linear-gradient(138deg, #f49593, #f7bf28);\n  border-bottom-left-radius: 100%;\n  cursor: pointer;\n\n  > div {\n    width: 16px;\n    height: 16px;\n    position: absolute;\n    right: 20px;\n    top: 20px;\n  }\n"]);
var StyledIconBg = exports.StyledIconBg = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconBg",
  componentId: "sc-457sl8-2"
})(["\n  position: absolute;\n  right: 10px;\n  bottom: -15px;\n  width: 64px;\n  height: 64px;\n  opacity: 0.2;\n\n  ", " {\n    right: 0;\n    bottom: -18px;\n    width: 108px;\n    height: 108px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});