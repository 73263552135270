"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WrapperAppStore = exports.StyledStoreLogo = exports.StyledLaunchBlock = exports.StyledContentWrapper = exports.StyledButtonWrapper = exports.SectionWrapper = exports.SectionFullBack = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _styles = require("../SectionTitle/styles");
var _styles2 = require("../shared/styles");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var SectionFullBack = exports.SectionFullBack = (0, _styledComponents.default)(_styles2.WrapperFullBack).withConfig({
  displayName: "styles__SectionFullBack",
  componentId: "sc-15b92bk-0"
})(["\n  background-image: url('https://vivienergia.imgix.net/uploads/2021/05/18110738/section_download_app_mobile.png?width=1440&auto=format');\n  background-color: transparent;\n  background-position: center 0;\n  background-repeat: no-repeat;\n  background-size: contain;\n\n  ", " {\n    background-image: url('https://vivienergia.imgix.net/uploads/2021/05/18105540/section_download_app.png?width=1440&auto=format');\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var SectionWrapper = exports.SectionWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__SectionWrapper",
  componentId: "sc-15b92bk-1"
})(["\n  & > * {\n    color: white;\n    width: 100%;\n    margin-bottom: 20px;\n  }\n\n  p {\n    font-size: ", ";\n  }\n\n  display: flex;\n  flex-direction: column;\n\n  height: calc(100vw * 2.14 + 200px);\n  padding: 80px 10px 20px 10px;\n  margin-bottom: 20px;\n\n  ", " {\n    padding: 0;\n    margin-left: 50%;\n\n    & > * {\n      color: white;\n      width: 300px;\n      margin-bottom: 20px;\n    }\n\n    padding-top: 180px;\n    margin-bottom: 40px;\n    height: 600px;\n  }\n\n  ", " {\n    & > * {\n      color: white;\n      width: 400px;\n      margin-bottom: 20px;\n    }\n\n    padding-top: ", ";\n    margin-bottom: 40px;\n    height: 600px;\n  }\n\n  ", " {\n    padding: 40px 10px 20px 10px;\n  }\n"], function (props) {
  return props.customFontSize ? props.customFontSize + 'px' : '14px';
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.desktop;
}, function (props) {
  return props.customFontSize ? '120px' : '180px';
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-15b92bk-2"
})(["\n  width: ", ";\n  position: absolute;\n  left: 50%;\n  transform: translateX(-50%);\n  bottom: -44px;\n\n  @media (min-width: 768px) {\n    bottom: -38px;\n  }\n\n  @media (min-width: 1440px) {\n    bottom: -20px;\n  }\n\n  ", "\n\n  button {\n    font-family: ", ";\n    font-size: 16px;\n    font-weight: normal;\n    font-style: normal;\n    font-stretch: normal;\n    line-height: 42px;\n    letter-spacing: normal;\n    text-align: center;\n\n    @media (min-width: 1440px) {\n    }\n  }\n"], function (props) {
  return props.fluid ? 'calc(100% - 70px)' : '240px';
}, function (props) {
  return props.ctaWidth && (0, _styledComponents.css)(["\n      width: ", ";\n    "], function (props) {
    return props.ctaWidth;
  });
}, function (props) {
  return props.theme.font.regular;
});
var StyledContentWrapper = exports.StyledContentWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContentWrapper",
  componentId: "sc-15b92bk-3"
})(["\n  width: 100%;\n\n  padding: 20px 32px;\n  position: relative;\n  -webkit-font-smoothing: antialiased;\n  background-color: ", ";\n\n  height: auto;\n  padding-bottom: 40px;\n\n  border-radius: 35px;\n  border-radius: 35px;\n"], function (props) {
  return props.theme.color.white;
});
var StyledLaunchBlock = exports.StyledLaunchBlock = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledLaunchBlock",
  componentId: "sc-15b92bk-4"
})(["\n  width: 100%;\n  padding: 0 10px;\n  padding-bottom: 25px;\n  position: relative;\n\n  @media (min-width: 768px) {\n    height: 320px;\n    border-radius: 35px;\n    padding: 0 180px;\n  }\n\n  @media (min-width: 1440px) {\n    width: 100%;\n    height: 320px;\n    padding: 0 370px;\n  }\n\n  ", "\n"], function (props) {
  return props.fluid && (0, _styledComponents.css)(["\n      display: flex;\n      flex-direction: column;\n    "]);
});
var WrapperAppStore = exports.WrapperAppStore = _styledComponents.default.div.withConfig({
  displayName: "styles__WrapperAppStore",
  componentId: "sc-15b92bk-5"
})(["\n  display: flex;\n  margin-top: 110px;\n  margin-left: 80px;\n  // align-content:center;\n\n  ", " {\n    height: 100%;\n    justify-content: flex-end;\n\n    padding: 0 70px;\n    flex-direction: column;\n    margin: 0;\n    a {\n      width: 100%;\n      height: 50px;\n      &:first-child {\n        margin-bottom: 50px;\n      }\n    }\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var StyledStoreLogo = exports.StyledStoreLogo = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledStoreLogo",
  componentId: "sc-15b92bk-6"
})(["\ncursor:pointer;\nmargin: 0 auto;\n  position:relative;\n  width: ", "px;\n  height:  ", "px;\n  background-image: url('", "/images/common/", ".svg');\n  background-color: transparent;\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: contain;\n"], function (_ref) {
  var google = _ref.google;
  return google ? '142' : '123';
}, function (_ref2) {
  var google = _ref2.google;
  return google ? '42' : '41';
}, function (props) {
  return props.theme.assetsUrl;
}, function (_ref3) {
  var google = _ref3.google;
  return google ? 'g-store' : 'a-store';
});