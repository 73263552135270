"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Title = exports.MainWrapper = exports.DocumentiWrapper = exports.DisclaimersWrapper = exports.Disclaimer = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var MainWrapper = exports.MainWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__MainWrapper",
  componentId: "sc-1fufpvb-0"
})(["\n  max-width: 970px;\n  width: 90%;\n  margin: 80px auto 40px auto;\n\n  ", "{\n    padding: 0;\n    margin: 80px auto !important:\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var Title = exports.Title = _styledComponents.default.h2.withConfig({
  displayName: "styles__Title",
  componentId: "sc-1fufpvb-1"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 35px;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.36;\n  letter-spacing: normal;\n\n  ", " {\n    font-size: 22px;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var DocumentiWrapper = exports.DocumentiWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__DocumentiWrapper",
  componentId: "sc-1fufpvb-2"
})(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 23px;\n  margin: 40px 0 80px 0;\n  position: relative;\n\n  ", " {\n    margin-bottom: 58px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var DisclaimersWrapper = exports.DisclaimersWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__DisclaimersWrapper",
  componentId: "sc-1fufpvb-3"
})(["\n  font-size: 12px;\n  padding-top: 23px;\n  border-top: 1px solid ", ";\n"], function (props) {
  return props.theme.color.darkGray;
});
var Disclaimer = exports.Disclaimer = _styledComponents.default.p.withConfig({
  displayName: "styles__Disclaimer",
  componentId: "sc-1fufpvb-4"
})(["\n  font-family: ", ";\n  font-size: 12px;\n  color: ", ";\n  a {\n    color: ", " !important;\n    text-decoration: underline !important;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.color.magenta;
});