"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledUserActionsItem = exports.StyledUserActions = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledUserActions = exports.StyledUserActions = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledUserActions",
  componentId: "sc-sufq7h-0"
})(["\n  display: flex;\n  cursor: pointer;\n"]);
var StyledUserActionsItem = exports.StyledUserActionsItem = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledUserActionsItem",
  componentId: "sc-sufq7h-1"
})(["\n  position: relative;\n  z-index: 1;\n  overflow: visible;\n  cursor: pointer;\n  \n  &:last-child {\n    margin-left: 60px;\n    margin-right: 16px;\n    overflow: visible;\n    cursor: pointer;\n  }\n  \n  * {\n    overflow: visible !important;\n    cursor: pointer;\n  }\n  \n  > * {\n    ", "\n  }\n  \n  svg {\n    position: relative;\n    top: -20px;\n  }\n  \n  span {\n    display: block;\n    width: auto;\n    white-space: nowrap;\n    color: white;\n    position: absolute;\n    left: 50%;\n    bottom: -100px;\n    transform: translate(-50%, 0);\n    font-family: ", ";\n    font-size: 14px;\n  }\n  \n  > button {\n    border: none;\n  }\n"], function (_ref) {
  var isSticky = _ref.isSticky;
  return isSticky && (0, _styledComponents.css)(["\n      box-shadow: none;\n    "]);
}, function (props) {
  return props.theme.font.regular;
});