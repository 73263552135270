"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledWrapper = exports.StyledWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledWrapper",
  componentId: "sc-1g8r6to-0"
})(["\n  width: 100%;\n  margin: 0 auto;\n  padding: 0;\n  position: relative;\n  \n  ", "\n  \n  ", "\n  \n  \n  ", "\n    \n  ", "\n  \n  @media (min-width: 768px) {\n    max-width: 768px;\n    padding: 0 14px;\n  }\n  \n  @media (min-width: 1024px) {\n    max-width: 1024px;\n    padding: 0 135px;\n  }\n  \n  @media (min-width: 1440px) {\n    max-width: 1440px;\n    padding: 0 135px;\n  }\n  \n  ", "\n  \n  > canvas {\n    display: none;\n    position: absolute;\n    top: 50%;\n    left: 0;\n    transform: translate(-50%, -50%);\n    z-index: 0;\n    \n    ", " {\n      display: block;\n    }\n  }\n"], function (props) {
  return props.fixFormCasa && (0, _styledComponents.css)(["\n    max-width: 100% !important;\n    overflow: hidden !important;\n  "]);
}, function (props) {
  return props.contestPage && (0, _styledComponents.css)(["\n    \n    @media (min-width: 768px) {\n      max-width: 540px!important;\n      padding: 0!important;\n    }\n    \n    @media (min-width: 1440px) {\n      max-width: 100%!important;\n      padding: 0 335px!important;\n    }\n  "]);
}, function (props) {
  return props.refund && (0, _styledComponents.css)(["\n    \n    @media (min-width: 768px) {\n      max-width: 540px!important;\n      padding: 0!important;\n    }\n    \n    @media (min-width: 1440px) {\n      max-width: 1030px!important;\n      padding: 0!important;\n    }\n  "]);
}, function (props) {
  return props.projectPage && (0, _styledComponents.css)(["\n    max-width: 310px;\n    \n    @media (min-width: 768px) {\n      max-width: 540px!important;\n      padding: 0!important;\n    }\n    \n    @media (min-width: 1440px) {\n      max-width: 1170px!important;\n      padding: 0!important;\n    }\n  "]);
}, function (props) {
  return props.debug && (0, _styledComponents.css)(["\n    border: solid 1px red;\n    \n    & > * {\n        border-left: solid 1px green;\n        border-right: solid 1px green;\n     }\n  "]);
}, function (props) {
  return props.theme.mediaQueries.ipad;
});