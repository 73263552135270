import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { HeroPage, withWrapper } from '@alkemy/components'

export default compose(
  withPageBuilder({
    niceName: 'Hero Page',
  }),
  withFields([
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'images',
      label: 'Repeater Immagini',
      type: 'repeater',
      model: [
        {
          prop: 'image',
          label: 'Immagine',
        },
      ],
    },
  ]),
  withWrapper,
)(HeroPage)
