"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledVivipediaArticleBlock = exports.StyledTitle = exports.StyledText = exports.StyledImage = exports.StyledIconTypeWrapper = exports.StyledContentWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledVivipediaArticleBlock = exports.StyledVivipediaArticleBlock = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledVivipediaArticleBlock",
  componentId: "sc-1pgnseh-0"
})(["\n  position: relative;\n  width: 200px;\n  height: auto;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n  -webkit-font-smoothing: antialiased;\n  text-decoration: none;\n  background-color: ", ";\n\n  &:hover {\n    .image {\n      box-shadow: 0 0 50px 0 rgba(81, 129, 186, 0.34);\n    }\n  }\n\n  ", " {\n    width: 270px;\n    height: 330px;\n  }\n\n  ", " {\n    .slick-slide & {\n      transition: all 0.2s ease-in;\n    }\n    .slick-slide:not(.slick-active) & {\n      transform: scale(0.9);\n      opacity: 0.6;\n    }\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var StyledIconTypeWrapper = exports.StyledIconTypeWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconTypeWrapper",
  componentId: "sc-1pgnseh-1"
})(["\n  position: absolute;\n  left: 24px;\n  top: 0;\n  background-color: ", ";\n  width: 48px;\n  height: 42px;\n  margin-right: 0px;\n  border-bottom-left-radius: 28px;\n  border-bottom-right-radius: 28px;\n  > div {\n    height: 32px;\n  }\n  ", " {\n    width: 60px;\n    height: 53px;\n    > div {\n      height: 40px;\n    }\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledImage = exports.StyledImage = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledImage",
  componentId: "sc-1pgnseh-2"
})(["\n  width: 100%;\n  height: 120px;\n  background-image: url(", ");\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  border-radius: 24px;\n  transition: all 0.2s;\n\n  ", " {\n    height: 160px;\n    border-radius: 35px;\n  }\n"], function (props) {
  return props.imgUrl;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledContentWrapper = exports.StyledContentWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContentWrapper",
  componentId: "sc-1pgnseh-3"
})(["\n  padding: 20px 16px;\n\n  ", " {\n    width: 100%;\n    padding: 20px 24px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-1pgnseh-4"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 16px;\n  line-height: 1.5;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n\n  ", " {\n    font-size: 18px;\n  }\n"], function (props) {
  return props.theme.font.bold;
}, function (props) {
  return props.theme.color.magenta;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledText = exports.StyledText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledText",
  componentId: "sc-1pgnseh-5"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 14px;\n  line-height: 1.5;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});