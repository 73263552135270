import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'
import { FormChiamateSospette } from '@alkemy/components'
import { withPadder } from '@alkemy/components'

export default compose(
  withPageBuilder({
    niceName: 'Form Chiamate Sospette',
  }),
  withFields([
    {
      prop: 'title',
      label: 'Titolo Indicazioni',
    },
    {
      prop: 'content',
      label: 'Contenuto Indicazioni',
      type: 'WYSIWYG',
    },
    {
      prop: 'titleWarning',
      label: 'Titolo Avvertenze',
    },
    {
      prop: 'contentWarning',
      label: 'Contenuto Avvertenze',
      type: 'WYSIWYG',
    },
  ]),
  withPadder,
)(props => (
  <FormChiamateSospette
    {...props}
    validNumbers="3472538298"
    api={process.env.WP_API}
  />
))
