"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTriangle = exports.StyledTitle = exports.StyledText = exports.StyledSummaryWrapper = exports.StyledSummaryTitle = exports.StyledSummaryButtonWrapper = exports.StyledSummary = exports.StyledLoginWrapper = exports.StyledLoginCaption = exports.StyledImageWrapper = exports.StyledImage = exports.StyledFullTextWrapper = exports.StyledFullTextButtonWrapper = exports.StyledContentWrapper = exports.StyledAccordion = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledAccordion = exports.StyledAccordion = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledAccordion",
  componentId: "sc-1a7985j-0"
})(["\n  overflow: hidden;\n  margin: 40px auto;\n  box-shadow: 0 0 35px 0 ", ";\n  color: ", ";\n  -webkit-font-smoothing: antialiased;\n\n  @media (min-width: 768px) {\n    max-width: 740px;\n    border-radius: 35px;\n    margin: 0 auto 120px;\n  }\n\n  @media (min-width: 1440px) {\n    max-width: 1170px;\n  }\n"], function (props) {
  return props.theme.color.shadowBlue;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledImageWrapper = exports.StyledImageWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledImageWrapper",
  componentId: "sc-1a7985j-1"
})(["\n  width: 100%;\n  padding-bottom: 81.25%;\n  position: relative;\n  background-image: url(", ");\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n\n  @media (min-width: 768px) {\n    padding-bottom: 40.54%;\n    background-image: url(", ");\n  }\n\n  @media (min-width: 1440px) {\n    padding-bottom: 41.88%;\n    background-image: url(", ");\n  }\n"], function (props) {
  return props.img[0] + '?width=768&auto=format';
}, function (props) {
  return props.img[1] + '?width=1440&auto=format';
}, function (props) {
  return props.img[2] + '?width=1440&auto=format';
});
var StyledImage = exports.StyledImage = _styledComponents.default.img.withConfig({
  displayName: "styles__StyledImage",
  componentId: "sc-1a7985j-2"
})([""]);
var StyledTriangle = exports.StyledTriangle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTriangle",
  componentId: "sc-1a7985j-3"
})(["\n  width: 0;\n  height: 0;\n  border-style: solid;\n  border-width: 100px 0 0 1170px;\n  border-color: transparent transparent transparent\n    ", ";\n\n  position: absolute;\n  bottom: 0;\n  right: 0;\n\n  @media (min-width: 768px) {\n    border-width: 100px 0 0 1170px;\n  }\n\n  @media (min-width: 1440px) {\n    border-width: 100px 0 0 1170px;\n  }\n"], function (props) {
  return props.theme.color.white;
});
var StyledContentWrapper = exports.StyledContentWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContentWrapper",
  componentId: "sc-1a7985j-4"
})(["\n  padding: 10px;\n\n  @media (min-width: 768px) {\n    padding: 0 40px 40px;\n  }\n\n  @media (min-width: 1440px) {\n    padding: 0 80px 40px;\n  }\n"]);
var StyledFullTextWrapper = exports.StyledFullTextWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledFullTextWrapper",
  componentId: "sc-1a7985j-5"
})(["\n  overflow: hidden;\n  height: 50px;\n  margin-bottom: 30px;\n\n  @media (min-width: 768px) {\n    height: 170px;\n  }\n"]);
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-1a7985j-6"
})(["\n  text-align: center;\n  font-family: ", ";\n  font-size: 22px;\n  padding: 15px 0 30px;\n\n  @media (min-width: 768px) {\n    text-align: left;\n    padding: 0 0 45px;\n  }\n"], function (props) {
  return props.theme.font.medium;
});
var StyledText = exports.StyledText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledText",
  componentId: "sc-1a7985j-7"
})(["\n  font-family: ", ";\n  font-size: 16px;\n  line-height: 1.5;\n  padding-bottom: 60px;\n\n  > div {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: ", ";\n  }\n\n  strong {\n    font-family: ", ";\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.ellipsis ? 'nowrap' : 'initial';
}, function (props) {
  return props.theme.font.medium;
});
var StyledLoginWrapper = exports.StyledLoginWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledLoginWrapper",
  componentId: "sc-1a7985j-8"
})(["\n  padding-bottom: 10px;\n"]);
var StyledLoginCaption = exports.StyledLoginCaption = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledLoginCaption",
  componentId: "sc-1a7985j-9"
})(["\n  padding-bottom: 40px;\n  text-align: center;\n  font-family: ", ";\n  font-size: 22px;\n"], function (props) {
  return props.theme.font.medium;
});
var StyledFullTextButtonWrapper = exports.StyledFullTextButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledFullTextButtonWrapper",
  componentId: "sc-1a7985j-10"
})(["\n  margin: 0 auto;\n  width: 240px;\n\n  @media (min-width: 768px) {\n    width: 120px;\n  }\n\n  > a {\n    margin: 0 0 30px;\n    text-align: center;\n    line-height: 42px;\n\n    span {\n      font-size: 16px;\n    }\n  }\n"]);
var StyledSummaryTitle = exports.StyledSummaryTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSummaryTitle",
  componentId: "sc-1a7985j-11"
})(["\n  text-align: center;\n  font-family: ", ";\n  font-size: 22px;\n  padding: 15px 0;\n\n  @media (min-width: 768px) {\n    text-align: left;\n    padding: 0 0 45px;\n  }\n"], function (props) {
  return props.theme.font.medium;
});
var StyledSummary = exports.StyledSummary = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSummary",
  componentId: "sc-1a7985j-12"
})(["\n  display: none;\n  font-size: 16px;\n  font-family: ", ";\n\n  @media (min-width: 768px) {\n    display: block;\n    margin-bottom: 45px;\n  }\n"], function (props) {
  return props.theme.font.regular;
});
var StyledSummaryWrapper = exports.StyledSummaryWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSummaryWrapper",
  componentId: "sc-1a7985j-13"
})(["\n  ", "\n"], function (props) {
  return props.isOpen && (0, _styledComponents.css)(["\n      ", " {\n        display: none;\n      }\n\n      ", " {\n        display: none;\n      }\n    "], StyledSummaryTitle, StyledSummary);
});
var StyledSummaryButtonWrapper = exports.StyledSummaryButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSummaryButtonWrapper",
  componentId: "sc-1a7985j-14"
})(["\n  > button {\n    margin: 0 auto 15px;\n    padding: 0 25px;\n    background: white;\n    border: none;\n    color: ", ";\n    text-align: left;\n    box-shadow: none;\n    justify-content: center;\n    transition: all 0.2s ease-out 0s;\n    line-height: 42px;\n\n    @media (min-width: 768px) {\n      border: 1px solid ", ";\n      justify-content: space-between;\n      max-width: ", ";\n    }\n\n    &:after\xA0 {\n      display: none;\n    }\n\n    &:focus {\n    }\n\n    svg {\n      transition: transform 0.2s ease-in 0s;\n      transform: ", ";\n\n      path {\n        fill: ", ";\n      }\n    }\n\n    span {\n      display: none;\n\n      @media (min-width: 768px) {\n        display: block;\n        font-size: 16px;\n      }\n\n      & + div {\n        margin: 0;\n      }\n    }\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.isOpen ? '120px' : '180px';
}, function (props) {
  return props.isOpen ? 'rotate(-90deg)' : 'rotate(90deg)';
}, function (props) {
  return props.theme.color.darkBlue;
});