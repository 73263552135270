"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledServiceInfoWrapper = exports.StyledSalesPointList = exports.StyledRegionWrapper = exports.StyledItem = exports.StyledIconWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledSalesPointList = exports.StyledSalesPointList = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSalesPointList",
  componentId: "sc-1922zvy-0"
})(["\n  -webkit-font-smoothing: antialiased;\n"]);
var StyledServiceInfoWrapper = exports.StyledServiceInfoWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledServiceInfoWrapper",
  componentId: "sc-1922zvy-1"
})(["\n  max-width: 250px;\n  margin: 40px auto 45px;\n  font-family: ", ";\n  color: ", ";\n  font-size: 16px;\n  line-height: 1.5;\n  position: relative;\n\n  @media (min-width: 768px) {\n    max-width: 740px;\n    margin: 40px auto 95px;\n    padding: 0 35px;\n  }\n\n  @media (min-width: 1440px) {\n    max-width: 1042px;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.magenta;
});
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-1922zvy-2"
})(["\n  width: 24px;\n  height: 24px;\n  position: absolute;\n  left: 50%;\n  transform: translateX(-50%);\n  top: -30px;\n\n  @media (min-width: 768px) {\n    top: 0;\n    left: 0;\n    transform: none;\n  }\n"]);
var StyledItem = exports.StyledItem = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledItem",
  componentId: "sc-1922zvy-3"
})(["\n  max-width: 300px;\n  margin: 0 auto 95px;\n\n  @media (min-width: 768px) {\n    max-width: 740px;\n  }\n\n  @media (min-width: 1440px) {\n    max-width: 1042px;\n  }\n"]);
var StyledRegionWrapper = exports.StyledRegionWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledRegionWrapper",
  componentId: "sc-1922zvy-4"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 18px;\n  text-align: center;\n  margin-bottom: 20px;\n\n  @media (min-width: 768px) {\n    font-size: 22px;\n    margin-bottom: 30px;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});