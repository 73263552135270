"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitle = exports.StyledListItem = exports.StyledList = exports.StyledIconWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledList = exports.StyledList = _styledComponents.default.ul.withConfig({
  displayName: "styles__StyledList",
  componentId: "sc-1b853vh-0"
})(["\n  list-style: none;\n  padding: 0;\n\n  ", "\n"], function (props) {
  return props.isEditorial && (0, _styledComponents.css)(["\n      max-width: 300px;\n      margin: 0 auto;\n      padding: 60px 0 80px;\n\n      @media (min-width: 768px) {\n        max-width: 540px;\n      }\n\n      @media (min-width: 1440px) {\n        max-width: 430px;\n      }\n    "]);
});
var StyledListItem = exports.StyledListItem = _styledComponents.default.li.withConfig({
  displayName: "styles__StyledListItem",
  componentId: "sc-1b853vh-1"
})(["\n  min-height: 32px;\n  font-family: ", ";\n  position: relative;\n  padding-left: 52px;\n  padding-top: 8px;\n  vertical-align: middle;\n\n  &:not(:last-child) {\n    margin-bottom: 13px;\n  }\n"], function (props) {
  return props.theme.font.regular;
});
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-1b853vh-2"
})(["\n  width: 32px;\n  height: 32px;\n  position: absolute;\n  left: 0;\n  top: 0;\n"]);
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-1b853vh-3"
})(["\n  margin-bottom: 25px;\n  text-align: center;\n  font-family: ", ";\n  color: ", ";\n  font-size: 18px;\n\n  @media (min-width: 768px) {\n    margin-bottom: 60px;\n    font-size: 22px;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});