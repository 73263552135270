"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledOffersFormLocation = exports.StyledInputWrapper = exports.StyledButtonWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledOffersFormLocation = exports.StyledOffersFormLocation = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledOffersFormLocation",
  componentId: "sc-14b1gyg-0"
})(["\n  margin-top: 56px;\n  margin-bottom: 60px;\n\n  @media (min-width: 1440px) {\n    width: 500px;\n    margin: 90px auto 0;\n    display: flex;\n    flex-direction: row;\n    align-items: baseline;\n  }\n"]);
var StyledInputWrapper = exports.StyledInputWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledInputWrapper",
  componentId: "sc-14b1gyg-1"
})(["\n  margin: 0 auto;\n  width: 100%;\n  @media (min-width: 768px) {\n    width: 300px;\n  }\n"]);
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-14b1gyg-2"
})(["\n  width: 240px;\n  margin: 20px auto 0;\n\n  > button {\n    padding: 0;\n\n    > span {\n      font-size: 16px;\n    }\n\n    @media (min-width: 1440px) {\n      line-height: 42px;\n    }\n  }\n\n  @media (min-width: 1440px) {\n    width: 120px;\n    margin: 0 auto;\n  }\n"]);