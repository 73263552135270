"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitle = exports.StyledHeaderWrapper = exports.StyledEditorialPreview = exports.StyledCta = exports.StyledButtonWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledEditorialPreview = exports.StyledEditorialPreview = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledEditorialPreview",
  componentId: "sc-15qomvf-0"
})(["\n  margin: 100px 0;\n"]);
var StyledHeaderWrapper = exports.StyledHeaderWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledHeaderWrapper",
  componentId: "sc-15qomvf-1"
})(["\n  position: relative;\n  height: 80px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"]);
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-15qomvf-2"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 18px;\n  line-height: 1.44;\n  text-align: center;\n\n  ", " {\n    font-size: 30px;\n    line-height: 1.33;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-15qomvf-3"
})(["\n  margin: 0 auto;\n  display: flex;\n  justify-content: center;\n"]);
var StyledCta = exports.StyledCta = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledCta",
  componentId: "sc-15qomvf-4"
})(["\n  padding: 18px 48px;\n  text-align: center;\n  font-family: ", ";\n  color: ", ";\n  font-size: 20px;\n  border-radius: 34px;\n  border: 2px solid ", ";\n  text-decoration: none;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n  align-items: center;\n  transition: all 0.2s;\n\n  &:hover {\n    color: ", ";\n    background-color: ", ";\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.blue;
}, function (props) {
  return props.theme.color.blue;
}, function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.color.blue;
});