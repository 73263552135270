import React, { Component } from 'react'
import { createGlobalStyle } from 'styled-components'
import { style } from '@alkemy/components'

const withGlobalStyleExceptions = (
  { normalScroll } = { normalScroll: false },
) => EnhancedComponent =>
  class extends Component {
    static async getInitialProps(ctx) {
      let props

      if (typeof EnhancedComponent.getInitialProps === 'function') {
        props = await EnhancedComponent.getInitialProps(ctx)
      }

      // const hasPB = ctx.req.url.indexOf('?pb') > -1
      return { ...props }
    }

    render() {
      const { globalStyles, oldStyles } = style
      const { wpContent } = this.props
      let GlobalStyle = null

      if (
        wpContent ||
        this.props.hasPB ||
        normalScroll ||
        this.props.globalStyleOnly
      ) {
        GlobalStyle = createGlobalStyle`
        ${globalStyles}
      `
      } else {
        GlobalStyle = createGlobalStyle`
        ${globalStyles}
        ${oldStyles}
      `
      }

      return (
        <React.Fragment>
          <GlobalStyle />
          <EnhancedComponent {...this.props} />
        </React.Fragment>
      )
    }
  }

export default withGlobalStyleExceptions
