"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Wrapper = exports.Title = exports.StyledDocumentsList = exports.ListItem = exports.FlexRow = exports.FaqTitle = exports.DisclaimerWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _styles = require("../SectionFaqs/styles");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var Wrapper = exports.Wrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-1vjsf60-0"
})(["\n  font-family: ", ";\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  width: 90%;\n  max-width: 970px;\n  margin: 80px auto 0 auto;\n\n  ", " {\n    gap: 31px;\n  }\n\n  a {\n    color: ", ";\n    text-decoration: underline;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  return props.theme.color.magenta;
});
var Title = exports.Title = _styledComponents.default.h2.withConfig({
  displayName: "styles__Title",
  componentId: "sc-1vjsf60-1"
})(["\n  font-size: 35px;\n  color: ", ";\n  margin: 0 0 40px 0;\n\n  ", " {\n    margin-bottom: 0;\n    font-size: 22px;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var FlexRow = exports.FlexRow = _styledComponents.default.div.withConfig({
  displayName: "styles__FlexRow",
  componentId: "sc-1vjsf60-2"
})(["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  line-height: 1.4;\n  justify-content: ", ";\n  padding: ", ";\n  min-height: ", ";\n  transition: all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);\n  font-family: ", ";\n  color: ", ";\n  ", "\n\n  ", " {\n    padding: ", ";\n  }\n\n  ", "\n"], function (props) {
  return props.header ? 'space-between' : 'start';
}, function (props) {
  return props.body ? '0px 40px 20px' : '0 40px';
}, function (props) {
  return props.header ? '54px' : '100%';
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.body && (0, _styledComponents.css)(["\n      opacity: ", ";\n    "], function (props) {
    return props.open ? '1' : '0';
  });
}, function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  return props.body ? '24px 20px' : '8px 16px';
}, function (props) {
  return props.body && (0, _styledComponents.css)(["\n      font-family: ", ";\n    "], props.theme.font.light);
});
var FaqTitle = exports.FaqTitle = _styledComponents.default.h2.withConfig({
  displayName: "styles__FaqTitle",
  componentId: "sc-1vjsf60-3"
})(["\n  font-size: 16px;\n  font-family: ", ";\n  color: ", ";\n  flex: 1 1;\n\n  ", " {\n    margin: 0;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var ListItem = exports.ListItem = _styledComponents.default.div.withConfig({
  displayName: "styles__ListItem",
  componentId: "sc-1vjsf60-4"
})(["\n  font-family: ", ";\n  cursor: pointer;\n  box-shadow: 0 4px 20px 0 rgba(81, 129, 186, 0.15);\n  border-radius: 20px;\n  /* max-height: ", "; */\n  overflow: hidden;\n  transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);\n\n  &:hover {\n    box-shadow: 0 4px 20px 0 rgba(81, 129, 186, 0.24) !important;\n  }\n\n  &:not(:last-child) {\n    margin-bottom: 20px;\n  }\n\n  ", " {\n    &:not(:last-child) {\n      margin-bottom: 12px !important;\n    }\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.open ? '1000px' : '54px';
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var StyledDocumentsList = exports.StyledDocumentsList = (0, _styledComponents.default)(_styles.StyledList).withConfig({
  displayName: "styles__StyledDocumentsList",
  componentId: "sc-1vjsf60-5"
})(["\n  margin: 0 !important;\n"]);
var DisclaimerWrapper = exports.DisclaimerWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__DisclaimerWrapper",
  componentId: "sc-1vjsf60-6"
})(["\n  font-size: 16px;\n  font-family: ", ";\n\n  ", " {\n    margin-top: 24px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.regular;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});