import React from 'react'

export const SlugContext = React.createContext()
export const SlugConsumer = SlugContext.Consumer

const withSlugProvider = WrappedComponent =>
  class SlugProvider extends React.PureComponent {
    static async getInitialProps(ctx) {
      let props = {}
      if (typeof WrappedComponent.getInitialProps === 'function') {
        props = await WrappedComponent.getInitialProps(ctx)
      }
      return {
        ...props,
        slugFromContext: ctx.req && ctx.req.url && ctx.req.url.split('?')[0],
      }
    }

    constructor(props) {
      super(props)
      const {slugFromContext} = props

      this.state = {
        slugFromContext,
      }
    }

    addSlug = value => {
      this.setState({
        slug: value,
      })
    }

    render() {
      return (
        <SlugContext.Provider
          value={{
            slug: this.state.slugFromContext,
            addSlug: this.addSlug,
          }}
        >
          <WrappedComponent {...this.props} />
        </SlugContext.Provider>
      )
    }
  }

export default withSlugProvider
