"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledProductPageCta = exports.StyledModalVersion = exports.StyledModalDisclamer = exports.StyledModalContent = exports.StyledIconWrapper = exports.StyledDesktopVersion = exports.StyledCta = exports.StyledButtonWrapper = exports.StyledButtonModalWrapper = exports.StyledButtonLabel = exports.StyledButton = exports.StyledBlobUp = exports.StyledBloUpText = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledProductPageCta = exports.StyledProductPageCta = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledProductPageCta",
  componentId: "sc-12m8ujo-0"
})(["\n    margin: 80px 0;\n\n    ", " {\n    \n    }\n    ", " {\n      margin: 110px 0;\n    }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var StyledModalVersion = exports.StyledModalVersion = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledModalVersion",
  componentId: "sc-12m8ujo-1"
})(["\n    ", "{\n      display: none;\n    }  \n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledButtonModalWrapper = exports.StyledButtonModalWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonModalWrapper",
  componentId: "sc-12m8ujo-2"
})(["\n    position: relative;\n    margin: 0 auto;\n    width: 240px;\n    \n    ", " {\n        width: 300px;\n        margin: 70px 0 15px;\n    }\n    \n    ", " {\n        \n        margin: 0;\n    }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var StyledDesktopVersion = exports.StyledDesktopVersion = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDesktopVersion",
  componentId: "sc-12m8ujo-3"
})(["\n    display: none;\n    \n    ", "{\n      display: flex;\n      flex-wrap: 1;\n      flex-direction: row;\n      \n      justify-content: space-between;\n      padding-top: 60px;\n    }  \n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-12m8ujo-4"
})(["\n    display: flex;\n    flex-direction: column;\n    \n    margin-top: -15px;\n    margin-bottom: -15px;\n    \n    ", " {\n        flex-direction: row;\n        margin-top: 0;\n        margin-bottom: 0;\n    }\n"], function (props) {
  return props.theme.mediaQueries.desktop;
});
var StyledButton = exports.StyledButton = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledButton",
  componentId: "sc-12m8ujo-5"
})(["\n  display: flex;\n  position: relative;\n  \n  flex-direction: row;\n  \n  width: 100%;\n  min-width: 240px;\n  \n  cursor: pointer;\n  outline: none;\n  border-radius: 31.5px;\n  \n  border: solid 2px  ", ";\n  color: ", ";    \n  font-family: ", ";  \n  font-size: 16px;\n  line-height: 38px;\n  \n  text-align: center;\n  text-decoration: none;\n  \n  padding-left: 10px;\n  padding-right: 10px;\n  \n  \n  margin: 15px 0;\n  \n  ", " {\n    line-height: 56px;\n  \n    margin: 0 32.5px;\n  }\n  \n"], function (props) {
  return props.color || props.theme.color.blue;
}, function (props) {
  return props.color || props.theme.color.blue;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var StyledCta = exports.StyledCta = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledCta",
  componentId: "sc-12m8ujo-6"
})(["\n  display: flex;\n  position: relative;\n  \n  flex-direction: row;\n  \n  width: 100%;\n  min-width: 240px;\n  \n  cursor: pointer;\n  outline: none;\n  border-radius: 31.5px;\n  \n  border: solid 2px  ", ";\n  color: ", ";    \n  font-family: ", ";  \n  font-size: 16px;\n  line-height: 38px;\n  \n  text-align: center;\n  text-decoration: none;\n  \n  padding-left: 10px;\n  padding-right: 10px;\n  \n  \n  margin: 15px 0;\n  \n  ", " {\n    line-height: 56px;\n  \n    margin: 0 32.5px;\n  }\n  \n"], function (props) {
  return props.color || props.theme.color.blue;
}, function (props) {
  return props.color || props.theme.color.blue;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var StyledButtonLabel = exports.StyledButtonLabel = _styledComponents.default.span.withConfig({
  displayName: "styles__StyledButtonLabel",
  componentId: "sc-12m8ujo-7"
})(["\n    flex-grow: 1;\n    text-align: center;\n    pointer-events: none;\n    \n    color: ", ";\n"], function (props) {
  return props.color || 'inherit';
});
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-12m8ujo-8"
})(["\n flex-grow: 0;\n align-self: center;\n   \n width: 36px;\n height: 36px; \n \n ", " {  \n    \n } \n \n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledModalContent = exports.StyledModalContent = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledModalContent",
  componentId: "sc-12m8ujo-9"
})(["\n    display: flex;\n    flex-direction: column;\n    \n"]);
var StyledBlobUp = exports.StyledBlobUp = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBlobUp",
  componentId: "sc-12m8ujo-10"
})(["\n    position: absolute;\n    bottom: 100%;\n    left: 50%;\n    transform: translateX(-50%);\n    \n    > canvas {\n      position: absolute;\n      top: 50%;\n      left: 50%;\n      transform: translate(-50%, -50%);\n      z-index: -1;\n    }\n"]);
var StyledBloUpText = exports.StyledBloUpText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBloUpText",
  componentId: "sc-12m8ujo-11"
})(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  font-family: ", ";\n  font-size: 14px;\n  line-height: 14px;\n  text-align: center;\n  color: ", ";\n  \n  @media (min-width: 1280px) {\n    top: -40px;\n    width: 300px;\n    left: -150px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.white;
});
var StyledModalDisclamer = exports.StyledModalDisclamer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledModalDisclamer",
  componentId: "sc-12m8ujo-12"
})(["   \n    font-family: ", ";\n    font-size: 14px;\n    line-height: 16px;\n    text-align: center;\n    color: ", ";\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.white;
});