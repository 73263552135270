import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { ProductPageTabContent, withWrapper } from '@alkemy/components'

const TabContent = props => (
  <ProductPageTabContent {...props} funnelDomain={process.env.FUNNEL_DOMAIN} />
)

export default compose(
  withPageBuilder({
    niceName: 'Product Page Tab Content',
  }),
  withFields([
    {
      prop: 'tabId',
      label: 'Tab ID',
    },
    {
      prop: 'active',
      label: 'Active',
      type: 'boolean',
    },
    {
      prop: 'defaultTabTipologiaTariffa',
      label: 'Tab da preselezionare',
      type: 'multiple',
      options: ['mono:Monoraria', 'bio:A fasce'],
      initialValue: 'mono',
    },
    {
      prop: 'productIds',
      label: 'Product IDs',
      type: 'repeater',
      model: [
        {
          prop: 'productId',
          label: 'ID',
        },
      ],
    },
  ]),
  withWrapper,
)(TabContent)
