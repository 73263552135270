"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TariffaType = exports.TariffaText = exports.Tariffa = exports.ProductTitle = exports.MiniTabWrapper = exports.MiniTabHeader = exports.MiniTab = exports.ListaTariffe = exports.IconWrapper = exports.Header = exports.CostiCard = exports.BlobWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var CostiCard = exports.CostiCard = _styledComponents.default.div.withConfig({
  displayName: "styles__CostiCard",
  componentId: "sc-1yxjsah-0"
})(["\n  display: grid;\n  grid-template-columns: 1fr;\n  grid-template-rows: auto 1fr;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  gap: 30px;\n  font-family: ", ";\n\n  ", " {\n    gap: 0;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var Header = exports.Header = _styledComponents.default.div.withConfig({
  displayName: "styles__Header",
  componentId: "sc-1yxjsah-1"
})(["\n  display: flex;\n  justify-content: space-between;\n  gap: 10px;\n\n  @media (max-width: 768px) {\n    flex-direction: column;\n    gap: 20px;\n    ", "\n  }\n"], function (props) {
  return props.setMarginBottom && "margin-bottom: 20px;";
});
var MiniTabWrapper = exports.MiniTabWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__MiniTabWrapper",
  componentId: "sc-1yxjsah-2"
})(["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  justify-content: flex-end;\n  font-family: ", ";\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.57;\n  letter-spacing: normal;\n  text-align: center;\n  font-size: 14px;\n  margin-top: 3px;\n  width: 100%;\n\n  ", " {\n    margin-bottom: 19px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var ProductTitle = exports.ProductTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__ProductTitle",
  componentId: "sc-1yxjsah-3"
})(["\n  display: inline-block;\n  width: max-content;\n  font-family: ", ";\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.44;\n  letter-spacing: normal;\n  font-size: 22px;\n  color: ", ";\n\n  ", " {\n    max-width: auto;\n    font-size: 18px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var MiniTabHeader = exports.MiniTabHeader = _styledComponents.default.div.withConfig({
  displayName: "styles__MiniTabHeader",
  componentId: "sc-1yxjsah-4"
})(["\n  display: inline-block;\n  float: right;\n  width: 100%;\n  max-width: 200px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  text-align: center;\n  border: solid 2px rgba(248, 138, 46, 0.4);\n  border-radius: 20px;\n\n  @media (max-width: 768px) {\n    max-width: 232px;\n    margin: 0 auto;\n    width: 100vw !important;\n    grid-column: 1/3;\n  }\n"]);
var MiniTab = exports.MiniTab = _styledComponents.default.span.withConfig({
  displayName: "styles__MiniTab",
  componentId: "sc-1yxjsah-5"
})(["\n  font-family: ", ";\n  border-radius: 20px;\n  cursor: pointer;\n  flex: 50%;\n  text-align: center;\n  padding: 4px 0;\n  flex-basis: auto;\n  color: #f88a2e;\n  background-color: white;\n  font-size: 14px;\n  transition: all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);\n\n  ", "\n\n  ", " {\n    height: 30px !important;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  if (props.active) {
    return "\n        color: white;\n        background-color: #f88a2e;\n        z-index: 1;\n        border: none;\n      ";
  }
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var ListaTariffe = exports.ListaTariffe = _styledComponents.default.div.withConfig({
  displayName: "styles__ListaTariffe",
  componentId: "sc-1yxjsah-6"
})([""]);
var Tariffa = exports.Tariffa = _styledComponents.default.div.withConfig({
  displayName: "styles__Tariffa",
  componentId: "sc-1yxjsah-7"
})(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  padding: 10px 0;\n  border-bottom: 1px dashed #e6e6e6;\n  color: ", ";\n"], function (props) {
  return props.theme.color.darkBlue;
});
var TariffaBase = _styledComponents.default.span.withConfig({
  displayName: "styles__TariffaBase",
  componentId: "sc-1yxjsah-8"
})(["\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.57;\n  letter-spacing: normal;\n\n  ", " {\n    font-size: 14px !important;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var TariffaType = exports.TariffaType = (0, _styledComponents.default)(TariffaBase).withConfig({
  displayName: "styles__TariffaType",
  componentId: "sc-1yxjsah-9"
})(["\n  font-size: 18px;\n  font-weight: normal;\n"]);
var TariffaText = exports.TariffaText = (0, _styledComponents.default)(TariffaBase).withConfig({
  displayName: "styles__TariffaText",
  componentId: "sc-1yxjsah-10"
})(["\n  font-size: 22px;\n  font-weight: bold;\n"]);
var IconWrapper = exports.IconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__IconWrapper",
  componentId: "sc-1yxjsah-11"
})(["\n  position: relative;\n  width: 100%;\n  display: grid;\n  grid-template-columns: 1fr;\n  grid-template-rows: auto 1fr;\n  align-items: center;\n  gap: 10px;\n\n  ", " {\n    grid-template-columns: auto 1fr;\n    grid-template-rows: 1fr;\n    justify-content: center;\n    gap: 15px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var BlobWrapper = exports.BlobWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__BlobWrapper",
  componentId: "sc-1yxjsah-12"
})(["\n  display: flex;\n  flex-direction: column;\n  height: fit-content;\n  position: relative;\n  justify-content: center;\n\n  ", " {\n    flex-direction: row;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});