"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.retrieveProvinceAndRegion = exports.mappingLocation = exports.makeStores = exports.haversineDistance = exports.gasMapping = void 0;
var _axios = _interopRequireDefault(require("axios"));
var haversineDistance = exports.haversineDistance = function haversineDistance(coords1, coords2) {
  function toRad(x) {
    return x * Math.PI / 180;
  }
  var lon1 = coords1[0];
  var lat1 = coords1[1];
  var lon2 = coords2[0];
  var lat2 = coords2[1];
  var R = 6371; // km

  var x1 = lat2 - lat1;
  var dLat = toRad(x1);
  var x2 = lon2 - lon1;
  var dLon = toRad(x2);
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
};
var makeStores = exports.makeStores = function makeStores(data) {
  var arr = [];
  data.map(function (item) {
    var lat = parseFloat(item.acf.location.lat);
    var lng = parseFloat(item.acf.location.lng);
    arr.push({
      lat: lat,
      lng: lng
    });
  });
  return arr;
};
var retrieveProvinceAndRegion = exports.retrieveProvinceAndRegion = function retrieveProvinceAndRegion(_ref) {
  var address = _ref.address,
    callback = _ref.callback;
  if (address === 'Napoli') address = 'Città metropolitana di Napoli';
  _axios.default.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + address + '&key=AIzaSyBTMqOk0Z-6XJxRTK7KGGHqZULunxhWTJg').then(function (response) {
    try {
      var result = response.data.results[0];
      console.info(result.address_components);
      var provincia = result.address_components.find(function (ac) {
        return ac.types.includes('administrative_area_level_2');
      }).long_name;
      var citta_short = result.address_components.find(function (ac) {
        return ac.types.includes('administrative_area_level_2');
      }).long_name;
      var provincia_short = result.address_components.find(function (ac) {
        return ac.types.includes('administrative_area_level_2');
      }).short_name;
      var nazione_short = result.address_components.find(function (ac) {
        return ac.types.includes('country');
      }).long_name;
      var regione = result.address_components.find(function (ac) {
        return ac.types.includes('administrative_area_level_1');
      }).long_name;
      callback({
        provincia: provincia,
        regione: regione,
        provincia_short: provincia_short,
        nazione_short: nazione_short,
        citta_short: citta_short
      });
    } catch (err) {
      console.log(err);
    }
  }).catch(function (err) {
    console.info('err', err);
  });
};
var gasMapping = exports.gasMapping = {
  'NORD-ORIENTALE': 1.0655,
  'NORD-OCCIDENTALE': 1.08,
  CENTRALE: 1.08,
  'CENTRO-SUD OCCIDENTALE': 1.15,
  'CENTRO-SUD ORIENTALE': 1.08,
  MERIDIONALE: 1.2
};
var mappingLocation = exports.mappingLocation = {
  AG: 'MERIDIONALE',
  AL: 'NORD-OCCIDENTALE',
  AN: 'CENTRALE',
  AO: 'NORD-OCCIDENTALE',
  AP: 'CENTRALE',
  AQ: 'CENTRO-SUD ORIENTALE',
  AR: 'CENTRALE',
  AT: 'NORD-OCCIDENTALE',
  AV: 'CENTRO-SUD OCCIDENTALE',
  BA: 'CENTRO-SUD ORIENTALE',
  BG: 'NORD-ORIENTALE',
  BI: 'NORD-OCCIDENTALE',
  BL: 'NORD-ORIENTALE',
  BN: 'CENTRO-SUD OCCIDENTALE',
  BO: 'NORD-ORIENTALE',
  BR: 'CENTRO-SUD ORIENTALE',
  BS: 'NORD-ORIENTALE',
  BT: 'CENTRO-SUD ORIENTALE',
  BZ: 'NORD-ORIENTALE',
  CA: 'non metanizzata',
  CB: 'CENTRO-SUD ORIENTALE',
  CE: 'CENTRO-SUD OCCIDENTALE',
  CH: 'CENTRO-SUD ORIENTALE',
  CI: 'non metanizzata',
  CL: 'MERIDIONALE',
  CN: 'NORD-OCCIDENTALE',
  CO: 'NORD-ORIENTALE',
  CR: 'NORD-ORIENTALE',
  CS: 'MERIDIONALE',
  CT: 'MERIDIONALE',
  CZ: 'MERIDIONALE',
  EN: 'MERIDIONALE',
  FC: 'NORD-ORIENTALE',
  FE: 'NORD-ORIENTALE',
  FG: 'CENTRO-SUD ORIENTALE',
  FI: 'CENTRALE',
  FM: 'CENTRALE',
  FR: 'CENTRO-SUD OCCIDENTALE',
  GE: 'NORD-OCCIDENTALE',
  GO: 'NORD-ORIENTALE',
  GR: 'CENTRALE',
  IM: 'NORD-OCCIDENTALE',
  IS: 'CENTRO-SUD ORIENTALE',
  KR: 'MERIDIONALE',
  LC: 'NORD-ORIENTALE',
  LE: 'CENTRO-SUD ORIENTALE',
  LI: 'CENTRALE',
  LO: 'NORD-ORIENTALE',
  LT: 'CENTRO-SUD OCCIDENTALE',
  LU: 'CENTRALE',
  MB: 'NORD-ORIENTALE',
  MC: 'CENTRALE',
  ME: 'MERIDIONALE',
  MI: 'NORD-ORIENTALE',
  MN: 'NORD-ORIENTALE',
  MO: 'NORD-ORIENTALE',
  MS: 'CENTRALE',
  MT: 'CENTRO-SUD ORIENTALE',
  NA: 'CENTRO-SUD OCCIDENTALE',
  NO: 'NORD-OCCIDENTALE',
  NU: 'non metanizzata',
  OG: 'non metanizzata',
  OR: 'non metanizzata',
  OT: 'non metanizzata',
  PA: 'MERIDIONALE',
  PC: 'NORD-ORIENTALE',
  PD: 'NORD-ORIENTALE',
  PE: 'CENTRO-SUD ORIENTALE',
  PG: 'CENTRALE',
  PI: 'CENTRALE',
  PN: 'NORD-ORIENTALE',
  PO: 'CENTRALE',
  PR: 'NORD-ORIENTALE',
  PT: 'CENTRALE',
  PU: 'CENTRALE',
  PV: 'NORD-ORIENTALE',
  PZ: 'CENTRO-SUD ORIENTALE',
  RA: 'NORD-ORIENTALE',
  RC: 'MERIDIONALE',
  RE: 'NORD-ORIENTALE',
  RG: 'MERIDIONALE',
  RI: 'CENTRO-SUD OCCIDENTALE',
  RM: 'CENTRO-SUD OCCIDENTALE',
  RN: 'NORD-ORIENTALE',
  RO: 'NORD-ORIENTALE',
  SA: 'CENTRO-SUD OCCIDENTALE',
  SI: 'CENTRALE',
  SO: 'NORD-ORIENTALE',
  SP: 'NORD-OCCIDENTALE',
  SR: 'MERIDIONALE',
  SS: 'non metanizzata',
  SU: 'non metanizzata',
  SV: 'NORD-OCCIDENTALE',
  TA: 'CENTRO-SUD ORIENTALE',
  TE: 'CENTRO-SUD ORIENTALE',
  TN: 'NORD-ORIENTALE',
  TO: 'NORD-OCCIDENTALE',
  TP: 'MERIDIONALE',
  TR: 'CENTRALE',
  TS: 'NORD-ORIENTALE',
  TV: 'NORD-ORIENTALE',
  UD: 'NORD-ORIENTALE',
  VA: 'NORD-ORIENTALE',
  VB: 'NORD-OCCIDENTALE',
  VC: 'NORD-OCCIDENTALE',
  VE: 'NORD-ORIENTALE',
  VI: 'NORD-ORIENTALE',
  VR: 'NORD-ORIENTALE',
  VS: 'non metanizzata',
  VT: 'CENTRO-SUD OCCIDENTALE',
  VV: 'MERIDIONALE',
  VCO: 'NORD-OCCIDENTALE'
};