"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledHamburgerLine = exports.StyledHamburger = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledHamburger = exports.StyledHamburger = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledHamburger",
  componentId: "sc-ofw0qm-0"
})(["\n  position: absolute;\n  width: 42px;\n  height: 42px;\n  top: 0;\n  right: 0;\n  overflow: hidden;\n  z-index: 10;\n  pointer-events: all;\n  \n  ", " {\n    display: none;\n  }\n"], function (props) {
  return props.theme.mediaQueries.ipad;
});
var StyledHamburgerLine = exports.StyledHamburgerLine = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledHamburgerLine",
  componentId: "sc-ofw0qm-1"
})(["\n  position: absolute;\n  width: 50%;\n  height: 2px;\n  background-color: white;\n  top: 50%;\n  left: 50%;\n  transform: ", ";\n  transition: all .2s linear 0s;\n  pointer-events: none;\n  \n  &:first-child {\n    top: 50%;\n    transform: ", ";\n  }\n  \n  &:last-child {\n    top: 50%;\n    transform: ", ";\n  }\n"], function (_ref) {
  var isActive = _ref.isActive;
  return isActive ? 'translate(100%, -50%)' : 'translate(-60%, -50%)';
}, function (_ref2) {
  var isActive = _ref2.isActive;
  return isActive ? 'translate(-50%, -50%) rotate(-45deg)' : 'translate(-40%, -9px) rotate(0deg)';
}, function (_ref3) {
  var isActive = _ref3.isActive;
  return isActive ? 'translate(-50%, -50%) rotate(45deg)' : 'translate(-40%, 8px) rotate(0deg)';
});