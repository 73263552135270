"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Wrapper = exports.ProductPageTitle = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _styles = require("../ProductPageTab/styles");
var Wrapper = exports.Wrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-x75m1t-0"
})(["\n  max-width: 1170px;\n  width: 100%;\n  margin: 47px auto 0 auto;\n\n  ", " {\n    margin-top: 0px !important;\n    margin-bottom: 0;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var ProductPageTitle = exports.ProductPageTitle = _styledComponents.default.h2.withConfig({
  displayName: "styles__ProductPageTitle",
  componentId: "sc-x75m1t-1"
})(["\n  font-family: ", ";\n  color: ", ";\n  width: 100%;\n  padding: 0;\n  margin: 0;\n  line-height: 1.26;\n  font-stretch: normal;\n  font-style: normal;\n  letter-spacing: normal;\n  margin-bottom: 64px;\n  padding-left: 100px;\n  font-size: 35px;\n  max-width: 740px;\n\n  ", " {\n    font-size: 22px;\n    line-height: 1.36;\n    width: 100%;\n    padding: 30px 20px;\n    margin-bottom: 0;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.phone;
});