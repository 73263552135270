"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Link = exports.IconWrapper = exports.ButtonLabel = exports.Button = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var Button = exports.Button = _styledComponents.default.button.withConfig({
  displayName: "styles__Button",
  componentId: "sc-2kwuqo-0"
})(["\n  width: 100%;\n  //min-width: 220px;\n  padding: 0 35px;\n  line-height: 40px;\n  position: relative;\n  color: ", ";\n  border-radius: 30px;\n  border: none;\n  background: none;\n  text-align: center;\n  cursor: pointer;\n  -webkit-font-smoothing: antialiased;\n  -webkit-appearance: none;\n  box-shadow: 0px 0px 20px 2px ", ";\n  outline: none;\n  overflow: hidden;\n  will-change: box-shadow;\n  transition: all 0.2s ease-in 0s;\n  white-space: nowrap;\n\n  @media (min-width: 1280px) {\n    //.no-touch & {\n      &:hover {\n        @media (min-width: 768px) {\n          box-shadow: 0px 0px 20px 8px ", ";\n        }\n\n        &:after {\n          background-color: ", ";\n          opacity: 1;\n        }\n\n      }\n    //}\n  }\n\n  &:active {\n    &:after {\n        background-color: ", ";\n        opacity: 1;\n      }\n  }\n\n  ", "\n\n  ", "\n\n  ", "\n\n  ", "\n\n\t\t", "\n\n  ", "\n\n    ", "\n  &:after {\n    content: '';\n    height: 100%;\n    width: 100%;\n    position: absolute;\n    top: 0;\n    left: 0;\n    opacity: 0;\n    will-change: opacity;\n    transition: all 0.2s ease-in 0s;\n  }\n\n  /* ", " */\n\n\t\t", "\n\n\n  ", "\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.color.shadowRed;
}, function (props) {
  return props.theme.color.shadowRed;
}, function (props) {
  return props.theme.color.whiteHover;
}, function (props) {
  return props.theme.color.blackPressed;
}, function (props) {
  return props.short && (0, _styledComponents.css)(["\n      //min-width: 120px;\n      line-height: 42px;\n      border-radius: 21px;\n\n      @media (min-width: 1440px) {\n        line-height: 40px;\n        border-radius: 30px;\n      }\n    "]);
}, function (props) {
  return props.tall && (0, _styledComponents.css)(["\n      //min-width: 260px;\n      line-height: 80px;\n      border-radius: 40px;\n    "]);
}, function (props) {
  return props.mainColor && (0, _styledComponents.css)(["\n      background: ", ";\n    "], function (props) {
    return props.theme.color[props.mainColor];
  });
}, function (props) {
  return props.isDisabled && (0, _styledComponents.css)(["\n      background: ", ";\n      pointer-events: none;\n    "], function (props) {
    return props.theme.color.gray;
  });
}, function (props) {
  return props.highlight && (0, _styledComponents.css)(["\n      transform: scale(1.05);\n\t\t\ttransition: transform: 0.5s;\n\t\t\tfont-size: 12px;\n    "]);
}, function (props) {
  return props.icon && (0, _styledComponents.css)(["\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      flex-wrap: wrap;\n      flex-direction: ", ";\n    "], props.invertIcon ? 'row-reverse' : 'row');
}, function (props) {
  return props.solo && (0, _styledComponents.css)(["\n        min-width: 300px;\n        max-width: 300px;\n        position: relative;\n        display: block;\n        margin: 40px auto !important;\n        margin-top: 40px;\n        margin-bottom: 40px;\n      "]);
}, function (props) {
  return !props.archiveFilterActive && (0, _styledComponents.css)(["\n      background: ", ";\n      color: ", ";\n    "], function (props) {
    return props.theme.color.white;
  }, function (props) {
    return props.theme.color.darkBlue;
  });
}, function (props) {
  return props.mainGradient && (0, _styledComponents.css)(["\n        background: ", ";\n        font-family: ", ";\n        text-transform: uppercase;\n        font-size: 14px;\n        height: 72px;\n        border-radius: 16px;\n        top: -6px;\n      "], function (props) {
    return props.theme.color.greenBlue;
  }, function (props) {
    return props.theme.font.regular;
  });
}, function (props) {
  return props.archiveFilterActive && (0, _styledComponents.css)(["\n      box-shadow: none;\n\n      &:hover {\n        @media (min-width: 768px) {\n          box-shadow: none;\n        }\n      }\n    "]);
});
var Link = exports.Link = _styledComponents.default.a.withConfig({
  displayName: "styles__Link",
  componentId: "sc-2kwuqo-1"
})(["\n  display: block;\n  text-decoration: none;\n  width: 100%;\n  //min-width: 220px;\n  padding: 0 35px;\n  line-height: 50px;\n  position: relative;\n  color: ", ";\n  border-radius: 30px;\n  border: none;\n  background: none;\n  text-align: center;\n  cursor: pointer;\n  -webkit-font-smoothing: antialiased;\n  -webkit-appearance: none;\n  box-shadow: 0px 0px 20px 2px ", ";\n  outline: none;\n  overflow: hidden;\n  will-change: box-shadow;\n  transition: all 0.2s ease-in 0s;\n  white-space: nowrap;\n  ", "\n  ", "\n\n  ", "\n\n  ", "\n\n  ", "\n\n  ", "\n\n  &:after {\n    content: '';\n    height: 100%;\n    width: 100%;\n    position: absolute;\n    top: 0;\n    left: 0;\n    opacity: 0;\n    will-change: opacity;\n    transition: all 0.2s ease-in 0s;\n  }\n\n  //.no-touch & {\n    &:hover {\n      &:after {\n        background-color: ", ";\n        opacity: 1;\n      }\n\n      box-shadow: 0px 0px 20px 8px ", ";\n    }\n  //}\n\n  &:active {\n    &:after {\n        background-color: ", ";\n        opacity: 1;\n      }\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.color.shadowRed;
}, function (props) {
  return props.solo && (0, _styledComponents.css)(["\n      min-width: 300px;\n      max-width: 300px;\n      position: relative;\n      display: block;\n      margin: 40px auto !important;\n      margin-top: 40px;\n      margin-bottom: 40px;\n    "]);
}, function (props) {
  return props.short && (0, _styledComponents.css)(["\n      //min-width: 120px;\n      line-height: 42px;\n      border-radius: 21px;\n\n      @media (min-width: 1440px) {\n        line-height: 40px;\n        border-radius: 30px;\n      }\n    "]);
}, function (props) {
  return props.tall && (0, _styledComponents.css)(["\n      //min-width: 260px;\n      line-height: 80px;\n      border-radius: 40px;\n    "]);
}, function (props) {
  return props.mainColor && (0, _styledComponents.css)(["\n      background: ", ";\n    "], function (props) {
    return props.theme.color[props.mainColor];
  });
}, function (props) {
  return props.isDisabled && (0, _styledComponents.css)(["\n      background: ", ";\n      pointer-events: none;\n    "], function (props) {
    return props.theme.color.gray;
  });
}, function (props) {
  return props.icon && (0, _styledComponents.css)(["\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      flex-wrap: wrap;\n      flex-direction: ", ";\n    "], props.invertIcon ? 'row-reverse' : 'row');
}, function (props) {
  return props.theme.color.whiteHover;
}, function (props) {
  return props.theme.color.shadowRed;
}, function (props) {
  return props.theme.color.blackPressed;
});
var ButtonLabel = exports.ButtonLabel = _styledComponents.default.span.withConfig({
  displayName: "styles__ButtonLabel",
  componentId: "sc-2kwuqo-2"
})(["\n  font-family: ", ";\n  font-size: 16px;\n"], function (props) {
  return props.theme.font.regular;
});
var IconWrapper = exports.IconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__IconWrapper",
  componentId: "sc-2kwuqo-3"
})(["\n  ", "\n\n  ", "\n\n  ", "\n\n  ", "\n\n  ", "\n\n  ", ";\n"], function (props) {
  return props.icon === 'CaretRight' && (0, _styledComponents.css)(["\n      width: 10px;\n      height: 20px;\n    "]);
}, function (props) {
  return props.icon === 'Assistance' && (0, _styledComponents.css)(["\n      width: 25px;\n      height: 25px;\n    "]);
}, function (props) {
  return props.icon === 'House' && (0, _styledComponents.css)(["\n      width: 28px;\n      height: 26px;\n    "]);
}, function (props) {
  return props.icon === 'Business' && (0, _styledComponents.css)(["\n      width: 28px;\n      height: 25px;\n    "]);
}, function (props) {
  return props.icon === 'Leaf' && (0, _styledComponents.css)(["\n      width: 45px;\n      height: 45px;\n      margin-right: -1px !important;\n    "]);
}, function (props) {
  return props.invertIcon ? 'margin-right: 19px;' : 'margin-left: 19px;';
});