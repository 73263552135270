import { compose } from "recompose"
import withPageBuilder from "@alkemy/pagebuilder/lib/hoc/withPageBuilder"
import withFields from "@alkemy/pagebuilder/lib/hoc/withFields"

import {
  SectionDownloadApp,
  withWrapper
} from "@alkemy/components"

export default compose(
  withPageBuilder({
    niceName: "Box Scarica La App",
  }),
  withFields([{
      prop: "wrapped",
      type: "boolean",
      label: "Contenuto Boxed",
    },
    {
      prop: "title",
      label: "Titolo",
    },
    {
      prop: "text",
      type: "WYSIWYG",
      label: "Testo Blocco",
    },
    {
      prop: "hrefApple",
      label: "Apple Link",
    },
    {
      prop: "hrefGoogle",
      label: "Link Google",
    },
  ]),
  withWrapper
)(SectionDownloadApp)