import { MODAL_OPEN, MODAL_CLOSE } from "./types"

export default (state = {}, action) => {
  switch (action.type) {
    case MODAL_OPEN:
      return {
        status: "OPEN",
      }
    case MODAL_CLOSE:
      return {
        status: "CLOSED",
      }
    default:
      return state
  }
}

export const getLoginModal = state => {
  return state.loginModal
}

export const getLoginModalStatus = state => {
  return state.loginModal && state.loginModal.status
}
