"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledWrapper = exports.HeroWrapper = exports.Bollino = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _styles = require("../PageBanner/styles");
var HeroWrapper = exports.HeroWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__HeroWrapper",
  componentId: "sc-fqear-0"
})(["\n  background-image: linear-gradient(255deg, #e81c69 -21%, #f09724 46%);\n  padding: 0;\n  position: relative;\n  overflow: hidden;\n\n  margin-top: 103px;\n\n  canvas {\n    position: absolute;\n    z-index: 0;\n    ", " {\n      transform: scale(1, -1) !important;\n    }\n  }\n\n  ", " {\n    position: relative;\n    width: 100vw;\n    left: 50%;\n    transform: translateX(-50%);\n  }\n\n  @media (min-width: 768px) and (max-width: 1024px) {\n    margin-top: 55px;\n  }\n\n  @media (min-width: 1024px) and (max-width: 1250px) {\n    margin-top: 90px;\n  }\n\n  position: relative;\n  height: 269px;\n  padding-top: 50px;\n\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: top;\n\n  canvas {\n    position: absolute;\n    z-index: -1;\n    overflow: hidden;\n    bottom: -1px;\n\n    @media (max-width: 768px) {\n      z-index: 999;\n      width: 100%;\n      transform: rotate(0deg) !important;\n    }\n  }\n\n  ", " {\n    height: 116px !important;\n    div {\n      position: absolute !important;\n      bottom: 10px;\n    }\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var Bollino = exports.Bollino = _styledComponents.default.img.withConfig({
  displayName: "styles__Bollino",
  componentId: "sc-fqear-1"
})(["\n  width: 128px;\n  height: 128px;\n  position: absolute;\n  right: 5%;\n  top: 10%;\n  display: block;\n"]);
var StyledWrapper = exports.StyledWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledWrapper",
  componentId: "sc-fqear-2"
})(["\n  max-width: 1170px;\n  width: 90%;\n  margin: 0 auto;\n"]);