"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitle = exports.StyledText = exports.StyledPrevArrow = exports.StyledNextArrow = exports.StyledMainTitle = exports.StyledIcon = exports.StyledHighlight = exports.StyledGoTo = exports.StyledContent = exports.StyledContainer = exports.SlideWrapper = exports.SectionWrapper = exports.GoWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var SlideWrapper = exports.SlideWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__SlideWrapper",
  componentId: "sc-bnhhtf-0"
})(["\n  width: 200px;\n  height: 100%;\n  margin: 20px 0;\n  display: flex;\n  padding: 0 13px;\n\n  ", " {\n    width: 240px;\n    height: 320px;\n    padding: 0 26px;\n    margin: 20px 0;\n  }\n  ", " {\n    width: 240px;\n    height: 400px;\n    padding: 0 35px;\n    margin: 20px 0;\n  }\n"], function (props) {
  return props.theme.mediaQueries.ipad;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledContainer = exports.StyledContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContainer",
  componentId: "sc-bnhhtf-1"
})(["\n  position: relative;\n  padding: 0 !important;\n  width: 100% !important;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  @media (min-width: 1608px) {\n    background-size: contain;\n  }\n  canvas {\n    position: absolute;\n    z-index: -1;\n    pointer-events: all !important;\n    overflow: hidden;\n  }\n\n  ", " {\n    canvas {\n      width: 100%;\n      height: 100%;\n    }\n  }\n\n  > img {\n    height: 100%;\n    width: 100%;\n    position: absolute;\n    z-index: -1;\n    max-width: 973px;\n    max-height: 674px;\n  }\n\n  ", " {\n    > img {\n      width: 200vw;\n    }\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var StyledHighlight = exports.StyledHighlight = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledHighlight",
  componentId: "sc-bnhhtf-2"
})(["\n  height: 338px;\n  width: 200px;\n  background-color: white;\n  border-radius: 20px;\n  position: relative;\n  width: 100%;\n  padding: 10px 20px;\n  display: flex;\n  flex-direction: column;\n  box-shadow: 0 0 20px 0 rgb(81 129 186 / 25%);\n  cursor: pointer;\n\n  &:hover\xA0 {\n    box-shadow: 0 0 20px 0 rgb(81 129 186 / 35%);\n  }\n\n  ", " {\n    height: 342px;\n    width: 242px;\n    border-radius: 35px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var SectionWrapper = exports.SectionWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__SectionWrapper",
  componentId: "sc-bnhhtf-3"
})(["\n  overflow: hidden;\n\n  pointer-events: none;\n\n  > * {\n    pointer-events: all;\n  }\n\n  .slick-dots li.slick-active button:before {\n    color: white;\n    font-size: 12px;\n  }\n\n  .slick-dots li button::before {\n    color: white;\n    font-size: 12px;\n  }\n\n  .slice-slide {\n    margin-bottom: 26px;\n  }\n\n  display: flex;\n  flex-direction: column;\n\n  width: 100%;\n  height: 777px;\n\n  justify-content: center;\n\n  ", " {\n    height: 776px;\n    overflow: visible;\n  }\n\n  ", " {\n    .slick-slide[aria-hidden='true'] {\n      opacity: 0.5;\n    }\n\n    .slick-active ", " {\n      height: 376px;\n    }\n\n    .slick-active ", " {\n      margin-top: 0;\n    }\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.phone;
}, StyledHighlight, SlideWrapper);
var StyledIcon = exports.StyledIcon = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIcon",
  componentId: "sc-bnhhtf-4"
})(["\n  align-self: center;\n  width: 113px;\n  height: 113px;\n  margin: 18px 0 20px 0;\n  > img {\n    width: 100%;\n  }\n  @media (min-width: 768px) {\n    margin: 19px 0 22px 0;\n  }\n"]);
var StyledContent = exports.StyledContent = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContent",
  componentId: "sc-bnhhtf-5"
})(["\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n"]);
var StyledMainTitle = exports.StyledMainTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledMainTitle",
  componentId: "sc-bnhhtf-6"
})(["\n  font-size: 18px;\n  text-align: center;\n  font-family: ", ";\n  color: ", ";\n  margin-bottom: 32px;\n\n  ", " {\n    font-size: 30px;\n    margin-bottom: 56px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-bnhhtf-7"
})(["\n  font-size: 16px;\n  text-align: center;\n  font-family: ", ";\n  color: ", ";\n  font-weight: bold;\n  margin-bottom: 6px;\n\n  ", " {\n    font-size: 18px;\n    font-weight: bold;\n    margin-bottom: 4px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledPrevArrow = exports.StyledPrevArrow = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledPrevArrow",
  componentId: "sc-bnhhtf-8"
})(["\n  width: 65px;\n  height: 65px;\n  position: absolute;\n  display: block;\n  top: 50%;\n  left: -65px;\n  cursor: pointer;\n  transform: rotate(180deg);\n"]);
var StyledNextArrow = exports.StyledNextArrow = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledNextArrow",
  componentId: "sc-bnhhtf-9"
})(["\n  width: 65px;\n  height: 65px;\n  position: absolute;\n  display: block;\n  top: 50%;\n  right: -65px;\n  cursor: pointer;\n"]);
var StyledText = exports.StyledText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledText",
  componentId: "sc-bnhhtf-10"
})(["\n  margin: 0;\n\n  * {\n    margin: 0 !important;\n    color: ", ";\n    font-family: ", ";\n    font-size: 14px;\n    font-weight: normal;\n    font-style: normal;\n    font-stretch: normal;\n    line-height: 1.57;\n    letter-spacing: normal;\n  }\n\n  ", "\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.center && (0, _styledComponents.css)(["\n      text-align: center;\n    "]);
});
var StyledGoTo = exports.StyledGoTo = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledGoTo",
  componentId: "sc-bnhhtf-11"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: bold;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: 1.57;\n  letter-spacing: normal;\n  text-align: center;\n  text-decoration: none;\n"], function (props) {
  return props.theme.color.magenta;
}, function (props) {
  return props.theme.font.regular;
});
var GoWrapper = exports.GoWrapper = _styledComponents.default.a.withConfig({
  displayName: "styles__GoWrapper",
  componentId: "sc-bnhhtf-12"
})(["\n  text-decoration: none;\n  margin-top: auto;\n  width: 100%;\n  justify-content: center;\n  padding-left: 20px;\n  padding-bottom: 10px;\n  padding-top: 10px;\n  display: flex;\n  flex-direction: row;\n  flex-grow: 1;\n  align-items: flex-end;\n  & > div {\n    white-space: nowrap;\n    height: 20px;\n    svg {\n      margin-top: -15px;\n      height: auto;\n    }\n\n    &:last-child {\n      width: 50px;\n    }\n  }\n"]);