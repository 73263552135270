import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'
import OpenModal from '../components/OpenModal'

import {
  withWrapper,
  Row,
  CardBlockFeatured,
  withPadder,
} from '@alkemy/components'

const makeLaunchBlock = item => {
  return {
    ...item,
    text: item.text && item.text.replace('<p>', '').replace('</p>', ''),
    list: item.list && item.list.map(i => i.txt),
  }
}

const AdvMultipleLaunchBlockFeatured = ({ items }) => {
  return (
    <>
      {items && (
        <Row flex={true}>
          {items &&
            items.map(item => {
              return <CardBlockFeatured {...makeLaunchBlock(item)} />
            })}
        </Row>
      )}
    </>
  )
}

export default compose(
  withPageBuilder({
    niceName: 'Griglia Cards in Primo piano - Home',
  }),
  withFields([
    {
      prop: 'wrapped',
      type: 'boolean',
      label: 'Contenuto Boxed',
    },
    {
      prop: 'items',
      label: 'Card Blocks',
      type: 'repeater',
      model: [
        {
          prop: 'title',
          label: 'Titolo',
        },
        {
          prop: 'text',
          label: 'label',
        },
        {
          prop: 'img',
          label: 'Immagine Desktop',
        },
        {
          prop: 'imgMobile',
          label: 'Immagine Mobile',
        },
        {
          prop: 'ctaHref',
          label: 'Href',
        },
        {
          prop: 'ctaLabel',
          label: 'Link Label',
        },
      ],
    },
  ]),
  withWrapper,
  withPadder,
)(AdvMultipleLaunchBlockFeatured)
