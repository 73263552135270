"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTextWrapper = exports.SectionWrapper = exports.SectionFullBack = exports.DownloadWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _styles = require("../shared/styles");
var SectionFullBack = exports.SectionFullBack = (0, _styledComponents.default)(_styles.WrapperFullBack).withConfig({
  displayName: "styles__SectionFullBack",
  componentId: "sc-yka3bn-0"
})(["\n  background-color: ", ";\n"], function (props) {
  return props.hasWhiteBg ? "rgba(255, 255, 255, 1)" : "rgba(0, 0, 0, 0.02)";
});
var SectionWrapper = exports.SectionWrapper = (0, _styledComponents.default)(_styles.WrapperSectionWithTitle).withConfig({
  displayName: "styles__SectionWrapper",
  componentId: "sc-yka3bn-1"
})(["\n  justify-content: center;\n  align-items: center;\n\n  padding-top: 40px;\n  padding-bottom: 30px;\n\n  ", " {\n    padding-top: 60px;\n    padding-bottom: 30px;\n  }\n\n  ", " {\n    padding-top: 80px;\n    padding-bottom: 50px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var DownloadWrapper = exports.DownloadWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__DownloadWrapper",
  componentId: "sc-yka3bn-2"
})(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  padding: 0px 40px;\n\n  & > div {\n    margin-bottom: 40px;\n  }\n\n  ", " {\n    flex-direction: row;\n    padding-bottom: 40px;\n\n    & > div {\n      margin-bottom: 0px;\n      flex: 1;\n    }\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledTextWrapper = exports.StyledTextWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTextWrapper",
  componentId: "sc-yka3bn-3"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 16px;\n  max-width: 300px;\n  margin: 0 auto;\n  line-height: 1.5;\n  margin-bottom: 40px;\n\n  @media (min-width: 768px) {\n    max-width: 540px;\n  }\n\n  @media (min-width: 1440px) {\n    max-width: 770px;\n  }\n\n  a {\n    color: ", ";\n  }\n\n  strong {\n    font-family: ", ";\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.color.magenta;
}, function (props) {
  return props.theme.font.medium;
});