"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTriangle = exports.StyledStoreLocatorWrapper = exports.StyledStoreLocatorInfoWrapper = exports.StyledStoreLocator = exports.StyledSearchErrorTitle = exports.StyledSearchErrorText = exports.StyledSearchError = exports.StyledModalIconWrapper = exports.StyledMap = exports.StyledInfoBoxHeader = exports.StyledInfoBox = exports.StyledIconWrapper = exports.StyledHoursWrapper = exports.StyledDetail = exports.StyledDescriptionTitle = exports.StyledDays = exports.StyledCta = exports.StyledCityName = exports.StyledButtonWrapper = exports.StyledAddress = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledStoreLocator = exports.StyledStoreLocator = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledStoreLocator",
  componentId: "sc-374rkk-0"
})(["\n  ", "\n\n  .store-locator__cluster {\n    width: 40px !important;\n    height: 40px !important;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n    background: ", ";\n    border-radius: 50%;\n    color: ", ";\n    font-size: 14px !important;\n  }\n"], function (props) {
  return props.isModal && (0, _styledComponents.css)(["\n      width: 100%;\n      height: 100vh !important;\n      position: fixed;\n      left: 0;\n      top: 0;\n      opacity: 0;\n      visibility: hidden;\n      transition: all 0.2s ease-in-out 0s;\n      z-index: 1000;\n      margin: 0 !important;\n      background-color: ", ";\n\n      @media (min-width: 768px) {\n        overflow: scroll;\n      }\n      @media (min-width: 1440px) {\n        padding-top: 35px;\n      }\n      ", "\n    "], function (props) {
    return props.theme.color.white;
  }, function (props) {
    return props.isVisible && (0, _styledComponents.css)(["\n          opacity: 1;\n          visibility: visible;\n        "]);
  });
}, function (props) {
  return props.theme.color.blueViolet;
}, function (props) {
  return props.theme.color.white;
});
var StyledModalIconWrapper = exports.StyledModalIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledModalIconWrapper",
  componentId: "sc-374rkk-1"
})(["\n  width: 20px;\n  height: 20px;\n  position: fixed;\n  top: 20px;\n  right: 20px;\n  z-index: 20;\n\n  @media (min-width: 1280px) {\n    cursor: pointer;\n  }\n"]);
var StyledStoreLocatorWrapper = exports.StyledStoreLocatorWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledStoreLocatorWrapper",
  componentId: "sc-374rkk-2"
})(["\n  margin: 30px 0;\n  background-color: ", ";\n  opacity: 0;\n\n  ", "\n\n  @media (min-width: 768px) {\n    width: 768px;\n    margin: 0 auto;\n  }\n  @media (min-width: 992px) {\n    width: 100%;\n    heigth: 768px;\n  }\n\n  @media (min-width: 1440px) {\n    width: 1440px;\n    height: 800px;\n  }\n\n  .map-overlay {\n    position: relative;\n    display: flex;\n    flex-direction: column-reverse;\n    flex-direction: row;\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.isLoaded && (0, _styledComponents.css)(["\n      opacity: 1;\n    "]);
});
var StyledMap = exports.StyledMap = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledMap",
  componentId: "sc-374rkk-3"
})(["\n  display: none;\n\n  @media (min-width: 768px) {\n    width: 768px !important;\n    height: 100vh !important;\n    display: block;\n  }\n\n  @media (min-width: 1440px) {\n    width: 800px !important;\n    height: 800px !important;\n  }\n"]);
var StyledStoreLocatorInfoWrapper = exports.StyledStoreLocatorInfoWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledStoreLocatorInfoWrapper",
  componentId: "sc-374rkk-4"
})(["\n  @media (min-width: 768px) {\n    margin: 0 auto;\n    width: 350px;\n    padding: 35px 0;\n  }\n\n  @media (min-width: 1440px) {\n    width: 640px;\n    padding: 100px 100px 75px;\n\n    ", "\n  }\n"], function (props) {
  return props.isModal && (0, _styledComponents.css)(["\n        padding: 100px 100px 150px;\n      "]);
});
var StyledInfoBox = exports.StyledInfoBox = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledInfoBox",
  componentId: "sc-374rkk-5"
})(["\n  width: 350px;\n  min-height: 350px;\n  padding: 30px;\n  background-color: ", ";\n  border-radius: 35px;\n  color: ", ";\n  box-shadow: 0 0 35px 10px ", ";\n\n  position: absolute;\n  top: 50%;\n  transform: translateY(calc(-50% - 30px));\n  left: -390px;\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.color.shadowBlue;
});
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-374rkk-6"
})(["\n  width: 20px;\n  height: 20px;\n  position: absolute;\n  top: 20px;\n  right: 20px;\n  cursor: pointer;\n"]);
var StyledInfoBoxHeader = exports.StyledInfoBoxHeader = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledInfoBoxHeader",
  componentId: "sc-374rkk-7"
})(["\n  width: 240px;\n  margin: 0 auto 16px;\n  padding-bottom: 18px;\n  border-bottom: 1px solid ", ";\n  text-align: center;\n"], function (props) {
  return props.theme.color.gray;
});
var StyledCityName = exports.StyledCityName = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCityName",
  componentId: "sc-374rkk-8"
})(["\n  margin-bottom: 14px;\n  font-family: ", ";\n  font-size: 18px;\n"], function (props) {
  return props.theme.font.medium;
});
var StyledAddress = exports.StyledAddress = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledAddress",
  componentId: "sc-374rkk-9"
})(["\n  font-family: ", ";\n  font-size: 14px;\n"], function (props) {
  return props.theme.font.regular;
});
var StyledDescriptionTitle = exports.StyledDescriptionTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDescriptionTitle",
  componentId: "sc-374rkk-10"
})(["\n  margin-bottom: 10px;\n  font-family: ", ";\n  font-size: 18px;\n  text-align: center;\n"], function (props) {
  return props.theme.font.medium;
});
var StyledHoursWrapper = exports.StyledHoursWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledHoursWrapper",
  componentId: "sc-374rkk-11"
})(["\n  margin-bottom: 10px;\n  font-size: 16px;\n\n  > div {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n\n    span {\n      flex-basis: 50%;\n\n      &:first-child {\n        font-family: ", ";\n      }\n\n      &:last-child {\n        font-family: ", ";\n      }\n    }\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.font.regular;
});
var StyledDays = exports.StyledDays = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDays",
  componentId: "sc-374rkk-12"
})(["\n  flex-basis: 50%;\n  font-family: ", ";\n"], function (props) {
  return props.theme.font.medium;
});
var StyledDetail = exports.StyledDetail = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDetail",
  componentId: "sc-374rkk-13"
})(["\n  flex-basis: 50%;\n  font-family: ", ";\n"], function (props) {
  return props.theme.font.regular;
});
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-374rkk-14"
})(["\n  width: 100%;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  a {\n    width: 200px;\n    padding: 0;\n    margin: 10px 0;\n    line-height: 42px;\n  }\n"]);
var StyledTriangle = exports.StyledTriangle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTriangle",
  componentId: "sc-374rkk-15"
})(["\n  width: 0;\n  height: 0;\n  border-style: solid;\n  border-width: 20px 0 20px 20px;\n  border-color: transparent transparent transparent\n    ", ";\n\n  position: absolute;\n  right: -20px;\n  top: 50%;\n  transform: translateY(-50%);\n"], function (props) {
  return props.theme.color.white;
});
var StyledSearchError = exports.StyledSearchError = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSearchError",
  componentId: "sc-374rkk-16"
})(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  opacity: 0.9;\n  background-color: #fff;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  -webkit-font-smoothing: antialiased;\n\n  @media (min-width: 768px) {\n    width: 768px !important;\n    height: 100vh !important;\n  }\n\n  @media (min-width: 1440px) {\n    width: 800px !important;\n    height: 800px !important;\n  }\n"]);
var StyledSearchErrorTitle = exports.StyledSearchErrorTitle = _styledComponents.default.h3.withConfig({
  displayName: "styles__StyledSearchErrorTitle",
  componentId: "sc-374rkk-17"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 30px;\n  line-height: 40px;\n  margin: 10px 0 20px 0;\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledSearchErrorText = exports.StyledSearchErrorText = _styledComponents.default.p.withConfig({
  displayName: "styles__StyledSearchErrorText",
  componentId: "sc-374rkk-18"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 20px;\n  line-height: 30px;\n  margin: 0 0 30px 0;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledCta = exports.StyledCta = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledCta",
  componentId: "sc-374rkk-19"
})(["\n  padding: 18px 48px;\n  text-align: center;\n  font-family: ", ";\n  color: ", ";\n  font-size: 20px;\n  border-radius: 34px;\n  border: 2px solid ", ";\n  text-decoration: none;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n  align-items: center;\n  transition: all 0.2s;\n\n  &:hover {\n    color: ", ";\n    background-color: ", ";\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.blue;
}, function (props) {
  return props.theme.color.blue;
}, function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.color.blue;
});