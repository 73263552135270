"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _globalStyles = _interopRequireDefault(require("./globalStyles"));
var _oldStyles = _interopRequireDefault(require("./oldStyles"));
var _theme = _interopRequireDefault(require("./theme"));
var _default = exports.default = {
  oldStyles: _oldStyles.default,
  theme: _theme.default,
  globalStyles: _globalStyles.default
};