import React, { Component } from 'react'
import { Contents } from '@alkemy/common'
import { connect } from 'react-redux'
import * as actions from '../redux/loginModal/actions'
import { getLoginModalStatus } from '../redux/loginModal/reducer'
import { LoginModal } from '@alkemy/components'
import axios from 'axios'

const withLoginModal = EnhancedComponent => {
  class WithLoginModal extends Component {
    constructor(props) {
      super(props)

      this.fetchData = this.fetchData.bind(this)

      this.state = {
        showAlert: false,
        showModalLogin: false,
        loginAlert: '',
        modalLoginText: '',
      }
    }

    static async getInitialProps(ctx) {
      let props

      if (typeof EnhancedComponent.getInitialProps === 'function') {
        props = await EnhancedComponent.getInitialProps(ctx)
      }
      return props
    }

    componentDidMount() {
      this.fetchData()
    }

    fetchData() {
      axios(`${process.env.WP_API}/gse/v1/settings/it`).then(res => {
        const data = res.data.login_alert
        this.setState({
          showAlert: data.show_alert_text,
          showModalLogin: data.show_modal_login,
          loginAlert: data.login_alert_text,
          modalLoginText: data.modal_text,
        })
      })
    }

    render() {
      const { isVisible, closeModal } = this.props
      const {
        showAlert,
        showModalLogin,
        loginAlert,
        modalLoginText,
      } = this.state

      return (
        <React.Fragment>
          <EnhancedComponent {...this.props} />
          <LoginModal
            {...Contents.login}
            isVisible={isVisible}
            handleLoginModalClose={closeModal}
            showAlert={showAlert}
            showModalLogin={showModalLogin}
            loginAlert={loginAlert}
            modalLoginText={modalLoginText}
          />
        </React.Fragment>
      )
    }
  }
  const mapStateToProps = state => ({
    isVisible: getLoginModalStatus(state) === 'OPEN',
  })
  return connect(
    mapStateToProps,
    actions,
  )(WithLoginModal)
}

export default withLoginModal
