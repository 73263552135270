"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Wrapper = exports.StyledTitle = exports.StyledPicture = exports.StyledListWrapper = exports.StyledListItem = exports.StyledLink = exports.StyledDescription = exports.StyledButtonWrapper = exports.StyledBoxUtilityPicture = exports.StyledBoxUtility = exports.StyledBoxOfferContainer = exports.StyledBoxOffer = exports.StyledAlternative = exports.Separator = exports.Row = exports.PriceStrikethrough = exports.GreenBadgeStyle = exports.FullWidthColumn = exports.Cta = exports.ColumnTitle = exports.Block = exports.BadgeOfferta = exports.AmazonImage = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var AmazonImage = exports.AmazonImage = _styledComponents.default.img.withConfig({
  displayName: "styles__AmazonImage",
  componentId: "sc-b3ooev-0"
})(["\n  position: absolute;\n  right: 0px;\n  width: 90px;\n  margin-top: -60px;\n\n  ", " {\n    width: 120px;\n    margin-top: -70px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var BadgeOfferta = exports.BadgeOfferta = _styledComponents.default.img.withConfig({
  displayName: "styles__BadgeOfferta",
  componentId: "sc-b3ooev-1"
})(["\n  max-width: 200px;\n  top: -100px;\n  right: -180px;\n  position: absolute;\n\n  @media (max-width: 600px) {\n    top: -280px;\n    right: 0px;\n    display: none;\n  }\n"]);
var Separator = exports.Separator = _styledComponents.default.div.withConfig({
  displayName: "styles__Separator",
  componentId: "sc-b3ooev-2"
})(["\n  width: 100%;\n  height: 1px;\n  border-bottom: 3px dotted rgba(81, 129, 186, 0.3);\n  margin: 30px 0;\n"]);
var StyledPicture = exports.StyledPicture = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledPicture",
  componentId: "sc-b3ooev-3"
})(["\n  width: 665px;\n  padding: 16px 24px;\n  height: 80%;\n  background: ", ";\n  border-radius: 0 35px 35px 0;\n  background-position: center;\n  background-size: cover;\n  position: relative;\n\n  @media (max-width: 600px) {\n    max-width: 290px;\n    width: 290px;\n    border-radius: 35px 35px 0 0;\n    height: 130px;\n    background-position: top;\n  }\n\n  p {\n    font-size: 12px;\n    color: #fff;\n    text-align: right;\n  }\n"], function (props) {
  return 'url(' + props.src + ')';
});
var Cta = exports.Cta = _styledComponents.default.a.withConfig({
  displayName: "styles__Cta",
  componentId: "sc-b3ooev-4"
})(["\n  width: 270px;\n  height: 60px;\n  margin: 0 auto;\n  display: block;\n  padding: 17px 24px;\n  color: #fff;\n  font-family: ", ";\n  border-radius: 30px;\n  line-height: 1.5em;\n  appearance: none;\n  text-align: center;\n  border: none;\n  background: linear-gradient(257deg, rgba(240, 151, 36, 0.7) 2%, #e81c69 21%);\n"], function (props) {
  return props.theme.font.medium;
});
var StyledBoxOfferContainer = exports.StyledBoxOfferContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBoxOfferContainer",
  componentId: "sc-b3ooev-5"
})(["\n  display: flex;\n  align-items: center;\n  margin-bottom: 45px;\n  ", "\n\n  @media (max-width: 600px) {\n    flex-direction: column-reverse;\n    max-width: 100% !important;\n    width: 100% !important;\n    margin-top: 30px;\n    margin-bottom: 0;\n    ", "\n  }\n"], function (props) {
  return props.evidenza && (0, _styledComponents.css)(["\n      height: 550px;\n      @media (max-width: 600px) {\n        height: auto;\n      }\n    "]);
}, function (props) {
  return props.evidenza && (0, _styledComponents.css)(["\n        margin-top: ", ";\n      "], function (props) {
    return props.tag_evidenza ? '46px' : '0px';
  });
});
var StyledBoxOffer = exports.StyledBoxOffer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBoxOffer",
  componentId: "sc-b3ooev-6"
})(["\n  width: auto;\n  box-shadow: 0 0 35px 0 ", ";\n  position: relative;\n  margin-top: 0;\n  max-width: 670px;\n  border-radius: 35px;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n\n  @media (max-width: 600px) {\n    height: auto;\n    max-width: 340px;\n    z-index: 99;\n    background: #fff;\n  }\n\n  .box-content {\n    padding: 36px 62px 45px 59px;\n    overflow: hidden;\n    border-radius: 35px 35px 0 0;\n    margin-bottom: auto;\n\n    @media (max-width: 600px) {\n      padding: 24px;\n    }\n  }\n\n  .termsConditions {\n    width: 100%;\n    background-image: linear-gradient(to top, #fff 73%, #f2f2f2);\n    text-align: center;\n    padding: 16px;\n    border-radius: 0 0 35px 35px;\n    a {\n      color: #e81c69;\n      font-size: 14px;\n    }\n  }\n\n  .vantaggi {\n    list-style: none;\n    padding: 0;\n\n    li {\n      list-style: none;\n      display: flex;\n      align-items: center;\n      min-height: 48px;\n\n      @media (max-width: 600px) {\n        font-size: 16px;\n        line-height: 1.2em;\n        margin-bottom: 16px;\n      }\n\n      svg {\n        margin-right: 16px;\n        width: 24px;\n        height: 24px;\n      }\n    }\n  }\n"], function (props) {
  return props.theme.color.shadowBlue;
});
var Row = exports.Row = _styledComponents.default.div.withConfig({
  displayName: "styles__Row",
  componentId: "sc-b3ooev-7"
})(["\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n"]);
var StyledBoxUtility = exports.StyledBoxUtility = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBoxUtility",
  componentId: "sc-b3ooev-8"
})(["\n  max-width: ", ";\n  padding: ", ";\n\n  border-radius: 4px;\n  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);\n  background-color: #fecb1c;\n  font-family: ", ";\n  font-size: 18px;\n  font-weight: 500;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.56;\n  letter-spacing: normal;\n  color: #3f5063;\n\n  position: ", ";\n  top: ", ";\n  right: ", ";\n\n  span {\n    transform: ", ";\n  }\n"], function (props) {
  return props.skew ? '188px' : '123px';
}, function (props) {
  return props.skew ? '20px 24px 14px' : '2px 8px';
}, function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.skew ? 'absolute' : 'relative';
}, function (props) {
  return props.skew ? '-62px' : 'auto';
}, function (props) {
  return props.skew ? '46px' : 'auto';
}, function (props) {
  return props.skew ? 'skew(16deg)' : 'none';
});
var StyledBoxUtilityPicture = exports.StyledBoxUtilityPicture = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBoxUtilityPicture",
  componentId: "sc-b3ooev-9"
})(["\n  max-width: ", ";\n  padding: ", ";\n  border-radius: 4px;\n  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);\n  background-color: #fecb1c;\n  color: #3f5063;\n  font-family: ", ";\n  font-size: 18px;\n  font-weight: 500;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.56;\n\n  position: ", ";\n  top: ", ";\n  right: ", ";\n\n  @media (max-width: 600px) {\n    width: 100%;\n    max-width: 150px;\n    font-size: 14px;\n    height: 50px;\n    top: -46px;\n    right: 26px;\n    text-align: center;\n    padding: 18px 20px 10px;\n    z-index: -1;\n  }\n"], function (props) {
  return props.skew ? '188px' : '123px';
}, function (props) {
  return props.skew ? '20px 24px 14px' : '2px 8px';
}, function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.skew ? 'absolute' : 'relative';
}, function (props) {
  return props.skew ? '-62px' : 'auto';
}, function (props) {
  return props.skew ? '46px' : 'auto';
});
var ColumnTitle = exports.ColumnTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__ColumnTitle",
  componentId: "sc-b3ooev-10"
})(["\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: flex-start;\n  width: 100%;\n  margin-bottom: 24px;\n  @media (max-width: 600px) {\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: start;\n  }\n"]);
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-b3ooev-11"
})(["\n  font-family: ", ";\n  font-size: 22px;\n  line-height: 1.36;\n  color: ", ";\n  text-align: left;\n  margin-right: 28px;\n  ", " {\n    font-size: 30px;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledDescription = exports.StyledDescription = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDescription",
  componentId: "sc-b3ooev-12"
})(["\n  min-height: 30px;\n  font-family: ", ";\n  font-size: 24px;\n  line-height: 36px;\n  color: ", ";\n  text-align: left;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var GreenBadgeStyle = exports.GreenBadgeStyle = _styledComponents.default.div.withConfig({
  displayName: "styles__GreenBadgeStyle",
  componentId: "sc-b3ooev-13"
})(["\n  font-family: ", ";\n  font-weight: 400;\n  color: #6ec18a;\n  text-align: center;\n  span {\n    float: left;\n    padding-top: 5px;\n  }\n"], function (props) {
  return props.theme.font.medium;
});
var StyledListWrapper = exports.StyledListWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledListWrapper",
  componentId: "sc-b3ooev-14"
})(["\n  font-size: 14px;\n\n  color: ", ";\n  > img {\n    width: 100%;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
});
var StyledListItem = exports.StyledListItem = _styledComponents.default.li.withConfig({
  displayName: "styles__StyledListItem",
  componentId: "sc-b3ooev-15"
})(["\n  min-height: 12px;\n  font-family: ", ";\n  position: relative;\n  padding-left: 22px;\n  padding-top: 8px;\n  vertical-align: middle;\n\n  &:not(:last-child) {\n    margin-bottom: 13px;\n  }\n"], function (props) {
  return props.theme.font.regular;
});
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-b3ooev-16"
})(["\n  width: 160px;\n  position: absolute;\n  left: 50%;\n  bottom: -21px;\n  padding: 0 auto;\n  transform: translateX(-50%);\n  > button {\n    line-height: 40px;\n    padding: 0;\n  }\n  > a {\n    padding: 0;\n  }\n"]);
var StyledLink = exports.StyledLink = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledLink",
  componentId: "sc-b3ooev-17"
})(["\n  display: block;\n  font-size: 14px;\n  font-family: ", ";\n  cursor: pointer;\n  margin-bottom: 10px;\n  text-decoration: underline;\n  color: #e81c69;\n"], function (props) {
  return props.theme.font.regular;
});
var FullWidthColumn = exports.FullWidthColumn = _styledComponents.default.div.withConfig({
  displayName: "styles__FullWidthColumn",
  componentId: "sc-b3ooev-18"
})(["\n  display: flex;\n  flex: 1 0 100%;\n  text-align: right;\n"]);
var PriceStrikethrough = exports.PriceStrikethrough = _styledComponents.default.div.withConfig({
  displayName: "styles__PriceStrikethrough",
  componentId: "sc-b3ooev-19"
})(["\n  text-decoration: line-through;\n  font-size: 12px;\n  display: block;\n  width: 100%;\n  margin-right: 0px;\n"]);
var Wrapper = exports.Wrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-b3ooev-20"
})(["\n  display: flex;\n  width: 100%;\n  line-height: 50px;\n"]);
var Block = exports.Block = _styledComponents.default.div.withConfig({
  displayName: "styles__Block",
  componentId: "sc-b3ooev-21"
})(["\n  margin: 0px 0 20px 0px;\n  padding: 0;\n  position: relative;\n  font-family: ", ";\n  font-weight: 400;\n  color: #3f5063;\n  b,\n  strong {\n    font-family: ", ";\n    font-weight: 400;\n  }\n  img {\n    padding: 10px 0;\n  }\n  small {\n    font-size: 10px;\n    display: block;\n    padding-top: 10px;\n    padding-bottom: 0px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.font.bold;
});
var StyledAlternative = exports.StyledAlternative = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledAlternative",
  componentId: "sc-b3ooev-22"
})(["\n  width: 75%;\n  position: absolute;\n  top: -30px;\n  left: 50%;\n  transform: translateX(-50%);\n  font-family: ", ";\n  background: ", ";\n  padding: 15px 20px;\n  text-align: center;\n  color: ", ";\n  border-radius: 10px;\n  font-size: 14px;\n  text-transform: uppercase;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.pinkOrange;
}, function (props) {
  return props.theme.color.white;
});