"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledSmalltext = exports.StyledItem = exports.StyledIconWrapper = exports.StyledChiSiamoIconList = exports.StyledBlobWrapper = exports.StyledBigtext = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledChiSiamoIconList = exports.StyledChiSiamoIconList = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledChiSiamoIconList",
  componentId: "sc-16pste0-0"
})(["\n text-align: center;\n width: 150px;\n margin: 70px auto 40px;\n \n @media (min-width: 768px) {\n  width: 360px;\n  height: 120px;\n  margin: 60px auto;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-content: center;\n }\n \n @media (min-width: 1440px) {\n  width: 500px;\n  height: 170px;\n  margin: 25px auto 75px;\n }\n"]);
var StyledItem = exports.StyledItem = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledItem",
  componentId: "sc-16pste0-1"
})(["\n  width: 150px;\n  margin-bottom: 60px;\n  \n  @media (min-width: 768px) {\n    margin-bottom: 0;\n  }\n  \n  @media (min-width: 1440px) {\n    width: 195px;\n  }\n"]);
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-16pste0-2"
})(["\n  margin: 0 auto;\n  \n  ", "\n  \n  ", "\n  \n"], function (props) {
  return props.icon === 'Clients' && (0, _styledComponents.css)(["\n    width: 95px;\n    \n    @media (min-width: 768px) {\n      height: 74px;\n      position: relative;\n      top: -12px;\n    }\n    \n    @media (min-width: 1440px) {\n      width: 105px;\n      height: 94px;\n    }\n  "]);
}, function (props) {
  return props.icon === 'Graph' && (0, _styledComponents.css)(["\n    width: 45px;\n    margin-bottom: 15px;\n    \n    @media (min-width: 768px) {\n      height: 47px;\n      margin-top: 13px;\n    }\n    \n    @media (min-width: 1440px) {\n      width: 65px;\n      height: 67px;\n    }\n  "]);
});
var StyledSmalltext = exports.StyledSmalltext = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSmalltext",
  componentId: "sc-16pste0-3"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 18px;\n  \n  @media (min-width: 1440px) {\n    font-size: 22px;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledBigtext = exports.StyledBigtext = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBigtext",
  componentId: "sc-16pste0-4"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 40px;\n  \n  @media (min-width: 1440px) {\n    font-size: 50px;\n  }\n  \n  span {\n    font-size: 20px;\n    \n    @media (min-width: 1440px) {\n      font-size: 25px;\n    }\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledBlobWrapper = exports.StyledBlobWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBlobWrapper",
  componentId: "sc-16pste0-5"
})(["\n  \n"]);