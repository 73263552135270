"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BreadcrumbWrapper = exports.BreadcrumbSep = exports.BreadcrumbItemNoLink = exports.BreadcrumbItem = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var BreadcrumbWrapper = exports.BreadcrumbWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__BreadcrumbWrapper",
  componentId: "sc-19iz5fn-0"
})(["\n  position: relative;\n  margin: 0;\n\n  display: none;\n\n  ", " {\n    display: flex;\n    flex-direction: row;\n  }\n\n  ", " {\n    margin-top: 50px;\n    margin-bottom: 43px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var BreadcrumbItem = exports.BreadcrumbItem = _styledComponents.default.a.withConfig({
  displayName: "styles__BreadcrumbItem",
  componentId: "sc-19iz5fn-1"
})(["\n  cursor: pointer;\n  text-transform: capitalize;\n  outline: none;\n  text-decoration: none;\n\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: ", ";\n  font-style: normal;\n  font-stretch: normal;\n  letter-spacing: normal;\n  line-height: normal;\n  padding: 0px 7px 0px 8px;\n\n  &:first-child {\n    padding-left: 0;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.isSelected ? "bold" : "normal";
});
var BreadcrumbItemNoLink = exports.BreadcrumbItemNoLink = _styledComponents.default.span.withConfig({
  displayName: "styles__BreadcrumbItemNoLink",
  componentId: "sc-19iz5fn-2"
})(["\n  text-transform: capitalize;\n  outline: none;\n  text-decoration: none;\n\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: ", ";\n  font-style: normal;\n  font-stretch: normal;\n  letter-spacing: normal;\n  line-height: normal;\n  padding: 0px 7px 0px 8px;\n\n  &:first-child {\n    padding-left: 0;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.isSelected ? "bold" : "normal";
});
var BreadcrumbSep = exports.BreadcrumbSep = _styledComponents.default.div.withConfig({
  displayName: "styles__BreadcrumbSep",
  componentId: "sc-19iz5fn-3"
})(["\n  width: 8px;\n  height: 8px;\n  transform: rotate(45deg);\n  border-top: solid 2px #6ec18a;\n  border-right: solid 2px #6ec18a;\n  margin: auto 0;\n"]);