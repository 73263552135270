import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { MgmHome } from '@alkemy/components'

export default compose(
  withPageBuilder({
    niceName: 'Member Get Member Home',
  }),
  withFields([
    {
      prop: 'Script',
      label: 'Script',
    },
    {
      prop: 'uid',
      label: 'UIID',
    },
  ]),
)(MgmHome)
