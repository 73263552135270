"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitleWrapper = exports.StyledTextWrapper = exports.StyledSalesPoints = exports.StyledListWrapper = exports.StyledListItemInfoWrapper = exports.StyledListItemIconWrapper = exports.StyledListItem = exports.StyledIconWrapper = exports.StyledButtonWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledSalesPoints = exports.StyledSalesPoints = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSalesPoints",
  componentId: "sc-15mxi5i-0"
})(["\n  margin: 50px 0 80px;\n  padding: 35px 0 60px;\n  position: relative;\n  background: ", ";\n  color: ", ";\n  -webkit-font-smoothing: antialiased;\n\n  @media (min-width: 768px) {\n    width: calc(50% - 14px);\n    height: 560px;\n    margin-right: 28px;\n    border-radius: 35px;\n    float: left;\n  }\n\n  @media (min-width: 1440px) {\n    width: calc(50% - 15px);\n    height: 550px;\n    padding: 115px 0 130px;\n    margin: 32px 30px 160px 0;\n  }\n"], function (props) {
  return props.theme.color.greenBlue;
}, function (props) {
  return props.theme.color.white;
});
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-15mxi5i-1"
})(["\n  width: 54px;\n  height: 54px;\n  position: absolute;\n  top: -35px;\n  left: 50%;\n  transform: translateX(-50%);\n  border-radius: 50%;\n  background: ", ";\n\n  > div {\n    position: relative;\n    z-index: 1;\n  }\n\n  > canvas {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%) scale(0.6);\n    z-index: 0;\n\n    ", " {\n      transform: translate(-50%, -50%) scale(0.6);\n    }\n\n    ", " {\n      transform: translate(-50%, -50%) scale(1);\n    }\n  }\n\n  @media (min-width: 1440px) {\n    width: 65px;\n    height: 65px;\n    top: -32px;\n  }\n"], function (props) {
  return props.theme.color.transparent;
}, function (props) {
  return props.theme.mediaQueries.ipad;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var StyledTitleWrapper = exports.StyledTitleWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitleWrapper",
  componentId: "sc-15mxi5i-2"
})(["\n  margin-bottom: 12px;\n  text-align: center;\n  font-family: ", ";\n  color: ", ";\n\n  font-size: 22px;\n\n  @media (min-width: 1440px) {\n    margin-bottom: 18px;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.white;
});
var StyledTextWrapper = exports.StyledTextWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTextWrapper",
  componentId: "sc-15mxi5i-3"
})(["\n  margin-bottom: 25px;\n  padding: 0 20px;\n  font-family: ", ";\n  font-size: 16px;\n  color: ", ";\n\n  @media (min-width: 1440px) {\n    margin-bottom: 65px;\n    padding: 0 53px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.white;
});
var StyledListWrapper = exports.StyledListWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledListWrapper",
  componentId: "sc-15mxi5i-4"
})(["\n  max-width: 240px;\n  margin: 0 auto;\n  font-family: ", ";\n  font-size: 14px;\n\n  @media (min-width: 768px) {\n    max-width: 280px;\n  }\n\n  @media (min-width: 1440px) {\n    max-width: 530px;\n    font-size: 12px;\n    overflow: hidden;\n  }\n"], function (props) {
  return props.theme.font.regular;
});
var StyledListItem = exports.StyledListItem = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledListItem",
  componentId: "sc-15mxi5i-5"
})(["\n  margin-bottom: 23px;\n  display: flex;\n  align-items: center;\n  flex-flow: row;\n  justify-content: flex-start;\n  position: relative;\n\n  @media (min-width: 1440px) {\n    width: 20%;\n    height: 145px;\n    flex-flow: column;\n    justify-items: center;\n    float: left;\n    text-align: center;\n    > span {\n      max-width: 100px;\n    }\n  }\n"]);
var StyledListItemIconWrapper = exports.StyledListItemIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledListItemIconWrapper",
  componentId: "sc-15mxi5i-6"
})(["\n  width: 34px;\n  height: 34px;\n  display: block;\n  margin-right: 23px;\n\n  // TODO REFACTOR ICONA LISTCHECK\n  polyline {\n    stroke: white !important;\n  }\n\n  path {\n    fill: white;\n  }\n\n  @media (min-width: 1440px) {\n    width: 65px;\n    height: 65px;\n    margin-right: 0;\n    margin-bottom: 15px;\n    display: block;\n  }\n"]);
var StyledListItemInfoWrapper = exports.StyledListItemInfoWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledListItemInfoWrapper",
  componentId: "sc-15mxi5i-7"
})(["\n  width: 22px;\n  height: 22px;\n  fill: ", ";\n  margin-right: 20px;\n\n  @media (min-width: 768px) {\n    margin-right: 0;\n    position: absolute;\n    right: 0;\n\n    button {\n      min-width: 300px;\n    }\n  }\n\n  @media (min-width: 1440px) {\n    right: initial;\n    bottom: 0;\n    cursor: pointer;\n  }\n"], function (props) {
  return props.theme.color.white;
});
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-15mxi5i-8"
})(["\n  min-width: 240px;\n  position: absolute;\n  bottom: -21px;\n  left: 50%;\n  transform: translateX(-50%);\n\n  font-family: ", ";\n  font-size: 16px;\n  font-weight: normal;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: normal;\n  letter-spacing: normal;\n  text-align: center;\n\n  @media (min-width: 1440px) {\n    bottom: -30px;\n    font-size: 20px;\n  }\n"], function (props) {
  return props.theme.font.regular;
});