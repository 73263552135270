"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VideoDiv = exports.PlayButton = exports.Placeholder = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var VideoDiv = exports.VideoDiv = _styledComponents.default.div.withConfig({
  displayName: "styles__VideoDiv",
  componentId: "sc-9trzxj-0"
})(["\n  width: 100%;\n  height: 450px;\n"]);
var PlayButton = exports.PlayButton = _styledComponents.default.img.withConfig({
  displayName: "styles__PlayButton",
  componentId: "sc-9trzxj-1"
})(["\n  width: 60px;\n  height: 60px;\n  cursor: pointer;\n"]);
var Placeholder = exports.Placeholder = _styledComponents.default.div.withConfig({
  displayName: "styles__Placeholder",
  componentId: "sc-9trzxj-2"
})(["\n  width: 100%;\n  height: 300px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-position: center;\n  background-size: cover;\n  background-image: url(", ");\n"], function (props) {
  return props.placeholderImg;
});