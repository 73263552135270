import React from 'react'
import styled from 'styled-components'
import OpenModal from '../components/OpenModal'

import {IconsCTABlock} from '@alkemy/components'

const ListOfIconsCTA = ({items}) => {
  return (
    <OpenModal>
      {({handleOpen, openStoreLocatorModal}) => (
        <Grid>
          {items &&
            items.length > 0 &&
            items.map((item, index) => {
              return (
                <Block key={index}>
                  <IconsCTABlock
                    {...item}
                    handleOpen={handleOpen}
                    openStoreLocatorModal={openStoreLocatorModal}
                  />
                </Block>
              )
            })}
        </Grid>
      )}
    </OpenModal>
  )
}

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const Block = styled.div`
  flex: 1 0 30%;
  margin: 10px;
`

export default ListOfIconsCTA
