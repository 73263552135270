import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { AbsoluteBlob, Wrapper } from '@alkemy/components'
import {
  withStartGradientColors,
  withStopGradientColors,
} from '../lib/withGradientColors'

const BuildedAbsoluteBlob = ({
  positionTop = 'initial',
  positionRight = 'initial',
  positionBottom = 'initial',
  positionLeft = 'initial',
  width = 360,
  height = 360,
  startColor = '#1587C8',
  stopColor = '#7E2889',
  padding = 60,
  strength = 30,
  gradientRotation = -45,
  points = 20,
}) => {
  const position = {
    top: positionTop,
    right: positionRight,
    bottom: positionBottom,
    left: positionLeft,
  }

  const blob = {
    width,
    height,
    padding,
    strength,
    startColor,
    stopColor,
    gradientRotation,
    points,
  }

  return (
    <Wrapper>
      <AbsoluteBlob blob={blob} position={position} />
    </Wrapper>
  )
}

export default compose(
  withPageBuilder(),
  withFields([
    {
      prop: 'width',
      label: 'Larghezza',
    },
    {
      prop: 'height',
      label: 'Altezza',
    },
    {
      prop: 'positionTop',
      label: 'Posizione Top (px)',
    },
    {
      prop: 'positionRight',
      label: 'Posizione Right (px)',
    },
    {
      prop: 'positionBottom',
      label: 'Posizione Bottom (px)',
    },
    {
      prop: 'positionLeft',
      label: 'Posizione Left (px)',
    },
    withStartGradientColors(),
    withStopGradientColors(),
  ]),
)(BuildedAbsoluteBlob)
