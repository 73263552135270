"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledModalWrapper = exports.StyledListWithModal = exports.StyledListItemText = exports.StyledListItem = exports.StyledInfoIconWrapper = exports.StyledCheckIconWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledListWithModal = exports.StyledListWithModal = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledListWithModal",
  componentId: "sc-10dc4yc-0"
})([""]);
var StyledListItem = exports.StyledListItem = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledListItem",
  componentId: "sc-10dc4yc-1"
})(["\n  position: relative;\n  margin-bottom: 20px;\n  min-height: 32px;\n"]);
var StyledCheckIconWrapper = exports.StyledCheckIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCheckIconWrapper",
  componentId: "sc-10dc4yc-2"
})(["\n  width: 32px;\n  height: 32px;\n  position: absolute;\n  left: 0;\n  top: 0;\n"]);
var StyledListItemText = exports.StyledListItemText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledListItemText",
  componentId: "sc-10dc4yc-3"
})(["\n  padding-left: 62px;\n  font-family: ", ";\n  color: ", ";\n  font-size: 16px;\n  vertical-align: middle;\n  line-height: 32px;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledInfoIconWrapper = exports.StyledInfoIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledInfoIconWrapper",
  componentId: "sc-10dc4yc-4"
})(["\n  width: 22px;\n  height: 22px;\n  position: absolute;\n  right: 0;\n  top: 50%;\n  transform: translateY(-50%);\n  \n  @media (min-width: 1280px) {\n    cursor: pointer;\n  }\n"]);
var StyledModalWrapper = exports.StyledModalWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledModalWrapper",
  componentId: "sc-10dc4yc-5"
})(["\n  opacity: 0;\n  visibility: hidden;\n  transition: all .2s ease-in-out 0s;\n  position: absolute;\n  right: -50%;\n  bottom: 32px;\n  \n  ", "\n"], function (props) {
  return props.isVisible && (0, _styledComponents.css)(["\n    opacity: 1;\n    visibility: visible;  \n  "]);
});