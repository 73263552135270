"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitle = exports.StyledText = exports.StyledSeparator = exports.StyledMonth = exports.StyledLocation = exports.StyledLinkWrapper = exports.StyledImage = exports.StyledIconWrapper = exports.StyledGeopin = exports.StyledEventBlock = exports.StyledDay = exports.StyledDate = exports.StyledContentWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledImage = exports.StyledImage = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledImage",
  componentId: "sc-uvv5x-0"
})(["\n  width: 34.37%;\n  height: 100%;\n  float: left;\n  position: relative;\n\n  background-image: url(", ");\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n\n  @media (min-width: 768px) {\n    width: 100%;\n    height: 220px;\n    float: none;\n    border-radius: 15px;\n  }\n"], function (props) {
  return props.imgUrl + '?width=768&auto=format';
});
var StyledContentWrapper = exports.StyledContentWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContentWrapper",
  componentId: "sc-uvv5x-1"
})(["\n  width: 65.63%;\n  height: 100%;\n  padding: 13px;\n  float: left;\n\n  @media (min-width: 768px) {\n    width: 100%;\n    padding: 15px 0 0;\n    float: none;\n  }\n"]);
var StyledDate = exports.StyledDate = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDate",
  componentId: "sc-uvv5x-2"
})(["\n  width: 44px;\n  height: 66px;\n  position: absolute;\n  top: 12px;\n  left: 12px;\n  background-color: ", ";\n  border-radius: 3.5px;\n  text-align: center;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-evenly;\n  align-items: center;\n\n  @media (min-width: 768px) {\n    width: 62px;\n    height: 93px;\n    top: 20px;\n    left: 20px;\n    border-radius: 5px;\n  }\n"], function (props) {
  return props.theme.color.white;
});
var StyledSeparator = exports.StyledSeparator = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSeparator",
  componentId: "sc-uvv5x-3"
})(["\n  width: 34px;\n  height: 1px;\n  margin: 0 auto;\n  background-color: ", ";\n\n  @media (min-width: 768px) {\n    width: 50px;\n  }\n"], function (props) {
  return props.theme.color.darkGray;
});
var StyledDay = exports.StyledDay = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDay",
  componentId: "sc-uvv5x-4"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 34px;\n  line-height: 1.1;\n\n  @media (min-width: 768px) {\n    font-size: 48px;\n  }\n"], function (props) {
  return props.theme.color.magenta;
}, function (props) {
  return props.theme.font.regular;
});
var StyledMonth = exports.StyledMonth = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledMonth",
  componentId: "sc-uvv5x-5"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n\n  @media (min-width: 768px) {\n    font-size: 22px;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.medium;
});
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-uvv5x-6"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 18px;\n  margin-bottom: 12px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  line-height: 1;\n\n  @media (min-width: 768px) {\n    margin-bottom: 25px;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledText = exports.StyledText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledText",
  componentId: "sc-uvv5x-7"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 12px;\n  line-height: 1.5;\n\n  @media (min-width: 768px) {\n    margin-bottom: 30px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledLinkWrapper = exports.StyledLinkWrapper = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledLinkWrapper",
  componentId: "sc-uvv5x-8"
})(["\n  display: none;\n  text-decoration: none;\n  color: ", ";\n  font-family: ", ";\n  cursor: pointer;\n\n  &:visited {\n    color: ", ";\n  }\n\n  @media (min-width: 768px) {\n    margin-bottom: 60px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    float: right;\n    pointer-events: all !important;\n  }\n"], function (props) {
  return props.theme.color.magenta;
}, function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.magenta;
});
var StyledLocation = exports.StyledLocation = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledLocation",
  componentId: "sc-uvv5x-9"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 16px;\n  margin-bottom: 12px;\n  pointer-events: all !important;\n  display: flex;\n  align-items: flex-end;\n  text-decoration: none;\n  cursor: pointer;\n\n  @media (min-width: 768px) {\n    margin-bottom: 29px;\n  }\n\n  &:visited {\n    color: ", ";\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.magenta;
}, function (props) {
  return props.theme.color.magenta;
});
var StyledGeopin = exports.StyledGeopin = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledGeopin",
  componentId: "sc-uvv5x-10"
})(["\n  width: 18px;\n  height: 25px;\n  margin-right: 10px;\n  background-image: url('/static/images/store-locator/pin.svg');\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n"]);
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-uvv5x-11"
})(["\n  @media (min-width: 768px) {\n    width: 15px;\n    margin-left: 15px;\n  }\n"]);
var StyledEventBlock = exports.StyledEventBlock = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledEventBlock",
  componentId: "sc-uvv5x-12"
})(["\n  width: 100%;\n  height: 160px;\n  overflow: hidden;\n  -webkit-font-smoothing: antialiased;\n  display: block;\n  text-decoration: none;\n  background-color: ", ";\n  box-shadow: 0 0 35px 0 ", ";\n\n  @media (min-width: 768px) {\n    //width: 330px;\n    height: auto;\n    pointer-events: none;\n    box-shadow: none;\n  }\n\n  ", "\n\n  ", "\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.color.shadowBlue;
}, function (props) {
  return props.isPreview && (0, _styledComponents.css)(["\n      @media (max-width: 767px) {\n        width: 240px;\n        height: auto;\n        margin: 0 auto;\n        border-radius: 15px;\n\n        display: flex;\n        flex-direction: column;\n\n        ", " {\n          width: 100%;\n          height: 180px;\n        }\n\n        ", " {\n          width: 100%;\n          height: 185px;\n        }\n      }\n    "], StyledImage, StyledContentWrapper);
}, function (props) {
  return props.isRelevant && (0, _styledComponents.css)(["\n      @media (min-width: 768px) {\n        display: flex;\n        flex-direction: row;\n        flex-wrap: wrap;\n        background-color: transparent;\n      }\n\n      ", " {\n        @media (min-width: 768px) {\n          height: 240px;\n          flex-basis: 47.41%;\n          margin-right: 5.18%;\n        }\n\n        @media (min-width: 1440px) {\n          height: 320px;\n          flex-basis: 65.81%;\n          margin-right: 4.27%;\n        }\n      }\n\n      ", " {\n        @media (min-width: 768px) {\n          height: 240px;\n          padding-top: 40px;\n          flex-basis: 47.41%;\n          position: relative;\n        }\n\n        @media (min-width: 1440px) {\n          height: 320px;\n          padding-top: 50px;\n          flex-basis: 29.92%;\n        }\n      }\n\n      ", " {\n        //@media (min-width: 768px) {\n        //  margin-bottom: 15px;\n        //  font-size: 10px;\n        //}\n        //\n        //@media (min-width: 1440px) {\n        //  margin-bottom: 35px;\n        //}\n      }\n\n      ", " {\n        @media (min-width: 768px) {\n          margin-bottom: 25px;\n          font-size: 18px;\n        }\n      }\n\n      ", " {\n        @media (min-width: 768px) {\n          margin-bottom: 15px;\n          font-size: 14px;\n          line-height: 1.6;\n        }\n\n        @media (min-width: 1440px) {\n          width: 85.7%;\n        }\n      }\n\n      ", " {\n        @media (min-width: 768px) {\n          margin-bottom: 0;\n          position: absolute;\n          bottom: 0;\n          right: 0;\n        }\n      }\n    "], StyledImage, StyledContentWrapper, StyledDate, StyledTitle, StyledText, StyledLinkWrapper);
});