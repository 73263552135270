"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledWrapper = exports.StyledTitle = exports.StyledOutro = exports.StyledMainText = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledTitle = exports.StyledTitle = _styledComponents.default.h1.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-15duwy5-0"
})(["\n  margin: 0;\n  font-size: 45px;\n  font-weight: 500;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.24;\n  letter-spacing: normal;\n  max-width: 381px;\n\n  ", " {\n    max-width: auto;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var StyledMainText = exports.StyledMainText = _styledComponents.default.p.withConfig({
  displayName: "styles__StyledMainText",
  componentId: "sc-15duwy5-1"
})(["\n  margin: 0;\n  margin-top: 8px;\n  font-size: 24px;\n  font-weight: 500;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.5;\n  letter-spacing: normal;\n  max-width: 472px;\n\n  ", " {\n    max-width: auto;\n    font-size: 24px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var StyledOutro = exports.StyledOutro = _styledComponents.default.span.withConfig({
  displayName: "styles__StyledOutro",
  componentId: "sc-15duwy5-2"
})(["\n  margin: 0;\n  margin-top: 16px;\n  font-family: ", ";\n  font-size: 12px;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.5;\n  letter-spacing: normal;\n"], function (props) {
  return props.theme.font.regular;
});
var StyledWrapper = exports.StyledWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledWrapper",
  componentId: "sc-15duwy5-3"
})(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  gap: ", "px;\n  color: ", ";\n  font-family: ", ";\n\n  ", " {\n    padding-left: 20px;\n    width: 80%;\n    font-family: ", ";\n\n    ", " {\n      font-size: 22px;\n    }\n\n    ", " {\n      font-size: 16px;\n    }\n\n    ", " {\n      display: none;\n    }\n  }\n"], function (props) {
  return props.gap;
}, function (props) {
  return props.color;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  return props.theme.font.medium;
}, StyledTitle, StyledMainText, StyledOutro);