import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { Iframe } from '@alkemy/components'

export default compose(
  withPageBuilder({
    niceName: 'Iframe',
  }),
  withFields([
    {
      prop: 'Title',
      label: 'Titolo',
    },
    {
      prop: 'Url',
      label: 'Url',
    },
  ]),
)(Iframe)
