import moment from 'moment'

export const generateEventDate = date => ({
  day: date.format('DD'),
  month: date.format('MM'),
})

export const generateHrefBasedOnPostType = ({
  post_name,
  post_type,
  parents,
  permalink,
  acf,
}) => {
  let parentsSlugs =
    parents && parents.length > 0 && parents.map(item => item.post_name)
  if (parentsSlugs) {
    parentsSlugs.splice(-1, 1)
    parentsSlugs =
      parentsSlugs && parentsSlugs.length > 0
        ? `${parentsSlugs.join('/')}/`
        : ''
  }

  switch (post_type) {
    case 'news':
      return `/news/${parentsSlugs}${post_name}`
    case 'event':
      return `/eventi/${parentsSlugs}${post_name}`
    case 'document':
      return acf.document_attachment
    case 'shop':
      return `/punti-vendita/${parentsSlugs}${post_name}`
    default:
      return permalink
  }
}

const getFeaturedImg = (acf, index, better_featured_image, isRelevant) => {
  if (isRelevant) {
    return (
      (acf && acf.relevant_image && acf.relevant_image.sizes.large) ||
      (better_featured_image && better_featured_image.source_url)
    )
  }
  return better_featured_image && better_featured_image.source_url
}

const getFeaturedImgMobile = (
  acf,
  index,
  better_featured_image,
  isRelevant,
) => {
  if (isRelevant) {
    return (
      (acf &&
        acf.relevant_image_mobile &&
        acf.relevant_image_mobile.sizes.large) ||
      (better_featured_image && better_featured_image.source_url)
    )
  }
  return better_featured_image && better_featured_image.source_url
}

const checkIsRelevant = (index, isRelevantAlwaysFalse) => {
  if (isRelevantAlwaysFalse) return false
  if (index === 0) return true
  return false
}
const checkIsRelevantInNewsArchive = acf => {
  if (acf && acf.isRelevant && acf.isRelevant[0] === 'true') {
    return true
  }
  return false
}

export const makeNewsEvents = (
  { id, better_featured_image, date, title, excerpt, type, acf, slug },
  isRelevantAlwaysFalse = false,
  index,
) => ({
  id,
  img: getFeaturedImg(
    acf,
    index,
    better_featured_image,
    checkIsRelevant(index),
  ),
  imgMobile: getFeaturedImgMobile(
    acf,
    index,
    better_featured_image,
    checkIsRelevant(index),
  ),
  date:
    type === 'news'
      ? new moment(date).locale('it').format('DD MMMM YYYY')
      : generateEventDate(new moment(acf.date_time, 'DD/MM/YYYY')),
  title: title && title.rendered,
  text: excerpt && excerpt.rendered.replace('<p>', '').replace('</p>', ''),
  slug: `${type === 'event' ? 'eventi' : type}/${slug}`,
  isRelevant: checkIsRelevant(index, isRelevantAlwaysFalse),
  position: {
    city: acf && acf.event_location && acf.event_location.address,
    lat: acf && acf.event_location && acf.event_location.lat,
    lng: acf && acf.event_location && acf.event_location.lng,
  },
  type,
})

export const makeNews = (
  { id, better_featured_image, date, title, excerpt, type, acf, slug },
  index,
) => ({
  id,
  img: getFeaturedImg(
    acf,
    index,
    better_featured_image,
    checkIsRelevantInNewsArchive(acf),
  ),
  date: new moment(date).locale('it').format('DD MMMM YYYY'),
  title: title && title.rendered,
  text: excerpt && excerpt.rendered.replace('<p>', '').replace('</p>', ''),
  slug: `${type}/${slug}`,
  isRelevant: checkIsRelevantInNewsArchive(acf),
  type,
})

export const makeEvent = (
  { id, better_featured_image, date, title, excerpt, type, acf, slug },
  isRelevantAlwaysFalse,
  index,
) => ({
  id,
  img: getFeaturedImg(acf, index, better_featured_image, acf && acf.isRelevant),
  date: generateEventDate(new moment(acf.date_time, 'DD/MM/YYYY')),
  title: title && title.rendered,
  text: excerpt && excerpt.rendered.replace('<p>', '').replace('</p>', ''),
  slug: `eventi/${slug}`,
  isRelevant: checkIsRelevantInNewsArchive(acf),
  position: {
    city: acf && acf.event_location && acf.event_location.address,
    lat: acf && acf.event_location && acf.event_location.lat,
    lng: acf && acf.event_location && acf.event_location.lng,
  },
  type,
})

export const makeVivipediaArticleCard = ({
  better_featured_image,
  title,
  excerpt,
  slug,
  _embedded,
  acf,
}) => ({
  img: better_featured_image && better_featured_image.source_url,
  type: acf && acf.icon,
  title: title && title.rendered,
  text: excerpt && excerpt.rendered.replace('<p>', '').replace('</p>', ''),
  tags:
    'luce elettrico risparmio energetico energia calore termoconvettori contacalorie valvole',
  slug,
  parent:
    _embedded &&
    _embedded['wp:term'] &&
    _embedded['wp:term'][0][0] &&
    _embedded['wp:term'][0][0].slug,
})

export const makeVivipediaListOfIconCards = (
  { acf, slug, description, icon, name },
  label,
) => ({
  imgUrl: acf && acf.icona_grande && acf.icona_grande.url,
  description,
  title: name,
  cta: {
    label: label || 'VAI',
    path: process.env.APP_DOMAIN,
    slug,
  },
  slug,
})

export const makeProductCard = acf => ({
  icon: acf && acf.icon,
  electricitySubtitle: acf && acf.subtitle_light,
  gasSubtitle: acf && acf.subtitle_gas,
  description: acf && acf.subtitle_description,
  sidebarTitle: acf && acf.sidebar_title,
  electricityCosts: acf && acf.light_cost,
  electricityCostsBio: acf && acf.light_cost_bio,
  gasCost: acf && acf.gas_cost,
  bonusList: acf && acf.bonus_list,
  localStore: acf && acf.store_local,
  CallMeBackProduct: acf && acf.call_me_back_product,
  greenNumber: acf && acf.green_number,
  showSubscribe: acf && acf.show_subscribe_online,
  tipoTariffa: acf && acf.tipologia_tariffa,
  tariffaBioraria: acf && acf.tariffa_bioraria,
  labelA: acf && acf.label_fascia,
  labelB: acf && acf.label_fascia_2,
  id: acf && acf.post_id,
  funnel_domain: process.env.FUNNEL_DOMAIN,
  gasCommercializationCost: acf && acf.gas_commercialization_cost,
  energyCommercializationCost: acf && acf.energy_commercialization_cost,
  energybioCommercializationCost: acf && acf.energybio_commercialization_cost,
})

export const makeHorizontalBox = ({
  post_title,
  post_name,
  ID,
  acf,
  post_excerpt,
  featured_image,
  post_type,
  post_date,
  parents,
  post_content,
  permalink,
}) => ({
  title: post_title,
  icon: acf && acf.icon,
  description: post_excerpt,
  featured_image,
  key: ID,
  isEvent: post_type === 'event',
  isFaq: post_type === 'faq',
  isNews: post_type === 'news',
  content: post_content,
  publishDate: new moment(post_date).locale('it').format('DD MMMM YYYY'),
  eventDate: acf && generateEventDate(new moment(acf.date_time, 'DD/MM/YYYY')),
  link: generateHrefBasedOnPostType({
    post_type,
    post_name,
    parents,
    permalink,
    acf,
  }),
})

export const normalizeList = list => {
  const arr = []

  list.map(item => {
    arr.push({
      text: item.label,
    })
  })

  return arr
}

export const makeOpeningDays = data => {
  let openingDays = ''

  data.map(({ days_descriptions, working_hour_description }) => {
    openingDays += '<div>'
    openingDays += `<span>${days_descriptions}</span> <span>${working_hour_description}</span>`
    openingDays += '</div>'
    openingDays += '</br>'
  })

  return openingDays
}
