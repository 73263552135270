"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Ul = exports.Li = exports.IconWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var IconWrapper = exports.IconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__IconWrapper",
  componentId: "sc-4mif1i-0"
})(["\n  height: 70px;\n  width: 70px;\n  position: relative;\n  float: left;\n  margin-right: 15px;\n  flex: 0 0 70px;\n\n  @media (max-width: 767px) {\n    margin-left: 20px;\n  }\n  > div {\n    height: 100%;\n  }\n"]);
var Li = exports.Li = _styledComponents.default.li.withConfig({
  displayName: "styles__Li",
  componentId: "sc-4mif1i-1"
})(["\n  font-family: ", ";\n  font-weight: 400;\n  font-size: 16px;\n  color: #3f5063;\n  margin-bottom: 8px;\n  list-style: none;\n  display: block;\n  clear: both;\n  position: relative;\n  overflow: auto;\n  padding: 0;\n  margin: 0;\n  margin-bottom: 15px;\n  display: flex;\n  align-items: center;\n  a {\n    display: flex;\n    text-decoration: none;\n    color: #3f5063;\n    align-items: center;\n  }\n"], function (props) {
  return props.theme.font.regular;
});
var Ul = exports.Ul = _styledComponents.default.ul.withConfig({
  displayName: "styles__Ul",
  componentId: "sc-4mif1i-2"
})(["\n  overflow: auto;\n  padding: 0;\n  margin: 0;\n"]);