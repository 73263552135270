import React from 'react'
import { compose } from 'recompose'
import axios from 'axios'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'
import {
  WorkWithUsForm as Form,
  withWrapper,
  withPadder,
} from '@alkemy/components'

const handleSubmit = formData => {
  return axios.post(`${process.env.NODE_DOMAIN}/candidatura/`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}
const WorkWithUsForm = props => <Form {...props} onSubmit={handleSubmit} />

export default compose(
  withPageBuilder({
    nicename: 'Form candidatura',
  }),
  withFields([
    {
      prop: 'wrapped',
      type: 'boolean',
      label: 'Contenuto Boxed',
    },
    {
      prop: 'paddingLeftRight',
      label: 'Larghezza Componente',
      type: 'multiple',
      options: ['200:Small', '170:Medium', '100:Large', '0:Nessuno'],
    },
    {
      prop: 'paddingTop',
      label: 'Distanza dal Top',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'paddingBottom',
      label: 'Distanza dal Bottom',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'description',
      type: 'WYSIWYG',
      label: 'Descrizione',
    },
    {
      prop: 'position',
      label: 'Posizione',
    },
    {
      prop: 'destination',
      label: 'Email destinatario',
    },
    {
      prop: 'destinationCC',
      label: 'Email cc',
    },
  ]),
  withWrapper,
  withPadder,
)(WorkWithUsForm)
