const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const formatBreadcrumbs = wpData => {
  if (!wpData.parents) {
    return [{
      label: 'Home',
      href: '/',
    }]
  }
  let breadcrumbs = wpData && [
    {
      label: 'Home',
      href: '/',
    },
    ...wpData.parents.map(({ post_title }, index) => ({
      label: post_title,
      href:
        `/` +
        wpData.parents
          .slice(0, index + 1)
          .map(x => x.post_name)
          .join('/'),
    })),
  ]
  
  if (wpData && wpData.post_type !== 'page') {
    breadcrumbs = [
      {
        label: 'Home',
        href: '/',
      },
      {
        label:
          wpData.post_type_label &&
          capitalizeFirstLetter(wpData.post_type_label),
        href: `/${wpData.post_type_label.toLowerCase()}`,
      },
      ...wpData.parents.map(({ post_title }, index) => ({
        label: post_title,
        href:
          '/' +
          wpData.post_type_label.toLowerCase() +
          '/' +
          wpData.parents
            .slice(0, index + 1)
            .map(x => x.post_name)
            .join('/'),
      })),
    ]
  }
  
  if (wpData && wpData.post_type === 'regulation') {
    breadcrumbs = [
      {
        label: 'Home',
        href: '/',
      },
      {
        label: 'Normativa',
        href: '/normativa',
      },
      ...breadcrumbs.slice(2),
    ]
  }
  
  return breadcrumbs
}

export const generateBreadCrumbs = () => {
  return [
    {
      label: 'Home',
      href: '/',
    },
  ]
}

export default formatBreadcrumbs
