"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTriangle = exports.StyledMap = exports.StyledIconWrapper = exports.StyledHeroMap = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledHeroMap = exports.StyledHeroMap = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledHeroMap",
  componentId: "sc-p4m4gk-0"
})(["\n  height: 600px;\n  position: relative;\n  overflow: hidden;\n  margin-top: -60px;\n\n  @media (max-width: 768px) {\n    height: 320px;\n    margin-top: -200px;\n  }\n"]);
var StyledMap = exports.StyledMap = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledMap",
  componentId: "sc-p4m4gk-1"
})(["\n  width: 100%;\n  height: 100% !important;\n"]);
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-p4m4gk-2"
})(["\n  width: 45px;\n  height: 45px;\n  padding: 0 5px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  bottom: 10px;\n  right: 10px;\n  border-radius: 50%;\n  background-color: ", ";\n  cursor: pointer;\n  box-shadow: 0 0 35px 0 ", ";\n  z-index: 100;\n\n  @media (min-width: 768px) {\n    width: 72px;\n    height: 72px;\n    padding: 0 10px;\n  }\n\n  @media (min-width: 1440px) {\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.color.shadowBlue;
});
var StyledTriangle = exports.StyledTriangle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTriangle",
  componentId: "sc-p4m4gk-3"
})(["\n  position: absolute;\n  bottom: 0;\n  right: 0;\n\n  width: 0;\n  height: 0;\n  border-style: solid;\n  border-width: 160px 0 0 100vw;\n  border-color: transparent transparent transparent\n    ", ";\n  @media (max-width: 767px) {\n    border-width: 35px 0 0 100vw;\n  }\n"], function (props) {
  return props.theme.color.white;
});