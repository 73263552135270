import React from 'react'
import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { HeroImg } from '@alkemy/components'

const HeroImage = props => (
  <HeroImg
    {...props}
    imgBg={[
      '/static/images/blue-oval.png',
      '/static/images/blue-oval.png',
      '/static/images/blue-oval.png',
    ]}
  />
)

export default compose(
  withPageBuilder({
    niceName: 'Hero Image',
  }),
  withFields([
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'imgType',
      label: 'Immagine',
      type: 'multiple',
      options: ['url:URL'],
      initialValue: 'url',
    },
    {
      prop: 'imgHero',
      type: 'media',
      condition: ({ imgType }) => imgType === 'media',
    },
    {
      prop: 'imgHero',
      condition: ({ imgType }) => imgType === 'url',
    },
  ]),
)(HeroImage)
