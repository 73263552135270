const product0 = require("./products/product-0.json")
const product1 = require("./products/product-1.json")
const product2 = require("./products/product-2.json")
const product3 = require("./products/product-3.json")
const product4 = require("./products/product-4.json")
const product5 = require("./products/product-5.json")
const product6 = require("./products/product-6.json")
const product7 = require("./products/product-7.json")
const product8 = require("./products/product-8.json")
const product9 = require("./products/product-9.json")
const product10 = require("./products/product-10.json")
const product11 = require("./products/product-11.json")
const product12 = require("./products/product-12.json")
const product13 = require("./products/product-13.json")

module.exports = {
  "/business/aziende/fix-market-luce": product0,
  "/business/aziende/flex-market-luce": product1,
  "/business/aziende/nettofix-gas": product2,
  "/business/aziende/nettoflex-gas": product3,
  "/business/aziende/placet-luce-fissa": product4,
  "/business/aziende/placet-luce-variabile": product5,
  "/business/aziende/placet-gas-fissa": product6,
  "/business/aziende/placet-gas-variabile": product7,
  "/business/aziende/gas-rata-costante": product8,
  "/business/aziende/luce-rata-costante": product9,
  "/business/aziende/vivimix-luce": product10,
  "/business/aziende/vivimix-gas": product11,
  "/business/aziende/placet-gas-fix-condominio": product12,
  "/business/aziende/placet-gas-var-condominio": product13,
}
