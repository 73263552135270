import {
  compose
} from "recompose"
import withPageBuilder from "@alkemy/pagebuilder/lib/hoc/withPageBuilder"
import withFields from "@alkemy/pagebuilder/lib/hoc/withFields"

import {
  withWrapper,
} from "@alkemy/components"

import ProductPageCta from "../components/ProductPageCta"

export default compose(
  withPageBuilder({
    niceName: "CTA Contattaci/Offerta",
  }),
  withFields([
    {
      prop: "wrapped",
      type: "boolean",
      label: "Contenuto Boxed",
    },
    {
      prop: "subscribeVisible",
      type: "boolean",
      label: "Subscribe Visible",
    },
    {
      prop: "subscribeLabel",
      label: "Subscribe Label",
    },
    {
      prop: "discountString_up",
      label: "Sconto (riga superiore)",
    },
    {
      prop: "discountString_down",
      label: "Sconto (riga inferiore)",
    }
  ]),
  withWrapper
)(ProductPageCta)