"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitle = exports.StyledHeaderWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledHeaderWrapper = exports.StyledHeaderWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledHeaderWrapper",
  componentId: "sc-1v7s3u7-0"
})(["\n  position: relative;\n  height: 80px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  > canvas {\n    position: absolute;\n    left: 50%;\n    bottom: -70px;\n    transform: translateX(-50%);\n    z-index: -1;\n  }\n"]);
var StyledTitle = exports.StyledTitle = _styledComponents.default.h2.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-1v7s3u7-1"
})(["\n  margin: 0;\n  color: ", ";\n  font-family: ", ";\n  font-size: ", "px;\n  font-weight: 500;\n  font-style: normal;\n  font-stretch: normal;\n  letter-spacing: normal;\n  line-height: ", "px;\n  text-align: ", ";\n\n  @media (min-width: 768px) {\n    font-size: ", "px;\n    line-height: ", "px;\n  }\n\n  ", "\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.mFs ? props.mFs : '18';
}, function (props) {
  return props.mLh ? props.mLh : '24';
}, function (props) {
  return props.align || 'center';
}, function (props) {
  return props.mFs ? props.mFs : '22';
}, function (props) {
  return props.mLh ? props.mLh : '32';
}, function (props) {
  return props.refund && (0, _styledComponents.css)(["\n      font-family: ", ";\n      font-size: 20px;\n      text-align: left;\n      line-height: 1.2;\n      padding: 0 10px;\n\n      @media (min-width: 768px) {\n        max-width: 540px;\n        font-size: 32px;\n        line-height: 1.2;\n        padding: 0;\n      }\n\n      @media (min-width: 1440px) {\n        max-width: 770px;\n        font-size: 38px;\n        line-height: 1.2;\n      }\n\n      strong {\n        font-family: ", ";\n        font-weight: 400;\n      }\n    "], function (props) {
    return props.theme.font.regular;
  }, function (props) {
    return props.theme.font.medium;
  });
});