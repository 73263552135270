import React, { useContext } from "react"
import { compose } from "recompose"
import withPageBuilder from "@alkemy/pagebuilder/lib/hoc/withPageBuilder"
import withFields from "@alkemy/pagebuilder/lib/hoc/withFields"
import { ResizeContext } from "../lib/withResizeProvider"
import { MeasuresContext } from "../lib/withMeasureProvider"
import { HeroBlob } from "@alkemy/components"

const HeroBlobWithSticky = props => {
  const resize = useContext(ResizeContext)
  const globalMeasures = useContext(MeasuresContext)
  const { innerWidth } = resize
  const { headerHeight } = globalMeasures && globalMeasures.measures
  return (
    <HeroBlob {...props} innerWidth={innerWidth} headerHeight={headerHeight} />
  )
}

export default compose(
  withPageBuilder({
    niceName: "Hero Blob",
  }),
  withFields([
    {
      prop: "blobColors",
      type: "multiple",
      label: "Colore Blob",
      options: [
        {
          label: "Arancione",
          value: [{ text: "#f49864" }, { text: "#f6b624" }],
        },
        {
          label: "Blue",
          value: [{ text: "#7E2889" }, { text: "#1587C8" }],
        },
        {
          label: "Verde",
          value: [{ text: "#6EC18A" }, { text: "#0E89CA" }],
        },
      ],
      getInputValue: value =>
        value && value.length > 0 && value.map(({ text }) => text).join("|"),
      getPropValue: value => value && value.split("|").map(x => ({ text: x })),
    },
    {
      prop: "title",
      label: "Titolo",
    },
  ])
)(HeroBlobWithSticky)
