import {compose} from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'
import {
  withStartGradientColors,
  withStopGradientColors,
} from '../lib/withGradientColors'

import {InfographicList, withWrapper} from '@alkemy/components'

export default compose(
  withPageBuilder(),
  withFields([
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'list',
      type: 'repeater',
      label: 'List',
      model: [
        withStartGradientColors(),
        withStopGradientColors(),
        {
          prop: 'percentage',
          label: 'Percentuale',
        },
        {
          prop: 'number',
          label: 'Numero',
        },
        {
          prop: 'string',
          label: 'Stringa',
        },
        {
          prop: 'caption',
          label: 'Testo Addizionale',
        },
        {
          prop: 'separator',
          label: 'Separatore',
          type: 'multiple',
          options: ['.:Punto', ',:Virgola', 'false:Nessuno'],
          initialValue: 'false',
        },
      ],
    },
  ]),
  withWrapper,
)(InfographicList)
