"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  front: [{
    top: 0,
    left: '100%'
  }, {
    top: '16.5%',
    left: 0
  }, {
    top: '49%',
    left: '100%'
  }, {
    top: '66.5%',
    left: '100%'
  }, {
    top: '91%',
    left: 0
  }],
  back: [{
    top: '5.5%',
    left: '100%'
  }, {
    top: '23%',
    left: '100%'
  }, {
    top: '53%',
    left: 0
  }, {
    top: '53%',
    left: '100%'
  }, {
    top: '78.25%',
    left: '100%'
  }, {
    top: '82%',
    left: 0
  }]
};