import React, {useState, useEffect} from 'react'

export const ResizeContext = React.createContext()
export const ResizeProviderConsumer = ResizeContext.Consumer

const withResizeProvider = WrappedComponent => {
  const ResizeProvider = props => {
    const defaultState = {
      innerWidth: 0,
      innerHeight: 0,
    }
    const [dimensions, setDimensions] = useState(defaultState)
    const onResize = e => {
      const {innerWidth, innerHeight} = e.target
      setDimensions({
        innerWidth,
        innerHeight,
      })
    }

    useEffect(() => {
      const {innerWidth, innerHeight} = window
      setDimensions({
        innerWidth,
        innerHeight,
      })
      window.addEventListener('resize', onResize)
      return () => window.removeEventListener('resize', onResize)
    }, [])

    return (
      <ResizeContext.Provider value={dimensions}>
        <WrappedComponent {...props} />
      </ResizeContext.Provider>
    )
  }

  ResizeProvider.getInitialProps = async ctx => {
    let props = {}
    if (typeof WrappedComponent.getInitialProps === 'function') {
      props = await WrappedComponent.getInitialProps(ctx)
    }
    return {
      ...props,
    }
  }
  return ResizeProvider
}

export default withResizeProvider
