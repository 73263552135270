const generic = require('./generic/generic.json')
const generic2 = require('./generic/generic-prova-2.json')
const generic3 = require('./generic/generic-prova-3.json')
// const modulistica = require('./generic/modulistica.json');
const puntovendita0 = require('./generic/punto-vendita0.json')
const puntovendita1 = require('./generic/punto-vendita1.json')
const puntovendita2 = require('./generic/punto-vendita2.json')
const puntovendita3 = require('./generic/punto-vendita3.json')
const puntovendita4 = require('./generic/punto-vendita4.json')
const puntovendita5 = require('./generic/punto-vendita5.json')
const bolletta2_0 = require('./generic/bolletta2_0.json')
const puntovendita6 = require('./generic/punto-vendita6.json')
const puntovendita7 = require('./generic/punto-vendita7.json')
const puntovendita8 = require('./generic/punto-vendita8.json')
const puntovendita9 = require('./generic/punto-vendita9.json')
const puntovendita10 = require('./generic/punto-vendita10.json')
const puntovendita11 = require('./generic/punto-vendita11.json')
const puntovendita12 = require('./generic/punto-vendita12.json')
const puntovendita13 = require('./generic/punto-vendita13.json')
const puntovendita14 = require('./generic/punto-vendita14.json')
const puntovendita15 = require('./generic/punto-vendita15.json')
const puntovendita16 = require('./generic/punto-vendita16.json')
const puntovendita17 = require('./generic/punto-vendita17.json')
const puntovendita18 = require('./generic/punto-vendita18.json')
const puntovendita19 = require('./generic/punto-vendita19.json')
const puntovendita20 = require('./generic/punto-vendita20.json')
const puntovendita21 = require('./generic/punto-vendita21.json')
const puntovendita22 = require('./generic/punto-vendita22.json')
const puntovendita23 = require('./generic/punto-vendita23.json')
const puntovendita24 = require('./generic/punto-vendita24.json')
const puntovendita25 = require('./generic/punto-vendita25.json')
const puntovendita26 = require('./generic/punto-vendita26.json')
const tutelaGas = require('./generic/tutelaGas.json')

module.exports = {
  '/generic': generic,
  '/generic-prova-2': generic2,
  '/generic-prova-3': generic3,
  '/punti-vendita/roncadelle2/': puntovendita0,
  '/punti-vendita/abbiategrasso2/': puntovendita1,
  '/punti-vendita/arcisate2/': puntovendita2,
  '/punti-vendita/arona2/': puntovendita3,
  '/punti-vendita/casalpusterlengo1b/': puntovendita4,
  '/punti-vendita/casalpusterlengo2b/': puntovendita5,
  '/punti-vendita/castel_dazzano2/': puntovendita6,
  '/punti-vendita/chiari2/': puntovendita7,
  '/punti-vendita/gerenzano2/': puntovendita8,
  '/bolletta20/': bolletta2_0,
  '/punti-vendita/legnano2/': puntovendita9,
  '/punti-vendita/lovere2/': puntovendita10,
  '/punti-vendita/lurago-derba2/': puntovendita11,
  '/punti-vendita/magenta2/': puntovendita12,
  '/punti-vendita/monterotondo2/': puntovendita13,
  '/punti-vendita/narni2/': puntovendita14,
  '/punti-vendita/nave2/': puntovendita15,
  '/punti-vendita/negrar2/': puntovendita16,
  '/punti-vendita/nettuno2/': puntovendita17,
  '/punti-vendita/oleggio2/': puntovendita18,
  '/punti-vendita/olgiate-olona2/': puntovendita19,
  '/punti-vendita/omegna2/': puntovendita20,
  '/punti-vendita/orte2/': puntovendita21,
  '/punti-vendita/palestrina2/': puntovendita22,
  '/punti-vendita/parabiago2/': puntovendita23,
  '/punti-vendita/pontirolo-nuovo2/': puntovendita24,
  '/punti-vendita/terni2/': puntovendita25,
  '/tutela-gas/': tutelaGas,
  '/punti-vendita/busto-arsizio2/': puntovendita26,
}
