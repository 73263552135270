import React from 'react'
import {compose} from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import {SectionAreaPrivataPuoi} from '@alkemy/components'

const SectionCustomerArea = ({trackingId, ...props}) => {
  const className = trackingId ? `js-tracking track-${trackingId}` : null
  return <SectionAreaPrivataPuoi {...props} className={className} />
}

export default compose(
  withPageBuilder({
    niceName: 'Box Area Clienti',
  }),
  withFields([
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'ctaLabel',
      label: 'Etichetta CTA',
    },
    {
      prop: 'ctaLoginModalTrigger',
      label: 'Mostra modale login al click',
      type: 'boolean',
      initialValue: true,
    },
    {
      prop: 'ctaUrl',
      label: 'Url CTA',
      condition: props => !props.ctaLoginModalTrigger,
    },
    {
      prop: 'list',
      label: 'Vantaggi',
      type: 'repeater',
      model: [
        {
          prop: 'text',
        },
      ],
    },
    {
      prop: 'trackingId',
      label: 'Tracking ID',
    },
  ]),
)(SectionCustomerArea)
