"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RoundTileDiv = exports.LineButton = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var RoundTileDiv = exports.RoundTileDiv = _styledComponents.default.div.withConfig({
  displayName: "styles__RoundTileDiv",
  componentId: "sc-18c49sh-0"
})(["\n  padding: 28px 50px;\n  background-color: #fff;\n  width: 100%;\n  margin-bottom: 31px;\n  border-radius: 47px;\n  box-shadow: 0px 0px 20px #dbe3ef;\n  color: #3f5063;\n  position: relative;\n  font-family: ", ";\n"], function (props) {
  return props.theme.font.medium;
});
var LineButton = exports.LineButton = _styledComponents.default.a.withConfig({
  displayName: "styles__LineButton",
  componentId: "sc-18c49sh-1"
})(["\n  padding: 11px 18px;\n  border: 2px solid #3f5063;\n  color: #3f5063;\n  border-radius: 50px;\n  text-decoration: none;\n  font-family: ", ";\n  display: block;\n  position: absolute;\n  right: 50px;\n  top: 0;\n  transform: translateY(50%);\n  margin-top: -7px;\n  > div {\n    width: 15px;\n    float: right;\n    margin-left: 20px;\n  }\n\n  ", " {\n    padding: 7px 7px;\n    margin-top: 3px;\n    right: 30px;\n    > div {\n      width: 15px;\n      margin-left: 5px;\n      margin-right: 5px;\n    }\n    > span {\n      display: none;\n    }\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.mediaQueries.phone;
});