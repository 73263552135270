"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AbsoluteBlob", {
  enumerable: true,
  get: function get() {
    return _AbsoluteBlob.default;
  }
});
Object.defineProperty(exports, "Accordion", {
  enumerable: true,
  get: function get() {
    return _Accordion.default;
  }
});
Object.defineProperty(exports, "AccordionFaq", {
  enumerable: true,
  get: function get() {
    return _AccordionFaq.default;
  }
});
Object.defineProperty(exports, "AccordionFaqList", {
  enumerable: true,
  get: function get() {
    return _AccordionFaqList.default;
  }
});
Object.defineProperty(exports, "AccordionList", {
  enumerable: true,
  get: function get() {
    return _AccordionList.default;
  }
});
Object.defineProperty(exports, "AccordionStore", {
  enumerable: true,
  get: function get() {
    return _AccordionStore.default;
  }
});
Object.defineProperty(exports, "AccordionStoreList", {
  enumerable: true,
  get: function get() {
    return _AccordionStoreList.default;
  }
});
Object.defineProperty(exports, "AdvIconsList", {
  enumerable: true,
  get: function get() {
    return _AdvIconsList.default;
  }
});
Object.defineProperty(exports, "AdvantagesSidebar", {
  enumerable: true,
  get: function get() {
    return _AdvantagesSidebar.default;
  }
});
Object.defineProperty(exports, "AdvantagesSlider", {
  enumerable: true,
  get: function get() {
    return _AdvantagesSlider.default;
  }
});
Object.defineProperty(exports, "ArchiveCalendarList", {
  enumerable: true,
  get: function get() {
    return _ArchiveCalendarList.default;
  }
});
Object.defineProperty(exports, "ArchiveFilter", {
  enumerable: true,
  get: function get() {
    return _ArchiveFilter.default;
  }
});
Object.defineProperty(exports, "ArchiveFilterGrid", {
  enumerable: true,
  get: function get() {
    return _ArchiveFilterGrid.default;
  }
});
Object.defineProperty(exports, "ArchiveList", {
  enumerable: true,
  get: function get() {
    return _ArchiveList.default;
  }
});
Object.defineProperty(exports, "BannerCarousel", {
  enumerable: true,
  get: function get() {
    return _BannerCarousel.default;
  }
});
Object.defineProperty(exports, "BannerPromo", {
  enumerable: true,
  get: function get() {
    return _BannerPromo.default;
  }
});
Object.defineProperty(exports, "BannerWatty", {
  enumerable: true,
  get: function get() {
    return _BannerWatty.default;
  }
});
Object.defineProperty(exports, "Benefit", {
  enumerable: true,
  get: function get() {
    return _Benefit.default;
  }
});
Object.defineProperty(exports, "BillGuidelines", {
  enumerable: true,
  get: function get() {
    return _BillGuidelines.default;
  }
});
Object.defineProperty(exports, "Blob", {
  enumerable: true,
  get: function get() {
    return _Blob.default;
  }
});
Object.defineProperty(exports, "BlobIcon", {
  enumerable: true,
  get: function get() {
    return _BlobIcon.default;
  }
});
Object.defineProperty(exports, "BlockBox", {
  enumerable: true,
  get: function get() {
    return _BlockBox.default;
  }
});
Object.defineProperty(exports, "BlockButton", {
  enumerable: true,
  get: function get() {
    return _BlockButton.default;
  }
});
Object.defineProperty(exports, "BlockInput", {
  enumerable: true,
  get: function get() {
    return _BlockInput.default;
  }
});
Object.defineProperty(exports, "BlockRadioButton", {
  enumerable: true,
  get: function get() {
    return _BlockRadioButton.default;
  }
});
Object.defineProperty(exports, "BlockText", {
  enumerable: true,
  get: function get() {
    return _BlockText.default;
  }
});
Object.defineProperty(exports, "BoxOffer", {
  enumerable: true,
  get: function get() {
    return _BoxOffer.default;
  }
});
Object.defineProperty(exports, "BoxOfferConfigurator", {
  enumerable: true,
  get: function get() {
    return _BoxOfferConfigurator.default;
  }
});
Object.defineProperty(exports, "BoxOfferConfiguratorBusiness", {
  enumerable: true,
  get: function get() {
    return _BoxOfferConfiguratorBusiness.default;
  }
});
Object.defineProperty(exports, "BoxOfferEvidenza", {
  enumerable: true,
  get: function get() {
    return _BoxOfferEvidenza.default;
  }
});
Object.defineProperty(exports, "BoxOfferGrid", {
  enumerable: true,
  get: function get() {
    return _BoxOfferGrid.default;
  }
});
Object.defineProperty(exports, "BoxOfferSimple", {
  enumerable: true,
  get: function get() {
    return _BoxOfferSimple.default;
  }
});
Object.defineProperty(exports, "Breadcrumb", {
  enumerable: true,
  get: function get() {
    return _Breadcrumb.default;
  }
});
Object.defineProperty(exports, "Button", {
  enumerable: true,
  get: function get() {
    return _Button.default;
  }
});
Object.defineProperty(exports, "ButtonGradient", {
  enumerable: true,
  get: function get() {
    return _ButtonGradient.default;
  }
});
Object.defineProperty(exports, "CalcoloCostiAccordion", {
  enumerable: true,
  get: function get() {
    return _CalcoloCostiAccordion.default;
  }
});
Object.defineProperty(exports, "CallMeBack", {
  enumerable: true,
  get: function get() {
    return _CallMeBack.default;
  }
});
Object.defineProperty(exports, "CallMeBackNew", {
  enumerable: true,
  get: function get() {
    return _CallMeBackNew.default;
  }
});
Object.defineProperty(exports, "CallMeBackPage", {
  enumerable: true,
  get: function get() {
    return _CallMeBackPage.default;
  }
});
Object.defineProperty(exports, "CallMeBackPageButton", {
  enumerable: true,
  get: function get() {
    return _CallMeBackPageButton.default;
  }
});
Object.defineProperty(exports, "CallMeBackText", {
  enumerable: true,
  get: function get() {
    return _CallMeBackText.default;
  }
});
Object.defineProperty(exports, "CallMeBackWrapper", {
  enumerable: true,
  get: function get() {
    return _CallMeBackWrapper.default;
  }
});
Object.defineProperty(exports, "CardBlock", {
  enumerable: true,
  get: function get() {
    return _CardBlock.default;
  }
});
Object.defineProperty(exports, "CardBlockFeatured", {
  enumerable: true,
  get: function get() {
    return _CardBlockFeatured.default;
  }
});
Object.defineProperty(exports, "CardSlider", {
  enumerable: true,
  get: function get() {
    return _CardSlider.default;
  }
});
Object.defineProperty(exports, "CenteredLinks", {
  enumerable: true,
  get: function get() {
    return _CenteredLinks.default;
  }
});
Object.defineProperty(exports, "CenteredParagraph", {
  enumerable: true,
  get: function get() {
    return _styles.CenteredParagraph;
  }
});
Object.defineProperty(exports, "CheckboxList", {
  enumerable: true,
  get: function get() {
    return _CheckboxList.default;
  }
});
Object.defineProperty(exports, "ChiSiamoIconList", {
  enumerable: true,
  get: function get() {
    return _ChiSiamoIconList.default;
  }
});
Object.defineProperty(exports, "ChiSiamoTextImg", {
  enumerable: true,
  get: function get() {
    return _ChiSiamoTextImg.default;
  }
});
Object.defineProperty(exports, "Codice", {
  enumerable: true,
  get: function get() {
    return _Codice.default;
  }
});
Object.defineProperty(exports, "ConfiguratorConsumi", {
  enumerable: true,
  get: function get() {
    return _ConfiguratorConsumi.default;
  }
});
Object.defineProperty(exports, "ConfiguratorEntryPage", {
  enumerable: true,
  get: function get() {
    return _ConfiguratorEntryPage.default;
  }
});
Object.defineProperty(exports, "ConfiguratorEntryPoint", {
  enumerable: true,
  get: function get() {
    return _ConfiguratorEntryPoint.default;
  }
});
Object.defineProperty(exports, "ConfiguratorGrid", {
  enumerable: true,
  get: function get() {
    return _ConfiguratorGrid.default;
  }
});
Object.defineProperty(exports, "ConfiguratorList", {
  enumerable: true,
  get: function get() {
    return _ConfiguratorList.default;
  }
});
Object.defineProperty(exports, "ConfiguratorProdotto", {
  enumerable: true,
  get: function get() {
    return _ConfiguratorProdotto.default;
  }
});
Object.defineProperty(exports, "ConfiguratorQuantity", {
  enumerable: true,
  get: function get() {
    return _ConfiguratorQuantity.default;
  }
});
Object.defineProperty(exports, "ConfiguratorRicalcola", {
  enumerable: true,
  get: function get() {
    return _ConfiguratorRicalcola.default;
  }
});
Object.defineProperty(exports, "ConfiguratorStep", {
  enumerable: true,
  get: function get() {
    return _ConfiguratorStep.default;
  }
});
Object.defineProperty(exports, "ConfiguratorVerificaCopertura", {
  enumerable: true,
  get: function get() {
    return _ConfiguratorVerificaCopertura.default;
  }
});
Object.defineProperty(exports, "ConfiguratorWrapper", {
  enumerable: true,
  get: function get() {
    return _ConfiguratorWrapper.default;
  }
});
Object.defineProperty(exports, "ContestPrizeBlock", {
  enumerable: true,
  get: function get() {
    return _ContestPrizeBlock.default;
  }
});
Object.defineProperty(exports, "CookiesBanner", {
  enumerable: true,
  get: function get() {
    return _CookiesBanner.default;
  }
});
Object.defineProperty(exports, "DataLayer", {
  enumerable: true,
  get: function get() {
    return _DataLayer.default;
  }
});
Object.defineProperty(exports, "DocumentsPremium", {
  enumerable: true,
  get: function get() {
    return _DocumentsPremium.default;
  }
});
Object.defineProperty(exports, "DownloadAccordion", {
  enumerable: true,
  get: function get() {
    return _DownloadAccordion.default;
  }
});
Object.defineProperty(exports, "DownloadButton", {
  enumerable: true,
  get: function get() {
    return _DownloadButton.default;
  }
});
Object.defineProperty(exports, "DownloadList", {
  enumerable: true,
  get: function get() {
    return _DownloadList.default;
  }
});
Object.defineProperty(exports, "EditorialGrid", {
  enumerable: true,
  get: function get() {
    return _EditorialGrid.default;
  }
});
Object.defineProperty(exports, "EditorialGridHome", {
  enumerable: true,
  get: function get() {
    return _EditorialGridHome.default;
  }
});
Object.defineProperty(exports, "EditorialPreview", {
  enumerable: true,
  get: function get() {
    return _EditorialPreview.default;
  }
});
Object.defineProperty(exports, "EditorialPreviewHome", {
  enumerable: true,
  get: function get() {
    return _EditorialPreviewHome.default;
  }
});
Object.defineProperty(exports, "EditorialSlider", {
  enumerable: true,
  get: function get() {
    return _EditorialSlider.default;
  }
});
Object.defineProperty(exports, "EditorialText", {
  enumerable: true,
  get: function get() {
    return _EditorialText.default;
  }
});
Object.defineProperty(exports, "EventBlock", {
  enumerable: true,
  get: function get() {
    return _EventBlock.default;
  }
});
Object.defineProperty(exports, "EventInfo", {
  enumerable: true,
  get: function get() {
    return _EventInfo.default;
  }
});
Object.defineProperty(exports, "EventInfoVariant", {
  enumerable: true,
  get: function get() {
    return _EventInfoVariant.default;
  }
});
Object.defineProperty(exports, "Example", {
  enumerable: true,
  get: function get() {
    return _Example.default;
  }
});
Object.defineProperty(exports, "ExitPop", {
  enumerable: true,
  get: function get() {
    return _ExitPop.default;
  }
});
Object.defineProperty(exports, "FaqPremium", {
  enumerable: true,
  get: function get() {
    return _FaqPremium.default;
  }
});
Object.defineProperty(exports, "FixedBottomCTA", {
  enumerable: true,
  get: function get() {
    return _FixedBottomCTA.default;
  }
});
Object.defineProperty(exports, "Footer", {
  enumerable: true,
  get: function get() {
    return _Footer.default;
  }
});
Object.defineProperty(exports, "FooterNoPre", {
  enumerable: true,
  get: function get() {
    return _FooterNoPre.default;
  }
});
Object.defineProperty(exports, "FormAutocompleteInput", {
  enumerable: true,
  get: function get() {
    return _FormAutocompleteInput.default;
  }
});
Object.defineProperty(exports, "FormChiamateSospette", {
  enumerable: true,
  get: function get() {
    return _FormChiamateSospette.default;
  }
});
Object.defineProperty(exports, "FormPmi", {
  enumerable: true,
  get: function get() {
    return _FormPmi.default;
  }
});
Object.defineProperty(exports, "FormRadioButton", {
  enumerable: true,
  get: function get() {
    return _FormRadioButton.default;
  }
});
Object.defineProperty(exports, "FormRadioDate", {
  enumerable: true,
  get: function get() {
    return _FormRadioDate.default;
  }
});
Object.defineProperty(exports, "FormRadioInput", {
  enumerable: true,
  get: function get() {
    return _FormRadioInput.default;
  }
});
Object.defineProperty(exports, "FormTextAreaMaterial", {
  enumerable: true,
  get: function get() {
    return _FormTextAreaMaterial.default;
  }
});
Object.defineProperty(exports, "FormTextInput", {
  enumerable: true,
  get: function get() {
    return _FormTextInput.default;
  }
});
Object.defineProperty(exports, "FormTextInputMaterial", {
  enumerable: true,
  get: function get() {
    return _FormTextInputMaterial.default;
  }
});
Object.defineProperty(exports, "FormWatty", {
  enumerable: true,
  get: function get() {
    return _FormWatty.default;
  }
});
Object.defineProperty(exports, "Gallery", {
  enumerable: true,
  get: function get() {
    return _Gallery.default;
  }
});
Object.defineProperty(exports, "GradientText", {
  enumerable: true,
  get: function get() {
    return _GradientText.default;
  }
});
Object.defineProperty(exports, "Grid", {
  enumerable: true,
  get: function get() {
    return _Grid.default;
  }
});
Object.defineProperty(exports, "Hamburger", {
  enumerable: true,
  get: function get() {
    return _Hamburger.default;
  }
});
Object.defineProperty(exports, "Header", {
  enumerable: true,
  get: function get() {
    return _Header.default;
  }
});
Object.defineProperty(exports, "HeaderText", {
  enumerable: true,
  get: function get() {
    return _styles.HeaderText;
  }
});
Object.defineProperty(exports, "Hero", {
  enumerable: true,
  get: function get() {
    return _Hero.default;
  }
});
Object.defineProperty(exports, "HeroBanner", {
  enumerable: true,
  get: function get() {
    return _HeroBanner.default;
  }
});
Object.defineProperty(exports, "HeroBlob", {
  enumerable: true,
  get: function get() {
    return _HeroBlob.default;
  }
});
Object.defineProperty(exports, "HeroImg", {
  enumerable: true,
  get: function get() {
    return _HeroImg.default;
  }
});
Object.defineProperty(exports, "HeroMap", {
  enumerable: true,
  get: function get() {
    return _HeroMap.default;
  }
});
Object.defineProperty(exports, "HeroPage", {
  enumerable: true,
  get: function get() {
    return _HeroPage.default;
  }
});
Object.defineProperty(exports, "HeroSlider", {
  enumerable: true,
  get: function get() {
    return _HeroSlider.default;
  }
});
Object.defineProperty(exports, "HeroText", {
  enumerable: true,
  get: function get() {
    return _HeroText.default;
  }
});
Object.defineProperty(exports, "HorizontalSearchBox", {
  enumerable: true,
  get: function get() {
    return _HorizontalSearchBox.default;
  }
});
Object.defineProperty(exports, "Icons", {
  enumerable: true,
  get: function get() {
    return _Icons.default;
  }
});
Object.defineProperty(exports, "IconsCTABlock", {
  enumerable: true,
  get: function get() {
    return _IconsCTABlock.default;
  }
});
Object.defineProperty(exports, "IconsGrid", {
  enumerable: true,
  get: function get() {
    return _IconsGrid.default;
  }
});
Object.defineProperty(exports, "Iframe", {
  enumerable: true,
  get: function get() {
    return _Iframe.default;
  }
});
Object.defineProperty(exports, "InfographicItem", {
  enumerable: true,
  get: function get() {
    return _InfographicItem.default;
  }
});
Object.defineProperty(exports, "InfographicList", {
  enumerable: true,
  get: function get() {
    return _InfographicList.default;
  }
});
Object.defineProperty(exports, "LaunchBlock", {
  enumerable: true,
  get: function get() {
    return _LaunchBlock.default;
  }
});
Object.defineProperty(exports, "LegalNote", {
  enumerable: true,
  get: function get() {
    return _LegalNote.default;
  }
});
Object.defineProperty(exports, "List", {
  enumerable: true,
  get: function get() {
    return _List.default;
  }
});
Object.defineProperty(exports, "ListOfIcons", {
  enumerable: true,
  get: function get() {
    return _ListOfIcons.default;
  }
});
Object.defineProperty(exports, "ListWithModal", {
  enumerable: true,
  get: function get() {
    return _ListWithModal.default;
  }
});
Object.defineProperty(exports, "ListWithRoundImg", {
  enumerable: true,
  get: function get() {
    return _ListWithRoundImg.default;
  }
});
Object.defineProperty(exports, "ListWithRoundImgPMI", {
  enumerable: true,
  get: function get() {
    return _ListWithRoundImgPMI.default;
  }
});
Object.defineProperty(exports, "LoadMore", {
  enumerable: true,
  get: function get() {
    return _LoadMore.default;
  }
});
Object.defineProperty(exports, "Login", {
  enumerable: true,
  get: function get() {
    return _Login.default;
  }
});
Object.defineProperty(exports, "LoginModal", {
  enumerable: true,
  get: function get() {
    return _LoginModal.default;
  }
});
Object.defineProperty(exports, "Logo", {
  enumerable: true,
  get: function get() {
    return _Logo.default;
  }
});
Object.defineProperty(exports, "MainMenu", {
  enumerable: true,
  get: function get() {
    return _MainMenu.default;
  }
});
Object.defineProperty(exports, "MgmHome", {
  enumerable: true,
  get: function get() {
    return _MgmHome.default;
  }
});
Object.defineProperty(exports, "MissionItem", {
  enumerable: true,
  get: function get() {
    return _MissionItem.default;
  }
});
Object.defineProperty(exports, "MissionList", {
  enumerable: true,
  get: function get() {
    return _MissionList.default;
  }
});
Object.defineProperty(exports, "ModalPageTab", {
  enumerable: true,
  get: function get() {
    return _ModalPageTab.default;
  }
});
Object.defineProperty(exports, "ModuloPrenotazione", {
  enumerable: true,
  get: function get() {
    return _ModuloPrenotazione.default;
  }
});
Object.defineProperty(exports, "MultipleLaunchBlock", {
  enumerable: true,
  get: function get() {
    return _MultipleLaunchBlock.default;
  }
});
Object.defineProperty(exports, "NeedsModal", {
  enumerable: true,
  get: function get() {
    return _NeedsModal.default;
  }
});
Object.defineProperty(exports, "NewsBlock", {
  enumerable: true,
  get: function get() {
    return _NewsBlock.default;
  }
});
Object.defineProperty(exports, "NewsBlockHome", {
  enumerable: true,
  get: function get() {
    return _NewsBlockHome.default;
  }
});
Object.defineProperty(exports, "NotFound", {
  enumerable: true,
  get: function get() {
    return _NotFound.default;
  }
});
Object.defineProperty(exports, "OffersForm", {
  enumerable: true,
  get: function get() {
    return _OffersForm.default;
  }
});
Object.defineProperty(exports, "OffersFormBusiness", {
  enumerable: true,
  get: function get() {
    return _OffersFormBusiness.default;
  }
});
Object.defineProperty(exports, "OffersFormLocation", {
  enumerable: true,
  get: function get() {
    return _OffersFormLocation.default;
  }
});
Object.defineProperty(exports, "OffersFormServices", {
  enumerable: true,
  get: function get() {
    return _OffersFormServices.default;
  }
});
Object.defineProperty(exports, "OffersFormStep", {
  enumerable: true,
  get: function get() {
    return _OffersFormStep.default;
  }
});
Object.defineProperty(exports, "OffersFormTargets", {
  enumerable: true,
  get: function get() {
    return _OffersFormTargets.default;
  }
});
Object.defineProperty(exports, "OffersFormUtilities", {
  enumerable: true,
  get: function get() {
    return _OffersFormUtilities.default;
  }
});
Object.defineProperty(exports, "PageBanner", {
  enumerable: true,
  get: function get() {
    return _PageBanner.default;
  }
});
Object.defineProperty(exports, "PageBlock", {
  enumerable: true,
  get: function get() {
    return _PageBlock.default;
  }
});
Object.defineProperty(exports, "PageBlockWithBlob", {
  enumerable: true,
  get: function get() {
    return _PageBlockWithBlob.default;
  }
});
Object.defineProperty(exports, "PageBuilder", {
  enumerable: true,
  get: function get() {
    return _PageBuilder.default;
  }
});
Object.defineProperty(exports, "Parallax", {
  enumerable: true,
  get: function get() {
    return _Parallax.default;
  }
});
Object.defineProperty(exports, "PreNote", {
  enumerable: true,
  get: function get() {
    return _PreNote.default;
  }
});
Object.defineProperty(exports, "PressRelease", {
  enumerable: true,
  get: function get() {
    return _PressRelease.default;
  }
});
Object.defineProperty(exports, "PressReleaseList", {
  enumerable: true,
  get: function get() {
    return _PressReleaseList.default;
  }
});
Object.defineProperty(exports, "ProductCard", {
  enumerable: true,
  get: function get() {
    return _ProductCard.default;
  }
});
Object.defineProperty(exports, "ProductPageAdvantagesSlider", {
  enumerable: true,
  get: function get() {
    return _ProductPageAdvantagesSlider.default;
  }
});
Object.defineProperty(exports, "ProductPageCards", {
  enumerable: true,
  get: function get() {
    return _ProductPageCards.default;
  }
});
Object.defineProperty(exports, "ProductPageConfigurator", {
  enumerable: true,
  get: function get() {
    return _ProductPageConfigurator.default;
  }
});
Object.defineProperty(exports, "ProductPageCta", {
  enumerable: true,
  get: function get() {
    return _ProductPageCta.default;
  }
});
Object.defineProperty(exports, "ProductPageFixedBottomCTA", {
  enumerable: true,
  get: function get() {
    return _ProductPageFixedBottomCTA.default;
  }
});
Object.defineProperty(exports, "ProductPageHero", {
  enumerable: true,
  get: function get() {
    return _ProductPageHero.default;
  }
});
Object.defineProperty(exports, "ProductPageHeroList", {
  enumerable: true,
  get: function get() {
    return _ProductPageHeroList.default;
  }
});
Object.defineProperty(exports, "ProductPageHighlightsSlider", {
  enumerable: true,
  get: function get() {
    return _ProductPageHighlightsSlider.default;
  }
});
Object.defineProperty(exports, "ProductPageInfoCards", {
  enumerable: true,
  get: function get() {
    return _ProductPageInfoCards.default;
  }
});
Object.defineProperty(exports, "ProductPageRows", {
  enumerable: true,
  get: function get() {
    return _ProductPageRows.default;
  }
});
Object.defineProperty(exports, "ProductPageTab", {
  enumerable: true,
  get: function get() {
    return _ProductPageTab.default;
  }
});
Object.defineProperty(exports, "ProductPageTabContent", {
  enumerable: true,
  get: function get() {
    return _ProductPageTabContent.default;
  }
});
Object.defineProperty(exports, "QueryCodeString", {
  enumerable: true,
  get: function get() {
    return _QueryCodeString.default;
  }
});
Object.defineProperty(exports, "Recensioni", {
  enumerable: true,
  get: function get() {
    return _Recensioni.default;
  }
});
Object.defineProperty(exports, "RichText", {
  enumerable: true,
  get: function get() {
    return _RichText.default;
  }
});
Object.defineProperty(exports, "RoundButton", {
  enumerable: true,
  get: function get() {
    return _RoundButton.default;
  }
});
Object.defineProperty(exports, "RoundButtonIcon", {
  enumerable: true,
  get: function get() {
    return _RoundButtonIcon.default;
  }
});
Object.defineProperty(exports, "RoundFilters", {
  enumerable: true,
  get: function get() {
    return _RoundFilters.default;
  }
});
Object.defineProperty(exports, "RoundTile", {
  enumerable: true,
  get: function get() {
    return _RoundTile.default;
  }
});
Object.defineProperty(exports, "Row", {
  enumerable: true,
  get: function get() {
    return _Row.default;
  }
});
Object.defineProperty(exports, "SalesPoint", {
  enumerable: true,
  get: function get() {
    return _SalesPoint.default;
  }
});
Object.defineProperty(exports, "SalesPointList", {
  enumerable: true,
  get: function get() {
    return _SalesPointList.default;
  }
});
Object.defineProperty(exports, "SalesPoints", {
  enumerable: true,
  get: function get() {
    return _SalesPoints.default;
  }
});
Object.defineProperty(exports, "Scroller", {
  enumerable: true,
  get: function get() {
    return _Scroller.default;
  }
});
Object.defineProperty(exports, "SearchModal", {
  enumerable: true,
  get: function get() {
    return _SearchModal.default;
  }
});
Object.defineProperty(exports, "SectionAreaPrivataPuoi", {
  enumerable: true,
  get: function get() {
    return _SectionAreaPrivataPuoi.default;
  }
});
Object.defineProperty(exports, "SectionDownloadApp", {
  enumerable: true,
  get: function get() {
    return _SectionDownloadApp.default;
  }
});
Object.defineProperty(exports, "SectionFaqs", {
  enumerable: true,
  get: function get() {
    return _SectionFaqs.default;
  }
});
Object.defineProperty(exports, "SectionForniture", {
  enumerable: true,
  get: function get() {
    return _SectionForniture.default;
  }
});
Object.defineProperty(exports, "SectionHighlights", {
  enumerable: true,
  get: function get() {
    return _SectionHighlights.default;
  }
});
Object.defineProperty(exports, "SectionList", {
  enumerable: true,
  get: function get() {
    return _SectionList.default;
  }
});
Object.defineProperty(exports, "SectionPhone", {
  enumerable: true,
  get: function get() {
    return _SectionPhone.default;
  }
});
Object.defineProperty(exports, "SectionPhoneGrandi", {
  enumerable: true,
  get: function get() {
    return _SectionPhoneGrandi.default;
  }
});
Object.defineProperty(exports, "SectionPhoneIVA", {
  enumerable: true,
  get: function get() {
    return _SectionPhoneIVA.default;
  }
});
Object.defineProperty(exports, "SectionPhotoText", {
  enumerable: true,
  get: function get() {
    return _SectionPhotoText.default;
  }
});
Object.defineProperty(exports, "SectionStaticBlob", {
  enumerable: true,
  get: function get() {
    return _SectionStaticBlob.default;
  }
});
Object.defineProperty(exports, "SectionText", {
  enumerable: true,
  get: function get() {
    return _SectionText.default;
  }
});
Object.defineProperty(exports, "SectionTitle", {
  enumerable: true,
  get: function get() {
    return _SectionTitle.default;
  }
});
Object.defineProperty(exports, "SectionTitleCanvas", {
  enumerable: true,
  get: function get() {
    return _SectionTitleCanvas.default;
  }
});
Object.defineProperty(exports, "Separator", {
  enumerable: true,
  get: function get() {
    return _Separator.default;
  }
});
Object.defineProperty(exports, "ServiceNumber", {
  enumerable: true,
  get: function get() {
    return _ServiceNumber.default;
  }
});
Object.defineProperty(exports, "Slide", {
  enumerable: true,
  get: function get() {
    return _Slide.default;
  }
});
Object.defineProperty(exports, "SmallHero", {
  enumerable: true,
  get: function get() {
    return _SmallHero.default;
  }
});
Object.defineProperty(exports, "SmallHeroBanner", {
  enumerable: true,
  get: function get() {
    return _SmallHeroBanner.default;
  }
});
Object.defineProperty(exports, "SmallRadio", {
  enumerable: true,
  get: function get() {
    return _SmallRadio.default;
  }
});
Object.defineProperty(exports, "Social", {
  enumerable: true,
  get: function get() {
    return _Social.default;
  }
});
Object.defineProperty(exports, "SponsorSlider", {
  enumerable: true,
  get: function get() {
    return _SponsorSlider.default;
  }
});
Object.defineProperty(exports, "StepNav", {
  enumerable: true,
  get: function get() {
    return _StepNav.default;
  }
});
Object.defineProperty(exports, "Steps", {
  enumerable: true,
  get: function get() {
    return _Steps.default;
  }
});
Object.defineProperty(exports, "StickyElement", {
  enumerable: true,
  get: function get() {
    return _StickyElement.default;
  }
});
Object.defineProperty(exports, "StickyWrapper", {
  enumerable: true,
  get: function get() {
    return _StickyWrapper.default;
  }
});
Object.defineProperty(exports, "StimaCostiCard", {
  enumerable: true,
  get: function get() {
    return _StimaCostiCard.default;
  }
});
Object.defineProperty(exports, "StoreDetail", {
  enumerable: true,
  get: function get() {
    return _StoreDetail.default;
  }
});
Object.defineProperty(exports, "StoreLocator", {
  enumerable: true,
  get: function get() {
    return _StoreLocator.default;
  }
});
Object.defineProperty(exports, "StoreLocatorExtra", {
  enumerable: true,
  get: function get() {
    return _StoreLocatorExtra.default;
  }
});
Object.defineProperty(exports, "StoreLocatorMobileModal", {
  enumerable: true,
  get: function get() {
    return _StoreLocatorMobileModal.default;
  }
});
Object.defineProperty(exports, "StoreLocatorWrapper", {
  enumerable: true,
  get: function get() {
    return _StoreLocatorWrapper.default;
  }
});
Object.defineProperty(exports, "Switcher", {
  enumerable: true,
  get: function get() {
    return _Switcher.default;
  }
});
Object.defineProperty(exports, "TariffaDetails", {
  enumerable: true,
  get: function get() {
    return _TariffaDetails.default;
  }
});
Object.defineProperty(exports, "Teaser", {
  enumerable: true,
  get: function get() {
    return _Teaser.default;
  }
});
Object.defineProperty(exports, "TestForm", {
  enumerable: true,
  get: function get() {
    return _TestForm.default;
  }
});
Object.defineProperty(exports, "TextAnimation", {
  enumerable: true,
  get: function get() {
    return _TextAnimation.default;
  }
});
Object.defineProperty(exports, "TextBlock", {
  enumerable: true,
  get: function get() {
    return _TextBlock.default;
  }
});
Object.defineProperty(exports, "Triangle", {
  enumerable: true,
  get: function get() {
    return _Triangle.default;
  }
});
Object.defineProperty(exports, "UnsupportedBrowser", {
  enumerable: true,
  get: function get() {
    return _UnsupportedBrowser.default;
  }
});
Object.defineProperty(exports, "UserActions", {
  enumerable: true,
  get: function get() {
    return _UserActions.default;
  }
});
Object.defineProperty(exports, "VideoPlayer", {
  enumerable: true,
  get: function get() {
    return _VideoPlayer.default;
  }
});
Object.defineProperty(exports, "ViviescoForm", {
  enumerable: true,
  get: function get() {
    return _ViviescoForm.default;
  }
});
Object.defineProperty(exports, "ViviescoParagraphIcons", {
  enumerable: true,
  get: function get() {
    return _ViviescoParagraphIcons.default;
  }
});
Object.defineProperty(exports, "ViviescoProdotti", {
  enumerable: true,
  get: function get() {
    return _ViviescoProdotti.default;
  }
});
Object.defineProperty(exports, "ViviescoRiquadro", {
  enumerable: true,
  get: function get() {
    return _ViviescoRiquadro.default;
  }
});
Object.defineProperty(exports, "VivipediaArticleBlock", {
  enumerable: true,
  get: function get() {
    return _VivipediaArticleBlock.default;
  }
});
Object.defineProperty(exports, "VivipediaArticleBlockHome", {
  enumerable: true,
  get: function get() {
    return _VivipediaArticleBlockHome.default;
  }
});
Object.defineProperty(exports, "VivipediaArticleList", {
  enumerable: true,
  get: function get() {
    return _VivipediaArticleList.default;
  }
});
Object.defineProperty(exports, "VivipediaArticleListHome", {
  enumerable: true,
  get: function get() {
    return _VivipediaArticleListHome.default;
  }
});
Object.defineProperty(exports, "VivipediaArticleListSlider", {
  enumerable: true,
  get: function get() {
    return _VivipediaArticleListSlider.default;
  }
});
Object.defineProperty(exports, "VivipediaLaunchBlock", {
  enumerable: true,
  get: function get() {
    return _VivipediaLaunchBlock.default;
  }
});
Object.defineProperty(exports, "VivipediaLaunchList", {
  enumerable: true,
  get: function get() {
    return _VivipediaLaunchList.default;
  }
});
Object.defineProperty(exports, "VivipediaPageToggler", {
  enumerable: true,
  get: function get() {
    return _VivipediaPageToggler.default;
  }
});
Object.defineProperty(exports, "VivipediaSearchBox", {
  enumerable: true,
  get: function get() {
    return _VivipediaSearchBox.default;
  }
});
Object.defineProperty(exports, "WorkWithUsBlock", {
  enumerable: true,
  get: function get() {
    return _WorkWithUsBlock.default;
  }
});
Object.defineProperty(exports, "WorkWithUsBlockList", {
  enumerable: true,
  get: function get() {
    return _WorkWithUsBlockList.default;
  }
});
Object.defineProperty(exports, "WorkWithUsForm", {
  enumerable: true,
  get: function get() {
    return _WorkWithUsForm.default;
  }
});
Object.defineProperty(exports, "WorkWithUsList", {
  enumerable: true,
  get: function get() {
    return _WorkWithUsList.default;
  }
});
Object.defineProperty(exports, "WorkWithUsTitle", {
  enumerable: true,
  get: function get() {
    return _WorkWithUsTitle.default;
  }
});
Object.defineProperty(exports, "Wrapper", {
  enumerable: true,
  get: function get() {
    return _Wrapper.default;
  }
});
Object.defineProperty(exports, "style", {
  enumerable: true,
  get: function get() {
    return _style.default;
  }
});
Object.defineProperty(exports, "withDrawSVG", {
  enumerable: true,
  get: function get() {
    return _withDrawSVG.default;
  }
});
Object.defineProperty(exports, "withLoginModal", {
  enumerable: true,
  get: function get() {
    return _withLoginModal.default;
  }
});
Object.defineProperty(exports, "withMainMenu", {
  enumerable: true,
  get: function get() {
    return _withMainMenu.default;
  }
});
Object.defineProperty(exports, "withModal", {
  enumerable: true,
  get: function get() {
    return _withModal.default;
  }
});
Object.defineProperty(exports, "withPadder", {
  enumerable: true,
  get: function get() {
    return _withPadder.default;
  }
});
Object.defineProperty(exports, "withScrollWatcher", {
  enumerable: true,
  get: function get() {
    return _withScrollWatcher.default;
  }
});
Object.defineProperty(exports, "withStickyElement", {
  enumerable: true,
  get: function get() {
    return _withStickyElement.default;
  }
});
Object.defineProperty(exports, "withStoreLocator", {
  enumerable: true,
  get: function get() {
    return _withStoreLocator.default;
  }
});
Object.defineProperty(exports, "withTracking", {
  enumerable: true,
  get: function get() {
    return _withTracking.default;
  }
});
Object.defineProperty(exports, "withWrapper", {
  enumerable: true,
  get: function get() {
    return _withWrapper.default;
  }
});
var _styles = require("./components/ModuloPrenotazione/styles");
var _Blob = _interopRequireDefault(require("./components/Blob"));
var _ExitPop = _interopRequireDefault(require("./components/ExitPop"));
var _Breadcrumb = _interopRequireDefault(require("./components/Breadcrumb"));
var _Example = _interopRequireDefault(require("./components/Example"));
var _ButtonGradient = _interopRequireDefault(require("./components/ButtonGradient"));
var _DownloadButton = _interopRequireDefault(require("./components/DownloadButton"));
var _DownloadList = _interopRequireDefault(require("./components/DownloadList"));
var _DownloadAccordion = _interopRequireDefault(require("./components/DownloadAccordion"));
var _Icons = _interopRequireDefault(require("./components/Icons"));
var _IconsCTABlock = _interopRequireDefault(require("./components/IconsCTABlock"));
var _List = _interopRequireDefault(require("./components/List"));
var _PageBanner = _interopRequireDefault(require("./components/PageBanner"));
var _BannerWatty = _interopRequireDefault(require("./components/BannerWatty"));
var _PageBlock = _interopRequireDefault(require("./components/PageBlock"));
var _RichText = _interopRequireDefault(require("./components/RichText"));
var _RoundFilters = _interopRequireDefault(require("./components/RoundFilters"));
var _RoundTile = _interopRequireDefault(require("./components/RoundTile"));
var _QueryCodeString = _interopRequireDefault(require("./components/QueryCodeString"));
var _CenteredLinks = _interopRequireDefault(require("./components/CenteredLinks"));
var _ProductCard = _interopRequireDefault(require("./components/ProductCard"));
var _ListOfIcons = _interopRequireDefault(require("./components/ListOfIcons"));
var _AdvantagesSidebar = _interopRequireDefault(require("./components/AdvantagesSidebar"));
var _FixedBottomCTA = _interopRequireDefault(require("./components/FixedBottomCTA"));
var _ProductPageFixedBottomCTA = _interopRequireDefault(require("./components/ProductPageFixedBottomCTA"));
var _BannerCarousel = _interopRequireDefault(require("./components/BannerCarousel"));
var _SectionAreaPrivataPuoi = _interopRequireDefault(require("./components/SectionAreaPrivataPuoi"));
var _SectionTitle = _interopRequireDefault(require("./components/SectionTitle"));
var _SectionTitleCanvas = _interopRequireDefault(require("./components/SectionTitleCanvas"));
var _SectionList = _interopRequireDefault(require("./components/SectionList"));
var _SectionPhone = _interopRequireDefault(require("./components/SectionPhone"));
var _SectionPhoneIVA = _interopRequireDefault(require("./components/SectionPhoneIVA"));
var _SectionPhoneGrandi = _interopRequireDefault(require("./components/SectionPhoneGrandi"));
var _SectionText = _interopRequireDefault(require("./components/SectionText"));
var _Triangle = _interopRequireDefault(require("./components/Triangle"));
var _Wrapper = _interopRequireDefault(require("./components/Wrapper"));
var _Teaser = _interopRequireDefault(require("./components/Teaser"));
var _Footer = _interopRequireDefault(require("./components/Footer"));
var _FooterNoPre = _interopRequireDefault(require("./components/FooterNoPre"));
var _Header = _interopRequireDefault(require("./components/Header"));
var _LaunchBlock = _interopRequireDefault(require("./components/LaunchBlock"));
var _CardBlock = _interopRequireDefault(require("./components/CardBlock"));
var _CardBlockFeatured = _interopRequireDefault(require("./components/CardBlockFeatured"));
var _AdvIconsList = _interopRequireDefault(require("./components/AdvIconsList"));
var _Slide = _interopRequireDefault(require("./components/Slide"));
var _HeroSlider = _interopRequireDefault(require("./components/HeroSlider"));
var _HeroText = _interopRequireDefault(require("./components/HeroText"));
var _DataLayer = _interopRequireDefault(require("./components/DataLayer"));
var _Scroller = _interopRequireDefault(require("./components/Scroller"));
var _Hero = _interopRequireDefault(require("./components/Hero"));
var _Logo = _interopRequireDefault(require("./components/Logo"));
var _Button = _interopRequireDefault(require("./components/Button"));
var _RoundButton = _interopRequireDefault(require("./components/Buttons/RoundButton"));
var _RoundButtonIcon = _interopRequireDefault(require("./components/Buttons/RoundButtonIcon"));
var _SmallRadio = _interopRequireDefault(require("./components/Buttons/SmallRadio"));
var _UserActions = _interopRequireDefault(require("./components/UserActions"));
var _MainMenu = _interopRequireDefault(require("./components/MainMenu"));
var _Hamburger = _interopRequireDefault(require("./components/Hamburger"));
var _Row = _interopRequireDefault(require("./components/Row"));
var _SalesPoints = _interopRequireDefault(require("./components/SalesPoints"));
var _BannerPromo = _interopRequireDefault(require("./components/BannerPromo"));
var _FormTextInput = _interopRequireDefault(require("./components/FormElements/FormTextInput"));
var _FormAutocompleteInput = _interopRequireDefault(require("./components/FormElements/FormAutocompleteInput"));
var _FormTextInputMaterial = _interopRequireDefault(require("./components/FormElementsMaterial/FormTextInputMaterial"));
var _FormTextAreaMaterial = _interopRequireDefault(require("./components/FormElementsMaterial/FormTextAreaMaterial"));
var _LegalNote = _interopRequireDefault(require("./components/FormElementsMaterial/LegalNote"));
var _PreNote = _interopRequireDefault(require("./components/FormElementsMaterial/PreNote"));
var _FormRadioButton = _interopRequireDefault(require("./components/FormElements/FormRadioButton"));
var _FormRadioInput = _interopRequireDefault(require("./components/FormElements/FormRadioInput"));
var _FormRadioDate = _interopRequireDefault(require("./components/FormElements/FormRadioDate"));
var _OffersFormLocation = _interopRequireDefault(require("./components/OffersFormLocation"));
var _OffersFormUtilities = _interopRequireDefault(require("./components/OffersFormUtilities"));
var _OffersFormServices = _interopRequireDefault(require("./components/OffersFormServices"));
var _OffersFormStep = _interopRequireDefault(require("./components/OffersFormStep"));
var _OffersForm = _interopRequireDefault(require("./components/OffersForm"));
var _Grid = _interopRequireDefault(require("./components/Grid"));
var _BoxOffer = _interopRequireDefault(require("./components/BoxOffer"));
var _BoxOfferEvidenza = _interopRequireDefault(require("./components/BoxOfferEvidenza"));
var _BoxOfferSimple = _interopRequireDefault(require("./components/BoxOfferSimple"));
var _BoxOfferGrid = _interopRequireDefault(require("./components/BoxOfferGrid"));
var _TestForm = _interopRequireDefault(require("./components/TestForm"));
var _SectionFaqs = _interopRequireDefault(require("./components/SectionFaqs"));
var _SectionHighlights = _interopRequireDefault(require("./components/SectionHighlights"));
var _ProductPageCta = _interopRequireDefault(require("./components/ProductPageCta"));
var _StepNav = _interopRequireDefault(require("./components/StepNav"));
var _MultipleLaunchBlock = _interopRequireDefault(require("./components/MultipleLaunchBlock"));
var _SectionDownloadApp = _interopRequireDefault(require("./components/SectionDownloadApp"));
var _SectionPhotoText = _interopRequireDefault(require("./components/SectionPhotoText"));
var _NeedsModal = _interopRequireDefault(require("./components/NeedsModal"));
var _AdvantagesSlider = _interopRequireDefault(require("./components/AdvantagesSlider"));
var _OffersFormBusiness = _interopRequireDefault(require("./components/OffersFormBusiness"));
var _OffersFormTargets = _interopRequireDefault(require("./components/OffersFormTargets"));
var _SmallHero = _interopRequireDefault(require("./components/SmallHero"));
var _CallMeBack = _interopRequireDefault(require("./components/CallMeBack"));
var _CallMeBackNew = _interopRequireDefault(require("./components/CallMeBackNew"));
var _CallMeBackPage = _interopRequireDefault(require("./components/CallMeBackPage"));
var _CallMeBackPageButton = _interopRequireDefault(require("./components/CallMeBackPageButton"));
var _CookiesBanner = _interopRequireDefault(require("./components/CookiesBanner"));
var _Accordion = _interopRequireDefault(require("./components/Accordion"));
var _AccordionList = _interopRequireDefault(require("./components/AccordionList"));
var _ContestPrizeBlock = _interopRequireDefault(require("./components/ContestPrizeBlock"));
var _Social = _interopRequireDefault(require("./components/Social"));
var _StickyElement = _interopRequireDefault(require("./components/StickyElement"));
var _StickyWrapper = _interopRequireDefault(require("./components/StickyWrapper"));
var _HeroImg = _interopRequireDefault(require("./components/HeroImg"));
var _PageBlockWithBlob = _interopRequireDefault(require("./components/PageBlockWithBlob"));
var _Steps = _interopRequireDefault(require("./components/Steps"));
var _ListWithModal = _interopRequireDefault(require("./components/ListWithModal"));
var _AccordionStore = _interopRequireDefault(require("./components/AccordionStore"));
var _AccordionStoreList = _interopRequireDefault(require("./components/AccordionStoreList"));
var _StoreLocator = _interopRequireDefault(require("./components/StoreLocator"));
var _StoreLocatorExtra = _interopRequireDefault(require("./components/StoreLocatorExtra"));
var _StoreLocatorMobileModal = _interopRequireDefault(require("./components/StoreLocatorMobileModal"));
var _StoreDetail = _interopRequireDefault(require("./components/StoreDetail"));
var _HeroMap = _interopRequireDefault(require("./components/HeroMap"));
var _HorizontalSearchBox = _interopRequireDefault(require("./components/HorizontalSearchBox"));
var _ServiceNumber = _interopRequireDefault(require("./components/ServiceNumber"));
var _SearchModal = _interopRequireDefault(require("./components/SearchModal"));
var _Login = _interopRequireDefault(require("./components/Login"));
var _LoginModal = _interopRequireDefault(require("./components/LoginModal"));
var _NewsBlock = _interopRequireDefault(require("./components/NewsBlock"));
var _NewsBlockHome = _interopRequireDefault(require("./components/NewsBlockHome"));
var _EventBlock = _interopRequireDefault(require("./components/EventBlock"));
var _EditorialGrid = _interopRequireDefault(require("./components/EditorialGrid"));
var _EditorialGridHome = _interopRequireDefault(require("./components/EditorialGridHome"));
var _PageBuilder = _interopRequireDefault(require("./components/PageBuilder"));
var _EditorialPreview = _interopRequireDefault(require("./components/EditorialPreview"));
var _EditorialPreviewHome = _interopRequireDefault(require("./components/EditorialPreviewHome"));
var _EventInfo = _interopRequireDefault(require("./components/EventInfo"));
var _Gallery = _interopRequireDefault(require("./components/Gallery"));
var _EventInfoVariant = _interopRequireDefault(require("./components/EventInfoVariant"));
var _EditorialSlider = _interopRequireDefault(require("./components/EditorialSlider"));
var _Separator = _interopRequireDefault(require("./components/Separator"));
var _ModuloPrenotazione = _interopRequireDefault(require("./components/ModuloPrenotazione"));
var _CardSlider = _interopRequireDefault(require("./components/CardSlider"));
var _SectionStaticBlob = _interopRequireDefault(require("./components/SectionStaticBlob"));
var _ListWithRoundImg = _interopRequireDefault(require("./components/ListWithRoundImg"));
var _ListWithRoundImgPMI = _interopRequireDefault(require("./components/ListWithRoundImgPMI"));
var _GradientText = _interopRequireDefault(require("./components/GradientText"));
var _InfographicItem = _interopRequireDefault(require("./components/InfographicItem"));
var _InfographicList = _interopRequireDefault(require("./components/InfographicList"));
var _MissionItem = _interopRequireDefault(require("./components/MissionItem"));
var _MissionList = _interopRequireDefault(require("./components/MissionList"));
var _ChiSiamoIconList = _interopRequireDefault(require("./components/ChiSiamoIconList"));
var _CheckboxList = _interopRequireDefault(require("./components/CheckboxList"));
var _ChiSiamoTextImg = _interopRequireDefault(require("./components/ChiSiamoTextImg"));
var _EditorialText = _interopRequireDefault(require("./components/EditorialText"));
var _AbsoluteBlob = _interopRequireDefault(require("./components/AbsoluteBlob"));
var _SalesPoint = _interopRequireDefault(require("./components/SalesPoint"));
var _SalesPointList = _interopRequireDefault(require("./components/SalesPointList"));
var _VideoPlayer = _interopRequireDefault(require("./components/VideoPlayer"));
var _VivipediaArticleBlock = _interopRequireDefault(require("./components/VivipediaArticleBlock"));
var _VivipediaArticleList = _interopRequireDefault(require("./components/VivipediaArticleList"));
var _VivipediaArticleBlockHome = _interopRequireDefault(require("./components/VivipediaArticleBlockHome"));
var _VivipediaArticleListHome = _interopRequireDefault(require("./components/VivipediaArticleListHome"));
var _VivipediaArticleListSlider = _interopRequireDefault(require("./components/VivipediaArticleListSlider"));
var _VivipediaLaunchBlock = _interopRequireDefault(require("./components/VivipediaLaunchBlock"));
var _VivipediaLaunchList = _interopRequireDefault(require("./components/VivipediaLaunchList"));
var _VivipediaPageToggler = _interopRequireDefault(require("./components/VivipediaPageToggler"));
var _VivipediaSearchBox = _interopRequireDefault(require("./components/VivipediaSearchBox"));
var _LoadMore = _interopRequireDefault(require("./components/LoadMore"));
var _SponsorSlider = _interopRequireDefault(require("./components/SponsorSlider"));
var _IconsGrid = _interopRequireDefault(require("./components/IconsGrid"));
var _AccordionFaq = _interopRequireDefault(require("./components/AccordionFaq"));
var _AccordionFaqList = _interopRequireDefault(require("./components/AccordionFaqList"));
var _PressRelease = _interopRequireDefault(require("./components/PressRelease"));
var _PressReleaseList = _interopRequireDefault(require("./components/PressReleaseList"));
var _ArchiveList = _interopRequireDefault(require("./components/ArchiveList"));
var _ArchiveFilter = _interopRequireDefault(require("./components/ArchiveFilter"));
var _ArchiveFilterGrid = _interopRequireDefault(require("./components/ArchiveFilterGrid"));
var _ArchiveCalendarList = _interopRequireDefault(require("./components/ArchiveCalendarList"));
var _Parallax = _interopRequireDefault(require("./components/Parallax"));
var _TextAnimation = _interopRequireDefault(require("./components/TextAnimation"));
var _UnsupportedBrowser = _interopRequireDefault(require("./components/UnsupportedBrowser"));
var _WorkWithUsTitle = _interopRequireDefault(require("./components/WorkWithUsTitle"));
var _WorkWithUsList = _interopRequireDefault(require("./components/WorkWithUsList"));
var _WorkWithUsBlock = _interopRequireDefault(require("./components/WorkWithUsBlock"));
var _WorkWithUsBlockList = _interopRequireDefault(require("./components/WorkWithUsBlockList"));
var _WorkWithUsForm = _interopRequireDefault(require("./components/WorkWithUsForm"));
var _CallMeBackWrapper = _interopRequireDefault(require("./components/CallMeBackWrapper"));
var _TextBlock = _interopRequireDefault(require("./components/TextBlock"));
var _SmallHeroBanner = _interopRequireDefault(require("./components/SmallHeroBanner"));
var _HeroBanner = _interopRequireDefault(require("./components/HeroBanner"));
var _NotFound = _interopRequireDefault(require("./components/NotFound"));
var _HeroBlob = _interopRequireDefault(require("./components/HeroBlob"));
var _StoreLocatorWrapper = _interopRequireDefault(require("./components/StoreLocatorWrapper"));
var _MgmHome = _interopRequireDefault(require("./components/MgmHome"));
var _Iframe = _interopRequireDefault(require("./components/Iframe"));
var _Codice = _interopRequireDefault(require("./components/Codice"));
var _ProductPageHero = _interopRequireDefault(require("./components/ProductPageHero"));
var _ProductPageHeroList = _interopRequireDefault(require("./components/ProductPageHeroList"));
var _ProductPageTab = _interopRequireDefault(require("./components/ProductPageTab"));
var _ModalPageTab = _interopRequireDefault(require("./components/ModalPageTab"));
var _ProductPageCards = _interopRequireDefault(require("./components/ProductPageCards"));
var _ProductPageInfoCards = _interopRequireDefault(require("./components/ProductPageInfoCards"));
var _ProductPageTabContent = _interopRequireDefault(require("./components/ProductPageTabContent"));
var _ProductPageRows = _interopRequireDefault(require("./components/ProductPageRows"));
var _Recensioni = _interopRequireDefault(require("./components/Recensioni"));
var _FaqPremium = _interopRequireDefault(require("./components/FaqPremium"));
var _DocumentsPremium = _interopRequireDefault(require("./components/DocumentsPremium"));
var _BillGuidelines = _interopRequireDefault(require("./components/BillGuidelines"));
var _ViviescoParagraphIcons = _interopRequireDefault(require("./components/Viviesco/ViviescoParagraphIcons"));
var _ViviescoProdotti = _interopRequireDefault(require("./components/Viviesco/ViviescoProdotti"));
var _ViviescoRiquadro = _interopRequireDefault(require("./components/Viviesco/ViviescoRiquadro"));
var _ViviescoForm = _interopRequireDefault(require("./components/Viviesco/ViviescoForm"));
var _FormWatty = _interopRequireDefault(require("./components/FormWatty/FormWatty"));
var _FormPmi = _interopRequireDefault(require("./components/FormPmi/FormPmi"));
var _StimaCostiCard = _interopRequireDefault(require("./components/StimaCostiCard"));
var _TariffaDetails = _interopRequireDefault(require("./components/TariffaDetails"));
var _BlobIcon = _interopRequireDefault(require("./components/BlobIcon"));
var _SectionForniture = _interopRequireDefault(require("./components/SectionForniture"));
var _CalcoloCostiAccordion = _interopRequireDefault(require("./components/CalcoloCostiAccordion"));
var _CallMeBackText = _interopRequireDefault(require("./components/CallMeBackText"));
var _ProductPageHighlightsSlider = _interopRequireDefault(require("./components/ProductPageHighlightsSlider"));
var _ProductPageAdvantagesSlider = _interopRequireDefault(require("./components/ProductPageAdvantagesSlider"));
var _Benefit = _interopRequireDefault(require("./components/Benefit"));
var _Switcher = _interopRequireDefault(require("./components/Switcher"));
var _withWrapper = _interopRequireDefault(require("./hoc/withWrapper"));
var _withPadder = _interopRequireDefault(require("./hoc/withPadder"));
var _withScrollWatcher = _interopRequireDefault(require("./hoc/withScrollWatcher"));
var _withStickyElement = _interopRequireDefault(require("./hoc/withStickyElement"));
var _withMainMenu = _interopRequireDefault(require("./hoc/withMainMenu"));
var _withModal = _interopRequireDefault(require("./hoc/withModal"));
var _withStoreLocator = _interopRequireDefault(require("./hoc/withStoreLocator"));
var _withLoginModal = _interopRequireDefault(require("./hoc/withLoginModal"));
var _withTracking = _interopRequireDefault(require("./hoc/withTracking"));
var _withDrawSVG = _interopRequireDefault(require("./hoc/withDrawSVG"));
var _ConfiguratorEntryPoint = _interopRequireDefault(require("./components/ConfiguratorEntryPoint"));
var _ConfiguratorWrapper = _interopRequireDefault(require("./components/Configurator/Components/ConfiguratorWrapper"));
var _ConfiguratorVerificaCopertura = _interopRequireDefault(require("./components/Configurator/Components/ConfiguratorVerificaCopertura"));
var _ConfiguratorStep = _interopRequireDefault(require("./components/Configurator/Components/ConfiguratorStep"));
var _ConfiguratorGrid = _interopRequireDefault(require("./components/Configurator/Components/ConfiguratorGrid"));
var _ConfiguratorList = _interopRequireDefault(require("./components/Configurator/Components/ConfiguratorList"));
var _ConfiguratorQuantity = _interopRequireDefault(require("./components/Configurator/Components/ConfiguratorQuantity"));
var _ConfiguratorConsumi = _interopRequireDefault(require("./components/Configurator/Components/ConfiguratorConsumi"));
var _ConfiguratorRicalcola = _interopRequireDefault(require("./components/Configurator/Components/ConfiguratorRicalcola"));
var _ConfiguratorProdotto = _interopRequireDefault(require("./components/Configurator/Components/ConfiguratorProdotto"));
var _BoxOfferConfigurator = _interopRequireDefault(require("./components/BoxOfferConfigurator"));
var _BoxOfferConfiguratorBusiness = _interopRequireDefault(require("./components/BoxOfferConfiguratorBusiness"));
var _ProductPageConfigurator = _interopRequireDefault(require("./components/ProductPageConfigurator"));
var _ConfiguratorEntryPage = _interopRequireDefault(require("./components/Configurator/Components/ConfiguratorEntryPage"));
var _FormChiamateSospette = _interopRequireDefault(require("./components/FormChiamateSospette"));
var _HeroPage = _interopRequireDefault(require("./components/HeroPage"));
var _BlockText = _interopRequireDefault(require("./components/Blocks/BlockText"));
var _BlockBox = _interopRequireDefault(require("./components/Blocks/BlockBox"));
var _BlockButton = _interopRequireDefault(require("./components/Blocks/BlockButton"));
var _BlockInput = _interopRequireDefault(require("./components/Blocks/BlockInput"));
var _BlockRadioButton = _interopRequireDefault(require("./components/Blocks/BlockRadioButton"));
var _style = _interopRequireDefault(require("./style"));