"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledStoreDetail = exports.StyledServiceInfoWrapper = exports.StyledOpeningDays = exports.StyledImage = exports.StyledIconWrapper = exports.StyledHoursTitle = exports.StyledDescription = exports.StyledContentWrapper = exports.StyledCity = exports.StyledAddressLocation = exports.StyledAddress = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledStoreDetail = exports.StyledStoreDetail = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledStoreDetail",
  componentId: "sc-rqqu64-0"
})(["\n  padding: 0 10px;\n  margin: 0 auto 50px;\n  color: ", ";\n  -webkit-font-smoothing: antialiased;\n\n  @media (min-width: 768px) {\n    max-width: 540px;\n    margin-bottom: 80px;\n  }\n\n  @media (min-width: 1280px) {\n    margin-bottom: 50px;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    max-width: 1170px;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
});
var StyledImage = exports.StyledImage = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledImage",
  componentId: "sc-rqqu64-1"
})(["\n  display: none;\n\n  @media (min-width: 1280px) {\n    height: 42a0px;\n    display: block;\n    flex-basis: 48.71%;\n    background-image: url(", ");\n    background-repeat: no-repeat;\n    background-position: center;\n    background-size: cover;\n  }\n"], function (props) {
  return props.imgUrl + '?width=1440&auto=format';
});
var StyledContentWrapper = exports.StyledContentWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContentWrapper",
  componentId: "sc-rqqu64-2"
})(["\n  @media (min-width: 1280px) {\n    flex-basis: 100%;\n  }\n"]);
var StyledCity = exports.StyledCity = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCity",
  componentId: "sc-rqqu64-3"
})(["\n  margin: 40px 0;\n  font-family: ", ";\n  text-align: center;\n  font-size: 18px;\n  text-transform: uppercase;\n\n  @media (min-width: 768px) {\n    margin: 30px 0;\n    font-size: 22px;\n  }\n\n  @media (min-width: 1280px) {\n    margin: 0 0 30px;\n    text-align: left;\n    font-size: 26px;\n  }\n"], function (props) {
  return props.theme.font.bold;
});
var StyledAddress = exports.StyledAddress = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledAddress",
  componentId: "sc-rqqu64-4"
})(["\n  padding-bottom: 30px;\n  border-bottom: 1px solid ", ";\n  font-family: ", ";\n  text-align: center;\n  font-size: 16px;\n\n  @media (min-width: 768px) {\n    padding-bottom: 20px;\n  }\n\n  @media (min-width: 1280px) {\n    text-align: left;\n  }\n"], function (props) {
  return props.theme.color.gray;
}, function (props) {
  return props.theme.font.regular;
});
var StyledAddressLocation = exports.StyledAddressLocation = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledAddressLocation",
  componentId: "sc-rqqu64-5"
})(["\n  font-family: ", ";\n  text-align: center;\n  font-size: 16px;\n  text-transform: uppercase;\n  @media (min-width: 1280px) {\n    text-align: left;\n  }\n"], function (props) {
  return props.theme.font.bold;
});
var StyledHoursTitle = exports.StyledHoursTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledHoursTitle",
  componentId: "sc-rqqu64-6"
})(["\n  margin: 40px 0 30px;\n  font-family: ", ";\n  font-size: 20px;\n"], function (props) {
  return props.theme.font.medium;
});
var StyledOpeningDays = exports.StyledOpeningDays = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledOpeningDays",
  componentId: "sc-rqqu64-7"
})(["\n  font-size: 16px;\n  margin-bottom: 60px;\n\n  @media (min-width: 768px) {\n    width: 310px;\n  }\n\n  @media (min-width: 1440px) {\n    width: auto;\n  }\n\n  > div {\n    > div {\n      display: flex;\n      flex-direction: row;\n      flex-wrap: wrap;\n\n      span {\n        flex-basis: 50%;\n\n        &:first-child {\n          font-family: ", ";\n        }\n\n        &:last-child {\n          font-family: ", ";\n        }\n      }\n    }\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.font.regular;
});
var StyledDescription = exports.StyledDescription = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDescription",
  componentId: "sc-rqqu64-8"
})(["\n  font-family: ", ";\n  font-size: 16px;\n  line-height: 1.5;\n"], function (props) {
  return props.theme.font.regular;
});
var StyledServiceInfoWrapper = exports.StyledServiceInfoWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledServiceInfoWrapper",
  componentId: "sc-rqqu64-9"
})(["\n  max-width: 100%;\n  margin: 40px auto 45px;\n  font-family: ", ";\n  color: ", ";\n  font-size: 16px;\n  line-height: 1.5;\n  position: relative;\n\n  @media (min-width: 768px) {\n    max-width: 740px;\n    margin: 20px auto 5px;\n    padding: 0 35px;\n  }\n\n  @media (min-width: 1440px) {\n    max-width: 100%;\n    width: 100%;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.magenta;
});
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-rqqu64-10"
})(["\n  width: 24px;\n  height: 24px;\n  position: absolute;\n  left: 50%;\n  transform: translateX(-50%);\n  top: -30px;\n\n  @media (min-width: 768px) {\n    top: 0;\n    left: 0;\n    transform: none;\n  }\n"]);