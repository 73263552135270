import React, {Component} from 'react'
import {connect} from 'react-redux'

import * as actions from '../redux/loginModal/actions'
import * as actionsStoreLocator from '../redux/storeLocatorModal/actions'
import * as actionsSearchModal from '../redux/searchModal/actions'

class OpenModal extends Component {
  render() {
    const {
      openModal,
      closeModal,
      openStoreLocatorModal,
      openSearchModal,
      closeSearchModal,
      closeStoreLocatorModal,
    } = this.props

    return (
      <React.Fragment>
        {this.props.children({
          handleOpen: openModal,
          handleClose: closeModal,
          openSearchModal: openSearchModal,
          closeSearchModal: closeSearchModal,
          openStoreLocatorModal: openStoreLocatorModal,
          closeStoreLocatorModal: closeStoreLocatorModal,
        })}
      </React.Fragment>
    )
  }
}

export default connect(
  null,
  {...actions, ...actionsStoreLocator, ...actionsSearchModal},
)(OpenModal)
