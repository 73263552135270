"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTextWrapper = exports.StyledSectionStaticBlob = exports.StyledImgWrapper = exports.StyledImg = exports.StyledBlob = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledBlob = exports.StyledBlob = _styledComponents.default.img.withConfig({
  displayName: "styles__StyledBlob",
  componentId: "sc-15xvjny-0"
})(["\n  width: 1440px;\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n\n  @media (min-width: 1440px) {\n    left: 120px;\n    transform: translateY(-50%);\n  }\n"]);
var StyledTextWrapper = exports.StyledTextWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTextWrapper",
  componentId: "sc-15xvjny-1"
})(["\n  color: ", ";\n  width: 290px;\n  line-height: 1.5;\n  z-index: 1;\n  text-align: center;\n\n  @media (min-width: 768px) {\n    width: 708px;\n  }\n\n  @media (min-width: 1440px) {\n    width: 1170px;\n  }\n\n  .medium-italic {\n    font-family: ", ";\n    font-size: 20px;\n\n    @media (min-width: 1440px) {\n      font-size: 30px;\n    }\n  }\n\n  .small {\n    font-family: ", ";\n    font-size: 18px;\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.font.mediumItalic;
}, function (props) {
  return props.theme.font.regular;
});
var StyledSectionStaticBlob = exports.StyledSectionStaticBlob = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSectionStaticBlob",
  componentId: "sc-15xvjny-2"
})(["\n  max-width: 1440px;\n  width: 100%;\n  height: 460px;\n  margin: 0 auto;\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  -webkit-font-smoothing: antialiased;\n\n  ", "\n\n  ", "\n"], function (props) {
  return props.type === "blue" && (0, _styledComponents.css)(["\n      height: 500px;\n      margin: 65px auto 85px;\n\n      @media (min-width: 768px) {\n        margin: 135px auto 150px;\n      }\n\n      @media (min-width: 1440px) {\n        height: 590px;\n        margin: 50px auto 105px;\n      }\n\n      ", " {\n        width: auto;\n        height: 100%;\n        position: absolute;\n        left: 50%;\n        top: 50%;\n        transform: translate(-50%, -50%);\n\n        @media (min-width: 1440px) {\n          left: 50%;\n          transform: translate(-50%, -50%);\n        }\n      }\n    "], StyledBlob);
}, function (props) {
  return props.type === "red" && (0, _styledComponents.css)(["\n      height: 500px;\n      margin: 65px auto 85px;\n\n      @media (min-width: 768px) {\n        height: 600px;\n        margin: 135px auto 150px;\n      }\n\n      @media (min-width: 1440px) {\n        height: 735px;\n        margin: 50px auto 105px;\n      }\n\n      ", " {\n        width: auto;\n        height: 100%;\n        position: absolute;\n        left: 50%;\n        top: 50%;\n        transform: translate(-50%, -50%);\n        z-index: -1;\n\n        @media (min-width: 1440px) {\n          left: 50%;\n          transform: translate(-50%, -50%);\n        }\n      }\n    "], StyledBlob);
});
var StyledImgWrapper = exports.StyledImgWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledImgWrapper",
  componentId: "sc-15xvjny-3"
})(["\n  width: 294px;\n  height: 288px;\n  padding: 38px 14px;\n  background-color: ", ";\n  border-radius: 35px;\n  box-shadow: 0px 0px 20px 2px ", ";\n\n  @media (min-width: 768px) {\n    width: 583px;\n    height: 387px;\n    margin-top: 30px;\n    padding: 24px 76px;\n  }\n\n  @media (min-width: 1440px) {\n    width: 729px;\n    height: 484px;\n    margin-top: 40px;\n    padding: 30px 95px;\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.color.shadowBlue;
});
var StyledImg = exports.StyledImg = _styledComponents.default.img.withConfig({
  displayName: "styles__StyledImg",
  componentId: "sc-15xvjny-4"
})(["\n  width: 100%;\n"]);