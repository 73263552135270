import React, { Fragment } from 'react'
import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import {
  withWrapper,
  withPadder,
  SectionTitle,
  SectionText,
} from '@alkemy/components'

const SectionAbstract = ({ title, text, alternativeLayout }) => (
  <Fragment>
    <SectionTitle title={title} refund={alternativeLayout} />
    <SectionText text={text} refund={alternativeLayout} />
  </Fragment>
)

export default compose(
  withPageBuilder(),
  withFields([
    {
      prop: 'wrapped',
      type: 'boolean',
      label: 'Contenuto Boxed',
    },
    {
      prop: 'paddingLeftRight',
      label: 'Larghezza Componente',
      type: 'multiple',
      options: ['200:Small', '170:Medium', '100:Large', '0:Nessuno'],
    },
    {
      prop: 'paddingTop',
      label: 'Distanza dal Top',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'paddingBottom',
      label: 'Distanza dal Bottom',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'text',
      label: 'Descrizione',
    },
    {
      prop: 'alternativeLayout',
      label: 'Layout alternativo',
      type: 'boolean',
    },
  ]),
  withWrapper,
  withPadder,
)(SectionAbstract)
