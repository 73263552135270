"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTriangle = exports.StyledTitle = exports.StyledParagraph = exports.StyledNeedsModal = exports.StyledIconWrapper = exports.StyledCtaWrapper = exports.StyledCTAIcon = exports.StyledCTA = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledNeedsModal = exports.StyledNeedsModal = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledNeedsModal",
  componentId: "sc-w9l73y-0"
})(["\n  width: 300px;\n  min-height: 380px;\n  padding: 60px 0 35px;\n  position: relative;\n  border-radius: 35px;\n  box-shadow: 0 0 35px 0 ", ";\n  background-color: ", ";\n  z-index: 10;\n\n  @media (min-width: 768px) {\n  }\n\n  @media (min-width: 1440px) {\n    width: 354px;\n  }\n"], function (props) {
  return props.theme.color.shadowBlue;
}, function (props) {
  return props.theme.color.white;
});
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-w9l73y-1"
})(["\n  width: 20px;\n  height: 20px;\n  position: absolute;\n  top: 20px;\n  right: 20px;\n  cursor: pointer;\n  z-index: 1;\n\n  path {\n    fill: ", ";\n  }\n"], function (props) {
  return props.theme.color.black;
});
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-w9l73y-2"
})(["\n  margin-bottom: 30px;\n  text-align: ", ";\n  font-size: 20px;\n  font-family: ", ";\n  color: ", ";\n"], function (_ref) {
  var _ref$alignment = _ref.alignment,
    alignment = _ref$alignment === void 0 ? 'center' : _ref$alignment;
  return alignment;
}, function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledParagraph = exports.StyledParagraph = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledParagraph",
  componentId: "sc-w9l73y-3"
})(["\n  margin-bottom: 20px;\n  padding: 0 20px;\n  font-size: 16px;\n  line-height: 1.5;\n  font-family: ", ";\n  color: ", ";\n\n  @media (min-width: 768px) {\n    padding: 0 40px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledCTA = exports.StyledCTA = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledCTA",
  componentId: "sc-w9l73y-4"
})(["\n  display: block;\n  color: ", ";\n  font-size: 14px;\n  font-family: ", ";\n  text-align: center;\n  text-decoration: none;\n\n  &:visited {\n    color: ", ";\n  }\n"], function (props) {
  return props.theme.color.magenta;
}, function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.magenta;
});
var StyledTriangle = exports.StyledTriangle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTriangle",
  componentId: "sc-w9l73y-5"
})(["\n  position: absolute;\n  left: 50%;\n  bottom: -10px;\n  transform: translateX(-50%);\n  width: 0;\n  height: 0;\n  border-left: 20px solid transparent;\n  border-right: 20px solid transparent;\n\n  border-top: 20px solid ", ";\n"], function (props) {
  return props.theme.color.white;
});
var StyledCtaWrapper = exports.StyledCtaWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCtaWrapper",
  componentId: "sc-w9l73y-6"
})(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n"]);
var StyledCTAIcon = exports.StyledCTAIcon = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCTAIcon",
  componentId: "sc-w9l73y-7"
})(["\n  width: 15px;\n  margin-left: 10px;\n"]);