"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.awesomeZoom = void 0;
var defaultCallback = function defaultCallback() {};
var awesomeZoom = exports.awesomeZoom = function awesomeZoom(map, markers, bounds, maxDistance, handleSuccess, handleError) {
  var sphere = window.google.maps.geometry.spherical;
  var min = maxDistance ? maxDistance : 999999999999;
  var current = false;
  markers.map(function (marker, index) {
    var distance = Math.round(sphere.computeDistanceBetween(bounds.geometry.location, marker.getPosition()));
    if (distance <= min) {
      min = distance;
      current = index;
    }
  });
  if (current !== false) {
    map.setZoom(13);
    map.panTo(markers[current].getPosition());
    handleSuccess(markers[current], {
      name: bounds.name,
      position: {
        lat: bounds.geometry.location.lat(),
        lng: bounds.geometry.location.lng()
      }
    });
  } else {
    handleError();
  }
};
var CiffiStoreLocator = function () {
  function CiffiStoreLocator(config, callback) {
    this.config = config || {};
    this.onLoad = callback || defaultCallback;
    this.clusterConfig = {
      gridSize: 100,
      minimumClusterSize: 2,
      zoomOnClick: true,
      styles: [{
        url: false,
        width: 32,
        height: 32,
        anchor: [0, 0],
        textColor: '#fff',
        textSize: 11,
        className: 'store-locator__cluster store-locator__cluster--small'
      }, {
        url: false,
        width: 32,
        height: 32,
        anchor: [0, 0],
        textColor: '#fff',
        textSize: 11,
        className: 'store-locator__cluster store-locator__cluster--big'
      }]
    };
    init.bind(this).call();
  }
  function loadGoogle(config) {
    var _lang = config.lang || 'it';
    var _key = config.apiKey || '';
    var _libraries = config.libraries ? '&libraries=' + config.libraries : '';
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//maps.googleapis.com/maps/api/js?key=' + _key + _libraries + '&language=' + _lang + '&callback=initializeMap';
    document.body.appendChild(script);
  }
  function initializeMap() {
    this.map = new window.google.maps.Map(document.getElementById(this.elId), this.config.mapOptions);
    var Cluster = require('./ciffi-storelocator-libs/cluster');
    this.ClusterManager = new Cluster(this.map, [], this.clusterConfig);
    window.google.maps.event.addDomListener(window, 'resize', function () {
      var center = this.map.getCenter();
      window.google.maps.event.trigger(this.map, 'resize');
      this.map.panTo(center);
    }.bind(this));
    this.directionsService = new window.google.maps.DirectionsService();
    this.directionsDisplay = new window.google.maps.DirectionsRenderer({
      map: this.map
    });
    if (this.config.mapStyle) {
      this.map.setOptions({
        styles: this.config.mapStyle
      });
    }
    this.markers = [];
    this.infoBoxes = [];
    if (this.config.stores && this.config.stores.length) {
      for (var _i = 0; _i < this.config.stores.length; _i++) {
        if (_i === this.config.stores.length - 1) {
          addMarker.bind(this)(this.config.stores[_i], this.map, (_i + 1) * this.config.markersAnimationDelay, function (res) {
            this.markers.push(res);
            this.onLoad(this);
          }.bind(this));
        } else {
          addMarker.bind(this)(this.config.stores[_i], this.map, (_i + 1) * this.config.markersAnimationDelay, function (res) {
            this.markers.push(res);
          }.bind(this));
        }
      }
    } else {
      this.onLoad(this);
    }
    if (this.config.autocomplete) {
      initAutocomplete.bind(this)();
    }
  }
  function showRoute(marker, position, type) {
    var _marker = marker;
    var _position = position;
    var _type = type;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (res) {
        this.directionsService.route({
          origin: {
            lat: res.coords.latitude,
            lng: res.coords.longitude
          },
          destination: _position,
          travelMode: _type
        }, function (res, status) {
          if (status === 'OK') {
            _marker.setVisible(false);
            this.directionsDisplay.setDirections(res);
            this.directionsDisplay.setPanel(document.getElementsByClassName(this.config.directionResults)[0]);
            var _routeHider = document.getElementsByClassName(this.config.directionButtons.resultsCloser)[0];
            _routeHider.addEventListener('click', function () {
              hideRoute.bind(this)(_marker);
            }.bind(this));
          }
        }.bind(this));
      }.bind(this));
    } else {
      console.log('geolocation is not supported');
    }
  }
  function hideRoute(marker) {
    var _marker = marker;
    this.directionsDisplay.set('directions', null);
    _marker.setVisible(true);
  }
  function addMarker(store, map, delay, callback) {
    setTimeout(function () {
      var _position = {
        lat: Number(store.mapPosition.lat),
        lng: Number(store.mapPosition.lng)
      };
      var _marker = new window.google.maps.Marker({
        position: _position,
        map: map,
        animation: window.google.maps.Animation.DROP,
        icon: store.icon || ''
      });
      if (store.centerMap) {
        map.panTo(_position);
      }
      if (this.infoBoxTemplate) {
        var _compiledInfoBoxTemplate = this.infoBoxTemplate.replace(/%\w+%/g, function (word) {
          if (store.info) {
            return store.info[word.replace(/%/g, '')] || word;
          }
        });
        var _infobox = require('./ciffi-storelocator-libs/infobox');
        var _myOptions = {
          boxClass: 'ciffi-info-box',
          content: _compiledInfoBoxTemplate,
          disableAutoPan: !this.config.infoboxAutoPan || false,
          maxWidth: 0,
          pixelOffset: new window.google.maps.Size(0, 0),
          zIndex: null,
          boxStyle: {},
          alignBottom: true,
          closeBoxMargin: '0px',
          closeBoxURL: '',
          infoBoxClearance: new window.google.maps.Size(100, 100),
          isHidden: false,
          pane: 'floatPane',
          enableEventPropagation: false
        };
        var _myInfoBox = new _infobox(_myOptions);
        this.infoBoxes.push(_myInfoBox);
        var _infoBoxes = this.infoBoxes;
      }
      _marker.addListener('click', function () {
        if (this.config.onMarkerClick && typeof this.config.onMarkerClick === 'function') {
          this.config.onMarkerClick(store);
        } else {
          map.panTo(_marker.getPosition());
          if (_myInfoBox.getVisible()) {
            _myInfoBox.close(map, _marker);
          } else {
            closeAllInfoBoxes(_infoBoxes);
            _myInfoBox.open(map, _marker);
            setTimeout(function () {
              var _close = document.getElementsByClassName(this.config.infoBoxCloser)[0];
              var _drive = document.getElementsByClassName(this.config.directionButtons.drive)[0];
              var _walk = document.getElementsByClassName(this.config.directionButtons.walk)[0];
              if (_close) {
                _close.addEventListener('click', function () {
                  _myInfoBox.close(map, _marker);
                });
              }
              if (_drive) {
                _drive.addEventListener('click', function () {
                  showRoute.bind(this)(_marker, _position, 'DRIVING');
                }.bind(this));
              }
              if (_walk) {
                _walk.addEventListener('click', function () {
                  showRoute.bind(this)(_marker, _position, 'WALKING');
                }.bind(this));
              }
            }.bind(this), 30);
          }
        }
      }.bind(this));
      if (this.config.markersClusters) {
        this.ClusterManager.addMarker(_marker, false);
      }
      _marker.store = store;
      callback(_marker);
    }.bind(this), delay);
  }
  function closeAllInfoBoxes(infoBoxes) {
    for (var _i = 0; _i < infoBoxes.length; _i++) {
      infoBoxes[_i].close();
    }
  }
  function initAutocomplete() {
    var _this = this;
    var input = document.querySelector(this.config.autocompleteSelector);
    this.autocomplete = new window.google.maps.places.Autocomplete(input, {
      //types: ['(cities)'],
      types: ['geocode'],
      componentRestrictions: {
        country: 'IT'
      }
    });
    this.autocomplete.addListener('place_changed', function () {
      var place = _this.autocomplete.getPlace();
      if (place.geometry) {
        awesomeZoom(_this.map, _this.markers, place, _this.config.awesomeZoomConfig.maxDistance, _this.config.awesomeZoomConfig.handleSuccess, _this.config.awesomeZoomConfig.handleError);
      }
    });
  }
  function init() {
    var el = typeof this.config.el === 'string' ? document.getElementsByClassName(this.config.el)[0] : this.config.el;
    this.infoBoxTemplate = el.firstElementChild ? el.innerHTML : false;
    this.elId = 'ciffi-storelocator-' + new Date().getTime();
    el.setAttribute('id', this.elId);
    if (!window.google) {
      window.initializeMap = initializeMap.bind(this);
      loadGoogle(this.config);
    } else {
      initializeMap.bind(this)();
    }
    return this.map;
  }
  return CiffiStoreLocator;
}();
var _default = exports.default = CiffiStoreLocator;