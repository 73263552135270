"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledWarningWrapper = exports.StyledSalesPoint = exports.StyledPlaceWrapper = exports.StyledOpeningDays = exports.StyledLinkWrapper = exports.StyledIconWrapper = exports.StyledCity = exports.StyledBlobWrapper = exports.StyledAddress = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledSalesPoint = exports.StyledSalesPoint = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSalesPoint",
  componentId: "sc-1e3dp7h-0"
})(["\n  padding: 30px 10px;\n  -webkit-font-smoothing: antialiased;\n  line-height: 1.2;\n  position: relative;\n  margin: 0 auto;\n\n  &:not(:last-child) {\n    border-bottom: 1px solid ", ";\n  }\n\n  @media (min-width: 768px) {\n    padding: 50px 0;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n  }\n\n  @media (min-width: 1440px) {\n    padding: 30px 0;\n    max-width: 1042px;\n  }\n"], function (props) {
  return props.theme.color.gray;
});
var StyledPlaceWrapper = exports.StyledPlaceWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledPlaceWrapper",
  componentId: "sc-1e3dp7h-1"
})(["\n  max-width: 71.85%;\n  margin-bottom: 15px;\n\n  @media (min-width: 768px) {\n    font-size: 20px;\n    max-width: 100%;\n    width: 315px;\n  }\n\n  @media (min-width: 1440px) {\n    width: 444px;\n    margin-right: 115px;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n  }\n"]);
var StyledCity = exports.StyledCity = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCity",
  componentId: "sc-1e3dp7h-2"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 16px;\n  margin-bottom: 5px;\n\n  @media (min-width: 768px) {\n    font-size: 20px;\n    max-width: 100%;\n    width: 300px;\n  }\n\n  @media (min-width: 1440px) {\n    padding-right: 25px;\n    flex-basis: 50%;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledAddress = exports.StyledAddress = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledAddress",
  componentId: "sc-1e3dp7h-3"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 12px;\n\n  @media (min-width: 768px) {\n    font-size: 14px;\n  }\n\n  @media (min-width: 1440px) {\n    padding-top: 8px;\n    flex-basis: 50%;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledOpeningDays = exports.StyledOpeningDays = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledOpeningDays",
  componentId: "sc-1e3dp7h-4"
})(["\n  max-width: 69%;\n  color: ", ";\n  font-size: 14px;\n\n  @media (min-width: 768px) {\n    font-size: 16px;\n    max-width: 100%;\n    width: 322px;\n  }\n\n  @media (min-width: 1440px) {\n    padding-top: 6px;\n    width: 300px;\n  }\n\n  > div {\n    > div {\n      display: flex;\n      flex-direction: row;\n      flex-wrap: wrap;\n\n      span {\n        flex-basis: 50%;\n\n        &:first-child {\n          font-family: ", ";\n        }\n\n        &:last-child {\n          font-family: ", ";\n        }\n      }\n    }\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.font.regular;
});
var StyledLinkWrapper = exports.StyledLinkWrapper = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledLinkWrapper",
  componentId: "sc-1e3dp7h-5"
})(["\n  min-width: 72px;\n  position: absolute;\n  right: 10px;\n  top: 50%;\n  transform: translateY(-50%);\n  color: ", ";\n  font-family: ", ";\n  display: flex;\n  align-items: center;\n  font-size: 14px;\n  text-decoration: none;\n  cursor: pointer;\n\n  @media (min-width: 768px) {\n    right: 0;\n  }\n"], function (props) {
  return props.theme.color.magenta;
}, function (props) {
  return props.theme.font.medium;
});
var StyledBlobWrapper = exports.StyledBlobWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBlobWrapper",
  componentId: "sc-1e3dp7h-6"
})(["\n  width: 100%;\n  height: 50px;\n  position: absolute;\n  top: -40px;\n\n  > canvas {\n    position: absolute;\n    z-index: -1;\n    left: 7px;\n  }\n"]);
var StyledWarningWrapper = exports.StyledWarningWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledWarningWrapper",
  componentId: "sc-1e3dp7h-7"
})(["\n  width: 15px;\n  position: absolute;\n  top: 13px;\n  left: 20px;\n"]);
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-1e3dp7h-8"
})(["\n  width: 14px;\n  margin-left: 12px;\n"]);