import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'

import { CallMeBackPage, withWrapper } from '@alkemy/components'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

export default compose(
  withPageBuilder({
    niceName: 'Call Me Back New',
  }),
  withFields([
    {
      prop: 'wrapped',
      type: 'boolean',
      label: 'Icona Immagine',
    },
  ]),
  withWrapper,
)(CallMeBackPage)
