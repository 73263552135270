"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WrapperChildren = exports.Wrapper = exports.TimeTitle = exports.TimeSelectionContainer = exports.TimeHourWrapper = exports.TimeContainer = exports.TextInputsContainer = exports.TextInputWrapper = exports.TextInputErrorMessage = exports.StyledStepNavWrapper = exports.StyledIconWrapper = exports.StepsContainer = exports.SectionTitle = exports.RadioInputsContainer = exports.RadioInputWrapper = exports.RadioDateInputWrapper = exports.PrivacyWrapper = exports.PrivacyText = exports.ModuleWrapper = exports.LeftParagraph = exports.Label = exports.HeaderTitle = exports.HeaderText = exports.ErrorMessage = exports.DaySliderWrapper = exports.DaySelectTitle = exports.ContentWrapper = exports.CenteredParagraph = exports.ButtonsContainer = exports.ButtonWrapper = exports.ActivityTitleText = exports.ActivityTitleIcon = exports.ActivityTitleContainer = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var ButtonsContainer = exports.ButtonsContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__ButtonsContainer",
  componentId: "sc-i3fxln-0"
})(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin: auto;\n  position: absolute;\n  bottom: 30px;\n  left: 0;\n  right: 0;\n  max-width: 400px;\n  z-index: 9999;\n  ", " {\n    max-width: 100%;\n  }\n"], function (props) {
  return props.theme.mediaQueries.small;
});
var ButtonWrapper = exports.ButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__ButtonWrapper",
  componentId: "sc-i3fxln-1"
})(["\n  margin: auto;\n  max-width: 200px;\n"]);
var RadioInputsContainer = exports.RadioInputsContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__RadioInputsContainer",
  componentId: "sc-i3fxln-2"
})(["\n  display: flex;\n  flex-flow: row wrap;\n  justify-content: center;\n  margin: 0;\n  padding: 0;\n"]);
var RadioInputWrapper = exports.RadioInputWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__RadioInputWrapper",
  componentId: "sc-i3fxln-3"
})(["\n  margin: auto;\n  @media (max-width: 768px) {\n    position: relative;\n    max-width: 50%;\n    flex: 50%;\n    max-height: 100px;\n    padding: 0px;\n    margin: 20px auto 0 auto;\n  }\n"]);
var RadioDateInputWrapper = exports.RadioDateInputWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__RadioDateInputWrapper",
  componentId: "sc-i3fxln-4"
})(["\n  margin: auto;\n  padding: 10px 10px;\n"]);
var TextInputsContainer = exports.TextInputsContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__TextInputsContainer",
  componentId: "sc-i3fxln-5"
})(["\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  margin: 0px auto 40px auto;\n  max-width: 740px;\n  ", " {\n    margin: 0px auto 20px auto;\n  }\n"], function (props) {
  return props.theme.mediaQueries.small;
});
var TextInputWrapper = exports.TextInputWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__TextInputWrapper",
  componentId: "sc-i3fxln-6"
})(["\n  width: 250px;\n  margin: 30px auto;\n  ", " {\n    margin: 20px auto;\n  }\n"], function (props) {
  return props.theme.mediaQueries.small;
});
var ErrorMessage = exports.ErrorMessage = _styledComponents.default.div.withConfig({
  displayName: "styles__ErrorMessage",
  componentId: "sc-i3fxln-7"
})(["\n  margin: 20px auto 20px auto;\n  text-align: center;\n  color: red;\n  font-family: ", ";\n  ", " {\n    margin: 0px auto 40px auto;\n  }\n  @media (max-width: 768px) {\n    position: absolute;\n    top: 120px;\n    left: 0;\n    padding: 10px;\n    width: 100%;\n    background: ", ";\n    color: ", ";\n  }\n"], function (props) {
  return props.theme.font.bold;
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.color.yellowPink;
}, function (props) {
  return props.theme.color.white;
});
var TextInputErrorMessage = exports.TextInputErrorMessage = _styledComponents.default.div.withConfig({
  displayName: "styles__TextInputErrorMessage",
  componentId: "sc-i3fxln-8"
})(["\n  margin: 10px auto 0px auto;\n  text-align: center;\n  color: red;\n  font-family: ", ";\n"], function (props) {
  return props.theme.font.bold;
});
var Wrapper = exports.Wrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-i3fxln-9"
})(["\n  flex: 1;\n  width: 100%;\n  margin-bottom: 100px;\n  overflow-y: auto;\n  margin: 0px 7px;\n  /* margin: 0px 7px;\n  padding: 20px 5px;\n  position: relative;\n  max-width: 400px;\n\n  @media (min-width: 768px) {\n    max-width: 768px;\n    padding: 30px 14px;\n  }\n  @media (min-width: 1024px) {\n    max-width: 1440px;\n    padding: 50px 25px;\n  } */\n"]);
var PrivacyText = exports.PrivacyText = _styledComponents.default.p.withConfig({
  displayName: "styles__PrivacyText",
  componentId: "sc-i3fxln-10"
})(["\n  margin: auto;\n  text-align: center;\n  color: ", ";\n  font-family: ", ";\n  font-size: 13px;\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.light;
});
var PrivacyWrapper = exports.PrivacyWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__PrivacyWrapper",
  componentId: "sc-i3fxln-11"
})(["\n  text-align: center;\n  margin: 0px auto 0px auto;\n  @media (max-width: 768px) {\n    margin-bottom: 80px;\n  }\n"]);
var CenteredParagraph = exports.CenteredParagraph = _styledComponents.default.p.withConfig({
  displayName: "styles__CenteredParagraph",
  componentId: "sc-i3fxln-12"
})(["\n  color: ", ";\n  text-align: center;\n"], function (props) {
  return props.theme.color.darkBlue;
});
var LeftParagraph = exports.LeftParagraph = _styledComponents.default.p.withConfig({
  displayName: "styles__LeftParagraph",
  componentId: "sc-i3fxln-13"
})(["\n  color: ", ";\n  text-align: center;\n  font-size: 14px !important;\n  @media (max-width: 768px) {\n    text-align: left;\n  }\n"], function (props) {
  return props.theme.color.darkGray;
});
var HeaderText = exports.HeaderText = _styledComponents.default.h2.withConfig({
  displayName: "styles__HeaderText",
  componentId: "sc-i3fxln-14"
})(["\n  color: ", ";\n  text-align: center;\n"], function (props) {
  return props.theme.color.darkBlue;
});
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-i3fxln-15"
})(["\n  width: 34px;\n  height: 34px;\n  position: absolute;\n  top: 20px;\n  right: 20px;\n"]);
var StyledStepNavWrapper = exports.StyledStepNavWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledStepNavWrapper",
  componentId: "sc-i3fxln-16"
})(["\n  transform: rotateZ(-90deg);\n  margin: 0 auto;\n  /* position: absolute; */\n  /* left: 50%; */\n  /* top: 0px; */\n  @media (min-width: 1024px) {\n    position: absolute;\n    left: 0;\n    top: 50%;\n    transform: translateX(-50%);\n    transform: none;\n    margin: auto 0;\n  }\n"]);
var ModuleWrapper = exports.ModuleWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__ModuleWrapper",
  componentId: "sc-i3fxln-17"
})(["\n  width: 100%;\n"]);
var Label = exports.Label = _styledComponents.default.div.withConfig({
  displayName: "styles__Label",
  componentId: "sc-i3fxln-18"
})([""]);
var ContentWrapper = exports.ContentWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__ContentWrapper",
  componentId: "sc-i3fxln-19"
})(["\n  width: 100%;\n  max-width: 840px;\n  margin: 0 auto;\n  ", " {\n    height: auto;\n  }\n"], function (props) {
  return props.theme.mediaQueries.small;
});
var WrapperChildren = exports.WrapperChildren = _styledComponents.default.div.withConfig({
  displayName: "styles__WrapperChildren",
  componentId: "sc-i3fxln-20"
})(["\n  margin-top: 60px;\n  @media (max-width: 992px) {\n    max-height: 100%;\n    margin-top: -20px;\n  }\n"]);
var SectionTitle = exports.SectionTitle = _styledComponents.default.h2.withConfig({
  displayName: "styles__SectionTitle",
  componentId: "sc-i3fxln-21"
})(["\n  font-family: ", ";\n  font-weight: bold;\n  font-size: 16px;\n  color: ", ";\n  text-align: center;\n  margin-top: 28px;\n  text-transform: uppercase;\n"], function (props) {
  return props.theme.font.bold;
}, function (props) {
  return props.theme.color.darkBlue;
});
var DaySelectTitle = exports.DaySelectTitle = (0, _styledComponents.default)(SectionTitle).withConfig({
  displayName: "styles__DaySelectTitle",
  componentId: "sc-i3fxln-22"
})([""]);
var DaySliderWrapper = exports.DaySliderWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__DaySliderWrapper",
  componentId: "sc-i3fxln-23"
})(["\n  margin-top: 32px;\n"]);
var TimeContainer = exports.TimeContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__TimeContainer",
  componentId: "sc-i3fxln-24"
})(["\n  margin-bottom: 80px;\n"]);
var TimeTitle = exports.TimeTitle = (0, _styledComponents.default)(SectionTitle).withConfig({
  displayName: "styles__TimeTitle",
  componentId: "sc-i3fxln-25"
})([""]);
var TimeSelectionContainer = exports.TimeSelectionContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__TimeSelectionContainer",
  componentId: "sc-i3fxln-26"
})(["\n  display: flex;\n  justify-content: center;\n  margin-top: 22px;\n"]);
var TimeHourWrapper = exports.TimeHourWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__TimeHourWrapper",
  componentId: "sc-i3fxln-27"
})(["\n  margin-right: 16px;\n  @media (min-width: 1024px) {\n    margin-right: 13px;\n  }\n"]);
var StepsContainer = exports.StepsContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StepsContainer",
  componentId: "sc-i3fxln-28"
})(["\n  margin-top: 24px;\n  margin-bottom: 50px;\n  @media (min-width: 1400px) {\n    position: absolute;\n    left: 0;\n    top: 0;\n    bottom: 0;\n    display: flex;\n    align-items: center;\n  }\n"]);
var ActivityTitleContainer = exports.ActivityTitleContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__ActivityTitleContainer",
  componentId: "sc-i3fxln-29"
})(["\n  padding: 0;\n  margin: 0;\n  color: ", ";\n\n  font-family: ", ";\n  text-align: center;\n  margin-bottom: 0px;\n  margin-top: 10px;\n  display: flex;\n  justify-content: center;\n  align-items: flex-end;\n\n  @media (min-width: 768px) {\n    margin-bottom: 56px;\n    margin-top: 100px;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.regular;
});
var ActivityTitleText = exports.ActivityTitleText = _styledComponents.default.p.withConfig({
  displayName: "styles__ActivityTitleText",
  componentId: "sc-i3fxln-30"
})(["\n  padding: 0;\n  margin: 0;\n  color: ", ";\n  font-size: 18px;\n  font-family: ", ";\n  text-align: center;\n  @media (max-width: 768px) {\n    font-size: 12px;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.regular;
});
var ActivityTitleIcon = exports.ActivityTitleIcon = _styledComponents.default.div.withConfig({
  displayName: "styles__ActivityTitleIcon",
  componentId: "sc-i3fxln-31"
})(["\n  margin-left: 20px;\n  width: 22px;\n  height: 22px;\n  flex-shrink: 0;\n"]);
var HeaderTitle = exports.HeaderTitle = _styledComponents.default.h2.withConfig({
  displayName: "styles__HeaderTitle",
  componentId: "sc-i3fxln-32"
})(["\n  margin: 0;\n  color: ", ";\n  font-size: 22px;\n  font-weight: 500;\n  font-family: ", ";\n  text-transform: uppercase;\n  flex: 1;\n  text-align: center;\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.medium;
});