"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RoundButtonIcon = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var activeBkg = "\n  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e81c69+41,f09724+100 */\n  background: #e81c69; /* Old browsers */\n  background: -moz-linear-gradient(left, #e81c69 41%, #f09724 100%); /* FF3.6-15 */\n  background: -webkit-linear-gradient(left, #e81c69 41%,#f09724 100%); /* Chrome10-25,Safari5.1-6 */\n  background: linear-gradient(to right, #e81c69 41%,#f09724 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */\n  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e81c69', endColorstr='#f09724',GradientType=1 ); /* IE6-9 */\n";
var RoundButtonIcon = exports.RoundButtonIcon = _styledComponents.default.div.withConfig({
  displayName: "styles__RoundButtonIcon",
  componentId: "sc-1ad2k41-0"
})(["\n  padding: 5px 60px;\n  background-color: #fff;\n  border-radius: 47px;\n  cursor: pointer;\n  color: ", ";\n  font-family: ", ";\n  font-weight: 400;\n  display: inline-block;\n  font-size: 15px;\n  width: 100%;\n  border: ", ";\n  text-align: center;\n  text-transform: uppercase;\n  padding: ", ";\n  height: 70px;\n  width: 70px;\n  margin: 0;\n  > div {\n    * {\n      fill: ", ";\n    }\n  }\n  ", ";\n"], function (props) {
  return props.active ? "#fff" : "#E81C69";
}, function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.active ? "" : "2px solid #E81C69";
}, function (props) {
  return props.active ? "12px" : "10px";
}, function (props) {
  return props.active ? "#fff" : "#E81C69";
}, function (props) {
  return props.active && activeBkg;
});