"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledInput = exports.StyledIconWrapper = exports.StyledFormTextInput = exports.StyledErrorWrapper = exports.SelectMenu = exports.SelectItem = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledFormTextInput = exports.StyledFormTextInput = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledFormTextInput",
  componentId: "sc-1sew9pa-0"
})(["\n  width: 100%;\n"]);
var StyledInput = exports.StyledInput = _styledComponents.default.input.withConfig({
  displayName: "styles__StyledInput",
  componentId: "sc-1sew9pa-1"
})(["\n  width: 100%;\n  padding: 25px 6px 14px;\n  -webkit-appearance: none;\n  border: none;\n  border-bottom: 1px solid ", ";\n  font-family: ", ";\n  font-size: 18px;\n  transition: all 0.2s linear 0s;\n  outline: none;\n\n  &::placeholder {\n    color: ", ";\n    transition: color 0.2s linear 0s;\n  }\n\n  &:focus {\n    border-bottom: 1px solid ", ";\n\n    &::placeholder {\n      color: ", ";\n    }\n  }\n"], function (props) {
  return props.theme.color.gray;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.gray;
}, function (props) {
  return props.theme.color.lightBlue;
}, function (props) {
  return props.theme.color.lightBlue;
});
var SelectItem = exports.SelectItem = _styledComponents.default.div.withConfig({
  displayName: "styles__SelectItem",
  componentId: "sc-1sew9pa-2"
})(["\n  width: 100%;\n  padding: 25px 6px 14px;\n  -webkit-appearance: none;\n  border: none;\n  border-bottom: 1px solid ", ";\n  font-family: ", ";\n  font-size: 18px;\n  transition: all 0.2s linear 0s;\n  outline: none;\n  background: ", ";\n"], function (props) {
  return props.theme.color.gray;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.isHighlighted ? props.theme.color.gray : 'white';
});
var SelectMenu = exports.SelectMenu = _styledComponents.default.div.withConfig({
  displayName: "styles__SelectMenu",
  componentId: "sc-1sew9pa-3"
})(["\n  width: 100%;\n  position: absolute;\n  -webkit-appearance: none;\n  background: white;\n  z-index: 9;\n  border-left: 1px solid ", ";\n  border-right: 1px solid ", ";\n"], function (props) {
  return props.theme.color.gray;
}, function (props) {
  return props.theme.color.gray;
});
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-1sew9pa-4"
})([""]);
var StyledErrorWrapper = exports.StyledErrorWrapper = _styledComponents.default.span.withConfig({
  displayName: "styles__StyledErrorWrapper",
  componentId: "sc-1sew9pa-5"
})(["\n  color: ", ";\n"], function (props) {
  return props.theme.color.red;
});