import {MODAL_OPEN_SEARCH, MODAL_CLOSE_SEARCH} from './types'

export default (state = {}, action) => {
  switch (action.type) {
    case MODAL_OPEN_SEARCH:
      return {
        status: 'OPEN',
      }
    case MODAL_CLOSE_SEARCH:
      return {
        status: 'CLOSED',
      }
    default:
      return state
  }
}

export const getSearchModal = state => {
  return state.searchModal
}

export const getSearchModalStatus = state => {
  return state.searchModal && state.searchModal.status
}
