"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledWrapper = exports.StyledIcon = exports.StyledCounterValue = exports.StyledCounterIcon = exports.StyledCounter = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _styles = require("../../styles");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledWrapper = exports.StyledWrapper = (0, _styledComponents.default)(_styles.FlexCenter).withConfig({
  displayName: "styles__StyledWrapper",
  componentId: "sc-jg7gx5-0"
})(["\n  flex-direction: column;\n"]);
var StyledIcon = exports.StyledIcon = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIcon",
  componentId: "sc-jg7gx5-1"
})(["\n  width: auto;\n  height: 80px;\n  ", " {\n    height: 88px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledCounter = exports.StyledCounter = (0, _styledComponents.default)(_styles.FlexCenter).withConfig({
  displayName: "styles__StyledCounter",
  componentId: "sc-jg7gx5-2"
})(["\n  justify-content: space-between;\n  gap: 20px;\n  margin-bottom: 48px;\n  ", " {\n    gap: 25px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledCounterValue = exports.StyledCounterValue = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCounterValue",
  componentId: "sc-jg7gx5-3"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 20px;\n  line-height: 1.5;\n  padding: 5px 0;\n  width: 120px;\n  height: auto;\n  display: flex;\n  justify-content: center;\n  border-bottom: 1px dotted ", ";\n\n  ", " {\n    padding: 8px 0;\n    width: 180px;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledCounterIcon = exports.StyledCounterIcon = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCounterIcon",
  componentId: "sc-jg7gx5-4"
})(["\n  width: 40px;\n  height: 40px;\n  cursor: pointer;\n  transition: all 0.2s;\n\n  &:hover {\n    opacity: 0.6;\n  }\n  ", "\n\n  ", " {\n    width: 48px;\n    height: 48px;\n  }\n"], function (props) {
  return props.disabled && (0, _styledComponents.css)(["\n      cursor: default;\n      opacity: 0.6;\n    "]);
}, function (props) {
  return props.theme.mediaQueries.tablet;
});