import React from 'react'
import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import Sticky from 'react-stickynode'

import { HeroBanner, Social } from '@alkemy/components'

const HeroBannerWithSticky = props => (
  <React.Fragment>
    <HeroBanner {...props} />
    {props.stickyShare && (
      <Sticky enabled top={200} bottomBoundary="#socialEnd">
        <div style={{ position: 'absolute', right: 0, paddingRight: '40px' }}>
          <Social title="Titolo" />
        </div>
      </Sticky>
    )}
  </React.Fragment>
)

export default compose(
  withPageBuilder({
    niceName: 'Hero Banner',
  }),
  withFields([
    {
      prop: 'deactivateBlob',
      type: 'boolean',
      label: 'Nascondi  Blob',
    },
    {
      prop: 'blobColors',
      type: 'multiple',
      label: 'Colore Blob',
      options: [
        {
          label: 'Arancione',
          value: [{ text: '#f49864' }, { text: '#f6b624' }],
        },
        {
          label: 'Blue',
          value: [{ text: '#7E2889' }, { text: '#1587C8' }],
        },
        {
          label: 'Verde',
          value: [{ text: '#6EC18A' }, { text: '#0E89CA' }],
        },
        {
          label: 'Verde PMI',
          value: [{ text: '#6EC18A' }, { text: 'rgba(14, 137, 202, 1)' }],
        },
      ],
      getInputValue: value =>
        value && value.length > 0 && value.map(({ text }) => text).join('|'),
      getPropValue: value => value && value.split('|').map(x => ({ text: x })),
    },
    {
      prop: 'text_color',
      type: 'multiple',
      label: 'Colore Titolo',
      options: ['darkText:Testo Scuro', 'lightText:Testo Chiaro'],
      initialValue: 'lightText',
    },
    {
      prop: 'alignment',
      type: 'multiple',
      label: 'Allinea titolo',
      options: ['center:Allinea al centro', 'bottom:Allinea in basso'],
      initialValue: 'center',
    },
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'imgUrl',
      label: 'Immagine',
    },
    {
      prop: 'stickyShare',
      type: 'boolean',
      label: 'Condivisione Social',
    },
  ]),
)(HeroBannerWithSticky)
