"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledPadder = exports.StyledOffersFormServices = exports.StyledButtonWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledOffersFormServices = exports.StyledOffersFormServices = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledOffersFormServices",
  componentId: "sc-4vv21i-0"
})(["\n  > div {\n    display: flex;\n    justify-content: space-between;\n    flex-flow: row wrap;\n  }\n"]);
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-4vv21i-1"
})(["\n  width: 240px;\n  margin: 40px auto 0;\n\n  @media (min-width: 768px) {\n    margin: 0 auto;\n    padding-top: 90px;\n  }\n"]);
var StyledPadder = exports.StyledPadder = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledPadder",
  componentId: "sc-4vv21i-2"
})(["\n  @media (min-width: 1440px) {\n    padding-bottom: 200px;\n  }\n"]);