import { MODAL_OPEN_SL, MODAL_CLOSE_SL } from "./types"

export default (state = {}, action) => {
  switch (action.type) {
    case MODAL_OPEN_SL:
      return {
        status: "OPEN",
      }
    case MODAL_CLOSE_SL:
      return {
        status: "CLOSED",
      }
    default:
      return state
  }
}

export const getStoreLocatorModal = state => {
  return state.storeLocatorModal
}

export const getStoreLocatorModalStatus = state => {
  return state.storeLocatorModal && state.storeLocatorModal.status
}
