import React from 'react'
import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { PressReleaseList, withWrapper, withPadder } from '@alkemy/components'

const PressReleases = props => <PressReleaseList {...props} showAll />

export default compose(
  withPageBuilder({
    niceName: 'Elenco Comunicati Stampa',
  }),
  withFields([
    {
      prop: 'paddingTop',
      label: 'Distanza dal Top',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'paddingBottom',
      label: 'Distanza dal Bottom',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'pressReleases',
      label: 'Comunicati',
      type: 'repeater',
      initialValue: [],
      model: [
        {
          prop: 'date',
          label: 'Data',
        },
        {
          prop: 'title',
          label: 'Titolo',
        },
        {
          prop: 'text',
          label: 'Descrizione',
          //type: 'WYSIWYG',
        },
        {
          prop: 'download',
          label: 'Link',
          type: 'group',
          model: [
            {
              prop: 'label',
              label: 'Etichetta',
            },
            {
              prop: 'href',
              label: 'URL',
            },
          ],
        },
      ],
    },
  ]),
  withWrapper,
  withPadder,
)(PressReleases)
