"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Wrapper = exports.WarningMessage = exports.Title = exports.Subtitle = exports.ResourceTitle = exports.ResourceDescription = exports.ResourceContainer = exports.OptionText = exports.OptionContainerTitleText = exports.OptionContainerTitle = exports.Option = exports.OffertaWrapper = exports.OffertaTitle = exports.OffertaHeader = exports.InnerWrapper = exports.IconContainer = exports.HeaderWrapper = exports.GasOptionContainer = exports.Flex = exports.FasciaTitle = exports.FasciaDescription = exports.Fascia = exports.FasceWrapper = exports.CustomRadio = exports.Button = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _ = require("../../");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var Wrapper = exports.Wrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-1umm2vy-0"
})(["\n  font-family: ", ";\n  color: ", ";\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  gap: 10px;\n  width: 100%;\n\n  ", " {\n    justify-content: flex-start;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var Title = exports.Title = _styledComponents.default.h2.withConfig({
  displayName: "styles__Title",
  componentId: "sc-1umm2vy-1"
})(["\n  margin: 5px 0;\n"]);
var Subtitle = exports.Subtitle = _styledComponents.default.p.withConfig({
  displayName: "styles__Subtitle",
  componentId: "sc-1umm2vy-2"
})(["\n  margin: 5px 0;\n"]);
var OffertaWrapper = exports.OffertaWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__OffertaWrapper",
  componentId: "sc-1umm2vy-3"
})(["\n  display: flex;\n  flex-direction: column;\n  text-align: left;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  gap: 32px;\n"]);
var OffertaHeader = exports.OffertaHeader = _styledComponents.default.div.withConfig({
  displayName: "styles__OffertaHeader",
  componentId: "sc-1umm2vy-4"
})(["\n  display: flex;\n  align-items: center;\n  align-self: flex-start;\n  gap: 10px;\n  padding: 0 30px;\n\n  ", " {\n    padding: 0;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var OffertaTitle = exports.OffertaTitle = _styledComponents.default.span.withConfig({
  displayName: "styles__OffertaTitle",
  componentId: "sc-1umm2vy-5"
})(["\n  color: ", ";\n"], function (props) {
  return props.theme.color.orange;
});
var CustomRadio = exports.CustomRadio = _styledComponents.default.input.withConfig({
  displayName: "styles__CustomRadio",
  componentId: "sc-1umm2vy-6"
})(["\n  padding: 10px;\n  height: 24px;\n  width: 24px;\n  border: 1px solid ", ";\n  border-radius: 50%;\n  position: relative;\n\n  &:checked:after {\n    width: 16px;\n    height: 16px;\n    border-radius: 50%;\n    background-color: #6ec18a;\n    content: '';\n    visibility: visible;\n    border: 1px solid white;\n    margin: 3px;\n    position: absolute;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
});
var Option = exports.Option = _styledComponents.default.div.withConfig({
  displayName: "styles__Option",
  componentId: "sc-1umm2vy-7"
})(["\n  padding: 20px 15px;\n  margin: 0 30px;\n  width: 100%;\n  display: grid;\n  grid-template-columns: 250px 1fr;\n  align-items: center;\n  gap: 90px;\n  box-shadow: 0 10px 35px 0 rgba(81, 129, 186, 0.25);\n  border: 2px solid transparent;\n  border-radius: 20px;\n  cursor: pointer;\n\n  ", "\n\n  ", " {\n    grid-template-columns: 1fr;\n    justify-content: center;\n    gap: 0;\n    align-items: flex-start !important;\n  }\n"], function (props) {
  if (props.selected) {
    return "\n        background-clip: padding-box; /* !important\xE9 */\n        border: solid 2px transparent; /* !important\xE9 */\n        background: \n          linear-gradient(#FFF,#FFF) padding-box,\n          linear-gradient(to right, #0E89CA, #6EC18A) border-box;\n      ";
  }
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var OptionText = exports.OptionText = _styledComponents.default.h3.withConfig({
  displayName: "styles__OptionText",
  componentId: "sc-1umm2vy-8"
})(["\n  font-size: 20px;\n"]);
var WarningMessage = exports.WarningMessage = _styledComponents.default.div.withConfig({
  displayName: "styles__WarningMessage",
  componentId: "sc-1umm2vy-9"
})(["\n  padding: 5px 16px;\n  background-color: #fecb1c;\n  color: ", ";\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  gap: 14px;\n  margin: 20px auto;\n  opacity: 0.7;\n  border-radius: 10px;\n  font-size: 12px;\n  width: fit-content;\n"], function (props) {
  return props.theme.color.darkBlue;
});
var Button = exports.Button = _styledComponents.default.button.withConfig({
  displayName: "styles__Button",
  componentId: "sc-1umm2vy-10"
})(["\n  font-stretch: normal;\n  font-style: normal;\n  line-height: normal;\n  letter-spacing: normal;\n  text-align: center;\n  font-family: ", ";\n  font-weight: 500;\n  color: #fff;\n  font-size: 20px;\n  padding: 15px 45px;\n  border: none;\n  background-image: ", ";\n  border-radius: 30px;\n  width: fit-content;\n  bottom: 10px;\n  position: sticky;\n  cursor: pointer;\n\n  ", " {\n    width: 100vw;\n    z-index: 10;\n    position: absolute;\n    left: 0;\n    bottom: 0;\n    border-radius: 0;\n  }\n"], function (props) {
  return props.theme.mediaQueries.medium;
}, function (props) {
  return props.theme.color.blueViolet;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var FasceWrapper = exports.FasceWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__FasceWrapper",
  componentId: "sc-1umm2vy-11"
})(["\n  display: flex;\n  align-items: center;\n  gap: 30px;\n\n  ", " {\n    flex-direction: column;\n    align-items: flex-start;\n    justify-content: center;\n    margin-left: 45px;\n    gap: 21px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var Fascia = exports.Fascia = _styledComponents.default.div.withConfig({
  displayName: "styles__Fascia",
  componentId: "sc-1umm2vy-12"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 12px;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.83;\n  letter-spacing: normal;\n  display: flex;\n  flex-direction: column;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var FasciaTitle = exports.FasciaTitle = _styledComponents.default.h3.withConfig({
  displayName: "styles__FasciaTitle",
  componentId: "sc-1umm2vy-13"
})(["\n  font-size: 14px;\n  margin: 0;\n"]);
var FasciaDescription = exports.FasciaDescription = _styledComponents.default.p.withConfig({
  displayName: "styles__FasciaDescription",
  componentId: "sc-1umm2vy-14"
})(["\n  font-size: 12px;\n  font-family: ", ";\n  margin: 0;\n"], function (props) {
  return props.theme.font.light;
});
var GasOptionContainer = exports.GasOptionContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__GasOptionContainer",
  componentId: "sc-1umm2vy-15"
})(["\n  position: relative;\n  border-radius: 35px;\n  border: solid 1px rgba(151, 151, 151, 0.35);\n  padding: 24px 30px 24px 7px;\n  margin-top: 45px;\n  width: 100%;\n\n  ", " {\n    display: flex;\n    align-items: center;\n    padding: 24px 30px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.desktop;
});
var OptionContainerTitle = exports.OptionContainerTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__OptionContainerTitle",
  componentId: "sc-1umm2vy-16"
})(["\n  margin: 0;\n  position: absolute;\n  top: -8px;\n  right: 0;\n  left: 0;\n  background-color: transparent;\n  display: flex;\n  justify-content: center;\n"]);
var TextBlock = _styledComponents.default.p.withConfig({
  displayName: "styles__TextBlock",
  componentId: "sc-1umm2vy-17"
})(["\n  font-size: 16px;\n  font-family: ", ";\n  font-weight: normal;\n  color: ", ";\n  margin: 0;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var OptionContainerTitleText = exports.OptionContainerTitleText = (0, _styledComponents.default)(TextBlock).withConfig({
  displayName: "styles__OptionContainerTitleText",
  componentId: "sc-1umm2vy-18"
})(["\n  font-family: ", ";\n  margin: 0;\n  font-size: 14px;\n  background-color: white;\n  text-align: center;\n  font-weight: 500;\n  padding: 0 20px;\n"], function (props) {
  return props.theme.font.medium;
});
var ResourceContainer = exports.ResourceContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__ResourceContainer",
  componentId: "sc-1umm2vy-19"
})(["\n  display: flex;\n  align-items: center;\n"]);
var IconContainer = exports.IconContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__IconContainer",
  componentId: "sc-1umm2vy-20"
})(["\n  margin-right: 13px;\n  ", " {\n    margin-right: 16px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.desktop;
});
var ResourceTitle = exports.ResourceTitle = _styledComponents.default.span.withConfig({
  displayName: "styles__ResourceTitle",
  componentId: "sc-1umm2vy-21"
})(["\n  font-size: 20px;\n  color: ", ";\n"], function (props) {
  return props.theme.color.darkBlue;
});
var ResourceDescription = exports.ResourceDescription = (0, _styledComponents.default)(TextBlock).withConfig({
  displayName: "styles__ResourceDescription",
  componentId: "sc-1umm2vy-22"
})(["\n  font-size: 12px;\n"]);
var Flex = exports.Flex = _styledComponents.default.div.withConfig({
  displayName: "styles__Flex",
  componentId: "sc-1umm2vy-23"
})(["\n  display: flex;\n  align-items: center;\n  justify-content: ", ";\n  ", "\n  ", "\n"], function (props) {
  return props.justify ? props.justify : 'center';
}, function (props) {
  return props.direction && (0, _styledComponents.css)(["\n      flex-direction: ", ";\n    "], props.direction);
}, function (props) {
  return props.gap && (0, _styledComponents.css)(["\n      gap: ", "px;\n    "], props.gap);
});
var HeaderWrapper = exports.HeaderWrapper = (0, _styledComponents.default)(Flex).withConfig({
  displayName: "styles__HeaderWrapper",
  componentId: "sc-1umm2vy-24"
})(["\n  flex-direction: column;\n  gap: 0;\n  margin-bottom: 20px;\n  position: sticky;\n  top: -10px;\n  background-color: white;\n  z-index: 90;\n"]);
var InnerWrapper = exports.InnerWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__InnerWrapper",
  componentId: "sc-1umm2vy-25"
})(["\n  max-height: 690px;\n  max-width: 770px;\n  width: 100%;\n  overflow-y: scroll;\n  padding: 0 30px;\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n  scrollbar-width: none;\n\n  ", " {\n    max-height: 90%;\n    height: 93vh;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});