import React from "react"

const { Provider, Consumer } = React.createContext()

const withStickyProvider = WrappedComponent => {
  class StickyProvider extends React.PureComponent {
    static async getInitialProps(ctx) {
      let props = {}
      if (typeof WrappedComponent.getInitialProps === "function") {
        props = await WrappedComponent.getInitialProps(ctx)
      }
      return {
        ...props,
      }
    }

    state = {
      isScrolled: false,
    }
    checkPosition = () => {
      if (!window) return false
      if (window.scrollY > 0) {
        this.setState({
          isScrolled: true,
        })
      } else if (window.scrollY === 0 && this.state.isScrolled) {
        this.setState({
          isScrolled: false,
        })
      }
    }
    componentDidMount = () => {
      this.checkPosition()
      window.addEventListener("scroll", this.checkPosition, false)
    }
    componentWillUnmount = () => {
      window.removeEventListener("scroll", this.checkPosition, false)
    }

    render() {
      return (
        <Provider
          value={{
            isScrolled: this.state.isScrolled,
          }}
        >
          <WrappedComponent {...this.props} />
        </Provider>
      )
    }
  }

  return StickyProvider
}

export default withStickyProvider
export const StickyConsumer = Consumer
