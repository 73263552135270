import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { FaqPremium, withWrapper } from '@alkemy/components'

export default compose(
  withPageBuilder({
    niceName: 'Faq Premium',
  }),
  withFields([
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'link',
      label: 'Link tutte le faq',
      type: 'WYSIWYG',
    },
    {
      prop: 'faqs',
      label: 'Faq',
      type: 'repeater',
      model: [
        {
          prop: 'open',
          label: 'Aperto',
          type: 'boolean',
        },
        {
          prop: 'title',
          label: 'Titolo',
        },
        {
          prop: 'text',
          label: 'Testo',
        },
      ],
    },
  ]),
  withWrapper,
)(FaqPremium)
