import {compose} from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'
import {
  withStartGradientColors,
  withStopGradientColors,
} from '../lib/withGradientColors'

import {MissionList, withWrapper} from '@alkemy/components'

export default compose(
  withPageBuilder(),
  withFields([
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'text',
      label: 'Testo',
    },
    {
      prop: 'list',
      type: 'repeater',
      label: 'List',
      model: [
        {
          prop: 'icon',
          type: 'multiple',
          label: 'Icona',
          options: [
            'Award:Premio',
            'Speaker:Speaker',
            'Magnifier:Lente',
            'BlueGreenLeaf:Foglia',
          ],
          initialValue: 'Clients',
        },
        {
          prop: 'title',
          label: 'Title',
        },
        {
          prop: 'text',
          label: 'Testo',
        },
        withStartGradientColors(),
        withStopGradientColors(),
      ],
    },
  ]),
  withWrapper,
)(MissionList)
