import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { DocumentsPremium, withWrapper } from '@alkemy/components'

export default compose(
  withPageBuilder({
    niceName: 'Documents Premium',
  }),
  withFields([
    {
      prop: 'tabId',
      label: 'Tab ID',
    },
    {
      prop: 'active',
      label: 'Active',
      type: 'boolean',
    },
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'documents',
      label: 'Document',
      type: 'repeater',
      model: [
        {
          prop: 'icon',
          label: 'Icon',
          type: 'multiple',
          options: ['pdf:Pdf', 'image:Image', 'zip:Zip'],
        },
        {
          prop: 'title',
          label: 'Titolo',
        },
        {
          prop: 'peso',
          label: 'Peso',
        },
        {
          prop: 'url',
          label: 'URL',
        },
      ],
    },
    {
      prop: 'disclaimers',
      label: 'Disclaimers',
      type: 'repeater',
      model: [
        {
          prop: 'text',
          label: 'Text',
        },
      ],
    },
  ]),
  withWrapper,
)(DocumentsPremium)
