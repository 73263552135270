import {compose} from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import {ChiSiamoIconList, withWrapper} from '@alkemy/components'

export default compose(
  withPageBuilder(),
  withFields([
    {
      prop: 'list',
      type: 'repeater',
      label: 'List',
      model: [
        {
          prop: 'icon',
          type: 'multiple',
          label: 'Icona',
          options: [
            'Clients:Clienti',
            'Graph:Fatturato',
            'Gas:Gas',
            'OrionPlug:Plug',
            'Iban:Iban',
            'Id:Id',
            'Bill:Bill',
            'OrionDownload:Download',
          ],
          initialValue: 'Clients',
        },
        {
          prop: 'smallText',
          label: 'Title',
        },
        {
          prop: 'bigText',
          label: 'Value',
        },
      ],
    },
  ]),
  withWrapper,
)(ChiSiamoIconList)
