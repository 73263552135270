"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledQueryCodeString = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledQueryCodeString = exports.StyledQueryCodeString = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledQueryCodeString",
  componentId: "sc-kky6z0-0"
})([""]);