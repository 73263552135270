import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { WorkWithUsTitle, withWrapper } from '@alkemy/components'

export default compose(
  withPageBuilder(),
  withFields([
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'place',
      label: 'Sede',
    },
    {
      prop: 'image',
      label: 'Logo',
      type: 'multiple',
      options: ['vivigas:Vivigas', 'viviesco:Viviesco'],
    },
  ]),
  withWrapper,
)(WorkWithUsTitle)
