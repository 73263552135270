import {compose} from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import {ChiSiamoTextImg, withWrapper, withPadder} from '@alkemy/components'

export default compose(
  withPageBuilder(),
  withFields([
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'text',
      type: 'WYSIWYG',
      label: 'Paragrafo',
    },
    {
      prop: 'img',
      label: 'Immagine',
    },
  ]),
  withPadder,
  withWrapper,
)(ChiSiamoTextImg)
