"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledSpan = exports.StyledInvertedText = exports.StyledCallMeBackTrigger = exports.StyledCallMeBackText = exports.StyledCallMeBackFormWrapper = exports.StyledCallMeBackFormResponse = exports.StyledCallMeBackForm = exports.StyledCallMeBackErrors = exports.StyledCallMeBackCloser = exports.StyledCallMeBack = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledCallMeBack = exports.StyledCallMeBack = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCallMeBack",
  componentId: "sc-i4sjms-0"
})(["\n  position: relative;\n  width: auto;\n  height: auto;\n  max-height: 40px;\n  bottom: 0;\n  right: 0;\n  z-index: 99;\n  font-family: ", ";\n  pointer-events: ", ";\n\n  ", "\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.isVisible ? 'all' : 'none';
}, function (props) {
  return props.isVisible && (0, _styledComponents.css)(["\n      width: 100%;\n      height: 100%;\n      bottom: 0;\n      right: 0;\n      z-index: 1000000;\n    "]);
});
var StyledInvertedText = exports.StyledInvertedText = _styledComponents.default.span.withConfig({
  displayName: "styles__StyledInvertedText",
  componentId: "sc-i4sjms-1"
})(["\n  background: linear-gradient(90deg, #f54052 0%, #f56d40 100%);\n  background-clip: text;\n  -webkit-background-clip: text;\n  color: transparent;\n  cursor: pointer;\n  width: fit-content;\n  justify-self: center;\n  font-family: ", ";\n  font-size: 15px;\n  font-weight: 600;\n  letter-spacing: 0.5;\n  cursor: pointer;\n  margin: 0;\n  max-width: 250px;\n  min-width: 210px;\n"], function (props) {
  return props.theme.font.regular;
});
var StyledCallMeBackTrigger = exports.StyledCallMeBackTrigger = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCallMeBackTrigger",
  componentId: "sc-i4sjms-2"
})(["\n  cursor: pointer;\n  text-align: center;\n  position: relative;\n  bottom: 0;\n  z-index: 0;\n  right: 0;\n  opacity: 1;\n  font-size: 13px;\n  font-weight: 400;\n  font-family: ", ";\n  pointer-events: ", ";\n  z-index: 3;\n  transition: opacity 0.25s linear 0s;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.isVisible ? 'none' : 'all';
});
var StyledCallMeBackFormWrapper = exports.StyledCallMeBackFormWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCallMeBackFormWrapper",
  componentId: "sc-i4sjms-3"
})(["\n  border-radius: 10px;\n  width: 100%;\n  height: auto;\n  display: block;\n  opacity: ", ";\n  position: fixed;\n  right: 25%;\n  bottom: ", ";\n  height: 500px;\n  width: 340px;\n  margin-bottom: 50px;\n  margin-right: 20px;\n  transition: 0.4s;\n  background-color: transparent;\n  z-index: 9999;\n  @media (max-width: 767px) {\n    height: 90%;\n    width: 90%;\n    right: 0;\n    bottom: 0;\n  }\n"], function (props) {
  return props.isVisible ? 1 : 0;
}, function (props) {
  return props.isConfigurator ? '35px' : '115px';
});
var StyledCallMeBackForm = exports.StyledCallMeBackForm = _styledComponents.default.form.withConfig({
  displayName: "styles__StyledCallMeBackForm",
  componentId: "sc-i4sjms-4"
})(["\n  position: relative;\n  top: 0;\n  left: 0;\n  transition: transform 0.25s linear 0s;\n  background: ", ";\n  padding: 40px 30px 20px;\n  height: 100%;\n  width: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  border-radius: 11px;\n\n  ", " {\n    height: auto;\n  }\n\n  input {\n    width: 100%;\n    padding: 16px;\n    padding-left: 0;\n    font-size: 16px;\n    border-radius: 0;\n    margin-bottom: 20px;\n    color: white;\n    background-color: transparent;\n    border: none;\n    border-bottom: 1px solid white;\n    outline: none;\n\n    &::placeholder {\n      color: white;\n      opacity: 1;\n    }\n  }\n\n  button {\n    box-shadow: none;\n    border: 1px solid white;\n    line-height: 40px;\n    margin-bottom: 24px;\n  }\n\n  p,\n  div#CallMeBack {\n    color: white;\n  }\n"], function (props) {
  return props.theme.color.greenBlue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledCallMeBackCloser = exports.StyledCallMeBackCloser = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCallMeBackCloser",
  componentId: "sc-i4sjms-5"
})(["\n  position: absolute;\n  width: 20px;\n  height: 20px;\n  top: 10px;\n  right: 10px;\n  opacity: ", ";\n  pointer-events: ", ";\n  cursor: pointer;\n"], function (props) {
  return props.isVisible ? 1 : 0;
}, function (props) {
  return props.isVisible ? 'all' : 'none';
});
var StyledCallMeBackText = exports.StyledCallMeBackText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCallMeBackText",
  componentId: "sc-i4sjms-6"
})(["\n  color: white;\n  width: 100%;\n  font-size: 14px;\n  font-family: ", ";\n"], function (props) {
  return props.theme.font.regular;
});
var StyledCallMeBackFormResponse = exports.StyledCallMeBackFormResponse = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCallMeBackFormResponse",
  componentId: "sc-i4sjms-7"
})(["\n  display: inline-block;\n  background: ", ";\n  color: white;\n  padding: 60px 20px;\n  width: 100%;\n  height: 100%;\n  border-radius: 20px;\n\n  ", " {\n    width: 100%;\n    height: auto;\n    display: block;\n    position: absolute;\n    bottom: 0;\n    margin-bottom: 22px;\n  }\n"], function (props) {
  return props.theme.color.greenBlue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledCallMeBackErrors = exports.StyledCallMeBackErrors = _styledComponents.default.span.withConfig({
  displayName: "styles__StyledCallMeBackErrors",
  componentId: "sc-i4sjms-8"
})(["\n  display: inline-block;\n  color: orange;\n  font-size: 14px !important;\n  margin-bottom: 14px;\n"]);
var StyledSpan = exports.StyledSpan = _styledComponents.default.button.withConfig({
  displayName: "styles__StyledSpan",
  componentId: "sc-i4sjms-9"
})(["\n  display: block;\n  margin: auto;\n  text-align: center;\n  line-height: 40px;\n  padding: 0px 35px;\n  box-shadow: rgb(95 11 43 / 30%) 0px 0px 20px 2px;\n  transition: all 0.2s ease-in 0s;\n  background: linear-gradient(255deg, #f09724, #e81c69);\n  color: #fff;\n  cursor: pointer;\n  border-radius: 30px;\n  border: none;\n  &&:hover {\n    box-shadow: rgb(95 11 43 / 30%) 0px 0px 20px 8px !important;\n    transition: all 0.2s ease-in 0s;\n    background: linear-gradient(255deg, #f49593, #f7bf28);\n  }\n"]);