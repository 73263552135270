"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RightCol = exports.LeftCol = exports.Banner = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var Banner = exports.Banner = _styledComponents.default.div.withConfig({
  displayName: "styles__Banner",
  componentId: "sc-1x2v53p-0"
})(["\n  display: block;\n  position: relative;\n  overflow: auto;\n  width: 95%;\n  margin: auto;\n  font-family: ", ";\n  font-weight: 400;\n  border-radius: 22px;\n  box-shadow: 0px 0px 18px 1px #586b7847;\n  h4 {\n    font-family: ", ";\n    font-weight: 400;\n    font-size: 16px;\n    margin-bottom: 0;\n  }\n\n  color: ", ";\n  ", " {\n    background-image: url(", ");\n    background-position: center;\n    background-size: cover;\n  }\n  p {\n    line-height: 23px;\n    font-size: 14px;\n  }\n  padding: 25px 35px;\n  margin: auto;\n  display: flex;\n  align-items: center;\n  margin-bottom: 30px;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.font.bold;
}, function (props) {
  return props.darkColor ? "#3F5063" : "#fff";
}, function (props) {
  return props.bgImg;
}, function (props) {
  return props.bgImg + '?width=1440&auto=format';
});
var LeftCol = exports.LeftCol = _styledComponents.default.div.withConfig({
  displayName: "styles__LeftCol",
  componentId: "sc-1x2v53p-1"
})(["\n  width: 22%;\n  display: block;\n  position: relative;\n  float: left;\n  img {\n    width: 100%;\n  }\n"]);
var RightCol = exports.RightCol = _styledComponents.default.div.withConfig({
  displayName: "styles__RightCol",
  componentId: "sc-1x2v53p-2"
})(["\n  width: 73%;\n  display: block;\n  position: relative;\n  float: right;\n  padding-left: 30px;\n"]);