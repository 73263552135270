import React, { Fragment } from 'react'
import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { withWrapper, withPadder, PageBlockWithBlob } from '@alkemy/components'

const PageBlockWithBlobAndHR = ({ hasHorizontalRules, ...props }) => (
  <Fragment>
    {hasHorizontalRules && <hr />}
    <PageBlockWithBlob {...props} />
    {hasHorizontalRules && <hr />}
  </Fragment>
)

export default compose(
  withPageBuilder({
    niceName: 'Blocco Pagina con Blob',
  }),
  withFields([
    {
      prop: 'wrapped',
      type: 'boolean',
      label: 'Contenuto Boxed',
    },
    {
      prop: 'paddingLeftRight',
      label: 'Larghezza Componente',
      type: 'multiple',
      options: ['200:Small', '170:Medium', '100:Large', '0:Nessuno'],
    },
    {
      prop: 'paddingTop',
      label: 'Distanza dal Top',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'paddingBottom',
      label: 'Distanza dal Bottom',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'text',
      label: 'Descrizione',
      type: 'WYSIWYG',
    },
    {
      prop: 'blobText',
      label: 'Testo Blob',
    },
    {
      prop: 'hasHorizontalRules',
      label: 'Mostra linee divisorie',
      type: 'boolean',
    },
  ]),
  withWrapper,
  withPadder,
)(PageBlockWithBlobAndHR)
