import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { withWrapper, withPadder, CheckboxList } from '@alkemy/components'

const normalizeList = list => {
  let arr = []

  list.map(item => {
    arr.push({
      text: item.text,
    })
  })

  return arr
}

const BuildedCheckboxList = props => {
  const {} = props

  return <CheckboxList {...props} items={normalizeList(props.items)} />
}

export default compose(
  withPageBuilder({
    niceName: 'Lista Checkbox',
  }),
  withFields([
    {
      prop: 'wrapped',
      type: 'boolean',
      label: 'Contenuto Boxed',
    },
    {
      prop: 'paddingLeftRight',
      label: 'Larghezza Componente',
      type: 'multiple',
      options: ['200:Small', '170:Medium', '100:Large', '0:Nessuno'],
    },
    {
      prop: 'paddingTop',
      label: 'Distanza dal Top',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'paddingBottom',
      label: 'Distanza dal Bottom',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'titleSize',
      label: 'Dimensione Titolo',
    },
    {
      prop: 'img',
      label: 'Immagine',
    },
    {
      prop: 'items',
      type: 'repeater',
      label: 'Lista',
      model: [
        {
          prop: 'text',
          label: 'Testo',
        },
      ],
    },
  ]),
  withWrapper,
  withPadder,
)(BuildedCheckboxList)
