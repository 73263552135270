"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTriangleWrapper = exports.StyledTitleWrapper = exports.StyledTextWrapper = exports.StyledLaunchBlock = exports.StyledImageWrapper = exports.StyledContentWrapper = exports.StyledButtonWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledImageWrapper = exports.StyledImageWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledImageWrapper",
  componentId: "sc-9e2sff-0"
})(["\n  width: 100%;\n  height: ", ";\n\n  background-color: ", ";\n  position: relative;\n  overflow: hidden;\n\n  @media (min-width: 768px) {\n    border-top-left-radius: 35px;\n    border-top-right-radius: 35px;\n  }\n\n ", "{\n    height: ", ";\n\n    ", "\n }\n\n ", "\n\n\n  ", "\n\n  ", "\n"], function (props) {
  return props.fluid ? 'auto' : '235px';
}, function (props) {
  return props.theme.color.gray;
}, function (props) {
  return props.theme.mediaQueries.desktop;
}, function (props) {
  return props.fluid ? 'auto' : '320px';
}, function (props) {
  return props.imageHeight && (0, _styledComponents.css)(["\n        height: ", ";\n      "], function (props) {
    return props.imageHeight;
  });
}, function (props) {
  return props.imageHeight && (0, _styledComponents.css)(["\n     height: ", ";\n   "], function (props) {
    return props.imageHeight;
  });
}, function (props) {
  return props.triangleOrientation === 'vertical' && (0, _styledComponents.css)(["\n      @media (min-width: 768px) {\n        width: 48.64%;\n        height: 100%;\n        float: left;\n        border-top-left-radius: 35px;\n        border-top-right-radius: 0;\n        border-bottom-right-radius: 0;\n        border-bottom-left-radius: 35px;\n      }\n    "]);
}, function (props) {
  return props.full && props.triangleOrientation === 'horizontal' && (0, _styledComponents.css)(["\n      @media (min-width: 768px) {\n      }\n      @media (min-width: 1440px) {\n        height: ", ";\n      }\n    "], function (props) {
    return props.fluid ? 'auto' : '490px';
  });
});
var StyledTriangleWrapper = exports.StyledTriangleWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTriangleWrapper",
  componentId: "sc-9e2sff-1"
})(["\n  position: absolute;\n  bottom: 0;\n  left: 0;\n\n  ", "\n"], function (props) {
  return props.triangleOrientation === 'vertical' && (0, _styledComponents.css)(["\n      @media (min-width: 768px) {\n        left: unset;\n        right: 100px;\n      }\n\n      @media (min-width: 1440px) {\n        right: 125px;\n      }\n    "]);
});
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-9e2sff-2"
})(["\n  width: ", ";\n  position: absolute;\n  left: 50%;\n  transform: translateX(-50%);\n  bottom: -44px;\n\n  @media (min-width: 768px) {\n    bottom: -38px;\n  }\n\n  @media (min-width: 1440px) {\n    bottom: -46px;\n  }\n\n  ", "\n\n  button {\n    font-family: ", ";\n    font-size: 16px;\n    font-weight: normal;\n    font-style: normal;\n    font-stretch: normal;\n    line-height: 42px;\n    letter-spacing: normal;\n    text-align: center;\n\n    @media (min-width: 1440px) {\n      font-size: 20px;\n      line-height: 60px;\n    }\n  }\n"], function (props) {
  return props.fluid ? 'calc(100% - 70px)' : '240px';
}, function (props) {
  return props.ctaWidth && (0, _styledComponents.css)(["\n      width: ", ";\n    "], function (props) {
    return props.ctaWidth;
  });
}, function (props) {
  return props.theme.font.regular;
});
var StyledTextWrapper = exports.StyledTextWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTextWrapper",
  componentId: "sc-9e2sff-3"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 16px;\n  line-height: 1.4;\n  text-align: ", ";\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.full ? 'left' : 'center';
});
var StyledTitleWrapper = exports.StyledTitleWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitleWrapper",
  componentId: "sc-9e2sff-4"
})(["\n  margin-bottom: 20px;\n  text-align: center;\n  font-family: ", ";\n  color: ", ";\n  font-size: ", ";\n\n  ", "\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.titleSize || '22px';
}, function (props) {
  return props.titleLineHeight && (0, _styledComponents.css)(["\n      line-height: ", ";\n    "], function (props) {
    return props.titleLineHeight;
  });
});
var StyledContentWrapper = exports.StyledContentWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContentWrapper",
  componentId: "sc-9e2sff-5"
})(["\n  width: 100%;\n  height: auto;\n  min-height: 213px;\n  padding: 20px 32px;\n  position: relative;\n  -webkit-font-smoothing: antialiased;\n  background-color: ", ";\n\n  ", "\n\n  @media (min-width: 768px) {\n    border-bottom-left-radius: 35px;\n    border-bottom-right-radius: 35px;\n  }\n  @media (min-width: 992px) {\n  }\n  @media (max-width: 991px) {\n    min-height: 308px;\n  }\n  ", "\n\n  ", "\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.fluid && (0, _styledComponents.css)(["\n      flex-grow: 1;\n      height: auto;\n      padding-bottom: 40px;\n    "]);
}, function (props) {
  return props.triangleOrientation === 'vertical' && (0, _styledComponents.css)(["\n      border-radius: 0;\n\n      @media (min-width: 768px) {\n        width: 51.36%;\n        min-height: 308px;\n        height: 100%;\n        padding: 50px 40px 0 25px;\n        float: left;\n        border-top-right-radius: 35px;\n        border-bottom-right-radius: 35px;\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n      }\n\n      @media (min-width: 1440px) {\n        padding: 90px 70px 0 20px;\n      }\n\n      ", " {\n        @media (min-width: 768px) {\n          bottom: -21px;\n        }\n      }\n\n      ", " {\n        @media (min-width: 768px) {\n          margin-bottom: 0;\n        }\n      }\n    "], StyledButtonWrapper, StyledTextWrapper);
}, function (props) {
  return props.full && (0, _styledComponents.css)(["\n      width: 280px;\n      height: auto;\n      margin: -50px auto 0;\n      padding: 30px 20px 90px;\n      box-shadow: 0 0 35px 0 ", ";\n      z-index: 10;\n      border-radius: 20px;\n\n      @media (min-width: 768px) {\n        margin: 0;\n        border-top-right-radius: 35px;\n        border-bottom-right-radius: 35px;\n        border-bottom-left-radius: 0;\n        border-top-left-radius: 0;\n        box-shadow: none;\n      }\n\n      ", " {\n        bottom: 30px;\n\n        @media (min-width: 768px) {\n          bottom: -21px;\n        }\n      }\n\n      ", "\n    "], function (props) {
    return props.theme.color.shadowBlue;
  }, StyledButtonWrapper, function (props) {
    return props.triangleOrientation === 'horizontal' && (0, _styledComponents.css)(["\n          @media (min-width: 768px) {\n            width: 60.81%;\n            padding: 0 45px;\n            position: initial;\n\n            ", " {\n              text-align: left;\n            }\n\n            ", " {\n              left: initial;\n              transform: none;\n              bottom: 50px;\n              right: 50px;\n            }\n          }\n\n          @media (min-width: 1440px) {\n            width: 75%;\n            padding: 0 80px;\n\n            ", " {\n              text-align: left;\n            }\n\n            ", " {\n              bottom: 86px;\n              right: 70px;\n            }\n          }\n        "], StyledTitleWrapper, StyledButtonWrapper, StyledTitleWrapper, StyledButtonWrapper);
  });
});
var StyledLaunchBlock = exports.StyledLaunchBlock = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledLaunchBlock",
  componentId: "sc-9e2sff-6"
})(["\n  width: 100%;\n  margin: 50px 0 80px;\n  box-shadow: 0 0 35px 0 ", ";\n  padding-bottom: 25px;\n  position: relative;\n\n  @media (min-width: 768px) {\n    width: calc(", " - 14px);\n    height: ", ";\n    float: left;\n    border-radius: 35px;\n\n    &:not(:last-child) {\n      margin-right: 28px;\n    }\n  }\n\n  @media (min-width: 1440px) {\n    width: calc(", " - 15px);\n    height: ", ";\n    margin: ", "px 0 64px;\n\n    &:not(:last-child) {\n      margin-right: 30px;\n    }\n  }\n\n  ", "\n\n  ", "\n"], function (props) {
  return props.theme.color.shadowBlue;
}, function (props) {
  return props.fluid ? '100%' : '50%';
}, function (props) {
  return props.fluid ? 'auto' : '560px';
}, function (props) {
  return props.fluid ? '100%' : '50%';
}, function (props) {
  return props.fluid ? 'auto' : '550px';
}, function (props) {
  return props.hasMarginTop ? 98 : 32;
}, function (props) {
  return props.fluid && (0, _styledComponents.css)(["\n      display: flex;\n      flex-direction: column;\n    "]);
}, function (props) {
  return props.full && (0, _styledComponents.css)(["\n    padding-bottom: 0;\n    box-shadow: none;\n\n    @media (min-width: 768px) {\n      width: 100%;\n      height: ", "\n      float: none;\n      box-shadow: 0 0 35px 0 ", ";\n    }\n\n    @media (min-width: 1440px) {\n      height: ", ";\n      float: none;\n    }\n\n    ", " {\n      box-shadow: 0 0 35px 0 ", ";\n\n      @media (min-width: 768px) {\n        box-shadow: none;\n      }\n    }\n\n    ", " {\n      display: none;\n\n      @media (min-width: 768px) {\n        display: block;\n\n        ", "\n      }\n\n      @media (min-width: 1440px) {\n        ", "\n      }\n\n\n    }\n\n   "], function (props) {
    return props.fluid ? 'auto' : '400px';
  }, function (props) {
    return props.theme.color.shadowBlue;
  }, function (props) {
    return props.triangleOrientation === 'horizontal' ? '700px' : '460px';
  }, StyledImageWrapper, function (props) {
    return props.theme.color.shadowBlue;
  }, StyledTriangleWrapper, function (props) {
    return props.triangleOrientation === 'horizontal' && (0, _styledComponents.css)(["\n            > div {\n              border-right: 740px solid transparent;\n              border-bottom: 65px solid ", ";\n            }\n          "], function (props) {
      return props.theme.color.white;
    });
  }, function (props) {
    return props.triangleOrientation === 'horizontal' && (0, _styledComponents.css)(["\n            > div {\n              border-right: 1170px solid transparent;\n              border-left: 0;\n              border-bottom: 100px solid ", ";\n            }\n          "], function (props) {
      return props.theme.color.white;
    });
  });
});