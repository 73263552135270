"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitleWrapper = exports.StyledSmallText = exports.StyledItemsWrapper = exports.StyledItem = exports.StyledInfographicList = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledInfographicList = exports.StyledInfographicList = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledInfographicList",
  componentId: "sc-1q8c98c-0"
})(["\n  max-width: 240px;\n  margin: 0 auto 88px;\n  text-align: center;\n\n  @media (min-width: 768px) {\n    max-width: 570px;\n    margin: 0 auto 150px;\n  }\n\n  @media (min-width: 1440px) {\n    max-width: 1170px;\n  }\n"]);
var StyledTitleWrapper = exports.StyledTitleWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitleWrapper",
  componentId: "sc-1q8c98c-1"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 22px;\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledItemsWrapper = exports.StyledItemsWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledItemsWrapper",
  componentId: "sc-1q8c98c-2"
})(["\n  padding: 45px 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  @media (min-width: 768px) {\n    padding-bottom: 0;\n    flex-direction: row;\n    justify-content: space-between;\n    flex-wrap: wrap;\n  }\n\n  @media (min-width: 1440px) {\n    flex-direction: row;\n    justify-content: space-evenly;\n  }\n"]);
var StyledItem = exports.StyledItem = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledItem",
  componentId: "sc-1q8c98c-3"
})(["\n  &:not(:last-child) {\n    margin-bottom: 150px;\n  }\n\n  @media (min-width: 768px) {\n    flex-basis: 150px;\n    margin-bottom: 150px;\n  }\n"]);
var StyledSmallText = exports.StyledSmallText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSmallText",
  componentId: "sc-1q8c98c-4"
})(["\n  font-family: ", ";\n  color: ", ";\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});