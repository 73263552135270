"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Price = exports.CostoWrapper = exports.CostoTitle = exports.CostoItem = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var CostoWrapper = exports.CostoWrapper = _styledComponents.default.div.withConfig({
  displayName: "style__CostoWrapper",
  componentId: "sc-fkbzgy-0"
})(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  ", "\n\n  ", "\n\n  ", " {\n    &:not(:first-child) {\n      margin-top: 20px;\n    }\n  }\n"], function (props) {
  return !props.setMarginTop && "margin: 20px 0 30px 0;";
}, function (props) {
  return props.setMarginTop && "\n    &:not(:first-child) {\n      margin-top: 35px;\n    }\n  ";
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var Price = exports.Price = _styledComponents.default.span.withConfig({
  displayName: "style__Price",
  componentId: "sc-fkbzgy-1"
})(["\n  font-weight: bold;\n  flex: 0 0 auto;\n"]);
var CostoItem = exports.CostoItem = _styledComponents.default.div.withConfig({
  displayName: "style__CostoItem",
  componentId: "sc-fkbzgy-2"
})(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  margin: 10px 0;\n  color: ", ";\n\n  * {\n    font-size: 16px;\n\n    ", " {\n      font-size: 14px;\n    }\n  }\n\n  ", "\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  return props.bigText && (0, _styledComponents.css)(["\n      > span {\n        font-size: 16px;\n      }\n\n      ", " {\n        font-size: 20px;\n      }\n\n      ", " {\n        font-size: 14px;\n\n        ", " {\n          font-size: 14px;\n        }\n      }\n    "], Price, function (props) {
    return props.theme.mediaQueries.phone;
  }, Price);
});
var CostoTitle = exports.CostoTitle = _styledComponents.default.span.withConfig({
  displayName: "style__CostoTitle",
  componentId: "sc-fkbzgy-3"
})(["\n  font-family: ", ";\n  font-size: 18px;\n  font-weight: bold;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.5;\n  letter-spacing: normal;\n  color: ", ";\n  padding: 5px 0;\n  border-bottom: 1px dashed ", ";\n\n  ", " {\n    font-size: 16px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.titleBorderColor;
}, function (props) {
  return props.theme.mediaQueries.phone;
});