"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledWorkWithUsTitle = exports.StyledTitle = exports.StyledPlace = exports.StyledImageWrapper = exports.StyledImage = exports.StyledDetailsWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledWorkWithUsTitle = exports.StyledWorkWithUsTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledWorkWithUsTitle",
  componentId: "sc-11smf7e-0"
})(["\n  max-width: 335px;\n  margin: 150px auto 0;\n  padding-bottom: 60px;\n  border-bottom: 1px solid ", ";\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n\n  @media (min-width: 1024px) {\n    max-width: 770px;\n  }\n"], function (props) {
  return props.theme.color.gray;
});
var StyledImageWrapper = exports.StyledImageWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledImageWrapper",
  componentId: "sc-11smf7e-1"
})(["\n  flex-basis: 50%;\n  padding-right: 20px;\n  position: relative;\n  border-right: 1px solid ", ";\n\n  @media (min-width: 1024px) {\n    flex-basis: 318px;\n    text-align: right;\n  }\n"], function (props) {
  return props.theme.color.gray;
});
var StyledImage = exports.StyledImage = _styledComponents.default.img.withConfig({
  displayName: "styles__StyledImage",
  componentId: "sc-11smf7e-2"
})(["\n  width: 135px;\n  height: 60px;\n"]);
var StyledDetailsWrapper = exports.StyledDetailsWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDetailsWrapper",
  componentId: "sc-11smf7e-3"
})(["\n  flex-basis: 50%;\n  padding-left: 20px;\n"]);
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-11smf7e-4"
})(["\n  margin-bottom: 5px;\n  color: ", ";\n  font-family: ", ";\n  font-size: 18px;\n  line-height: 22px;\n\n  @media (min-width: 1024px) {\n    font-size: 22px;\n    line-height: 32px;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.medium;
});
var StyledPlace = exports.StyledPlace = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledPlace",
  componentId: "sc-11smf7e-5"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n  line-height: 22px;\n\n  @media (min-width: 1024px) {\n    font-size: 18px;\n    line-height: 32px;\n  }\n"], function (props) {
  return props.theme.color.gray;
}, function (props) {
  return props.theme.font.regular;
});