"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LegalNoteDiv = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var LegalNoteDiv = exports.LegalNoteDiv = _styledComponents.default.div.withConfig({
  displayName: "styles__LegalNoteDiv",
  componentId: "sc-dj6aiq-0"
})(["\n  padding: 0 50px;\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.43;\n  letter-spacing: normal;\n  color: #3f5063;\n  a {\n    color: #e81c69;\n    text-decoration: none;\n  }\n"], function (props) {
  return props.theme.font.regular;
});