"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IsStoreSelected = exports.GetMobileStores = exports.GetDistance = exports.BuildSuggestions = void 0;
var BuildSuggestions = exports.BuildSuggestions = function BuildSuggestions(stores) {
  var arr = [];
  stores.map(function (store) {
    arr.push(GenerateLocation(store));
  });
  return arr;
};
var GenerateLocation = function GenerateLocation(_ref) {
  var city = _ref.city,
    cap = _ref.cap;
  return {
    city: city,
    cap: cap
  };
};
var GetDistance = exports.GetDistance = function GetDistance(from, to) {
  Number.prototype.toRad = function () {
    return this * Math.PI / 180;
  };
  var R = 6371; // km

  var x1 = to.lat - from.lat;
  var x2 = to.lng - from.lng;
  var dLat = x1.toRad();
  var dLon = x2.toRad();
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(to.lat.toRad()) * Math.cos(from.lat.toRad()) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
};
var GetMobileStores = exports.GetMobileStores = function GetMobileStores(selected, stores) {
  var results = [];
  stores.map(function (store) {
    if (selected.lat !== store.lat && selected.lng !== store.lng) {
      var distance = Math.round(GetDistance(selected, store) * 10) / 10;
      if (distance < 50) {
        store.distance = distance;
        results.push(store);
      }
    }
  });
  results.sort(function (a, b) {
    return a.distance - b.distance;
  });
  return results;
};
var IsStoreSelected = exports.IsStoreSelected = function IsStoreSelected(center, markers) {};