"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledText = exports.StyledCtaWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledText = exports.StyledText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledText",
  componentId: "sc-1wceuyu-0"
})(["\n  padding: 0;\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  font-weight: normal;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: 1.5;\n  letter-spacing: normal;\n  text-align: center;\n\n  * {\n    margin: 0;\n  }\n\n  @media (min-width: 768px) {\n    margin-bottom: 0px;\n  }\n\n  ", "\n    \n  b {\n    font-family: ", "!important;\n    margin-bottom: 10px !important;\n    display: block;\n    font-weight: 400;\n  }\n\n  strong {\n    font-family: ", ";\n    font-weight: 400;\n  }\n\n  .highlighted {\n    width: 100%;\n    display: block;\n    color: ", ";\n    font-size: 18px;\n    margin: 60px 0;\n    text-align: center;\n\n    @media (min-width: 1440px) {\n      margin: 60px 0;\n      font-size: 20px;\n    }\n  }\n\n  ", "\n\n  ", "\n    \n  ", "  \n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.center && (0, _styledComponents.css)(["\n      text-align: center;\n    "]);
}, function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.orange;
}, function (props) {
  return props.refund && (0, _styledComponents.css)(["\n      margin: 0 auto;\n      font-family: ", ";\n      font-size: 16px;\n      text-align: left;\n      line-height: 1.2;\n\n      @media (min-width: 768px) {\n        max-width: 540px;\n        padding: 0;\n        font-size: 18px;\n        line-height: 1.2;\n      }\n\n      @media (min-width: 1440px) {\n        max-width: 770px;\n        font-size: 18px;\n        line-height: 1.2;\n      }\n    "], function (props) {
    return props.theme.font.regular;
  });
}, function (props) {
  return props.isProject && (0, _styledComponents.css)(["\n      margin: 0 auto;\n      padding: 0;\n\n      @media (min-width: 768px) {\n        max-width: 540px;\n      }\n\n      @media (min-width: 1440px) {\n        max-width: 770px;\n      }\n    "]);
}, function (props) {
  return props.redLinks && (0, _styledComponents.css)(["\n      a {\n        color: ", "!important;\n        text-decoration: none;\n      }\n    "], function (props) {
    return props.theme.color.red;
  });
});
var StyledCtaWrapper = exports.StyledCtaWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCtaWrapper",
  componentId: "sc-1wceuyu-1"
})(["\n  width: 240px;\n  margin: 30px auto 95px;\n\n  @media (min-width: 768px) {\n    width: 300px;\n    margin: 56px auto 96px;\n  }\n\n  @media (min-width: 1440px) {\n    width: 300px;\n    margin: 69px auto 132px;\n  }\n"]);