"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledSocial = exports.StyledShareButton = exports.StyledPrintButton = exports.StyledIconWrapper = exports.SocialWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledSocial = exports.StyledSocial = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSocial",
  componentId: "sc-13vkq1z-0"
})(["\n  width: 35px;\n  height: ", ";\n  background-color: ", ";\n  border-radius: 5px;\n\n  @media (min-width: 768px) {\n    background-color: transparent;\n  }\n"], function (props) {
  return props.isVisible ? 'auto' : '35px';
}, function (props) {
  return props.theme.color.white;
});
var StyledPrintButton = exports.StyledPrintButton = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledPrintButton",
  componentId: "sc-13vkq1z-1"
})([""]);
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-13vkq1z-2"
})(["\n  width: 35px;\n  height: 35px;\n  margin-bottom: 15px;\n  cursor: pointer;\n\n  &:hover {\n    @media (min-width: 1280px) {\n      path {\n        fill: #3f5063;\n      }\n    }\n  }\n"]);
var SocialWrapper = exports.SocialWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__SocialWrapper",
  componentId: "sc-13vkq1z-3"
})(["\n  transition: all 0.2s ease-in-out 0s;\n  opacity: ", ";\n  visibility: ", ";\n"], function (props) {
  return props.isVisible ? '1' : '0';
}, function (props) {
  return props.isVisible ? 'visible' : 'hidden';
});
var StyledShareButton = exports.StyledShareButton = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledShareButton",
  componentId: "sc-13vkq1z-4"
})(["\n  @media (min-width: 768px) {\n    display: none;\n  }\n"]);