import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { ViviescoForm, withWrapper } from '@alkemy/components'

export default compose(
  withPageBuilder({
    niceName: 'Viviesco - Form',
  }),
  withFields([
    {
      prop: 'legalNotes',
      label: 'note legali',
      type: 'WYSIWYG',
    },
    {
      prop: 'formTitle',
      label: 'titolo form',
    },
    {
      prop: 'formText',
      label: 'testo form',
    },
    {
      prop: 'formEndpoint',
      label: 'endpoint form',
    },
    {
      prop: 'formLegalNotes',
      label: 'note legali form',
      type: 'WYSIWYG',
    },
    {
      prop: 'subject',
      label: 'Subject',
    },
    {
      prop: 'thankMessage',
      type: 'WYSIWYG',
      label: 'Thank You Message',
    },
  ]),
  withWrapper,
)(ViviescoForm)
