"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledVivipediaPageToggler = exports.StyledListItem = exports.StyledImage = exports.StyledCtaWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledListItem = exports.StyledListItem = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledListItem",
  componentId: "sc-1y927fl-0"
})(["\n  position: relative;\n  margin: 40px 0 80px;\n\n  &:focus {\n    outline: none;\n  }\n\n  @media (min-width: 1440px) {\n    margin: 40px 0 90px;\n  }\n"]);
var StyledImage = exports.StyledImage = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledImage",
  componentId: "sc-1y927fl-1"
})(["\n  width: 93px;\n  height: 93px;\n  margin: 0 auto;\n  background-image: url(", ");\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n  transform: scale(0.8);\n  opacity: 0.5;\n  transition: all 0.2s ease-in 0s;\n  cursor: pointer;\n\n  @media (min-width: 768px) {\n    width: 133px;\n    height: 133px;\n  }\n\n  @media (min-width: 1440px) {\n    width: 190px;\n    height: 190px;\n    transform: scale(0.6);\n  }\n"], function (props) {
  return props.img + '?width=190&auto=format';
});
var StyledCtaWrapper = exports.StyledCtaWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCtaWrapper",
  componentId: "sc-1y927fl-2"
})(["\n  width: 180px;\n  position: absolute;\n  left: 50%;\n  transform: translateX(-50%);\n  bottom: -80px;\n  opacity: 0;\n  visibility: hidden;\n  transition: all 0.15s ease-in 0s;\n  pointer-events: all;\n\n  a {\n    padding: 0;\n    line-height: 42px;\n    @media (min-width: 1440px) {\n      line-height: 42px;\n    }\n  }\n  p {\n    text-align: center;\n    font-family: ", ";\n    color: ", ";\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.orange;
});
var StyledVivipediaPageToggler = exports.StyledVivipediaPageToggler = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledVivipediaPageToggler",
  componentId: "sc-1y927fl-3"
})(["\n  padding: 0 0 50px;\n\n  .slick-active {\n    > div {\n      &:focus {\n        outline: none;\n      }\n      > div {\n        div {\n          &:first-child {\n            transform: scale(1);\n            opacity: 1;\n          }\n        }\n      }\n    }\n  }\n\n  .slick-track {\n    &:focus {\n      outline: none;\n    }\n  }\n\n  .button-visible {\n    ", " {\n      opacity: 1;\n      visibility: visible;\n    }\n  }\n"], StyledCtaWrapper);