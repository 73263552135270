import ListOfRegulations from "../components/ListOfRegulations"
import { compose } from "recompose"

import withPageBuilder from "@alkemy/pagebuilder/lib/hoc/withPageBuilder"
import withFields from "@alkemy/pagebuilder/lib/hoc/withFields"
import { withWrapper } from "@alkemy/components"

export default compose(
  withPageBuilder({
    niceName: "Normative",
  }),
  withFields([
    {
      prop: "wrapped",
      type: "boolean",
      label: "Contenuto Boxed",
    },
  ]),
  withWrapper
)(ListOfRegulations)
