"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledFilterWrapper = exports.StyledArchiveFilterGrid = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledArchiveFilterGrid = exports.StyledArchiveFilterGrid = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledArchiveFilterGrid",
  componentId: "sc-1nkrmj4-0"
})(["\n  width: 100%;\n  margin: 80px auto 50px;\n\n  @media (min-width: 768px) {\n    max-width: 540px;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: space-between;\n  }\n\n  @media (min-width: 1440px) {\n    max-width: 680px;\n  }\n"]);
var StyledFilterWrapper = exports.StyledFilterWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledFilterWrapper",
  componentId: "sc-1nkrmj4-1"
})(["\n  width: 200px;\n  margin: 35px auto;\n  padding: 0 10px;\n\n  @media (min-width: 768px) {\n    width: 160px;\n    margin: 0 0 35px 0;\n    padding: 0;\n  }\n\n  @media (min-width: 1440px) {\n    width: 200px;\n  }\n\n  button {\n    padding: 0;\n    line-height: 42px;\n  }\n"]);