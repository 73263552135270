"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledUnsupportedBrowser = exports.StyledTextWrapper = exports.StyledContentWrapper = exports.StyledButtonWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledUnsupportedBrowser = exports.StyledUnsupportedBrowser = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledUnsupportedBrowser",
  componentId: "sc-14gew40-0"
})(["\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  top: 0;\n  left: 0;\n  background-color: rgba(255, 255, 255, 0.7);\n  z-index: 9999;\n"]);
var StyledContentWrapper = exports.StyledContentWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContentWrapper",
  componentId: "sc-14gew40-1"
})(["\n  width: 80%;\n  max-width: 1024px;\n  padding: 20px;\n  position: absolute;\n  transform: translate(-50%, -50%);\n  left: 50%;\n  top: 50%;\n  background: white;\n  border-radius: 20px;\n  box-shadow: 0 0 35px 0 ", ";\n\n  @media (min-width: 768px) {\n    padding: 50px;\n  }\n"], function (props) {
  return props.theme.color.shadowBlue;
});
var StyledTextWrapper = exports.StyledTextWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTextWrapper",
  componentId: "sc-14gew40-2"
})(["\n  margin-bottom: 25px;\n  text-align: center;\n  font-family: ", ";\n  color: ", ";\n  font-size: 18px;\n  line-height: 1.2;\n\n  @media (min-width: 768px) {\n    margin-bottom: 20px;\n    font-size: 24px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.font;
});
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-14gew40-3"
})(["\n  margin: 0 auto;\n  width: 150px;\n"]);