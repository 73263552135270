"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledModalInner = exports.StyledImage = exports.StyledForm = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledForm = exports.StyledForm = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledForm",
  componentId: "sc-1opc2ka-0"
})(["\n  * {\n    box-sizing: border-box;\n  }\n  margin-top: 40px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 32px;\n  width: 100%;\n  max-width: 370px;\n\n  button {\n    margin-top: 24px;\n  }\n"]);
var StyledModalInner = exports.StyledModalInner = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledModalInner",
  componentId: "sc-1opc2ka-1"
})(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  align-content: center;\n  gap: 16px;\n  min-height: 350px;\n\n  .icon {\n    margin-top: 40px;\n    width: 104px;\n    height: 104px;\n  }\n\n  > div {\n    &:first-child {\n      align-self: flex-start;\n      max-width: 300px;\n    }\n  }\n"]);
var StyledImage = exports.StyledImage = _styledComponents.default.img.withConfig({
  displayName: "styles__StyledImage",
  componentId: "sc-1opc2ka-2"
})(["\n  height: auto;\n  width: 100%;\n"]);