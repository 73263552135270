"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTriangleWrapper = exports.StyledText = exports.StyledRow = exports.StyledMultipleLaunchBlock = exports.StyledLaunchBlock = exports.StyledImageWrapper = exports.StyledImage = exports.StyledContentWrapper = exports.StyledCol = exports.StyledButtonWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledMultipleLaunchBlock = exports.StyledMultipleLaunchBlock = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledMultipleLaunchBlock",
  componentId: "sc-1qhrahz-0"
})(["\n  max-width: 310px;\n  margin: 0 auto;\n  @media (min-width: 768px) {\n    max-width: 100%;\n  }\n  ", " {\n    margin: 100px auto;\n  }\n"], function (props) {
  return props.theme.mediaQueries.desktop;
});
var StyledRow = exports.StyledRow = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledRow",
  componentId: "sc-1qhrahz-1"
})(["\n  ", " {\n    display: flex;\n    flex-wrap: nowrap;\n    flex-direction: row;\n    margin: 0 -40px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.desktop;
});
var StyledCol = exports.StyledCol = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCol",
  componentId: "sc-1qhrahz-2"
})(["\n  ", " {\n    flex-grow: 1;\n    flex-basis: 100%;\n    display: flex;\n    align-items: stretch;\n    justify-content: center;\n    padding: 0 20px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.desktop;
});
var StyledLaunchBlock = exports.StyledLaunchBlock = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledLaunchBlock",
  componentId: "sc-1qhrahz-3"
})(["\n  display: flex;\n  flex-direction: row;\n\n  min-height: 120px;\n  max-height: 400px;\n  border-radius: 35px;\n  box-shadow: 0 0 35px 0 ", ";\n  width: 100%;\n  margin: 40px 0;\n  text-decoration: none;\n\n  @media (min-width: 768px) {\n    min-height: 180px;\n    cursor: pointer;\n  }\n\n  ", " {\n    width: ", ";\n    flex-direction: column;\n    max-height: none;\n  }\n"], function (props) {
  return props.theme.color.shadowBlue;
}, function (props) {
  return props.theme.mediaQueries.desktop;
}, function (props) {
  return props.blocksLength === 1 ? '30%' : '100%';
});
var StyledImageWrapper = exports.StyledImageWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledImageWrapper",
  componentId: "sc-1qhrahz-4"
})(["\n  background-color: ", ";\n  position: relative;\n  overflow: hidden;\n\n  border-top-left-radius: 35px;\n  border-bottom-left-radius: 35px;\n  border-top-right-radius: 0;\n  border-bottom-right-radius: 0;\n\n  width: 100%;\n\n  ", " {\n    width: 100%;\n    border-top-left-radius: 35px;\n    border-bottom-left-radius: 0;\n    border-top-right-radius: 35px;\n    border-bottom-right-radius: 0;\n\n    height: 221px;\n\n    ", "\n  }\n"], function (props) {
  return props.theme.color.gray;
}, function (props) {
  return props.theme.mediaQueries.desktop;
}, function (props) {
  return props.imageHeight && (0, _styledComponents.css)(["\n        height: ", ";\n      "], function (props) {
    return props.imageHeight;
  });
});
var StyledImage = exports.StyledImage = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledImage",
  componentId: "sc-1qhrahz-5"
})(["\n    background-size: cover;\n    background-position: top left;\n    background-repeat: no-repeat;\n    background-image: url('", "');\n\n    width: 100%;\n    height: 100%;\n\n    ", " {\n\n    }\n"], function (props) {
  return props.src + '?width=400&auto=format';
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var StyledTriangleWrapper = exports.StyledTriangleWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTriangleWrapper",
  componentId: "sc-1qhrahz-6"
})(["\n  position: absolute;\n  left: auto;\n  right: 0;\n  top: auto;\n  bottom: 0;\n\n  width: 100px;\n\n  > div {\n    border-top-width: 400px;\n  }\n\n  @media (min-width: 1440px) {\n    left: 0;\n    right: auto;\n\n    width: 100%;\n    height: 100px;\n\n    > div {\n      border-left-width: 545px;\n    }\n  }\n"]);
var StyledContentWrapper = exports.StyledContentWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContentWrapper",
  componentId: "sc-1qhrahz-7"
})(["\n  background-color: ", ";\n\n  -webkit-font-smoothing: antialiased;\n\n  border-top-left-radius: 0;\n  border-bottom-left-radius: 0;\n  border-top-right-radius: 35px;\n  border-bottom-right-radius: 35px;\n\n  position: relative;\n  width: 51.36%;\n\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  flex-grow: 1;\n\n  padding: 0 17px;\n  margin-left: -42px;\n  min-width: 9rem;\n\n  @media (min-width: 768px) {\n    padding: 0 42px;\n    margin-left: -42px;\n    min-width: auto;\n  }\n\n  ", " {\n    border-top-left-radius: 0;\n    border-bottom-left-radius: 35px;\n    border-top-right-radius: 0;\n    border-bottom-right-radius: 35px;\n    width: 100%;\n    padding: 27px 22px 55px;\n    margin-left: 0;\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-1qhrahz-8"
})(["\n  display: block;\n\n  &,\n  &:hover,\n  &:active,\n  &:visited {\n    text-decoration: none;\n  }\n\n  position: absolute;\n  left: 50%;\n  width: calc(100% - 70px);\n  max-width: 150px;\n\n  transform: translateX(-50%);\n\n  bottom: -44px;\n\n  display: none;\n\n  ", " {\n    display: block;\n    bottom: -30px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.desktop;
});
var StyledText = exports.StyledText = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledText",
  componentId: "sc-1qhrahz-9"
})(["\n  display: block;\n\n  &,\n  &:hover,\n  &:active,\n  &:visited {\n    text-decoration: none;\n    color: ", ";\n  }\n\n  font-size: 16px;\n  line-height: 26px;\n\n  font-family: ", ";\n\n  text-align: center;\n\n  @media (min-width: 768px) {\n    font-size: 20px;\n    line-height: 32px;\n  }\n\n  ", " {\n    font-size: 22px;\n    line-height: 32px;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});