import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { DataLayer } from '@alkemy/components'

export default compose(
  withPageBuilder({
    niceName: 'DataLayer',
  }),
  withFields([
    {
      prop: 'pageType',
      type: 'multiple',
      label: 'Tipo Pagina',
      options: ['ProductPage:Product Page', 'cmsPage:CMS Page'],
      initialValue: '',
    },
    {
      label: 'Case 1 Product Page',
      type: 'sep',
    },
    {
      prop: 'ProductID',
      label: 'Product ID',
    },
    {
      prop: 'ProductName',
      label: 'Product Name',
    },
    {
      prop: 'ProductPrice',
      label: 'Product Price',
    },
    {
      type: 'multiple',
      prop: 'bType',
      label: 'BUSINESS_TYPE',
      options: ['Private:Private', 'Business:Business'],
    },
    {
      type: 'multiple',
      prop: 'cType',
      label: 'COMMODITY_TYPE',
      options: ['Dual:Dual', 'Ele:Ele', 'Gas:Gas'],
    },
    {
      type: 'multiple',
      prop: 'dType',
      label: 'OFFER_TYPE',
      options: ['Mono:Mono', 'Bio:Bio'],
    },
    {
      label: 'Case 2 Cms Page',
      type: 'sep',
    },
    {
      prop: 'PageTemplate',
      label: 'Page Template',
      initialValue: 'category',
    },
    {
      prop: 'PageName',
      label: 'Page NameD',
    },
    {
      prop: 'PageCategory',
      label: 'Page Category',
    },
  ]),
)(DataLayer)
