"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TextBlock = exports.StyledIconWrapper = exports.StyledButtonLabel = exports.StyledButton = exports.Label = exports.ImageContainer = exports.IconWrapper = exports.Container = exports.ChatButtonWrapper = exports.ButtonWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var Container = exports.Container = _styledComponents.default.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-7sft18-0"
})(["\n  width: 100%;\n  height: 285px;\n  flex: 1;\n  border-radius: 20px;\n  box-shadow: 1px 0px 18px #d0dfdf;\n  background-color: #fff;\n  display: flex;\n  flex-flow: column;\n"]);
var TextBlock = exports.TextBlock = _styledComponents.default.div.withConfig({
  displayName: "styles__TextBlock",
  componentId: "sc-7sft18-1"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  font-weight: normal;\n  display: flex;\n  justify-content: center;\n  margin-top: 8px;\n  text-align: center;\n  margin-bottom: 7px;\n  padding: 0 15px;\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.regular;
});
var Label = exports.Label = _styledComponents.default.div.withConfig({
  displayName: "styles__Label",
  componentId: "sc-7sft18-2"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 18px;\n  font-weight: normal;\n  display: flex;\n  justify-content: center;\n  margin-top: 8px;\n  margin-bottom: 7px;\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.bold;
});
var ButtonWrapper = exports.ButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__ButtonWrapper",
  componentId: "sc-7sft18-3"
})(["\n  width: 270px;\n  margin: auto;\n  text-align: center;\n\n  ", "\n"], function (props) {
  return props.chat && (0, _styledComponents.css)(["\n      position: relative;\n\n      button {\n        box-shadow: none !important;\n        > div {\n          width: 30px;\n          height: 18px;\n          position: absolute;\n          top: 50%;\n          transform: translateY(-50%);\n          left: 15px;\n        }\n      }\n    "]);
});
var ImageContainer = exports.ImageContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__ImageContainer",
  componentId: "sc-7sft18-4"
})(["\n  width: 90%;\n  margin: auto;\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  > a {\n    justify-content: center;\n    display: flex;\n    height: 40px;\n    > img {\n      height: 100%;\n      width: auto;\n    }\n  }\n"]);
var IconWrapper = exports.IconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__IconWrapper",
  componentId: "sc-7sft18-5"
})(["\n  height: 60px;\n  padding: 5px;\n  background-color: #fff;\n  border-radius: 50%;\n  display: inline-block;\n  margin-top: 18px;\n  position: relative;\n  width: 100%;\n  margin-bottom: 10px;\n\n  svg {\n    width: 100% !important;\n    height: 100% !important;\n  }\n  > div {\n    width: 100% !important;\n    height: 100% !important;\n    > svg {\n      width: 100% !important;\n      height: 100% !important;\n    }\n    > div {\n      > svg {\n        width: 100% !important;\n        height: 100% !important;\n      }\n    }\n  }\n"]);
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-7sft18-6"
})(["\n  flex-grow: 0;\n  align-self: center;\n  width: 26px;\n  height: 29px;\n"]);
var StyledButtonLabel = exports.StyledButtonLabel = _styledComponents.default.span.withConfig({
  displayName: "styles__StyledButtonLabel",
  componentId: "sc-7sft18-7"
})(["\n  flex-grow: 1;\n  text-align: center;\n  pointer-events: none;\n"]);
var StyledButton = exports.StyledButton = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledButton",
  componentId: "sc-7sft18-8"
})(["\n  display: flex;\n  position: relative;\n  font-family: ", ";\n  font-weight: 400;\n  flex-direction: row;\n  width: 100%;\n  min-width: 240px;\n\n  cursor: pointer;\n  outline: none;\n  border-radius: 31.5px;\n\n  border: solid 2px #204386;\n  color: #204386;\n  font-family: ", ";\n  font-size: 16px;\n  line-height: 38px;\n\n  text-align: center;\n  text-decoration: none;\n\n  padding-left: 10px;\n  padding-right: 10px;\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.font.regular;
});
var ChatButtonWrapper = exports.ChatButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__ChatButtonWrapper",
  componentId: "sc-7sft18-9"
})(["\n  width: 270px;\n  margin: auto;\n  text-align: center;\n"]);