import React from 'react'
import axios from 'axios'
import _ from 'lodash'
import styled from 'styled-components'

import { RoundFilters, RoundTile, Wrapper } from '@alkemy/components'

const filterRegulationByID = (regulations, id) =>
  regulations.filter(item => _(item.terms).find({ id }))

const makeRegulation = ({ title, slug, ...regulation }) => {
  return {
    title: title && title.rendered,
    slug,
    terms: getTerms(regulation).map(({ name, slug, id }) => ({
      id,
      name,
      slug,
    })),
  }
}

const Padder = styled.div`
  padding: 0px 53px 65px;
  ${props => props.theme.mediaQueries.tablet} {
    padding: 0;
  }
  ${props => props.theme.mediaQueries.phone} {
    padding: 0 30px;
  }
  @media screen and (max-width: 1366px) {
    && > div.list > div:nth-child(1):not(a) {
      font-size: 14px;
    }
  }
`

const makeTile = ({ slug, id, title }) => ({
  id,
  title,
  href: `/normativa/${slug}`,
})

const getTerms = source =>
  (source &&
    source['_embedded'] &&
    source['_embedded']['wp:term'] &&
    source['_embedded']['wp:term'][0]) ||
  []

class ListOfRegulations extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    filters: [],
    activeID: null,
    regulations: [],
  }

  async componentDidMount() {
    const wpData = await axios
      .get(`${process.env.WP_API}/wp/v2/regulation?_embed&per_page=100`)
      .then(x => x.data)
    const regulations = wpData.length > 0 && wpData.map(makeRegulation)
    const filters = _(regulations)
      .map('terms')
      .flatten()
      .uniqBy('slug')
      .value()

    this.setState({
      regulations,
      filters,
    })
  }

  selectFilter = id => {
    this.setState({
      activeID: id,
    })
  }

  render() {
    const { activeID, regulations, filters } = this.state
    return (
      <React.Fragment>
        <Padder>
          <RoundFilters
            filters={filters}
            activeID={activeID}
            selectFilter={this.selectFilter}
          />
          <ListOfRoundTiles
            items={
              activeID
                ? filterRegulationByID(regulations, activeID)
                : regulations
            }
          />
        </Padder>
      </React.Fragment>
    )
  }
}

const ListOfRoundTiles = props => {
  const { items } = props
  return items
    ? items.map(item => (
        <div className={'list'}>
          <RoundTile {...makeTile(item)} buttonText={'Scopri di più'} />
        </div>
      ))
    : null
}

export default ListOfRegulations
