"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Title = exports.SectionTariffa = exports.IntroductionTitle = exports.IntroductionDescription = exports.Introduction = exports.HeaderText = exports.Header = exports.FooterTitle = exports.FooterText = exports.Footer = exports.FlexRow = exports.Container = exports.CalcoloHeader = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var Container = exports.Container = _styledComponents.default.div.withConfig({
  displayName: "style__Container",
  componentId: "sc-9ei5r7-0"
})(["\n  padding-top: 40px;\n  padding: 40px 52px 80px 52px;\n\n  ", " {\n    padding: 40px 24px 100px 24px;\n  }\n\n  ", " {\n    padding: 40px 15px !important;\n    margin-bottom: 93px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  return props.isModal;
});
var HeaderText = exports.HeaderText = _styledComponents.default.span.withConfig({
  displayName: "style__HeaderText",
  componentId: "sc-9ei5r7-1"
})(["\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.57;\n  letter-spacing: normal;\n  text-align: center;\n\n  ", " {\n    margin: 0 !important;\n    width: 100%;\n    max-width: 200px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var CalcoloHeader = exports.CalcoloHeader = _styledComponents.default.div.withConfig({
  displayName: "style__CalcoloHeader",
  componentId: "sc-9ei5r7-2"
})(["\n  text-align: center;\n  font-family: ", ";\n  font-size: 16px;\n  color: ", ";\n  background-image: linear-gradient(to bottom, #3f5063, #73889c);\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  bottom: 0;\n  gap: 10px;\n  height: 54px;\n\n  ", " {\n    flex-direction: column;\n    height: 93px;\n    gap: 0;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var Introduction = exports.Introduction = _styledComponents.default.div.withConfig({
  displayName: "style__Introduction",
  componentId: "sc-9ei5r7-3"
})(["\n  line-height: 1.5 !important;\n  font-family: ", " !important;\n  color: ", ";\n  font-weight: normal;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var IntroductionTitle = exports.IntroductionTitle = _styledComponents.default.h2.withConfig({
  displayName: "style__IntroductionTitle",
  componentId: "sc-9ei5r7-4"
})(["\n  font-size: 30px;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.33;\n  letter-spacing: normal;\n\n  ", " {\n    font-size: 18px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var IntroductionDescription = exports.IntroductionDescription = _styledComponents.default.div.withConfig({
  displayName: "style__IntroductionDescription",
  componentId: "sc-9ei5r7-5"
})(["\n  font-size: 16px;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.5;\n  letter-spacing: normal;\n\n  > * {\n    margin: 0;\n    padding: 0;\n  }\n"]);
var SectionTariffa = exports.SectionTariffa = _styledComponents.default.div.withConfig({
  displayName: "style__SectionTariffa",
  componentId: "sc-9ei5r7-6"
})(["\n  font-family: ", ";\n"], function (props) {
  return props.theme.font.regular;
});
var Header = exports.Header = _styledComponents.default.div.withConfig({
  displayName: "style__Header",
  componentId: "sc-9ei5r7-7"
})(["\n  height: 48px;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  gap: 10px;\n  align-items: center;\n  margin-top: 30px;\n  color: ", ";\n\n  > *:first-child {\n    height: 48px;\n    width: 48px;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
});
var Title = exports.Title = _styledComponents.default.div.withConfig({
  displayName: "style__Title",
  componentId: "sc-9ei5r7-8"
})(["\n  font-size: 20px;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.5;\n  letter-spacing: normal;\n\n  ", " {\n    font-size: 16px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var Footer = exports.Footer = _styledComponents.default.div.withConfig({
  displayName: "style__Footer",
  componentId: "sc-9ei5r7-9"
})(["\n  margin: 20px 0;\n  font-family: ", ";\n  color: ", ";\n  font-size: 14px;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.57;\n  letter-spacing: normal;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var FooterTitle = exports.FooterTitle = _styledComponents.default.span.withConfig({
  displayName: "style__FooterTitle",
  componentId: "sc-9ei5r7-10"
})(["\n  font-weight: 600;\n  color: ", ";\n"], function (props) {
  return props.theme.color.darkBlue;
});
var FooterText = exports.FooterText = _styledComponents.default.p.withConfig({
  displayName: "style__FooterText",
  componentId: "sc-9ei5r7-11"
})(["\n  line-height: 1.5;\n  color: ", ";\n\n  > * {\n    margin: 0;\n    padding: 0;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
});
var FlexRow = exports.FlexRow = _styledComponents.default.div.withConfig({
  displayName: "style__FlexRow",
  componentId: "sc-9ei5r7-12"
})(["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: ", ";\n  align-items: center;\n  padding-top: 20px;\n  padding-bottom: 40px;\n\n  ", " {\n    flex-direction: ", ";\n    ", "\n  }\n"], function (props) {
  return props.justify ? props.justify : 'center';
}, function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  return props.mobileDirection ? props.mobileDirection : 'row';
}, function (props) {
  return props.parent && (0, _styledComponents.css)(["\n        gap: 48px;\n      "]);
});