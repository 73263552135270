"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitleWrapper = exports.StyledTextWrapper = exports.StyledImg = exports.StyledChiSiamoTextImg = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledChiSiamoTextImg = exports.StyledChiSiamoTextImg = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledChiSiamoTextImg",
  componentId: "sc-x5pzs2-0"
})(["\n  max-width: 320px;\n  margin: 0 auto 80px;\n  -webkit-font-smoothing: antialiased;\n\n  @media (min-width: 768px) {\n    max-width: 700px;\n    display: flex;\n    flex-direction: row-reverse;\n    justify-content: space-between;\n  }\n\n  @media (min-width: 1440px) {\n    max-width: 970px;\n    flex-wrap: wrap;\n  }\n"]);
var StyledTitleWrapper = exports.StyledTitleWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitleWrapper",
  componentId: "sc-x5pzs2-1"
})(["\n  margin-bottom: 25px;\n  font-family: ", ";\n  color: ", ";\n  font-size: 22px;\n  text-align: center;\n\n  @media (min-width: 768px) {\n    display: none;\n  }\n\n  @media (min-width: 1440px) {\n    display: block;\n    flex-basis: 100%;\n    margin-bottom: 40px;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledTextWrapper = exports.StyledTextWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTextWrapper",
  componentId: "sc-x5pzs2-2"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 16px;\n  line-height: 1.5;\n  padding: 0 10px !important;\n\n  p {\n    padding: 0 !important;\n  }\n\n  ", " {\n    display: none;\n\n    @media (min-width: 768px) {\n      display: block;\n      text-align: left;\n      font-size: 22px;\n    }\n\n    @media (min-width: 1440px) {\n      display: none;\n    }\n  }\n\n  @media (min-width: 768px) {\n    max-width: 275px;\n  }\n\n  @media (min-width: 1440px) {\n    max-width: 370px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, StyledTitleWrapper);
var StyledImg = exports.StyledImg = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledImg",
  componentId: "sc-x5pzs2-3"
})(["\n  width: 320px;\n  height: 240px;\n  background-image: url(", ");\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n  margin-bottom: 40px;\n\n  @media (min-width: 768px) {\n    width: 384px;\n    height: 280px;\n    border-radius: 20px;\n  }\n\n  @media (min-width: 1440px) {\n    width: 570px;\n    height: 320px;\n  }\n"], function (props) {
  return props.imgSrc + '?width=320&auto=format';
});