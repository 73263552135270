"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledRichText = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledRichText = exports.StyledRichText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledRichText",
  componentId: "sc-1viaieg-0"
})(["\n  font-style: normal;\n  font-stretch: normal;\n  letter-spacing: normal;\n\n  b > span {\n    font-size: 180%;\n  }\n\n  p,\n  h1,\n  h2,\n  h3,\n  h4,\n  h5,\n  h6 {\n    padding: 0 10px;\n  }\n  li {\n    margin-left: 40px;\n  }\n\n  p {\n    font-family: ", ";\n    font-size: ", ";\n    font-weight: normal;\n    line-height: ", ";\n  }\n\n  h1,\n  h2,\n  h3,\n  h4,\n  h5,\n  h6 {\n    margin-bottom: 24px;\n\n    font-family: ", ";\n    font-size: 18px;\n    font-weight: 500;\n    line-height: 24px;\n    text-align: center;\n  }\n\n  ul {\n    padding: 0;\n  }\n\n  img {\n    display: block;\n  }\n\n  & > img {\n    margin: 40px 0px;\n    width: 100vw;\n  }\n\n  ", " {\n    p {\n      padding: 0px;\n    }\n    h1,\n    h2,\n    h3,\n    h4,\n    h5,\n    h6 {\n      padding: 0 100px;\n    }\n\n    h1,\n    h2,\n    h3,\n    h4,\n    h5,\n    h6 {\n      margin-bottom: 40px;\n\n      font-size: 22px;\n      line-height: 32px;\n    }\n\n    & > img {\n      margin: 40px -14px;\n    }\n  }\n\n  ", " {\n    p,\n    h1,\n    h2,\n    h3,\n    h4,\n    h5,\n    h6,\n    ul,\n    img {\n      padding: 0 200px;\n    }\n\n    h1,\n    h2,\n    h3,\n    h4,\n    h5,\n    h6 {\n      margin-bottom: 60px;\n    }\n\n    & > img {\n      margin: 60px auto;\n      width: 100%;\n    }\n  }\n\n  ", "\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.customFontSize ? props.customFontSize + 'px' : '16px';
}, function (props) {
  return props.customFontSize ? '30px' : '24px';
}, function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.desktop;
}, function (props) {
  return props.nopad ? "{\n    p {\n      padding:0\n    }\n  }" : '';
});