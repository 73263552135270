import { compose } from "recompose"
import withPageBuilder from "@alkemy/pagebuilder/lib/hoc/withPageBuilder"
import withFields from "@alkemy/pagebuilder/lib/hoc/withFields"

import {
  EventInfoVariant,
  withWrapper
} from "@alkemy/components"

export default compose(
  withPageBuilder(),
  withFields([{
    prop: "wrapped",
    type: "boolean",
    label: "Contenuto Boxed",
  }
  ]),
  withWrapper
)((props) => {
  return (<div>
    <EventInfoVariant
      position={{
        "city": "Verona",
        "lat": 45.3986773,
        "lng": 8.9197752
      }}
      date={{
        "day": "02",
        "month": "DIC"
      }}
    />
  </div>)
})
  