import React, { useEffect, useContext } from 'react'
import { Icons } from '@alkemy/components'
import { ResizeContext } from './withResizeProvider'

const toggleChat = state => {
  let elChatra

  try {
    if (typeof document !== 'undefined') {
      elChatra = document.querySelector('#chatra')

      state
        ? elChatra.classList.remove('chat-hidden--product')
        : elChatra.classList.add('chat-hidden--product')
    }
  } catch (err) {
    console.log(err)
  }
}

const removeChat = () => {
  let el
  let customEl
  let wrapperEl
  try {
    if (typeof document !== 'undefined') {
      el = document.querySelector('#chatra')
      customEl = document.querySelector('.chat-icon--custom')
      wrapperEl = document.querySelector('#chatra__iframe-wrapper')
      el.classList.add('chat-hidden--product')
      customEl.classList.add('chat-hidden--product')
      wrapperEl.classList.add('chat-hidden--product')
    }
  } catch (err) {
    console.log(err)
  }
}

const withChatIcon = EnhancedComponent => {
  const WithChatIcon = props => {
    const resize = useContext(ResizeContext)
    const { innerWidth } = resize
    const isMobilePhone = innerWidth <= 768
    const style = isMobilePhone
      ? {
          position: 'fixed',
          bottom: '-38px',
          left: '-38px',
          zIndex: '99999',
          cursor: 'pointer',
          transform: 'scale(0.5)',
          maxHeight: '167px',
          maxWidth: '167px',
        }
      : {
          position: 'fixed',
          bottom: '-18px',
          left: '-18px',
          zIndex: '99999',
          transform: 'scale(0.75)',
          cursor: 'pointer',
          maxHeight: '167px',
          maxWidth: '167px',
        }
    const currentdate = new Date()
    let isVisible = false
    /*
        currentdate.getHours() >= 10 &&
        currentdate.getHours() < 24 &&
        currentdate.getDay() !== 0
        */
    /*    const mobileHidden =
      props.wpData && props.wpData.template === 'page-product' */
    // console.log(JSON.stringify(props.wpData.post_title))
    if (
      (typeof props.wpData !== 'undefined' &&
        props.wpData.template === 'page-offers') ||
      (typeof props.wpData !== 'undefined' &&
        props.wpData.template === 'page-product') ||
      (typeof props.wpData !== 'undefined' &&
        props.wpData.template === 'page-partner') ||
      (typeof props.wpData !== 'undefined' &&
        props.wpData.template === 'page-nochat') ||
      (typeof props.wpData !== 'undefined' &&
        props.wpData.template === 'page-iframe') ||
      (typeof props.wpData !== 'undefined' &&
        props.wpData.template === 'page-hiddenchat')
    ) {
      removeChat()
      isVisible = false
      useEffect(() => {
        const body = document.body
        body.classList.add('no-chat')
      }, [])
    }
    useEffect(() => {
      // console.log('href', window.location.href)
      const body = document.body
      const url = window.location.href

      let showChat = false

      if (
        url == 'http://localhost:3000/' ||
        url == 'http://vivigas.local/' ||
        url == 'https://dev.vivienergia.alkemy.com/' ||
        url == 'https://vivienergia.alkemy.com/' ||
        url == 'https://www.vivienergia.it/'
      ) {
        showChat = true
      }
      if (!showChat) {
        const el = document.getElementById('chatra-icon')
        if (el) {
          el.style.display = 'none'
        }

        body.classList.add('no-chat')
        //showChat = true
      }
      // alert(showChat)
    }, [])
    return (
      <React.Fragment>
        <EnhancedComponent {...props} />
        {isVisible && (
          <div style={style}>
            <Icons
              id="ChatraIcon"
              icon="Chatra"
              handleClick={() => {
                if (Chatra) {
                  Chatra('openChat', true)
                }
              }}
            />
          </div>
        )}
      </React.Fragment>
    )
  }

  WithChatIcon.getInitialProps = async ctx => {
    let props = {}
    if (typeof EnhancedComponent.getInitialProps === 'function') {
      props = await EnhancedComponent.getInitialProps(ctx)
    }
    return { ...props }
  }

  return WithChatIcon
}

export default withChatIcon
