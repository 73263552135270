"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ScontoText = exports.ScontoDescription = exports.BollinoScontoWrapper = exports.BollinoScontoIconWrapper = exports.BollinoScontoIcon = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _style = require("../CalcoloCostiAccordion/style");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var BollinoScontoIconWrapper = exports.BollinoScontoIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "style__BollinoScontoIconWrapper",
  componentId: "sc-r8zdjd-0"
})(["\n  position: relative;\n  width: 64px;\n  height: 64px;\n  margin-left: -52px;\n  background-color: white;\n  border-radius: 50%;\n\n  ", " {\n    margin-left: 0;\n    margin-top: -52px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var BollinoScontoIcon = exports.BollinoScontoIcon = _styledComponents.default.img.withConfig({
  displayName: "style__BollinoScontoIcon",
  componentId: "sc-r8zdjd-1"
})(["\n  height: 64px;\n  width: 64px;\n"]);
var BollinoScontoWrapper = exports.BollinoScontoWrapper = (0, _styledComponents.default)(_style.FlexRow).withConfig({
  displayName: "style__BollinoScontoWrapper",
  componentId: "sc-r8zdjd-2"
})(["\n  background-color: #fecb1c;\n  margin-left: -50%;\n  margin: 0 auto;\n  padding: 10px 20px;\n  width: fit-content;\n  border-radius: 10px;\n\n  ", " {\n    padding: 15px 25px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var ScontoText = exports.ScontoText = _styledComponents.default.span.withConfig({
  displayName: "style__ScontoText",
  componentId: "sc-r8zdjd-3"
})(["\n  font-family: ", ";\n  font-size: 30px;\n  font-weight: bold;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.33;\n  letter-spacing: normal;\n  color: ", ";\n  padding-left: 5px;\n\n  ", " {\n    font-size: 35px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var ScontoDescription = exports.ScontoDescription = _styledComponents.default.div.withConfig({
  displayName: "style__ScontoDescription",
  componentId: "sc-r8zdjd-4"
})(["\n  display: inline-block;\n  line-height: 1.57;\n  font-family: ", ";\n  color: ", ";\n  max-width: 200px;\n  font-size: 14px;\n  margin-left: 20px;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});