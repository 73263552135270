"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Wrapper = exports.WeLLCallYou = exports.Subscribe = exports.StyledIconWrapper = exports.StyledButtonLabel = exports.StyledButton = exports.ServiceNumberWrapper = exports.MiniIcon = exports.ListItem = exports.IconWrapper = exports.FlexTable = exports.Container = exports.Col = exports.Card = exports.Block = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var Container = exports.Container = _styledComponents.default.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-1kvk8vw-0"
})(["\n  display: flex;\n  position: fixed;\n  bottom: 0;\n  z-index: 999;\n  width: 100%;\n  left: 0;\n  background-color: #fff;\n  padding: 5px 20px;\n  box-shadow: 0px -2px 20px #40414438;\n  align-content: center;\n  align-items: center;\n  justify-content: center;\n  @media (max-width: 768px) {\n    display: none;\n  }\n"]);
var Wrapper = exports.Wrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-1kvk8vw-1"
})(["\n  display: flex;\n  width: 100%;\n  @media (min-width: 1000px) {\n    width: 1000px;\n  }\n"]);
var Col = exports.Col = _styledComponents.default.div.withConfig({
  displayName: "styles__Col",
  componentId: "sc-1kvk8vw-2"
})(["\n  display: flex;\n  flex: 1;\n  font-family: ", ";\n  font-weight: 400;\n  font-size: 20px;\n  color: #3f5063;\n  align-items: center;\n  text-transform: uppercase;\n  > div {\n    flex: 1;\n  }\n  &:nth-child(2) {\n    > div {\n      text-align: center;\n      width: 90%;\n      margin: 0;\n      /* padding: 9px 0 !important; */\n      justify-content: flex-end;\n      display: flex;\n      /* > div {\n        width: 90%;\n        margin:0;\n        left:0;\n      } */\n    }\n  }\n"], function (props) {
  return props.theme.font.medium;
});
var Card = exports.Card = _styledComponents.default.div.withConfig({
  displayName: "styles__Card",
  componentId: "sc-1kvk8vw-3"
})(["\n  box-shadow: 0 0 14px 0px #5181ba75;\n  width: 100%;\n  padding: 25px;\n  height: auto;\n  align-self: flex-start;\n  margin-top: 60px;\n  border-radius: 36px;\n  font-family: ", ";\n  font-weight: 400;\n  font-size: 16px;\n  color: #3f5063;\n  b,\n  strong {\n    font-family: ", ";\n    font-weight: 400;\n  }\n  @media (max-width: 1000px) {\n    width: 95%;\n  }\n  @media (max-width: 767px) {\n    width: 85%;\n    margin: auto;\n    max-width: 300px;\n    margin-bottom: 60px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.font.bold;
});
var Block = exports.Block = _styledComponents.default.div.withConfig({
  displayName: "styles__Block",
  componentId: "sc-1kvk8vw-4"
})(["\n  margin: 0;\n  padding: 0;\n  padding-top: 20px;\n  border-bottom: 1px solid #ccc;\n  padding-bottom: 20px;\n  font-family: ", ";\n  font-weight: 400;\n  color: #3f5063;\n  b,\n  strong {\n    font-family: ", ";\n    font-weight: 400;\n  }\n  img {\n    padding: 10px 0;\n  }\n  small {\n    font-size: 10px;\n    display: block;\n    padding-top: 10px;\n    padding-bottom: 0px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.font.bold;
});
var IconWrapper = exports.IconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__IconWrapper",
  componentId: "sc-1kvk8vw-5"
})(["\n  height: 50px;\n  width: 50px;\n  flex: 0 70px !important;\n  > div {\n    height: 100%;\n  }\n"]);
var ListItem = exports.ListItem = _styledComponents.default.div.withConfig({
  displayName: "styles__ListItem",
  componentId: "sc-1kvk8vw-6"
})(["\n  margin-bottom: 20px;\n  width: 100%;\n  text-align: left;\n"]);
var MiniIcon = exports.MiniIcon = _styledComponents.default.div.withConfig({
  displayName: "styles__MiniIcon",
  componentId: "sc-1kvk8vw-7"
})(["\n  width: 25px;\n  float: left;\n  margin-right: 20px;\n"]);
var FlexTable = exports.FlexTable = _styledComponents.default.div.withConfig({
  displayName: "styles__FlexTable",
  componentId: "sc-1kvk8vw-8"
})(["\n  display: flex;\n  flex-wrap: wrap;\n  padding-top: 12px;\n"]);
var ServiceNumberWrapper = exports.ServiceNumberWrapper = _styledComponents.default.span.withConfig({
  displayName: "styles__ServiceNumberWrapper",
  componentId: "sc-1kvk8vw-9"
})(["\n  display: block;\n  padding: 10px;\n"]);
var Subscribe = exports.Subscribe = _styledComponents.default.div.withConfig({
  displayName: "styles__Subscribe",
  componentId: "sc-1kvk8vw-10"
})(["\n  cursor: pointer;\n  padding: 10px 20px;\n  width: 80%;\n  max-width: 230px;\n  margin: auto;\n  font-size: 13px;\n  border-radius: 47px;\n  color: #fff;\n  font-family: ", ";\n  font-weight: 400;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e81c69+0,ff9000+100 */\n  background: #e81c69; /* Old browsers */\n  background: -moz-linear-gradient(\n    left,\n    #e81c69 0%,\n    #ff9000 100%\n  ); /* FF3.6-15 */\n  background: -webkit-linear-gradient(\n    left,\n    #e81c69 0%,\n    #ff9000 100%\n  ); /* Chrome10-25,Safari5.1-6 */\n  > span {\n    flex: 1;\n  }\n  background: linear-gradient(\n    to right,\n    #e81c69 0%,\n    #ff9000 100%\n  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */\n  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e81c69', endColorstr='#ff9000',GradientType=1 ); /* IE6-9 */\n  @media (max-width: 767px) {\n    display: block;\n    position: fixed;\n    bottom: 0;\n    width: 100%;\n    left: 0;\n    border-radius: 0;\n    padding: 23px 15px;\n    font-size: 20px;\n    z-index: 999;\n  }\n"], function (props) {
  return props.theme.font.bold;
});
var WeLLCallYou = exports.WeLLCallYou = _styledComponents.default.div.withConfig({
  displayName: "styles__WeLLCallYou",
  componentId: "sc-1kvk8vw-11"
})(["\n  cursor: pointer;\n  padding: 10px 20px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 90%;\n  margin: auto;\n  font-size: 13px;\n  border-radius: 47px;\n  color: #fff;\n  font-family: ", ";\n  font-weight: 400;\n  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e81c69+0,ff9000+100 */\n  background: #6ec18a; /* Old browsers */\n  background: -moz-linear-gradient(\n    left,\n    #6ec18a 0%,\n    #0e89ca 100%\n  ); /* FF3.6-15 */\n  background: -webkit-linear-gradient(\n    left,\n    #6ec18a 0%,\n    #0e89ca 100%\n  ); /* Chrome10-25,Safari5.1-6 */\n  background: linear-gradient(\n    to right,\n    #6ec18a 0%,\n    #0e89ca 100%\n  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */\n  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6EC18A', endColorstr='#0E89CA',GradientType=1 ); /* IE6-9 */\n"], function (props) {
  return props.theme.font.bold;
});
var StyledButtonLabel = exports.StyledButtonLabel = _styledComponents.default.span.withConfig({
  displayName: "styles__StyledButtonLabel",
  componentId: "sc-1kvk8vw-12"
})(["\n  flex-grow: 1;\n  text-align: center;\n  pointer-events: none;\n"]);
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-1kvk8vw-13"
})(["\n  flex-grow: 0;\n  align-self: center;\n  width: 26px;\n  height: 29px;\n"]);
var StyledButton = exports.StyledButton = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledButton",
  componentId: "sc-1kvk8vw-14"
})(["\n  display: flex;\n  position: relative;\n  font-family: ", ";\n  font-weight: 400;\n  flex-direction: row;\n  width: 100%;\n  min-width: 240px;\n\n  cursor: pointer;\n  outline: none;\n  border-radius: 31.5px;\n\n  border: solid 2px #204386;\n  color: #204386;\n  font-family: ", ";\n  font-size: 16px;\n  line-height: 38px;\n\n  text-align: center;\n  text-decoration: none;\n\n  padding-left: 10px;\n  padding-right: 10px;\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.font.regular;
});