"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitleWrapper = exports.StyledLabelWrapper = exports.StyledImageWrapper = exports.StyledImage = exports.StyledContentWrapper = exports.StyledCardBlockFeatured = exports.StyledButtonWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledCardBlockFeatured = exports.StyledCardBlockFeatured = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCardBlockFeatured",
  componentId: "sc-bpbz8q-0"
})(["\n  position: relative;\n  width: 100%;\n  margin: 0 20px 24px;\n  padding-bottom: 0;\n  box-shadow: 0 0 50px 0 ", ";\n  border-radius: 24px;\n  overflow: hidden;\n  transition: box-shadow 0.2s ease-in 0s;\n  height: 450px;\n\n  &:hover {\n    box-shadow: 0 0 50px 0 ", ";\n  }\n\n  @media (min-width: 768px) {\n    width: calc(50% - 14px);\n    height: 520px;\n    border-radius: 35px;\n    box-shadow: ", ";\n    margin: 56px 0 56px;\n\n    &:nth-child(odd) {\n      margin-right: 28px;\n    }\n  }\n\n  @media (min-width: 1440px) {\n    width: calc(50% - 15px);\n\n    &:nth-child(odd) {\n      margin-right: 30px;\n    }\n  }\n"], function (props) {
  return props.theme.color.shadowBlue;
}, function (props) {
  return props.theme.color.shadowBlueHover;
}, function (props) {
  return "0 10px 50px 0 ".concat(props.theme.color.shadowBlue);
});
var StyledImageWrapper = exports.StyledImageWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledImageWrapper",
  componentId: "sc-bpbz8q-1"
})(["\n  width: 100%;\n  height: 100%;\n  background-color: ", ";\n  position: absolute;\n  left: 0;\n  top: 0;\n\n  &:after {\n    content: '';\n    position: absolute;\n    left: 0;\n    top: 0;\n    background-image: url('/static/images/CardBlock_overlay.png');\n    background-position: center right;\n    width: 100%;\n    height: 100%;\n  }\n\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    object-position: right;\n    @media (min-width: 768px) {\n      object-position: center;\n    }\n  }\n"], function (props) {
  return props.theme.color.gray;
});
var StyledImage = exports.StyledImage = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledImage",
  componentId: "sc-bpbz8q-2"
})(["\n  background-image: url(", ");\n  width: 100%;\n  height: 100%;\n  background-size: cover;\n  background-position: center;\n  @media (min-width: 768px) {\n    background-image: url(", ");\n  }\n"], function (props) {
  return props.imgMobile ? props.imgMobile : props.img;
}, function (props) {
  return props.img;
});
var StyledContentWrapper = exports.StyledContentWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContentWrapper",
  componentId: "sc-bpbz8q-3"
})(["\n  padding: 32px;\n  position: relative;\n  -webkit-font-smoothing: antialiased;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  height: 100%;\n\n  @media (min-width: 768px) {\n    padding: 40px;\n  }\n"]);
var StyledLabelWrapper = exports.StyledLabelWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledLabelWrapper",
  componentId: "sc-bpbz8q-4"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 14px;\n  line-height: 1.5;\n  margin-bottom: 24px;\n  background-color: rgba(255, 255, 255, 0.8);\n  border-radius: 8px;\n  padding: 3px 13px;\n  text-transform: uppercase;\n\n  @media (min-width: 768px) {\n    padding: 2px 16px;\n    font-size: 18px;\n    line-height: 2;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledTitleWrapper = exports.StyledTitleWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitleWrapper",
  componentId: "sc-bpbz8q-5"
})(["\n  margin-top: auto;\n  font-family: ", ";\n  color: ", ";\n  font-size: 18px;\n  line-height: 1.44;\n\n  @media (min-width: 768px) {\n    font-size: 30px;\n    line-height: 1.33;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.white;
});
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-bpbz8q-6"
})(["\n  margin-top: 24px;\n  @media (min-width: 768px) {\n    margin-top: 40px;\n  }\n"]);