import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import {
  ViviescoParagraphIcons,
  withWrapper,
} from '@alkemy/components'

export default compose(
  withPageBuilder({
    niceName: 'Viviesco - Paragrafo e Lista icone',
  }),
  withFields([{
    prop: 'text',
    label: 'Testo',
  }, {
    prop: 'iconData',
    label: 'Lista',
    type: 'repeater',
    initialValue: [],
    model: [
      {
        prop: 'id',
        label: 'ID',
      }, {
        prop: 'name',
        label: 'Nome icona',
      }, {
        prop: 'tooltipText',
        label: 'Testo',
      }, {
        prop: 'translateX',
        label: 'Translate X',
      }, {
        prop: 'translateY',
        label: 'Translate Y',
      }, {
        prop: 'scale',
        label: 'Scale',
      }, {
        prop: 'svgWidth',
        label: 'Larghezza svg'
      },
    ],
  }]),
  withWrapper,
)
(ViviescoParagraphIcons)
