"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SyledTextWrapper = exports.StyledNotFound = exports.StyledButtonWrapper = exports.StyledBlobWrapperTablet = exports.StyledBlobWrapperDesktop = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledNotFound = exports.StyledNotFound = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledNotFound",
  componentId: "sc-1i2d36w-0"
})(["\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  position: relative;\n  background-image: url('/static/images/404/Mobile/404.jpg');\n  background-size: 100% 90%;\n  background-position: center bottom;\n  background-repeat: no-repeat;\n  overflow: hidden;\n\n  @media (min-width: 768px) {\n    background-image: url('/static/images/404/Tablet/404.jpg');\n  }\n\n  @media (min-width: 1440px) {\n    background-image: url('/static/images/404/Desktop/404.jpg');\n  }\n"]);
var SyledTextWrapper = exports.SyledTextWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__SyledTextWrapper",
  componentId: "sc-1i2d36w-1"
})(["\n  margin-bottom: 40px;\n  font-family: ", ";\n  text-align: center;\n  font-size: 30px;\n\n  @media (min-width: 768px) {\n    font-size: 40px;\n  }\n\n  @media (min-width: 1440px) {\n  }\n"], function (props) {
  return props.theme.font.bold;
});
var StyledBlobWrapperTablet = exports.StyledBlobWrapperTablet = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBlobWrapperTablet",
  componentId: "sc-1i2d36w-2"
})(["\n  width: 200px;\n  height: 200px;\n  position: absolute;\n  display: none;\n\n  ", "\n\n  ", "\n  \n  @media (min-width: 768px) {\n    display: block;\n  }\n\n  @media (min-width: 1280px) {\n    display: none;\n  }\n"], function (props) {
  return props.position === 'top-left' && (0, _styledComponents.css)(["\n      top: 190px;\n      left: 60px;\n    "]);
}, function (props) {
  return props.position === 'bottom-right' && (0, _styledComponents.css)(["\n      bottom: 250px;\n      right: -90px;\n    "]);
});
var StyledBlobWrapperDesktop = exports.StyledBlobWrapperDesktop = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBlobWrapperDesktop",
  componentId: "sc-1i2d36w-3"
})(["\n  width: 200px;\n  height: 200px;\n  position: absolute;\n  display: none;\n  \n  ", "\n  \n  ", "\n  \n  ", "\n  \n  @media (min-width: 1280px) {\n    display: block;\n  }\n"], function (props) {
  return props.position === 'top-left' && (0, _styledComponents.css)(["\n      top: 190px;\n      left: 15vw;\n    "]);
}, function (props) {
  return props.position === 'top-right' && (0, _styledComponents.css)(["\n      top: 200px;\n      right: 20vw;\n    "]);
}, function (props) {
  return props.position === 'bottom-right' && (0, _styledComponents.css)(["\n      bottom: 77px;\n      right: -75px;\n    "]);
});
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-1i2d36w-4"
})(["\n  width: 240px;\n  margin: 0 auto;\n\n  @media (min-width: 768px) {\n    width: 170px;\n  }\n\n  a {\n    line-height: 42px;\n    padding: 0;\n  }\n"]);