"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledVideo = exports.StyledTitle = exports.StyledText = exports.StyledSubTitle = exports.StyledSlide = exports.StyledImgWrapper = exports.StyledImg = exports.StyledContentWrapper = exports.StyledContent = exports.StyledButtonWrapper = exports.StyledBlockRight = exports.StyledAbstract = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledImgWrapper = exports.StyledImgWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledImgWrapper",
  componentId: "sc-1azr9d7-0"
})(["\n  position: relative;\n  overflow: hidden;\n  width: 100%;\n\n  ", " {\n    width: 100%;\n    height: 430px;\n    border-top-right-radius: 0px;\n    border-bottom-right-radius: 0px;\n  }\n  @media (min-width: 1024px) {\n    width: 100%;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var StyledImg = exports.StyledImg = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledImg",
  componentId: "sc-1azr9d7-1"
})(["\n  width: 100%;\n  top: 0;\n  left: 0;\n  height: 100%;\n  background-color: transparent;\n  background-repeat: no-repeat;\n  background-size: cover;\n\n  background-image: url(", ");\n\n  ", " {\n    display: block;\n  }\n\n  ", " {\n    background-size: cover;\n    background-image: url(", ");\n  }\n\n  @media (min-width: 1920px) {\n    max-width: 1440px;\n    background-size: cover;\n    border-radius: 35px;\n    box-shadow: 0 0 35px 0 rgba(81, 129, 186, 0.25);\n  }\n  @media (max-width: 1023px) {\n    background-position: top !important;\n  }\n"], function (props) {
  return "".concat(props.img && props.img[0], "?width=768&auto=format");
}, function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  return props.theme.mediaQueries.ipad;
}, function (props) {
  return "".concat(props.img && props.img[1], "?width=1440&auto=format");
});
var StyledVideo = exports.StyledVideo = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledVideo",
  componentId: "sc-1azr9d7-2"
})(["\n  position: relative;\n  width: 100%;\n  top: 0;\n  left: 0;\n  height: 100%;\n\n  @media (min-width: 1920px) {\n    max-width: 1440px;\n    border-radius: 35px;\n    box-shadow: 0 0 35px 0 rgba(81, 129, 186, 0.25);\n    overflow: hidden;\n  }\n\n  > video {\n    position: absolute;\n    z-index: -1;\n    object-fit: cover;\n    width: 100%;\n    height: 100%;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n\n    &::-webkit-media-controls {\n      display: none !important;\n    }\n  }\n"]);
var StyledContentWrapper = exports.StyledContentWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContentWrapper",
  componentId: "sc-1azr9d7-3"
})(["\n  display: flex;\n  max-width: 1440px;\n  margin: auto;\n  @media (min-width: 1024px) {\n    padding-top: 55px;\n  }\n\n  .blockContent {\n    max-width: 590px;\n    width: 100%;\n  }\n  @media (max-width: 1023px) {\n    .blockContent {\n      position: absolute;\n      top: 60px;\n      margin: 0;\n      max-width: 345px;\n      width: 100%;\n      left: 0;\n      > div {\n        padding: 0 15px;\n      }\n    }\n  }\n"]);
var StyledContent = exports.StyledContent = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContent",
  componentId: "sc-1azr9d7-4"
})(["\n  ", " {\n    padding-left: 200px !important;\n    flex: 50%;\n    font-family: ", ";\n    font-size: 20px;\n    font-weight: 500;\n    font-stretch: normal;\n    font-style: normal;\n    line-height: normal;\n    letter-spacing: normal;\n    margin-left: 2;\n    margin-bottom: 20px;\n  }\n\n  ", " {\n    display: block;\n    width: 100%;\n    width: 95%;\n  }\n  ", " {\n    display: block;\n    width: 95%;\n  }\n"], function (props) {
  return props.theme.mediaQueries.ipad;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var StyledBlockRight = exports.StyledBlockRight = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBlockRight",
  componentId: "sc-1azr9d7-5"
})(["\n  flex: 40%;\n  text-align: right;\n  padding-right: 200px;\n  > div {\n    float: right;\n    max-width: 200px;\n  }\n  img {\n    display: block;\n    float: right;\n    margin-bottom: 20px;\n  }\n  img.bollini {\n    width: 144px;\n  }\n  img.card {\n    width: 189px;\n  }\n  ", " {\n    display: block;\n    width: 100%;\n    img.bollini {\n      width: 50%;\n    }\n    img.card {\n      width: 50%;\n    }\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var StyledAbstract = exports.StyledAbstract = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledAbstract",
  componentId: "sc-1azr9d7-6"
})(["\n  flex: 100%;\n  font-size: 14px;\n  padding-left: 200px;\n  font-family: ", ";\n  margin-top: -80px;\n  padding: 0 135px;\n  div {\n    padding-top: 20px;\n    padding-left: 65px;\n  }\n  @media (max-width: 1280px) {\n    margin-top: -110px;\n  }\n  ", " {\n    display: none;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-1azr9d7-7"
})(["\n  width: 240px;\n  font-family: ", ";\n  font-size: 16px;\n  font-weight: normal;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: normal;\n  letter-spacing: normal;\n  text-align: center;\n\n  @media (min-width: 768px) {\n    width: 180px;\n  }\n  @media (max-width: 1023px) {\n    margin-top: 15px;\n  }\n  @media (min-width: 1440px) {\n    font-size: 20px;\n    width: 240px;\n  }\n"], function (props) {
  return props.theme.font.regular;
});
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-1azr9d7-8"
})(["\n  font-family: ", ";\n  font-size: 30px;\n  font-weight: 500;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 35px;\n  letter-spacing: normal;\n  margin-bottom: 20px;\n  @media (max-width: 1023px) {\n    font-family: ", ";\n    font-size: 22px;\n    font-weight: 500;\n    line-height: 1;\n    margin-bottom: 16px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.font.medium;
});
var StyledText = exports.StyledText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledText",
  componentId: "sc-1azr9d7-9"
})(["\n  font-family: ", ";\n  font-size: 20px;\n  line-height: 28px;\n  font-weight: 500;\n  font-stretch: normal;\n  font-style: normal;\n  letter-spacing: normal;\n  margin-bottom: 20px;\n\n  @media (max-width: 1023px) {\n    font-size: 16px;\n    line-height: 24px;\n    margin-bottom: 16px;\n  }\n  .subtext {\n    display: block;\n    padding: 0;\n    ", " {\n      display: none;\n    }\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var StyledSubTitle = exports.StyledSubTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSubTitle",
  componentId: "sc-1azr9d7-10"
})(["\n  display: block;\n  font-family: ", ";\n  font-size: 48px;\n  font-weight: bold;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: normal;\n  letter-spacing: normal;\n\n  margin-bottom: 20px;\n  ", " {\n    display: none;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var StyledSlide = exports.StyledSlide = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSlide",
  componentId: "sc-1azr9d7-11"
})(["\n  @media (min-width: 768px) {\n    height: 600px;\n    display: flex;\n    flex-direction: row-reverse;\n    border-radius: 0px;\n  }\n\n  cursor: pointer;\n\n  ", " {\n    height: 600px !important;\n  }\n  ", " {\n    height: 600px !important;\n  }\n  ", " {\n    height: 430px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.desktop;
}, function (props) {
  return props.theme.mediaQueries.ipad;
}, function (props) {
  return props.theme.mediaQueries.phone;
});