"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledLink = exports.StyledButton = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledLink = exports.StyledLink = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledLink",
  componentId: "sc-1rku6q4-0"
})(["\n  display: block;\n  background: ", ";\n  cursor: pointer;\n\n  ", "\n"], function (props) {
  return props.theme.color[props.bgColor];
}, function (_ref) {
  var circle = _ref.circle;
  return circle && (0, _styledComponents.css)(["\n      position: relative;\n      width: 60px;\n      box-shadow: 0 0 35px 0\n        ", ";\n      border-radius: 50%;\n      overflow: hidden;\n\n      &:before {\n        content: '';\n        display: block;\n        width: 100%;\n        padding-bottom: 100%;\n        pointer-events: none;\n      }\n\n      > * {\n        position: absolute;\n        width: 50%;\n        top: 50%;\n        left: 50%;\n        transform: translate(-50%, -50%);\n      }\n    "], function (props) {
    return props.withShadow ? props.theme.color.shadowBlue : 'transparent';
  });
});
var StyledButton = exports.StyledButton = _styledComponents.default.button.withConfig({
  displayName: "styles__StyledButton",
  componentId: "sc-1rku6q4-1"
})(["\n  background: ", ";\n  outline: none;\n\n  ", "\n"], function (props) {
  return props.theme.color[props.bgColor];
}, function (_ref2) {
  var circle = _ref2.circle;
  return circle && (0, _styledComponents.css)(["\n      position: relative;\n      width: 60px;\n      box-shadow: 0 0 35px 0\n        ", ";\n      border-radius: 50%;\n      overflow: hidden;\n\n      &:before {\n        content: '';\n        display: block;\n        width: 100%;\n        padding-bottom: 100%;\n        pointer-events: none;\n      }\n\n      > * {\n        position: absolute;\n        width: 50%;\n        top: 50%;\n        left: 50%;\n        transform: translate(-50%, -50%);\n      }\n    "], function (props) {
    return props.withShadow ? props.theme.color.shadowBlue : 'transparent';
  });
});