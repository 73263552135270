"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WrapperTel = exports.WrapperMain = exports.WrapperInfo = exports.SubTitle = exports.StyledSectionWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _styles = require("../shared/styles");
var SubTitle = exports.SubTitle = _styledComponents.default.p.withConfig({
  displayName: "styles__SubTitle",
  componentId: "sc-1or5ctf-0"
})(["\n  margin 0 0 24px 0;\n\n  color: ", ";\n  font-family: ", ";   \n  font-size: 18px;\n  font-weight: 500;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: normal;\n  letter-spacing: normal;\n  \n  ", "{    \n   text-align: center;\n  } \n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var WrapperInfo = exports.WrapperInfo = _styledComponents.default.div.withConfig({
  displayName: "styles__WrapperInfo",
  componentId: "sc-1or5ctf-1"
})(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  \n  ", "{\n    margin-right:20px;\n  } \n"], function (props) {
  return props.theme.mediaQueries.desktop;
});
var WrapperTel = exports.WrapperTel = _styledComponents.default.div.withConfig({
  displayName: "styles__WrapperTel",
  componentId: "sc-1or5ctf-2"
})(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  flex: 1;\n  \n  svg {\n    margin: 10px 0; \n  } \n  \n  margin-top: 50px;\n  \n  ", "{\n    margin-top:0px;\n  } \n   \n  ", "{\n    margin-left:20px;\n  } \n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var WrapperMain = exports.WrapperMain = _styledComponents.default.div.withConfig({
  displayName: "styles__WrapperMain",
  componentId: "sc-1or5ctf-3"
})(["\n  display: flex;\n  flex-direction: column;\n  \n  ", "{\n  flex-direction: row;\n  }  \n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledSectionWrapper = exports.StyledSectionWrapper = (0, _styledComponents.default)(_styles.WrapperSectionWithTitle).withConfig({
  displayName: "styles__StyledSectionWrapper",
  componentId: "sc-1or5ctf-4"
})(["\nmargin:80px 0;\n  padding: 0 10px;  \n   padding-bottom:20px;\n  ", "{\n    padding: 0 55px;\n     padding-bottom:40px;\n  }  \n  ", "{  \n    padding: 0 170px; \n    padding-bottom:40px;\n  }  \n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});