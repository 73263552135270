import React, { Component } from "react"

const withTracking = EnhancedComponent => {
  return class WithTracking extends Component {
    constructor(props) {
      super(props)

      this.handleTracking = this.handleTracking.bind(this)
      this.getTrackingType = this.getTrackingType.bind(this)
      this.getTrackingCity = this.getTrackingCity.bind(this)
      this.getTrackingFindOffers = this.getTrackingFindOffers.bind(this)
      this.handleTrackingOffers = this.handleTrackingOffers.bind(this)
      this.handleTrackingProduct = this.handleTrackingProduct.bind(this)

      this.state = {}
    }

    static async getInitialProps(ctx) {
      let props = {}
      if (EnhancedComponent.getInitialProps) {
        props = await EnhancedComponent.getInitialProps(ctx)
      }
      return { ...props }
    }

    componentDidMount() {
      const elements = document.querySelectorAll(".js-tracking")
      const offersFormElement = document.querySelectorAll(".js-offers-form")
      const offersFormBusinessElement = document.querySelectorAll(
        ".js-offersBusiness-form"
      )
      const productContactElement = document.querySelectorAll(
        ".js-track-product"
      )

      Array.prototype.forEach.call(elements, element => {
        element.addEventListener("click", e => {
          this.handleTracking(e.target)
        })
      })

      Array.prototype.forEach.call(offersFormElement, element => {
        element.addEventListener("click", e => {
          this.handleTrackingOffers(e.target)
        })
      })

      Array.prototype.forEach.call(offersFormBusinessElement, element => {
        element.addEventListener("click", e => {
          this.handleTrackingOffers(e.target)
        })
      })

      Array.prototype.forEach.call(productContactElement, element => {
        element.addEventListener("click", e => {
          this.handleTrackingProduct(e.target)
        })
      })
    }

    handleTracking(element) {
      const type = this.getTrackingType(element.classList.value)
      if (!window.adf) {
        return false
      }
      switch (type) {
        case "loginAccedi":
          adf.ClickTrack(this, 1495908, "vivigas|modale|accedi", {})
          break
        case "loginRegistrati":
          adf.ClickTrack(this, 1495908, "vivigas|modale|registrati", {})
          break
        case "smistatoreCasaStep1Luce":
          adf.ClickTrack(this, 1495908, "vivigas|casa|step1|luce", {})
          break
        case "smistatoreCasaStep1Gas":
          adf.ClickTrack(this, 1495908, "vivigas|casa|step1|gas", {})
          break
        case "smistatoreCasaStep1LuceGas":
          adf.ClickTrack(this, 1495908, "vivigas|casa|step1|lucegas", {})
          break
        case "smistatoreCasaStep2Localita":
          adf.ClickTrack(this, 1495908, "vivigas|casa|step2|localita", {
            sv1: this.getTrackingCity(),
          })
          break
        case "offersType":
          this.getTrackingFindOffers()
          break
        case "assistenzaPMI":
          adf.ClickTrack(
            this,
            1495908,
            "vivigas|assistenza-PMI|area_clienti",
            {}
          )
          break
        case "smistatoreBusinessStep1Luce":
          adf.ClickTrack(
            this,
            1495908,
            "vivigas|business_aziende|step1|luce",
            {}
          )
          break
        case "smistatoreBusinessStep1Gas":
          adf.ClickTrack(
            this,
            1495908,
            "vivigas|business_aziende|step1|gas",
            {}
          )
          break
        case "smistatoreBusinessStep2Localita":
          adf.ClickTrack(
            this,
            1495908,
            "vivigas|business_aziende|step2|localita",
            { sv1: this.getTrackingCity() }
          )
          break
        case "smistatoreBusinessStep3Uffici":
          adf.ClickTrack(
            this,
            1495908,
            "vivigas|business_aziende|step3|professionisti_uffici",
            {}
          )
          break
        case "smistatoreBusinessStep3Negozi":
          adf.ClickTrack(
            this,
            1495908,
            "vivigas|business_aziende|step3|negozi",
            {}
          )
          break
        case "smistatoreBusinessStep3Horeca":
          adf.ClickTrack(
            this,
            1495908,
            "vivigas|business_aziende|step3|horeca",
            {}
          )
          break
        case "smistatoreBusinessStep3Min50k":
          adf.ClickTrack(
            this,
            1495908,
            "vivigas|business_aziende|step3|pmi_min_50k",
            {}
          )
          break
        case "smistatoreBusinessStep3Max50k":
          adf.ClickTrack(
            this,
            1495908,
            "vivigas|business_aziende|step3|pmi_max_50k",
            {}
          )
          break
        case "offersTypeBusiness":
          adf.ClickTrack(
            this,
            1495908,
            "vivigas|business_aziende|step4|trovaofferte",
            { sv2: this.getTrackingFindOffersBusiness() }
          )
          break
        case "pdfCondizioniEconomiche":
          adf.ClickTrack(this, 1495908, "vivigas|pdf|condizioni_economiche", {})
          break
        case "pdfSchedeConfrontabilità":
          adf.ClickTrack(
            this,
            1495908,
            "vivigas|pdf|schede_confrontabilita",
            {}
          )
          break
        case "pdfKitContrattuale":
          adf.ClickTrack(this, 1495908, "vivigas|pdf|kit_contrattuale", {})
          break
        case "tvtbRegistratiOra":
          adf.ClickTrack(this, 1495908, "vivigas|tvtb|registrati_ora", {})
          break
        case "tvtbRegolamento":
          adf.ClickTrack(this, 1495908, "vivigas|tvtb|pdf|regolamento", {})
          break
        case "pdfSRCartaQualita":
          adf.ClickTrack(
            this,
            1495908,
            "vivigas|soddisfatti-rimborsati|pdf|carta_qualita",
            {}
          )
          break
        case "pdfAncoraTiPremiaRegolamento":
          adf.ClickTrack(
            this,
            1495908,
            "vivigas|concorso-ancora-ti-premia|pdf|regolamento",
            {}
          )
          break
        case "pdfConcorsoRegolamento":
          adf.ClickTrack(this, 1495908, "vivigas|concorso|pdf|regolamento", {})
          break
        case "assistenzaCasaAreaClienti":
          adf.ClickTrack(
            this,
            1495908,
            "vivigas|assistenza-casa|area_clienti",
            {}
          )
          break
        case "assistenzaPMIAreaClienti":
          adf.ClickTrack(
            this,
            1495908,
            "vivigas|assistenza-PMI|area_clienti",
            {}
          )
          break
        case "pdfSR2019Regolamento":
          adf.ClickTrack(
            this,
            1495908,
            "vivigas|soddisfatti-rimborsati-2019|pdf|regolamento",
            {}
          )
          break
        case "SR2019IniziaSubito":
          adf.ClickTrack(
            this,
            1495908,
            "vivigas|soddisfatti-rimborsati-2019|inizia_subito",
            {}
          )
          break
        case "pdfViviarancioneCondizioni":
          adf.ClickTrack(
            this,
            1495908,
            "vivigas|viviarancione|pdf|condizioni",
            {}
          )
          break
        case "pdfViviarancionePrivacy":
          adf.ClickTrack(this, 1495908, "vivigas|viviarancione|pdf|privacy", {})
          break
        case "pdfViviarancioneRipensamento":
          adf.ClickTrack(
            this,
            1495908,
            "vivigas|viviarancione|pdf|ripensamento",
            {}
          )
          break
        case "viviarancioneCaffeScopri":
          adf.ClickTrack(
            this,
            1495908,
            "vivigas|viviarancione|caffe|scopri",
            {}
          )
          break
        case "viviarancioneViaggiScopri":
          adf.ClickTrack(
            this,
            1495908,
            "vivigas|viviarancione|viaggi|scopri",
            {}
          )
          break
      }
    }

    handleTrackingProduct(element) {
      if (!element || !element.classList || !element.classList.value)
        return false

      const classList = element.classList.value

      let type
      let arr = classList.split(" ")

      arr.map(item => {
        if (item.indexOf("productHomeContactUs-") >= 0) {
          type = item.split("-")
          adf.ClickTrack(
            this,
            1495908,
            `vivigas|prodotto|${type[1]}|contattaci`,
            {}
          )
          return true
        }

        if (item.indexOf("productHomeStores-") >= 0) {
          type = item.split("-")
          adf.ClickTrack(
            this,
            1495908,
            `vivigas|prodotto|${type[1]}|punto_vendita`,
            {}
          )
          return true
        }

        if (item.indexOf("productHomeSubscribe-") >= 0) {
          type = item.split("-")
          adf.ClickTrack(
            this,
            1495908,
            `vivigas|prodotto|${type[1]}|sottoscrivi'`,
            {}
          )
          return true
        }

        if (item.indexOf("productBusinessContactUs-") >= 0) {
          type = item.split("-")
          adf.ClickTrack(
            this,
            1495908,
            `vivigas|prodotto_aziende|${type[1]}|contattaci`,
            {}
          )
          return true
        }

        if (item.indexOf("productBusinessStores-") >= 0) {
          type = item.split("-")
          adf.ClickTrack(
            this,
            1495908,
            `vivigas|prodotto_aziende|${type[1]}|punto_vendita`,
            {}
          )
          return true
        }

        if (item.indexOf("productBusinessSubscribe-") >= 0) {
          type = item.split("-")
          adf.ClickTrack(
            this,
            1495908,
            `vivigas|prodotto_aziende|${type[1]}|sottoscrivi'`,
            {}
          )
          return true
        }
      })
    }

    handleTrackingOffers(element) {
      if (!element || !element.classList || !element.classList.value)
        return false

      const classList = element.classList.value

      if (classList.indexOf("js-offers") < 0) {
        return false
      }

      let type
      let arr = classList.split(" ")

      arr.map(item => {
        if (item.indexOf("offer-") >= 0) {
          type = item.split("-")

          adf.ClickTrack(this, 1495908, `vivigas|casa|step4|${type[1]}`, {})

          return true
        }
      })

      arr.map(item => {
        if (item.indexOf("offerBusiness-") >= 0) {
          type = item.split("-")

          adf.ClickTrack(
            this,
            1495908,
            `vivigas|business_aziende|step5|${type[1]}`,
            {}
          )

          return true
        }
      })
    }

    getTrackingFindOffers() {
      let offer
      let id
      const elements = document.querySelectorAll(
        ".track-smistatoreTrovaOfferte"
      )

      Array.prototype.forEach.call(elements, element => {
        if (typeof element.getAttribute("checked") !== "object") {
          id = element.value
        }
      })

      switch (id) {
        case "1":
          adf.ClickTrack(this, 1495908, "vivigas|casa|step3|trovaofferte", {
            sv2: "NUOVA ATTIVAZIONE",
          })
          break
        case "2":
          adf.ClickTrack(this, 1495908, "vivigas|casa|step3|trovaofferte", {
            sv2: "VOLTURA",
          })
          break
        case "3":
          adf.ClickTrack(this, 1495908, "vivigas|casa|step3|trovaofferte", {
            sv2: "SUBENTRO",
          })
          break
        case "4":
          adf.ClickTrack(this, 1495908, "vivigas|casa|step3|trovaofferte", {
            sv2: "CAMBIO FORNITORE",
          })
          break
        case "5":
          adf.ClickTrack(
            this,
            1495908,
            "vivigas|business_aziende|step4|trovaofferte",
            { sv2: "NUOVA ATTIVAZIONE" }
          )
          break
        case "6":
          adf.ClickTrack(
            this,
            1495908,
            "vivigas|business_aziende|step4|trovaofferte",
            { sv2: "CAMBIO FORNITORE" }
          )
          break
        case "7":
          adf.ClickTrack(
            this,
            1495908,
            "vivigas|business_aziende|step4|trovaofferte",
            { sv2: "SUBENTRO" }
          )
          break
        default:
          break
      }
    }

    getTrackingCity() {
      const element = document.querySelector(".js-form-location")
      return element.value
    }

    getTrackingType(classList) {
      if (classList) {
        let type
        let arr = classList.split(" ")

        arr.map(item => {
          if (item.indexOf("track-") >= 0) {
            type = item.split("-")
          }
        })

        return type[1]
      }
      return null
    }

    render() {
      return <EnhancedComponent {...this.props} />
    }
  }
}

export default withTracking
