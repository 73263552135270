"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledWarningWrapper = exports.StyledThankyouWrapper = exports.StyledThankyou = exports.StyledPageWrapper = exports.StyledLabel = exports.StyledInputWrapper = exports.StyledInfo = exports.StyledIconSmall = exports.StyledIcon = exports.StyledFormWrapper = exports.StyledError = exports.StyledContentWrapper = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
var StyledPageWrapper = exports.StyledPageWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledPageWrapper",
  componentId: "sc-x3298p-0"
})(["\n  padding: 0;\n  margin-bottom: -91px;\n"]);
var StyledFormWrapper = exports.StyledFormWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledFormWrapper",
  componentId: "sc-x3298p-1"
})(["\n  width: 90%;\n  max-width: 770px;\n  margin: 0 auto 80px auto;\n  padding: 48px 24px;\n  border-radius: 35px;\n  box-shadow: 0 10px 35px 0 rgba(81, 129, 186, 0.25);\n  background-color: #fff;\n\n  ", " {\n    padding: 56px 100px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledContentWrapper = exports.StyledContentWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContentWrapper",
  componentId: "sc-x3298p-2"
})(["\n  width: 90%;\n  max-width: 770px;\n  padding: 80px 0;\n  margin: 0 auto;\n  background-color: transparent;\n\n  ", " {\n    padding: 64px 0;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledInputWrapper = exports.StyledInputWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledInputWrapper",
  componentId: "sc-x3298p-3"
})(["\n  position: relative;\n\n  > input {\n    width: 100%;\n    padding: 25px 6px 14px;\n    -webkit-appearance: none;\n    border: none;\n    border-bottom: 1px solid ", ";\n    font-family: ", ";\n    font-size: 16px;\n    transition: all 0.2s linear 0s;\n    outline: none;\n    color: ", ";\n\n    ", " {\n      font-size: 18px;\n    }\n\n    &::placeholder {\n      color: ", ";\n      transition: color 0.2s linear 0s;\n    }\n\n    &:focus {\n      border-bottom: 1px solid ", ";\n    }\n\n    ", "\n  }\n"], function (props) {
  return props.theme.color.gray;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.color.gray;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.error && css(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n        border-bottom: 1px solid ", ";\n      "])), function (props) {
    return props.theme.color.red;
  });
});
var StyledWarningWrapper = exports.StyledWarningWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledWarningWrapper",
  componentId: "sc-x3298p-4"
})(["\n  width: 100%;\n  background-image: linear-gradient(\n    to top,\n    #f2f2f2,\n    #fff 61%,\n    #fff 27%,\n    #f2f2f2\n  );\n"]);
var StyledIcon = exports.StyledIcon = _styledComponents.default.img.withConfig({
  displayName: "styles__StyledIcon",
  componentId: "sc-x3298p-5"
})(["\n  width: 152px;\n  height: 152px;\n  margin: 0 auto;\n  display: block;\n  margin-bottom: 16px;\n"]);
var StyledIconSmall = exports.StyledIconSmall = _styledComponents.default.img.withConfig({
  displayName: "styles__StyledIconSmall",
  componentId: "sc-x3298p-6"
})(["\n  width: 65px;\n  height: 65px;\n  margin: 0 auto;\n  display: block;\n  margin-bottom: 24px;\n"]);
var StyledLabel = exports.StyledLabel = _styledComponents.default.label.withConfig({
  displayName: "styles__StyledLabel",
  componentId: "sc-x3298p-7"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n\n  ", " {\n    font-size: 16px;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledError = exports.StyledError = _styledComponents.default.span.withConfig({
  displayName: "styles__StyledError",
  componentId: "sc-x3298p-8"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 12px;\n"], function (props) {
  return props.theme.color.red;
}, function (props) {
  return props.theme.font.regular;
});
var StyledInfo = exports.StyledInfo = _styledComponents.default.span.withConfig({
  displayName: "styles__StyledInfo",
  componentId: "sc-x3298p-9"
})(["\n  float: right;\n  margin-top: 4px;\n  font-family: ", ";\n  font-size: 14px;\n  color: ", ";\n  text-decoration: underline;\n  cursor: pointer;\n\n  ", " {\n    margin: 0;\n    position: absolute;\n    top: 46px;\n    right: 0;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.magenta;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledThankyou = exports.StyledThankyou = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledThankyou",
  componentId: "sc-x3298p-10"
})(["\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  background-color: rgba(0, 0, 0, 0.8);\n  z-index: 9999;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"]);
var StyledThankyouWrapper = exports.StyledThankyouWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledThankyouWrapper",
  componentId: "sc-x3298p-11"
})(["\n  width: 100%;\n  max-width: 570px;\n  height: 395px;\n  margin: 0 24px;\n  border-radius: 35px;\n  box-shadow: 0 10px 35px 0 rgba(81, 129, 186, 0.25);\n  background-color: #fff;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n"]);