"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledText = exports.StyledPOutro = exports.StyledPIntro = exports.StyledP = exports.StyledDIVOutro = exports.StyledDIVIntro = exports.StyledDIV = exports.StyledBlockTextDiv = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledBlockTextDiv = exports.StyledBlockTextDiv = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBlockTextDiv",
  componentId: "sc-1jt2zeh-0"
})(["\n  font-family: ", ";\n  width: ", ";\n  height: ", ";\n  margin: ", ";\n  padding: ", ";\n  color: ", ";\n\n  ", ";\n\n  ", ";\n\n  ", ";\n\n  ", ";\n\n  a {\n    color: #e81c69;\n    text-decoration: underline;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.width;
}, function (props) {
  return props.height;
}, function (props) {
  return props.margin;
}, function (props) {
  return props.padding;
}, function (props) {
  return props.color;
}, function (props) {
  return props.fontSize && (0, _styledComponents.css)(["\n      font-size: ", ";\n    "], typeof props.fontSize === 'number' ? "".concat(props.fontSize, "px") : props.fontSize);
}, function (props) {
  return props.fontWeight && (0, _styledComponents.css)(["\n      font-weight: ", ";\n    "], props.fontWeight);
}, function (props) {
  return props.align && (0, _styledComponents.css)(["\n      text-align: ", ";\n    "], props.align);
}, function (props) {
  return props.lineHeight && (0, _styledComponents.css)(["\n      line-height: ", ";\n    "], props.lineHeight);
});
var StyledText = exports.StyledText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledText",
  componentId: "sc-1jt2zeh-1"
})(["\n  padding: 0 0px;\n\n  ", ";\n\n  ", ";\n\n  ", "\n\n\n  font-family: ", ";\n  font-size: 16px;\n  font-weight: normal;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: 1.5;\n  letter-spacing: normal;\n\n  @media (min-width: 768px) {\n    margin-bottom: 0px;\n  }\n\n  ", "\n\n  b {\n    font-family: ", "!important;\n    margin-bottom: 10px !important;\n    font-weight: 400;\n    display: block;\n  }\n\n  strong {\n    font-family: ", ";\n    font-weight: 400;\n  }\n\n  .highlighted {\n    width: 100%;\n    display: block;\n    color: ", ";\n    font-size: 18px;\n    margin: 60px 0;\n    text-align: center;\n\n    @media (min-width: 1440px) {\n      margin: 60px 0;\n      font-size: 20px;\n    }\n  }\n\n  ", "\n\n  ", "\n"], function (props) {
  return props.noPad && (0, _styledComponents.css)(["\n      padding: 0 35px;\n    "]);
}, function (props) {
  return props.marginTop && (0, _styledComponents.css)(["\n      margin-top: ", "px;\n    "], props.marginTop);
}, function (props) {
  if (props.color) {
    return (0, _styledComponents.css)(["\n        color: ", ";\n      "], props.color);
  } else {
    return (0, _styledComponents.css)(["\n        color: ", ";\n      "], props.theme.color.darkBlue);
  }
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.center && (0, _styledComponents.css)(["\n      text-align: center;\n    "]);
}, function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.orange;
}, function (props) {
  return props.refund && (0, _styledComponents.css)(["\n      margin: 0 auto;\n      font-family: ", ";\n      font-size: 16px;\n      text-align: left;\n      line-height: 1.2;\n\n      @media (min-width: 768px) {\n        max-width: 540px;\n        padding: 0;\n        font-size: 18px;\n        line-height: 1.2;\n      }\n\n      @media (min-width: 1440px) {\n        max-width: 770px;\n        font-size: 18px;\n        line-height: 1.2;\n      }\n    "], function (props) {
    return props.theme.font.regular;
  });
}, function (props) {
  return props.isProject && (0, _styledComponents.css)(["\n      margin: 0 auto;\n      padding: 0;\n\n      @media (min-width: 768px) {\n        max-width: 540px;\n      }\n\n      @media (min-width: 1440px) {\n        max-width: 770px;\n      }\n    "]);
});
var StyledP = exports.StyledP = _styledComponents.default.p.withConfig({
  displayName: "styles__StyledP",
  componentId: "sc-1jt2zeh-2"
})(["\n  margin-top: 30px;\n  margin-bottom: 0px;\n  line-height: 18 px;\n  padding: ", " b, strong {\n    display: inline-block;\n    font-family: ", ";\n  }\n  ", "\n"], function (props) {
  return props.padder ? '30px 0px;' : '0;';
}, function (props) {
  return props.theme.font.bold;
}, function (props) {
  return props.center && (0, _styledComponents.css)(["\n      text-align: center;\n    "]);
});
var StyledDIV = exports.StyledDIV = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDIV",
  componentId: "sc-1jt2zeh-3"
})(["\n  margin-top: 30px;\n  margin-bottom: 0px;\n  line-height: 23px;\n  font-family: ", ";\n  padding: ", " b, strong {\n    display: inline-block;\n    font-family: ", ";\n  }\n  p {\n    margin-bottom: 0;\n  }\n  ", "\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.padder ? '30px 0px;' : '0;';
}, function (props) {
  return props.theme.font.bold;
}, function (props) {
  return props.center && (0, _styledComponents.css)(["\n      text-align: center;\n    "]);
});
var StyledPIntro = exports.StyledPIntro = _styledComponents.default.p.withConfig({
  displayName: "styles__StyledPIntro",
  componentId: "sc-1jt2zeh-4"
})(["\n  margin-top: 0px;\n  margin-bottom: 30px;\n  font-size: 18px;\n  line-height: 28px;\n  padding: ", ";\n  padding-top: 0;\n  b {\n    display: inline-block;\n  }\n  ", "\n"], function (props) {
  return props.padder ? '20px 0px' : '0';
}, function (props) {
  return props.center && (0, _styledComponents.css)(["\n      text-align: center;\n    "]);
});
var StyledDIVIntro = exports.StyledDIVIntro = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDIVIntro",
  componentId: "sc-1jt2zeh-5"
})(["\n  margin-top: 0px;\n  margin-bottom: 30px;\n  font-size: 18px;\n  line-height: 28px;\n  padding: ", ";\n  padding-top: 0;\n\n  small {\n    font-size: 12px !important;\n  }\n  b,\n  strong {\n    font-family: ", ";\n    font-weight: 400;\n    ", "\n  }\n  h1 {\n    font-size: 35px;\n    margin: 0em 0;\n    line-height: 1.1em;\n    font-weight: 400;\n    font-family: ", ";\n    @media (max-width: 768px) {\n      font-size: 25px;\n    }\n  }\n  h2 {\n    font-size: 35px;\n    margin: 0em 0;\n    line-height: 1.1em;\n    font-weight: 400;\n    font-family: ", ";\n  }\n  ", "\n  @media (max-width:767px) {\n    padding-left: 20px;\n  }\n"], function (props) {
  return props.padder ? '20px 0px' : '0';
}, function (props) {
  return props.theme.font.bold;
}, function (props) {
  return props.colorBold && (0, _styledComponents.css)(["\n        color: #d7007a;\n      "]);
}, function (props) {
  return props.theme.font.bold;
}, function (props) {
  return props.theme.font.light;
}, function (props) {
  return props.center && (0, _styledComponents.css)(["\n      text-align: center;\n    "]);
});
var StyledPOutro = exports.StyledPOutro = _styledComponents.default.p.withConfig({
  displayName: "styles__StyledPOutro",
  componentId: "sc-1jt2zeh-6"
})(["\n  margin-top: 30px;\n  margin-bottom: 30px;\n  line-height: 18px;\n  padding: ", " b {\n    display: inline-block;\n  }\n  ", "\n"], function (props) {
  return props.padder ? '30px 0px;' : '0;';
}, function (props) {
  return props.center && (0, _styledComponents.css)(["\n      text-align: center;\n    "]);
});
var StyledDIVOutro = exports.StyledDIVOutro = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDIVOutro",
  componentId: "sc-1jt2zeh-7"
})(["\n  margin-top: 30px;\n  margin-bottom: 30px;\n  line-height: 18px;\n  padding: ", " b {\n    display: inline-block;\n  }\n  ", "\n  ", "\n"], function (props) {
  return props.padder ? '30px 0px;' : '0;';
}, function (props) {
  return props.colorBold && (0, _styledComponents.css)(["\n      color: #d7007a;\n    "]);
}, function (props) {
  return props.center && (0, _styledComponents.css)(["\n      text-align: center;\n    "]);
});