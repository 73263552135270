import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'
import * as SVGIcon from '@alkemy/components/lib/components/Icons/SVG'
import * as SVGIconIllustrate from '@alkemy/components/lib/components/Icons/illustrate'
import { Icons } from '@alkemy/components'
import styled from 'styled-components'

import { ProductPageCards, withWrapper } from '@alkemy/components'

const iconOptionsOld = Object.keys(SVGIcon).map(item => `${item}:${item}`)
const iconOptionsNew = Object.keys(SVGIconIllustrate).map(
  item => `${item}:${item}`,
)

const IconWrapper = styled.div`
  width: 35px;
  height: 35px;
  padding: 5px;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 20px;
  flex: 0 35px;
  svg {
    width: 100% !important;
    height: 100% !important;
  }
  > div {
    width: 100% !important;
    height: 100% !important;
    > svg {
      width: 100% !important;
      height: 100% !important;
    }
    > div {
      > svg {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
`
const OptionContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  > span {
    flex: 1;
  }
`

const optionsComponent = props => (
  <OptionContainer style={{ height: '40px' }}>
    <IconWrapper>
      <Icons icon={props.data.value} style={{ width: '100%' }} color="#000" />
    </IconWrapper>
    <span>{props.data.label}</span>
  </OptionContainer>
)

const PageCards = props => {
  let cards = []
  if (props.cards) {
    cards = props.cards.map(card => {
      if (card.link && card.linkLabel) {
        card.goto = {
          href: card.link,
          label: card.linkLabel,
        }
      }

      return card
    })
  }

  return <ProductPageCards {...props} cards={cards} />
}

export default compose(
  withPageBuilder({
    niceName: 'Product Page Cards',
  }),
  withFields([
    {
      prop: 'tabId',
      label: 'Tab ID',
    },
    {
      prop: 'active',
      label: 'Active',
      type: 'boolean',
    },
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'backgroundShape',
      type: 'boolean',
      label: 'Background Shape',
    },
    {
      prop: 'cards',
      label: 'Cards',
      type: 'repeater',
      model: [
        {
          prop: 'title',
          label: 'titolo',
        },
        {
          prop: 'text',
          label: 'testo',
          type: 'WYSIWYG',
        },
        {
          prop: 'link',
          label: 'link',
        },
        {
          prop: 'linkLabel',
          label: 'label link',
        },
        {
          prop: 'icon',
          type: 'select',
          label: 'Icona',
          optionsComponent,
          options: [...iconOptionsNew, ...iconOptionsOld],
          initialValue: 'Gas',
        },
        {
          prop: 'img',
          type: 'multiple',
          label: 'Icona Immagine',
          options: [
            '/static/images/icons/autoritaIstituzioni.png:Autorita Istituzioni',
            '/static/images/icons/azionisti.png:Azionisti',
            '/static/images/icons/banche.png:Banche',
            '/static/images/icons/dipendenti.png:Dipendenti',
            '/static/images/icons/fornitori.png:Fornitori',
            '/static/images/icons/generazioniFuture.png:Generazioni Future',
            '/static/images/icons/bimestrale.svg:Calendario',
            '/static/images/icons/trustworthy.svg:Trust',
            '/static/images/icons/evening.svg:Sera',
            '/static/images/icons/prezzo-bloccato.svg:Prezzo Bloccato',
            '/static/images/icons/monoraria.svg:Monoraria',
            '/static/images/icons/single-bill.svg:Fattura Singola',
            '/static/images/icons/numVerdeIcon.png:NumeroVerde',
            '/static/images/icons/soddisfatti-o-rimborsati.svg:Sodd. o Rimb.',
            '/static/images/icons/bolletta_online.svg:Bolletta Online',
            '/static/images/icons/appDownload.svg:Download App - Line',
            '/static/images/icons/register.svg:Registrati - Line',
            '/static/images/icons/click.svg:Clicca - Line',
            '/static/images/icons/clickSatispay.svg:Satispay - Line',
            '/static/images/icons/logo-wegreen.png:Logo We Green Circle',
            '/static/images/icons/Icona_Scudetto.svg:Icona Scudetto',
            '/static/images/icons/Icona_Mano.svg:Icona Mano',
            '/static/images/icons/Icona_Operatore.svg:Icona Operatore',
            '/static/images/icons/Icona_Posta.svg:Icona Posta',
            '/static/images/icons/Icona_Privacy.svg:Icona Privacy',
            '/static/images/products/icons/prezzovariabile.png:Prezzo Variabile',
            ':Nessuna',
          ],
          initialValue: '/static/images/icons/bimestrale.svg',
        },
      ],
    },
  ]),
  withWrapper,
)(PageCards)
