"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledLaunchBlock = exports.StyledContentWrapper = exports.StyledButtonWrapper = exports.SectionWrapper = exports.SectionFullBack = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _styles = require("../SectionTitle/styles");
var _styles2 = require("../shared/styles");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var SectionFullBack = exports.SectionFullBack = (0, _styledComponents.default)(_styles2.WrapperFullBack).withConfig({
  displayName: "styles__SectionFullBack",
  componentId: "sc-s8mj8o-0"
})(["\n  //  max-width:1607px;\n  background-image: url('https://vivienergia.imgix.net/uploads/2024/01/09161249/banner_assistenza_blank-rev2.png?width=1440&auto=format');\n  background-color: transparent;\n  background-position: center 0;\n  background-repeat: no-repeat;\n  //  background-size: contain;\n"]);
var SectionWrapper = exports.SectionWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__SectionWrapper",
  componentId: "sc-s8mj8o-1"
})(["\n  ", " {\n    color: white;\n  }\n\n  display: flex;\n  flex-direction: column;\n\n  width: 100%;\n  height: 520px;\n\n  justify-content: space-around;\n  align-items: center;\n\n  padding-top: 40px;\n\n  margin-bottom: 20px;\n  ", " {\n    padding-top: 40px;\n    margin-bottom: 40px;\n    height: 520px;\n  }\n\n  ", " {\n    padding-top: 40px;\n    margin-bottom: 40px;\n    height: 520px;\n  }\n"], _styles.StyledTitle, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-s8mj8o-2"
})(["\n  width: ", ";\n  position: absolute;\n  left: 50%;\n  transform: translateX(-50%);\n  bottom: -44px;\n\n  @media (min-width: 768px) {\n    bottom: -38px;\n  }\n\n  @media (min-width: 1440px) {\n    bottom: -20px;\n  }\n\n  ", "\n\n  button {\n    font-family: ", ";\n    font-size: 16px;\n    font-weight: normal;\n    font-style: normal;\n    font-stretch: normal;\n    line-height: 42px;\n    letter-spacing: normal;\n    text-align: center;\n\n    @media (min-width: 1440px) {\n    }\n  }\n"], function (props) {
  return props.fluid ? 'calc(100% - 70px)' : '240px';
}, function (props) {
  return props.ctaWidth && (0, _styledComponents.css)(["\n      width: ", ";\n    "], function (props) {
    return props.ctaWidth;
  });
}, function (props) {
  return props.theme.font.regular;
});
var StyledContentWrapper = exports.StyledContentWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContentWrapper",
  componentId: "sc-s8mj8o-3"
})(["\n  width: 100%;\n\n  padding: 20px 32px;\n  position: relative;\n  -webkit-font-smoothing: antialiased;\n  background-color: ", ";\n\n  height: auto;\n  padding-bottom: 40px;\n\n  border-radius: 35px;\n  border-radius: 35px;\n"], function (props) {
  return props.theme.color.white;
});
var StyledLaunchBlock = exports.StyledLaunchBlock = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledLaunchBlock",
  componentId: "sc-s8mj8o-4"
})(["\n  width: 100%;\n  padding: 0 10px;\n  padding-bottom: 25px;\n  position: relative;\n\n  @media (min-width: 768px) {\n    height: 320px;\n    border-radius: 35px;\n    padding: 0 180px;\n  }\n\n  @media (min-width: 1440px) {\n    width: 100%;\n    height: 320px;\n    padding: 0 370px;\n  }\n\n  ", "\n"], function (props) {
  return props.fluid && (0, _styledComponents.css)(["\n      display: flex;\n      flex-direction: column;\n    "]);
});