"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTopText = exports.StyledSvgWrapper = exports.StyledServiceNumber = exports.StyledBottomText = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledServiceNumber = exports.StyledServiceNumber = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledServiceNumber",
  componentId: "sc-165p9f4-0"
})(["\n  width: 100%;\n  max-width: 240px;\n  text-align: center;\n  font-family: ", ";\n  font-size: ", ";\n  color: ", ";\n  -webkit-font-smoothing: antialiased;\n  text-decoration: none;\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.inFooter ? '12px' : '16px';
}, function (props) {
  return props.whiteColor ? props.theme.color.darkBlue : props.theme.color.darkBlue;
});
var StyledSvgWrapper = exports.StyledSvgWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSvgWrapper",
  componentId: "sc-165p9f4-1"
})(["\n  display: block !important;\n"]);
var StyledTopText = exports.StyledTopText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTopText",
  componentId: "sc-165p9f4-2"
})(["\n  line-height: 1.5;\n  font-family: ", ";\n  font-weight: 400;\n  font-size: 16px;\n  color: #3f5063;\n"], function (props) {
  return props.theme.font.regular;
});
var StyledBottomText = exports.StyledBottomText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBottomText",
  componentId: "sc-165p9f4-3"
})(["\n  font-family: ", ";\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 1.5;\n  color: #3f5063;\n"], function (props) {
  return props.theme.font.regular;
});