import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'
import styled, { css } from 'styled-components'

const SimpleImage = styled.img`
  width: ${props => (props.imgWith ? props.imgWith : '100%')};
  ${props =>
    props.imgWith &&
    css`
      display: block;
      position: relative;
      margin: auto;
    `}
`

import { withWrapper, withPadder } from '@alkemy/components'

export default compose(
  withPageBuilder({
    niceName: 'Immagine',
  }),
  withFields([
    {
      prop: 'wrapped',
      type: 'boolean',
      label: 'Contenuto Boxed',
    },
    {
      prop: 'paddingLeftRight',
      label: 'Larghezza Componente',
      type: 'multiple',
      options: ['200:Small', '170:Medium', '100:Large', '0:Nessuno'],
    },
    {
      prop: 'paddingTop',
      label: 'Distanza dal Top',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'paddingBottom',
      label: 'Distanza dal Bottom',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'imgWith',
      label: 'Larghezza Immagine (vuoto per auto)',
      initialValue: '930',
    },
    {
      prop: 'classValue',
      label:
        'Valore Attributo Class image-mobile or image-tablet or image-small-desktop or image-low-definition-desktop or image-hd-desktop',
      initialValue: '',
    },
    {
      prop: 'img',
      label: 'Immagine',
    },
    {
      prop: 'url',
      label: 'Link Immagine',
    },
  ]),
  withWrapper,
  withPadder,
)(({ img, imgWith, classValue, url }) => {
  let imageComponent

  if (url === '') {
    imageComponent = (
      <span>
        <SimpleImage
          src={img + (classValue ? '' : `?width=${imgWith || 930}&auto=format`)}
          url={url}
          imgWith={imgWith}
          className={classValue}
        />
      </span>
    )
  } else {
    imageComponent = (
      <a href={url} target="_blank">
        <SimpleImage
          src={img + (classValue ? '' : `?width=${imgWith || 930}&auto=format`)}
          url={url}
          imgWith={imgWith}
          className={classValue}
        />
      </a>
    )
  }

  return imageComponent
})
