"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WrapperSectionWithTitle = exports.WrapperSectionWithPadAndTitle = exports.WrapperSectionWithPad = exports.WrapperSection = exports.WrapperFullBack = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _styles = require("../SectionTitle/styles");
var WrapperFullBack = exports.WrapperFullBack = _styledComponents.default.div.withConfig({
  displayName: "styles__WrapperFullBack",
  componentId: "sc-13hmc4u-0"
})(["  \n  \n ", "{     \n    position:relative;    \n    width: 100vw;\n    left:50%;\n    transform: translateX(-50%);\n    padding: 0 calc(((100vw - 768px)/2) + 14px);\n } \n \n ", "{  \n   padding: 0 calc(((100vw - 1440px)/2) + 135px);  \n }  \n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var WrapperSection = exports.WrapperSection = _styledComponents.default.div.withConfig({
  displayName: "styles__WrapperSection",
  componentId: "sc-13hmc4u-1"
})(["\n  display: flex;\n  flex-direction: column;\n"]);
var WrapperSectionWithPad = exports.WrapperSectionWithPad = (0, _styledComponents.default)(WrapperSection).withConfig({
  displayName: "styles__WrapperSectionWithPad",
  componentId: "sc-13hmc4u-2"
})(["\n  padding: 0 10px;  \n  ", "{\n    padding: 0 100px;\n  }  \n  ", "{  \n    padding: 0 200px; \n  }  \n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var WrapperSectionWithPadAndTitle = exports.WrapperSectionWithPadAndTitle = (0, _styledComponents.default)(WrapperSectionWithPad).withConfig({
  displayName: "styles__WrapperSectionWithPadAndTitle",
  componentId: "sc-13hmc4u-3"
})([" \n  ", " {\n     margin-bottom: 24px;\n  }\n   \n  ", "{\n    ", " {\n       margin-bottom: 60px;\n    }\n  }  \n"], _styles.StyledTitle, function (props) {
  return props.theme.mediaQueries.tablet;
}, _styles.StyledTitle);
var WrapperSectionWithTitle = exports.WrapperSectionWithTitle = (0, _styledComponents.default)(WrapperSection).withConfig({
  displayName: "styles__WrapperSectionWithTitle",
  componentId: "sc-13hmc4u-4"
})([" \n  ", " {\n     margin-bottom: 24px;\n  }\n   \n  ", "{\n    ", " {\n       margin-bottom: 60px;\n    }\n  }  \n"], _styles.StyledTitle, function (props) {
  return props.theme.mediaQueries.tablet;
}, _styles.StyledTitle);