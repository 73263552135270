import React from "react"
import { Contents } from '@alkemy/common'
import {
  ProductPageCta,
} from '@alkemy/components'
import OpenModal from "./OpenModal"

 class ProductPageCtaPB extends React.Component {
   render() {

     const {discountString_down,productname,subscribeVisible,subscribeLabel,discountString_up} = this.props

     return (
       <OpenModal>
         {({ handleOpen, handleClose, openStoreLocatorModal }) => (
          <ProductPageCta
           subscribeVisible={subscribeVisible}
           subscribeLabel={subscribeLabel}
           handleStoreLocatorVisibilityToggler={openStoreLocatorModal}
           handleLoginModalOpen={handleOpen}
           productName={productname}
           discountString_up={discountString_up}
           discountString_down={discountString_down}
          />
        )}
      </OpenModal>
    )
   }

 }



export default ProductPageCtaPB
