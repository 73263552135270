import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { withWrapper } from '@alkemy/components'

import SectionAreaPrivataPuoiWithLoginModal from '../components/SectionAreaPrivataPuoiWithLoginModal'

const normalizeList = list => {
  let arr = []

  list.map(item => {
    arr.push({
      text: item,
    })
  })

  return arr
}

const BuildedLaunchBlock = props => {
  const { bkgImg, title, list, ctaUrl, ctaLabel } = props

  return (
    <SectionAreaPrivataPuoiWithLoginModal
      bkgImg={bkgImg}
      title={title}
      ctaUrl={ctaUrl}
      ctaLabel={ctaLabel}
      list={normalizeList(list)}
    />
  )
}

export default compose(
  withPageBuilder({
    niceName: 'Blocco Illustrazione e Lista',
  }),
  withFields([
    {
      prop: 'bkgImg',
      label: 'Background Image',
    },
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'list',
      type: 'repeater',
      label: 'Elenco Puntato',
    },
    {
      prop: 'ctaUrl',
      label: 'Collegamento Tasto',
    },
    {
      prop: 'ctaLabel',
      label: 'Label Tasto',
    },
  ]),
  withWrapper,
)(BuildedLaunchBlock)
