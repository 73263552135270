import React, { Component } from 'react'
import { Contents } from '@alkemy/common'
import { connect } from 'react-redux'
import * as actionsStoreLocator from '../redux/storeLocatorModal/actions'
import { getStoreLocatorModalStatus } from '../redux/storeLocatorModal/reducer'
import { StoreLocator } from '@alkemy/components'
import axios from 'axios'
import { makeOpeningDays } from './utils'

const makeStores = data => {
  let arr = []

  data.map(item => {
    const city = item.title.rendered
    const address = item.acf.location.address
    const openingDays = makeOpeningDays(item.acf.working_day_time)
    const slug = item.slug
    const lat = item.acf.location.lat
    const lng = item.acf.location.lng

    arr.push({
      city,
      address,
      openingDays,
      slug,
      lat,
      lng,
    })
  })

  return arr
}

const withStoreLocatorModal = EnhancedComponent => {
  class WithStoreLocatorModal extends Component {
    constructor(props) {
      super(props)

      this.fetchData = this.fetchData.bind(this)

      this.state = {
        stores: [],
      }
    }

    static async getInitialProps(ctx) {
      let props

      if (typeof EnhancedComponent.getInitialProps === 'function') {
        props = await EnhancedComponent.getInitialProps(ctx)
      }
      return props
    }

    componentDidMount() {
      // console.log("ISVISIBLE", this.props.isVisible);
      // this.fetchData()
    }

    fetchData() {
      let list

      axios(`${process.env.WP_API}/wp/v2/shop?per_page=40`).then(res => {
        /*list = makeList(res.data)*/
        this.setState({
          stores: makeStores(res.data),
        })
      })
    }

    render() {
      const { isVisible, closeStoreLocatorModal } = this.props
      const { stores } = this.state

      return (
        <React.Fragment>
          <EnhancedComponent {...this.props} />
          {isVisible && (
            <StoreLocator
              isModal
              info={Contents.storeLocatorInfo}
              isVisible={isVisible}
              handleStoreLocatorClose={closeStoreLocatorModal}
              appDomain={`${process.env.APP_DOMAIN}/punti-vendita/`}
              storeURL={`${process.env.WP_API}/wp/v2/shop?per_page=40`}
              handleScroller={this.props.getScrollRef}
              makeStores={makeStores}
              stores={stores}
            />
          )}
        </React.Fragment>
      )
    }
  }

  const mapStateToProps = state => ({
    isVisible: getStoreLocatorModalStatus(state) === 'OPEN',
  })

  return connect(mapStateToProps, actionsStoreLocator)(WithStoreLocatorModal)
}

export default withStoreLocatorModal
