"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledEditorialText = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledEditorialText = exports.StyledEditorialText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledEditorialText",
  componentId: "sc-q45j0f-0"
})(["\n  max-width: 300px;\n  margin: 25px auto;\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  line-height: 1.5;\n\n  @media (min-width: 768px) {\n    max-width: 540px;\n  }\n\n  @media (min-width: 1440px) {\n    max-width: 770px;\n  }\n\n  strong {\n    font-family: ", ";\n    font-size: 25px;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.font.bold;
});