"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledIframe = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledIframe = exports.StyledIframe = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIframe",
  componentId: "sc-u1v0y9-0"
})(["\n  display: block;\n  width: 100vw;\n  > iframe {\n    border: 0px;\n  }\n"]);