"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitle = exports.StyledStepsWrapper = exports.StyledSteps = exports.StyledStepText = exports.StyledStepNumber = exports.StyledStepItem = exports.StyledSmallText = exports.StyledButtonWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledSmallText = exports.StyledSmallText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSmallText",
  componentId: "sc-1ix4fan-0"
})(["\n  margin-bottom: 40px;\n  font-family: ", ";\n  font-size: 22px;\n  line-height: 1.5;\n  text-align: center;\n  \n  @media (min-width: 768px) {\n    margin-bottom: 30px;\n  }\n  \n  strong {\n    font-family: ", ";    \n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.font.medium;
});
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-1ix4fan-1"
})(["\n  margin-bottom: 40px;\n  font-family: ", ";\n  font-size: 30px;\n  text-align: center;\n  \n  @media (min-width: 768px) {\n    margin-bottom: 60px;\n    font-size: 35px;\n  }\n  \n  @media (min-width: 1440px) {\n    margin-bottom: 50px;\n    font-size: 36px;\n  }\n"], function (props) {
  return props.theme.font.medium;
});
var StyledStepsWrapper = exports.StyledStepsWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledStepsWrapper",
  componentId: "sc-1ix4fan-2"
})([""]);
var StyledStepItem = exports.StyledStepItem = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledStepItem",
  componentId: "sc-1ix4fan-3"
})(["\n  \n  @media (min-width: 768px) {\n    min-height: 42px;\n    position: relative;\n    margin-bottom: 30px;\n  }\n"]);
var StyledStepNumber = exports.StyledStepNumber = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledStepNumber",
  componentId: "sc-1ix4fan-4"
})(["\n  width: 42px;\n  height: 42px;\n  margin: 0 auto 22px ;\n  font-family: ", ";\n  font-size: 20px;\n  border: 1px solid ", ";\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  \n  @media (min-width: 768px) {\n    margin: 0;\n    position: absolute;\n    left: 0;\n    top: 0;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.green;
});
var StyledStepText = exports.StyledStepText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledStepText",
  componentId: "sc-1ix4fan-5"
})(["\n  margin-bottom: 30px;\n  font-family: ", ";\n  font-size: 16px;\n  text-align: center;\n  \n  @media (min-width: 768px) {\n    padding-top: 10px ;\n    padding-left: 75px;\n    text-align: left;\n  }\n  \n  @media (min-width: 1440px) {\n    font-size: 18px;\n  }\n  \n  strong\xA0{\n    font-family: ", ";\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.font.medium;
});
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-1ix4fan-6"
})(["\n  width: 300px;\n  margin: 85px auto 0;\n"]);
var StyledSteps = exports.StyledSteps = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSteps",
  componentId: "sc-1ix4fan-7"
})(["\n  padding: 40px 10px 50px;\n  color: ", ";\n  \n  @media (min-width: 1440px) {\n    padding: 100px 155px;\n  }\n  \n  ", "\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.isViviesco && (0, _styledComponents.css)(["\n    max-width: 310px;\n    margin: 0 auto;\n    \n    @media (min-width: 768px) {\n      max-width: 482px;\n    }\n    \n    @media (min-width: 1440px) {\n      max-width: 766px;\n      padding: 0;\n    }\n    \n    ", " {\n      @media (min-width: 768px) {\n        font-size: 26px;\n      }\n    }\n    \n    ", " {\n      text-align: left;\n      padding: 0!important;\n    }\n    \n    ", " {\n      min-height: 32px;\n      font-family: ", ";\n      position: relative;\n      vertical-align: middle;\n      padding-left: 55px;\n      \n      &:not(:last-child) {\n        margin-bottom: 20px;\n      }\n    }\n    \n    ", " {\n      width: 32px;\n      height: 32px;\n      position: absolute;\n      left: 0;\n      top: 0;\n    }\n  "], StyledTitle, StyledStepText, StyledStepItem, function (props) {
    return props.theme.font.regular;
  }, StyledStepNumber);
});