import React, { Component } from "react"

const getPathname = req => {
  if (req && req._parsedUrl && req._parsedUrl.pathname) {
    return req._parsedUrl.pathname
  }

  return window.location.pathname
}

const withPathname = EnhacedPage => {
  return class WithPathname extends Component {
    constructor(props) {
      super(props)
    }

    static async getInitialProps(ctx) {
      let pageProps

      if (typeof EnhacedPage.getInitialProps === "function") {
        pageProps = await EnhacedPage.getInitialProps(ctx)
      }

      return {
        pathname: getPathname(ctx && ctx.req),
        ...pageProps,
      }
    }

    render() {
      return <EnhacedPage {...this.props} />
    }
  }
}

export default withPathname
