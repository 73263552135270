"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitleWrapper = exports.StyledTextWrapper = exports.StyledImageWrapper = exports.StyledImage = exports.StyledContentWrapper = exports.StyledCardBlock = exports.StyledButtonWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledCardBlock = exports.StyledCardBlock = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCardBlock",
  componentId: "sc-1hibsa2-0"
})(["\n  position: relative;\n  width: 100%;\n  margin: 0 20px 24px;\n  padding-bottom: 0;\n  box-shadow: 0 0 50px 0 ", ";\n  display: flex;\n  flex-direction: column;\n  border-radius: 24px;\n  overflow: hidden;\n  transition: box-shadow 0.2s ease-in 0s;\n\n  &:hover {\n    box-shadow: 0 0 50px 0 ", ";\n  }\n\n  @media (min-width: 768px) {\n    width: calc(50% - 14px);\n    height: 336px;\n    flex-direction: row-reverse;\n    border-radius: 35px;\n    box-shadow: ", ";\n    margin: 0 0 56px;\n\n    &:nth-child(odd) {\n      margin-right: 28px;\n    }\n  }\n\n  @media (min-width: 1440px) {\n    width: calc(", " - 15px);\n\n    &:nth-child(odd) {\n      margin-right: 30px;\n    }\n  }\n"], function (props) {
  return props.theme.color.shadowBlue;
}, function (props) {
  return props.theme.color.shadowBlueHover;
}, function (props) {
  return "0 10px 50px 0 ".concat(props.theme.color.shadowBlue);
}, function (props) {
  return props.fluid ? '100%' : '50%';
});
var StyledImageWrapper = exports.StyledImageWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledImageWrapper",
  componentId: "sc-1hibsa2-1"
})(["\n  width: 100%;\n  height: 222px;\n  background-color: ", ";\n  position: relative;\n  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 126px);\n\n  @media (min-width: 768px) {\n    width: 264px;\n    height: 336px;\n    clip-path: polygon(110px 0, 100% 0, 100% 100%, 0 100%);\n    flex: 1;\n  }\n\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    object-position: center;\n  }\n"], function (props) {
  return props.theme.color.gray;
});
var StyledImage = exports.StyledImage = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledImage",
  componentId: "sc-1hibsa2-2"
})(["\n  background-image: url(", ");\n  width: 100%;\n  height: 100%;\n  background-size: cover;\n  background-position: center;\n  @media (min-width: 768px) {\n    background-image: url(", ");\n  }\n"], function (props) {
  return props.imgMobile ? props.imgMobile : props.img;
}, function (props) {
  return props.img;
});
var StyledContentWrapper = exports.StyledContentWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContentWrapper",
  componentId: "sc-1hibsa2-3"
})(["\n  padding: 0 32px 32px;\n  position: relative;\n  -webkit-font-smoothing: antialiased;\n  background-color: ", ";\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n\n  @media (min-width: 768px) {\n    padding: 40px 0 40px 40px;\n    flex: 1;\n  }\n"], function (props) {
  return props.theme.color.white;
});
var StyledTitleWrapper = exports.StyledTitleWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitleWrapper",
  componentId: "sc-1hibsa2-4"
})(["\n  margin-bottom: 16px;\n  font-family: ", ";\n  color: ", ";\n  font-size: 18px;\n  line-height: 1.44;\n\n  @media (min-width: 768px) {\n    font-size: 24px;\n    line-height: 1.5;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledTextWrapper = exports.StyledTextWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTextWrapper",
  componentId: "sc-1hibsa2-5"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 16px;\n  line-height: 1.5;\n  margin-bottom: 24px;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-1hibsa2-6"
})(["\n  margin-top: auto;\n\n  a {\n    color: ", ";\n    background-color: ", ";\n    border: 2px solid ", ";\n    font-family: ", ";\n    font-size: 16px;\n    font-weight: normal;\n    font-style: normal;\n    font-stretch: normal;\n    letter-spacing: normal;\n    text-align: center;\n    text-decoration: none;\n    display: flex;\n    align-items: center;\n    border-radius: 30px;\n    padding: 12px 26px;\n    transition: all 0.2s ease-in 0s;\n\n    &:hover {\n      color: ", ";\n      background-color: ", ";\n    }\n\n    @media (min-width: 1440px) {\n      font-size: 20px;\n      padding: 18px 32px;\n    }\n  }\n"], function (props) {
  return props.theme.color.blue;
}, function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.color.blue;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.color.blue;
});