"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledLoadMore = exports.StyledIconWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledLoadMore = exports.StyledLoadMore = _styledComponents.default.button.withConfig({
  displayName: "styles__StyledLoadMore",
  componentId: "sc-zwqdbb-0"
})(["\n  width: 100%;\n  padding: 0 0 0 20px;\n  border-radius: 35px;\n  line-height: 42px;\n  border: 2px solid ", ";\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  text-align: left;\n  position: relative;\n  cursor: pointer;\n  -webkit-appearance: none;\n  background-color: ", ";\n  \n  &:focus {\n    outline: none;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.white;
});
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-zwqdbb-1"
})(["\n  width: 30px; \n  height: 30px;\n  position: absolute;\n  top: 50%;\n  right: 15px;\n  transform: translateY(-50%);\n"]);