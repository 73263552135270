"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledBlockButtonCommon = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledBlockButtonCommon = exports.StyledBlockButtonCommon = _styledComponents.default.button.withConfig({
  displayName: "styles__StyledBlockButtonCommon",
  componentId: "sc-1dbt1xk-0"
})(["\n  font-family: ", ";\n  color: #fff;\n  font-size: 20px;\n  background: linear-gradient(\n    260deg,\n    rgb(240, 151, 36) -30%,\n    rgb(232, 28, 105) 84%\n  );\n  line-height: normal;\n  border-width: 0;\n  border-radius: 30px;\n  width: auto;\n  padding: 17px 27px 19px;\n  box-shadow: 0px 0px 20px 2px rgba(95, 11, 43, 0.3);\n  will-change: box-shadow;\n  margin: 0 auto;\n\n  &:hover {\n    cursor: pointer;\n    text-decoration: none;\n    content: '';\n    top: 0;\n    left: 0;\n    opacity: 0.75;\n    will-change: opacity;\n    transition: all 0.2s ease-in 0s;\n    @media (min-width: 768px) {\n      box-shadow: 0px 0px 20px 2px rgba(95, 11, 43, 0.3);\n    }\n    &:after {\n      background-color: 0px 0px 20px 8px rgba(95, 11, 43, 0.3);\n      opacity: 1;\n    }\n  }\n\n  ", "\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.width && (0, _styledComponents.css)(["\n      width: ", ";\n    "], function (props) {
    return props.width;
  });
});