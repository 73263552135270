import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import {
  BoxOffer,
  RoundButton,
  RoundButtonIcon,
  SmallRadio,
} from '@alkemy/components'

import { normalizeList } from '../lib/utils'

const makeOffer = ({
  acf,
  slug,
  offers_category,
  offerTags_category,
  link,
}) => ({
  list: acf && acf.bonus_list && normalizeList(acf.bonus_list),
  partners_list:
    acf && acf.partners_list && acf.partners_list.map(i => i.partner_image.url),
  title: acf && acf.sidebar_title,
  tag: [...offerTags_category],
  electricitySubtitle: acf && acf.subtitle_light,
  description: acf && acf.subtitle_description,
  gasSubtitle: acf && acf.subtitle_gas,
  sidebarTitle: acf && acf.sidebar_title,
  electricityCosts: acf && acf.light_cost,
  electricityCostsBio: acf && acf.light_cost_bio,
  gasCost: acf && acf.gas_cost,
  isBusiness: false,
  icon: acf && acf.icon,
  isGreenEnergy: acf && acf.is_green_energy,
  showSubscribe: acf && acf.show_subscribe_online,
  tipoTariffa: acf && acf.tipologia_tariffa,
  tariffaBioraria: acf && acf.tariffa_bioraria,
  labelA: acf && acf.label_fascia,
  labelB: acf && acf.label_fascia_2,
  id: acf && acf.post_id,
  classNameOpen: 'open',
  funnel_domain: process.env.FUNNEL_DOMAIN,
  cta: {
    ctaLabel: 'SCOPRI',
    ctaUrl: link,
  },
  gasCommercializationCost: acf && acf.gas_commercialization_cost,
  energyCommercializationCost: acf && acf.energy_commercialization_cost,
  energybioCommercializationCost: acf && acf.energybio_commercialization_cost,
})
const ListOfAggregators = ({ offers }) => (
  <CustomWrapper>
    <Flex>
      <Flex flow="wrap">
        {offers &&
          offers.length > 0 &&
          offers.map((offer, index) => (
            <Col>
              <BoxOffer key={index} {...makeOffer(offer)} />
            </Col>
          ))}
      </Flex>
    </Flex>
  </CustomWrapper>
)

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-flow: ${props => props.flow || 'row'};
  align-content: flex-start;
  @media (max-width: 600px) {
    justify-content: center;
  }
`
const Col = styled.div`
  flex: 1;
  padding: 0px;
  margin: auto 10px;
  max-width: 270px;
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    display: float;
    float: left;
    max-width: 50%;
    margin: auto;
    padding: 7%;
  }
`
const SidebarFiltersStyle = styled.div`
  width: 260px;
  display: flex;
  flex: 1;
  padding: 0 10px;
  flex-flow: column;
`
const Label = styled.div`
  font-family: ${props => props.theme.font.medium};
  font-weight: 400;
  color: #3f5063;
  margin-bottom: 20px;
  margin-top: 20px;
  flex: 1 0 100%;
`
const Box = styled.div`
  border-bottom: 1px solid #ddd;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
  margin-bottom: 15px;
`
const ColOfFilters = styled(Flex)`
  @media (max-width: 600px) {
    display: flex;
    position: fixed;
    background: #fff;
    width: 100vw;
    height: calc(100vh - 38px);
    z-index: 999999;
    bottom: 0;
    padding: 0 40px;
    transform: translateY(0%);
    transition: 0.3s;
    ${props =>
      !props.showFilters &&
      css`
        transform: translateY(100%);
      `}
  }
`
const CustomWrapper = styled.div`
  width: 100%;
  margin: 60px auto;
  padding: 0px;
  position: relative;

  max-width: 870px;
`
const HandleBar = styled.div`
  display: none;
  @media (max-width: 600px) {
    display: block;
    top: 0;
    position: absolute;
    width: 100%;
    font-family: ${props => props.theme.font.medium};
    font-weight: 400;
    text-align: center;
    left: 0;
    padding: 10px 0;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    background: #e81c69; /* Old browsers */
    background: -moz-linear-gradient(
      left,
      #e81c69 41%,
      #f09724 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      left,
      #e81c69 41%,
      #f09724 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to right,
      #e81c69 41%,
      #f09724 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e81c69', endColorstr='#f09724',GradientType=1 ); /* IE6-9 */
  }
`

export default ListOfAggregators
