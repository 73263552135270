import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'
import OpenModal from '../components/OpenModal'
import * as SVGIcon from '@alkemy/components/lib/components/Icons/SVG'
import * as SVGIconIllustrate from '@alkemy/components/lib/components/Icons/illustrate'
import styled from 'styled-components'
import {
  withWrapper,
  Row,
  withPadder,
  Icons,
  AdvIconsList,
} from '@alkemy/components'

const iconOptionsOld = Object.keys(SVGIcon).map(item => `${item}:${item}`)
const iconOptionsNew = Object.keys(SVGIconIllustrate).map(
  item => `${item}:${item}`,
)
const IconWrapper = styled.div`
  width: 35px;
  height: 35px;
  padding: 5px;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 20px;
  flex: 0 35px;
  svg {
    width: 100% !important;
    height: 100% !important;
  }
  > div {
    width: 100% !important;
    height: 100% !important;
    > svg {
      width: 100% !important;
      height: 100% !important;
    }
    > div {
      > svg {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
`
const OptionContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  > span {
    flex: 1;
  }
`
const optionsComponent = props => (
  <OptionContainer style={{ height: '40px' }}>
    <IconWrapper>
      <Icons icon={props.data.value} style={{ width: '100%' }} color="#000" />
    </IconWrapper>
    <span>{props.data.label}</span>
  </OptionContainer>
)

const AdvListIconsBlock = ({
  typeCompo,
  background,
  title,
  description,
  listItems,
  ctaHref,
  ctaLabel,
}) => {
  if (typeCompo === 'sales-point') {
    return (
      <Row>
        <OpenModal>
          {({ openStoreLocatorModal }) => (
            <>
              <AdvIconsList
                background={background}
                title={title}
                description={description}
                iconList={listItems}
                type={typeCompo}
                handleOnClick={openStoreLocatorModal}
                ctaLabel={ctaLabel}
              />
            </>
          )}
        </OpenModal>
      </Row>
    )
  }
  return (
    <Row>
      <AdvIconsList
        background={background}
        title={title}
        description={description}
        iconList={listItems}
        ctaLabel={ctaLabel}
        ctaHref={ctaHref}
      />
    </Row>
  )
}

export default compose(
  withPageBuilder({
    niceName: 'Icons List Block',
  }),
  withFields([
    {
      prop: 'wrapped',
      type: 'boolean',
      label: 'Contenuto Boxed',
    },
    {
      prop: 'typeCompo',
      type: 'multiple',
      options: ['sales-point:Sales Point', 'icons-list:Icons List'],
      initialValue: 'icons-list',
      label: 'Tipologia',
    },
    {
      prop: 'background',
      label: 'Background',
    },
    {
      prop: 'title',
      label: 'Title',
    },
    {
      prop: 'description',
      label: 'Description',
    },
    {
      prop: 'listItems',
      label: 'Lista con Icone',
      type: 'repeater',
      model: [
        {
          prop: 'label',
          label: 'Label',
        },
        {
          prop: 'icon',
          type: 'select',
          label: 'Icona',
          optionsComponent,
          options: [...iconOptionsNew, ...iconOptionsOld],
          initialValue: 'Gas',
        },
      ],
    },
    {
      prop: 'ctaHref',
      label: 'Href - (only Icons List)',
    },
    {
      prop: 'ctaLabel',
      label: 'Link Label',
    },
  ]),
  withWrapper,
  withPadder,
)(AdvListIconsBlock)
