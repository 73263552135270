"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledHeaderUserAction = exports.StyledHeaderNav = exports.StyledHeaderLogo = exports.StyledHeaderHamburgerBlob = exports.StyledHeader = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledHeader = exports.StyledHeader = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledHeader",
  componentId: "sc-1pnnld0-0"
})(["\n  position: ", ";\n  width: 100vw;\n\n  min-height: 80px;\n  max-height: 200px;\n  z-index: 1000;\n  height: 80px;\n  top: 0;\n  left: 0;\n  transition: all 0.6s linear 0s;\n  pointer-events: none;\n  &:before {\n    content: '';\n    display: block;\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n    background-color: ", ";\n    pointer-events: none;\n    transition: all 0.4s linear 0s;\n  }\n  @media (max-width: 1440px) {\n    width: 100vw;\n  }\n  @media (min-width: 1920px) {\n    top: 0;\n    left: 50%;\n    max-width: 1920px;\n    transform: translateX(-50%);\n  }\n"], function (props) {
  return props.stickTopPage ? 'relative' : 'fixed';
}, function (_ref) {
  var isSticky = _ref.isSticky;
  return isSticky ? 'white' : 'transparent';
});
var StyledHeaderLogo = exports.StyledHeaderLogo = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledHeaderLogo",
  componentId: "sc-1pnnld0-1"
})(["\n  position: absolute;\n  top: 0;\n  left: 20px;\n  transition: all 0.4s linear 0s;\n  pointer-events: all;\n  transform: translate(\n      ", "px,\n      ", "px\n    )\n    scale(", ");\n  transform-origin: 0;\n  z-index: 9;\n\n  @media (min-width: 1440px) {\n    left: ", ";\n    transform: translate(\n        ", ",\n        ", "px\n      )\n      scale(", ");\n  }\n\n  @media (min-width: 1440px) {\n    left: ", ";\n  }\n\n  ", " {\n    left: ", ";\n    transform: translate(\n        ", ",\n        ", "px\n      )\n      scale(", ");\n  }\n"], function (props) {
  return props.isMenuOpen ? -10 : 0;
}, function (props) {
  return props.isMenuOpen ? -10 : 0;
}, function (props) {
  return props.isMenuOpen ? 0.4 : 0.6;
}, function (props) {
  return props.isHome ? '135px' : '0';
}, function (_ref2) {
  var isSticky = _ref2.isSticky;
  return !isSticky ? '0' : '0';
}, function (_ref3) {
  var isSticky = _ref3.isSticky;
  return !isSticky ? 36 : -10;
}, function (_ref4) {
  var isSticky = _ref4.isSticky;
  return !isSticky ? 1 : 0.5;
}, function (props) {
  return props.isHome ? '250px' : '100px';
}, function (props) {
  return props.theme.mediaQueries.small;
}, function (props) {
  return props.isHome ? '135px' : '0';
}, function (_ref5) {
  var isSticky = _ref5.isSticky;
  return !isSticky ? '0' : '135px';
}, function (_ref6) {
  var isSticky = _ref6.isSticky;
  return !isSticky ? 36 : -10;
}, function (_ref7) {
  var isSticky = _ref7.isSticky;
  return !isSticky ? 1 : 0.5;
});
var StyledHeaderHamburgerBlob = exports.StyledHeaderHamburgerBlob = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledHeaderHamburgerBlob",
  componentId: "sc-1pnnld0-2"
})(["\n  position: absolute;\n  top: 0;\n  right: 0;\n  transform: translate(50%, -50%);\n\n  ", " {\n    > canvas {\n      position: relative;\n    }\n  }\n\n  ", " {\n    display: none;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.ipad;
});
var StyledHeaderUserAction = exports.StyledHeaderUserAction = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledHeaderUserAction",
  componentId: "sc-1pnnld0-3"
})(["\n  display: none;\n  position: absolute;\n  top: ", "px;\n  right: 0;\n  transition: all 0.4s linear 0s;\n  pointer-events: all;\n\n  ", " {\n    display: block;\n  }\n\n  > canvas {\n    display: block;\n    position: absolute;\n    top: 0;\n    right: 0;\n    transform: translate(25%, -30%);\n    z-index: 0;\n  }\n"], function (_ref8) {
  var isSticky = _ref8.isSticky;
  return !isSticky ? 66 : 10;
}, function (props) {
  return props.theme.mediaQueries.ipad;
});
var StyledHeaderNav = exports.StyledHeaderNav = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledHeaderNav",
  componentId: "sc-1pnnld0-4"
})(["\n  position: absolute;\n  width: 100%;\n  min-height: 100vh;\n  max-height: 100%;\n  top: 0;\n  left: 0;\n  transform: ", ";\n  transition: all 0.4s linear ", "s;\n  background: ", ";\n  border-radius: ", "%;\n  z-index: 2;\n\n  > * {\n    opacity: 1;\n    transition: all 0.4s linear ", "s;\n\n    ", " {\n      opacity: 1;\n    }\n  }\n\n  ", " {\n    min-height: 0;\n    max-height: none;\n    top: 0;\n    left: 50%;\n    transform: translate(-50%, ", "px);\n    transition: all 0.4s linear 0s;\n    opacity: 1;\n    background: transparent;\n    border-radius: 0;\n  }\n"], function (props) {
  return props.isVisible ? 'translate(0, 0)' : 'translate(80%, -90%)';
}, function (props) {
  return props.isVisible ? 0 : 0.5;
}, function (props) {
  return props.isVisible ? props.theme.color.pinkOrange : 'transparent';
}, function (props) {
  return props.isVisible ? 0 : 15;
}, function (props) {
  return props.isVisible ? 0.5 : 0;
}, function (props) {
  return props.theme.mediaQueries.ipad;
}, function (props) {
  return props.theme.mediaQueries.ipad;
}, function (_ref9) {
  var isSticky = _ref9.isSticky;
  return !isSticky ? 36 : -10;
});