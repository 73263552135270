"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SmallRadio = exports.RoundCheck = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var SmallRadio = exports.SmallRadio = _styledComponents.default.div.withConfig({
  displayName: "styles__SmallRadio",
  componentId: "sc-lpoyi8-0"
})(["\n  padding: 8px 5px;\n  font-family: ", ";\n  font-weight: 400;\n  color: #3f5063;\n"], function (props) {
  return props.theme.font.regular;
});
var RoundCheck = exports.RoundCheck = _styledComponents.default.div.withConfig({
  displayName: "styles__RoundCheck",
  componentId: "sc-lpoyi8-1"
})(["\n  width: 20px;\n  height: 20px;\n  border-radius: 20px;\n  padding: 0;\n  margin: 0;\n  margin-right: 15px;\n  position: relative;\n  float: left;\n  border: ", ";\n  background-color: ", ";\n"], function (props) {
  return props.active ? "" : "2px solid #E81C69";
}, function (props) {
  return props.active ? "#E81C69" : "#fff";
});