import React, {useContext} from 'react'
import {compose} from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'
import Sticky from 'react-stickynode'
import {SmallHeroBanner, Social} from '@alkemy/components'
import {ResizeContext} from '../lib/withResizeProvider'
import {MeasuresContext} from '../lib/withMeasureProvider'

const HeroBannerWithSticky = props => {
  const resize = useContext(ResizeContext)
  const globalMeasures = useContext(MeasuresContext)
  const {innerWidth} = resize
  const {headerHeight} = globalMeasures && globalMeasures.measures

  return (
    <React.Fragment>
      <SmallHeroBanner
        {...props}
        innerWidth={innerWidth}
        headerHeight={headerHeight}
      />
      {props.stickyShare && (
        <Sticky enabled top={200} bottomBoundary="#socialEnd">
          <div style={{position: 'absolute', right: 0, paddingRight: '40px'}}>
            <Social title="Titolo" />
          </div>
        </Sticky>
      )}
    </React.Fragment>
  )
}

export default compose(
  withPageBuilder({
    niceName: 'Hero Banner - Small',
  }),
  withFields([
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'withBlob',
      type: 'boolean',
      label: 'Inserisci Blob',
    },
    {
      prop: 'icon',
      type: 'multiple',
      label: 'Icona',
      options: ['Gas:Gas', 'GasPlug:Gas&Luce', 'OrionPlugWhite:Plug'],
      initialValue: 'Gas',
    },
    {
      prop: 'text_color',
      type: 'multiple',
      label: 'Colore Titolo',
      options: ['darkText:Testo Scuro', 'lightText:Testo Chiaro'],
      initialValue: 'lightText',
    },
    {
      prop: 'stickyShare',
      type: 'boolean',
      label: 'Condivisione Social',
    },
    {
      prop: 'blobColors',
      type: 'multiple',
      label: 'Colore Blob',
      options: [
        {
          label: 'Arancione',
          value: [{text: '#f49864'}, {text: '#f6b624'}],
        },
        {
          label: 'Blue',
          value: [{text: '#7E2889'}, {text: '#1587C8'}],
        },
        {
          label: 'Verde',
          value: [{text: '#6EC18A'}, {text: '#0E89CA'}],
        },
      ],
      getInputValue: value =>
        value && value.length > 0 && value.map(({text}) => text).join('|'),
      getPropValue: value => value && value.split('|').map(x => ({text: x})),
    },
  ]),
)(HeroBannerWithSticky)
