"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTextWrapper = exports.StyledInfographicItem = exports.StyledGradientTextWrapper = exports.StyledCircleWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledInfographicItem = exports.StyledInfographicItem = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledInfographicItem",
  componentId: "sc-erukmw-0"
})(["\n  width: 150px;\n  height: 150px;\n  margin: 0 auto 45px;\n  position: relative;\n  border: 4px solid ", ";\n  border-radius: 50%;\n"], function (props) {
  return props.theme.color.gray;
});
var StyledCircleWrapper = exports.StyledCircleWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCircleWrapper",
  componentId: "sc-erukmw-1"
})(["\n  width: 150px;\n  height: 150px;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n\n  circle {\n    stroke-dashoffset: 459.673;\n    stroke-dasharray: 459.673px, 459.673px;\n  }\n"]);
var StyledGradientTextWrapper = exports.StyledGradientTextWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledGradientTextWrapper",
  componentId: "sc-erukmw-2"
})(["\n  position: absolute;\n  width: 100%;\n  top: 50%;\n  transform: translateY(-50%);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-family: ", ";\n  font-size: 26px;\n"], function (props) {
  return props.theme.font.medium;
});
var StyledTextWrapper = exports.StyledTextWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTextWrapper",
  componentId: "sc-erukmw-3"
})(["\n  width: 100%;\n  text-align: center;\n  font-family: ", ";\n  color: ", ";\n  font-size: 22px;\n  position: absolute;\n  left: 50%;\n  bottom: -40px;\n  top: 155px;\n  transform: translateX(-50%);\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});