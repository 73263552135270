"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledHeroContent = exports.StyledHero = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledHero = exports.StyledHero = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledHero",
  componentId: "sc-b7inuh-0"
})(["\n  position: relative;\n  width: 100%;\n  min-height: 100vh;\n  height: 100%;\n  padding-top: 80px;\n  transition: all 0.4s linear 0s;\n\n  ", " {\n    margin-top: 0px;\n    padding-top: 120px;\n    min-height: 0;\n  }\n  ", " {\n    margin-top: 0px;\n    padding-top: 120px;\n  }\n\n  ", " {\n    margin-top: 20px;\n    height: 430;\n    min-height: auto;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.ipad;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var StyledHeroContent = exports.StyledHeroContent = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledHeroContent",
  componentId: "sc-b7inuh-1"
})(["\n  padding-top: 0px;\n  margin-top: -10px;\n"]);