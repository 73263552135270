"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledStickyElement = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledStickyElement = exports.StyledStickyElement = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledStickyElement",
  componentId: "sc-r3bt1i-0"
})(["\n  padding: 80px 0 40px;\n  position: absolute;\n  left: 0;\n  top: 0;\n  z-index: 1;\n  \n  @media(min-width: 768px) {\n    left: auto;\n    right: ", ";\n  }\n  \n  @media(min-width: 1440px) {\n    right: ", ";\n  }\n  \n  ", "\n  \n  ", "\n  \n  ", "\n"], function (props) {
  return props.unwrapped ? '14px' : '-100px';
}, function (props) {
  return props.unwrapped ? '155px' : '-180px';
}, function (props) {
  return props.isSticky === 'sticky' && (0, _styledComponents.css)(["\n    \n    position: fixed;\n    top: 140px;\n    \n    @media(min-width: 768px) {\n      right: 14px;\n    }\n    \n    @media(min-width: 1440px) {\n      right: 155px;\n    }\n  "]);
}, function (props) {
  return !isNaN(props.isSticky) && (0, _styledComponents.css)(["\n    \n    position: absolute;\n    top: initial;\n    \n    @media(min-width: 768px) {\n      right: ", ";\n      bottom: ", "px;\n    }\n    \n    @media(min-width: 1440px) {\n      right: ", ";\n      bottom: ", "px;\n    }\n  "], function (props) {
    return props.unwrapped ? '14px' : '-100px';
  }, props.isSticky, function (props) {
    return props.unwrapped ? '155px' : '-180px';
  }, props.isSticky);
}, function (props) {
  return props.unwrapped && (0, _styledComponents.css)(["\n    padding: 0;\n    background-color: ", ";\n    border-radius: 15px;\n    \n    @media(min-width: 768px) {\n      padding: 80px 0 40px;\n      background-color: transparent;\n      border-radius: 0;\n    }\n  "], function (props) {
    return props.theme.color.white;
  });
});