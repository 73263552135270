"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PreNoteDiv = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var PreNoteDiv = exports.PreNoteDiv = _styledComponents.default.div.withConfig({
  displayName: "styles__PreNoteDiv",
  componentId: "sc-v2ie8a-0"
})(["\n  padding: 25px 50px;\n  color: #3f5063;\n  font-family: ", ";\n  font-size: 28px;\n  line-height: 28px;\n  display: block;\n  text-align: center;\n  a {\n    color: #e81c69;\n    text-decoration: none;\n  }\n"], function (props) {
  return props.theme.font.regular;
});