"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledScroller = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledScroller = exports.StyledScroller = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledScroller",
  componentId: "sc-j89yv8-0"
})(["\n  position: absolute;\n  width: 100vw;\n  min-height: 100vh;\n  max-height: 100%;\n  top: 0;\n  left: 0;\n  margin: 0;\n  padding: 0;\n  overflow-x: hidden;\n  overflow-y: scroll;\n  -webkit-overflow-scrolling: touch;\n"]);