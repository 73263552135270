"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledAccordionFaqList = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledAccordionFaqList = exports.StyledAccordionFaqList = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledAccordionFaqList",
  componentId: "sc-4hft2-0"
})(["\n  max-width: 300px;\n  margin: 0 auto;\n\n  @media (min-width: 768px) {\n    max-width: 630px;\n  }\n\n  p {\n    padding: 0 !important;\n  }\n"]);