"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledWorkWithUsSliderItemWrapper = exports.StyledWorkWithUsBlockList = exports.StyledTitle = exports.StyledPrevArrow = exports.StyledNextArrow = exports.StyledLoadMoreWrapper = exports.StyledItemsFlexWrapper = exports.StyledIconWrapper = exports.StyledDesc = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-1y5rw9i-0"
})(["\n  margin-bottom: 10px;\n  text-align: center;\n  font-size: 22px;\n  line-height: 22px;\n  font-family: ", ";\n"], function (props) {
  return props.theme.font.medium;
});
var StyledDesc = exports.StyledDesc = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDesc",
  componentId: "sc-1y5rw9i-1"
})(["\n  text-align: center;\n  font-size: 18px;\n  line-height: 32px;\n  font-family: ", ";\n"], function (props) {
  return props.theme.font.regular;
});
var StyledPrevArrow = exports.StyledPrevArrow = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledPrevArrow",
  componentId: "sc-1y5rw9i-2"
})(["\n  width: 44px;\n  height: 44px;\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  left: -55px;\n  cursor: pointer;\n"]);
var StyledNextArrow = exports.StyledNextArrow = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledNextArrow",
  componentId: "sc-1y5rw9i-3"
})(["\n  width: 44px;\n  height: 44px;\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  right: -55px;\n  cursor: pointer;\n"]);
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-1y5rw9i-4"
})([""]);
var StyledWorkWithUsSliderItemWrapper = exports.StyledWorkWithUsSliderItemWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledWorkWithUsSliderItemWrapper",
  componentId: "sc-1y5rw9i-5"
})(["\n  @media (max-width: 1024px) {\n    margin: auto 5px;\n  }\n"]);
var StyledItemsFlexWrapper = exports.StyledItemsFlexWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledItemsFlexWrapper",
  componentId: "sc-1y5rw9i-6"
})(["\n  max-width: 710px;\n  margin: 0 auto;\n  padding-top: 40px;\n  padding-bottom: 40px;\n  overflow: hidden;\n\n  @media (min-width: 768px) {\n    max-width: 730px;\n    padding-left: 10px;\n    padding-right: 10px;\n  }\n\n  @media (min-width: 1024px) {\n    max-width: 890px;\n  }\n\n  @media (min-width: 1280px) {\n    max-width: 1190px;\n  }\n"]);
var StyledLoadMoreWrapper = exports.StyledLoadMoreWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledLoadMoreWrapper",
  componentId: "sc-1y5rw9i-7"
})(["\n  max-width: 170px;\n  margin: 25px auto;\n"]);
var StyledWorkWithUsBlockList = exports.StyledWorkWithUsBlockList = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledWorkWithUsBlockList",
  componentId: "sc-1y5rw9i-8"
})(["\n  color: ", ";\n  max-width: 1190px;\n  margin: 0 auto;\n\n  ", "\n\n  .slick-track {\n    margin: 0 auto;\n  }\n\n  .slick-dots {\n    z-index: 100;\n\n    li {\n      width: 12px;\n      height: 12px;\n      margin: 0;\n      border-radius: 50%;\n      border: 2px solid ", ";\n      position: relative;\n\n      &:before {\n        content: '';\n        width: 100%;\n        height: 100%;\n        position: absolute;\n        top: 50%;\n        left: 50%;\n        background-color: ", ";\n        transition: all 0.35s ease-in-out 0s;\n        transform: translate(-50%, -50%) scale(0);\n        border-radius: 50%;\n      }\n\n      &.slick-active {\n        &:before {\n          transform: translate(-50%, -50%) scale(1);\n        }\n      }\n\n      &:not(:last-child) {\n        margin-right: 5px;\n\n        @media (min-width: 768px) {\n          margin-right: 8px;\n        }\n      }\n    }\n\n    button {\n      position: absolute;\n      top: 0;\n      left: 0;\n      padding: 0;\n      width: 100%;\n      height: 100%;\n\n      &:before {\n        display: none;\n      }\n    }\n  }\n\n  .slick-slide {\n    padding: 40px 0;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.hasSlider && (0, _styledComponents.css)(["\n      padding: 40px 0 60px;\n    "]);
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.color.darkBlue;
});