import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { ListWithRoundImgPMI, withWrapper } from '@alkemy/components'

export default compose(
  withPageBuilder({
    niceName: 'Blocco Prodotti PMI',
  }),
  withFields([
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'img',
      label: 'Immagine blocco 1',
    },
    {
      prop: 'ColText',
      label: 'Testo Colonna 1',
      type: 'WYSIWYG',
    },
    {
      prop: 'ColText2',
      label: 'Testo Colonna 2',
      type: 'WYSIWYG',
    },
    {
      prop: 'img2',
      label: 'Immagine 2 blocco',
    },
  ]),
  withWrapper,
)(ListWithRoundImgPMI)
