import React, { useState, useEffect, useContext } from 'react'
import _ from 'lodash'
import axios from 'axios'
import styled from 'styled-components'
import { SectionFaqs } from '@alkemy/components'
import { SlugContext } from '../lib/withSlug'

const FaqWrapper = props => {
  const [faqs, setFaqs] = useState([])
  const slugContext = useContext(SlugContext)
  const { hideTitles, showAnchorList, firstNotSelected } = props

  useEffect(() => {
    if (faqs.length > 0) return false
    checkAndRetreiveFaq({ faqs, setFaqs, slugContext })
    return () => {}
  }, [])

  const hideTitlesIfRequired = faqGroup => {
    if (!hideTitles) return faqGroup
    return {
      groupName: null,
      childs: faqGroup.childs,
    }
  }

  return (
    <React.Fragment>
      <Wrapper>
        {showAnchorList &&
          faqs.map(({ groupName }) => (
            <RoundButton href={'#' + groupName}>
              <span>{groupName}</span>
            </RoundButton>
          ))}
      </Wrapper>
      {faqs && faqs.length > 0 && (
        <SectionFaqs
          items={faqs.map(hideTitlesIfRequired)}
          firstNotSelected={firstNotSelected}
        />
      )}
    </React.Fragment>
  )
}

const makeFaq = ({ title, content, faq_category }) => ({
  title: title && title.rendered,
  text: content && content.rendered,
  faq_category: faq_category && faq_category[0],
})

const filterByFaqCat = (faq, faqCategory) => {
  return faq.faq_category.indexOf(faqCategory.term_id) !== -1
}

const gatherPageData = async slug => {
  const url = `${process.env.WP_API}/msr/v1/slug/it/?slug=${slug}`
  try {
    const data = await axios.get(url).then(i => i.data)
    return data
  } catch (e) {
    return undefined
  }
}

const getFaqs = async categories => {
  const url = `${process.env.WP_API}/wp/v2/faq?filter[faq_category]=${categories}&per_page=100`
  try {
    const data = await axios.get(url).then(i => i.data)
    return data
  } catch (e) {
    return undefined
  }
}

const checkAndRetreiveFaq = async ({ faq, setFaqs, slugContext }) => {
  if (window && window.location.pathname) {
    // await wpdata
    const wpData = await gatherPageData(slugContext && slugContext.slug)
    // se ci sono faq
    if (wpData && wpData.acf && wpData.acf.associazione_faq) {
      const faqCategoryList = wpData.acf.associazione_faq

      if (!faqCategoryList) return false

      const categoriesJoined =
        faqCategoryList &&
        faqCategoryList.length > 0 &&
        faqCategoryList.map(x => x.slug).join(',')

      // get delle faq CPT filtrate per taxonomy
      const faqs = await getFaqs(categoriesJoined)
      // refactor dell'oggetto
      const refactoredFaqs = () => {
        return faqCategoryList.map(categoryObj => {
          return {
            groupName: categoryObj.name,
            childs:
              faqs &&
              faqs.filter(faq => filterByFaqCat(faq, categoryObj)).map(makeFaq),
          }
        })
      }
      // setState delle faq
      setFaqs(refactoredFaqs)
    }
  } else {
    return false
  }
}

export const RoundButton = styled.a`
  padding: 5px 10px;
  margin-bottom: 31px;
  margin-right: 19px;
  background-color: #fff;
  border-radius: 47px;
  cursor: pointer;
  box-shadow: 0px 0px 20px #dbe3ef;
  text-decoration: none;
  color: #3f5063;
  font-family: ${props => props.theme.font.regular};
  flex-grow: 1;
  max-width: 33%;
  text-align: center;

  @media (max-width: 1024px) {
    width: 100%;
    margin: auto;
    margin-bottom: 8px;
    text-align: center;
    max-width: 100%;
    display: block;
  }
  font-size: 0.9em;
`
export const Wrapper = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
`
export default FaqWrapper
