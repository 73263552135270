"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitle = exports.StyledText = exports.StyledPageBlockWithBlob = exports.StyledContentWrapper = exports.StyledBlobWrapper = exports.StyledBlobText = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledPageBlockWithBlob = exports.StyledPageBlockWithBlob = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledPageBlockWithBlob",
  componentId: "sc-d18csz-0"
})(["\n  position: relative;\n  padding: 50px 10px;\n\n  @media (min-width: 1440px) {\n    display: flex;\n    flex-direction: row;\n    padding: 80px 0 95px;\n  }\n"]);
var StyledBlobWrapper = exports.StyledBlobWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBlobWrapper",
  componentId: "sc-d18csz-1"
})(["\n  position: absolute;\n  text-align: center;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  top: 290px;\n  left: 50%;\n  transform: translateX(-50%);\n\n  @media (min-width: 1440px) {\n    position: relative;\n    top: auto;\n    left: auto;\n    transform: rotate(-10deg);\n    flex-basis: 40%;\n  }\n\n  > canvas {\n    z-index: -1;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n  }\n"]);
var StyledBlobText = exports.StyledBlobText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBlobText",
  componentId: "sc-d18csz-2"
})(["\n  width: 200px;\n  color: ", ";\n  font-family: ", ";\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%) rotate(-10deg);\n  font-size: 18px;\n\n  @media (min-width: 1440px) {\n    font-size: 21px;\n  }\n\n  span {\n    font-size: 250%;\n    letter-spacing: -5px;\n    font-family: ", ";\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.font.bold;
});
var StyledContentWrapper = exports.StyledContentWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContentWrapper",
  componentId: "sc-d18csz-3"
})(["\n  @media (min-width: 1440px) {\n    display: flex;\n    flex-direction: column;\n    flex-basis: 60%;\n  }\n"]);
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-d18csz-4"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 27px;\n  margin-bottom: 290px;\n  text-align: center;\n\n  @media (min-width: 768px) {\n    font-size: 36px;\n  }\n\n  @media (min-width: 1440px) {\n    margin-bottom: 35px;\n    text-align: left;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.medium;
});
var StyledText = exports.StyledText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledText",
  componentId: "sc-d18csz-5"
})(["\n  color: ", ";\n  font-family: ", ";\n\n  * {\n    padding: 0 !important;\n    font-size: 14px !important;\n    line-height: 1.5 !important;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.regular;
});