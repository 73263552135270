import React from 'react'
import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'
import OpenModal from '../components/OpenModal'

import { SectionText, withWrapper, withPadder } from '@alkemy/components'

const makeCta = (cta, ctaLoginModalTrigger) => {
  if (typeof cta !== 'object' || cta === false) {
    return null
  }

  return Array.isArray(cta)
    ? { label: cta[0], href: cta[1], target: cta[2], ctaLoginModalTrigger }
    : { ...cta, ctaLoginModalTrigger }
}

const SectionTextWithCTA = ({
  hasCta,
  ctaLoginModalTrigger,
  cta,
  ...props
}) => {
  if (ctaLoginModalTrigger) {
    return (
      <OpenModal>
        {({ handleOpen, handleClose }) => (
          <SectionText
            handleOnClick={handleOpen}
            {...props}
            cta={makeCta(cta, ctaLoginModalTrigger)}
          />
        )}
      </OpenModal>
    )
  } else {
    return <SectionText {...props} cta={makeCta(cta, ctaLoginModalTrigger)} />
  }
}

export default compose(
  withPageBuilder(),
  withFields([
    {
      prop: 'wrapped',
      type: 'boolean',
      label: 'Contenuto Boxed',
    },
    {
      prop: 'paddingLeftRight',
      label: 'Larghezza Componente',
      type: 'multiple',
      options: ['200:Small', '170:Medium', '100:Large', '0:Nessuno'],
    },
    {
      prop: 'paddingTop',
      label: 'Distanza dal Top',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'paddingBottom',
      label: 'Distanza dal Bottom',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'biggerFont',
      type: 'boolean',
      label: 'Font Più Grande',
    },
    {
      prop: 'align',
      type: 'multiple',
      options: ['center:Centrato', 'left:Sinistra', 'right:Destra'],
      label: 'Allineamento',
    },
    {
      prop: 'redLinks',
      type: 'boolean',
      label: 'Link in rosso',
    },
    {
      prop: 'text',
      type: 'WYSIWYG',
      label: 'Paragrafo',
    },
    {
      prop: 'hasCta',
      label: 'CTA',
      type: 'boolean',
    },
    {
      prop: 'ctaLoginModalTrigger',
      label: 'Mostra login al click',
      type: 'boolean',
      condition: ({ hasCta }) => hasCta,
    },
    {
      prop: 'cta',
      type: 'cta',
      condition: ({ hasCta }) => hasCta,
    },
  ]),
  withPadder,
  withWrapper,
)(SectionTextWithCTA)
