import React, { Component } from 'react'
import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'
import axios from 'axios'
import { makeOpeningDays } from '../lib/utils'

import { StoreLocatorWrapper } from '@alkemy/components'

const makeStores = data => {
  let arr = []

  data.map(item => {
    const city = item.title.rendered
    const address = item.acf.location.address
    const openingDays = makeOpeningDays(item.acf.working_day_time)
    const slug = item.slug
    const lat = item.acf.location.lat
    const lng = item.acf.location.lng

    arr.push({
      city,
      address,
      openingDays,
      slug,
      lat,
      lng,
    })
  })

  return arr
}

class BuildedStoreLocator extends Component {
  constructor(props) {
    super(props)

    this.fetchData = this.fetchData.bind(this)

    this.state = {
      stores: [],
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    let list

    axios(`${process.env.WP_API}/wp/v2/shop?per_page=100`).then(res => {
      /*list = makeList(res.data)*/
      this.setState({
        stores: makeStores(res.data),
      })
    })
  }

  render() {
    const { stores } = this.state
    const { title, text, list } = this.props

    return (
      <StoreLocatorWrapper
        stores={stores}
        title={title}
        text={text}
        list={list}
        appDomain={`${process.env.APP_DOMAIN}/punti-vendita/`}
        storeURL={`${process.env.WP_API}/wp/v2/shop?per_page=40`}
      />
    )
  }
}

export default compose(
  withPageBuilder(),
  withFields([
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'text',
      label: 'Sottotitolo',
    },
    {
      prop: 'list',
      type: 'repeater',
      label: 'Lista Punti',
      model: [
        {
          prop: 'name',
          label: 'Label',
        },
      ],
    },
  ]),
)(BuildedStoreLocator)
