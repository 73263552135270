import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'
import MultipleButton from '../components/MultipleButton'

import { withWrapper, ButtonGradient } from '@alkemy/components'

export default compose(
  withPageBuilder({
    niceName: 'Tasto Gradiente',
  }),
  withFields([
    {
      prop: 'wrapped',
      type: 'boolean',
      label: 'Contenuto Boxed',
    },
    {
      prop: 'label',
      label: 'Titolo',
    },
    {
      prop: 'mainColor',
      label: 'Colore',
      type: 'multiple',
      options: [
        'blueViolet:Blue',
        'greenBlue:Verde',
        'pinkOrange:Arancione',
        'yellowPink:Giallo',
        'loginGradient:Rosa (usato per login)',
      ],
      initialValue: 'blueViolet',
    },
    {
      prop: 'typeOfButton',
      label: 'Tipologia Tasto',
      type: 'multiple',
      options: [
        'simple:Link Semplice',
        'chat:Apri Chat',
        'access:Accedi',
        'storeLocator:Storelocator',
      ],
      initialValue: 'simple',
    },
    {
      prop: 'href',
      label: 'Link',
    },
    {
      prop: 'target',
      label: 'Target Options',
      type: 'multiple',
      options: ['_blank:Nuova Scheda', '_self:Stessa Scheda'],
    },
  ]),
  withWrapper,
)(MultipleButton)
