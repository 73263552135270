"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledWrapper = exports.StyledTitleWrapper = exports.StyledIconsWrapper = exports.StyledIcon = exports.StyledDescriptionWrapper = exports.StyledButtonWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledWrapper = exports.StyledWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledWrapper",
  componentId: "sc-11nudd9-0"
})(["\n  position: relative;\n  width: 100%;\n  min-height: 400px;\n  background-color: ", ";\n  background-image: url(", "?&auto=format);\n  background-size: cover;\n  background-repeat: no-repeat;\n  padding: 40px;\n  text-align: center;\n\n  ", " {\n    min-height: 380px;\n  }\n"], function (props) {
  return props.theme.color.gray;
}, function (props) {
  return props.background;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledTitleWrapper = exports.StyledTitleWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitleWrapper",
  componentId: "sc-11nudd9-1"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 18px;\n  line-height: 1.5;\n  margin-bottom: 8px;\n  ", " {\n    font-size: 24px;\n    margin-bottom: 4px;\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledDescriptionWrapper = exports.StyledDescriptionWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDescriptionWrapper",
  componentId: "sc-11nudd9-2"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  line-height: 1.5;\n  margin-bottom: 16px;\n  ", " {\n    font-size: 18px;\n    margin-bottom: 32px;\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledIconsWrapper = exports.StyledIconsWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconsWrapper",
  componentId: "sc-11nudd9-3"
})(["\n  max-width: 970px;\n  margin-left: -40px;\n  margin-right: -40px;\n  padding-bottom: 35px;\n\n  ", " {\n    margin-left: auto;\n    margin-right: auto;\n  }\n\n  .slick-dots {\n    display: flex !important;\n    justify-content: center;\n    align-items: center;\n    bottom: -35px;\n    li {\n      width: 10px;\n      height: 10px;\n      margin: 0 8px;\n      button {\n        width: 10px;\n        height: 10px;\n        padding: 0;\n        border: 1px solid ", ";\n        border-radius: 50%;\n        transition: all 0.2s;\n        &:before {\n          content: none;\n        }\n      }\n      &.slick-active {\n        button {\n          background-color: ", ";\n        }\n      }\n    }\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.color.white;
});
var StyledIcon = exports.StyledIcon = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIcon",
  componentId: "sc-11nudd9-4"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n  line-height: 1.5;\n  display: flex !important;\n  flex-direction: column;\n  align-items: center;\n  align-content: center;\n  width: 150px;\n\n  ", " {\n    font-family: ", ";\n    text-transform: uppercase;\n  }\n\n  svg {\n    width: 90px;\n    height: 90px;\n    margin-bottom: 8px;\n  }\n  ", " {\n    .slick-slide & {\n      transition: all 0.2s ease-in;\n    }\n    .slick-slide:not(.slick-active) & {\n      transform: scale(0.83);\n      opacity: 0.6;\n    }\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-11nudd9-5"
})(["\n  margin-top: 32px;\n  display: flex;\n  justify-content: center;\n\n  a,\n  button {\n    color: ", ";\n    background-color: transparent;\n    border: 2px solid ", ";\n    font-family: ", ";\n    font-size: 16px;\n    font-weight: normal;\n    font-style: normal;\n    font-stretch: normal;\n    letter-spacing: normal;\n    text-align: center;\n    text-decoration: none;\n    display: flex;\n    align-items: center;\n    border-radius: 30px;\n    padding: 12px 16px;\n    transition: all 0.2s ease-in 0s;\n    cursor: pointer;\n\n    &:hover {\n      color: ", ";\n      background-color: ", ";\n    }\n\n    @media (min-width: 1440px) {\n      font-size: 20px;\n      padding: 18px 48px;\n    }\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.blue;
}, function (props) {
  return props.theme.color.white;
});