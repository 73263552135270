"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledCallMeBackTrigger = exports.StyledCallMeBackText = exports.StyledCallMeBackFormWrapper = exports.StyledCallMeBackFormResponse = exports.StyledCallMeBackForm = exports.StyledCallMeBackErrors = exports.StyledCallMeBackCloser = exports.StyledCallMeBack = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledCallMeBack = exports.StyledCallMeBack = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCallMeBack",
  componentId: "sc-vry6il-0"
})(["\n  position: fixed;\n  width: auto;\n  height: auto;\n  bottom: 21px;\n  right: 0px;\n  z-index: 99;\n  font-family: ", ";\n  pointer-events: ", ";\n\n  ", " {\n    bottom: 0px;\n    right: 0px;\n  }\n\n  ", "\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.isVisible ? 'all' : 'none';
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.isVisible && (0, _styledComponents.css)(["\n      width: 100vw;\n      height: 100%;\n      bottom: 0;\n      right: 0;\n      z-index: 1000000;\n\n      ", " {\n        width: auto;\n        height: auto;\n        bottom: 20px;\n        right: 180px;\n      }\n    "], function (props) {
    return props.theme.mediaQueries.tablet;
  });
});
var StyledCallMeBackTrigger = exports.StyledCallMeBackTrigger = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCallMeBackTrigger",
  componentId: "sc-vry6il-1"
})(["\n  cursor: pointer;\n  text-align: center;\n\n  position: absolute;\n  bottom: -15px;\n  right: 5px;\n\n  opacity: ", ";\n  pointer-events: ", ";\n  z-index: 3;\n  width: 83px;\n  height: 83px;\n\n  background-image: url(/static/images/call_me_back/contattaci.png);\n  background-repeat: no-repeat;\n  background-size: contain;\n  background-position-x: center;\n  pointer-events: ", ";\n  ", " {\n    width: 120px;\n    height: 120px;\n    bottom: 0px;\n    right: 0px;\n    margin: 3px;\n  }\n"], function (props) {
  return props.isVisible ? 0 : 1;
}, function (props) {
  return props.isVisible ? 'none' : 'all';
}, function (props) {
  return props.isVisible ? 'none' : 'all';
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledCallMeBackFormWrapper = exports.StyledCallMeBackFormWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCallMeBackFormWrapper",
  componentId: "sc-vry6il-2"
})(["\n  display: block;\n  position: relative;\n  width: 100%;\n  overflow: hidden;\n  border-radius: 0;\n  height: 100%;\n\n  ", " {\n    border-radius: 10px;\n    width: 320px;\n    height: auto;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledCallMeBackForm = exports.StyledCallMeBackForm = _styledComponents.default.form.withConfig({
  displayName: "styles__StyledCallMeBackForm",
  componentId: "sc-vry6il-3"
})(["\n  position: relative;\n  top: 0;\n  left: 0;\n  transform: translateY(", "%);\n  transition: transform 0.25s linear 0s;\n  background: ", ";\n  padding: 40px 30px 20px;\n  height: 100%;\n\n  ", " {\n    height: auto;\n  }\n\n  input {\n    width: 100%;\n    padding: 16px;\n    padding-left: 0;\n    font-size: 16px;\n    border-radius: 0;\n    margin-bottom: 20px;\n    color: white;\n    background-color: transparent;\n    border: none;\n    border-bottom: 1px solid white;\n    outline: none;\n\n    &::placeholder {\n      color: white;\n      opacity: 1;\n    }\n  }\n\n  button {\n    box-shadow: none;\n    border: 1px solid white;\n    line-height: 40px;\n    margin-bottom: 24px;\n  }\n"], function (props) {
  return props.isVisible ? 0 : 100;
}, function (props) {
  return props.theme.color.greenBlue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledCallMeBackCloser = exports.StyledCallMeBackCloser = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCallMeBackCloser",
  componentId: "sc-vry6il-4"
})(["\n  position: absolute;\n  width: 20px;\n  height: 20px;\n  top: 10px;\n  right: 10px;\n  opacity: ", ";\n  pointer-events: ", ";\n  cursor: pointer;\n"], function (props) {
  return props.isVisible ? 1 : 0;
}, function (props) {
  return props.isVisible ? 'all' : 'none';
});
var StyledCallMeBackText = exports.StyledCallMeBackText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCallMeBackText",
  componentId: "sc-vry6il-5"
})(["\n  color: white;\n  width: 100%;\n  font-size: 14px;\n  font-family: ", ";\n"], function (props) {
  return props.theme.font.regular;
});
var StyledCallMeBackFormResponse = exports.StyledCallMeBackFormResponse = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCallMeBackFormResponse",
  componentId: "sc-vry6il-6"
})(["\n  display: inline-block;\n  background: ", ";\n  color: white;\n  padding: 60px 20px;\n  width: 100vw;\n  height: 100vh;\n\n  ", " {\n    width: auto;\n    height: auto;\n  }\n"], function (props) {
  return props.theme.color.greenBlue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledCallMeBackErrors = exports.StyledCallMeBackErrors = _styledComponents.default.span.withConfig({
  displayName: "styles__StyledCallMeBackErrors",
  componentId: "sc-vry6il-7"
})(["\n  display: inline-block;\n  color: white;\n  font-size: 14px;\n  margin-bottom: 20px;\n"]);