"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Accesso", {
  enumerable: true,
  get: function get() {
    return _Accesso.default;
  }
});
Object.defineProperty(exports, "AccordionClose", {
  enumerable: true,
  get: function get() {
    return _AccordionClose.default;
  }
});
Object.defineProperty(exports, "AcquaCalda", {
  enumerable: true,
  get: function get() {
    return _AcquaCalda.default;
  }
});
Object.defineProperty(exports, "AcquistoDiretto", {
  enumerable: true,
  get: function get() {
    return _AcquistoDiretto.default;
  }
});
Object.defineProperty(exports, "Appstore", {
  enumerable: true,
  get: function get() {
    return _Appstore.default;
  }
});
Object.defineProperty(exports, "ArrowDown", {
  enumerable: true,
  get: function get() {
    return _ArrowDown.default;
  }
});
Object.defineProperty(exports, "ArrowDownScroll", {
  enumerable: true,
  get: function get() {
    return _ArrowDownScroll.default;
  }
});
Object.defineProperty(exports, "ArrowFaq", {
  enumerable: true,
  get: function get() {
    return _ArrowFaq.default;
  }
});
Object.defineProperty(exports, "ArrowLeft", {
  enumerable: true,
  get: function get() {
    return _ArrowLeft.default;
  }
});
Object.defineProperty(exports, "ArrowRight", {
  enumerable: true,
  get: function get() {
    return _ArrowRight.default;
  }
});
Object.defineProperty(exports, "ArrowSlide", {
  enumerable: true,
  get: function get() {
    return _ArrowSlide.default;
  }
});
Object.defineProperty(exports, "ArrowUp", {
  enumerable: true,
  get: function get() {
    return _ArrowUp.default;
  }
});
Object.defineProperty(exports, "Assistance", {
  enumerable: true,
  get: function get() {
    return _Assistance.default;
  }
});
Object.defineProperty(exports, "Award", {
  enumerable: true,
  get: function get() {
    return _Award.default;
  }
});
Object.defineProperty(exports, "Bill", {
  enumerable: true,
  get: function get() {
    return _Bill.default;
  }
});
Object.defineProperty(exports, "Blank", {
  enumerable: true,
  get: function get() {
    return _Blank.default;
  }
});
Object.defineProperty(exports, "BlueGreenLeaf", {
  enumerable: true,
  get: function get() {
    return _BlueGreenLeaf.default;
  }
});
Object.defineProperty(exports, "Boiler", {
  enumerable: true,
  get: function get() {
    return _Boiler.default;
  }
});
Object.defineProperty(exports, "BollinoConfigurator", {
  enumerable: true,
  get: function get() {
    return _BollinoConfigurator.default;
  }
});
Object.defineProperty(exports, "Book", {
  enumerable: true,
  get: function get() {
    return _Book.default;
  }
});
Object.defineProperty(exports, "Business", {
  enumerable: true,
  get: function get() {
    return _Business.default;
  }
});
Object.defineProperty(exports, "BusinessAlt", {
  enumerable: true,
  get: function get() {
    return _BusinessAlt.default;
  }
});
Object.defineProperty(exports, "BusinessLight", {
  enumerable: true,
  get: function get() {
    return _BusinessLight.default;
  }
});
Object.defineProperty(exports, "CallCenter", {
  enumerable: true,
  get: function get() {
    return _CallCenter.default;
  }
});
Object.defineProperty(exports, "CallCenterSmall", {
  enumerable: true,
  get: function get() {
    return _CallCenterSmall.default;
  }
});
Object.defineProperty(exports, "CaretDown", {
  enumerable: true,
  get: function get() {
    return _CaretDown.default;
  }
});
Object.defineProperty(exports, "CaretLeft", {
  enumerable: true,
  get: function get() {
    return _CaretLeft.default;
  }
});
Object.defineProperty(exports, "CaretLeftAlt", {
  enumerable: true,
  get: function get() {
    return _CaretLeftAlt.default;
  }
});
Object.defineProperty(exports, "CaretRight", {
  enumerable: true,
  get: function get() {
    return _CaretRight.default;
  }
});
Object.defineProperty(exports, "Cart", {
  enumerable: true,
  get: function get() {
    return _Cart.default;
  }
});
Object.defineProperty(exports, "Casa1", {
  enumerable: true,
  get: function get() {
    return _Casa.default;
  }
});
Object.defineProperty(exports, "Casa2", {
  enumerable: true,
  get: function get() {
    return _Casa2.default;
  }
});
Object.defineProperty(exports, "Casa3", {
  enumerable: true,
  get: function get() {
    return _Casa3.default;
  }
});
Object.defineProperty(exports, "Casa4", {
  enumerable: true,
  get: function get() {
    return _Casa4.default;
  }
});
Object.defineProperty(exports, "Casa5", {
  enumerable: true,
  get: function get() {
    return _Casa5.default;
  }
});
Object.defineProperty(exports, "Cashback", {
  enumerable: true,
  get: function get() {
    return _Cashback.default;
  }
});
Object.defineProperty(exports, "ChatWhite", {
  enumerable: true,
  get: function get() {
    return _ChatWhite.default;
  }
});
Object.defineProperty(exports, "Check", {
  enumerable: true,
  get: function get() {
    return _Check.default;
  }
});
Object.defineProperty(exports, "ChevronUp", {
  enumerable: true,
  get: function get() {
    return _ChevronUp.default;
  }
});
Object.defineProperty(exports, "CircledCross", {
  enumerable: true,
  get: function get() {
    return _CircledCross.default;
  }
});
Object.defineProperty(exports, "Clients", {
  enumerable: true,
  get: function get() {
    return _Clients.default;
  }
});
Object.defineProperty(exports, "Climatizzatore", {
  enumerable: true,
  get: function get() {
    return _Climatizzatore.default;
  }
});
Object.defineProperty(exports, "Close", {
  enumerable: true,
  get: function get() {
    return _Close.default;
  }
});
Object.defineProperty(exports, "CompareOnLine", {
  enumerable: true,
  get: function get() {
    return _CompareOnLine.default;
  }
});
Object.defineProperty(exports, "ContactUs", {
  enumerable: true,
  get: function get() {
    return _ContactUs.default;
  }
});
Object.defineProperty(exports, "ContractTransfer", {
  enumerable: true,
  get: function get() {
    return _ContractTransfer.default;
  }
});
Object.defineProperty(exports, "Coupon", {
  enumerable: true,
  get: function get() {
    return _Coupon.default;
  }
});
Object.defineProperty(exports, "Cross", {
  enumerable: true,
  get: function get() {
    return _Cross.default;
  }
});
Object.defineProperty(exports, "Cucina", {
  enumerable: true,
  get: function get() {
    return _Cucina.default;
  }
});
Object.defineProperty(exports, "Decrease", {
  enumerable: true,
  get: function get() {
    return _Decrease.default;
  }
});
Object.defineProperty(exports, "Download", {
  enumerable: true,
  get: function get() {
    return _Download.default;
  }
});
Object.defineProperty(exports, "DownloadArrow", {
  enumerable: true,
  get: function get() {
    return _DownloadArrow.default;
  }
});
Object.defineProperty(exports, "EditorialSliderArrowLeft", {
  enumerable: true,
  get: function get() {
    return _EditorialSliderArrowLeft.default;
  }
});
Object.defineProperty(exports, "EditorialSliderArrowRight", {
  enumerable: true,
  get: function get() {
    return _EditorialSliderArrowRight.default;
  }
});
Object.defineProperty(exports, "Email", {
  enumerable: true,
  get: function get() {
    return _Email.default;
  }
});
Object.defineProperty(exports, "ExternalLink", {
  enumerable: true,
  get: function get() {
    return _ExternalLink.default;
  }
});
Object.defineProperty(exports, "Facebook", {
  enumerable: true,
  get: function get() {
    return _Facebook.default;
  }
});
Object.defineProperty(exports, "Fattura", {
  enumerable: true,
  get: function get() {
    return _Fattura.default;
  }
});
Object.defineProperty(exports, "Forno", {
  enumerable: true,
  get: function get() {
    return _Forno.default;
  }
});
Object.defineProperty(exports, "Frigorifero", {
  enumerable: true,
  get: function get() {
    return _Frigorifero.default;
  }
});
Object.defineProperty(exports, "Gas", {
  enumerable: true,
  get: function get() {
    return _Gas.default;
  }
});
Object.defineProperty(exports, "GasBig", {
  enumerable: true,
  get: function get() {
    return _GasBig.default;
  }
});
Object.defineProperty(exports, "GasMini", {
  enumerable: true,
  get: function get() {
    return _GasMini.default;
  }
});
Object.defineProperty(exports, "GasPlug", {
  enumerable: true,
  get: function get() {
    return _GasPlug.default;
  }
});
Object.defineProperty(exports, "GeoPin", {
  enumerable: true,
  get: function get() {
    return _GeoPin.default;
  }
});
Object.defineProperty(exports, "Graph", {
  enumerable: true,
  get: function get() {
    return _Graph.default;
  }
});
Object.defineProperty(exports, "GreenLeaf", {
  enumerable: true,
  get: function get() {
    return _GreenLeaf.default;
  }
});
Object.defineProperty(exports, "Headset", {
  enumerable: true,
  get: function get() {
    return _Headset.default;
  }
});
Object.defineProperty(exports, "HoReCa", {
  enumerable: true,
  get: function get() {
    return _HoReCa.default;
  }
});
Object.defineProperty(exports, "HoReCaAlt", {
  enumerable: true,
  get: function get() {
    return _HoReCaAlt.default;
  }
});
Object.defineProperty(exports, "House", {
  enumerable: true,
  get: function get() {
    return _House.default;
  }
});
Object.defineProperty(exports, "HouseAlt", {
  enumerable: true,
  get: function get() {
    return _HouseAlt.default;
  }
});
Object.defineProperty(exports, "HouseLight", {
  enumerable: true,
  get: function get() {
    return _HouseLight.default;
  }
});
Object.defineProperty(exports, "Iban", {
  enumerable: true,
  get: function get() {
    return _Iban.default;
  }
});
Object.defineProperty(exports, "Id", {
  enumerable: true,
  get: function get() {
    return _Id.default;
  }
});
Object.defineProperty(exports, "Image", {
  enumerable: true,
  get: function get() {
    return _Image.default;
  }
});
Object.defineProperty(exports, "Increase", {
  enumerable: true,
  get: function get() {
    return _Increase.default;
  }
});
Object.defineProperty(exports, "Info", {
  enumerable: true,
  get: function get() {
    return _Info.default;
  }
});
Object.defineProperty(exports, "Instagram", {
  enumerable: true,
  get: function get() {
    return _Instagram.default;
  }
});
Object.defineProperty(exports, "Lavastoviglie", {
  enumerable: true,
  get: function get() {
    return _Lavastoviglie.default;
  }
});
Object.defineProperty(exports, "Lavatrice", {
  enumerable: true,
  get: function get() {
    return _Lavatrice.default;
  }
});
Object.defineProperty(exports, "Leaf", {
  enumerable: true,
  get: function get() {
    return _Leaf.default;
  }
});
Object.defineProperty(exports, "Lightbulb", {
  enumerable: true,
  get: function get() {
    return _Lightbulb.default;
  }
});
Object.defineProperty(exports, "Linkedin", {
  enumerable: true,
  get: function get() {
    return _Linkedin.default;
  }
});
Object.defineProperty(exports, "ListCheck", {
  enumerable: true,
  get: function get() {
    return _ListCheck.default;
  }
});
Object.defineProperty(exports, "ListCheckGreen", {
  enumerable: true,
  get: function get() {
    return _ListCheckGreen.default;
  }
});
Object.defineProperty(exports, "Location", {
  enumerable: true,
  get: function get() {
    return _Location.default;
  }
});
Object.defineProperty(exports, "Login", {
  enumerable: true,
  get: function get() {
    return _Login.default;
  }
});
Object.defineProperty(exports, "Magnifier", {
  enumerable: true,
  get: function get() {
    return _Magnifier.default;
  }
});
Object.defineProperty(exports, "MagnifierBold", {
  enumerable: true,
  get: function get() {
    return _MagnifierBold.default;
  }
});
Object.defineProperty(exports, "Marker", {
  enumerable: true,
  get: function get() {
    return _Marker.default;
  }
});
Object.defineProperty(exports, "MessageReceived", {
  enumerable: true,
  get: function get() {
    return _MessageReceived.default;
  }
});
Object.defineProperty(exports, "MessageSent", {
  enumerable: true,
  get: function get() {
    return _MessageSent.default;
  }
});
Object.defineProperty(exports, "MoneyPercentage", {
  enumerable: true,
  get: function get() {
    return _MoneyPercentage.default;
  }
});
Object.defineProperty(exports, "MoneyPig", {
  enumerable: true,
  get: function get() {
    return _MoneyPig.default;
  }
});
Object.defineProperty(exports, "MouseDownScroll", {
  enumerable: true,
  get: function get() {
    return _MouseDownScroll.default;
  }
});
Object.defineProperty(exports, "Negozi", {
  enumerable: true,
  get: function get() {
    return _Negozi.default;
  }
});
Object.defineProperty(exports, "NewActivation", {
  enumerable: true,
  get: function get() {
    return _NewActivation.default;
  }
});
Object.defineProperty(exports, "NotFound", {
  enumerable: true,
  get: function get() {
    return _NotFound.default;
  }
});
Object.defineProperty(exports, "Offices", {
  enumerable: true,
  get: function get() {
    return _Offices.default;
  }
});
Object.defineProperty(exports, "OrionDownload", {
  enumerable: true,
  get: function get() {
    return _OrionDownload.default;
  }
});
Object.defineProperty(exports, "OrionPlug", {
  enumerable: true,
  get: function get() {
    return _OrionPlug.default;
  }
});
Object.defineProperty(exports, "OrionPlugWhite", {
  enumerable: true,
  get: function get() {
    return _OrionPlugWhite.default;
  }
});
Object.defineProperty(exports, "Pdf", {
  enumerable: true,
  get: function get() {
    return _Pdf.default;
  }
});
Object.defineProperty(exports, "People1", {
  enumerable: true,
  get: function get() {
    return _People.default;
  }
});
Object.defineProperty(exports, "People2", {
  enumerable: true,
  get: function get() {
    return _People2.default;
  }
});
Object.defineProperty(exports, "People3", {
  enumerable: true,
  get: function get() {
    return _People3.default;
  }
});
Object.defineProperty(exports, "People4", {
  enumerable: true,
  get: function get() {
    return _People4.default;
  }
});
Object.defineProperty(exports, "People5", {
  enumerable: true,
  get: function get() {
    return _People5.default;
  }
});
Object.defineProperty(exports, "Phone", {
  enumerable: true,
  get: function get() {
    return _Phone.default;
  }
});
Object.defineProperty(exports, "PhoneVariant", {
  enumerable: true,
  get: function get() {
    return _PhoneVariant.default;
  }
});
Object.defineProperty(exports, "Playstore", {
  enumerable: true,
  get: function get() {
    return _Playstore.default;
  }
});
Object.defineProperty(exports, "Plug", {
  enumerable: true,
  get: function get() {
    return _Plug.default;
  }
});
Object.defineProperty(exports, "PlusCircle", {
  enumerable: true,
  get: function get() {
    return _PlusCircle.default;
  }
});
Object.defineProperty(exports, "Pmi", {
  enumerable: true,
  get: function get() {
    return _Pmi.default;
  }
});
Object.defineProperty(exports, "Pointer", {
  enumerable: true,
  get: function get() {
    return _Pointer.default;
  }
});
Object.defineProperty(exports, "Pointer2", {
  enumerable: true,
  get: function get() {
    return _Pointer2.default;
  }
});
Object.defineProperty(exports, "Print", {
  enumerable: true,
  get: function get() {
    return _Print.default;
  }
});
Object.defineProperty(exports, "Professionals", {
  enumerable: true,
  get: function get() {
    return _Professionals.default;
  }
});
Object.defineProperty(exports, "RectangleDashed", {
  enumerable: true,
  get: function get() {
    return _RectangleDashed.default;
  }
});
Object.defineProperty(exports, "Reload", {
  enumerable: true,
  get: function get() {
    return _Reload.default;
  }
});
Object.defineProperty(exports, "ReplyNo", {
  enumerable: true,
  get: function get() {
    return _ReplyNo.default;
  }
});
Object.defineProperty(exports, "ReplyNoBusiness", {
  enumerable: true,
  get: function get() {
    return _ReplyNoBusiness.default;
  }
});
Object.defineProperty(exports, "ReplyYes", {
  enumerable: true,
  get: function get() {
    return _ReplyYes.default;
  }
});
Object.defineProperty(exports, "ReplyYesBusiness", {
  enumerable: true,
  get: function get() {
    return _ReplyYesBusiness.default;
  }
});
Object.defineProperty(exports, "Riscaldamento", {
  enumerable: true,
  get: function get() {
    return _Riscaldamento.default;
  }
});
Object.defineProperty(exports, "Share", {
  enumerable: true,
  get: function get() {
    return _Share.default;
  }
});
Object.defineProperty(exports, "Shop", {
  enumerable: true,
  get: function get() {
    return _Shop.default;
  }
});
Object.defineProperty(exports, "SliderLeft", {
  enumerable: true,
  get: function get() {
    return _SliderLeft.default;
  }
});
Object.defineProperty(exports, "SliderLeftAlt", {
  enumerable: true,
  get: function get() {
    return _SliderLeftAlt.default;
  }
});
Object.defineProperty(exports, "SliderRight", {
  enumerable: true,
  get: function get() {
    return _SliderRight.default;
  }
});
Object.defineProperty(exports, "SliderRightAlt", {
  enumerable: true,
  get: function get() {
    return _SliderRightAlt.default;
  }
});
Object.defineProperty(exports, "Speaker", {
  enumerable: true,
  get: function get() {
    return _Speaker.default;
  }
});
Object.defineProperty(exports, "Store", {
  enumerable: true,
  get: function get() {
    return _Store.default;
  }
});
Object.defineProperty(exports, "Subenter", {
  enumerable: true,
  get: function get() {
    return _Subenter.default;
  }
});
Object.defineProperty(exports, "SupplierChange", {
  enumerable: true,
  get: function get() {
    return _SupplierChange.default;
  }
});
Object.defineProperty(exports, "SwitchAlt", {
  enumerable: true,
  get: function get() {
    return _SwitchAlt.default;
  }
});
Object.defineProperty(exports, "ThumbsUp", {
  enumerable: true,
  get: function get() {
    return _ThumbsUp.default;
  }
});
Object.defineProperty(exports, "Ticket", {
  enumerable: true,
  get: function get() {
    return _Ticket.default;
  }
});
Object.defineProperty(exports, "Twitter", {
  enumerable: true,
  get: function get() {
    return _Twitter.default;
  }
});
Object.defineProperty(exports, "Vas", {
  enumerable: true,
  get: function get() {
    return _Vas.default;
  }
});
Object.defineProperty(exports, "VasHome", {
  enumerable: true,
  get: function get() {
    return _VasHome.default;
  }
});
Object.defineProperty(exports, "VipAccount", {
  enumerable: true,
  get: function get() {
    return _VipAccount.default;
  }
});
Object.defineProperty(exports, "VolturaAlt", {
  enumerable: true,
  get: function get() {
    return _VolturaAlt.default;
  }
});
Object.defineProperty(exports, "Warning", {
  enumerable: true,
  get: function get() {
    return _Warning.default;
  }
});
Object.defineProperty(exports, "WarningRounded", {
  enumerable: true,
  get: function get() {
    return _WarningRounded.default;
  }
});
Object.defineProperty(exports, "Worker", {
  enumerable: true,
  get: function get() {
    return _Worker.default;
  }
});
Object.defineProperty(exports, "Youtube", {
  enumerable: true,
  get: function get() {
    return _Youtube.default;
  }
});
Object.defineProperty(exports, "Zip", {
  enumerable: true,
  get: function get() {
    return _Zip.default;
  }
});
var _AccordionClose = _interopRequireDefault(require("./AccordionClose"));
var _AcquaCalda = _interopRequireDefault(require("./AcquaCalda"));
var _Appstore = _interopRequireDefault(require("./Appstore"));
var _ArrowDown = _interopRequireDefault(require("./ArrowDown"));
var _ArrowDownScroll = _interopRequireDefault(require("./ArrowDownScroll"));
var _MouseDownScroll = _interopRequireDefault(require("./MouseDownScroll"));
var _ArrowFaq = _interopRequireDefault(require("./ArrowFaq"));
var _ArrowLeft = _interopRequireDefault(require("./ArrowLeft"));
var _ArrowRight = _interopRequireDefault(require("./ArrowRight"));
var _ArrowSlide = _interopRequireDefault(require("./ArrowSlide"));
var _ArrowUp = _interopRequireDefault(require("./ArrowUp"));
var _Assistance = _interopRequireDefault(require("./Assistance"));
var _Award = _interopRequireDefault(require("./Award"));
var _Bill = _interopRequireDefault(require("./Bill"));
var _Blank = _interopRequireDefault(require("./Blank"));
var _BlueGreenLeaf = _interopRequireDefault(require("./BlueGreenLeaf"));
var _Boiler = _interopRequireDefault(require("./Boiler"));
var _Book = _interopRequireDefault(require("./Book"));
var _Business = _interopRequireDefault(require("./Business"));
var _BusinessLight = _interopRequireDefault(require("./BusinessLight"));
var _BusinessAlt = _interopRequireDefault(require("./BusinessAlt"));
var _CallCenter = _interopRequireDefault(require("./CallCenter"));
var _CallCenterSmall = _interopRequireDefault(require("./CallCenterSmall"));
var _CaretDown = _interopRequireDefault(require("./CaretDown"));
var _CaretLeft = _interopRequireDefault(require("./CaretLeft"));
var _CaretLeftAlt = _interopRequireDefault(require("./CaretLeftAlt"));
var _Cart = _interopRequireDefault(require("./Cart"));
var _CaretRight = _interopRequireDefault(require("./CaretRight"));
var _Casa = _interopRequireDefault(require("./Casa1"));
var _Casa2 = _interopRequireDefault(require("./Casa2"));
var _Casa3 = _interopRequireDefault(require("./Casa3"));
var _Casa4 = _interopRequireDefault(require("./Casa4"));
var _Casa5 = _interopRequireDefault(require("./Casa5"));
var _Check = _interopRequireDefault(require("./Check"));
var _CircledCross = _interopRequireDefault(require("./CircledCross"));
var _Clients = _interopRequireDefault(require("./Clients"));
var _Climatizzatore = _interopRequireDefault(require("./Climatizzatore"));
var _Close = _interopRequireDefault(require("./Close"));
var _ContractTransfer = _interopRequireDefault(require("./ContractTransfer"));
var _ContactUs = _interopRequireDefault(require("./ContactUs"));
var _Cross = _interopRequireDefault(require("./Cross"));
var _Cucina = _interopRequireDefault(require("./Cucina"));
var _Decrease = _interopRequireDefault(require("./Decrease"));
var _Download = _interopRequireDefault(require("./Download"));
var _EditorialSliderArrowLeft = _interopRequireDefault(require("./EditorialSliderArrowLeft"));
var _OrionDownload = _interopRequireDefault(require("./OrionDownload"));
var _Headset = _interopRequireDefault(require("./Headset"));
var _EditorialSliderArrowRight = _interopRequireDefault(require("./EditorialSliderArrowRight"));
var _Email = _interopRequireDefault(require("./Email"));
var _ExternalLink = _interopRequireDefault(require("./ExternalLink"));
var _Facebook = _interopRequireDefault(require("./Facebook"));
var _Fattura = _interopRequireDefault(require("./Fattura"));
var _Forno = _interopRequireDefault(require("./Forno"));
var _Frigorifero = _interopRequireDefault(require("./Frigorifero"));
var _Gas = _interopRequireDefault(require("./Gas"));
var _GasBig = _interopRequireDefault(require("./GasBig"));
var _GasMini = _interopRequireDefault(require("./GasMini"));
var _GasPlug = _interopRequireDefault(require("./GasPlug"));
var _GeoPin = _interopRequireDefault(require("./GeoPin"));
var _Graph = _interopRequireDefault(require("./Graph"));
var _GreenLeaf = _interopRequireDefault(require("./GreenLeaf"));
var _HoReCa = _interopRequireDefault(require("./HoReCa"));
var _HoReCaAlt = _interopRequireDefault(require("./HoReCaAlt"));
var _House = _interopRequireDefault(require("./House"));
var _HouseLight = _interopRequireDefault(require("./HouseLight"));
var _HouseAlt = _interopRequireDefault(require("./HouseAlt"));
var _Iban = _interopRequireDefault(require("./Iban"));
var _Id = _interopRequireDefault(require("./Id"));
var _Info = _interopRequireDefault(require("./Info"));
var _Increase = _interopRequireDefault(require("./Increase"));
var _Instagram = _interopRequireDefault(require("./Instagram"));
var _Lavastoviglie = _interopRequireDefault(require("./Lavastoviglie"));
var _Lavatrice = _interopRequireDefault(require("./Lavatrice"));
var _Leaf = _interopRequireDefault(require("./Leaf"));
var _Lightbulb = _interopRequireDefault(require("./Lightbulb"));
var _Linkedin = _interopRequireDefault(require("./Linkedin"));
var _ListCheck = _interopRequireDefault(require("./ListCheck"));
var _ListCheckGreen = _interopRequireDefault(require("./ListCheckGreen"));
var _Location = _interopRequireDefault(require("./Location"));
var _Login = _interopRequireDefault(require("./Login"));
var _Magnifier = _interopRequireDefault(require("./Magnifier"));
var _MagnifierBold = _interopRequireDefault(require("./MagnifierBold"));
var _Marker = _interopRequireDefault(require("./Marker"));
var _MessageReceived = _interopRequireDefault(require("./MessageReceived"));
var _Accesso = _interopRequireDefault(require("./Accesso"));
var _MessageSent = _interopRequireDefault(require("./MessageSent"));
var _MoneyPercentage = _interopRequireDefault(require("./MoneyPercentage"));
var _MoneyPig = _interopRequireDefault(require("./MoneyPig"));
var _Negozi = _interopRequireDefault(require("./Negozi"));
var _NewActivation = _interopRequireDefault(require("./NewActivation"));
var _NotFound = _interopRequireDefault(require("./NotFound"));
var _Offices = _interopRequireDefault(require("./Offices"));
var _OrionPlug = _interopRequireDefault(require("./OrionPlug"));
var _OrionPlugWhite = _interopRequireDefault(require("./OrionPlugWhite"));
var _Pdf = _interopRequireDefault(require("./Pdf"));
var _People = _interopRequireDefault(require("./People1"));
var _People2 = _interopRequireDefault(require("./People2"));
var _People3 = _interopRequireDefault(require("./People3"));
var _People4 = _interopRequireDefault(require("./People4"));
var _People5 = _interopRequireDefault(require("./People5"));
var _Phone = _interopRequireDefault(require("./Phone"));
var _PhoneVariant = _interopRequireDefault(require("./PhoneVariant"));
var _Playstore = _interopRequireDefault(require("./Playstore"));
var _Plug = _interopRequireDefault(require("./Plug"));
var _PlusCircle = _interopRequireDefault(require("./PlusCircle"));
var _Pmi = _interopRequireDefault(require("./Pmi"));
var _Pointer = _interopRequireDefault(require("./Pointer"));
var _Pointer2 = _interopRequireDefault(require("./Pointer2"));
var _Print = _interopRequireDefault(require("./Print"));
var _Professionals = _interopRequireDefault(require("./Professionals"));
var _RectangleDashed = _interopRequireDefault(require("./RectangleDashed"));
var _ReplyNo = _interopRequireDefault(require("./ReplyNo"));
var _ReplyNoBusiness = _interopRequireDefault(require("./ReplyNoBusiness"));
var _ReplyYes = _interopRequireDefault(require("./ReplyYes"));
var _ReplyYesBusiness = _interopRequireDefault(require("./ReplyYesBusiness"));
var _Riscaldamento = _interopRequireDefault(require("./Riscaldamento"));
var _Share = _interopRequireDefault(require("./Share"));
var _Shop = _interopRequireDefault(require("./Shop"));
var _SliderLeft = _interopRequireDefault(require("./SliderLeft"));
var _SliderRight = _interopRequireDefault(require("./SliderRight"));
var _SliderLeftAlt = _interopRequireDefault(require("./SliderLeftAlt"));
var _SliderRightAlt = _interopRequireDefault(require("./SliderRightAlt"));
var _Speaker = _interopRequireDefault(require("./Speaker"));
var _Store = _interopRequireDefault(require("./Store"));
var _Subenter = _interopRequireDefault(require("./Subenter"));
var _SupplierChange = _interopRequireDefault(require("./SupplierChange"));
var _SwitchAlt = _interopRequireDefault(require("./SwitchAlt"));
var _ThumbsUp = _interopRequireDefault(require("./ThumbsUp"));
var _Ticket = _interopRequireDefault(require("./Ticket"));
var _Twitter = _interopRequireDefault(require("./Twitter"));
var _VipAccount = _interopRequireDefault(require("./VipAccount"));
var _VolturaAlt = _interopRequireDefault(require("./VolturaAlt"));
var _Cashback = _interopRequireDefault(require("./Cashback"));
var _Coupon = _interopRequireDefault(require("./Coupon"));
var _CompareOnLine = _interopRequireDefault(require("./CompareOnLine"));
var _AcquistoDiretto = _interopRequireDefault(require("./AcquistoDiretto"));
var _Warning = _interopRequireDefault(require("./Warning"));
var _Worker = _interopRequireDefault(require("./Worker"));
var _Youtube = _interopRequireDefault(require("./Youtube"));
var _ChatWhite = _interopRequireDefault(require("./ChatWhite"));
var _Vas = _interopRequireDefault(require("./Vas"));
var _VasHome = _interopRequireDefault(require("./VasHome"));
var _ChevronUp = _interopRequireDefault(require("./ChevronUp"));
var _Image = _interopRequireDefault(require("./Image"));
var _Zip = _interopRequireDefault(require("./Zip"));
var _DownloadArrow = _interopRequireDefault(require("./DownloadArrow"));
var _WarningRounded = _interopRequireDefault(require("./WarningRounded"));
var _Reload = _interopRequireDefault(require("./Reload"));
var _BollinoConfigurator = _interopRequireDefault(require("./BollinoConfigurator"));