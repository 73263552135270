const home = require('./home.json')
const casa = require('./casa.json')
const landingAssistenza = require('./landingAssistenza.json')
const business = require('./business.json')
const aziende = require('./aziende.json')
const fixMarketLuce = require('./offers/business/fixMarketLuce.json')
const flexMarketLuce = require('./offers/business/flexMarketLuce.json')
const nettoFixGas = require('./offers/business/nettoFixGas.json')
const nettoFlexGas = require('./offers/business/nettoFlexGas.json')
const placetLuceFissaBusiness = require('./offers/business/placetLuceFissa.json')
const placetLuceVariabileBusiness = require('./offers/business/placetLuceVariabile.json')
const placetGasFissaBusiness = require('./offers/business/placetGasFissa.json')
const placetGasVariabileBusiness = require('./offers/business/placetGasVariabile.json')
const gasRataCostante = require('./offers/business/gasRataCostante.json')
const luceRataCostante = require('./offers/business/luceRataCostante.json')
const formulaFissoElettrico = require('./offers/casa/formulaFissoElettrico.json')
const prezzoFissoGas = require('./offers/casa/prezzoFissoGas.json')
const formulaFissoGas = require('./offers/casa/formulaFissoGas.json')
const basicGas = require('./offers/casa/basicGas.json')
const basicLuce = require('./offers/casa/basicLuce.json')
const placetLuceFissaCasa = require('./offers/casa/placetLuceFissa.json')
const placetLuceVariabileCasa = require('./offers/casa/placetLuceVariabile.json')
const placetGasFissaCasa = require('./offers/casa/placetGasFissa.json')
const placetGasVariabileCasa = require('./offers/casa/placetGasVariabile.json')
const gasLuceFisso = require('./offers/casa/gasLuceFisso.json')
const energiaPerDue = require('./offers/casa/energiaPerDue.json')
const viviflex = require('./offers/casa/viviflex.json')
const viviserenoGasDomestico = require('./offers/casa/viviserenoGasDomestico.json')
const viviserenoLuceDomestico = require('./offers/casa/viviserenoLuceDomestico.json')
const viviserenoLuceGasDomestico = require('./offers/casa/viviserenoLuceGasDomestico.json')
const vivisostenibileGasDomestico = require('./offers/casa/vivisostenibileGasDomestico.json')
const vivisostenibileLuceDomestico = require('./offers/casa/vivisostenibileLuceDomestico.json')
const vivisostenibileLuceGasDomestico = require('./offers/casa/vivisostenibileLuceGasDomestico.json')
const generic = require('./generic.json')
const genericProva2 = require('./genericProva2.json')
const genericProva3 = require('./genericProva3.json')
const modulistica = require('./modulistica.json')
const bolletta20 = require('./bolletta20.json')
const roncadelle = require('./punti-vendita/roncadelle.json')
const abbiategrasso = require('./punti-vendita/abbiategrasso.json')
const arcisate = require('./punti-vendita/arcisate.json')
const arona = require('./punti-vendita/arona.json')
const casalpusterlengo1 = require('./punti-vendita/casalpusterlengo1.json')
const casalpusterlengo2 = require('./punti-vendita/casalpusterlengo2.json')
const castelDazzano = require('./punti-vendita/castelDazzano.json')
const chiari = require('./punti-vendita/chiari.json')
const legnano = require('./punti-vendita/legnano.json')
const lovere = require('./punti-vendita/lovere.json')
const luragoDerba = require('./punti-vendita/luragoDerba.json')
const magenta = require('./punti-vendita/magenta.json')
const monterotondo = require('./punti-vendita/monterotondo.json')
const narni = require('./punti-vendita/narni.json')
const nave = require('./punti-vendita/nave.json')
const negrar = require('./punti-vendita/negrar.json')
const nettuno = require('./punti-vendita/nettuno.json')
const oleggio = require('./punti-vendita/oleggio.json')
const olgiateOlona = require('./punti-vendita/olgiateOlona.json')
const omegna = require('./punti-vendita/omegna.json')
const orte = require('./punti-vendita/orte.json')
const palestrina = require('./punti-vendita/palestrina.json')
const parabiago = require('./punti-vendita/parabiago.json')
const pontiroloNuovo = require('./punti-vendita/pontiroloNuovo.json')
const terni = require('./punti-vendita/terni.json')
const assistenzaCasa = require('./landing-assistenza/assistenzaCasa.json')
const assistenzaPMI = require('./landing-assistenza/assistenzaPMI.json')
const assistenzaGrandiAziende = require('./landing-assistenza/assistenzaGrandiAziende.json')
const tutelaGas = require('./tutelaGas.json')
const contatti = require('./contatti.json')
const grandiAziende = require('./grandiAziende.json')
const reseller = require('./reseller.json')
const puntiVendita = require('./puntiVendita.json')
const prodotti = require('./prodotti.json')
const vivigasPerTeCasa = require('./vivigasPerTeCasa.json')
const tvtb = require('./tvtb.json')
const soddisfattiRimborsati = require('./soddisfattiRimborsati.json')
const chiSiamo = require('./chiSiamo.json')
const concorso = require('./concorso.json')
const watty = require('./watty.json')
const autoletturagas = require('./vivipedia/autoletturagas.json')
const allaccioLuce = require('./vivipedia/allaccioLuce.json')
const allaccioGas = require('./vivipedia/allaccioGas.json')
const attivazioneGas = require('./vivipedia/attivazioneGas.json')
const attivazioneLuce = require('./vivipedia/attivazioneLuce.json')
const risparmioEnergetico = require('./vivipedia/risparmioEnergetico.json')
const volturagas = require('./vivipedia/volturagas.json')
const volturaluce = require('./vivipedia/volturaluce.json')
const contabilizzatori = require('./vivipedia/contabilizzatori.json')
const valvole_termostatiche = require('./vivipedia/valvole_termostatiche.json')
const termoconvettori = require('./vivipedia/termoconvettori.json')
const viviesco = require('./viviesco.json')

module.exports = {
  '/': home,
  '/casa': casa,
  '/landing-assistenza': landingAssistenza,
  '/business': business,
  '/business/aziende': aziende,
  '/business/aziende/fix-market-luce': fixMarketLuce,
  '/business/aziende/flex-market-luce': flexMarketLuce,
  '/business/aziende/nettofix-gas': nettoFixGas,
  '/business/aziende/nettoflex-gas': nettoFlexGas,
  '/business/aziende/placet-luce-fissa': placetLuceFissaBusiness,
  '/business/aziende/placet-luce-variabile': placetLuceVariabileBusiness,
  '/business/aziende/placet-gas-fissa': placetGasFissaBusiness,
  '/business/aziende/placet-gas-variabile': placetGasVariabileBusiness,
  '/business/aziende/gas-rata-costante': gasRataCostante,
  '/business/aziende/luce-rata-costante': luceRataCostante,
  '/casa/formula-fisso-elettrico': formulaFissoElettrico,
  '/casa/prezzo-fisso-gas': prezzoFissoGas,
  '/casa/formula-fisso-gas': formulaFissoGas,
  '/casa/basic-gas': basicGas,
  '/casa/basic-luce': basicLuce,
  '/casa/placet-luce-fissa': placetLuceFissaCasa,
  '/casa/placet-luce-variabile': placetLuceVariabileCasa,
  '/casa/placet-gas-fissa': placetGasFissaCasa,
  '/casa/placet-gas-variabile': placetGasVariabileCasa,
  '/casa/gas-luce-fisso': gasLuceFisso,
  '/casa/energia-per-due': energiaPerDue,
  '/casa/viviflex': viviflex,
  '/casa/vivisereno-gas-domestico': viviserenoGasDomestico,
  '/casa/vivisereno-luce-domestico': viviserenoLuceDomestico,
  '/casa/vivisereno-lucegas-domestico': viviserenoLuceGasDomestico,
  '/casa/vivisostenibile-gas-domestico': vivisostenibileGasDomestico,
  '/casa/vivisostenibile-luce-domestico': vivisostenibileLuceDomestico,
  '/casa/vivistonebile-lucegas-domestico': vivisostenibileLuceGasDomestico,
  '/generic': generic,
  '/generic-prova-2': genericProva2,
  '/generic-prova-3': genericProva3,
  '/modulistica': modulistica,
  '/bolletta20/': bolletta20,
  '/punti-vendita/roncadelle/': roncadelle,
  '/punti-vendita/abbiategrasso/': abbiategrasso,
  '/punti-vendita/arcisate/': arcisate,
  '/punti-vendita/arona/': arona,
  '/punti-vendita/casalpusterlengo1/': casalpusterlengo1,
  '/punti-vendita/casalpusterlengo2/': casalpusterlengo2,
  '/punti-vendita/castel_dazzano/': castelDazzano,
  '/punti-vendita/chiari/': chiari,
  '/punti-vendita/legnano/': legnano,
  '/punti-vendita/lovere/': lovere,
  '/punti-vendita/lurago-derba/': luragoDerba,
  '/punti-vendita/magenta/': magenta,
  '/punti-vendita/monterotondo/': monterotondo,
  '/punti-vendita/narni/': narni,
  '/punti-vendita/nave/': nave,
  '/punti-vendita/negrar/': negrar,
  '/punti-vendita/nettuno/': nettuno,
  '/punti-vendita/oleggio/': oleggio,
  '/punti-vendita/olgiate-olona/': olgiateOlona,
  '/punti-vendita/omegna/': omegna,
  '/punti-vendita/orte/': orte,
  '/punti-vendita/palestrina/': palestrina,
  '/punti-vendita/parabiago/': parabiago,
  '/punti-vendita/pontirolo-nuovo/': pontiroloNuovo,
  '/punti-vendita/terni/': terni,
  '/landing-assistenza/assistenza-casa': assistenzaCasa,
  '/landing-assistenza/assistenza-PMI': assistenzaPMI,
  '/landing-assistenza/assistenza-grandiAziende': assistenzaGrandiAziende,
  '/tutela-gas/': tutelaGas,
  '/contatti': contatti,
  '/grandi-aziende/': grandiAziende,
  '/reseller': reseller,
  '/punti-vendita/': puntiVendita,
  '/prodotti/': prodotti,
  '/vivigas-per-te-casa': vivigasPerTeCasa,
  '/tvtb': tvtb,
  '/soddisfatti-rimborsati': soddisfattiRimborsati,
  '/chi-siamo': chiSiamo,
  '/concorso': concorso,
  '/watty': watty,
  '/casa/vivipedia/guida-energia/autolettura-gas': autoletturagas,
  '/casa/vivipedia/guida-energia/allaccio-luce-cos-e-come-funziona': allaccioLuce,
  '/casa/vivipedia/guida-energia/allaccio-gas-cos-e-come-funziona': allaccioGas,
  '/casa/vivipedia/guida-energia/attivazione-fornitura-gas': attivazioneGas,
  '/casa/vivipedia/guida-energia/attivazione-fornitura-luce': attivazioneLuce,
  '/casa/vivipedia/consigli-di-risparmio/risparmiare-energia-elettrica-evitare-sprechi': risparmioEnergetico,
  '/casa/vivipedia/guida-energia/voltura-gas': volturagas,
  '/casa/vivipedia/guida-energia/voltura-luce': volturaluce,
  '/casa/vivipedia/consigli-di-risparmio/contabilizzatori-di-calore-cosa-sono-e-come-funzionano': contabilizzatori,
  '/casa/vivipedia/consigli-di-risparmio/valvole-termostatiche-cosa-sono-e-come-funzionano': valvole_termostatiche,
  '/casa/vivipedia/consigli-di-risparmio/termoconvettori-cosa-sono-e-come-funzionano': termoconvettori,
  '/viviesco': viviesco,
}
