import { compose } from "recompose"
import withPageBuilder from "@alkemy/pagebuilder/lib/hoc/withPageBuilder"
import withFields from "@alkemy/pagebuilder/lib/hoc/withFields"

import { SectionPhone, withWrapper } from "@alkemy/components"

export default compose(
  withPageBuilder({
    niceName: "Box Numero Verde",
  }),
  withFields([
    {
      prop: "wrapped",
      type: "boolean",
      label: "Contenuto Boxed",
    },
    {
      prop: "centeredPhone",
      type: "boolean",
      label: "N°Verde Centrato",
    },
    {
      prop: "title",
      label: "Titolo",
    },
    {
      prop: "subtitle",
      label: "Sottotitolo",
    },
    {
      prop: "text",
      label: "Body",
    },
    {
      prop: "topText",
      label: "Testo N Verde",
    },
    {
      prop: "telNum",
      label: "Numero Verde",
    },
    {
      prop: "bottomText",
      label: "Chiamata Gratuita",
    },
  ]),
  withWrapper
)(SectionPhone)
