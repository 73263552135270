"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WrapperMain = exports.PageTitle = exports.BlobContainer = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var WrapperMain = exports.WrapperMain = _styledComponents.default.div.withConfig({
  displayName: "styles__WrapperMain",
  componentId: "sc-1u41zn1-0"
})(["\n  position: absolute;\n  z-index: 1;\n  top: 7%;\n  left: 50%;\n  transform: translateX(-50%);\n"]);
var PageTitle = exports.PageTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__PageTitle",
  componentId: "sc-1u41zn1-1"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 30px;\n  font-weight: 400;\n  font-style: normal;\n  font-stretch: normal;\n  letter-spacing: normal;\n  line-height: normal;\n  text-align: center;\n  text-transform: uppercase;\n\n  ", " {\n    font-size: 30px;\n  }\n  h1 {\n    color: ", ";\n    font-family: ", ";\n    font-size: 30px;\n    font-weight: 400;\n    font-style: normal;\n    font-stretch: normal;\n    letter-spacing: normal;\n    line-height: normal;\n    text-align: center;\n    text-transform: uppercase;\n\n    ", " {\n      font-size: 30px;\n    }\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.mediaQueries.desktop;
}, function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var BlobContainer = exports.BlobContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__BlobContainer",
  componentId: "sc-1u41zn1-2"
})(["\n  display: flex;\n  color: ", ";\n  text-align: center;\n  line-height: 30px;\n  font-size: 25px;\n  font-family: ", ";\n  margin-top: ", ";\n  @media screen and (min-width: 767px) {\n    font-size: 35px;\n    line-height: 40px;\n  }\n  @media screen and (min-width: 1024px) {\n    font-size: 45px;\n    line-height: 50px;\n  }\n  canvas {\n    z-index: -1;\n    width: 200vw;\n    position: relative;\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.font.light;
}, function (props) {
  return props.headerHeight ? "".concat(props.headerHeight, "px") : 0;
});