"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitle = exports.StyledText = exports.StyledPrevArrow = exports.StyledNextArrow = exports.StyledMainTitle = exports.StyledIconWrapper = exports.StyledIcon = exports.StyledContent = exports.StyledButtonLabel = exports.StyledButton = exports.SlideWrapper = exports.IconImg = exports.Block = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var SlideWrapper = exports.SlideWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__SlideWrapper",
  componentId: "sc-1o8xv0-0"
})(["\n  width: 200px;\n  height: 350px;\n  margin: 20px 0;\n  display: flex;\n  padding: 0 13px;\n\n  ", " {\n    width: 240px;\n    height: 320px;\n    padding: 0 26px;\n    margin: 20px 0;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var Block = exports.Block = _styledComponents.default.div.withConfig({
  displayName: "styles__Block",
  componentId: "sc-1o8xv0-1"
})(["\n  display: flex;\n  padding: 25px;\n  flex-flow: column;\n  align-items: center;\n  justify-content: flex-start;\n  > a {\n    width: 100%;\n    img {\n      width: 100%;\n      max-height: 125px;\n    }\n  }\n"]);
var StyledIcon = exports.StyledIcon = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIcon",
  componentId: "sc-1o8xv0-2"
})(["\n  align-self: center;\n  width: 113px;\n  margin: 25px 0;\n  > img {\n    width: 100%;\n  }\n"]);
var IconImg = exports.IconImg = _styledComponents.default.img.withConfig({
  displayName: "styles__IconImg",
  componentId: "sc-1o8xv0-3"
})(["\n  width: 50%;\n  padding-bottom: 20px;\n"]);
var StyledContent = exports.StyledContent = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContent",
  componentId: "sc-1o8xv0-4"
})(["\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n"]);
var StyledMainTitle = exports.StyledMainTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledMainTitle",
  componentId: "sc-1o8xv0-5"
})(["\n  font-size: 20px;\n  text-align: center;\n  font-family: ", ";\n  color: #3f5063;\n  font-weight: 400;\n  margin-bottom: 0px;\n  margin-top: 28px;\n  margin-bottom: 20px;\n"], function (props) {
  return props.theme.font.medium;
});
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-1o8xv0-6"
})(["\n  font-size: 18px;\n  text-align: center;\n  font-family: ", ";\n  color: #3f5063;\n  font-weight: 400;\n  margin-bottom: 10px;\n"], function (props) {
  return props.theme.font.medium;
});
var StyledPrevArrow = exports.StyledPrevArrow = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledPrevArrow",
  componentId: "sc-1o8xv0-7"
})(["\n  width: 65px;\n  height: 65px;\n  position: absolute;\n  display: block;\n  top: 50%;\n  left: -65px;\n  cursor: pointer;\n  transform: rotate(180deg);\n"]);
var StyledNextArrow = exports.StyledNextArrow = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledNextArrow",
  componentId: "sc-1o8xv0-8"
})(["\n  width: 65px;\n  height: 65px;\n  position: absolute;\n  display: block;\n  top: 50%;\n  right: -65px;\n  cursor: pointer;\n"]);
var StyledText = exports.StyledText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledText",
  componentId: "sc-1o8xv0-9"
})(["\n  margin: 0;\n  color: #204386;\n  font-family: ", ";\n  font-size: 14px;\n  b,\n  strong {\n    font-family: ", ";\n    font-weight: 400;\n  }\n  font-weight: normal;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: 1.57;\n  letter-spacing: normal;\n  text-align: center;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.font.bold;
});
var StyledButton = exports.StyledButton = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledButton",
  componentId: "sc-1o8xv0-10"
})(["\n  display: flex;\n  position: relative;\n  font-family: ", ";\n  font-weight: 400;\n  flex-direction: row;\n  width: 100%;\n  min-width: 240px;\n\n  cursor: pointer;\n  outline: none;\n  border-radius: 31.5px;\n\n  border: solid 2px #204386;\n  color: #204386;\n  font-family: ", ";\n  font-size: 16px;\n  line-height: 38px;\n\n  text-align: center;\n  text-decoration: none;\n\n  padding-left: 10px;\n  padding-right: 10px;\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.font.regular;
});
var StyledButtonLabel = exports.StyledButtonLabel = _styledComponents.default.span.withConfig({
  displayName: "styles__StyledButtonLabel",
  componentId: "sc-1o8xv0-11"
})(["\n  flex-grow: 1;\n  text-align: center;\n  pointer-events: none;\n"]);
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-1o8xv0-12"
})(["\n  flex-grow: 0;\n  align-self: center;\n  width: 26px;\n  height: 29px;\n"]);