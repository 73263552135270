"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledOffersFormUtilities = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledOffersFormUtilities = exports.StyledOffersFormUtilities = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledOffersFormUtilities",
  componentId: "sc-g08ppr-0"
})(["\n  > div {\n    @media (min-width: 768px) {\n      flex-wrap: nowrap;\n    }\n  }\n"]);