import React, {useState, useEffect} from 'react'
import {compose} from 'recompose'
import axios from 'axios'
import styled from 'styled-components'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import {Wrapper, ArchiveList} from '@alkemy/components'

async function getDocuments() {
  const url = `${
    process.env.WP_API
  }/wp/v2/documents?filter[documents_categories]=casa,business&_fields[]=id&_fields[]=acf&_fields[]=title&_fields[]=documents_categories&per_page=100`
  const data = await axios.get(url).then(x => x.data)

  return data
}

async function getDocumentTags() {
  const url = `${
    process.env.WP_API
  }/wp/v2/documents_categories?_fields[]=id&_fields[]=name&_fields[]=slug&slug=casa,business`
  const data = await axios.get(url).then(x => x.data)

  return data
}

function makeFilters(filter) {
  const {id, name, slug} = filter
  return {
    id,
    label: name,
    value: slug,
  }
}

function makeDocument(archiveDocument) {
  const {id, title, documents_categories, acf} = archiveDocument
  const href = acf ? acf.document_attachment : '#'
  const text = title ? title.rendered : ''
  return {
    id,
    text,
    href,
    label: 'scarica',
    tags: documents_categories,
  }
}

const ArchiveListPB = () => {
  const [filters, setFilters] = useState()
  const [archiveList, setArchiveList] = useState()

  async function fetchWpData() {
    const tags = await getDocumentTags()
    const documentList = await getDocuments()
    setFilters([
      {
        id: -1,
        label: 'Tutti',
        value: 'all',
      },
      ...tags.map(makeFilters),
    ])
    setArchiveList(documentList.map(documentItem => makeDocument(documentItem)))
  }

  useEffect(() => {
    fetchWpData()
  }, [])

  return (
    <Wrapper>
      {filters && archiveList ? (
        <ArchiveList filters={filters} archiveList={archiveList} />
      ) : (
        <Loader>Caricamento...</Loader>
      )}
    </Wrapper>
  )
}

const Loader = styled.div`
  width: 100%;
  margin: 2rem auto;
  text-align: center;
  font-size: 1rem;
  font-family: ${props => props.theme.font.regular};
  color: ${props => props.theme.color.darkBlue};
`

export default compose(withPageBuilder())(ArchiveListPB)
