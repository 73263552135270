import { compose } from 'recompose'

import withGlobalSettings from './withGlobalSettings'
import withIntl from './withIntl'
import withGlobalStyleExceptions from './withGlobalStyleExceptions'
import withError from './withError'
import withMeasureProvider from './withMeasureProvider'
import withSlug from './withSlug'
import withResizeProvider from './withResizeProvider'
import withStickyProvider from './withStickyProvider'
import withSEO from './withSEO'
import withPathname from './withPathname'
import withChatIcon from './withChatIcon'
import withUnsupportedBrowser from './withUnsupportedBrowser'

export default compose(
  withGlobalStyleExceptions(),
  withIntl,
  withError,
  withUnsupportedBrowser,
  withGlobalSettings,
  withMeasureProvider,
  withSlug,
  withResizeProvider,
  withStickyProvider,
  withSEO,
  withPathname,
  withChatIcon,
)

export const withGlobalInitialProps = config =>
  compose(
    withGlobalStyleExceptions(config),
    withIntl,
    withError,
    withUnsupportedBrowser,
    withGlobalSettings,
    withMeasureProvider,
    withSlug,
    withResizeProvider,
    withStickyProvider,
    withSEO,
    withPathname,
    withChatIcon,
  )
