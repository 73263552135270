"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledRow = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledRow = exports.StyledRow = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledRow",
  componentId: "sc-1tbkscy-0"
})(["\n  position: relative;\n  z-index: 1;\n  overflow: hidden;\n\n  ", "\n\n  ", " {\n    margin: 0 -25px;\n    padding: 0 25px;\n\n    ", "\n    ", "\n  }\n\n  ", "\n"], function (props) {
  return props.salesPointContained && (0, _styledComponents.css)(["\n      padding-top: 72px;\n    "]);
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.salesPointHome && (0, _styledComponents.css)(["\n        padding-top: 50px;\n      "]);
}, function (props) {
  return props.salesPointContained && (0, _styledComponents.css)(["\n        padding-top: 72px;\n      "]);
}, function (props) {
  return props.flex && (0, _styledComponents.css)(["\n      display: flex;\n      flex-wrap: wrap;\n    "]);
});