"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledVivipediaArticleListSlider = exports.StyledTitle = exports.StyledSliderItem = exports.StyledPrevArrow = exports.StyledNextArrow = exports.StyledButtonWrapper = exports.StyledArticleWrapper = exports.StyledArticleItem = exports.StyledArrowIconWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledVivipediaArticleListSlider = exports.StyledVivipediaArticleListSlider = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledVivipediaArticleListSlider",
  componentId: "sc-10zwtok-0"
})(["\n  &:last-child {\n    margin: 60px auto 80px !important;\n\n    @media (min-width: 768px) {\n      margin: 60px auto 100px !important;\n    }\n\n    @media (min-width: 1440px) {\n      margin: 60px auto 120px !important;\n    }\n  }\n"]);
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-10zwtok-1"
})(["\n  margin: 40px 0;\n  font-family: ", ";\n  color: ", ";\n  text-align: center;\n  font-size: 18px;\n\n  @media (min-width: 768px) {\n    margin: 60px 0;\n    font-size: 22px;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledArticleWrapper = exports.StyledArticleWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledArticleWrapper",
  componentId: "sc-10zwtok-2"
})(["\n  text-align: center;\n  max-width: auto;\n  margin: 0 auto;\n  > @media (min-width: 768px) {\n    max-width: 100%;\n  }\n\n  @media (min-width: 1440px) {\n    max-width: 1260px;\n  }\n"]);
var StyledArticleItem = exports.StyledArticleItem = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledArticleItem",
  componentId: "sc-10zwtok-3"
})(["\n  margin-bottom: 40px;\n"]);
var StyledSliderItem = exports.StyledSliderItem = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSliderItem",
  componentId: "sc-10zwtok-4"
})(["\n  &:focus {\n    outline: none;\n  }\n"]);
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-10zwtok-5"
})(["\n  width: 180px;\n  margin: 40px auto 0;\n\n  @media (min-width: 768px) {\n    display: none;\n  }\n"]);
var StyledPrevArrow = exports.StyledPrevArrow = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledPrevArrow",
  componentId: "sc-10zwtok-6"
})(["\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  left: -50px;\n  cursor: pointer;\n"]);
var StyledNextArrow = exports.StyledNextArrow = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledNextArrow",
  componentId: "sc-10zwtok-7"
})(["\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  right: -50px;\n  cursor: pointer;\n"]);
var StyledArrowIconWrapper = exports.StyledArrowIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledArrowIconWrapper",
  componentId: "sc-10zwtok-8"
})(["\n  width: 55px;\n  height: 55px;\n"]);