"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WhiteFill = exports.StyledTitle = exports.StyledLoginModal = exports.StyledLoginFormWrapper = exports.StyledListWrapper = exports.StyledInfoWrapper = exports.StyledInfoCaption = exports.StyledImg = exports.StyledIconWrapper = exports.StyledContentWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledLoginModal = exports.StyledLoginModal = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledLoginModal",
  componentId: "sc-5qxqjj-0"
})(["\n  width: 100%;\n  height: 100vh;\n  max-height: 100%;\n  padding-top: 110px;\n  padding-bottom: 100px;\n  position: fixed;\n  top: 0;\n  left: 0;\n  background-image: url('https://vivienergia.imgix.net/uploads/2021/02/15114908/login-320px.png?width=320&auto=format');\n  background-position: right top;\n  background-repeat: no-repeat;\n  background-size: 95% auto;\n  background-color: ", ";\n  opacity: 0;\n  visibility: hidden;\n  transition: all 0.2s ease-in-out 0s;\n  z-index: 999999;\n  overflow: scroll;\n\n  ", "\n\n  @media (min-width: 768px) {\n    padding-top: 35px;\n    padding-bottom: 0;\n    background-image: url('https://vivienergia.imgix.net/uploads/2021/02/15114925/login-768px.png?width=768&auto=format');\n    background-size: contain;\n    background-position: center top;\n  }\n\n  @media (min-width: 1280px) {\n    background-image: url('https://vivienergia.imgix.net/uploads/2021/02/15114952/login-1440px.png?width=1440&auto=format');\n    background-position: left top;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.isVisible && (0, _styledComponents.css)(["\n      opacity: 1;\n      visibility: visible;\n    "]);
});
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-5qxqjj-1"
})(["\n  width: 21px;\n  height: 21px;\n  position: absolute;\n  top: 15px;\n  right: 15px;\n  cursor: pointer;\n\n  @media (min-width: 768px) {\n    width: 52px;\n    height: 52px;\n    top: 30px;\n    right: 30px;\n\n    .white-fill {\n      path {\n        fill: white;\n      }\n    }\n  }\n\n  @media (min-width: 1280px) {\n    width: 65px;\n    height: 65px;\n  }\n"]);
var StyledInfoWrapper = exports.StyledInfoWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledInfoWrapper",
  componentId: "sc-5qxqjj-2"
})(["\n  display: none;\n\n  @media (min-width: 768px) {\n    display: block;\n    width: 430px;\n    min-height: 300px;\n    margin: 0 auto 70px;\n    padding: 35px 0 30px;\n    background-color: ", ";\n    border-radius: 35px;\n    box-shadow: 0 0 35px 10px ", ";\n    position: relative;\n  }\n\n  @media (min-width: 1280px) {\n    //width: auto;\n    //flex-basis: 41.66%;\n    margin-top: 190px;\n    margin-left: 220px;\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.color.shadowBlue;
});
var StyledImg = exports.StyledImg = _styledComponents.default.img.withConfig({
  displayName: "styles__StyledImg",
  componentId: "sc-5qxqjj-3"
})(["\n  display: none;\n\n  @media (min-width: 1280px) {\n    display: block;\n    width: 800px;\n    height: auto;\n    position: absolute;\n    left: 50%;\n    transform: translateX(-50%);\n    z-index: -1;\n    top: -290px;\n  }\n"]);
var StyledListWrapper = exports.StyledListWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledListWrapper",
  componentId: "sc-5qxqjj-4"
})(["\n  @media (min-width: 768px) {\n    padding: 0 80px;\n  }\n\n  @media (min-width: 1280px) {\n    padding: 0 65px;\n    margin-bottom: 30px;\n  }\n"]);
var StyledLoginFormWrapper = exports.StyledLoginFormWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledLoginFormWrapper",
  componentId: "sc-5qxqjj-5"
})(["\n  width: 280px;\n  height: 450px;\n  margin: 0 auto;\n\n  @media (min-width: 768px) {\n    width: 330px;\n  }\n\n  @media (min-width: 1280px) {\n    //width: auto;\n    //flex-basis: 41.66%;\n    height: auto;\n  }\n"]);
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-5qxqjj-6"
})(["\n  @media (min-width: 768px) {\n    margin-bottom: 20px;\n    font-family: ", ";\n    color: ", ";\n    font-size: 22px;\n    text-align: center;\n  }\n\n  @media (min-width: 1280px) {\n    margin-bottom: 30px;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledInfoCaption = exports.StyledInfoCaption = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledInfoCaption",
  componentId: "sc-5qxqjj-7"
})(["\n  font-family: ", ";\n  color: ", ";\n  text-align: center;\n  font-size: 16px;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var WhiteFill = exports.WhiteFill = _styledComponents.default.div.withConfig({
  displayName: "styles__WhiteFill",
  componentId: "sc-5qxqjj-8"
})(["\n  path {\n    fill: white;\n  }\n"]);
var StyledContentWrapper = exports.StyledContentWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContentWrapper",
  componentId: "sc-5qxqjj-9"
})(["\n  overflow: scroll;\n"]);