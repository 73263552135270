import React from 'react'
import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import {
  withPadder,
  SponsorSlider as SponsorSliderComponent,
} from '@alkemy/components'

const SponsorSlider = ({ logos = [], ...props }) => (
  <SponsorSliderComponent {...props} images={logos.map(entry => entry.url)} />
)

export default compose(
  withPageBuilder(),
  withFields([
    {
      prop: 'paddingTop',
      label: 'Distanza dal Top',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'paddingBottom',
      label: 'Distanza dal Bottom',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'images',
      label: 'Loghi',
      type: 'repeater',
      initialValue: [],
      model: [
        {
          prop: 'image',
          type: 'media',
        },
      ],
    },
  ]),
  withPadder,
)(SponsorSlider)
