"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InputBoxContainer = exports.InputBox = exports.IconWrapper = exports.Container = exports.ChildrenContainer = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var ChildrenContainer = exports.ChildrenContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__ChildrenContainer",
  componentId: "sc-1kslbmi-0"
})(["\n  width: 70%;\n  height: 50px;\n  @media (min-width: 1000px) {\n    width: 1000px;\n  }\n"]);
var Container = exports.Container = _styledComponents.default.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-1kslbmi-1"
})(["\n  display: flex;\n  flex-flow: column nowrap;\n  align-items: center;\n  justify-content: space-evenly;\n  width: 100%;\n  height: 280px;\n  position: relative;\n  background: ", ";\n  margin-top: ", "px;\n  ", "\n"], function (props) {
  return props.theme.color.pinkOrange;
}, function (props) {
  return props.headerHeight ? props.headerHeight : 0;
}, function (props) {
  return props.isFullScreen && (0, _styledComponents.css)(["\n      width: 100%;\n      height: 100%;\n    "]);
});
var InputBox = exports.InputBox = _styledComponents.default.input.withConfig({
  displayName: "styles__InputBox",
  componentId: "sc-1kslbmi-2"
})(["\n  width: 100%;\n  height: 100%;\n  background-color: transparent;\n  font-size: 24px;\n  color: #fff;\n  border: 0;\n  outline: none;\n  font-family: ", ";\n  font-weight: 400;\n  ::-webkit-input-placeholder {\n    /* WebKit, Blink, Edge */\n    color: #fff;\n  }\n  :-moz-placeholder {\n    /* Mozilla Firefox 4 to 18 */\n    color: #fff;\n    opacity: 1;\n  }\n  ::-moz-placeholder {\n    /* Mozilla Firefox 19+ */\n    color: #fff;\n    opacity: 1;\n  }\n  :-ms-input-placeholder {\n    /* Internet Explorer 10-11 */\n    color: #fff;\n  }\n  ::-ms-input-placeholder {\n    /* Microsoft Edge */\n    color: #fff;\n  }\n\n  ::placeholder {\n    /* Most modern browsers support this now. */\n    color: #fff;\n  }\n"], function (props) {
  return props.theme.font.medium;
});
var InputBoxContainer = exports.InputBoxContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__InputBoxContainer",
  componentId: "sc-1kslbmi-3"
})(["\n  display: flex;\n  width: 70%;\n  height: 50px;\n  border-bottom: 3px solid #fff;\n  @media (min-width: 1000px) {\n    width: 1000px;\n  }\n"]);
var IconWrapper = exports.IconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__IconWrapper",
  componentId: "sc-1kslbmi-4"
})(["\n  cursor: pointer;\n  width: 40px;\n  padding: 5px;\n  > svg {\n    height: 100%;\n    width: 100%;\n  }\n"]);