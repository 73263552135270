import React, { useState, useEffect } from 'react'
import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'
import { Wrapper, ConfiguratorEntryPage } from '@alkemy/components'

const OffersFormPB = props => {
  return (
    <Wrapper>
      <ConfiguratorEntryPage
        step="utility"
        skipTo="Privato"
        api={process.env.WP_API}
      />
    </Wrapper>
  )
}

export default compose(
  withPageBuilder(),
  withFields([
    {
      prop: 'modals',
      type: 'repeater',
      label: 'Contenuto Tooltip',
      model: [
        {
          prop: 'id',
          type: 'multiple',
          label: 'Tipologia needs',
          options: [
            '4:CAMBIO FORNITORE',
            '1:NUOVA ATTIVAZIONE',
            '2:VOLTURA',
            '3:SUBENTRO',
          ],
          initialValue: '1',
        },
        {
          prop: 'title',
          label: 'Titolo',
        },
        {
          prop: 'text',
          label: 'Testo',
          type: 'WYSIWYG',
        },
      ],
    },
  ]),
)(OffersFormPB)
