"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledRegisterWrapper = exports.StyledPasswordRecoverLink = exports.StyledModalLogin = exports.StyledLoginAlert = exports.StyledLogin = exports.StyledFormRow = exports.StyledForm = exports.StyledCta = exports.StyledCaption = exports.StyledButtonWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledLogin = exports.StyledLogin = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledLogin",
  componentId: "sc-10p36zw-0"
})(["\n  height: 100%;\n  position: relative;\n\n  @media (min-width: 768px) {\n    height: 500px;\n  }\n\n  @media (min-width: 768px) {\n    height: 540px;\n  }\n"]);
var StyledForm = exports.StyledForm = _styledComponents.default.form.withConfig({
  displayName: "styles__StyledForm",
  componentId: "sc-10p36zw-1"
})([""]);
var StyledFormRow = exports.StyledFormRow = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledFormRow",
  componentId: "sc-10p36zw-2"
})(["\n  margin-bottom: 20px;\n  position: relative;\n\n  @media (min-width: 768px) {\n  }\n\n  @media (min-width: 1440px) {\n    margin-bottom: 60px;\n  }\n"]);
var StyledPasswordRecoverLink = exports.StyledPasswordRecoverLink = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledPasswordRecoverLink",
  componentId: "sc-10p36zw-3"
})(["\n  margin: 20px 0 50px;\n  text-decoration: underline;\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n  float: right;\n  cursor: pointer;\n\n  &:visited {\n    color: ", ";\n  }\n\n  @media (min-width: 768px) {\n  }\n\n  @media (min-width: 1440px) {\n    margin-bottom: 80px;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-10p36zw-4"
})(["\n  width: 240px;\n  margin: 0 auto;\n\n  > button {\n    line-height: 42px;\n  }\n\n  @media (min-width: 768px) {\n    width: 120px;\n  }\n"]);
var StyledRegisterWrapper = exports.StyledRegisterWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledRegisterWrapper",
  componentId: "sc-10p36zw-5"
})(["\n  position: absolute;\n  left: 50%;\n  bottom: 20px;\n  transform: translateX(-50%);\n"]);
var StyledCaption = exports.StyledCaption = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCaption",
  componentId: "sc-10p36zw-6"
})(["\n  margin-bottom: 15px;\n  text-align: center;\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.regular;
});
var StyledCta = exports.StyledCta = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledCta",
  componentId: "sc-10p36zw-7"
})(["\n  width: 240px;\n  margin: 0 auto;\n  display: block;\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  line-height: 40px;\n  border: 1px solid ", ";\n  border-radius: 32px;\n  text-align: center;\n  cursor: pointer;\n\n  @media (min-width: 768px) {\n    width: 120px;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledLoginAlert = exports.StyledLoginAlert = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledLoginAlert",
  componentId: "sc-10p36zw-8"
})(["\n  margin-bottom: 20px;\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  line-height: 1.5;\n  text-align: center;\n"], function (props) {
  return props.theme.color.magenta;
}, function (props) {
  return props.theme.font.regular;
});
var StyledModalLogin = exports.StyledModalLogin = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledModalLogin",
  componentId: "sc-10p36zw-9"
})(["\n  padding-top: 20px;\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  line-height: 1.5;\n  text-align: center;\n\n  @media (min-width: 1280px) {\n    font-size: 24px;\n    padding-top: 40px;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.regular;
});