import React from "react"
import { SectionAreaPrivataPuoi } from "@alkemy/components"
import OpenModal from "./OpenModal"

const SectionAreaPrivataPuoiWithLoginModal = props => (
  <OpenModal>
    {({ handleOpen, handleClose }) => (
      <SectionAreaPrivataPuoi {...props} handleLoginModalOpen={handleOpen} />
    )}
  </OpenModal>
)

export default SectionAreaPrivataPuoiWithLoginModal
