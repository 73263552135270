"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitle = exports.StyledStoreLocatorMobileModal = exports.StyledBackWrapper = exports.StyledAccordionList = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledStoreLocatorMobileModal = exports.StyledStoreLocatorMobileModal = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledStoreLocatorMobileModal",
  componentId: "sc-1ujtfcx-0"
})(["\n  width: 100%;\n  min-height: 100vh;\n  padding-top: 90px;\n  position: fixed;\n  top: 0;\n  left: 0; \n  background-image: url('/static/images/store-locator/top-blob.png');\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-position: center top;\n  background-color: ", ";\n  z-index: 100000;\n  \n  @media (min-width: 768px) {\n    display: none;\n  }\n"], function (props) {
  return props.theme.color.white;
});
var StyledBackWrapper = exports.StyledBackWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBackWrapper",
  componentId: "sc-1ujtfcx-1"
})(["\n  width: 34px;\n  position: absolute;\n  top: 14px;\n  left: 10px;\n"]);
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-1ujtfcx-2"
})(["\n  width: 235px;\n  margin: 0 auto;\n  font-family: ", ";\n  color: ", ";\n  text-align: center;\n  font-size: 18px;\n  line-height: 1.3;\n  text-transform: uppercase;\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledAccordionList = exports.StyledAccordionList = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledAccordionList",
  componentId: "sc-1ujtfcx-3"
})(["\n  \n"]);