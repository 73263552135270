"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Wrapper = exports.StyledTitle = exports.StyledTextReg = exports.StyledListWrapper = exports.StyledListWithRoundImgPmi = exports.StyledListWithRoundImgNoPadPmi = exports.StyledImg = exports.StyledBoldText = exports.Container = exports.Col = exports.Block = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledListWithRoundImgPmi = exports.StyledListWithRoundImgPmi = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledListWithRoundImgPmi",
  componentId: "sc-l5ee5k-0"
})(["\n  background-color: #edf0f3;\n"]);
var StyledListWithRoundImgNoPadPmi = exports.StyledListWithRoundImgNoPadPmi = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledListWithRoundImgNoPadPmi",
  componentId: "sc-l5ee5k-1"
})([""]);
var StyledImg = exports.StyledImg = _styledComponents.default.img.withConfig({
  displayName: "styles__StyledImg",
  componentId: "sc-l5ee5k-2"
})([""]);
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-l5ee5k-3"
})(["\n  padding-top: 50px;\n  font-family: ", ";\n  color: #3f5063;\n  font-size: 22px;\n  text-align: center;\n\n  @media (min-width: 1440px) {\n  }\n"], function (props) {
  return props.theme.font.medium;
});
var StyledTextReg = exports.StyledTextReg = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTextReg",
  componentId: "sc-l5ee5k-4"
})(["\n  margin-bottom: 10px;\n  font-family: ", ";\n  color: ", ";\n  font-size: 18px;\n  text-align: left;\n\n  @media (min-width: 1440px) {\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledListWrapper = exports.StyledListWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledListWrapper",
  componentId: "sc-l5ee5k-5"
})([""]);
var StyledBoldText = exports.StyledBoldText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBoldText",
  componentId: "sc-l5ee5k-6"
})(["\n  color: #ffffff;\n"]);
var Container = exports.Container = _styledComponents.default.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-l5ee5k-7"
})(["\n  max-width: 1170px;\n  margin: auto;\n"]);
var Wrapper = exports.Wrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-l5ee5k-8"
})(["\n  display: flex;\n  margin: 50px auto;\n  background-size: contain;\n  background-position: center center;\n  background-repeat: no-repeat;\n"]);
var Col = exports.Col = _styledComponents.default.div.withConfig({
  displayName: "styles__Col",
  componentId: "sc-l5ee5k-9"
})(["\n  display: flex;\n  flex: 1;\n  align-items: center;\n  @media (max-width: 1024px) {\n    margin-top: 50px;\n    padding: 30px 0;\n  }\n"]);
var Block = exports.Block = _styledComponents.default.div.withConfig({
  displayName: "styles__Block",
  componentId: "sc-l5ee5k-10"
})(["\n  margin: 0;\n  padding: 0;\n  padding-top: 20px;\n  border-bottom: 1px solid #ccc;\n  padding-bottom: 20px;\n  font-family: ", ";\n  font-weight: 400;\n  color: #3f5063;\n"], function (props) {
  return props.theme.font.regular;
});