"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SearchArticle = exports.LoadMoreData = exports.GetElements = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var GetElements = exports.GetElements = function GetElements(array, length) {
  var relevant;
  var elements = (0, _toConsumableArray2.default)(array);

  // console.log(JSON.stringify(elements[1]))

  array.map(function (item, i) {
    if (item.isRelevant) {
      relevant = elements.splice(i, 1);
      elements.reverse();
      elements.splice(0, 0, item);
    }
  });
  if (length !== undefined) {
    if (length < elements.length) {
      return elements.splice(0, length);
    } else if (length >= elements.length) {
      return elements;
    }
  } else {
    return elements;
  }
  if (!elements[0].isRelevant) {
    elements.reverse();
  }
};
var SearchArticle = exports.SearchArticle = function SearchArticle(data, terms) {
  var result = [];
  var termsArray;
  if (terms === undefined || terms.length <= 0) {
    return result;
  }
  termsArray = terms.split(' ');
  data.map(function (item) {
    var found = true;
    for (var i = 0; i < termsArray.length; i++) {
      if (item.tags.indexOf(termsArray[i]) < 0) {
        found = false;
      }
    }
    if (found) {
      result.push(item);
    }
  });
  if (result.length <= 0) {
    result.push('Nessun risultato');
  }
  return result;
};
var LoadMoreData = exports.LoadMoreData = function LoadMoreData() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var currentIndex = arguments.length > 1 ? arguments[1] : undefined;
  var step = arguments.length > 2 ? arguments[2] : undefined;
  var arr;
  if (currentIndex + step >= data.length) {
    return data;
  }
  arr = data.slice(0, currentIndex + step);
  return arr;
};