"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitleStep = exports.StyledSubtitle = exports.StyledFooterTitle = exports.StyledFooterText = exports.StyledFooter = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledTitleStep = exports.StyledTitleStep = _styledComponents.default.h2.withConfig({
  displayName: "styles__StyledTitleStep",
  componentId: "sc-aaswb2-0"
})(["\n  font-family: ", ";\n  font-size: 18px;\n  line-height: 1.44;\n  color: ", ";\n  text-align: center;\n  margin: 40px 0 24px;\n\n  ", " {\n    font-size: 45px;\n    line-height: 1.33;\n    margin: 74px 0 48px;\n    max-width: none;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledSubtitle = exports.StyledSubtitle = _styledComponents.default.h3.withConfig({
  displayName: "styles__StyledSubtitle",
  componentId: "sc-aaswb2-1"
})(["\n  font-family: ", ";\n  font-size: 14px;\n  line-height: 1.5;\n  color: ", ";\n  text-align: center;\n  margin: 0 0 8px;\n\n  ", " {\n    font-size: 24px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledFooter = exports.StyledFooter = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledFooter",
  componentId: "sc-aaswb2-2"
})(["\n  height: 257px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-image: url('", "/images/common/configurator-footerBg-sm.svg');\n  background-repeat: no-repeat;\n  background-size: cover;\n  text-align: left;\n\n  ", " {\n    background-image: url('", "/images/common/configurator-footerBg-lg.svg');\n  }\n\n  > div {\n    max-width: 970px;\n    width: 90%;\n  }\n\n  ", " {\n    height: 183px;\n  }\n"], function (props) {
  return props.theme.assetsUrl;
}, function (props) {
  return props.theme.mediaQueries.desktop;
}, function (props) {
  return props.theme.assetsUrl;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var StyledFooterTitle = exports.StyledFooterTitle = _styledComponents.default.h2.withConfig({
  displayName: "styles__StyledFooterTitle",
  componentId: "sc-aaswb2-3"
})(["\n  font-family: ", ";\n  font-size: 30px;\n  color: ", ";\n\n  ", " {\n    font-size: 18px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var StyledFooterText = exports.StyledFooterText = _styledComponents.default.h3.withConfig({
  displayName: "styles__StyledFooterText",
  componentId: "sc-aaswb2-4"
})(["\n  position: relative;\n  font-family: ", ";\n  font-size: 20px;\n  color: ", ";\n  margin-top: 0;\n  display: inline-block;\n\n  a {\n    text-decoration: none;\n    color: ", ";\n  }\n\n  ", " {\n    font-size: 14px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.mediaQueries.phone;
});