"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledArchiveWrapper = exports.StyledArchiveList = exports.StyledArchiveItem = exports.FilterWrapper = exports.ButtonPadding = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledArchiveList = exports.StyledArchiveList = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledArchiveList",
  componentId: "sc-1ud5b51-0"
})(["\n  padding-top: 10rem;\n"]);
var StyledArchiveWrapper = exports.StyledArchiveWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledArchiveWrapper",
  componentId: "sc-1ud5b51-1"
})(["\n  width: 100%;\n  max-width: 300px;\n  margin: 0 auto;\n\n  @media (min-width: 768px) {\n    max-width: 540px;\n    //display: flex;\n    //flex-wrap: wrap;\n    //flex-direction: row;\n    //justify-content: space-between;\n    overflow: hidden;\n  }\n\n  @media (min-width: 1440px) {\n    max-width: 1170px;\n  }\n"]);
var StyledArchiveItem = exports.StyledArchiveItem = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledArchiveItem",
  componentId: "sc-1ud5b51-2"
})(["\n  margin-bottom: 80px;\n\n  @media (min-width: 768px) {\n    float: left;\n    width: 50%;\n  }\n\n  @media (min-width: 1440px) {\n    width: 25%;\n  }\n"]);
var FilterWrapper = exports.FilterWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__FilterWrapper",
  componentId: "sc-1ud5b51-3"
})(["\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: center;\n  padding: 0 0 5rem;\n"]);
var ButtonPadding = exports.ButtonPadding = _styledComponents.default.div.withConfig({
  displayName: "styles__ButtonPadding",
  componentId: "sc-1ud5b51-4"
})(["\n  padding: 0 1rem;\n"]);