"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TextInputsContainer = exports.TextInputWrapper = exports.TextInputErrorMessage = exports.StyledWorkWithUsForm = exports.StyledTitle = exports.StyledResultMessage = exports.StyledPrivacyDisclaimer = exports.StyledForm = exports.StyledDescription = exports.StyledCVInput = exports.ButtonWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledWorkWithUsForm = exports.StyledWorkWithUsForm = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledWorkWithUsForm",
  componentId: "sc-shine4-0"
})(["\n  margin: 50px auto 50px;\n  padding: 60px 0 40px;\n  color: ", ";\n  font-family: ", ";\n  border-top: 1px solid ", ";\n  border-bottom: 1px solid ", ";\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.gray;
}, function (props) {
  return props.theme.color.gray;
});
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-shine4-1"
})(["\n  text-align: center;\n  margin: 0 30px;\n  font-size: 22px;\n  line-height: 1;\n"]);
var StyledDescription = exports.StyledDescription = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDescription",
  componentId: "sc-shine4-2"
})(["\n  margin: 30px 30px 0;\n"]);
var StyledForm = exports.StyledForm = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledForm",
  componentId: "sc-shine4-3"
})(["\n  padding-bottom: 30px;\n  position: relative;\n  margin-top: 30px;\n"]);
var StyledCVInput = exports.StyledCVInput = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCVInput",
  componentId: "sc-shine4-4"
})(["\n  display: flex;\n  height: 100%;\n  position: relative;\n  align-items: flex-end;\n\n  & > div {\n    margin: 0;\n    color: ", ";\n  }\n\n  & > input[type='file'] {\n    display: none;\n  }\n\n  & > span {\n    display: block;\n    width: 100%;\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    transform: translateY(100%);\n    font-size: 14px;\n    text-align: center;\n  }\n"], function (props) {
  return props.theme.color.blue;
});
var StyledPrivacyDisclaimer = exports.StyledPrivacyDisclaimer = _styledComponents.default.p.withConfig({
  displayName: "styles__StyledPrivacyDisclaimer",
  componentId: "sc-shine4-5"
})(["\n  margin: 30px 30px 60px;\n  font-size: 13px;\n  line-height: 1.5;\n\n  a {\n    color: ", ";\n  }\n"], function (props) {
  return props.theme.color.red;
});
var StyledResultMessage = exports.StyledResultMessage = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledResultMessage",
  componentId: "sc-shine4-6"
})(["\n  display: flex;\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 2;\n  justify-content: center;\n  align-items: center;\n  background-color: white;\n"]);
var TextInputsContainer = exports.TextInputsContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__TextInputsContainer",
  componentId: "sc-shine4-7"
})(["\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  margin: 0px auto 40px auto;\n"]);
var TextInputWrapper = exports.TextInputWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__TextInputWrapper",
  componentId: "sc-shine4-8"
})(["\n  width: calc(100% - 60px);\n  margin: 30px;\n\n  @media screen and (min-width: 768px) {\n    ", "\n  }\n"], function (_ref) {
  var fullwidth = _ref.fullwidth;
  return !fullwidth ? 'width: calc(50% - 60px);' : null;
});
var TextInputErrorMessage = exports.TextInputErrorMessage = _styledComponents.default.div.withConfig({
  displayName: "styles__TextInputErrorMessage",
  componentId: "sc-shine4-9"
})(["\n  margin: 10px auto 0px auto;\n  text-align: center;\n  color: red;\n  font-family: ", ";\n"], function (props) {
  return props.theme.font.bold;
});
var ButtonWrapper = exports.ButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__ButtonWrapper",
  componentId: "sc-shine4-10"
})(["\n  margin: auto;\n  max-width: 200px;\n"]);