import { compose } from "recompose"
import withPageBuilder from "@alkemy/pagebuilder/lib/hoc/withPageBuilder"
import withFields from "@alkemy/pagebuilder/lib/hoc/withFields"

import {
  ContestPrizeBlock,
  withWrapper
} from "@alkemy/components"

export default compose(
  withPageBuilder({
    niceName: "Box Concorso Premio",
  }),
  withFields([{
      prop: "wrapped",
      type: "boolean",
      label: "Contenuto Boxed",
    },
    {
      prop: "text",
      label: "Testo Blocco",
      type: "WYSIWYG"
    },
    {
      prop: "prizeImg",
      label: "Immagine Blocco",
    },
  ]),
  withWrapper
)(ContestPrizeBlock)