"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "APlusPlus", {
  enumerable: true,
  get: function get() {
    return _APlusPlus.default;
  }
});
Object.defineProperty(exports, "Amazon50", {
  enumerable: true,
  get: function get() {
    return _Amazon.default;
  }
});
Object.defineProperty(exports, "Amazon50nobg", {
  enumerable: true,
  get: function get() {
    return _Amazon50nobg.default;
  }
});
Object.defineProperty(exports, "Amazon75", {
  enumerable: true,
  get: function get() {
    return _Amazon2.default;
  }
});
Object.defineProperty(exports, "Amazon75nobg", {
  enumerable: true,
  get: function get() {
    return _Amazon75nobg.default;
  }
});
Object.defineProperty(exports, "AnalisiEnergetica", {
  enumerable: true,
  get: function get() {
    return _AnalisiEnergetica.default;
  }
});
Object.defineProperty(exports, "App", {
  enumerable: true,
  get: function get() {
    return _App.default;
  }
});
Object.defineProperty(exports, "App01", {
  enumerable: true,
  get: function get() {
    return _App2.default;
  }
});
Object.defineProperty(exports, "AreaClienti", {
  enumerable: true,
  get: function get() {
    return _AreaClienti.default;
  }
});
Object.defineProperty(exports, "Attivazione", {
  enumerable: true,
  get: function get() {
    return _Attivazione.default;
  }
});
Object.defineProperty(exports, "Autolettura", {
  enumerable: true,
  get: function get() {
    return _Autolettura.default;
  }
});
Object.defineProperty(exports, "Batteria", {
  enumerable: true,
  get: function get() {
    return _Batteria.default;
  }
});
Object.defineProperty(exports, "Bimestrale", {
  enumerable: true,
  get: function get() {
    return _Bimestrale.default;
  }
});
Object.defineProperty(exports, "Bioraria", {
  enumerable: true,
  get: function get() {
    return _Bioraria.default;
  }
});
Object.defineProperty(exports, "Birthday", {
  enumerable: true,
  get: function get() {
    return _Birthday.default;
  }
});
Object.defineProperty(exports, "BollettaOnline", {
  enumerable: true,
  get: function get() {
    return _BollettaOnline.default;
  }
});
Object.defineProperty(exports, "BusinessIcon", {
  enumerable: true,
  get: function get() {
    return _BusinessIcon.default;
  }
});
Object.defineProperty(exports, "Caldaia", {
  enumerable: true,
  get: function get() {
    return _Caldaia.default;
  }
});
Object.defineProperty(exports, "Chat", {
  enumerable: true,
  get: function get() {
    return _Chat.default;
  }
});
Object.defineProperty(exports, "Chatra", {
  enumerable: true,
  get: function get() {
    return _Chatra.default;
  }
});
Object.defineProperty(exports, "ChattaConNoiNew", {
  enumerable: true,
  get: function get() {
    return _ChattaConNoiNew.default;
  }
});
Object.defineProperty(exports, "ChiaviInMano", {
  enumerable: true,
  get: function get() {
    return _ChiaviInMano.default;
  }
});
Object.defineProperty(exports, "ClasseAEnergetica", {
  enumerable: true,
  get: function get() {
    return _ClasseAEnergetica.default;
  }
});
Object.defineProperty(exports, "CliccaQui", {
  enumerable: true,
  get: function get() {
    return _CliccaQui.default;
  }
});
Object.defineProperty(exports, "ClienteBronze", {
  enumerable: true,
  get: function get() {
    return _ClienteBronze.default;
  }
});
Object.defineProperty(exports, "ClienteGold", {
  enumerable: true,
  get: function get() {
    return _ClienteGold.default;
  }
});
Object.defineProperty(exports, "ClientePlatinum", {
  enumerable: true,
  get: function get() {
    return _ClientePlatinum.default;
  }
});
Object.defineProperty(exports, "ClienteSilver", {
  enumerable: true,
  get: function get() {
    return _ClienteSilver.default;
  }
});
Object.defineProperty(exports, "ClienteUnico", {
  enumerable: true,
  get: function get() {
    return _ClienteUnico.default;
  }
});
Object.defineProperty(exports, "Clima", {
  enumerable: true,
  get: function get() {
    return _Clima.default;
  }
});
Object.defineProperty(exports, "Co2", {
  enumerable: true,
  get: function get() {
    return _Co.default;
  }
});
Object.defineProperty(exports, "Competitiviness", {
  enumerable: true,
  get: function get() {
    return _Competitiviness.default;
  }
});
Object.defineProperty(exports, "Consigli", {
  enumerable: true,
  get: function get() {
    return _Consigli.default;
  }
});
Object.defineProperty(exports, "ConsigliDiRisparmio", {
  enumerable: true,
  get: function get() {
    return _ConsigliDiRisparmio.default;
  }
});
Object.defineProperty(exports, "Contacts", {
  enumerable: true,
  get: function get() {
    return _Contacts.default;
  }
});
Object.defineProperty(exports, "Contatti", {
  enumerable: true,
  get: function get() {
    return _Contatti.default;
  }
});
Object.defineProperty(exports, "ContattiNew", {
  enumerable: true,
  get: function get() {
    return _ContattiNew.default;
  }
});
Object.defineProperty(exports, "Convenienza", {
  enumerable: true,
  get: function get() {
    return _Convenienza.default;
  }
});
Object.defineProperty(exports, "CostEffective", {
  enumerable: true,
  get: function get() {
    return _CostEffective.default;
  }
});
Object.defineProperty(exports, "CustomService", {
  enumerable: true,
  get: function get() {
    return _CustomService.default;
  }
});
Object.defineProperty(exports, "DB20", {
  enumerable: true,
  get: function get() {
    return _DB.default;
  }
});
Object.defineProperty(exports, "Design", {
  enumerable: true,
  get: function get() {
    return _Design.default;
  }
});
Object.defineProperty(exports, "Detrazione", {
  enumerable: true,
  get: function get() {
    return _Detrazione.default;
  }
});
Object.defineProperty(exports, "Detrazione50", {
  enumerable: true,
  get: function get() {
    return _Detrazione2.default;
  }
});
Object.defineProperty(exports, "Detrazione65", {
  enumerable: true,
  get: function get() {
    return _Detrazione3.default;
  }
});
Object.defineProperty(exports, "Dilazione", {
  enumerable: true,
  get: function get() {
    return _Dilazione.default;
  }
});
Object.defineProperty(exports, "Documentation", {
  enumerable: true,
  get: function get() {
    return _Documentation.default;
  }
});
Object.defineProperty(exports, "DomesticoNew", {
  enumerable: true,
  get: function get() {
    return _DomesticoNew.default;
  }
});
Object.defineProperty(exports, "EasyPayment", {
  enumerable: true,
  get: function get() {
    return _EasyPayment.default;
  }
});
Object.defineProperty(exports, "EnergiaFlessibile", {
  enumerable: true,
  get: function get() {
    return _EnergiaFlessibile.default;
  }
});
Object.defineProperty(exports, "Evening", {
  enumerable: true,
  get: function get() {
    return _Evening.default;
  }
});
Object.defineProperty(exports, "FatturazioneEDeposito", {
  enumerable: true,
  get: function get() {
    return _FatturazioneEDeposito.default;
  }
});
Object.defineProperty(exports, "Fidelity", {
  enumerable: true,
  get: function get() {
    return _Fidelity.default;
  }
});
Object.defineProperty(exports, "Filtro", {
  enumerable: true,
  get: function get() {
    return _Filtro.default;
  }
});
Object.defineProperty(exports, "FiltroAltaDensita", {
  enumerable: true,
  get: function get() {
    return _FiltroAltaDensita.default;
  }
});
Object.defineProperty(exports, "Fotovoltaico", {
  enumerable: true,
  get: function get() {
    return _Fotovoltaico.default;
  }
});
Object.defineProperty(exports, "GasEco", {
  enumerable: true,
  get: function get() {
    return _GasEco.default;
  }
});
Object.defineProperty(exports, "GestioneIntell", {
  enumerable: true,
  get: function get() {
    return _GestioneIntell.default;
  }
});
Object.defineProperty(exports, "Grafico", {
  enumerable: true,
  get: function get() {
    return _Grafico.default;
  }
});
Object.defineProperty(exports, "Green", {
  enumerable: true,
  get: function get() {
    return _Green.default;
  }
});
Object.defineProperty(exports, "GuidaEnergia", {
  enumerable: true,
  get: function get() {
    return _GuidaEnergia.default;
  }
});
Object.defineProperty(exports, "Installazione", {
  enumerable: true,
  get: function get() {
    return _Installazione.default;
  }
});
Object.defineProperty(exports, "MobilePhone", {
  enumerable: true,
  get: function get() {
    return _Phone.default;
  }
});
Object.defineProperty(exports, "ModulisticaGiudaEnergetica", {
  enumerable: true,
  get: function get() {
    return _ModulisticaGuidaEnergetica.default;
  }
});
Object.defineProperty(exports, "Monoraria", {
  enumerable: true,
  get: function get() {
    return _Monoraria.default;
  }
});
Object.defineProperty(exports, "NoRisks", {
  enumerable: true,
  get: function get() {
    return _NoRisks.default;
  }
});
Object.defineProperty(exports, "NumeroVerde", {
  enumerable: true,
  get: function get() {
    return _NumeroVerde.default;
  }
});
Object.defineProperty(exports, "OneHand", {
  enumerable: true,
  get: function get() {
    return _OneHand.default;
  }
});
Object.defineProperty(exports, "PassaAVivigas", {
  enumerable: true,
  get: function get() {
    return _PassaAVivigas.default;
  }
});
Object.defineProperty(exports, "Percentuale", {
  enumerable: true,
  get: function get() {
    return _Percentuale.default;
  }
});
Object.defineProperty(exports, "PrezzoBloccato", {
  enumerable: true,
  get: function get() {
    return _PrezzoBloccato.default;
  }
});
Object.defineProperty(exports, "Prontoweb", {
  enumerable: true,
  get: function get() {
    return _Prontoweb.default;
  }
});
Object.defineProperty(exports, "Protezione", {
  enumerable: true,
  get: function get() {
    return _Protezione.default;
  }
});
Object.defineProperty(exports, "Qualita", {
  enumerable: true,
  get: function get() {
    return _Qualita.default;
  }
});
Object.defineProperty(exports, "Rapporto", {
  enumerable: true,
  get: function get() {
    return _Rapporto.default;
  }
});
Object.defineProperty(exports, "RiduzioneConsumi", {
  enumerable: true,
  get: function get() {
    return _RiduzioneConsumi.default;
  }
});
Object.defineProperty(exports, "Satispay", {
  enumerable: true,
  get: function get() {
    return _Satispay.default;
  }
});
Object.defineProperty(exports, "ServiziPerLaCasa", {
  enumerable: true,
  get: function get() {
    return _ServiziPerLaCasa.default;
  }
});
Object.defineProperty(exports, "ServizioClienti", {
  enumerable: true,
  get: function get() {
    return _ServizioClienti.default;
  }
});
Object.defineProperty(exports, "Shop", {
  enumerable: true,
  get: function get() {
    return _Shop.default;
  }
});
Object.defineProperty(exports, "ShopAlt", {
  enumerable: true,
  get: function get() {
    return _ShopAlt.default;
  }
});
Object.defineProperty(exports, "Silenziosita", {
  enumerable: true,
  get: function get() {
    return _Silenziosita.default;
  }
});
Object.defineProperty(exports, "SingleBill", {
  enumerable: true,
  get: function get() {
    return _SingleBill.default;
  }
});
Object.defineProperty(exports, "SoddisfattiORimbordati", {
  enumerable: true,
  get: function get() {
    return _SoddisfattiORimbordati.default;
  }
});
Object.defineProperty(exports, "Sole", {
  enumerable: true,
  get: function get() {
    return _Sole.default;
  }
});
Object.defineProperty(exports, "SoluzioniPerGrandiAziende", {
  enumerable: true,
  get: function get() {
    return _SoluzioniPerGrandiAziende.default;
  }
});
Object.defineProperty(exports, "SoluzioniPmi", {
  enumerable: true,
  get: function get() {
    return _SoluzioniPmi.default;
  }
});
Object.defineProperty(exports, "SoluzioniTecnologiche", {
  enumerable: true,
  get: function get() {
    return _SoluzioniTecnologiche.default;
  }
});
Object.defineProperty(exports, "SplitCondizionatore", {
  enumerable: true,
  get: function get() {
    return _SplitCondizionatore.default;
  }
});
Object.defineProperty(exports, "StoreNew", {
  enumerable: true,
  get: function get() {
    return _StoreNew.default;
  }
});
Object.defineProperty(exports, "Subentro", {
  enumerable: true,
  get: function get() {
    return _Subentro.default;
  }
});
Object.defineProperty(exports, "Switch", {
  enumerable: true,
  get: function get() {
    return _Switch.default;
  }
});
Object.defineProperty(exports, "Telegram", {
  enumerable: true,
  get: function get() {
    return _Telegram.default;
  }
});
Object.defineProperty(exports, "Telethon", {
  enumerable: true,
  get: function get() {
    return _Telethon.default;
  }
});
Object.defineProperty(exports, "TimeToMarket", {
  enumerable: true,
  get: function get() {
    return _TimeToMarket.default;
  }
});
Object.defineProperty(exports, "Trustworthy", {
  enumerable: true,
  get: function get() {
    return _Trustworthy.default;
  }
});
Object.defineProperty(exports, "Tvtb", {
  enumerable: true,
  get: function get() {
    return _Tvtb.default;
  }
});
Object.defineProperty(exports, "Updating", {
  enumerable: true,
  get: function get() {
    return _Updating.default;
  }
});
Object.defineProperty(exports, "User", {
  enumerable: true,
  get: function get() {
    return _User.default;
  }
});
Object.defineProperty(exports, "VIVI_HUB", {
  enumerable: true,
  get: function get() {
    return _VIVI_HUB.default;
  }
});
Object.defineProperty(exports, "VIVI_STORE", {
  enumerable: true,
  get: function get() {
    return _VIVI_STORE.default;
  }
});
Object.defineProperty(exports, "VIVIvip_Logo", {
  enumerable: true,
  get: function get() {
    return _VIVIvip_Logo.default;
  }
});
Object.defineProperty(exports, "VIVIvip_Punti", {
  enumerable: true,
  get: function get() {
    return _VIVIvip_Punti.default;
  }
});
Object.defineProperty(exports, "Visibility", {
  enumerable: true,
  get: function get() {
    return _Visibility.default;
  }
});
Object.defineProperty(exports, "VisibilityDocument", {
  enumerable: true,
  get: function get() {
    return _VisibilityDocument.default;
  }
});
Object.defineProperty(exports, "ViviLoyalty", {
  enumerable: true,
  get: function get() {
    return _ViviLoyalty.default;
  }
});
Object.defineProperty(exports, "Voltura", {
  enumerable: true,
  get: function get() {
    return _Voltura.default;
  }
});
Object.defineProperty(exports, "Wallbox", {
  enumerable: true,
  get: function get() {
    return _Wbox.default;
  }
});
Object.defineProperty(exports, "WiFi", {
  enumerable: true,
  get: function get() {
    return _WiFi.default;
  }
});
var _Amazon = _interopRequireDefault(require("./Amazon50"));
var _Amazon50nobg = _interopRequireDefault(require("./Amazon50nobg"));
var _Amazon2 = _interopRequireDefault(require("./Amazon75"));
var _Amazon75nobg = _interopRequireDefault(require("./Amazon75nobg"));
var _AnalisiEnergetica = _interopRequireDefault(require("./AnalisiEnergetica"));
var _App = _interopRequireDefault(require("./App"));
var _App2 = _interopRequireDefault(require("./App01"));
var _AreaClienti = _interopRequireDefault(require("./AreaClienti"));
var _Attivazione = _interopRequireDefault(require("./Attivazione"));
var _Autolettura = _interopRequireDefault(require("./Autolettura"));
var _Bimestrale = _interopRequireDefault(require("./Bimestrale"));
var _Bioraria = _interopRequireDefault(require("./Bioraria"));
var _BollettaOnline = _interopRequireDefault(require("./BollettaOnline"));
var _BusinessIcon = _interopRequireDefault(require("./BusinessIcon"));
var _ChiaviInMano = _interopRequireDefault(require("./ChiaviInMano"));
var _ChattaConNoiNew = _interopRequireDefault(require("./ChattaConNoiNew"));
var _CliccaQui = _interopRequireDefault(require("./CliccaQui"));
var _ClasseAEnergetica = _interopRequireDefault(require("./ClasseAEnergetica"));
var _ClienteBronze = _interopRequireDefault(require("./ClienteBronze"));
var _ClienteGold = _interopRequireDefault(require("./ClienteGold"));
var _ClientePlatinum = _interopRequireDefault(require("./ClientePlatinum"));
var _ClienteSilver = _interopRequireDefault(require("./ClienteSilver"));
var _ClienteUnico = _interopRequireDefault(require("./ClienteUnico"));
var _Co = _interopRequireDefault(require("./Co2"));
var _Consigli = _interopRequireDefault(require("./Consigli"));
var _CostEffective = _interopRequireDefault(require("./CostEffective"));
var _ContattiNew = _interopRequireDefault(require("./ContattiNew"));
var _Phone = _interopRequireDefault(require("./Phone"));
var _Telegram = _interopRequireDefault(require("./Telegram"));
var _User = _interopRequireDefault(require("./User"));
var _Chat = _interopRequireDefault(require("./Chat"));
var _Chatra = _interopRequireDefault(require("./Chatra"));
var _Competitiviness = _interopRequireDefault(require("./Competitiviness"));
var _ConsigliDiRisparmio = _interopRequireDefault(require("./ConsigliDiRisparmio"));
var _Contacts = _interopRequireDefault(require("./Contacts"));
var _Contatti = _interopRequireDefault(require("./Contatti"));
var _Convenienza = _interopRequireDefault(require("./Convenienza"));
var _CustomService = _interopRequireDefault(require("./CustomService"));
var _Dilazione = _interopRequireDefault(require("./Dilazione"));
var _Documentation = _interopRequireDefault(require("./Documentation"));
var _DomesticoNew = _interopRequireDefault(require("./DomesticoNew"));
var _EasyPayment = _interopRequireDefault(require("./EasyPayment"));
var _EnergiaFlessibile = _interopRequireDefault(require("./EnergiaFlessibile"));
var _Evening = _interopRequireDefault(require("./Evening"));
var _FatturazioneEDeposito = _interopRequireDefault(require("./FatturazioneEDeposito"));
var _Fidelity = _interopRequireDefault(require("./Fidelity"));
var _Grafico = _interopRequireDefault(require("./Grafico"));
var _Green = _interopRequireDefault(require("./Green"));
var _GuidaEnergia = _interopRequireDefault(require("./GuidaEnergia"));
var _Installazione = _interopRequireDefault(require("./Installazione"));
var _Monoraria = _interopRequireDefault(require("./Monoraria"));
var _ModulisticaGuidaEnergetica = _interopRequireDefault(require("./ModulisticaGuidaEnergetica"));
var _NoRisks = _interopRequireDefault(require("./NoRisks"));
var _NumeroVerde = _interopRequireDefault(require("./NumeroVerde"));
var _PassaAVivigas = _interopRequireDefault(require("./PassaAVivigas"));
var _Percentuale = _interopRequireDefault(require("./Percentuale"));
var _PrezzoBloccato = _interopRequireDefault(require("./PrezzoBloccato"));
var _Prontoweb = _interopRequireDefault(require("./Prontoweb"));
var _Qualita = _interopRequireDefault(require("./Qualita"));
var _Rapporto = _interopRequireDefault(require("./Rapporto"));
var _Satispay = _interopRequireDefault(require("./Satispay"));
var _ServizioClienti = _interopRequireDefault(require("./ServizioClienti"));
var _ServiziPerLaCasa = _interopRequireDefault(require("./ServiziPerLaCasa"));
var _Shop = _interopRequireDefault(require("./Shop"));
var _ShopAlt = _interopRequireDefault(require("./ShopAlt"));
var _SingleBill = _interopRequireDefault(require("./SingleBill"));
var _Sole = _interopRequireDefault(require("./Sole"));
var _SoddisfattiORimbordati = _interopRequireDefault(require("./SoddisfattiORimbordati"));
var _SoluzioniPerGrandiAziende = _interopRequireDefault(require("./SoluzioniPerGrandiAziende"));
var _SoluzioniPmi = _interopRequireDefault(require("./SoluzioniPmi"));
var _SoluzioniTecnologiche = _interopRequireDefault(require("./SoluzioniTecnologiche"));
var _Subentro = _interopRequireDefault(require("./Subentro"));
var _StoreNew = _interopRequireDefault(require("./StoreNew"));
var _Switch = _interopRequireDefault(require("./Switch"));
var _TimeToMarket = _interopRequireDefault(require("./TimeToMarket"));
var _Trustworthy = _interopRequireDefault(require("./Trustworthy"));
var _Tvtb = _interopRequireDefault(require("./Tvtb"));
var _Updating = _interopRequireDefault(require("./Updating"));
var _Visibility = _interopRequireDefault(require("./Visibility"));
var _VisibilityDocument = _interopRequireDefault(require("./VisibilityDocument"));
var _Voltura = _interopRequireDefault(require("./Voltura"));
var _DB = _interopRequireDefault(require("./DB20"));
var _SplitCondizionatore = _interopRequireDefault(require("./SplitCondizionatore"));
var _APlusPlus = _interopRequireDefault(require("./APlusPlus"));
var _WiFi = _interopRequireDefault(require("./WiFi"));
var _FiltroAltaDensita = _interopRequireDefault(require("./FiltroAltaDensita"));
var _Detrazione = _interopRequireDefault(require("./Detrazione"));
var _ViviLoyalty = _interopRequireDefault(require("./ViviLoyalty"));
var _GasEco = _interopRequireDefault(require("./GasEco"));
var _Silenziosita = _interopRequireDefault(require("./Silenziosita"));
var _Wbox = _interopRequireDefault(require("./Wbox"));
var _Batteria = _interopRequireDefault(require("./Batteria"));
var _Caldaia = _interopRequireDefault(require("./Caldaia"));
var _Clima = _interopRequireDefault(require("./Clima"));
var _Design = _interopRequireDefault(require("./Design"));
var _Detrazione2 = _interopRequireDefault(require("./Detrazione50"));
var _Detrazione3 = _interopRequireDefault(require("./Detrazione65"));
var _Filtro = _interopRequireDefault(require("./Filtro"));
var _Fotovoltaico = _interopRequireDefault(require("./Fotovoltaico"));
var _GestioneIntell = _interopRequireDefault(require("./GestioneIntell"));
var _OneHand = _interopRequireDefault(require("./OneHand"));
var _Protezione = _interopRequireDefault(require("./Protezione"));
var _RiduzioneConsumi = _interopRequireDefault(require("./RiduzioneConsumi"));
var _Telethon = _interopRequireDefault(require("./Telethon"));
var _Birthday = _interopRequireDefault(require("./Birthday"));
var _VIVI_HUB = _interopRequireDefault(require("./VIVI_HUB"));
var _VIVI_STORE = _interopRequireDefault(require("./VIVI_STORE"));
var _VIVIvip_Logo = _interopRequireDefault(require("./VIVIvip_Logo"));
var _VIVIvip_Punti = _interopRequireDefault(require("./VIVIvip_Punti"));