import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import {
  PageBlock,
  withWrapper,
} from '@alkemy/components'

export default compose(
  withPageBuilder({
    niceName: 'Blocco Pagina',
  }),
  withFields([
    {
      prop: 'isViviesco',
      label: 'is viviesco?',
      type: 'boolean',
    }, {
      prop: 'title',
      label: 'Titolo Section',
    },
    {
      prop: 'text',
      type: 'WYSIWYG',
      label: 'Body',
    },
    {
      prop: 'img',
      label: 'Image',
    },
    {
      prop: 'type',
      type: 'multiple',
      label: 'Posizione Immagine',
      options: [
        'img_bottom: In Basso',
        'img_left: Affiancata a Sinistra',
      ],
      initialValue: 'img_bottom',
    },
    {
      prop: 'wrapped',
      type: 'boolean',
      label: 'Contenuto Boxed',
    },
    {
      prop: 'redLinks',
      label: 'Link Rossi',
      type: 'boolean',
    },
  ]),
  withWrapper,
)(PageBlock)
