"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitle = exports.StyledModalWrapper = exports.StyledIconwrapper = exports.StyledBoxOfferGrid = exports.FlexContent = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledBoxOfferGrid = exports.StyledBoxOfferGrid = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBoxOfferGrid",
  componentId: "sc-1pql0qp-0"
})(["\n  position: relative;\n  margin: 0 auto;\n"]);
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-1pql0qp-1"
})(["\n  width: 95%;\n  margin: 2rem auto 0;\n  padding-bottom: 10px;\n  color: ", ";\n  font-family: ", ";\n  font-size: 18px;\n  text-align: center;\n  border-bottom: 1px solid ", ";\n"], function (props) {
  return props.theme.color.darkGray;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.gray;
});
var StyledIconwrapper = exports.StyledIconwrapper = _styledComponents.default.span.withConfig({
  displayName: "styles__StyledIconwrapper",
  componentId: "sc-1pql0qp-2"
})(["\n  width: 20px;\n  height: 20px;\n  margin-left: 25px;\n  margin-bottom: 3px;\n  display: inline-block;\n  cursor: pointer;\n  vertical-align: middle;\n"]);
var StyledModalWrapper = exports.StyledModalWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledModalWrapper",
  componentId: "sc-1pql0qp-3"
})(["\n  position: absolute;\n  left: 50%;\n  transform: translateX(-50%);\n\n  opacity: 0;\n  visibility: hidden;\n  transition: all 0.2s ease-in 0s;\n  z-index: 10;\n\n  ", "\n\n  @media (min-width: 768px) {\n    top: -400px;\n  }\n"], function (props) {
  return props.isVisible && (0, _styledComponents.css)(["\n      opacity: 1;\n      visibility: visible;\n    "]);
});
var FlexContent = exports.FlexContent = _styledComponents.default.div.withConfig({
  displayName: "styles__FlexContent",
  componentId: "sc-1pql0qp-4"
})(["\n  > div {\n    display: flex;\n    flex-flow: row wrap;\n    @media (min-width: 1440px) {\n      flex-flow: row nowrap;\n    }\n  }\n"]);