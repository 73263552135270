import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { ProductPageTab, withWrapper } from '@alkemy/components'

const TabContent = props => (
  <ProductPageTab {...props} funnelDomain={process.env.FUNNEL_DOMAIN} />
)

export default compose(
  withPageBuilder({
    niceName: 'Product Page Tab',
  }),
  withFields([
    {
      prop: 'tabs',
      label: 'Repeater Tab Item',
      type: 'repeater',
      model: [
        {
          prop: 'title',
          label: 'Title',
        },
        {
          prop: 'active',
          label: 'Active',
          type: 'boolean',
        },
        {
          prop: 'unactiveImg',
          label: 'Immagine unactive',
        },
        {
          prop: 'unactiveImgMobile',
          label: 'Immagine unactive mobile',
        },
        {
          prop: 'tabId',
          label: 'Tab ID',
        },
      ],
    },
  ]),
  withWrapper,
)(TabContent)
