import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { ProductPageHero, withWrapper } from '@alkemy/components'

export default compose(
  withPageBuilder({
    niceName: 'Product Page Hero',
  }),
  withFields([
    {
      prop: 'darkMode',
      type: 'boolean',
      label: 'Dark mode',
    },
    {
      prop: 'occhiello',
      label: 'Occhiello',
    },
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'disclaimer',
      label: 'Disclaimer',
    },
    {
      prop: 'bollino',
      label: 'Immagine bollino',
    },
    {
      prop: 'images',
      label: 'Repeater Immagini',
      type: 'repeater',
      model: [
        {
          prop: 'image',
          label: 'Immagine',
        },
      ],
    },
  ]),
  withWrapper,
)(ProductPageHero)
