const allaccioLuce = require('./article-details/allaccioLuce.json')
const allaccioGas = require('./article-details/allaccioGas.json')
const attivazioneGas = require('./article-details/attivazioneGas.json')
const attivazioneLuce = require('./article-details/attivazioneLuce.json')
const risparmioEnergetico = require('./article-details/risparmioEnergetico.json')
const autoletturagas = require('./article-details/autoletturagas.json')
const volturagas = require('./article-details/volturagas.json')
const volturaluce = require('./article-details/volturaluce.json')
const contabilizzatori = require('./article-details/contabilizzatori.json')
const valvoleTermostatiche = require('./article-details/valvole_termostatiche.json')
const termoconvettori = require('./article-details/termoconvettori.json')

module.exports = {
  '/casa/vivipedia/guida-energia/allaccio-luce-cos-e-come-funziona': allaccioLuce,
  '/casa/vivipedia/guida-energia/allaccio-gas-cos-e-come-funziona': allaccioGas,
  '/casa/vivipedia/guida-energia/attivazione-fornitura-gas': attivazioneGas,
  '/casa/vivipedia/guida-energia/attivazione-fornitura-luce': attivazioneLuce,
  '/casa/vivipedia/guida-energia/autolettura-gas': autoletturagas,
  '/casa/vivipedia/guida-energia/voltura-gas': volturagas,
  '/casa/vivipedia/guida-energia/voltura-luce': volturaluce,
  '/casa/vivipedia/consigli-di-risparmio/risparmiare-energia-elettrica-evitare-sprechi': risparmioEnergetico,
  '/casa/vivipedia/consigli-di-risparmio/contabilizzatori-di-calore-cosa-sono-e-come-funzionano': contabilizzatori,
  '/casa/vivipedia/consigli-di-risparmio/valvole-termostatiche-cosa-sono-e-come-funzionano': valvoleTermostatiche,
  '/casa/vivipedia/consigli-di-risparmio/termoconvettori-cosa-sono-e-come-funzionano': termoconvettori,
}
