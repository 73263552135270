"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Title = exports.TariffeWrapper = exports.StyledIcon = exports.StimaCostiWrapper = exports.SottoscriviSticky = exports.SottoscriviInnerWrapper = exports.MainWrapper = exports.IscrivitiWrapper = exports.InvertedButton = exports.IconContainer = exports.FlexWrapper = exports.FlexRow = exports.CostiCardWrapper = exports.CardWrapper = exports.CalcoloCostiWrapper = exports.Button = exports.Bollino = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _ = require("../../");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var MainWrapper = exports.MainWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__MainWrapper",
  componentId: "sc-la5csh-0"
})(["\n  box-shadow: 0 0 50px 0 rgb(81 129 186 / 25%);\n  border-radius: 35px;\n  margin: 70px auto 88px auto;\n  max-width: 970px;\n  width: 90%;\n  position: relative;\n  ", "\n\n  ", " {\n    max-width: 100%;\n    margin: 0 auto;\n  }\n"], function (props) {
  return props.isModal && "\n      box-shadow: none;\n\t\t\twidth: 100%;\n\t\t\tmargin: 20px auto;\n\t\t\toverflow-y:scroll;\n\t\t\t-ms-overflow-style: none;  /* Internet Explorer 10+ */\n      scrollbar-width: none;  /* Firefox */\n\n\t\t\t&::-webkit-scrollbar {\n    \t\tdisplay: none;  /* Safari and Chrome */\n\t\t\t}\n\n  ";
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var CardWrapper = exports.CardWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__CardWrapper",
  componentId: "sc-la5csh-1"
})(["\n  padding: 30px 0;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: no-wrap;\n  justify-content: center;\n  align-items: center;\n  gap: 40px;\n  margin: 30px 0;\n"]);
var TariffeWrapper = exports.TariffeWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__TariffeWrapper",
  componentId: "sc-la5csh-2"
})(["\n  padding: 20px 58px;\n\n  ", "\n\n  ", " {\n    padding: 24px;\n  }\n"], function (props) {
  return props.isModal && "\n\t\tpadding: 20px 20px 0;\n\n  ";
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var IscrivitiWrapper = exports.IscrivitiWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__IscrivitiWrapper",
  componentId: "sc-la5csh-3"
})(["\n  display: grid;\n  grid-template-columns: auto;\n  grid-template-rows: 1fr auto auto;\n  justify-content: center;\n  align-items: center;\n  margin-top: 80px;\n  margin-bottom: 10px;\n  gap: 15px;\n  text-align: center;\n  font-family: ", ";\n  color: #3f5063;\n  font-size: 14px;\n\n  ", "\n\n", "\n\n  ", " {\n    margin-top: 50px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.sticky && "\n      position: fixed;\n      bottom: 0;\n      left: 0;\n      width: 100%;\n      height: 60px;\n      display: flex;\n      flex-direction: row;\n      justify-content: space-around;\n      align-items: center;\n      gap: 19px;\n  ";
}, function (props) {
  return props.isModal && "\n\t\tposition: fixed;\n    width: 100%;\n    left: 0;\n\t\t-moz-backdrop-filter: blur(20px);\n\t\t-webkit-backdrop-filter: blur(20px);\n\t\tbackdrop-filter: blur(20px);\n\t\tz-index: 999;\n    align-items: center;\n    display: flex;\n    padding: 30px 0;\n    bottom: -15px;\n    border-radius: 0 0 35px 35px;\n  ";
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var Button = exports.Button = _styledComponents.default.button.withConfig({
  displayName: "styles__Button",
  componentId: "sc-la5csh-4"
})(["\n  padding: 17px 69px 19px 71px;\n  border-radius: 30px;\n  background: linear-gradient(256deg, #e67111 0%, rgb(232, 28, 105) 100%);\n  font-family: ", ";\n  font-size: 20px;\n  font-weight: 500;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: normal;\n  letter-spacing: normal;\n  color: #fff;\n  border: none;\n  cursor: pointer;\n  text-transform: capitalize;\n\n  ", " {\n    padding: 11px 16px 12px;\n    font-size: 16px;\n    border-radius: 21px;\n    width: 232px;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var InvertedButton = exports.InvertedButton = _styledComponents.default.span.withConfig({
  displayName: "styles__InvertedButton",
  componentId: "sc-la5csh-5"
})(["\n  color: #e81c69;\n  cursor: pointer;\n  width: fit-content;\n  justify-self: center;\n  font-family: ", ";\n  cursor: pointer;\n  margin: 0;\n  font-size: 20px;\n  font-weight: bold;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: normal;\n  letter-spacing: normal;\n  color: #e81c69;\n\n  ", " {\n    font-size: 16px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var StimaCostiWrapper = exports.StimaCostiWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StimaCostiWrapper",
  componentId: "sc-la5csh-6"
})([""]);
var CalcoloCostiWrapper = exports.CalcoloCostiWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__CalcoloCostiWrapper",
  componentId: "sc-la5csh-7"
})(["\n  position: relative;\n  overflow: hidden;\n  background-image: linear-gradient(to top, #fff 95%, #f2f2f2);\n  border-bottom-left-radius: 20px;\n  border-bottom-right-radius: 20px;\n  cursor: pointer;\n  box-shadow: inset -5px 10px -5px rgba(0, 0, 0, 0.5);\n  transition: all 0.8s ease-in-out;\n\n  ", "\n  ", "\n\n  ", " {\n    ", "\n  }\n"], function (props) {
  if (props.isOpen) {
    return "\n          max-height: 2000px;\n        ";
  } else {
    return "\n          max-height: 54px;\n        ";
  }
}, function (props) {
  if (props.isModal) {
    return "\n         cursor: default;\n\t\t\t\t background: transparent;\n        ";
  }
}, function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  if (props.isOpen) {
    return "\n            max-height: 2000px;\n          ";
  } else {
    return "\n            max-height: 93px;\n          ";
  }
});
var FlexWrapper = exports.FlexWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__FlexWrapper",
  componentId: "sc-la5csh-8"
})(["\n  width: fit-content;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 30px;\n\n  ", " {\n    flex-direction: row;\n    align-items: center;\n    gap: 15px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var CostiCardWrapper = exports.CostiCardWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__CostiCardWrapper",
  componentId: "sc-la5csh-9"
})(["\n  display: flex;\n  flex-direction: row;\n  gap: 78px;\n  justify-content: center;\n  align-items: start;\n  width: 100%;\n\n  @media (max-width: 968px) {\n    flex-direction: column;\n    gap: 30px;\n  }\n"]);
var IconContainer = exports.IconContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__IconContainer",
  componentId: "sc-la5csh-10"
})(["\n  position: relative;\n  width: 80px;\n  height: 80px;\n\n  > canvas {\n    z-index: -1;\n    border-radius: 50%;\n  }\n"]);
var Title = exports.Title = _styledComponents.default.div.withConfig({
  displayName: "styles__Title",
  componentId: "sc-la5csh-11"
})(["\n  display: inline-block;\n  width: max-content;\n  font-family: ", ";\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.44;\n  letter-spacing: normal;\n  font-size: 22px;\n  color: ", ";\n  position: relative;\n\n  ", " {\n    font-size: 18px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var SottoscriviSticky = exports.SottoscriviSticky = _styledComponents.default.div.withConfig({
  displayName: "styles__SottoscriviSticky",
  componentId: "sc-la5csh-12"
})(["\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  height: 60px;\n  z-index: 99999;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: ", ";\n  transition: opacity 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);\n  opacity: ", ";\n  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);\n  pointer-events: ", ";\n\n  ", " {\n    margin: 0;\n    display: flex;\n    align-items: center;\n  }\n\n  ", " {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    font-size: 16px;\n  }\n\n  ", " {\n    width: auto;\n    font-size: 16px;\n    padding: 11px 16px 12px;\n    border-radius: 21px;\n    white-space: nowrap;\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.active ? 1 : 0;
}, function (props) {
  return props.active ? 'all' : 'none';
}, Title, InvertedButton, Button);
var SottoscriviInnerWrapper = exports.SottoscriviInnerWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__SottoscriviInnerWrapper",
  componentId: "sc-la5csh-13"
})(["\n  max-width: 1130px;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  margin-left: 10px;\n\n  ", " {\n    padding-left: 0;\n    justify-content: space-around;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var FlexRow = exports.FlexRow = _styledComponents.default.div.withConfig({
  displayName: "styles__FlexRow",
  componentId: "sc-la5csh-14"
})(["\n  display: flex;\n  align-items: center;\n  gap: 20px;\n\n  ", " {\n    ", "\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  return props.hideMobile && "display: none;";
});
var Bollino = exports.Bollino = _styledComponents.default.img.withConfig({
  displayName: "styles__Bollino",
  componentId: "sc-la5csh-15"
})(["\n  position: absolute;\n  right: 33px;\n  bottom: 100%;\n  width: 188px;\n  height: 62px;\n  z-index: -10;\n\n  ", " {\n    width: 150px;\n    height: 50px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var StyledIcon = exports.StyledIcon = (0, _styledComponents.default)(_.Icons).withConfig({
  displayName: "styles__StyledIcon",
  componentId: "sc-la5csh-16"
})([""]);