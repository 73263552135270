"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledWorkWithUsBlock = exports.StyledTitle = exports.StyledPlace = exports.StyledLinkWrapper = exports.StyledImage = exports.StyledIconWrapper = exports.StyledDesc = exports.StylecFooterWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledWorkWithUsBlock = exports.StyledWorkWithUsBlock = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledWorkWithUsBlock",
  componentId: "sc-1t9mrdo-0"
})(["\n  width: 315px;\n  flex-basis: 315px;\n  height: 280px;\n  padding: 40px 20px;\n  position: relative;\n  border-radius: 35px;\n  box-shadow: 0 0 25px 0 ", ";\n  margin-left: 10px;\n  margin-right: 10px;\n  float: left;\n\n  &:not(:last-child) {\n    margin-bottom: 20px;\n  }\n\n  ", "\n\n  @media (min-width: 375px) {\n    width: 335px;\n    flex-basis: 335px;\n  }\n\n  &:not(:last-child) {\n    margin-bottom: 20px;\n  }\n\n  @media (min-width: 1024px) {\n    margin-bottom: 30px;\n    width: 270px;\n    flex-basis: 270px;\n    height: 280px;\n  }\n"], function (props) {
  return props.theme.color.shadowBlue;
}, function (props) {
  return props.hasSlider && (0, _styledComponents.css)(["\n      margin-left: auto;\n      margin-right: auto;\n      float: none;\n    "]);
});
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-1t9mrdo-1"
})(["\n  margin-bottom: 20px;\n  font-size: 18px;\n  line-height: 22px;\n  font-family: ", ";\n"], function (props) {
  return props.theme.font.medium;
});
var StyledDesc = exports.StyledDesc = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDesc",
  componentId: "sc-1t9mrdo-2"
})(["\n  margin-bottom: 20px;\n  font-size: 14px;\n  line-height: 22px;\n  font-family: ", ";\n"], function (props) {
  return props.theme.font.regular;
});
var StyledPlace = exports.StyledPlace = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledPlace",
  componentId: "sc-1t9mrdo-3"
})(["\n  font-size: 14px;\n  line-height: 22px;\n  color: ", ";\n  font-family: ", ";\n"], function (props) {
  return props.theme.color.gray;
}, function (props) {
  return props.theme.font.regular;
});
var StylecFooterWrapper = exports.StylecFooterWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StylecFooterWrapper",
  componentId: "sc-1t9mrdo-4"
})(["\n  width: 100%;\n  padding: 0 20px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  position: absolute;\n  left: 0;\n  bottom: 40px;\n"]);
var StyledImage = exports.StyledImage = _styledComponents.default.img.withConfig({
  displayName: "styles__StyledImage",
  componentId: "sc-1t9mrdo-5"
})(["\n  height: 30px;\n"]);
var StyledLinkWrapper = exports.StyledLinkWrapper = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledLinkWrapper",
  componentId: "sc-1t9mrdo-6"
})(["\n  color: ", ";\n  font-size: 14px;\n  text-decoration: none;\n  font-family: ", ";\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n\n  span {\n    margin-right: 10px;\n  }\n"], function (props) {
  return props.theme.color.magenta;
}, function (props) {
  return props.theme.font.regular;
});
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-1t9mrdo-7"
})(["\n  width: 20px;\n  height: 14px;\n"]);