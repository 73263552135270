"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledSectionWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _styles = require("../shared/styles");
var StyledSectionWrapper = exports.StyledSectionWrapper = (0, _styledComponents.default)(_styles.WrapperSectionWithPadAndTitle).withConfig({
  displayName: "styles__StyledSectionWrapper",
  componentId: "sc-1957ttk-0"
})(["\nmargin-bottom:40px;\n  ul {\n    margin: 0;\n  }\n\n  ", "{\n  ul {\n    margin-left: 170px;\n    }\n  }\n"], function (props) {
  return props.theme.mediaQueries.desktop;
});