import {compose} from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import {DownloadList} from '@alkemy/components'

export default compose(
  withPageBuilder({
    niceName: 'Lista Download',
  }),
  withFields([
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'text',
      label: 'Testo',
    },
    {
      prop: 'items',
      type: 'repeater',
      label: 'Files',
      model: [
        {
          prop: 'label',
          label: 'Label',
        },
        {
          prop: 'href',
          label: 'Link',
        },
      ],
    },
    {
      prop: 'hasWhiteBg',
      type: 'boolean',
      label: 'Background bianco',
    },
  ]),
)(DownloadList)
