import { compose } from "recompose"
import withPageBuilder from "@alkemy/pagebuilder/lib/hoc/withPageBuilder"

import { Contents } from '@alkemy/common'

import {
  AdvantagesSlider,
} from "@alkemy/components"

export default compose(withPageBuilder({
  niceName: "Slider Vantaggi",
}))(() => <AdvantagesSlider {...Contents.landingCasaSlider} />)