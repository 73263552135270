import React, { Component } from 'react'
import { Contents } from '@alkemy/common'
import Head from 'next/head'
import _ from 'lodash'

const getMetaKey = (wpMeta, key) => {
  const metaArray = wpMeta && wpMeta[key]
  return metaArray && metaArray.length > 0 ? metaArray[0] : ''
}

const getFromTitle = wpData => {
  if (wpData && wpData.post_title) {
    return wpData.post_title
  }
  if (wpData && wpData.title && typeof wpData.title === 'object') {
    return wpData.title.rendered
  }

  return wpData && wpData.title
}

const makeSEO = seo => ({
  metaTitle: seo ? seo.title : '',
  metaDescription: seo ? seo.description : '',
  ogTitle: seo ? seo.title : '',
  ogDescription: seo ? seo.description : '',
  ogImage: seo ? seo.og_image : '',
  ogUrl: '',
  twTitle: seo ? seo.title : '',
  twDescription: seo ? seo.description : '',
  twImage: seo ? seo.og_image : '',
})

export default ComposedComponent =>
  class WithSEO extends Component {
    constructor(props) {
      super(props)

      this.state = {
        componentData: '',
      }
    }

    static async getInitialProps(ctx) {
      const { req } = ctx
      let composedInitialProps = {}
      if (ComposedComponent.getInitialProps) {
        composedInitialProps = await ComposedComponent.getInitialProps(ctx)
      }

      const route = composedInitialProps.pathname

      const metaUrl = encodeURI(
        req && req.url
          ? `${process.env.APP_DOMAIN}${req.url}`
          : window.location.href,
      )

      const { wpData } = composedInitialProps

      const getMeta = key => getMetaKey(wpData && wpData.meta, key)

      if (wpData) {
        const metaTitle = getMeta('_yoast_wpseo_title') || getFromTitle(wpData)
        const metaDescription = getMeta('_yoast_wpseo_metadesc')
        const canonicalUrl = getMeta('_yoast_wpseo_canonical') || metaUrl
        const noFollow = getMeta('_yoast_wpseo_meta-robots-noindex') || ''

        const ogTitle =
          getMeta('_yoast_wpseo_opengraph-title') || getFromTitle(wpData)

        const ogDescription =
          getMeta('_yoast_wpseo_opengraph-description') || metaDescription
        const ogImage =
          getMeta('_yoast_wpseo_opengraph-image') ||
          (wpData &&
            wpData.thumbnail &&
            `${wpData.thumbnail}?w=960&auto=format`)
        const ogUrl = metaUrl

        const twTitle =
          getMeta('_yoast_wpseo_twitter-title') || (wpData && wpData.post_title)
        const twDescription =
          getMeta('_yoast_wpseo_twitter-description') || metaDescription
        const twImage =
          getMeta('_yoast_wpseo_twitter-image') ||
          (wpData &&
            wpData.thumbnail &&
            `${wpData.thumbnail}?w=960&auto=format`)

        return {
          ...composedInitialProps,
          seo: {
            metaTitle,
            metaDescription,
            ogTitle,
            ogDescription,
            ogImage,
            ogUrl,
            twTitle,
            twDescription,
            twImage,
            canonicalUrl,
            noFollow,
          },
        }
      }

      return {
        ...composedInitialProps,
        seo: {
          ...makeSEO(Contents.seo[route]),
          ogUrl: metaUrl,
        },
      }
    }
    componentDidMount() {
      const urlpage = window.location.pathname
      this.setState({
        componentData: urlpage,
      })
    }
    render() {
      const componentData = this.state.componentData
      const { seo = {} } = this.props
      const {
        metaTitle,
        noFollow,
        metaDescription,
        ogTitle,
        ogDescription,
        ogImage,
        ogUrl,
        twTitle,
        canonicalUrl,
        twDescription,
        twImage,
      } = seo
      //console.log('nofollow', noFollow)
      return (
        <React.Fragment>
          {componentData === '/viviesco/prodotti/caldaia-formentera' && (
            <Head>
              <title key="metatitle">
                Caldaia a Condensazione Fondital Formentera KC | VIVI energia
              </title>
              <meta
                key="description"
                name="description"
                content="La caldaia murale a condensazione Fondital Formentera KC con classe energetica A è disponibile in tre versioni: scopri prezzi e condizioni online su VIVI energia."
              />
            </Head>
          )}
          {componentData === '/viviesco/prodotti/condizionatore-italtherm' && (
            <Head>
              <title key="metatitle">
                Condizionatore a Parete Italtherm fino a 5 Split | VIVI energia
              </title>
              <meta
                key="description"
                name="description"
                content="Il condizionatore Italtherm acquistabile con detrazione fiscale è la soluzione ideale per chi è alla ricerca di un dispositivo ad alta efficienza energetica: scopri di più."
              />
            </Head>
          )}
          {componentData === '/viviesco/prodotti/caldaia-ischia' && (
            <Head>
              <title key="metatitle">
                Caldaia Murale a Condensazione Fondital Ischia KC | VIVI energia
              </title>
              <meta
                key="description"
                name="description"
                content="La caldaia ad incasso per esterno Fondital Ischia KC è disponibile con alimentazione a metano o propano: scopri prezzi e condizioni delle caldaie in offerta su VIVI."
              />
            </Head>
          )}
          {componentData != '/viviesco/prodotti/caldaia-formentera' &&
            componentData != '/viviesco/prodotti/condizionatore-italtherm' &&
            componentData != '/viviesco/prodotti/caldaia-ischia' && (
              <Head>
                <title key="metatitle">
                  {metaTitle ? `${metaTitle} |` : ''} VIVI energia
                </title>
                {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
                {noFollow && (
                  <meta name="robots" content="noindex, nofollow " />
                )}
                <meta
                  key="description"
                  name="description"
                  content={metaDescription}
                />
                <meta key="og:type" property="og:type" content="website" />
                <meta
                  key="og:title"
                  property="og:title"
                  content={ogTitle || ''}
                />
                <meta
                  key="og:description"
                  property="og:description"
                  content={ogDescription}
                />
                <meta key="og:image" property="og:image" content={ogImage} />
                <meta key="og:url" property="og:url" content={ogUrl} />
                <meta
                  key="og:site_name"
                  property="og:site_name"
                  content="VIVI energia"
                />
                <meta
                  key="twitter:card"
                  name="twitter:card"
                  content="summary"
                />
                <meta
                  key="twitter:title"
                  name="twitter:title"
                  content={twTitle}
                />
                <meta
                  key="twitter:description"
                  name="twitter:description"
                  content={twDescription}
                />
                <meta
                  key="twitter:image"
                  name="twitter:iamge"
                  content={twImage}
                />
              </Head>
            )}
          <ComposedComponent {...this.props} />
        </React.Fragment>
      )
    }
  }
