"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WrapperTels = exports.WrapperSocialTel = exports.WrapperSocial = exports.WrapperMenu = exports.WrapperMain = exports.WrapperLogo = exports.WrapperCopy = exports.WrapperAppStore = exports.StyledStoreLogo = exports.StyledFooter = exports.PreStyledFooter = exports.PreFooterLabel = exports.PreFooterItem = exports.PreFooterIconWrapper = exports.PreFooterFullBack = exports.PaddingSingleTel = exports.Menu = exports.IconWrapper = exports.FooterFullBack = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _styles = require("../shared/styles");
var _styles2 = require("../Logo/styles");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledStoreLogo = exports.StyledStoreLogo = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledStoreLogo",
  componentId: "sc-s6iexp-0"
})(["\n  margin: 0 auto;\n  position:relative;\n  width: ", "px;\n  height: 34px;\n  background-image: url('", "/images/common/", ".svg');\n  background-color: transparent;\n  background-position: left center;\n  background-repeat: no-repeat;\n  background-size: contain;\n"], function (_ref) {
  var google = _ref.google;
  return google ? '113' : '100';
}, function (props) {
  return props.theme.assetsUrl;
}, function (_ref2) {
  var google = _ref2.google;
  return google ? 'g-store' : 'a-store';
});
var IconWrapper = exports.IconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__IconWrapper",
  componentId: "sc-s6iexp-1"
})(["\n  width: 34px;\n  height: 34px;\n  margin: 0 auto;\n\n  ", " {\n    margin: 0;\n  }\n"], function (props) {
  return props.theme.mediaQueries.desktop;
});
var WrapperCopy = exports.WrapperCopy = _styledComponents.default.div.withConfig({
  displayName: "styles__WrapperCopy",
  componentId: "sc-s6iexp-2"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 12px;\n  font-weight: normal;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: 1.83;\n  letter-spacing: normal;\n  text-align: center;\n\n  a {\n    color: ", ";\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.white;
});
var WrapperSocial = exports.WrapperSocial = _styledComponents.default.div.withConfig({
  displayName: "styles__WrapperSocial",
  componentId: "sc-s6iexp-3"
})(["\n  display: flex;\n  flex-direction: row;\n  height: 34px;\n\n  margin: 50px 0;\n\n  padding-bottom: 20px;\n\n  & > * {\n    flex: 1;\n  }\n\n  ", " {\n    // margin: 50px 270px;\n  }\n\n  ", " {\n    margin: 0;\n    width: 200px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var WrapperMenu = exports.WrapperMenu = _styledComponents.default.div.withConfig({
  displayName: "styles__WrapperMenu",
  componentId: "sc-s6iexp-4"
})(["\n  flex: 2;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n"]);
var Menu = exports.Menu = _styledComponents.default.div.withConfig({
  displayName: "styles__Menu",
  componentId: "sc-s6iexp-5"
})(["\n  min-width: 140px;\n  display: flex;\n  flex-direction: column;\n\n  margin: 0 auto;\n  padding-left: 40px;\n\n  color: ", ";\n\n  a {\n    text-decoration: none;\n    color: ", ";\n  }\n\n  font-family: ", ";\n  font-style: normal;\n  font-stretch: normal;\n  line-height: normal;\n\n  h4 {\n    margin: 0 0 10px 0;\n    font-size: 16px;\n    font-weight: bold;\n    letter-spacing: 0.1px;\n    text-transform: uppercase;\n  }\n\n  ul {\n    list-style: none;\n    padding: 0;\n    margin: 0;\n\n    font-size: 16px;\n    font-weight: normal;\n    line-height: 2.44;\n\n    ", "\n\n    li {\n      position: relative;\n\n      &:before {\n        content: '';\n        position: absolute;\n        display: inline-block;\n        width: 20px;\n        top: 50%;\n        left: -25px;\n        height: 1px;\n        background: ", ";\n      }\n    }\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.small && (0, _styledComponents.css)(["\n        font-size: 14px;\n        line-height: 2.21;\n      "]);
}, function (props) {
  return props && props.theme.color.white;
});
var WrapperLogo = exports.WrapperLogo = _styledComponents.default.div.withConfig({
  displayName: "styles__WrapperLogo",
  componentId: "sc-s6iexp-6"
})(["\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n\n  ", " {\n    margin: 0 auto 20px auto;\n\n    width: 120px;\n    height: 50px;\n\n    ", " {\n      width: 180px;\n      height: 80px;\n    }\n\n    ", " {\n      margin: 0 0 20px 0;\n    }\n  }\n"], _styles2.StyledLogo, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var WrapperAppStore = exports.WrapperAppStore = _styledComponents.default.div.withConfig({
  displayName: "styles__WrapperAppStore",
  componentId: "sc-s6iexp-7"
})(["\n  display: flex;\n  flex-direction: column;\n  ", " {\n    margin-top: 50px;\n  }\n\n  flex: 1;\n\n  color: ", ";\n\n  a {\n    text-decoration: none;\n    color: ", ";\n  }\n\n  font-family: ", ";\n  font-style: normal;\n  font-stretch: normal;\n  line-height: normal;\n\n  h4 {\n    margin: 0 auto 10px auto;\n    font-size: 16px;\n    font-weight: bold;\n    letter-spacing: 0.1px;\n    text-transform: uppercase;\n    text-align: center;\n  }\n\n  & > div {\n    display: flex;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.font.regular;
});
var WrapperMain = exports.WrapperMain = _styledComponents.default.div.withConfig({
  displayName: "styles__WrapperMain",
  componentId: "sc-s6iexp-8"
})(["\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 50px;\n"]);
var FooterFullBack = exports.FooterFullBack = (0, _styledComponents.default)(_styles.WrapperFullBack).withConfig({
  displayName: "styles__FooterFullBack",
  componentId: "sc-s6iexp-9"
})(["\n  position: relative;\n  z-index: 0;\n  background-image: linear-gradient(189deg, #7e2889, #1587c8);\n\n  @media (min-width: 768px) {\n    background-image: linear-gradient(206deg, #7e2889, #1587c8);\n  }\n\n  @media (min-width: 1440px) {\n    background-image: linear-gradient(234deg, #7e2889, #1587c8);\n  }\n"]);
var PreFooterFullBack = exports.PreFooterFullBack = (0, _styledComponents.default)(_styles.WrapperFullBack).withConfig({
  displayName: "styles__PreFooterFullBack",
  componentId: "sc-s6iexp-10"
})(["\n  position: relative;\n  z-index: 0;\n  background-image: linear-gradient(120deg, #f49593, #f7bf28);\n\n  @media (min-width: 768px) {\n    linear-gradient(95deg, #f49593, #f7bf28);\n  }\n\n  @media (min-width: 1440px) {\n     linear-gradient(93deg, #f49593, #f7bf28);\n  }\n  margin-top:92px;\n"]);
var StyledFooter = exports.StyledFooter = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledFooter",
  componentId: "sc-s6iexp-11"
})(["\n  display: flex;\n  flex-direction: column;\n\n  padding: 60px 10px 20px;\n\n  ", " {\n    padding: 60px 0px 60px 0px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.desktop;
});
var PreStyledFooter = exports.PreStyledFooter = _styledComponents.default.div.withConfig({
  displayName: "styles__PreStyledFooter",
  componentId: "sc-s6iexp-12"
})(["\n  display: flex;\n  flex-direction: column;\n\n  padding: 5px 10px;\n\n  ", " {\n    padding: 5px 35px;\n    flex-direction: row;\n    justify-content: space-around;\n  }\n\n  ", " {\n    // padding: 60px 0px 20px 0px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var PreFooterItem = exports.PreFooterItem = _styledComponents.default.a.withConfig({
  displayName: "styles__PreFooterItem",
  componentId: "sc-s6iexp-13"
})(["\n  cursor: pointer;\n\n  outline: none;\n  text-decoration: none;\n\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n\n  &>: first-child {\n    margin-right: 10px;\n  }\n  ", " {\n  }\n"], function (props) {
  return props.theme.mediaQueries.desktop;
});
var PreFooterLabel = exports.PreFooterLabel = _styledComponents.default.div.withConfig({
  displayName: "styles__PreFooterLabel",
  componentId: "sc-s6iexp-14"
})(["\n  font-family: ", ";\n  font-size: 16px;\n  font-weight: 500;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: normal;\n  letter-spacing: normal;\n  text-align: center;\n  color: #ffffff;\n  ", " {\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var PreFooterIconWrapper = exports.PreFooterIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__PreFooterIconWrapper",
  componentId: "sc-s6iexp-15"
})(["\n  width: 50px;\n  height: 50px;\n  ", " {\n  }\n"], function (props) {
  return props.theme.mediaQueries.desktop;
});
var WrapperTels = exports.WrapperTels = _styledComponents.default.div.withConfig({
  displayName: "styles__WrapperTels",
  componentId: "sc-s6iexp-16"
})(["\n  color: ", ";\n\n  font-family: ", ";\n  font-size: 12px;\n  font-weight: normal;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: 1.2;\n  letter-spacing: normal;\n  text-align:center;\n\n  p{ margin:0; margin-bottom:5px}\n\n  display : flex;\n  justify-content:center;\n  flex-flow: row wrap\n\n  margin: 40px 10px 0 10px;\n\n  ", "{\n    margin: auto -165px 20px -165px;\n  }\n\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var WrapperSocialTel = exports.WrapperSocialTel = _styledComponents.default.div.withConfig({
  displayName: "styles__WrapperSocialTel",
  componentId: "sc-s6iexp-17"
})(["\n  display: flex;\n\n  & > div {\n    width: 50%;\n    flex: 1;\n  }\n"]);
var PaddingSingleTel = exports.PaddingSingleTel = _styledComponents.default.div.withConfig({
  displayName: "styles__PaddingSingleTel",
  componentId: "sc-s6iexp-18"
})(["\n  padding: 8px 5px;\n  ", " {\n    padding: 5px 5px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.desktop;
});