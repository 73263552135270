import React from "react"
import axios from "axios"

import { camelCase, mapKeys } from "lodash"

// Gets the display name of a JSX component for dev tools
function getComponentDisplayName(Component) {
  return Component.displayName || Component.name || "Unknown"
}

const makeGlobalSettings = globalSettings => {
  return mapKeys(globalSettings, (value, key) => camelCase(key))
}
export default ComposedComponent => {
  return class withGlobalSettings extends React.Component {
    static displayName = `withGlobalSettings(${getComponentDisplayName(
      ComposedComponent
    )})`

    static async getInitialProps(ctx) {
      let composedInitialProps = {}
      if (
        ComposedComponent.getInitialProps &&
        typeof ComposedComponent.getInitialProps === "function"
      ) {
        composedInitialProps = await ComposedComponent.getInitialProps(ctx)
      }

      const globalSettings = await axios
        .get(`${process.env.WP_API}/gse/v1/settings/it`)
        .then(x => x.data)

      return {
        ...composedInitialProps,
        globalSettings: makeGlobalSettings(globalSettings),
      }
    }

    render() {
      return <ComposedComponent {...this.props} />
    }
  }
}
