import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { ViviescoProdotti, withWrapper } from '@alkemy/components'

export default compose(
  withPageBuilder({
    niceName: 'Viviesco - Prodotti',
  }),
  withFields([
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'items',
      label: 'Lista ancore',
      type: 'repeater',
      initialValue: [],
      model: [
        {
          prop: 'hash',
          label: 'Hashtag',
        },
        {
          prop: 'image',
          label: 'Immagine',
        },
        {
          prop: 'name',
          label: 'Nome',
        },
      ],
    },
    {
      prop: 'products',
      label: 'Lista prodotti',
      type: 'repeater',
      initialValue: [],
      model: [
        {
          prop: 'smallImgHeight',
          label: 'small height?',
          type: 'boolean',
        },
        {
          prop: 'image',
          label: 'Immagine',
        },
        {
          prop: 'inverted',
          label: 'Immagine invertita?',
          type: 'boolean',
        },
        {
          prop: 'title',
          label: 'Titolo',
        },
        {
          prop: 'offer',
          label: 'Offerta',
        },
        {
          prop: 'price',
          label: 'Prezzo',
        },
        {
          prop: 'totalPrice',
          label: 'Prezzo totale',
        },
        {
          prop: 'paymentList',
          label: 'Lista pagamenti',
          type: 'repeater',
          initialValue: [],
          model: [
            {
              prop: 'text',
              label: 'Testo',
            },
          ],
        },
        {
          prop: 'anchor',
          label: 'ancora',
        },
        {
          prop: 'buttonHref',
          label: 'cta link',
        },
        {
          prop: 'buttonLabel',
          label: 'cta label',
        },
        {
          prop: 'buttonColor',
          label: 'cta color',
        },
        {
          prop: 'buttonHrefExtra',
          label: 'Extra cta link',
        },
        {
          prop: 'buttonLabelExtra',
          label: 'Extra cta label',
        },
        {
          prop: 'buttonColorExtra',
          label: 'Extra cta color',
        },
      ],
    },
  ]),
  withWrapper,
)(ViviescoProdotti)
