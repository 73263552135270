import React, {Component} from 'react'

import {UnsupportedBrowser} from '@alkemy/components'
import {CheckUserAgent} from '../lib/CheckUserAgent'

const withUnsupportedBrowser = WrappedComponent => {
  class WithUnsupportedBrowser extends Component {
    static async getInitialProps(context) {
      const {req} = context

      let composedInitialProps = {}
      if (WrappedComponent.getInitialProps) {
        composedInitialProps = await WrappedComponent.getInitialProps(context)
      }

      const userAgent = req ? req.headers['user-agent'] : navigator.userAgent
      const ua = CheckUserAgent(userAgent)

      return {
        ...composedInitialProps,
        ua,
      }
    }

    constructor(props) {
      super(props)

      this.hideUnsupportedBrowser = this.hideUnsupportedBrowser.bind(this)

      this.state = {unsupportedBrowserVisible: false}
    }

    componentDidMount() {
      const {ua} = this.props

      if (ua === 'ie11' || ua === 'ie10') {
        this.setState({
          unsupportedBrowserVisible: true,
        })
      }
    }

    hideUnsupportedBrowser() {
      this.setState({unsupportedBrowserVisible: false})
    }

    render() {
      const {unsupportedBrowserVisible} = this.state

      return (
        <React.Fragment>
          <WrappedComponent {...this.props} />
          {unsupportedBrowserVisible && (
            <UnsupportedBrowser handleOnClick={this.hideUnsupportedBrowser} />
          )}
        </React.Fragment>
      )
    }
  }

  return WithUnsupportedBrowser
}

export default withUnsupportedBrowser
