import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { SectionTitle, withPadder, withWrapper } from '@alkemy/components'

export default compose(
  withPageBuilder(),
  withFields([
    {
      prop: 'wrapped',
      type: 'boolean',
      label: 'Contenuto Boxed',
    },
    {
      prop: 'paddingLeftRight',
      label: 'Larghezza Componente',
      type: 'multiple',
      options: ['200:Small', '170:Medium', '100:Large', '0:Nessuno'],
    },
    {
      prop: 'paddingTop',
      label: 'Distanza dal Top',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'paddingBottom',
      label: 'Distanza dal Bottom',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'biggerFont',
      type: 'boolean',
      label: 'Font Più Grande',
    },
    {
      prop: 'align',
      type: 'multiple',
      options: ['center:Centrato', 'left:Sinistra', 'right:Destra'],
      label: 'Allineamento',
    },
    {
      prop: 'anchor',

      label: 'Anchor',
    },
    {
      prop: 'title',
      type: 'WYSIWYG',
      label: 'Titolo Section',
    },
  ]),
  withPadder,
  withWrapper,
)(SectionTitle)
