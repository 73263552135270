"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledDescription = exports.PriceStrikethrough = exports.LabelPrice = exports.LabelCost = exports.FullWidthColumn = exports.FlexTable = exports.Block = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledDescription = exports.StyledDescription = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDescription",
  componentId: "sc-1cx2r1y-0"
})(["\n  min-height: 30px;\n  font-family: ", ";\n  font-size: 14px;\n  color: ", ";\n  text-align: center;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var Block = exports.Block = _styledComponents.default.div.withConfig({
  displayName: "styles__Block",
  componentId: "sc-1cx2r1y-1"
})(["\n  margin: 0px 0 20px 0px;\n  padding: 0;\n  font-family: ", ";\n  font-weight: 400;\n  color: #3f5063;\n  b,\n  strong {\n    font-family: ", ";\n    font-weight: 400;\n  }\n  img {\n    padding: 10px 0;\n  }\n  small {\n    font-size: 10px;\n    display: block;\n    padding-top: 10px;\n    padding-bottom: 0px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.font.bold;
});
var FlexTable = exports.FlexTable = _styledComponents.default.div.withConfig({
  displayName: "styles__FlexTable",
  componentId: "sc-1cx2r1y-2"
})(["\n  display: flex;\n  flex-wrap: wrap;\n  padding: 0;\n  margin-top: 10px;\n  b {\n    font-family: ", ";\n    font-weight: 400;\n  }\n  span {\n    font-family: ", ";\n  }\n"], function (props) {
  return props.theme.font.bold;
}, function (props) {
  return props.theme.font.regular;
});
var PriceStrikethrough = exports.PriceStrikethrough = _styledComponents.default.div.withConfig({
  displayName: "styles__PriceStrikethrough",
  componentId: "sc-1cx2r1y-3"
})(["\n  text-decoration: line-through;\n  font-size: 12px;\n  display: block;\n  width: 100%;\n  margin-right: 0px;\n"]);
var FullWidthColumn = exports.FullWidthColumn = _styledComponents.default.div.withConfig({
  displayName: "styles__FullWidthColumn",
  componentId: "sc-1cx2r1y-4"
})(["\n  display: flex;\n  flex: 1 0 100%;\n  text-align: right;\n  margin-bottom: 10px;\n"]);
var LabelCost = exports.LabelCost = _styledComponents.default.div.withConfig({
  displayName: "styles__LabelCost",
  componentId: "sc-1cx2r1y-5"
})(["\n  display: block;\n  width: 35%;\n  text-align: left;\n  font-size: 14px;\n"]);
var LabelPrice = exports.LabelPrice = _styledComponents.default.div.withConfig({
  displayName: "styles__LabelPrice",
  componentId: "sc-1cx2r1y-6"
})(["\n  display: block;\n  width: 65%;\n  text-align: right;\n"]);