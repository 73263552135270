"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledWrapper = exports.StyledTextArea = exports.StyledLabel = exports.StyledError = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledWrapper = exports.StyledWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledWrapper",
  componentId: "sc-15dsi9d-0"
})(["\n  border: 0;\n  flex: 1 0 100%;\n  padding: 0 10px;\n  margin-bottom: 50px;\n  position: relative;\n  ", " {\n    padding: 0 50px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.desktop;
});
var StyledLabel = exports.StyledLabel = _styledComponents.default.label.withConfig({
  displayName: "styles__StyledLabel",
  componentId: "sc-15dsi9d-1"
})(["\n  display: block;\n  position: absolute;\n  color: ", ";\n  font-size: 16px;\n  font-family: ", ";\n  top: 0;\n  margin-top: 40px;\n  margin-left: 10px;\n  z-index: -1;\n  transition: 0.2s ease-in-out;\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.light;
});
var StyledError = exports.StyledError = _styledComponents.default.span.withConfig({
  displayName: "styles__StyledError",
  componentId: "sc-15dsi9d-2"
})(["\n  color: ", ";\n  margin-left: 10px;\n"], function (props) {
  return props.theme.color.red;
});
var StyledTextArea = exports.StyledTextArea = _styledComponents.default.textarea.withConfig({
  displayName: "styles__StyledTextArea",
  componentId: "sc-15dsi9d-3"
})(["\n  border: 1px solid #cbcbcb;\n  width: 100%;\n  padding: 10px;\n  outline: none;\n  z-index: 9;\n  margin-top: 30px;\n  background: transparent;\n  height: 250px;\n  &:focus,\n  &:valid {\n    color: #3b61af;\n    border-color: #3b61af;\n  }\n  &:focus ~ label,\n  &:valid ~ label {\n    margin-top: 9px;\n    margin-left: 0px;\n    font-size: 13px;\n  }\n  ", "\n"], function (props) {
  return props.error && "\n    color: #ce1111!important;\n    border-color:#e38892!important;\n  ";
});