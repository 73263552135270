"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTextWrapper = exports.StyledCookiesBanner = exports.StyledButtonWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledCookiesBanner = exports.StyledCookiesBanner = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCookiesBanner",
  componentId: "sc-1xrtudf-0"
})(["\n  width: 100%;\n  min-height: 90px;\n  padding: 20px;\n  background-color: #818181;\n  color: #FFF;\n  position: fixed;\n  bottom: 0;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  z-index: 100000;\n  font-family: 'ApercuNormal', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  flex-wrap: wrap;\n  \n  @media (min-width: 768px) {\n    justify-content: space-between;\n    padding: 0 35px;\n  }\n"]);
var StyledTextWrapper = exports.StyledTextWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTextWrapper",
  componentId: "sc-1xrtudf-1"
})(["\n  flex-basis: 100%;\n  margin-bottom: 20px;\n\n  @media (min-width: 768px) {\n    flex-basis: 80%;\n    margin-bottom: 0;\n  }\n  \n  > a {\n    color: #FFF;\n    \n    &:visited {\n       color: #FFF;\n    }\n  }\n"]);
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-1xrtudf-2"
})(["\n  flex-basis: 100%;\n  background-color: #FFF;\n  line-height: 40px;\n  text-align: center;\n  border-radius: 20px;\n  color: #000;\n  cursor: pointer;\n  \n  @media (min-width: 768px) {\n    flex-basis: 10%;\n  }\n"]);