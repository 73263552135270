"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WrapperContent = exports.StyledRichText = exports.StyledPageBlock = exports.ImgWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledRichText = exports.StyledRichText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledRichText",
  componentId: "sc-9pim1d-0"
})(["\n  font-style: normal;\n  font-stretch: normal; \n  letter-spacing: normal; \n  \n  p, h1, h2, h3, h4, h5, h6 {\n    // padding: 0 10px;\n  }\n  \n  p, div {        \n    font-family: ", ";    \n    font-size: 16px;\n    font-weight: normal;\n    line-height: 24px;\n  }\n  \n  h1, h2, h3, h4, h5, h6 {   \n    margin-bottom: 24px;\n   \n    font-family: ", ";  \n    font-size: 18px;\n    font-weight: 500;\n    line-height: 24px;\n    text-align: center;\n  }\n   \n  img {\n    display: block;\n  }\n\n  & > img {\n    margin: 40px 0px;\n    width: 100vw;\n  }\n  \n  ", "{\n   \n    p, h1, h2, h3, h4, h5, h6 {\n      // padding: 0 100px;\n    }\n  \n    h1, h2, h3, h4, h5, h6 {\n       margin-bottom:40px;\n       \n       font-size: 22px;\n       line-height: 32px;\n    }\n   \n    & > img {\n      margin: 40px -14px;\n    }\n  }  \n  \n  ", "{\n   \n    p, h1, h2, h3, h4, h5, h6, img {\n      // padding: 0 200px;\n    }\n  \n    h1, h2, h3, h4, h5, h6 {\n       margin-bottom: 60px;\n    }\n   \n    & > img {\n      margin: 60px auto;\n      width: 100%;\n    }\n  }  \n\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var ImgWrapper = exports.ImgWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__ImgWrapper",
  componentId: "sc-9pim1d-1"
})(["\n\n"]);
var WrapperContent = exports.WrapperContent = _styledComponents.default.div.withConfig({
  displayName: "styles__WrapperContent",
  componentId: "sc-9pim1d-2"
})(["\n display:flex;\n flex-direction:", ";\n// align-items: center;\n padding: 0 10px;\n \n margin-bottom:40px;\n \n \n p {\n  margin:0;\n  margin-top:15px\n  }\n\n", "\n  \n  \n  \n ", "\n  \n   ", "\n  \n  \n  \n  \n  \nimg{\n  width: ", ";\n  margin-left:-10px;\n}\n \n \n \n  ", " { \n     padding: 0 100px;\n     img{\n  width:100%;\n   margin-left:0;\n}\n   }  \n  \n  ", "{\n    padding: 0 200px; \n     margin-bottom:60px;\n     img{\n  width:100%;\n}\n  }  \n \n"], function (props) {
  return props.type === 1 ? 'column' : 'row-reverse';
}, function (props) {
  return props.redLinks && (0, _styledComponents.css)(["\n  a {\n    color: #E81C69\n  }\n"]);
}, function (props) {
  return props.type === 2 && (0, _styledComponents.css)(["\n  \n & > *{\n   flex:1;\n   \n   &:first-child{\n  \n     margin-left:15px;\n     }\n   &:not(:first-child){\n     margin-right:15px;\n     }  \n }\n\n "]);
}, function (props) {
  return props.type === 1 && (0, _styledComponents.css)(["\n\n  \n & > *{\n   \n   &:first-child{\n  \n     margin-bottom:60px;\n     }\n }\n\n "]);
}, function (props) {
  return props.type === 1 ? '100vw' : '100%';
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var StyledPageBlock = exports.StyledPageBlock = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledPageBlock",
  componentId: "sc-9pim1d-3"
})(["\n  color: ", ";\n  \n  ", "\n  \n  \n  ", "\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.isEditorial && (0, _styledComponents.css)(["\n    \n    ", " {\n   \n      @media (min-width: 768px) {\n        padding: 20px 0;\n      }\n    \n      @media (min-width: 1440px) {\n        padding: 20px 335px;      \n      }\n      \n      > p {\n     \n        @media (min-width: 768px) {\n          padding: 0 115px;\n        }\n      \n        @media (min-width: 1440px) {\n          padding: 0;      \n        }\n      }\n    }\n    \n    ", "\n   \n  "], WrapperContent, function (props) {
    return props.type === 'img_left' && (0, _styledComponents.css)(["\n        ", " {\n          //margin: 0;\n          \n          img {\n            width: 100vw;\n            \n            @media (min-width: 768px) {\n              width: 100%;\n            }\n          }\n          \n          @media (min-width: 768px) {\n            flex-basis: 50%;\n          }\n        }\n\n        ", " {\n          flex-direction: column-reverse;\n        \n          @media (min-width: 768px) {\n            flex-direction: row-reverse;\n            padding: 20px 0;\n          }\n        \n          @media (min-width: 1440px) {\n            padding: 20px 335px;      \n          }\n          \n          > p {\n            margin: 40px 0 0;\n          \n            @media (min-width: 768px) {\n              flex-basis: 50%;\n              padding: 0 114px 0 0;\n              margin: 0;\n            }\n          \n            @media (min-width: 1440px) {\n              padding: 0;      \n            }\n          }\n        }\n    "], ImgWrapper, WrapperContent);
  });
}, function (props) {
  return props.isViviesco && (0, _styledComponents.css)(["\n\n    h2 {\n      font-size: 18px;\n      line-height: 1.5;\n      \n      @media (min-width: 768px) {\n        font-size: 26px;  \n      }\n      \n      @media (min-width: 1440px) {\n        font-size: 30px;\n      }\n    }\n    \n    ", " {\n      @media (min-width: 1440px) {\n        padding: 20px 235px;\n      }\n      \n      p {\n        @media (min-width: 1440px) {\n          padding: 0 100px;\n        }\n      }\n    }\n  "], WrapperContent);
});