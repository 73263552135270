"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Wrapper = exports.StyledTitle = exports.StyledListWrapper = exports.StyledListItem = exports.StyledLink = exports.StyledDescription = exports.StyledButtonWrapper = exports.StyledBoxOffer = exports.StyledAlternative = exports.PriceStrikethrough = exports.GreenBadgeStyle = exports.FullWidthColumn = exports.Block = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledBoxOffer = exports.StyledBoxOffer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBoxOffer",
  componentId: "sc-1tmtkip-0"
})(["\n  width: auto;\n  height: 100%;\n  min-width: 270px;\n  max-width: 310px;\n  min-height: 568px;\n  padding: 36px 15px 45px;\n  border-radius: 35px;\n  box-shadow: 0 0 35px 0 ", ";\n  position: relative;\n  margin-bottom: 45px;\n"], function (props) {
  return props.theme.color.shadowBlue;
});
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-1tmtkip-1"
})(["\n  min-height: 60px;\n  font-family: ", ";\n  font-size: 22px;\n  color: ", ";\n  text-align: center;\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledDescription = exports.StyledDescription = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDescription",
  componentId: "sc-1tmtkip-2"
})(["\n  min-height: 30px;\n  font-family: ", ";\n  font-size: 14px;\n  color: ", ";\n  text-align: center;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var GreenBadgeStyle = exports.GreenBadgeStyle = _styledComponents.default.div.withConfig({
  displayName: "styles__GreenBadgeStyle",
  componentId: "sc-1tmtkip-3"
})(["\n  font-family: ", ";\n  font-weight: 400;\n  color: #6ec18a;\n  text-align: center;\n  span {\n    float: left;\n    padding-top: 5px;\n  }\n"], function (props) {
  return props.theme.font.medium;
});
var StyledListWrapper = exports.StyledListWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledListWrapper",
  componentId: "sc-1tmtkip-4"
})(["\n  font-size: 14px;\n\n  color: ", ";\n  > img {\n    width: 100%;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
});
var StyledListItem = exports.StyledListItem = _styledComponents.default.li.withConfig({
  displayName: "styles__StyledListItem",
  componentId: "sc-1tmtkip-5"
})(["\n  min-height: 12px;\n  font-family: ", ";\n  position: relative;\n  padding-left: 22px;\n  padding-top: 8px;\n  vertical-align: middle;\n\n  &:not(:last-child) {\n    margin-bottom: 13px;\n  }\n"], function (props) {
  return props.theme.font.regular;
});
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-1tmtkip-6"
})(["\n  width: 160px;\n  position: absolute;\n  left: 50%;\n  bottom: -21px;\n  padding: 0 auto;\n  transform: translateX(-50%);\n  > button {\n    line-height: 40px;\n    padding: 0;\n  }\n  > a {\n    padding: 0;\n  }\n"]);
var StyledLink = exports.StyledLink = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledLink",
  componentId: "sc-1tmtkip-7"
})(["\n  display: block;\n  font-size: 14px;\n  font-family: ", ";\n  cursor: pointer;\n  margin-bottom: 10px;\n  text-decoration: underline;\n  color: #e81c69;\n"], function (props) {
  return props.theme.font.regular;
});
var FullWidthColumn = exports.FullWidthColumn = _styledComponents.default.div.withConfig({
  displayName: "styles__FullWidthColumn",
  componentId: "sc-1tmtkip-8"
})(["\n  display: flex;\n  flex: 1 0 100%;\n  text-align: right;\n"]);
var PriceStrikethrough = exports.PriceStrikethrough = _styledComponents.default.div.withConfig({
  displayName: "styles__PriceStrikethrough",
  componentId: "sc-1tmtkip-9"
})(["\n  text-decoration: line-through;\n  font-size: 12px;\n  display: block;\n  width: 100%;\n  margin-right: 0px;\n"]);
var Wrapper = exports.Wrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-1tmtkip-10"
})(["\n  display: flex;\n  width: 100%;\n  line-height: 50px;\n"]);
var Block = exports.Block = _styledComponents.default.div.withConfig({
  displayName: "styles__Block",
  componentId: "sc-1tmtkip-11"
})(["\n  margin: 0px 0 20px 0px;\n  padding: 0;\n  font-family: ", ";\n  font-weight: 400;\n  color: #3f5063;\n  b,\n  strong {\n    font-family: ", ";\n    font-weight: 400;\n  }\n  img {\n    padding: 10px 0;\n  }\n  small {\n    font-size: 10px;\n    display: block;\n    padding-top: 10px;\n    padding-bottom: 0px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.font.bold;
});
var StyledAlternative = exports.StyledAlternative = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledAlternative",
  componentId: "sc-1tmtkip-12"
})(["\n  width: 75%;\n  position: absolute;\n  top: -30px;\n  left: 50%;\n  transform: translateX(-50%);\n  font-family: ", ";\n  background: ", ";\n  padding: 15px 20px;\n  text-align: center;\n  color: ", ";\n  border-radius: 10px;\n  font-size: 14px;\n  text-transform: uppercase;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.pinkOrange;
}, function (props) {
  return props.theme.color.white;
});