import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { Codice } from '@alkemy/components'

export default compose(
  withPageBuilder({
    niceName: 'Iframe Advanced',
  }),
  withFields([
    {
      prop: 'Title',
      label: 'Titolo',
    },
    {
      prop: 'Code',
      label: 'Codice',
    },
  ]),
)(Codice)
