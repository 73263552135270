"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateTimeForm = exports.validatePersonalDataForm = exports.validateDaysForm = exports.validateAttivitaForm = exports.sortHours = exports.setAppuntamento = exports.makeTime = exports.makeDays = exports.makeAttivita = exports.getWellFormattedDatePart = exports.getFormattedDate = exports.getFiliali = exports.getDisponibilita = exports.getAvailableHoursForDay = exports.getAttivita = exports.capitalizeFirstLetter = exports.WEEK_DAYS = exports.TOKEN = exports.MONTH = exports.ICONS = exports.DAY_STEP = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _axios = _interopRequireDefault(require("axios"));
var TOKEN = exports.TOKEN = 'xNDsi7jTLeQQoYmbxsA+7nHyOCb+oHUv/cPJwTa+G2j9UJwZNBRxyky45GjKoz9GdtHJvokAxUPON8oizRDnqAEmq+4a8ieI99AKgUqMa/xW5kO+cW41mA==';
var DAY_STEP = exports.DAY_STEP = 17;
var WEEK_DAYS = exports.WEEK_DAYS = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];
var MONTH = exports.MONTH = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
var ICONS = exports.ICONS = [{
  id: 1,
  icon: 'NewActivation',
  modal: {
    title: 'NUOVA ATTIVAZIONE',
    text: "La nuova attivazione è l'apertura di una fornitura mai attivata.<br/>Puoi diventare cliente VIVI energia anche se non hai una fornitura attiva."
  }
}, {
  id: 3,
  icon: 'ContractTransfer',
  modal: {
    title: 'VOLTURA',
    text: 'La voltura è un passaggio diretto di un contratto di fornitura attiva senza nessuna interruzione, che prevede la chiusura del contratto da parte del precedente Cliente e la sottoscrizione di un nuovo contratto da parte del nuovo Cliente.'
  }
}, {
  id: 2,
  icon: 'Subenter',
  modal: {
    title: 'SUBENTRO',
    text: "Il subentro è l'attivazione della fornitura da parte di un nuovo Cliente a seguito della cessazione del contratto da parte dell'intestatario precedente (contratto cessato e/o contatore disattivato).<br/>Entrambe implicano la sottoscrizione di un nuovo contratto di fornitura."
  }
}, {
  id: 4,
  icon: 'SupplierChange',
  modal: {
    title: 'CAMBIO FORNITORE',
    text: 'Puoi cambiare fornitore senza costi e senza interruzioni di fornitura.',
    ctaLabel: '',
    ctaUrl: '#'
  }
}, {
  id: 6,
  icon: 'Vas',
  modal: {
    title: 'EFFICIENZA ENERGETICA',
    text: 'Richiedi un appuntamento per informazioni o acquisti su caldaie, climatizzatori, fotovoltaici e wallbox oppure per assicurare il tuo impianto gas e luce.',
    ctaLabel: '',
    ctaUrl: '#'
  }
}, {
  id: 20,
  icon: 'Vas',
  modal: {
    title: 'EFFICIENZA ENERGETICA',
    text: 'Richiedi un appuntamento per informazioni o acquisti su caldaie, climatizzatori, fotovoltaici e wallbox oppure per assicurare il tuo impianto gas e luce.',
    ctaLabel: '',
    ctaUrl: '#'
  }
}];
var makeAttivita = exports.makeAttivita = function makeAttivita(attivita) {
  var id = attivita.id;
  var labelText;
  var description;
  if (id === 1) {
    labelText = 'NUOVA ATTIVAZIONE';
  }
  if (id === 2) {
    labelText = 'SUBENTRO';
  }
  if (id === 4) {
    labelText = 'CAMBIO FORNITORE';
  }
  if (id === 3) {
    labelText = 'VOLTURA';
  }
  if (id === 6) {
    labelText = 'EFFICIENZA ENERGETICA';
  }
  if (id === 20) {
    labelText = 'EFFICIENZA ENERGETICA';
  }
  return {
    id: id,
    name: 'attivita',
    label: labelText
  };
};
var makeDays = exports.makeDays = function makeDays(days) {
  return {
    id: days,
    name: 'days',
    label: days
  };
};
var makeTime = exports.makeTime = function makeTime(time) {
  return {
    id: time,
    name: 'time',
    label: time
  };
};
var getWellFormattedDatePart = exports.getWellFormattedDatePart = function getWellFormattedDatePart(part) {
  if (part && part > 0) {
    return part > 9 ? part : "0".concat(part);
  }
  return '01';
};
var getFormattedDate = exports.getFormattedDate = function getFormattedDate(_ref) {
  var date = _ref.date,
    timeToInject = _ref.timeToInject,
    daysToAdd = _ref.daysToAdd;
  if (daysToAdd && daysToAdd > 0) {
    date.setDate(date.getDate() + daysToAdd);
  }
  var day = date.getDate();
  var month = date.getMonth();
  var year = date.getFullYear();
  return "".concat(year).concat(getWellFormattedDatePart(month + 1)).concat(getWellFormattedDatePart(day)).concat(timeToInject);
};
var getAttivita = exports.getAttivita = function getAttivita() {
  return _axios.default.get('/pub/rest/get/type/attivita').then(function (x) {
    return x.data.data.items;
  }).catch(function () {
    return [];
  });
};
var getFiliali = exports.getFiliali = function getFiliali() {
  return _axios.default.get('/pub/rest/get/type/filiale').then(function (x) {
    return x.data.data.items;
  }).catch(function () {
    return [];
  });
};
var getDisponibilita = exports.getDisponibilita = function getDisponibilita(_ref2) {
  var id = _ref2.id,
    init = _ref2.init,
    end = _ref2.end,
    token = _ref2.token;
  var query = "/rest/custom/method/getfreeslots/filiale/".concat(id, "/datainizio/").concat(init, "/datafine/").concat(end, "?token=").concat(token);
  return _axios.default.get(query).then(function (x) {
    return x.data.data.items;
  }).catch(function () {
    return [];
  });
};
var setAppuntamento = exports.setAppuntamento = function setAppuntamento(_ref3) {
  var filiale = _ref3.filiale,
    nomeFiliale = _ref3.nomeFiliale,
    attivita = _ref3.attivita,
    datainizio = _ref3.datainizio,
    datafine = _ref3.datafine,
    name = _ref3.name,
    surname = _ref3.surname,
    phone = _ref3.phone,
    email = _ref3.email,
    token = _ref3.token,
    textDate = _ref3.textDate,
    textTime = _ref3.textTime;
  var query = '/prenota-appuntamento/';
  return _axios.default.post(query, {
    data: {
      formData: {
        contattolibero: "".concat(name, " ").concat(surname),
        filiale: filiale,
        attivita: attivita,
        datainizio: datainizio,
        datafine: datafine,
        cellulare: phone,
        email: email,
        giacliente: '1'
      },
      nomeFiliale: nomeFiliale,
      textDate: textDate,
      textTime: textTime,
      token: token
    }
  }).then(function (x) {
    return x.data;
  }).catch(function () {
    return [];
  });
};
var validateAttivitaForm = exports.validateAttivitaForm = function validateAttivitaForm(values) {
  var errors = {};
  if (!values.attivita) {
    errors.attivita = "Selezionare un'attività";
  }
  return errors;
};
var validateDaysForm = exports.validateDaysForm = function validateDaysForm(values) {
  var errors = {};
  if (!values.days) {
    errors.days = 'Selezionare un giorno';
  }
  return errors;
};
var validateTimeForm = exports.validateTimeForm = function validateTimeForm(values) {
  var errors = {};
  if (!values.time) {
    errors.time = 'Selezionare un orario';
  }
  return errors;
};
var validatePersonalDataForm = exports.validatePersonalDataForm = function validatePersonalDataForm(values) {
  var name = values.name,
    surname = values.surname,
    phone = values.phone,
    email = values.email,
    emailConfirm = values.emailConfirm;
  var errors = {};
  if (!name) {
    errors.name = 'Campo obbligatorio';
  } else if (!/^[ ',-\.A-Za-z\xC0-\xCF\xD1-\xD6\xD8-\xDD\xDF-\xE5\xE7-\xF6\xF8-\xFD\xFF\u0104-\u0107\u010C\u010D\u0116-\u0119\u012E\u012F\u0141-\u0144\u0152\u0160\u0161\u016A\u016B\u0172\u0173\u0178-\u017E\u2202]+$/.test(name)) {
    errors.name = 'Il nome contiene caratteri non validi';
  }
  if (!surname) {
    errors.surname = 'Campo obbligatorio';
  } else if (!/^[ ',-\.A-Za-z\xC0-\xCF\xD1-\xD6\xD8-\xDD\xDF-\xE5\xE7-\xF6\xF8-\xFD\xFF\u0104-\u0107\u010C\u010D\u0116-\u0119\u012E\u012F\u0141-\u0144\u0152\u0160\u0161\u016A\u016B\u0172\u0173\u0178-\u017E\u2202]+$/.test(surname)) {
    errors.surname = 'Il cognome contiene caratteri non validi';
  }
  if (!phone) {
    errors.phone = 'Campo obbligatorio';
  } else if (!/^[0-9\s\+\-]{7,20}$/i.test(phone)) {
    errors.phone = 'Numero di telefono non valido';
  }
  if (!email) {
    errors.email = 'Campo obbligatorio';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    errors.email = 'Email non valida';
  }
  if (!emailConfirm) {
    errors.emailConfirm = 'Campo obbligatorio';
  } else if (emailConfirm.toLowerCase() !== email.toLowerCase()) {
    errors.emailConfirm = 'Le email devono essere uguali';
  }
  return errors;
};
var capitalizeFirstLetter = exports.capitalizeFirstLetter = function capitalizeFirstLetter(string) {
  return "".concat(string.charAt(0).toUpperCase()).concat(string.slice(1).toLowerCase());
};
var getAvailableHoursForDay = exports.getAvailableHoursForDay = function getAvailableHoursForDay(day, availability) {
  var availableTimes = (0, _toConsumableArray2.default)(new Set(availability.filter(function (x) {
    return x.datainizio.substr(0, 8) === day;
  }))).map(function (t) {
    return t.datainizio.substr(8);
  });
  return availableTimes.reduce(function (prev, t) {
    var hour = t.substr(0, 2);
    var minutes = t.substr(2, 2);
    if (prev[hour]) {
      prev[hour].push(minutes);
    } else {
      prev[hour] = [minutes];
    }
    return prev;
  }, {});
};
var sortHours = exports.sortHours = function sortHours(hours) {
  return Object.keys(hours).sort(function (a, b) {
    return parseInt(a) - parseInt(b);
  });
};