"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledQuestion = exports.StyledIconWrapper = exports.StyledHeader = exports.StyledBody = exports.StyledAnswerWrapper = exports.StyledAccordionFaq = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledAccordionFaq = exports.StyledAccordionFaq = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledAccordionFaq",
  componentId: "sc-y2poce-0"
})(["\n  width: 100%;\n  margin-bottom: 20px;\n  padding: 0 20px;\n  border-radius: 24px;\n  box-shadow: 0 0 35px 0 ", ";\n  overflow: hidden;\n  -webkit-font-smoothing: antialiased;\n  color: ", ";\n\n  @media (min-width: 768px) {\n    padding: 0 45px;\n  }\n"], function (props) {
  return props.theme.color.shadowBlue;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledHeader = exports.StyledHeader = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledHeader",
  componentId: "sc-y2poce-1"
})(["\n  position: relative;\n"]);
var StyledQuestion = exports.StyledQuestion = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledQuestion",
  componentId: "sc-y2poce-2"
})(["\n  width: 210px;\n  padding: 20px 0;\n  font-family: ", ";\n  font-size: 16px;\n  line-height: 1.5;\n  transition: all 0.2s ease-in-out 0s;\n\n  @media (min-width: 768px) {\n    width: 450px;\n  }\n\n  ", "\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.isOpen && (0, _styledComponents.css)(["\n      font-family: ", ";\n    "], function (props) {
    return props.theme.font.bold;
  });
});
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-y2poce-3"
})(["\n  width: 34px;\n  position: absolute;\n  top: 7px;\n  right: 0;\n  transition: all 0.2s ease-in-out 0s;\n  transform-origin: center;\n  transform: rotate(315deg);\n\n  @media (min-width: 768px) {\n    top: 14px;\n    right: -30px;\n  }\n\n  @media (min-width: 1280px) {\n    cursor: pointer;\n  }\n\n  ", "\n"], function (props) {
  return props.isOpen && (0, _styledComponents.css)(["\n      transform: rotate(0deg);\n    "]);
});
var StyledBody = exports.StyledBody = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBody",
  componentId: "sc-y2poce-4"
})([""]);
var StyledAnswerWrapper = exports.StyledAnswerWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledAnswerWrapper",
  componentId: "sc-y2poce-5"
})(["\n  padding-bottom: 20px;\n  font-family: ", ";\n  font-size: 16px;\n  line-height: 1.5;\n\n  a {\n    color: ", ";\n    text-decoration: none;\n\n    &:visited {\n      color: ", ";\n    }\n  }\n\n  @media (min-width: 768px) {\n    padding: 30px 0;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.magenta;
}, function (props) {
  return props.theme.color.magenta;
});