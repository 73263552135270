"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TextWrapper = exports.Text = exports.TabWrapperSticky = exports.TabWrapper = exports.TabSticky = exports.TabContainer = exports.Tab = exports.MainContainer = exports.Line = exports.IconWrapper = exports.EmptyBollino = exports.Bollino = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var MainContainer = exports.MainContainer = _styledComponents.default.div.withConfig({
  displayName: "modalStyles__MainContainer",
  componentId: "sc-laghtc-0"
})(["\n  max-width: 1170px;\n  width: 90%;\n  margin: 0px auto 0 auto;\n\n  ", " {\n    margin-top: 0;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var Line = exports.Line = _styledComponents.default.div.withConfig({
  displayName: "modalStyles__Line",
  componentId: "sc-laghtc-1"
})(["\n  width: 100%;\n  height: 5px;\n  background: ", ";\n  border-radius: 10px;\n  visibility: hidden;\n"], function (props) {
  return props.theme.color.pinkOrange;
});
var TextWrapper = exports.TextWrapper = _styledComponents.default.div.withConfig({
  displayName: "modalStyles__TextWrapper",
  componentId: "sc-laghtc-2"
})(["\n  width: fit-content;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  ", "\n\n  ", "\n"], function (props) {
  return props.active && !props.sticky && "\n      ".concat(Line, "{\n        visibility: visible;\n      }\n  ");
}, function (props) {
  return props.sticky && props.active && "\n    &:after{\n      content: '';\n      position: absolute;\n      bottom: 0;\n      height: 5px;\n      width: 100%;\n      background: ".concat(props.theme.color.pinkOrange, ";\n      border-radius: 10px;\n    }\n  ");
});
var Text = exports.Text = _styledComponents.default.h3.withConfig({
  displayName: "modalStyles__Text",
  componentId: "sc-laghtc-3"
})(["\n  margin: 0;\n  ", "\n  ", " {\n    font-size: 16px !important;\n  }\n"], function (props) {
  if (props.active) {
    return "\n        background: linear-gradient(255deg, #F09724, #E81C69);\n        background-clip: text;\n        -webkit-background-clip: text;\n        color: transparent;\n        text-fill-color: transparent;\n      ";
  } else {
    return "\n        color: ".concat(props.theme.color.darkBlue, ";\n      ");
  }
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var Tab = exports.Tab = _styledComponents.default.div.withConfig({
  displayName: "modalStyles__Tab",
  componentId: "sc-laghtc-4"
})(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  position: relative;\n  cursor: pointer;\n  font-family: ", ";\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.5;\n  letter-spacing: normal;\n  text-align: center;\n  background-clip: padding-box;\n  flex: 50%;\n  text-transform: uppercase;\n  background-clip: padding-box;\n  border: 1px solid transparent;\n  ", ";\n\n  ", " {\n    font-size: ", ";\n  }\n\n  ", " {\n    ", " {\n      font-size: 16px !important;\n    }\n\n    ", "\n  }\n"], function (props) {
  return props.theme.font.light;
}, function (props) {
  return props.active && "background-color: ".concat(props.theme.color.white);
}, Text, function (props) {
  return props.sticky ? '20px' : '24px';
}, function (props) {
  return props.theme.mediaQueries.phone;
}, Text, function (props) {
  return props.active && (0, _styledComponents.css)(["\n        border: 1px solid rgba(230, 230, 230, 0.4);\n        border-bottom: 0;\n      "]);
});
var BaseWrapper = _styledComponents.default.div.withConfig({
  displayName: "modalStyles__BaseWrapper",
  componentId: "sc-laghtc-5"
})(["\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n"]);
var IconWrapper = exports.IconWrapper = (0, _styledComponents.default)(BaseWrapper).withConfig({
  displayName: "modalStyles__IconWrapper",
  componentId: "sc-laghtc-6"
})(["\n  justify-content: space-between !important;\n  margin: 10px 0;\n\n  ", " {\n    align-items: flex-end;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var TabWrapper = exports.TabWrapper = (0, _styledComponents.default)(BaseWrapper).withConfig({
  displayName: "modalStyles__TabWrapper",
  componentId: "sc-laghtc-7"
})(["\n  height: 50px;\n  text-align: center;\n  position: relative;\n  ", " {\n    height: 56px;\n    box-shadow: 0px -10px 25px -10px rgba(200, 200, 200, 0.5);\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var Bollino = exports.Bollino = _styledComponents.default.img.withConfig({
  displayName: "modalStyles__Bollino",
  componentId: "sc-laghtc-8"
})(["\n  height: 73px;\n  width: 271px;\n  transition: all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);\n  opacity: ", ";\n\n  ", " {\n    height: 53px;\n    width: 120px;\n  }\n"], function (props) {
  return props.visible ? '1' : '0';
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var TabContainer = exports.TabContainer = _styledComponents.default.div.withConfig({
  displayName: "modalStyles__TabContainer",
  componentId: "sc-laghtc-9"
})(["\n  display: grid;\n  grid-template-columns: 1fr;\n  grid-template-rows: 120px 120px\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n  flex: 50%;\n\n  ", "{\n    grid-template-rows: 120px 56px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var EmptyBollino = exports.EmptyBollino = _styledComponents.default.div.withConfig({
  displayName: "modalStyles__EmptyBollino",
  componentId: "sc-laghtc-10"
})(["\n  display: none;\n  height: 73px;\n  width: 271px;\n  ", " {\n    height: 48px;\n    width: 130px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var TabWrapperSticky = exports.TabWrapperSticky = (0, _styledComponents.default)(TabWrapper).withConfig({
  displayName: "modalStyles__TabWrapperSticky",
  componentId: "sc-laghtc-11"
})(["\n  position: relative;\n  top: 0;\n  left: 0;\n  width: 100%;\n  border-radius: 0 !important;\n  height: 64px;\n  box-sizing: border-box;\n  transition-duration: 0.5s;\n  opacity: ", ";\n  z-index: ", ";\n"], function (props) {
  return props.active ? '1' : '0';
}, function (props) {
  return props.active ? '9999' : '-1';
});
var TabSticky = exports.TabSticky = _styledComponents.default.div.withConfig({
  displayName: "modalStyles__TabSticky",
  componentId: "sc-laghtc-12"
})(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  position: relative;\n  cursor: pointer;\n  font-family: ", ";\n  font-size: 20px;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.5;\n  letter-spacing: normal;\n  text-align: center;\n  background-clip: padding-box;\n  flex: 50%;\n  text-transform: uppercase;\n  transition: background-color 0.8s cubic-bezier(0.2, 0.8, 0.2, 1),\n    opacity 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);\n  background-clip: padding-box;\n  box-shadow: 0px -10px 25px -10px rgba(200, 200, 200, 0.5);\n\n  ", "\n"], function (props) {
  return props.theme.font.light;
}, function (props) {
  if (props.active) {
    return (0, _styledComponents.css)(["\n        background-color: ", ";\n        box-shadow: 0px -10px 20px -10px rgba(100, 100, 100, 0.5);\n      "], props.theme.color.white);
  }
});