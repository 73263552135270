"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitle = exports.StyledSliderWrapper = exports.StyledCardTitle = exports.StyledCardText = exports.StyledCardSlider = exports.StyledCardImg = exports.StyledCard = exports.StyledBlob = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledCardSlider = exports.StyledCardSlider = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCardSlider",
  componentId: "sc-atrn1s-0"
})(["\n  position: relative;\n  height: 515px;\n  padding-top: 85px;\n  -webkit-font-smoothing: antialiased;\n\n  @media (min-width: 768px) {\n    height: 650px;\n    padding-top: 125px;\n  }\n"]);
var StyledBlob = exports.StyledBlob = _styledComponents.default.img.withConfig({
  displayName: "styles__StyledBlob",
  componentId: "sc-atrn1s-1"
})(["\n  height: 100%;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n"]);
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-atrn1s-2"
})(["\n  margin-bottom: 20px;\n  font-family: ", ";\n  color: ", ";\n  font-size: 18px;\n  text-align: center;\n  line-height: 1.2;\n\n  @media (min-width: 768px) {\n    margin-bottom: 40px;\n    font-size: 26px;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledSliderWrapper = exports.StyledSliderWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSliderWrapper",
  componentId: "sc-atrn1s-3"
})(["\n  .slick-list,\n  .slick-track {\n    overflow: visible !important;\n  }\n\n  .slick-slide {\n    > div {\n      outline: none;\n    }\n  }\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  @media (min-width: 1440px) {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n  }\n"]);
var StyledCard = exports.StyledCard = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCard",
  componentId: "sc-atrn1s-4"
})(["\n  width: 200px !important;\n  height: 300px;\n  margin: 0 10px;\n  padding: 160px 10px 0;\n  position: relative;\n  background-color: ", ";\n  border-radius: 32px;\n  box-shadow: 0 0 35px 0 ", ";\n  outline: none;\n\n  @media (min-width: 768px) {\n    width: 250px !important;\n    height: 300px;\n    margin: 0 25px;\n    padding: 160px 15px 0;\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.color.shadowBlue;
});
var StyledCardImg = exports.StyledCardImg = _styledComponents.default.img.withConfig({
  displayName: "styles__StyledCardImg",
  componentId: "sc-atrn1s-5"
})(["\n  position: absolute;\n  top: 35px;\n  left: 50%;\n  transform: translateX(-50%);\n"]);
var StyledCardTitle = exports.StyledCardTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCardTitle",
  componentId: "sc-atrn1s-6"
})(["\n  margin-bottom: 10px;\n  font-family: ", ";\n  color: ", ";\n  font-size: 18px;\n  text-align: center;\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledCardText = exports.StyledCardText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCardText",
  componentId: "sc-atrn1s-7"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 14px;\n\n  @media (min-width: 768px) {\n    line-height: 1.5;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});