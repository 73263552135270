"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledVivipediaArticleList = exports.StyledTitle = exports.StyledButtonWrapper = exports.StyledArticleWrapper = exports.StyledArticleItem = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-zsyg2k-0"
})(["\n  margin: 40px 0;\n  font-family: ", ";\n  color: ", ";\n  text-align: center;\n  font-size: 18px;\n  \n  @media (min-width: 768px) {\n    margin: 60px 0;\n    font-size: 22px;  \n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledArticleWrapper = exports.StyledArticleWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledArticleWrapper",
  componentId: "sc-zsyg2k-1"
})(["\n  max-width: 375px;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n  \n  @media (min-width: 768px) {\n    max-width: 696px;\n    flex-direction: row;\n    justify-content: space-between;\n    flex-wrap: wrap;\n  }\n  \n  @media (min-width: 1440px) {\n    max-width: 1170px;\n  }\n"]);
var StyledArticleItem = exports.StyledArticleItem = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledArticleItem",
  componentId: "sc-zsyg2k-2"
})(["\n  margin-bottom: 40px;\n  \n  @media (min-width: 768px) {\n    margin-bottom: 60px;\n  }\n"]);
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-zsyg2k-3"
})(["\n  width: 180px;\n  margin: 40px auto 0;\n  \n  @media (min-width: 768px) {\n    margin: 20px auto 0;  \n  }\n"]);
var StyledVivipediaArticleList = exports.StyledVivipediaArticleList = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledVivipediaArticleList",
  componentId: "sc-zsyg2k-4"
})(["\n  margin: 60px 0 80px;\n  \n  @media (min-width: 768px) {\n    margin: 80px 0 100px;\n  }\n  \n  @media (min-width: 1440px) {\n    margin: 80px 0 120px;\n  }\n"]);