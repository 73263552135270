"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledWrapper = exports.StyledTitle = exports.StyledIcon = exports.StyledHighlight = exports.StyledGoTo = exports.StyledContent = exports.GoWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledWrapper = exports.StyledWrapper = _styledComponents.default.div.withConfig({
  displayName: "style__StyledWrapper",
  componentId: "sc-14yg7t6-0"
})(["\n  .slick-track {\n    margin: 0 auto;\n  }\n  .slick-list {\n    padding: 0 !important;\n  }\n\n  .slick-dots {\n    bottom: -40px;\n  }\n\n  .slick-dots li.slick-active button:before {\n    color: ", ";\n    font-size: 12px;\n  }\n\n  .slick-dots li button::before {\n    color: ", ";\n    font-size: 12px;\n  }\n  margin-bottom: 110px;\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledHighlight = exports.StyledHighlight = _styledComponents.default.div.withConfig({
  displayName: "style__StyledHighlight",
  componentId: "sc-14yg7t6-1"
})(["\n  position: relative;\n  flex-grow: 0;\n  flex-basis: 100%;\n  width: 100%;\n\n  ", " {\n    padding: 20px;\n  }\n\n  ", "\n\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n  flex-wrap: wrap;\n  gap: 8px;\n  flex-direction: column;\n"], function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  return props.refund && (0, _styledComponents.css)(["\n      text-align: center !important;\n    "]);
});
var StyledIcon = exports.StyledIcon = _styledComponents.default.div.withConfig({
  displayName: "style__StyledIcon",
  componentId: "sc-14yg7t6-2"
})(["\n  align-self: center;\n  width: 80px;\n  margin: 0 auto 12px auto;\n  height: 80px;\n  width: 80px;\n\n  svg {\n    width: 100%;\n    height: 100%;\n  }\n  ", " {\n    width: 110px;\n    height: 110px;\n  }\n\n  ", " {\n    align-self: ", ";\n\n    flex-grow: 0;\n    flex-basis: 135px;\n  }\n\n  ", "\n  height: auto;\n\n  justify-content: center;\n  justify-items: center;\n  align-items: center;\n  align-content: center;\n  display: flex;\n  ", "\n\n  > img {\n    width: 100%;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.desktop;
}, function (props) {
  return props.center ? 'center' : 'flex-start';
}, function (props) {
  return props.smallIcon && (0, _styledComponents.css)(["\n      width: 65px;\n      height: 65px;\n      flex-basis: 65px;\n      justify-content: flex-start;\n      max-height: 65px;\n      > div {\n        height: 100%;\n      }\n      ", " {\n        width: 65px;\n        height: 65px;\n        max-height: 65px;\n        flex-basis: 65px;\n      }\n      ", " {\n        width: 65px;\n        height: 65px;\n        max-height: 65px;\n        flex-basis: 65px;\n      }\n    "], function (props) {
    return props.theme.mediaQueries.tablet;
  }, function (props) {
    return props.theme.mediaQueries.desktop;
  });
}, function (props) {
  return props.cols === '1' && (0, _styledComponents.css)(["\n      margin: 0px !important;\n    "]);
});
var StyledContent = exports.StyledContent = _styledComponents.default.div.withConfig({
  displayName: "style__StyledContent",
  componentId: "sc-14yg7t6-3"
})(["\n  display: flex;\n  flex-flow: column;\n  align-content: center;\n  justify-content: flex-start;\n  justify-items: center;\n\n  ", " {\n    flex: 1;\n    padding: 0 20px;\n  }\n  ", "\n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.cols === '1' && (0, _styledComponents.css)(["\n      width: 100%;\n      text-align: ", ";\n      @media (max-width: 767px) {\n        > div {\n          text-align: center;\n        }\n      }\n    "], function (props) {
    return props.center ? 'center' : 'left';
  });
});
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "style__StyledTitle",
  componentId: "sc-14yg7t6-4"
})(["\n  font-size: 16px;\n  font-family: ", ";\n  color: ", ";\n  text-align: center;\n  text-transform: uppercase;\n  font-weight: 400;\n  margin: 0;\n\n  ", " {\n    font-size: 20px;\n  }\n\n  ", " {\n    margin: 7px 0 3px 0;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.orange;
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});
var StyledGoTo = exports.StyledGoTo = _styledComponents.default.div.withConfig({
  displayName: "style__StyledGoTo",
  componentId: "sc-14yg7t6-5"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: bold;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: 1.57;\n  letter-spacing: normal;\n  text-align: center;\n  text-decoration: none;\n"], function (props) {
  return props.theme.color.magenta;
}, function (props) {
  return props.theme.font.regular;
});
var GoWrapper = exports.GoWrapper = _styledComponents.default.a.withConfig({
  displayName: "style__GoWrapper",
  componentId: "sc-14yg7t6-6"
})(["\n  text-decoration: none;\n  margin-top: auto;\n  width: 100%;\n  justify-content: center;\n  padding-left: 20px;\n  padding-bottom: 10px;\n  padding-top: 10px;\n  display: flex;\n  flex-direction: row;\n  flex-grow: 1;\n  align-items: flex-end;\n  & > div {\n    white-space: nowrap;\n    height: 20px;\n    svg {\n      margin-top: -15px;\n      height: auto;\n    }\n\n    &:last-child {\n      width: 50px;\n    }\n  }\n"]);