"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledEditorialGridItem = exports.StyledEditorialGrid = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledEditorialGrid = exports.StyledEditorialGrid = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledEditorialGrid",
  componentId: "sc-j94n9v-0"
})(["\n  display: flex;\n  flex-direction: column;\n  padding-left: 20px;\n  padding-right: 20px;\n  ", " {\n    padding-left: 0;\n    padding-right: 0;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: space-between;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledEditorialGridItem = exports.StyledEditorialGridItem = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledEditorialGridItem",
  componentId: "sc-j94n9v-1"
})(["\n  margin: 0 0 24px;\n  flex: 1;\n\n  ", " {\n    margin: 0 0 48px;\n    flex: 0 0 calc(50% - 15px);\n    min-height: 272px;\n    ", "\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.isSingle && (0, _styledComponents.css)(["\n        flex: 1;\n        min-height: 415px;\n      "]);
});