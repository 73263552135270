"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTestForm = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledTestForm = exports.StyledTestForm = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTestForm",
  componentId: "sc-1osmpbo-0"
})([""]);