import React from 'react'

export const MeasuresContext = React.createContext()
export const MeasuresConsumer = MeasuresContext.Consumer

const withMeasuresProvider = WrappedComponent =>
  class MeasuresProvider extends React.PureComponent {
    static async getInitialProps(ctx) {
      let props = {}
      if (typeof WrappedComponent.getInitialProps === 'function') {
        props = await WrappedComponent.getInitialProps(ctx)
      }
      return {
        ...props,
      }
    }

    constructor(props) {
      super(props)
      this.state = {}
      this.addMeasure = this.addMeasure.bind(this)
    }

    addMeasure(name, value) {
      this.setState({
        [name]: value,
      })
    }

    render() {
      return (
        <MeasuresContext.Provider
          value={{
            measures: this.state,
            addMeasure: this.addMeasure,
          }}
        >
          <WrappedComponent {...this.props} />
        </MeasuresContext.Provider>
      )
    }
  }

export default withMeasuresProvider
