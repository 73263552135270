"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledText = exports.StyledIcon = exports.StyledGridItemInner = exports.StyledGridItem = exports.StyledGrid = exports.StyledActive = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _styles = require("../../styles");
var StyledGrid = exports.StyledGrid = (0, _styledComponents.default)(_styles.LayoutContainer).withConfig({
  displayName: "styles__StyledGrid",
  componentId: "sc-1zx807-0"
})(["\n  margin: 0 auto;\n  flex-wrap: wrap;\n  gap: 12px;\n  font-family: ", ";\n  color: ", ";\n\n  ", " {\n    gap: 30px;\n  }\n\n  > span {\n    flex: 0 0 100%;\n    display: block;\n    text-align: center;\n  }\n\n  + button {\n    margin-top: 50px;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledGridItem = exports.StyledGridItem = (0, _styledComponents.default)(_styles.LayoutItem).withConfig({
  displayName: "styles__StyledGridItem",
  componentId: "sc-1zx807-1"
})(["\n  border-radius: 12px;\n\n  ", " {\n    border-radius: 24px;\n  }\n\n  &.selected {\n    background-image: linear-gradient(40deg, #6ec18a 17%, #0e89ca 157%),\n      linear-gradient(40deg, #6ec18a 17%, #0e89ca 157%);\n    color: ", ";\n    box-shadow: 0 0 10px 0 rgba(21, 61, 35, 0.4);\n  }\n\n  &:hover {\n    ", " {\n      color: ", ";\n    }\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.color.white;
}, StyledText, function (props) {
  return props.theme.color.white;
});
var StyledGridItemInner = exports.StyledGridItemInner = (0, _styledComponents.default)(_styles.FlexCenter).withConfig({
  displayName: "styles__StyledGridItemInner",
  componentId: "sc-1zx807-2"
})(["\n  flex-direction: column;\n  gap: 4px;\n  width: 92px;\n  height: 90px;\n  padding: 12px;\n\n  ", " {\n    gap: 8px;\n    width: 170px;\n    height: 170px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledIcon = exports.StyledIcon = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIcon",
  componentId: "sc-1zx807-3"
})(["\n  width: 40px;\n  height: 40px;\n  ", " {\n    width: 65px;\n    height: 65px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledText = exports.StyledText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledText",
  componentId: "sc-1zx807-4"
})(["\n  padding: 0 12px;\n  font-size: 9px;\n  line-height: 1.33;\n  text-transform: uppercase;\n  text-align: center;\n  ", " {\n    font-size: 14px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledActive = exports.StyledActive = (0, _styledComponents.default)(_styles.FlexCenter).withConfig({
  displayName: "styles__StyledActive",
  componentId: "sc-1zx807-5"
})(["\n  position: absolute;\n  width: 24px;\n  height: 24px;\n  top: 10px;\n  right: 10px;\n  border-radius: 50%;\n  background: rgba(255, 255, 255, 0.31);\n  box-sizing: border-box;\n  padding: 3px;\n"]);