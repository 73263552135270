"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitleStep = exports.StyledSubtitle = exports.StyledGrid = exports.StyledBanner = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _styles = require("../../styles");
var StyledGrid = exports.StyledGrid = (0, _styledComponents.default)(_styles.FlexCenter).withConfig({
  displayName: "styles__StyledGrid",
  componentId: "sc-3a1rt8-0"
})(["\n  flex-direction: column;\n  justify-content: center;\n  max-width: 1200px;\n  margin-left: auto;\n  margin-right: auto;\n  min-height: calc(100vh - 78px);\n  padding: 60px 20px;\n  * {\n    box-sizing: border-box;\n  }\n"]);
var StyledTitleStep = exports.StyledTitleStep = _styledComponents.default.h2.withConfig({
  displayName: "styles__StyledTitleStep",
  componentId: "sc-3a1rt8-1"
})(["\n  font-family: ", ";\n  font-size: 18px;\n  line-height: 1.44;\n  color: ", ";\n  text-align: center;\n  margin: 0 0 24px;\n\n  ", " {\n    font-size: 30px;\n    line-height: 1.33;\n    margin: 0 0 40px;\n    max-width: 570px;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledSubtitle = exports.StyledSubtitle = _styledComponents.default.h3.withConfig({
  displayName: "styles__StyledSubtitle",
  componentId: "sc-3a1rt8-2"
})(["\n  font-family: ", ";\n  font-size: 14px;\n  line-height: 1.5;\n  color: ", ";\n  text-align: center;\n  margin: 0 0 8px;\n\n  ", " {\n    font-size: 24px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledBanner = exports.StyledBanner = _styledComponents.default.img.withConfig({
  displayName: "styles__StyledBanner",
  componentId: "sc-3a1rt8-3"
})(["\n  max-width: 960px;\n  width: 100%;\n  height: auto;\n  padding: 20px 0;\n"]);