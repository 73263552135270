"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TextWrapper = exports.Text = exports.Subtext = exports.Link = exports.IconWrapper = exports.Documento = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var Documento = exports.Documento = _styledComponents.default.div.withConfig({
  displayName: "style__Documento",
  componentId: "sc-21br47-0"
})(["\n  width: 100%;\n  font-family: ", ";\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  gap: 35px;\n\n  ", " {\n    gap: 16px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var TextWrapper = exports.TextWrapper = _styledComponents.default.div.withConfig({
  displayName: "style__TextWrapper",
  componentId: "sc-21br47-1"
})(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 5px;\n  font-family: ", ";\n  color: ", ";\n"], function (props) {
  return props.theme.font.light;
}, function (props) {
  return props.theme.color.darkBlue;
});
var Text = exports.Text = _styledComponents.default.span.withConfig({
  displayName: "style__Text",
  componentId: "sc-21br47-2"
})(["\n  font-size: 16px;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.5;\n  letter-spacing: normal;\n  font-family: ", ";\n\n  ", " {\n    font-size: 14px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var Subtext = exports.Subtext = _styledComponents.default.span.withConfig({
  displayName: "style__Subtext",
  componentId: "sc-21br47-3"
})(["\n  font-size: 12px;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.5;\n  letter-spacing: normal;\n"]);
var IconWrapper = exports.IconWrapper = _styledComponents.default.div.withConfig({
  displayName: "style__IconWrapper",
  componentId: "sc-21br47-4"
})([""]);
var Link = exports.Link = _styledComponents.default.a.withConfig({
  displayName: "style__Link",
  componentId: "sc-21br47-5"
})(["\n  position: relative;\n  padding-right: 40px;\n\n  ", " {\n    padding-right: 17px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});