"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitleWrapper = exports.StyledTextWrapper = exports.StyledMissionItem = exports.StyledIconWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledMissionItem = exports.StyledMissionItem = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledMissionItem",
  componentId: "sc-131o3or-0"
})(["\n  margin-bottom: 45px;\n  -webkit-font-smoothing: antialiased;\n  \n  @media (min-width: 1440px) {\n    margin: 0;\n    padding: 0 10px;\n  }\n"]);
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-131o3or-1"
})(["\n  margin-bottom: 22px;\n  width: 65px;\n  \n  ", " \n"], function (props) {
  return props.icon === 'Award' && (0, _styledComponents.css)(["\n    position: relative;\n    left: -18px;\n  "]);
});
var StyledTitleWrapper = exports.StyledTitleWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitleWrapper",
  componentId: "sc-131o3or-2"
})(["\n  margin-bottom: 15px;\n  font-family: ", ";\n  font-size: 22px;\n"], function (props) {
  return props.theme.font.medium;
});
var StyledTextWrapper = exports.StyledTextWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTextWrapper",
  componentId: "sc-131o3or-3"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 16px;\n  line-height: 1.5;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});