import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'
import * as SVGIcon from '@alkemy/components/lib/components/Icons/SVG'
import * as SVGIconIllustrate from '@alkemy/components/lib/components/Icons/illustrate'
import { Icons, withWrapper } from '@alkemy/components'
import ListOfIconsCTA from '../components/ListOfIconsCTA'
import styled from 'styled-components'

const iconOptionsOld = Object.keys(SVGIcon).map(item => `${item}:${item}`)
const iconOptionsNew = Object.keys(SVGIconIllustrate).map(
  item => `${item}:${item}`,
)

const IconWrapper = styled.div`
  width: 35px;
  height: 35px;
  padding: 5px;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 20px;
  flex: 0 35px;
  svg {
    width: 100% !important;
    height: 100% !important;
  }
  > div {
    width: 100% !important;
    height: 100% !important;
    > svg {
      width: 100% !important;
      height: 100% !important;
    }
    > div {
      > svg {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
`
const OptionContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  > span {
    flex: 1;
  }
`
const optionsComponent = props => (
  <OptionContainer style={{ height: '40px' }}>
    <IconWrapper>
      <Icons icon={props.data.value} style={{ width: '100%' }} color="#000" />
    </IconWrapper>
    <span>{props.data.label}</span>
  </OptionContainer>
)

export default compose(
  withPageBuilder({
    niceName: 'Griglia Icone e CTA',
  }),
  withFields([
    {
      prop: 'wrapped',
      type: 'boolean',
      label: 'Contenuto Boxed',
    },
    {
      prop: 'items',
      type: 'repeater',
      label: 'Lista',
      model: [
        {
          prop: 'label',
          label: 'Label',
        },
        {
          prop: 'description',
          label: 'Description',
        },
        {
          prop: 'icon',
          type: 'select',
          label: 'Icona',
          optionsComponent,
          options: [...iconOptionsNew, ...iconOptionsOld],
          initialValue: 'Gas',
        },
        {
          prop: 'cta',
          type: 'multiple',
          label: 'Call To Action',
          options: [
            'accedi:Accedi',
            'scarica:Scarica la App',
            'recall:Parla con noi',
            'storelocator:Storelocator',
            'green:Numero Verde',
            'chat:Apri Chat',
            'link:Link',
          ],
          initialValue: 'accedi',
        },
        {
          prop: 'href',
          label: 'Link',
        },
        {
          prop: 'buttonLabel',
          label: 'Testo Bottone Generico',
        },
        {
          prop: 'targetBlank',
          type: 'boolean',
          label: 'Apri in nuova finestra',
        },
        {
          prop: 'hrefAndroid',
          label: 'Android',
        },
        {
          prop: 'hrefApple',
          label: 'Apple',
        },
        {
          prop: 'buttonLabel',
          label: 'Testo Bottone Generico',
        },
        {
          prop: 'greenNumber',
          label: 'Numero Verde',
        },
      ],
    },
  ]),
  withWrapper,
)(ListOfIconsCTA)
