"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Wrapper = exports.StyledLinkWrapper = exports.StyledIconWrapperLink = exports.StyledIconWrapper = exports.SecondaryWrapper = exports.PricePerDay = exports.Price = exports.PhoneSubtitle = exports.PerDay = exports.PaymentOptionsTitle = exports.Paragraph = exports.ListWrapper = exports.Line = exports.LegalNotices = exports.LegalNoteVivi = exports.ImgRow = exports.ImgRelative = exports.ImgCol = exports.ImgAbs = exports.IconsRow = exports.IconsCol = exports.FlexContainerRow = exports.FlexContainerCol = exports.FasciaWrapper = exports.FasciaTitle = exports.FasciaLabel = exports.FasciaItem = exports.FasciaImg = exports.FasciaContent = exports.DownloadWrapper = exports.ContentCol = exports.BoxViviescoTxt = exports.BoxViviescoImg = exports.BoxViviescoBorder = exports.BoxViviesco = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var Paragraph = exports.Paragraph = _styledComponents.default.div.withConfig({
  displayName: "style__Paragraph",
  componentId: "sc-1jwhcu8-0"
})(["\n  margin: 0 auto 2rem;\n  font-size: 25px;\n  line-height: 30px;\n"]);
var Wrapper = exports.Wrapper = _styledComponents.default.div.withConfig({
  displayName: "style__Wrapper",
  componentId: "sc-1jwhcu8-1"
})(["\n  position: relative;\n  color: ", ";\n  font-family: ", ";\n  margin: 6rem 1rem;\n  padding: auto;\n  @media screen and (min-width: 768px) {\n    padding: 0 3rem;\n    max-width: 740px;\n    margin: auto;\n  }\n  @media screen and (min-width: 1024px) {\n    max-width: 970px;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.regular;
});
var LegalNotices = exports.LegalNotices = _styledComponents.default.div.withConfig({
  displayName: "style__LegalNotices",
  componentId: "sc-1jwhcu8-2"
})(["\n  font-size: 12px;\n  line-height: 15px;\n  margin: 7rem 0 10rem 0;\n"]);
var LegalNoteVivi = exports.LegalNoteVivi = _styledComponents.default.div.withConfig({
  displayName: "style__LegalNoteVivi",
  componentId: "sc-1jwhcu8-3"
})(["\n  font-size: 12px;\n  line-height: 15px;\n  font-family: ", ";\n"], function (props) {
  return props.theme.font.regular;
});
var PricePerDay = exports.PricePerDay = _styledComponents.default.div.withConfig({
  displayName: "style__PricePerDay",
  componentId: "sc-1jwhcu8-4"
})(["\n  display: flex;\n  margin: 1.5rem 0 1rem;\n"]);
var Price = exports.Price = _styledComponents.default.div.withConfig({
  displayName: "style__Price",
  componentId: "sc-1jwhcu8-5"
})(["\n  font-size: 60px;\n  line-height: 24px;\n  margin-bottom: 1rem;\n"]);
var PerDay = exports.PerDay = _styledComponents.default.div.withConfig({
  displayName: "style__PerDay",
  componentId: "sc-1jwhcu8-6"
})(["\n  margin: auto 0 0.3rem;\n  font-size: 25px;\n"]);
var SecondaryWrapper = exports.SecondaryWrapper = _styledComponents.default.div.withConfig({
  displayName: "style__SecondaryWrapper",
  componentId: "sc-1jwhcu8-7"
})(["\n  margin: 10rem auto;\n  text-align: center;\n"]);
var PhoneSubtitle = exports.PhoneSubtitle = _styledComponents.default.div.withConfig({
  displayName: "style__PhoneSubtitle",
  componentId: "sc-1jwhcu8-8"
})(["\n  margin-bottom: 0;\n  @media screen and (min-width: 768px) {\n    margin-bottom: 3rem;\n  }\n"]);
var PaymentOptionsTitle = exports.PaymentOptionsTitle = _styledComponents.default.div.withConfig({
  displayName: "style__PaymentOptionsTitle",
  componentId: "sc-1jwhcu8-9"
})(["\n  font-size: 18px;\n"]);
var FlexContainerRow = exports.FlexContainerRow = _styledComponents.default.div.withConfig({
  displayName: "style__FlexContainerRow",
  componentId: "sc-1jwhcu8-10"
})(["\n  display: flex;\n  flex-direction: ", ";\n  margin: 0 1rem 7rem;\n  @media screen and (min-width: 768px) {\n    margin: 0 0 7rem 0;\n  }\n  @media screen and (min-width: 1024px) {\n    flex-direction: ", ";\n    justify-content: ", ";\n  }\n"], function (props) {
  return props.product ? 'column' : 'column-reverse';
}, function (props) {
  return props.inverted ? 'row-reverse' : 'row';
}, function (props) {
  return props.inverted ? 'space-between' : 'start';
});
var FlexContainerCol = exports.FlexContainerCol = _styledComponents.default.div.withConfig({
  displayName: "style__FlexContainerCol",
  componentId: "sc-1jwhcu8-11"
})(["\n  display: flex;\n  flex-direction: column-reverse;\n  margin: auto;\n  justify-content: space-between;\n  @media screen and (min-width: 768px) {\n  }\n  @media screen and (min-width: 1024px) {\n  }\n"]);
var IconsCol = exports.IconsCol = _styledComponents.default.div.withConfig({
  displayName: "style__IconsCol",
  componentId: "sc-1jwhcu8-12"
})(["\n  text-align: left;\n  width: auto;\n  @media screen and (min-width: 768px) {\n  }\n  @media screen and (min-width: 1024px) {\n    width: 50%;\n  }\n"]);
var IconsRow = exports.IconsRow = _styledComponents.default.div.withConfig({
  displayName: "style__IconsRow",
  componentId: "sc-1jwhcu8-13"
})(["\n  text-align: center;\n  width: 100%;\n  @media screen and (min-width: 768px) {\n  }\n  @media screen and (min-width: 1024px) {\n  }\n"]);
var DownloadWrapper = exports.DownloadWrapper = _styledComponents.default.div.withConfig({
  displayName: "style__DownloadWrapper",
  componentId: "sc-1jwhcu8-14"
})(["\n  width: 100%;\n  @media screen and (min-width: 768px) {\n    width: 600px;\n  }\n  @media screen and (min-width: 1024px) {\n    width: 768px;\n  }\n"]);
var ListWrapper = exports.ListWrapper = _styledComponents.default.div.withConfig({
  displayName: "style__ListWrapper",
  componentId: "sc-1jwhcu8-15"
})(["\n  margin-bottom: 3rem;\n  max-width: 500px;\n"]);
var Line = exports.Line = _styledComponents.default.span.withConfig({
  displayName: "style__Line",
  componentId: "sc-1jwhcu8-16"
})(["\n  position: absolute;\n  width: ", ";\n  height: 1.5px;\n  left: 0;\n  bottom: 0;\n  text-align: center;\n  background: black;\n  transform: ", "\n    ", "\n    ", ";\n  ::after {\n    content: attr(data-label);\n    line-height: 3;\n  }\n"], function (props) {
  return props.width ? props.width : '100%';
}, function (props) {
  return props.rotate ? "rotate(".concat(props.rotate, ")") : 'rotate(0deg)';
}, function (props) {
  return props.translateX ? "translateX(".concat(props.translateX, ")") : 'translateX(0px)';
}, function (props) {
  return props.translateY ? "translateY(".concat(props.translateY, ")") : 'translateY(0px)';
});
var ImgRelative = exports.ImgRelative = _styledComponents.default.div.withConfig({
  displayName: "style__ImgRelative",
  componentId: "sc-1jwhcu8-17"
})(["\n  position: relative;\n  height: 300px;\n  margin-bottom: 2rem;\n  margin-top: 2rem;\n  @media screen and (min-width: 768px) {\n    height: 500px;\n  }\n  @media screen and (min-width: 1024px) {\n    margin-bottom: 0;\n    height: ", ";\n  }\n"], function (props) {
  return props.small ? '550px' : '700px';
});
var ImgAbs = exports.ImgAbs = _styledComponents.default.div.withConfig({
  displayName: "style__ImgAbs",
  componentId: "sc-1jwhcu8-18"
})(["\n  content: ", ";\n  max-height: 400px;\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  right: 0;\n  bottom: 0;\n  transform: translateX(-50%) translateY(-40%);\n  color: rgba(0, 0, 0, 0.15);\n  z-index: -1;\n  @media screen and (min-width: 768px) {\n    max-height: 500px;\n  }\n  @media screen and (min-width: 1024px) {\n    max-height: 700px;\n    padding: ", ";\n  }\n"], function (props) {
  return "url(".concat(props.imgSrc, ")");
}, function (props) {
  return props.inverted ? '0 80px 0 0' : '0 0 0 80px';
});
var ImgCol = exports.ImgCol = _styledComponents.default.div.withConfig({
  displayName: "style__ImgCol",
  componentId: "sc-1jwhcu8-19"
})(["\n  margin: 0 auto 3rem;\n  @media screen and (min-width: 768px) {\n  }\n  @media screen and (min-width: 1024px) {\n  }\n"]);
var ImgRow = exports.ImgRow = _styledComponents.default.div.withConfig({
  displayName: "style__ImgRow",
  componentId: "sc-1jwhcu8-20"
})(["\n  margin: 5rem 0;\n  @media screen and (min-width: 768px) {\n    max-width: 600px;\n    margin: 3rem auto;\n  }\n  @media screen and (min-width: 1024px) {\n    max-width: 600px;\n    margin: ", ";\n    width: 50%;\n  }\n"], function (props) {
  return props.vertical ? '0 auto 0 3rem' : '0 auto';
});
var ContentCol = exports.ContentCol = _styledComponents.default.div.withConfig({
  displayName: "style__ContentCol",
  componentId: "sc-1jwhcu8-21"
})(["\n  width: auto;\n  @media screen and (min-width: 1024px) {\n    width: 50%;\n  }\n"]);
var FasciaLabel = exports.FasciaLabel = _styledComponents.default.div.withConfig({
  displayName: "style__FasciaLabel",
  componentId: "sc-1jwhcu8-22"
})(["\n  margin: 6rem auto 2rem;\n  text-align: center;\n"]);
var FasciaContent = exports.FasciaContent = _styledComponents.default.div.withConfig({
  displayName: "style__FasciaContent",
  componentId: "sc-1jwhcu8-23"
})(["\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  margin: 0.5rem 0;\n  @media screen and (min-width: 426px) {\n    width: 95%;\n    margin: 0;\n  }\n"]);
var FasciaItem = exports.FasciaItem = _styledComponents.default.a.withConfig({
  displayName: "style__FasciaItem",
  componentId: "sc-1jwhcu8-24"
})(["\n  box-sizing: content-box;\n  display: flex;\n  justify-content: left;\n  flex-flow: row nowrap;\n  align-items: center;\n  height: 5rem;\n  width: 100%;\n  margin: 0 15px;\n  padding: 0.5rem;\n  border: 1px solid rgba(129, 129, 129, 0.5);\n  border-radius: 10px;\n  color: ", ";\n  text-decoration: none;\n  cursor: pointer;\n  :hover {\n    border: 1px solid rgba(129, 129, 129, 1);\n  }\n  @media screen and (min-width: 426px) {\n    white-space: nowrap;\n    height: auto;\n    justify-content: flex-end;\n    flex-flow: column nowrap;\n  }\n"], function (props) {
  return props.theme.color.darkGray;
});
var FasciaWrapper = exports.FasciaWrapper = _styledComponents.default.div.withConfig({
  displayName: "style__FasciaWrapper",
  componentId: "sc-1jwhcu8-25"
})(["\n  display: flex;\n  flex-flow: column nowrap;\n  justify-content: space-around;\n  margin: 0 auto 7rem;\n  @media screen and (min-width: 426px) {\n    flex-flow: row nowrap;\n  }\n"]);
var FasciaTitle = exports.FasciaTitle = _styledComponents.default.div.withConfig({
  displayName: "style__FasciaTitle",
  componentId: "sc-1jwhcu8-26"
})(["\n  margin: 0;\n  font-size: 14px;\n  font-family: ", ";\n  text-transform: uppercase;\n  flex-grow: 3;\n  @media screen and (min-width: 426px) {\n    margin: 0.5rem auto 0;\n    font-size: 15px;\n    flex-grow: initial;\n  }\n"], function (props) {
  return props.theme.font.medium;
});
var FasciaImg = exports.FasciaImg = _styledComponents.default.img.withConfig({
  displayName: "style__FasciaImg",
  componentId: "sc-1jwhcu8-27"
})(["\n  max-width: 60px;\n  max-height: 60px;\n  margin: 0 1rem;\n  object-fit: contain;\n  flex-grow: 2;\n  @media screen and (min-width: 426px) {\n    max-width: 70px;\n    max-height: 70px;\n    margin-left: 0;\n    flex-grow: initial;\n    margin: auto;\n  }\n"]);
var BoxViviesco = exports.BoxViviesco = _styledComponents.default.div.withConfig({
  displayName: "style__BoxViviesco",
  componentId: "sc-1jwhcu8-28"
})(["\n  padding: 40px 20px;\n  position: relative;\n  border-radius: 35px;\n  box-shadow: 0 0 25px 0 ", ";\n  margin-bottom: 30px;\n\n  @media screen and (min-width: 426px) {\n  }\n"], function (props) {
  return props.theme.color.shadowBlue;
});
var BoxViviescoImg = exports.BoxViviescoImg = _styledComponents.default.img.withConfig({
  displayName: "style__BoxViviescoImg",
  componentId: "sc-1jwhcu8-29"
})(["\n  max-width: 200px;\n  max-height: 100px;\n  object-fit: contain;\n  flex-grow: 2;\n"]);
var StyledIconWrapperLink = exports.StyledIconWrapperLink = _styledComponents.default.div.withConfig({
  displayName: "style__StyledIconWrapperLink",
  componentId: "sc-1jwhcu8-30"
})(["\n  margin-bottom: 30px;\n  display: inline;\n  pointer-events: all !important;\n  border: 1px solid 333;\n"]);
var BoxViviescoTxt = exports.BoxViviescoTxt = _styledComponents.default.div.withConfig({
  displayName: "style__BoxViviescoTxt",
  componentId: "sc-1jwhcu8-31"
})(["\n  margin-bottom: 30px;\n"]);
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "style__StyledIconWrapper",
  componentId: "sc-1jwhcu8-32"
})(["\n  width: 15px;\n  margin-left: 15px;\n  display: inline-block;\n"]);
var StyledLinkWrapper = exports.StyledLinkWrapper = _styledComponents.default.div.withConfig({
  displayName: "style__StyledLinkWrapper",
  componentId: "sc-1jwhcu8-33"
})([""]);
var BoxViviescoBorder = exports.BoxViviescoBorder = _styledComponents.default.div.withConfig({
  displayName: "style__BoxViviescoBorder",
  componentId: "sc-1jwhcu8-34"
})(["\n  margin: 0 auto 25px;\n  width: 125px;\n  height: 5px;\n  border-bottom: 1px dotted ", ";\n"], function (props) {
  return props.theme.color.darkBlue;
});