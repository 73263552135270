import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { Recensioni, withWrapper } from '@alkemy/components'

export default compose(
  withPageBuilder({
    niceName: 'Recensioni',
  }),
  withFields([
    {
      prop: 'title',
      label: 'Title',
    },
    {
      prop: 'code',
      label: 'Codice',
    },
  ]),
  withWrapper,
)(Recensioni)
