import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { withPadder, IconsGrid } from '@alkemy/components'

export default compose(
  withPageBuilder({
    niceName: 'Griglia Icone',
  }),
  withFields([
    {
      prop: 'paddingTop',
      label: 'Distanza dal Top',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'paddingBottom',
      label: 'Distanza dal Bottom',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'text',
      label: 'Titolo',
    },
    {
      prop: 'items',
      label: 'Elementi',
      type: 'repeater',
      initialValue: [],
      model: [
        {
          prop: 'icon',
          label: 'Icona',
          type: 'multiple',
          options: [
            'VipAccount:VipAccount',
            'Store:Store',
            'Pointer:Pointer',
            'Ticket:Ticket',
            'CallCenter:CallCenter',
            'MessageReceived:MessageReceived',
            'MessageSent:MessageSent',
            'Accesso:Accesso',
            'AcquistoDiretto:AcquistoDiretto',
            'Cashback:Cashback',
            'CompareOnLine:CompareOnLine',
            'Coupon:Coupon',
          ],
        },
        {
          prop: 'title',
          label: 'Titolo',
        },
        {
          prop: 'text',
          label: 'Descrizione',
          type: 'WYSIWYG',
        },
      ],
    },
  ]),
  withPadder,
)(IconsGrid)
