"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Wrapper = exports.StyledTextWrapper = exports.IconWrapper = exports.IconPdfWrapper = exports.DownloadLink = exports.ButtonLabel = exports.Button = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var Wrapper = exports.Wrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-1atmojx-0"
})(["\n  display: flex;\n  align-items: center;\n  justify-content: ", ";\n  flex-wrap: wrap;\n  flex-direction: ", ";\n  padding: ", ";\n\n  position: relative;\n  ", " {\n    width: 100%;\n  }\n"], function (_ref) {
  var horizontal = _ref.horizontal;
  return horizontal ? 'space-between' : 'center';
}, function (_ref2) {
  var horizontal = _ref2.horizontal;
  return horizontal ? 'row' : 'column';
}, function (_ref3) {
  var horizontal = _ref3.horizontal;
  return horizontal ? 0 : '0 20px';
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var IconWrapper = exports.IconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__IconWrapper",
  componentId: "sc-1atmojx-1"
})(["\n  position: absolute;\n\n  margin-right: 20px;\n  right: 0;\n\n  width: 36px;\n  height: 36px;\n\n  ", " {\n    margin-right: 10px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var Button = exports.Button = _styledComponents.default.a.withConfig({
  displayName: "styles__Button",
  componentId: "sc-1atmojx-2"
})(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-wrap: wrap;\n  flex-direction: row;\n  width: 100%;\n  min-height: 50px;\n\n  position: relative;\n\n  ", " {\n    width: 100%;\n    padding: 0 56px 0 20px;\n  }\n\n  ", " {\n    min-width: 180px;\n    padding: 0 46px 0 10px;\n  }\n\n  background: none;\n  cursor: pointer;\n\n  outline: none;\n  overflow: hidden;\n\n  border-radius: 31.5px;\n  border: solid 2px ", ";\n\n  color: ", ";\n\n  font-family: ", ";\n  font-size: 16px;\n  font-weight: normal;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: normal;\n  letter-spacing: normal;\n  text-align: center;\n  text-decoration: none;\n"], function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.color.blue;
}, function (props) {
  return props.theme.color.blue;
}, function (props) {
  return props.theme.font.regular;
});
var ButtonLabel = exports.ButtonLabel = _styledComponents.default.span.withConfig({
  displayName: "styles__ButtonLabel",
  componentId: "sc-1atmojx-3"
})(["\n  pointer-events: none;\n"]);
var IconPdfWrapper = exports.IconPdfWrapper = _styledComponents.default.a.withConfig({
  displayName: "styles__IconPdfWrapper",
  componentId: "sc-1atmojx-4"
})(["\n  margin: 16px 0;\n  text-decoration: none;\n\n  & > div {\n    width: 65px;\n    height: 65px;\n  }\n\n  & > span {\n    margin-left: 10px;\n    font-family: ", ";\n    color: ", ";\n  }\n\n  ", " {\n    display: ", ";\n  }\n\n  ", "\n\n  ", "\n"], function (props) {
  return props.theme.font.bold;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.phone;
}, function (_ref4) {
  var hasLabel = _ref4.hasLabel;
  return hasLabel ? 'flex' : 'none';
}, function (props) {
  return props.isArchive && (0, _styledComponents.css)(["\n      display: block !important;\n    "]);
}, function (_ref5) {
  var hasLabel = _ref5.hasLabel;
  return hasLabel ? "\n    display: flex;\n    align-items: center;\n  " : null;
});
var StyledTextWrapper = exports.StyledTextWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTextWrapper",
  componentId: "sc-1atmojx-5"
})(["\n  width: 236px;\n  height: 80px;\n  margin-top: 15px;\n  font-family: ", ";\n  color: ", ";\n  font-size: 15px;\n  line-height: 1.3;\n  text-align: center;\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var DownloadLink = exports.DownloadLink = _styledComponents.default.a.withConfig({
  displayName: "styles__DownloadLink",
  componentId: "sc-1atmojx-6"
})(["\n  display: flex;\n  align-items: center;\n  font-family: ", ";\n  color: ", ";\n  text-decoration: none;\n\n  & > *:first-child {\n    ", " {\n      display: none;\n    }\n  }\n\n  ", " {\n    position: relative;\n    top: auto;\n    right: auto;\n    margin: 0;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.magenta;
}, function (props) {
  return props.theme.mediaQueries.phone;
}, IconWrapper);