import React, { Component } from 'react'
import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'
import axios from 'axios'

import ListOfAggregators from '../../frontend/components/ListOfAggregator'

class BuildedListOfAggregators extends Component {
  constructor(props) {
    super(props)

    this.state = {
      offers: [],
    }
  }

  componentDidMount() {
    const tassonomia = this.props.tassonomia
    const url = `${process.env.WP_API}/wp/v2/pages?filter[offers_category]=${tassonomia}&per_page=50&_embed&per_page=100&filter[orderby]=menu_order`
    axios.get(url).then(res => {
      this.setState({
        offers: res.data,
      })
    })
  }

  render() {
    const { offers } = this.state

    const offersProducts = offers.filter(product => {
      return product.template === 'page-product.php'
    })

    return <ListOfAggregators offers={offersProducts} />
  }
}

export default compose(
  withPageBuilder({
    niceName: 'Lista di Aggregatori',
  }),
  withFields([
    {
      prop: 'tassonomia',
      label: 'Tassonomia',
    },
  ]),
)(BuildedListOfAggregators)
