"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitleWrapper = exports.StyledSmallHero = exports.StyledIconWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledSmallHero = exports.StyledSmallHero = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSmallHero",
  componentId: "sc-vtm48m-0"
})(["\n  height: 150px;\n  color: #ffffff;\n  margin-top: ", ";\n  font-size: 30px;\n  display: flex;\n  flex-direction: column;\n  justify-content: initial;\n  align-items: center;\n  overflow: hidden;\n  position: relative;\n  margin-bottom: 100px;\n  ", " {\n    height: ", ";\n  }\n  ", " {\n    height: 220px;\n  }\n  canvas {\n    position: absolute;\n    top: 0;\n    z-index: -1;\n    width: 130%;\n    ", " {\n      width: 200%;\n    }\n  }\n"], function (props) {
  return props.headerHeight + 'px';
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.withBreadcrumbs && '130px';
}, function (props) {
  return props.theme.mediaQueries.ipad;
}, function (props) {
  return props.theme.mediaQueries.ipad;
});
var StyledTitleWrapper = exports.StyledTitleWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitleWrapper",
  componentId: "sc-vtm48m-1"
})(["\n  display: flex;\n  align-items: center;\n  font-size: 20px;\n  color: ", ";\n  font-family: ", ";\n  text-align: center;\n  font-weight: 600;\n  line-height: 28px;\n  text-transform: uppercase;\n  h1 {\n    font-size: 20px;\n  }\n\n  ", " {\n    font-size: 25px;\n    line-height: 38px;\n    h1 {\n      font-size: 25px;\n    }\n  }\n  ", " {\n    font-size: 35px;\n    line-height: 48px;\n    h1 {\n      font-size: 35px;\n    }\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.font.light;
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.ipad;
});
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-vtm48m-2"
})(["\n  width: 60px;\n  height: 60px;\n  margin-right: 20px;\n\n  ", " {\n    width: 80px;\n    height: 80px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.ipad;
});