"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledSeparator = exports.StyledMonth = exports.StyledLocation = exports.StyledGeopin = exports.StyledEventInfo = exports.StyledDay = exports.StyledDate = exports.StyledBlobWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledEventInfo = exports.StyledEventInfo = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledEventInfo",
  componentId: "sc-19or787-0"
})(["\n  position: relative;\n  width: 100%;\n  padding: 85px 0 45px;\n  \n  @media (min-width: 768px) {\n    padding: 65px 0;\n  }\n"]);
var StyledBlobWrapper = exports.StyledBlobWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBlobWrapper",
  componentId: "sc-19or787-1"
})(["\n  width: 90px;\n  height: 90px;\n  position: absolute;\n  left: 0px;\n  top: 50%;\n  transform: translateY(-50%);\n  z-index: -1;\n\n  @media (min-width: 768px) {\n    width: 130px; \n    height: 130px;\n    left: 0px;\n  }\n  \n  @media (min-width: 1440px) {\n    left: 0px;\n  }\n  \n  > canvas {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    z-index: -1;\n  }\n"]);
var StyledDate = exports.StyledDate = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDate",
  componentId: "sc-19or787-2"
})(["\n  width: 44px;\n  height: 66px;\n  position: absolute;\n  text-align: center;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-evenly;\n  align-items: center;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  \n  @media (min-width: 768px) {\n    width: 62px;\n    height: 93px;\n  }\n"]);
var StyledSeparator = exports.StyledSeparator = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSeparator",
  componentId: "sc-19or787-3"
})(["\n  width: 34px;\n  height: 1px;\n  margin: 0 auto;\n  background-color: ", ";\n  \n  @media (min-width: 768px) {\n    width: 50px;\n  }\n"], function (props) {
  return props.theme.color.white;
});
var StyledDay = exports.StyledDay = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDay",
  componentId: "sc-19or787-4"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 34px;\n  line-height: 1;\n  text-transform: uppercase;\n  \n  @media (min-width: 768px) {\n    font-size: 48px;\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.font.regular;
});
var StyledMonth = exports.StyledMonth = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledMonth",
  componentId: "sc-19or787-5"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  text-transform: uppercase;\n  @media (min-width: 768px) {\n    font-size: 22px;\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.font.medium;
});
var StyledLocation = exports.StyledLocation = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledLocation",
  componentId: "sc-19or787-6"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 16px;\n  pointer-events: all!important;\n  display: flex;\n  align-items: flex-end;\n  justify-content: center;\n  text-decoration: none;\n  cursor: pointer;\n  padding-left: 160px;\n  \n  @media (min-width: 768px) {\n    justify-content: left;\n    padding-left: 30px;  \n  }\n  \n  @media (min-width: 1440px) {\n    padding-left: 160px;  \n  }\n  \n  &:visited {\n    color: ", ";\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.magenta;
}, function (props) {
  return props.theme.color.magenta;
});
var StyledGeopin = exports.StyledGeopin = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledGeopin",
  componentId: "sc-19or787-7"
})(["\n  width: 18px;\n  height: 25px;\n  margin-right: 10px;\n  background-image: url('/static/images/store-locator/pin.svg');\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n"]);