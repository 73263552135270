"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WrapperMain = exports.PageTitle = exports.FooterFullBack = exports.BottomTriangle = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var FooterFullBack = exports.FooterFullBack = _styledComponents.default.div.withConfig({
  displayName: "styles__FooterFullBack",
  componentId: "sc-1dzixnq-0"
})(["\n background-image: url(\"", "?width=1440&auto=format\");\n background-size: cover;\n background-repeat: no-repeat;\n background-position: center;\n padding: 0;\n position: relative;\n overflow: hidden;\n\n margin-top: 118px;\n\n canvas {\n  position: absolute;\n  z-index: 0;\n }\n\n ", "{\n    position:relative;\n    width: 100vw;\n    left:50%;\n    transform: translateX(-50%);\n    margin-top:160px;\n }\n\n ", "{\n    margin-top:91px;\n }\n"], function (props) {
  return props.imgUrl;
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.ipad;
});
var WrapperMain = exports.WrapperMain = _styledComponents.default.div.withConfig({
  displayName: "styles__WrapperMain",
  componentId: "sc-1dzixnq-1"
})(["\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  z-index: 1;\n\n  height: calc(100vw / 2.4);\n  & > div {\n    margin-top: ", "px;\n  }\n\n  ", " {\n    height: 320px;\n    & > div {\n      margin-top: ", "px;\n    }\n  }\n  ", " {\n    height: 600px;\n    & > div {\n      margin-top: ", "px;\n    }\n  }\n"], function (props) {
  return props.moreDown ? '39' : '28';
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.moreDown ? '216' : '190';
}, function (props) {
  return props.theme.mediaQueries.desktop;
}, function (props) {
  return props.moreDown ? '445' : '409';
});
var BottomTriangle = exports.BottomTriangle = _styledComponents.default.div.withConfig({
  displayName: "styles__BottomTriangle",
  componentId: "sc-1dzixnq-2"
})(["\n  position: absolute;\n  bottom: -1px;\n  border-right: 100vw solid transparent;\n\n  ", " {\n    //  border-right: 768px solid transparent;\n    border-bottom: 71px solid ", ";\n  }\n\n  ", " {\n    //  border-right: 1440px solid transparent;\n    border-bottom: 136px solid ", ";\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.mediaQueries.desktop;
}, function (props) {
  return props.theme.color.white;
});
var PageTitle = exports.PageTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__PageTitle",
  componentId: "sc-1dzixnq-3"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 30px;\n  font-weight: 400;\n  font-style: normal;\n  font-stretch: normal;\n  letter-spacing: normal;\n  line-height: normal;\n  text-align: center;\n  text-transform: uppercase;\n\n  ", " {\n    font-size: 30px;\n  }\n  h1 {\n    color: ", ";\n    font-family: ", ";\n    font-size: 30px;\n    font-weight: 400;\n    font-style: normal;\n    font-stretch: normal;\n    letter-spacing: normal;\n    line-height: normal;\n    text-align: center;\n    text-transform: uppercase;\n\n    ", " {\n      font-size: 30px;\n    }\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.mediaQueries.desktop;
}, function (props) {
  return props.text_color === 'darkText' ? '#3F5063' : '#ffffff';
}, function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.mediaQueries.desktop;
});