"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledPrevArrow = exports.StyledNextArrow = exports.StyledHeroSlider = exports.StyledDownArrow = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledDownArrow = exports.StyledDownArrow = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDownArrow",
  componentId: "sc-8pmnfl-0"
})(["\n  width: 21px;\n  height: 45px;\n  position: absolute;\n  bottom: 30px;\n  left: 50%;\n  z-index: 3;\n  cursor: pointer;\n  transform: translateX(-50%);\n  @media (max-width: 1023px) {\n    display: none;\n  }\n"]);
var StyledHeroSlider = exports.StyledHeroSlider = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledHeroSlider",
  componentId: "sc-8pmnfl-1"
})(["\n  z-index: 2;\n  box-shadow: 0 0 35px 0 ", ";\n  position: relative;\n  width: 100vw;\n  left: 50%;\n  transform: translateX(-50%);\n  ", " {\n    position: relative;\n    width: 100vw;\n    left: 50%;\n    transform: translateX(-50%);\n\n    overflow: hidden;\n  }\n  @media (min-width: 1920px) {\n    max-width: 1440px;\n  }\n\n  .slick-slider {\n    padding-bottom: 70px;\n\n    ", " {\n      padding-bottom: 0;\n    }\n  }\n\n  .slick-slide {\n    pointer-events: none;\n  }\n\n  .slick-current {\n    pointer-events: all !important;\n  }\n"], function (props) {
  return props.theme.color.shadowBlue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledPrevArrow = exports.StyledPrevArrow = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledPrevArrow",
  componentId: "sc-8pmnfl-2"
})(["\n  width: 90px;\n  height: 90px;\n  position: absolute;\n  -ms-transform: rotate(-90deg); /* IE 9 */\n  transform: rotate(-90deg);\n  top: 105px;\n  left: 6%;\n  z-index: 10;\n  opacity: .75\n  cursor: pointer;\n\n  @media (max-width: 1023px) {\n    display:none;\n  }\n"]);
var StyledNextArrow = exports.StyledNextArrow = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledNextArrow",
  componentId: "sc-8pmnfl-3"
})(["\n  width: 90px;\n  height: 90px;\n  position: absolute;\n  -ms-transform: rotate(90deg); /* IE 9 */\n  transform: rotate(90deg);\n  left: 6%;\n  bottom: 230px;\n  opacity: .75\n  z-index: 10;\n  cursor: pointer;\n  @media (max-width: 1023px) {\n    display:none;\n  }\n\n"]);