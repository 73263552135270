import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'
import FormPmi from '../components/FormPmi'
import { withPadder } from '@alkemy/components'

export default compose(
  withPageBuilder({
    niceName: 'Form PMI',
  }),
  withFields([
    {
      prop: 'paddingLeftRight',
      label: 'Larghezza Componente',
      type: 'multiple',
      options: ['200:Small', '170:Medium', '100:Large', '0:Nessuno'],
    },
    {
      prop: 'paddingTop',
      label: 'Distanza dal Top',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'paddingBottom',
      label: 'Distanza dal Bottom',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'preNote',
      type: 'WYSIWYG',
      label: 'Note prodotto',
    },
    {
      prop: 'Note',
      type: 'WYSIWYG',
      label: 'Informazioni aggiuntive',
    },
    {
      prop: 'legalNote',
      type: 'WYSIWYG',
      label: 'Note Legali',
    },
  ]),
  withPadder,
)(props => <FormPmi {...props} endpoint="/pmiform/" />)
