"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getData = void 0;
var _axios = _interopRequireDefault(require("axios"));
var getData = exports.getData = function getData(url) {
  return (0, _axios.default)({
    method: 'get',
    url: url,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  });
};