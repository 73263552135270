import React from 'react'
import {EditorialPreview} from '@alkemy/components'
import {makeNewsEvents} from '../lib/utils'
import {Contents} from '@alkemy/common'

import axios from 'axios'

class ListOfNewsEventsCards extends React.Component {
  state = {
    newsEventCard: false,
  }

  componentDidMount = async () => {
    const urlgeneralSettings = `${process.env.WP_API}/gse/v1/settings/it`
    const typeUrl = {
      news: `${process.env.WP_API}/wp/v2/news`,
      event: `${process.env.WP_API}/wp/v2/event`,
    }

    let wpEventsData = await axios
      .get(urlgeneralSettings)
      .then(x => x.data)
      .then(x => x.in_home_news_events)

    if (wpEventsData) {
      const newsEventsToShow = await axios.all(
        wpEventsData &&
          wpEventsData.map(({ID, post_type}) =>
            axios.get(typeUrl[post_type] + '/' + ID).then(x => x.data),
          ),
      )

      this.setState({
        newsEventCard: newsEventsToShow.map((item, index) => {
          if (!item) return false
          return makeNewsEvents(item, false, index)
        }),
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.newsEventCard && this.state.newsEventCard.length > 0 && (
          <EditorialPreview
            items={this.state.newsEventCard}
            title="NEWS ED EVENTI"
            appDomain={`${process.env.APP_DOMAIN}/`}
            isNews
          />
        )}
      </React.Fragment>
    )
  }
}

export default ListOfNewsEventsCards
