"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitle = exports.StyledStoreLocatorExtra = exports.StyledServiceInfoWrapper = exports.StyledSearchWrapper = exports.StyledSearchError = exports.StyledMobileModal = exports.StyledListWrapper = exports.StyledInputWrapper = exports.StyledDescription = exports.StyledButtonWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledStoreLocatorExtra = exports.StyledStoreLocatorExtra = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledStoreLocatorExtra",
  componentId: "sc-1mo3ru6-0"
})(["\n  height: 100%;\n  margin: 20px 0;\n  color: ", ";\n  -webkit-font-smoothing: antialiased;\n  position: relative;\n"], function (props) {
  return props.theme.color.darkBlue;
});
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-1mo3ru6-1"
})(["\n  margin: 20px 0;\n  font-family: ", ";\n  font-size: 16px;\n  text-align: center;\n"], function (props) {
  return props.theme.font.medium;
});
var StyledDescription = exports.StyledDescription = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDescription",
  componentId: "sc-1mo3ru6-2"
})(["\n  padding: 0 10px;\n  margin-bottom: 35px;\n  font-family: ", ";\n  font-size: 16px;\n  line-height: 1.5;\n\n  @media (min-width: 768px) {\n    max-width: 350px;\n    margin: 0 auto 50px;\n  }\n\n  @media (min-width: 1280px) {\n    margin-bottom: 35px;\n  }\n"], function (props) {
  return props.theme.font.regular;
});
var StyledListWrapper = exports.StyledListWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledListWrapper",
  componentId: "sc-1mo3ru6-3"
})(["\n  width: auto;\n  display: none;\n\n  @media (min-width: 1280px) {\n    display: block;\n    margin: 0 auto 35px;\n  }\n"]);
var StyledSearchWrapper = exports.StyledSearchWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSearchWrapper",
  componentId: "sc-1mo3ru6-4"
})([""]);
var StyledInputWrapper = exports.StyledInputWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledInputWrapper",
  componentId: "sc-1mo3ru6-5"
})(["\n  width: 280px;\n  margin: 0 auto 60px;\n\n  @media (min-width: 768px) {\n    width: 330px;\n  }\n\n  > input {\n    width: 100%;\n    padding: 25px 6px 14px;\n    -webkit-appearance: none;\n    border: none;\n    border-bottom: 1px solid ", ";\n    font-family: ", ";\n    font-size: 18px;\n    transition: all 0.2s linear 0s;\n    outline: none;\n\n    &::placeholder {\n      color: ", ";\n      transition: color 0.2s linear 0s;\n    }\n\n    &:focus {\n      border-bottom: 1px solid ", ";\n\n      &::placeholder {\n        color: ", ";\n      }\n    }\n  }\n"], function (props) {
  return props.theme.color.gray;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.gray;
}, function (props) {
  return props.theme.color.lightBlue;
}, function (props) {
  return props.theme.color.lightBlue;
});
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-1mo3ru6-6"
})(["\n  width: 240px;\n  margin: 0 auto;\n\n  button {\n    line-height: 42px;\n  }\n"]);
var StyledMobileModal = exports.StyledMobileModal = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledMobileModal",
  componentId: "sc-1mo3ru6-7"
})(["\n  opacity: 0;\n  visibility: hidden;\n  transition: all 0.2s ease-in-out 0s;\n\n  ", "\n"], function (props) {
  return props.isVisible && (0, _styledComponents.css)(["\n      opacity: 1;\n      visibility: visible;\n    "]);
});
var StyledSearchError = exports.StyledSearchError = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSearchError",
  componentId: "sc-1mo3ru6-8"
})(["\n  margin: 0 auto;\n  max-width: 310px;\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  line-height: 1.5;\n  opacity: 0;\n  visibility: hidden;\n  transition: all 0.2s linear 0s;\n\n  ", "\n\n  @media (min-width: 768px) {\n    max-width: 330px;\n  }\n"], function (props) {
  return props.theme.color.magenta;
}, function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.isVisible && (0, _styledComponents.css)(["\n      opacity: 1;\n      visibility: visible;\n    "]);
});
var StyledServiceInfoWrapper = exports.StyledServiceInfoWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledServiceInfoWrapper",
  componentId: "sc-1mo3ru6-9"
})(["\n  padding: 0 10px;\n  margin: 40px auto 45px;\n  font-family: ", ";\n  color: ", ";\n  font-size: 16px;\n  line-height: 1.5;\n  position: relative;\n\n  @media (min-width: 768px) {\n    max-width: 350px;\n    margin: 40px auto 95px;\n    padding: 0 35px;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.magenta;
});