"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WeLLCallYou = exports.Subscribe = exports.StyledTitle = exports.StyledLink = exports.StyledIconWrapper = exports.StyledDescription = exports.StyledButtonMobile = exports.StyledButtonLabel = exports.StyledButton = exports.ServiceNumberWrapper = exports.PriceStrikethrough = exports.MiniIcon = exports.ListItem = exports.LabelPrice = exports.LabelCost = exports.IconWrapper = exports.FullWidthColumn = exports.FulCol = exports.FlexTable = exports.Col6 = exports.Col4 = exports.Col = exports.Card = exports.Block = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var Card = exports.Card = _styledComponents.default.div.withConfig({
  displayName: "styles__Card",
  componentId: "sc-176kdlx-0"
})(["\n  box-shadow: 0 0 14px 0px #5181ba75;\n  width: 100%;\n  padding: 25px;\n  height: auto;\n  align-self: flex-start;\n  margin-top: 60px;\n  border-radius: 36px;\n  font-family: ", ";\n  font-weight: 400;\n  font-size: 16px;\n  color: #3f5063;\n  b,\n  strong {\n    font-family: ", ";\n    font-weight: 400;\n  }\n  @media (max-width: 1000px) {\n    width: 95%;\n  }\n  @media (max-width: 767px) {\n    width: 85%;\n    margin: auto;\n    max-width: 300px;\n    margin-bottom: 60px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.font.bold;
});
var Block = exports.Block = _styledComponents.default.div.withConfig({
  displayName: "styles__Block",
  componentId: "sc-176kdlx-1"
})(["\n  margin: 0px 0 20px 0px;\n  padding: 0;\n  font-family: ", ";\n  font-weight: 400;\n  color: #3f5063;\n  b,\n  strong {\n    font-family: ", ";\n    font-weight: 400;\n  }\n  img {\n    padding: 10px 0;\n  }\n  small {\n    font-size: 10px;\n    display: block;\n    padding-top: 10px;\n    padding-bottom: 0px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.font.bold;
});
var IconWrapper = exports.IconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__IconWrapper",
  componentId: "sc-176kdlx-2"
})(["\n  height: 65px;\n  > div {\n    height: 100%;\n  }\n"]);
var PriceStrikethrough = exports.PriceStrikethrough = _styledComponents.default.div.withConfig({
  displayName: "styles__PriceStrikethrough",
  componentId: "sc-176kdlx-3"
})(["\n  text-decoration: line-through;\n  font-size: 12px;\n  display: block;\n  width: 100%;\n  margin-right: 0px;\n"]);
var FulCol = exports.FulCol = _styledComponents.default.div.withConfig({
  displayName: "styles__FulCol",
  componentId: "sc-176kdlx-4"
})(["\n  flex-basis: 100%;\n  width: 0px;\n  height: 0px;\n  overflow: hidden;\n"]);
var ListItem = exports.ListItem = _styledComponents.default.div.withConfig({
  displayName: "styles__ListItem",
  componentId: "sc-176kdlx-5"
})(["\n  margin-bottom: 20px;\n  width: 100%;\n  text-align: left;\n"]);
var MiniIcon = exports.MiniIcon = _styledComponents.default.div.withConfig({
  displayName: "styles__MiniIcon",
  componentId: "sc-176kdlx-6"
})(["\n  width: 25px;\n  float: left;\n  margin-right: 20px;\n"]);
var FlexTable = exports.FlexTable = _styledComponents.default.div.withConfig({
  displayName: "styles__FlexTable",
  componentId: "sc-176kdlx-7"
})(["\n  display: flex;\n  flex-wrap: wrap;\n  padding: 0;\n  b {\n    font-family: ", ";\n    font-weight: 400;\n  }\n  span {\n    font-family: ", ";\n  }\n"], function (props) {
  return props.theme.font.bold;
}, function (props) {
  return props.theme.font.regular;
});
var LabelCost = exports.LabelCost = _styledComponents.default.div.withConfig({
  displayName: "styles__LabelCost",
  componentId: "sc-176kdlx-8"
})(["\n  display: block;\n  width: 35%;\n  text-align: left;\n  font-size: 14px;\n"]);
var StyledDescription = exports.StyledDescription = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDescription",
  componentId: "sc-176kdlx-9"
})(["\n  min-height: 30px;\n  font-family: ", ";\n  font-size: 14px;\n  color: ", ";\n  text-align: center;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var LabelPrice = exports.LabelPrice = _styledComponents.default.div.withConfig({
  displayName: "styles__LabelPrice",
  componentId: "sc-176kdlx-10"
})(["\n  display: block;\n  width: 65%;\n  text-align: right;\n  margin-bottom: 10px;\n"]);
var Col6 = exports.Col6 = _styledComponents.default.div.withConfig({
  displayName: "styles__Col6",
  componentId: "sc-176kdlx-11"
})(["\n  display: flex;\n  flex: 1 0 60%;\n  padding-bottom: 8px;\n"]);
var Col4 = exports.Col4 = _styledComponents.default.div.withConfig({
  displayName: "styles__Col4",
  componentId: "sc-176kdlx-12"
})(["\n  display: flex;\n  flex: 1 0 40%;\n  padding-bottom: 8px;\n"]);
var Col = exports.Col = _styledComponents.default.div.withConfig({
  displayName: "styles__Col",
  componentId: "sc-176kdlx-13"
})(["\n  display: flex;\n  flex: 1 0 50%;\n  padding-bottom: 8px;\n"]);
var FullWidthColumn = exports.FullWidthColumn = _styledComponents.default.div.withConfig({
  displayName: "styles__FullWidthColumn",
  componentId: "sc-176kdlx-14"
})(["\n  display: flex;\n  flex: 1 0 100%;\n  text-align: right;\n"]);
var ServiceNumberWrapper = exports.ServiceNumberWrapper = _styledComponents.default.span.withConfig({
  displayName: "styles__ServiceNumberWrapper",
  componentId: "sc-176kdlx-15"
})(["\n  display: block;\n  padding: 10px;\n"]);
var Subscribe = exports.Subscribe = _styledComponents.default.div.withConfig({
  displayName: "styles__Subscribe",
  componentId: "sc-176kdlx-16"
})(["\n  cursor: pointer;\n  padding: 10px 20px;\n  width: 80%;\n  margin: auto;\n  font-size: 13px;\n  border-radius: 47px;\n  color: #fff;\n  font-family: ", ";\n  font-weight: 400;\n  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e81c69+0,ff9000+100 */\n  background: #e81c69; /* Old browsers */\n  background: -moz-linear-gradient(\n    left,\n    #e81c69 0%,\n    #ff9000 100%\n  ); /* FF3.6-15 */\n  background: -webkit-linear-gradient(\n    left,\n    #e81c69 0%,\n    #ff9000 100%\n  ); /* Chrome10-25,Safari5.1-6 */\n  background: linear-gradient(\n    to right,\n    #e81c69 0%,\n    #ff9000 100%\n  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */\n  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e81c69', endColorstr='#ff9000',GradientType=1 ); /* IE6-9 */\n  @media (max-width: 767px) {\n    display: block;\n    position: fixed;\n    bottom: 0;\n    width: 50%;\n    right: 0;\n    border-radius: 0;\n    padding: 23px 0;\n    font-size: 16px;\n    z-index: 9999;\n    max-height: 70px;\n  }\n"], function (props) {
  return props.theme.font.bold;
});
var WeLLCallYou = exports.WeLLCallYou = _styledComponents.default.div.withConfig({
  displayName: "styles__WeLLCallYou",
  componentId: "sc-176kdlx-17"
})(["\n  cursor: pointer;\n  padding: 10px 20px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 90%;\n  margin: auto;\n  font-size: 13px;\n  border-radius: 47px;\n  color: #fff;\n  font-family: ", ";\n  font-weight: 400;\n  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e81c69+0,ff9000+100 */\n  background: #6ec18a; /* Old browsers */\n  background: -moz-linear-gradient(\n    left,\n    #6ec18a 0%,\n    #0e89ca 100%\n  ); /* FF3.6-15 */\n  background: -webkit-linear-gradient(\n    left,\n    #6ec18a 0%,\n    #0e89ca 100%\n  ); /* Chrome10-25,Safari5.1-6 */\n  background: linear-gradient(\n    to right,\n    #6ec18a 0%,\n    #0e89ca 100%\n  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */\n  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6EC18A', endColorstr='#0E89CA',GradientType=1 ); /* IE6-9 */\n"], function (props) {
  return props.theme.font.bold;
});
var StyledLink = exports.StyledLink = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledLink",
  componentId: "sc-176kdlx-18"
})(["\n  display: block;\n  font-size: 14px;\n  cursor: pointer;\n  margin-bottom: 10px;\n  text-decoration: underline;\n  color: #e81c69;\n"]);
var StyledButton = exports.StyledButton = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledButton",
  componentId: "sc-176kdlx-19"
})(["\n  display: flex;\n  position: relative;\n  font-family: ", ";\n  font-weight: 400;\n  flex-direction: row;\n  width: 100%;\n  min-width: 240px;\n\n  cursor: pointer;\n  outline: none;\n  border-radius: 31.5px;\n\n  border: solid 2px #204386;\n  color: #204386;\n  font-family: ", ";\n  font-size: 16px;\n  line-height: 38px;\n\n  text-align: center;\n  text-decoration: none;\n\n  padding-left: 10px;\n  padding-right: 10px;\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.font.regular;
});
var StyledButtonMobile = exports.StyledButtonMobile = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledButtonMobile",
  componentId: "sc-176kdlx-20"
})(["\n  cursor: pointer;\n  padding: 10px 20px;\n  text-align: center;\n  text-transform: uppercase;\n  width: 80%;\n  margin: auto;\n  font-size: 13px;\n  border-radius: 47px;\n  color: #fff;\n  font-family: ", ";\n  font-weight: 400;\n  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e81c69+0,ff9000+100 */\n  background: #e81c69; /* Old browsers */\n  background: -moz-linear-gradient(\n    left,\n    #e81c69 0%,\n    #ff9000 100%\n  ); /* FF3.6-15 */\n  background: -webkit-linear-gradient(\n    left,\n    #e81c69 0%,\n    #ff9000 100%\n  ); /* Chrome10-25,Safari5.1-6 */\n  background: linear-gradient(\n    to right,\n    #e81c69 0%,\n    #ff9000 100%\n  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */\n  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e81c69', endColorstr='#ff9000',GradientType=1 ); /* IE6-9 */\n  @media (max-width: 767px) {\n    display: block;\n    position: fixed;\n    bottom: 0;\n    width: ", ";\n    right: 0;\n    border-radius: 0;\n    padding: 23px 0;\n    font-size: 16px;\n    z-index: 9999;\n    max-height: 70px;\n  }\n"], function (props) {
  return props.theme.font.bold;
}, function (props) {
  return props.hasCallMeback ? '50%' : '100%';
});
var StyledButtonLabel = exports.StyledButtonLabel = _styledComponents.default.span.withConfig({
  displayName: "styles__StyledButtonLabel",
  componentId: "sc-176kdlx-21"
})(["\n  flex-grow: 1;\n  text-align: center;\n  pointer-events: none;\n"]);
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-176kdlx-22"
})(["\n  flex-grow: 0;\n  align-self: center;\n  width: 26px;\n  height: 29px;\n"]);
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-176kdlx-23"
})(["\n  font-size: 18px;\n  text-align: center;\n  font-family: ", ";\n  color: #3f5063;\n  font-weight: 400;\n  margin-bottom: 10px;\n"], function (props) {
  return props.theme.font.medium;
});