"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledVivipediaArticleList = exports.StyledTitle = exports.StyledPrevArrow = exports.StyledNextArrow = exports.StyledCta = exports.StyledButtonWrapper = exports.StyledArticleWrapper = exports.StyledArticleItem = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledVivipediaArticleList = exports.StyledVivipediaArticleList = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledVivipediaArticleList",
  componentId: "sc-1fpkixi-0"
})(["\n  margin: 60px auto 80px;\n\n  @media (min-width: 768px) {\n    margin: 80px auto 100px;\n    max-width: 970px;\n  }\n\n  @media (min-width: 1440px) {\n    margin: 80px auto 120px;\n  }\n"]);
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-1fpkixi-1"
})(["\n  margin: 40px 0 0;\n  font-family: ", ";\n  color: ", ";\n  text-align: center;\n  font-size: 18px;\n  line-height: 1.5;\n\n  @media (min-width: 768px) {\n    margin: 50px 0 20px;\n    font-size: 30px;\n    line-height: 1.3;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledArticleWrapper = exports.StyledArticleWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledArticleWrapper",
  componentId: "sc-1fpkixi-2"
})(["\n  padding: 35px 0;\n  .slick-dots {\n    display: flex !important;\n    justify-content: center;\n    align-items: center;\n    bottom: -35px;\n    li {\n      width: 10px;\n      height: 10px;\n      margin: 0 8px;\n      button {\n        width: 10px;\n        height: 10px;\n        padding: 0;\n        border: 1px solid ", ";\n        border-radius: 50%;\n        transition: all 0.2s;\n        &:before {\n          content: none;\n        }\n      }\n      &.slick-active {\n        button {\n          background-color: ", ";\n        }\n      }\n    }\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledArticleItem = exports.StyledArticleItem = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledArticleItem",
  componentId: "sc-1fpkixi-3"
})([""]);
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-1fpkixi-4"
})(["\n  margin: 40px auto 0;\n  display: flex;\n  justify-content: center;\n\n  @media (min-width: 768px) {\n    margin: 10px auto;\n  }\n"]);
var StyledCta = exports.StyledCta = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledCta",
  componentId: "sc-1fpkixi-5"
})(["\n  padding: 18px 48px;\n  text-align: center;\n  font-family: ", ";\n  color: ", ";\n  font-size: 20px;\n  border-radius: 34px;\n  border: 2px solid ", ";\n  text-decoration: none;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n  align-items: center;\n  transition: all 0.2s;\n\n  &:hover {\n    color: ", ";\n    background-color: ", ";\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.blue;
}, function (props) {
  return props.theme.color.blue;
}, function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.color.blue;
});
var StyledPrevArrow = exports.StyledPrevArrow = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledPrevArrow",
  componentId: "sc-1fpkixi-6"
})(["\n  width: 65px;\n  position: absolute;\n  left: -65px;\n  top: 50%;\n  transform: translateY(-50%);\n  z-index: 10;\n  cursor: pointer;\n"]);
var StyledNextArrow = exports.StyledNextArrow = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledNextArrow",
  componentId: "sc-1fpkixi-7"
})(["\n  width: 65px;\n  position: absolute;\n  right: -65px;\n  top: 50%;\n  transform: translateY(-50%);\n  z-index: 10;\n  cursor: pointer;\n"]);