"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledGridItem = exports.StyledGrid = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledGridItem = exports.StyledGridItem = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledGridItem",
  componentId: "sc-7sw3gd-0"
})(["\n  outline: none;\n"]);
var StyledGrid = exports.StyledGrid = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledGrid",
  componentId: "sc-7sw3gd-1"
})(["\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-evenly;  \n\n  ", "\n  \n  ", "\n  \n  ", "\n  \n  ", " {\n    ", "\n    \n    ", "\n    \n    ", "\n    \n    ", "\n"], function (props) {
  return props.layout === 'offersGrid' && (0, _styledComponents.css)(["\n      display: flex;\n      flex-wrap: wrap;\n      justify-content: space-evenly;\n    "]);
}, function (props) {
  return props.layout === 'one-third' && (0, _styledComponents.css)(["\n      display: flex;\n      flex-wrap: wrap;\n      justify-content: space-evenly;\n    "]);
}, function (props) {
  return props.layout === 'fifth' && (0, _styledComponents.css)(["\n      display: flex;\n      flex-direction: row;\n      flex-wrap: wrap;\n      justify-content: space-evenly;\n      align-items: center;\n    "]);
}, StyledGridItem, function (props) {
  return props.layout === 'one-third' && (0, _styledComponents.css)(["\n        float: left;\n        flex-basis: 33%;\n        padding: 35px 0.5rem;\n      "]);
}, function (props) {
  return props.layout === 'half' && (0, _styledComponents.css)(["\n        float: left;\n        flex-basis: 33%;\n        padding: 35px 0.5rem;\n      "]);
}, function (props) {
  return props.layout === 'fifth' && (0, _styledComponents.css)(["\n        padding: 35px 0.5rem;\n        flex-basis: 33%;\n        &:last-child,\n        &:nth-last-child(2) {\n          flex-basis: 50%;\n          @media (min-width: 768px) {\n            flex-basis: initial;\n          }\n        }\n        @media (min-width: 768px) {\n          width: 33%;\n          padding: 35px 0.5rem;\n          flex-basis: initial;\n        }\n        @media (min-width: 1024px) {\n          padding: 35px 25px;\n        }\n      "]);
}, function (props) {
  return props.layout === 'offersGrid' && (0, _styledComponents.css)(["\n        width: 300px;\n        margin: 35px auto;\n        padding: 0 10px;\n        @media (min-width: 768px) {\n          margin: 50px 0;\n          float: left;\n        }\n      "]);
});