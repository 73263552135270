"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledcontentWrapper = exports.StyledImg = exports.StyledHeroImg = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledHeroImg = exports.StyledHeroImg = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledHeroImg",
  componentId: "sc-2l6kwf-0"
})(["\n  width: 100%;\n  height: 145px;\n  margin-top: 118px;\n  padding: 20px 27px 0;\n  background-image: url(", ");\n  background-size: cover;\n  background-position: center bottom;\n  background-repeat: no-repeat;\n\n  @media (min-width: 1024px) {\n    height: 550px;\n    margin-top: 133px;\n    padding: 60px 150px 75px;\n    background-image: url(", ");\n  }\n"], function (props) {
  return props.imgBg[0] + '?width=768&auto=format';
}, function (props) {
  return props.imgBg[2] + '?width=1440&auto=format';
});
var StyledcontentWrapper = exports.StyledcontentWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledcontentWrapper",
  componentId: "sc-2l6kwf-1"
})(["\n  height: 100%;\n  font-family: ", ";\n  display: flex;\n  direction: row;\n  justify-content: space-between;\n  font-size: 24px;\n  color: ", ";\n\n  @media (min-width: 768px) {\n    font-size: 48px;\n  }\n\n  @media (min-width: 1440px) {\n    font-size: 100px;\n  }\n"], function (props) {
  return props.theme.font.bold;
}, function (props) {
  return props.theme.color.white;
});
var StyledImg = exports.StyledImg = _styledComponents.default.img.withConfig({
  displayName: "styles__StyledImg",
  componentId: "sc-2l6kwf-2"
})(["\n  width: 80px;\n  height: 80px;\n  align-self: flex-end;\n\n  @media (min-width: 768px) {\n    width: 177px;\n    height: 177px;\n  }\n\n  @media (min-width: 1440px) {\n    width: 315px;\n    height: 315px;\n  }\n"]);