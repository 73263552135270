"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitle = exports.StyledTextWrapper = exports.StyledText = exports.StyledListItemInner = exports.StyledListItem = exports.StyledList = exports.StyledIcon = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _styles = require("../../styles");
var StyledList = exports.StyledList = (0, _styledComponents.default)(_styles.LayoutContainer).withConfig({
  displayName: "styles__StyledList",
  componentId: "sc-145zj4m-0"
})(["\n  flex-direction: column;\n  gap: 18px;\n  margin: 0 20px;\n\n  ", " {\n    gap: 20px;\n    margin: 0;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledListItem = exports.StyledListItem = (0, _styledComponents.default)(_styles.LayoutItem).withConfig({
  displayName: "styles__StyledListItem",
  componentId: "sc-145zj4m-1"
})(["\n  width: 100%;\n\n  ", " {\n    max-width: 500px;\n  }\n\n  &:hover {\n    ", " {\n      color: ", ";\n    }\n    ", " {\n      color: ", ";\n    }\n\n    ", " {\n      max-width: 570px;\n    }\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, StyledTitle, function (props) {
  return props.theme.color.white;
}, StyledText, function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledListItemInner = exports.StyledListItemInner = (0, _styledComponents.default)(_styles.FlexCenter).withConfig({
  displayName: "styles__StyledListItemInner",
  componentId: "sc-145zj4m-2"
})(["\n  justify-content: flex-start;\n  gap: 20px;\n  height: auto;\n  padding: 32px 24px 32px 20px;\n\n  ", " {\n    min-height: 98px;\n    padding: 22px 22px 22px 24px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledIcon = exports.StyledIcon = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIcon",
  componentId: "sc-145zj4m-3"
})(["\n  width: 40px;\n  height: 40px;\n  flex: 0 0 auto;\n\n  ", ":hover & {\n    ", " {\n      width: 48px;\n      height: 48px;\n    }\n  }\n"], StyledListItem, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledTextWrapper = exports.StyledTextWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTextWrapper",
  componentId: "sc-145zj4m-4"
})(["\n  display: flex;\n  flex-direction: column;\n  max-width: 390px;\n"]);
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-145zj4m-5"
})(["\n  font-size: 16px;\n  line-height: 1.5;\n  text-transform: uppercase;\n"]);
var StyledText = exports.StyledText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledText",
  componentId: "sc-145zj4m-6"
})(["\n  font-family: ", ";\n  font-size: 14px;\n  line-height: 1.5;\n"], function (props) {
  return props.theme.font.regular;
});