"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledSponsorSliderTitle = exports.StyledSponsorSliderItemWrapper = exports.StyledSponsorSliderItem = exports.StyledSponsorSlider = exports.StyledPrevArrow = exports.StyledNextArrow = exports.StyledIconWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledSponsorSlider = exports.StyledSponsorSlider = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSponsorSlider",
  componentId: "sc-tvlxui-0"
})(["\n  width: 100%;\n  margin: 0 auto 60px;\n  position: relative;\n\n  @media (min-width: 768px) {\n    width: 714px;\n    height: 320px;\n    margin: 183px auto;\n    padding: 0 115px;\n    box-shadow: 0px 0px 20px 2px ", ";\n    border-radius: 35px;\n  }\n\n  @media (min-width: 1440px) {\n    width: 1170px;\n    margin: 183px auto;\n    padding: 0 115px;\n    box-shadow: 0px 0px 20px 2px ", ";\n    border-radius: 35px;\n  }\n\n  .slick-slider {\n    @media (min-width: 768px) {\n      width: 100%;\n      height: 100%;\n      display: flex;\n      align-items: center;\n    }\n  }\n\n  .slick-list {\n    @media (min-width: 768px) {\n      width: 100%;\n    }\n  }\n\n  .slick-slide {\n    > div {\n      outline: none;\n    }\n  }\n\n  .slick-dots {\n    bottom: -42px;\n\n    @media (min-width: 768px) {\n      bottom: -60px;\n    }\n\n    @media (min-width: 1440px) {\n      bottom: -52px;\n    }\n\n    li {\n      width: 12px;\n      height: 12px;\n      margin: 0;\n      border-radius: 50%;\n      border: 2px solid ", ";\n      position: relative;\n\n      &:before {\n        content: \"\";\n        width: 100%;\n        height: 100%;\n        position: absolute;\n        top: 50%;\n        left: 50%;\n        background-color: ", ";\n        transition: all 0.35s ease-in-out 0s;\n        transform: translate(-50%, -50%) scale(0);\n        border-radius: 50%;\n      }\n\n      &.slick-active {\n        &:before {\n          transform: translate(-50%, -50%) scale(1);\n        }\n      }\n\n      &:not(:last-child) {\n        margin-right: 5px;\n\n        @media (min-width: 768px) {\n          margin-right: 8px;\n        }\n      }\n    }\n\n    button {\n      display: none;\n    }\n  }\n"], function (props) {
  return props.theme.color.shadowBlue;
}, function (props) {
  return props.theme.color.shadowBlue;
}, function (props) {
  return props.theme.color.magenta;
}, function (props) {
  return props.theme.color.magenta;
});
var StyledSponsorSliderItem = exports.StyledSponsorSliderItem = _styledComponents.default.img.withConfig({
  displayName: "styles__StyledSponsorSliderItem",
  componentId: "sc-tvlxui-1"
})(["\n  width: 175px;\n  align-self: center;\n\n  @media (min-width: 768px) {\n    width: 126px;\n    margin: 0 auto;\n  }\n\n  @media (min-width: 1440px) {\n    width: 150px;\n  }\n"]);
var StyledSponsorSliderItemWrapper = exports.StyledSponsorSliderItemWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSponsorSliderItemWrapper",
  componentId: "sc-tvlxui-2"
})(["\n  height: 320px;\n  width: 230px !important;\n  margin: 42px 10px;\n  border-radius: 35px;\n  box-shadow: 0px 0px 20px 2px ", ";\n  display: inline-flex !important;\n  justify-content: center;\n  align-content: center;\n  outline: none;\n\n  @media (min-width: 768px) {\n    width: 100% !important;\n    height: 100%;\n    margin: 0 auto;\n    box-shadow: none;\n  }\n"], function (props) {
  return props.theme.color.shadowBlue;
});
var StyledPrevArrow = exports.StyledPrevArrow = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledPrevArrow",
  componentId: "sc-tvlxui-3"
})(["\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  left: -75px;\n  cursor: pointer;\n\n  @media (min-width: 768px) {\n    left: -98px;\n  }\n"]);
var StyledNextArrow = exports.StyledNextArrow = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledNextArrow",
  componentId: "sc-tvlxui-4"
})(["\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  right: -75px;\n  cursor: pointer;\n\n  @media (min-width: 768px) {\n    right: -98px;\n  }\n"]);
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-tvlxui-5"
})(["\n  width: 55px;\n  height: 55px;\n\n  path {\n    fill: ", ";\n  }\n"], function (props) {
  return props.theme.color.magenta;
});
var StyledSponsorSliderTitle = exports.StyledSponsorSliderTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSponsorSliderTitle",
  componentId: "sc-tvlxui-6"
})(["\n  text-align: center;\n  font-size: 18px;\n  color: ", ";\n  font-family: ", ";\n\n  @media (min-width: 768px) {\n    position: absolute;\n    left: 50%;\n    transform: translateX(-50%);\n    top: -90px;\n    font-size: 22px;\n  }\n"], function (props) {
  return props.theme.color.magenta;
}, function (props) {
  return props.theme.font.medium;
});