"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledStickyWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledStickyWrapper = exports.StyledStickyWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledStickyWrapper",
  componentId: "sc-u8y2ul-0"
})(["\n  position: relative;\n"]);