import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { BannerPromo, withWrapper } from '@alkemy/components'

export default compose(
  withPageBuilder({
    niceName: 'Promo Banner',
  }),
  withFields([
    {
      prop: 'wrapped',
      type: 'boolean',
      label: 'Contenuto Boxed',
    },
    {
      prop: 'campagna',
      type: 'select',
      label: 'Categoria',
      options: [
        'commerciale:Offerta Commerciale VIVIweb',
        'luce: Offerta Luce Viviweb',
        'gas: Offerta Gas Viviweb',
        'assicurazione:Assicurazione',
        'clientiBusiness:Clienti Business',
        'vas:VAS',
        'riscaldamento:Riscaldamento',
        'fotovoltaico: Fotovoltaico',
        'condizionatori: Condizionatori',
        'wallbox: Wallbox',
        'contatti: Contatti',
        'libera: Categoria Libera',
      ],
      initialValue: 'commerciale',
    },
  ]),
  withWrapper,
)(props => <BannerPromo {...props} api={`${process.env.WP_API}`} />)
