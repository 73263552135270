"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitle = exports.StyledTextWrapper = exports.StyledText = exports.StyledIconsGrid = exports.StyledIconWrapper = exports.StyledGridItem = exports.StyledGrid = exports.StyledContentWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledIconsGrid = exports.StyledIconsGrid = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconsGrid",
  componentId: "sc-5mxh7y-0"
})(["\n  max-width: 310px;\n  margin: 0 auto;\n  color: ", ";\n\n  @media (min-width: 768px) {\n    max-width: 540px;\n  }\n\n  @media (min-width: 1440px) {\n    max-width: 1170px;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
});
var StyledTextWrapper = exports.StyledTextWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTextWrapper",
  componentId: "sc-5mxh7y-1"
})(["\n  margin: 0 auto 70px;\n  font-family: ", ";\n  line-height: 1.3;\n  font-size: 18px;\n  text-align: center;\n\n  @media (min-width: 768px) {\n    font-size: 22px;\n    margin: 0 auto 60px;\n  }\n\n  @media (min-width: 1440px) {\n    width: 39%;\n    margin: 0 auto 100px;\n  }\n"], function (props) {
  return props.theme.font.medium;
});
var StyledGrid = exports.StyledGrid = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledGrid",
  componentId: "sc-5mxh7y-2"
})(["\n  width: 300px;\n  margin: 0 auto;\n\n  @media (min-width: 768px) {\n    width: 100%;\n    display: flex;\n    flex-wrap: wrap;\n    flex-direction: row;\n  }\n"]);
var StyledGridItem = exports.StyledGridItem = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledGridItem",
  componentId: "sc-5mxh7y-3"
})(["\n  margin-bottom: 40px;\n\n  @media (min-width: 768px) {\n    flex-basis: 50%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n  }\n\n  @media (min-width: 1440px) {\n    flex-basis: 25%;\n  }\n"]);
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-5mxh7y-4"
})(["\n  width: 65px;\n  margin-top: 10px;\n  float: left;\n\n  @media (min-width: 768px) {\n    width: 98px;\n    float: none;\n    margin: 0 auto 15px;\n  }\n"]);
var StyledContentWrapper = exports.StyledContentWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContentWrapper",
  componentId: "sc-5mxh7y-5"
})(["\n  padding-left: 85px;\n\n  @media (min-width: 768px) {\n    padding-left: 0;\n    text-align: center;\n  }\n"]);
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-5mxh7y-6"
})(["\n  margin-bottom: 15px;\n  font-family: ", ";\n  font-size: 16px;\n"], function (props) {
  return props.theme.font.bold;
});
var StyledText = exports.StyledText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledText",
  componentId: "sc-5mxh7y-7"
})(["\n  font-family: ", ";\n  font-size: 16px;\n"], function (props) {
  return props.theme.font.regular;
});