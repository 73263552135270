"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Wrapper = exports.StyledTitle = exports.StyledListWrapper = exports.StyledListItem = exports.StyledLink = exports.StyledDescription = exports.StyledButtonWrapper = exports.StyledBoxUtility = exports.StyledBoxOffer = exports.StyledAlternative = exports.Separator = exports.Row = exports.PriceStrikethrough = exports.GreenBadgeStyle = exports.FullWidthColumn = exports.ContainerBlob = exports.Column70 = exports.Column = exports.ColBlock = exports.BoxWord = exports.Block = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var ColBlock = exports.ColBlock = _styledComponents.default.div.withConfig({
  displayName: "styles__ColBlock",
  componentId: "sc-7rbsrw-0"
})(["\n  display: flex;\n  flex: 1 0 50%;\n  margin-bottom: 45px;\n"]);
var Wrapper = exports.Wrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-7rbsrw-1"
})([""]);
var Separator = exports.Separator = _styledComponents.default.div.withConfig({
  displayName: "styles__Separator",
  componentId: "sc-7rbsrw-2"
})(["\n  width: 100%;\n  height: 1px;\n  border-bottom: 2px dotted #3f5063;\n  margin: 30px 0;\n"]);
var Row = exports.Row = _styledComponents.default.div.withConfig({
  displayName: "styles__Row",
  componentId: "sc-7rbsrw-3"
})(["\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n"]);
var Column = exports.Column = _styledComponents.default.div.withConfig({
  displayName: "styles__Column",
  componentId: "sc-7rbsrw-4"
})(["\n  flex: 50%;\n  flex-wrap: wrap;\n"]);
var Column70 = exports.Column70 = _styledComponents.default.div.withConfig({
  displayName: "styles__Column70",
  componentId: "sc-7rbsrw-5"
})(["\n  flex-basis: 70%;\n"]);
var ContainerBlob = exports.ContainerBlob = _styledComponents.default.div.withConfig({
  displayName: "styles__ContainerBlob",
  componentId: "sc-7rbsrw-6"
})(["\n  float: right;\n  margin-top: -56px;\n  margin-right: -56px;\n  @media (max-width: 768px) {\n    margin-top: -36px;\n    margin-right: -36px;\n  }\n"]);
var StyledBoxOffer = exports.StyledBoxOffer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBoxOffer",
  componentId: "sc-7rbsrw-7"
})(["\n  width: 100%;\n  height: 100%;\n  max-height: 368px;\n  min-width: 270px;\n  max-width: 570px;\n  padding: 56px;\n  border-radius: 35px;\n  box-shadow: 0 0 35px 0 ", ";\n  position: relative;\n  margin-bottom: 45px;\n  overflow: hidden;\n  &:nth-child(1) {\n    // margin-right: 30px;\n  }\n  @media (max-width: 768px) {\n    margin: auto;\n    max-width: 340px;\n    max-height: none;\n    padding: 24px;\n  }\n"], function (props) {
  return props.theme.color.shadowBlue;
});
var StyledBoxUtility = exports.StyledBoxUtility = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBoxUtility",
  componentId: "sc-7rbsrw-8"
})(["\n  max-width: 123px;\n  padding: 2px 8px;\n  border-radius: 4px;\n  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);\n  background-color: #fecb1c;\n  font-family: ", ";\n  font-size: 18px;\n  font-weight: 500;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.56;\n  letter-spacing: normal;\n  color: #3f5063;\n"], function (props) {
  return props.theme.font.medium;
});
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-7rbsrw-9"
})(["\n  font-family: ", ";\n  font-size: 30px;\n  color: ", ";\n  line-height: 40px;\n  margin-right: 28px;\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var BoxWord = exports.BoxWord = _styledComponents.default.div.withConfig({
  displayName: "styles__BoxWord",
  componentId: "sc-7rbsrw-10"
})(["\n  min-height: 60px;\n  font-family: ", ";\n  font-size: 22px;\n  color: ", ";\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledDescription = exports.StyledDescription = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDescription",
  componentId: "sc-7rbsrw-11"
})(["\n  min-height: 30px;\n  font-family: ", ";\n  font-size: 20px;\n  color: ", ";\n\n  margin: 30px auto;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var GreenBadgeStyle = exports.GreenBadgeStyle = _styledComponents.default.div.withConfig({
  displayName: "styles__GreenBadgeStyle",
  componentId: "sc-7rbsrw-12"
})(["\n  font-family: ", ";\n  font-weight: 400;\n  color: #6ec18a;\n  text-align: center;\n  span {\n    float: left;\n    padding-top: 5px;\n  }\n"], function (props) {
  return props.theme.font.medium;
});
var StyledListWrapper = exports.StyledListWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledListWrapper",
  componentId: "sc-7rbsrw-13"
})(["\n  font-size: 14px;\n\n  color: ", ";\n  > img {\n    width: 100%;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
});
var StyledListItem = exports.StyledListItem = _styledComponents.default.li.withConfig({
  displayName: "styles__StyledListItem",
  componentId: "sc-7rbsrw-14"
})(["\n  min-height: 12px;\n  font-family: ", ";\n  position: relative;\n  padding-left: 22px;\n  padding-top: 8px;\n  vertical-align: middle;\n\n  &:not(:last-child) {\n    margin-bottom: 13px;\n  }\n"], function (props) {
  return props.theme.font.regular;
});
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-7rbsrw-15"
})(["\n  width: 160px;\n  position: absolute;\n  left: 50%;\n  bottom: -21px;\n  padding: 0 auto;\n  transform: translateX(-50%);\n  > button {\n    line-height: 40px;\n    padding: 0;\n  }\n  > a {\n    padding: 0;\n  }\n"]);
var StyledLink = exports.StyledLink = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledLink",
  componentId: "sc-7rbsrw-16"
})(["\n  display: block;\n  font-size: 14px;\n  font-family: ", ";\n  cursor: pointer;\n  margin-bottom: 10px;\n  text-decoration: underline;\n  color: #e81c69;\n"], function (props) {
  return props.theme.font.regular;
});
var FullWidthColumn = exports.FullWidthColumn = _styledComponents.default.div.withConfig({
  displayName: "styles__FullWidthColumn",
  componentId: "sc-7rbsrw-17"
})(["\n  display: flex;\n  flex: 1 0 100%;\n  text-align: right;\n"]);
var PriceStrikethrough = exports.PriceStrikethrough = _styledComponents.default.div.withConfig({
  displayName: "styles__PriceStrikethrough",
  componentId: "sc-7rbsrw-18"
})(["\n  text-decoration: line-through;\n  font-size: 12px;\n  display: block;\n  width: 100%;\n  margin-right: 0px;\n"]);
var Block = exports.Block = _styledComponents.default.div.withConfig({
  displayName: "styles__Block",
  componentId: "sc-7rbsrw-19"
})(["\n  margin: 0px 0 20px 0px;\n  padding: 0;\n  font-family: ", ";\n  font-weight: 400;\n  color: #3f5063;\n  b,\n  strong {\n    font-family: ", ";\n    font-weight: 400;\n  }\n  img {\n    padding: 10px 0;\n  }\n  small {\n    font-size: 10px;\n    display: block;\n    padding-top: 10px;\n    padding-bottom: 0px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.font.bold;
});
var StyledAlternative = exports.StyledAlternative = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledAlternative",
  componentId: "sc-7rbsrw-20"
})(["\n  width: 75%;\n  position: absolute;\n  top: -30px;\n  left: 50%;\n  transform: translateX(-50%);\n  font-family: ", ";\n  background: ", ";\n  padding: 15px 20px;\n  text-align: center;\n  color: ", ";\n  border-radius: 10px;\n  font-size: 14px;\n  text-transform: uppercase;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.pinkOrange;
}, function (props) {
  return props.theme.color.white;
});