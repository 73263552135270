"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Wrapper = exports.Title = exports.Separator = exports.FornitureWrapper = exports.FornituraName = exports.Fornitura = exports.FlexWrapper = exports.ButtonWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var Wrapper = exports.Wrapper = _styledComponents.default.div.withConfig({
  displayName: "style__Wrapper",
  componentId: "sc-ae7cw1-0"
})(["\n  height: 90px;\n  width: 300px;\n  border-radius: 16px;\n  position: relative;\n  margin-bottom: 30px;\n  margin-top: 0;\n\n  ", " {\n    margin-left: -15px;\n    width: 98.1vw;\n  }\n\n  ", " {\n    max-width: 805px;\n    height: 109px;\n    width: 805px;\n    margin-top: 20px;\n    margin-left: 0;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.ipad;
});
var FornitureWrapper = exports.FornitureWrapper = _styledComponents.default.div.withConfig({
  displayName: "style__FornitureWrapper",
  componentId: "sc-ae7cw1-1"
})(["\n  height: 58px;\n  padding: 0 10px 0 20px;\n  border-radius: 16px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n  align-items: center;\n  box-shadow: inset 0 0 7px 0 rgba(0, 0, 0, 0.29);\n  width: 100%;\n  background-color: #fff;\n  color: ", ";\n  font-family: ", ";\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.5;\n  letter-spacing: normal;\n  gap: 11px;\n\n  ", " {\n    height: 100%;\n    padding: 0 20px;\n    gap: 20px;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var Title = exports.Title = _styledComponents.default.h3.withConfig({
  displayName: "style__Title",
  componentId: "sc-ae7cw1-2"
})(["\n  font-family: ", ";\n  font-size: 20px;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.5;\n  letter-spacing: normal;\n  white-space: nowrap;\n  color: ", ";\n\n  ", " {\n    font-size: 11px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var ButtonWrapper = exports.ButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "style__ButtonWrapper",
  componentId: "sc-ae7cw1-3"
})(["\n  max-width: 180px;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  ", " {\n    position: absolute;\n    bottom: -50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var FlexWrapper = exports.FlexWrapper = _styledComponents.default.div.withConfig({
  displayName: "style__FlexWrapper",
  componentId: "sc-ae7cw1-4"
})(["\n  width: min-content;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n"]);
var FornituraName = exports.FornituraName = _styledComponents.default.span.withConfig({
  displayName: "style__FornituraName",
  componentId: "sc-ae7cw1-5"
})(["\n  text-transform: uppercase;\n  font-size: 14px;\n  font-weight: 600;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: normal;\n  letter-spacing: normal;\n  text-align: center;\n  color: white;\n\n  ", " {\n    display: ", ";\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  return props.hide ? 'none' : 'block';
});
var Fornitura = exports.Fornitura = _styledComponents.default.div.withConfig({
  displayName: "style__Fornitura",
  componentId: "sc-ae7cw1-6"
})(["\n  width: 120px;\n  height: 120px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 5px;\n  transform: scale(1);\n  font-size: 17px;\n  position: relative;\n  border-radius: 18.4px;\n  transition: all 0.7s cubic-bezier(0.2, 0.8, 0.2, 1);\n  cursor: pointer;\n  opacity: 0.4;\n\n  &:hover {\n    opacity: 1;\n  }\n\n  ", "\n\n  ", " {\n    height: 58px;\n    width: 58px;\n    gap: 0;\n    border-radius: 9.2px;\n\n    > ", " {\n      font-family: ", ";\n      font-size: 9px;\n    }\n\n    ", "\n  }\n"], function (props) {
  if (props.active) {
    return (0, _styledComponents.css)(["\n        background: linear-gradient(137deg, #6ec18a 23%, #0e89ca 130%);\n        opacity: 1;\n        transform: scale(1.05);\n\n        > ", " {\n          color: ", ";\n        }\n\n        ", " {\n          padding: 0 4px;\n        }\n      "], FornituraName, function (props) {
      return props.theme.color.white;
    }, function (props) {
      return props.theme.mediaQueries.phone;
    });
  } else {
    return (0, _styledComponents.css)(["\n        > ", " {\n          color: ", ";\n        }\n      "], FornituraName, function (props) {
      return props.theme.color.darkGray;
    });
  }
}, function (props) {
  return props.theme.mediaQueries.phone;
}, FornituraName, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.active && (0, _styledComponents.css)(["\n        height: 64px;\n        width: 64px;\n      "]);
});
var Separator = exports.Separator = _styledComponents.default.div.withConfig({
  displayName: "style__Separator",
  componentId: "sc-ae7cw1-7"
})(["\n  display: inline;\n  width: 1px;\n  height: 80px;\n  opacity: 0.8;\n  border-style: solid;\n  border-width: 0px;\n  border-image-source: linear-gradient(\n    to bottom,\n    rgba(255, 255, 255, 0),\n    #5181ba 48%,\n    rgba(255, 255, 255, 0)\n  );\n  border-image-slice: 1;\n  border-left: 1px solid;\n  opacity: ", ";\n\n  ", " {\n    height: 44px;\n    opacity: ", ";\n  }\n"], function (props) {
  return props.active ? '0' : '0.8';
}, function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  return props.active ? '0' : '0.6';
});