"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Canvas = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var Canvas = exports.Canvas = _styledComponents.default.canvas.withConfig({
  displayName: "styles__Canvas",
  componentId: "sc-ehnbom-0"
})(["\n  display: ", ";\n  overflow: hidden;\n  ", " {\n    position: absolute;\n    display: block;\n  }\n"], function (props) {
  return props.mobileOff ? 'none' : 'block';
}, function (props) {
  return props.theme.mediaQueries.tablet;
});