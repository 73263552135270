import React from 'react'
import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { withWrapper, withPadder, Steps } from '@alkemy/components'

const makeCTA = (cta, trackingId) => {
  if (typeof cta !== 'object' || cta === false) {
    return null
  }

  return {
    className: trackingId ? `js-tracking track-${trackingId}` : null,
    label: cta[0],
    url: cta[1],
    target: cta[2],
    isVisible: true,
  }
}

const SectionSteps = ({ cta, trackingId, steps = [], ...props }) => (
  <Steps
    {...props}
    steps={steps.map(({ text }) => text)}
    cta={makeCTA(cta, trackingId)}
  />
)

export default compose(
  withPageBuilder({
    niceName: 'Section Steps',
  }),
  withFields([
    {
      prop: 'isViviesco',
      type: 'boolean',
      label: 'is viviesco?',
    },{
      prop: 'wrapped',
      type: 'boolean',
      label: 'Contenuto Boxed',
    },
    {
      prop: 'paddingLeftRight',
      label: 'Larghezza Componente',
      type: 'multiple',
      options: ['200:Small', '170:Medium', '100:Large', '0:Nessuno'],
    },
    {
      prop: 'paddingTop',
      label: 'Distanza dal Top',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'paddingBottom',
      label: 'Distanza dal Bottom',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'smallText',
      label: 'Pre-titolo',
    },
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'steps',
      label: 'Steps',
      type: 'repeater',
      initialValue: [],
      model: [
        {
          prop: 'text',
        },
      ],
    },
    {
      prop: 'cta',
      label: 'CTA',
      type: 'cta',
    },
    {
      prop: 'trackingId',
      label: 'Tracking ID',
    },
  ]),
  withPadder,
  withWrapper,
)(SectionSteps)
