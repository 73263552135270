"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TimeText = exports.TimeIconContainer = exports.StyledTimeContainer = exports.StyledContainer = exports.OptionsContainer = exports.Option = exports.MobileSelectContainer = exports.MobileSelect = exports.Label = exports.ArrowDown = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledContainer = exports.StyledContainer = _styledComponents.default.div.withConfig({
  displayName: "style__StyledContainer",
  componentId: "sc-5abwy9-0"
})(["\n  position: relative;\n  width: 71px;\n  height: 71px;\n  opacity: ", ";\n  pointer-events: ", ";\n  &:hover {\n    cursor: not-allowed;\n  }\n  display: none;\n  @media (min-width: 1024px) {\n    display: block;\n  }\n"], function (props) {
  return props.isActive ? 1 : 0.5;
}, function (props) {
  return props.isActive ? 'auto' : 'none';
});
var StyledTimeContainer = exports.StyledTimeContainer = _styledComponents.default.div.withConfig({
  displayName: "style__StyledTimeContainer",
  componentId: "sc-5abwy9-1"
})(["\n  z-index: 101;\n  width: 71px;\n  height: 71px;\n  border: solid 1px #6ec18a;\n  background-color: #ffffff;\n  transition: transform 0.3s ease-in-out;\n  position: absolute;\n  top: 0;\n  &:hover {\n    cursor: pointer;\n    background-color: #e4e7ee;\n  }\n"]);
var Label = exports.Label = _styledComponents.default.p.withConfig({
  displayName: "style__Label",
  componentId: "sc-5abwy9-2"
})(["\n  font-family: ", ";\n  margin: 0;\n  color: #cbcbcb;\n  font-size: 9px;\n  text-align: center;\n  margin-top: 5px;\n\n  @media (max-width: 1024px) {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n  }\n"], function (props) {
  return props.theme.font.regular;
});
var TimeIconContainer = exports.TimeIconContainer = _styledComponents.default.div.withConfig({
  displayName: "style__TimeIconContainer",
  componentId: "sc-5abwy9-3"
})(["\n  display: flex;\n  justify-content: center;\n  margin-top: 10px;\n"]);
var TimeText = exports.TimeText = _styledComponents.default.p.withConfig({
  displayName: "style__TimeText",
  componentId: "sc-5abwy9-4"
})(["\n  font-size: 20px;\n  margin: 0;\n  font-family: ", ";\n  color: ", ";\n  padding-right: 10px;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var ArrowDown = exports.ArrowDown = _styledComponents.default.div.withConfig({
  displayName: "style__ArrowDown",
  componentId: "sc-5abwy9-5"
})(["\n  width: 6px;\n  height: 6px;\n  border: solid #6ec18a;\n  border-width: 0 2px 2px 0;\n  transform: rotate(45deg) translateY(6px);\n"]);
var OptionsContainer = exports.OptionsContainer = _styledComponents.default.div.withConfig({
  displayName: "style__OptionsContainer",
  componentId: "sc-5abwy9-6"
})(["\n  z-index: 100;\n  width: 71px;\n  max-height: 0px;\n  position: absolute;\n  top: 0;\n  border: solid #6ec18a;\n  border-width: 0 1px 1px 1px;\n  transition: max-height 0.3s ease-in-out, transform 0.3s ease-in-out;\n\n  & > div:first-child {\n    background-color: #e4e7ee;\n  }\n"]);
var Option = exports.Option = _styledComponents.default.div.withConfig({
  displayName: "style__Option",
  componentId: "sc-5abwy9-7"
})(["\n  font-family: ", ";\n  width: 100%;\n  height: 71px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: white;\n  &:hover {\n    cursor: pointer;\n    background-color: #e4e7ee;\n  }\n"], function (props) {
  return props.theme.font.regular;
});
var MobileSelectContainer = exports.MobileSelectContainer = _styledComponents.default.div.withConfig({
  displayName: "style__MobileSelectContainer",
  componentId: "sc-5abwy9-8"
})(["\n  position: relative;\n  display: block;\n\n  background-color: #ffffff;\n  @media (min-width: 1024px) {\n    display: none;\n  }\n"]);
var MobileSelect = exports.MobileSelect = _styledComponents.default.select.withConfig({
  displayName: "style__MobileSelect",
  componentId: "sc-5abwy9-9"
})(["\n  width: 68px;\n  height: 68px;\n  -webkit-appearance: none;\n  background-color: white;\n  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAJCAYAAAA/33wPAAAAvklEQVQoFY2QMQqEMBBFv7ERa/EMXkGw11K8QbDXzuN4BHv7QO6ifUgj7v4UAdlVM8Uwf+b9YZJISnlqrfEUZVlinucnBGKaJgghbiHOyLyFKIoCbdvecpyReYvo/Ma2bajrGtbaC58kCdZ1RZ7nl/4/4d5EsO/7nzl7IUtodBexMMagaRrs+06JLMvcNWmaOv2W/C/TMAyD58dxROgSmvxFFMdxoOs6lliWBXEcuzokXRbRoJRyvqqqQvye+QDMDz1D6yuj9wAAAABJRU5ErkJggg==);\n  background-position: right center;\n  background-repeat: no-repeat;\n  padding-right: 1.5em;\n  border: 2px solid rgb(110, 193, 138);\n  background-color: #ffffff;\n  border-radius: 14.7px;\n  text-align-last: center;\n"]);