"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledModalOverlay = exports.StyledModalContainer = exports.StyledHeader = exports.StyledContent = exports.StyledClose = exports.StyledBody = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledModalOverlay = exports.StyledModalOverlay = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledModalOverlay",
  componentId: "sc-198elrm-0"
})(["\n  position: ", ";\n  top: ", ";\n  left: ", ";\n  right: ", ";\n  bottom: ", ";\n\n  z-index: 999999;\n\n  opacity: ", ";\n\n  background-color: rgba(0, 0, 0, 0.8);\n\n  overflow: hidden;\n\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: column;\n"], function (props) {
  return props.open ? 'fixed' : 'fixed';
}, function (props) {
  return props.open ? '0' : '0';
}, function (props) {
  return props.open ? '0' : '0';
}, function (props) {
  return props.open ? '0' : '50%';
}, function (props) {
  return props.open ? '0' : '100%';
}, function (props) {
  return props.open ? '1' : '0';
});
var StyledModalContainer = exports.StyledModalContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledModalContainer",
  componentId: "sc-198elrm-1"
})(["\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  max-width: 970px;\n  max-height: 690px;\n  width: 100%;\n  border-radius: 35px;\n  box-shadow: 0 10px 35px 0 rgba(81, 129, 186, 0.25);\n  background-color: #ffffff;\n  overflow: hidden;\n\n  ", "\n\n  ", " {\n    max-width: 570px;\n\n    width: calc(100% - 20px);\n    height: calc(100% - 40px);\n\n    ", "\n  }\n"], function (props) {
  return props.fromProductTabPage && "\n    max-height: 690px;\n  ";
}, function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  return props.fromProductTabPage && "\n      height: 100%;\n      border-radius: 0;\n      max-width: 100%;\n      max-height: 100%;\n    ";
});
var StyledHeader = exports.StyledHeader = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledHeader",
  componentId: "sc-198elrm-2"
})(["\n  display: flex;\n  justify-content: space-between;\n  padding: 0 15px;\n\n  width: 100%;\n"]);
var StyledClose = exports.StyledClose = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledClose",
  componentId: "sc-198elrm-3"
})(["\n  position: absolute;\n  top: 20px;\n  right: 32px;\n  width: 20px;\n  height: 20px;\n\n  cursor: pointer;\n  z-index: 999;\n\n  > img {\n    width: 100%;\n  }\n\n  ", " {\n    top: 32px;\n    right: 20px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.phone;
});
var StyledContent = exports.StyledContent = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContent",
  componentId: "sc-198elrm-4"
})(["\n  align-self: center;\n  padding: 40px;\n  height: auto;\n  width: 100%;\n  overflow-y: auto;\n  display: flex;\n  justify-content: flex-start;\n  flex-direction: column;\n  -ms-overflow-style: none; /* for Internet Explorer, Edge */\n  scrollbar-width: auto; /* for Firefox */\n  scrollbar-color: #8f54a0 #f1f1f1; /* for Firefox */\n\n  &::-webkit-scrollbar {\n    width: 6px;\n  }\n  /* Track */\n  ::-webkit-scrollbar-track {\n    background: #f1f1f1;\n    height: 90%;\n  }\n\n  /* Handle */\n  ::-webkit-scrollbar-thumb {\n    background: #3f5063;\n    border-radius: 3px;\n    &:hover {\n      background: #555;\n    }\n  }\n\n  ", " {\n    height: 100vh;\n    padding: 0 0 100px;\n  }\n\n  ", "\n"], function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  return props.fromListing && "padding: 0 40px 120px;";
});
var StyledBody = exports.StyledBody = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBody",
  componentId: "sc-198elrm-5"
})(["\n  display: flex;\n  flex-grow: 1;\n  overflow: auto;\n  height: 90vh;\n  max-height: 690px;\n  align-items: flex-start;\n  align-content: flex-start;\n  flex-direction: column;\n\n  ", " {\n    height: auto;\n  }\n\n  ", " {\n    ", "\n  }\n\n  ", " {\n    ", " {\n      ", "\n    }\n  }\n"], function (props) {
  return props.autoheight;
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.fromProductTabPage && "max-height: 690px;";
}, function (props) {
  return props.theme.mediaQueries.phone;
}, StyledContent, function (props) {
  return props.fromProductTabPage && "padding: 15px;";
});