"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledAccordionStoreList = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledAccordionStoreList = exports.StyledAccordionStoreList = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledAccordionStoreList",
  componentId: "sc-169zoxl-0"
})(["\n  height: calc(100vh - 136px);\n  overflow: scroll;\n  padding: 10px;\n"]);