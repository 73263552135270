"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledWrapperSendMail = exports.StyledWrapper = exports.StyledOption = exports.StyledForm = exports.StyledConfirm = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledWrapper = exports.StyledWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledWrapper",
  componentId: "sc-krbmoe-0"
})(["\n  * {\n    box-sizing: border-box;\n  }\n  position: relative;\n  width: 100%;\n  max-width: 300px;\n  margin-left: auto;\n  margin-right: auto;\n\n  ", " {\n    max-width: 500px;\n  }\n\n  .dropdown {\n    position: absolute;\n    background-color: #ffffff;\n    border-bottom-left-radius: 16px;\n    border-bottom-right-radius: 16px;\n    max-height: 200px;\n    height: auto;\n    overflow: hidden;\n    width: 100%;\n    box-shadow: 0 0 50px 0 rgba(81, 129, 186, 0.25);\n    z-index: 100;\n\n    ul {\n      margin: 0;\n      padding: 0;\n      list-style-type: none;\n      overflow-y: auto;\n      width: 100%;\n      height: 100%;\n\n      li {\n        background-color: #fff;\n        &:not(:last-child) {\n          border-bottom: 1px solid #ccc;\n        }\n\n        button {\n          border: none;\n          background: none;\n          outline: inherit;\n          margin: 0;\n          padding: 16px 6px;\n          background: none;\n          width: 100%;\n          text-align: left;\n          cursor: pointer;\n\n          &:hover {\n            background-color: #ccc;\n          }\n        }\n      }\n    }\n  }\n\n  .submitWrapper {\n    height: 60px;\n    margin: 50px auto 0;\n    display: flex;\n    justify-content: center;\n\n    > button {\n      display: block;\n    }\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledWrapperSendMail = exports.StyledWrapperSendMail = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledWrapperSendMail",
  componentId: "sc-krbmoe-1"
})(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  align-content: center;\n  font-family: ", ";\n  color: ", ";\n  * {\n    box-sizing: border-box;\n  }\n  > div:not(.icon) {\n    width: 100%;\n  }\n\n  .icon {\n    width: 56px;\n    height: 56px;\n    margin-bottom: 8px;\n  }\n  .title {\n    font-family: ", ";\n    font-size: 18px;\n    line-height: 1.44;\n    margin-bottom: 8px;\n    text-align: center;\n    ", " {\n      font-size: 30px;\n      line-height: 1.33;\n      margin-bottom: 16px;\n    }\n  }\n  .subtitle {\n    font-size: 14px;\n    line-height: 1.57;\n    margin-bottom: 16px;\n    text-align: center;\n    ", " {\n      font-size: 18px;\n    }\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledForm = exports.StyledForm = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledForm",
  componentId: "sc-krbmoe-2"
})(["\n  display: flex;\n  flex-direction: column;\n  gap: 26px;\n\n  ", " {\n    gap: 30px;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: center;\n  }\n\n  > div {\n    ", " {\n      flex: 0 0 calc(50% - 15px);\n    }\n  }\n\n  .privacy {\n    flex: 0 0 100%;\n    text-align: center;\n    font-size: 12px;\n    line-height: 1.5;\n\n    /* ", " {\n      margin-top: 28px;\n    } */\n\n    a {\n      color: ", ";\n      text-decoration: underline;\n    }\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.color.magenta;
});
var StyledOption = exports.StyledOption = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledOption",
  componentId: "sc-krbmoe-3"
})(["\n  margin-top: 64px;\n  display: flex;\n  align-items: center;\n  flex: 1;\n  flex-direction: column;\n\n  > button,\n  p {\n    margin-bottom: 32px;\n  }\n\n  a {\n    color: ", ";\n    text-decoration: underline;\n  }\n"], function (props) {
  return props.theme.color.magenta;
});
var StyledConfirm = exports.StyledConfirm = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledConfirm",
  componentId: "sc-krbmoe-4"
})(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  .icon {\n    width: 65px;\n    height: 65px;\n  }\n\n  p {\n    margin: 24px 0 48px;\n    font-family: ", ";\n    color: ", ";\n    font-size: 22px;\n    line-height: 1.36;\n    text-align: center;\n\n    ", " {\n      font-size: 30px;\n    }\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});