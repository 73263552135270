"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledSliderMobileContainer = exports.StyledSliderDesktopContainer = exports.StyledPrevArrow = exports.StyledNextArrow = exports.StyledMobileContainer = exports.MobileScrollbarProgress = exports.MobileScrollbarBackground = exports.MobileRadioInputContainer = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledSliderDesktopContainer = exports.StyledSliderDesktopContainer = _styledComponents.default.div.withConfig({
  displayName: "style__StyledSliderDesktopContainer",
  componentId: "sc-97uzp1-0"
})(["\n  display: none;\n  width: 100%;\n  max-width: 840px;\n  margin: 0px auto;\n  @media (min-width: 1024px) {\n    display: block;\n  }\n"]);
var StyledMobileContainer = exports.StyledMobileContainer = _styledComponents.default.div.withConfig({
  displayName: "style__StyledMobileContainer",
  componentId: "sc-97uzp1-1"
})(["\n  display: block;\n  @media (min-width: 1024px) {\n    display: none;\n  }\n"]);
var StyledSliderMobileContainer = exports.StyledSliderMobileContainer = _styledComponents.default.div.withConfig({
  displayName: "style__StyledSliderMobileContainer",
  componentId: "sc-97uzp1-2"
})(["\n  display: flex;\n  flex-wrap: no-wrap;\n  overflow-x: auto;\n  width: 100%;\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n"]);
var MobileRadioInputContainer = exports.MobileRadioInputContainer = _styledComponents.default.div.withConfig({
  displayName: "style__MobileRadioInputContainer",
  componentId: "sc-97uzp1-3"
})(["\n  min-width: 140px;\n  flex-grow: 1;\n"]);
var StyledNextArrow = exports.StyledNextArrow = _styledComponents.default.div.withConfig({
  displayName: "style__StyledNextArrow",
  componentId: "sc-97uzp1-4"
})(["\n  width: 36px;\n  height: 36px;\n  position: absolute;\n  display: block;\n  top: 50%;\n  right: -40px;\n  transform: translateY(-50%) rotate(180deg);\n  cursor: pointer;\n"]);
var StyledPrevArrow = exports.StyledPrevArrow = _styledComponents.default.div.withConfig({
  displayName: "style__StyledPrevArrow",
  componentId: "sc-97uzp1-5"
})(["\n  width: 36px;\n  height: 36px;\n  position: absolute;\n  display: block;\n  top: 50%;\n  left: -40px;\n  transform: translateY(-50%);\n  cursor: pointer;\n"]);
var MobileScrollbarBackground = exports.MobileScrollbarBackground = _styledComponents.default.div.withConfig({
  displayName: "style__MobileScrollbarBackground",
  componentId: "sc-97uzp1-6"
})(["\n  width: 100%;\n  height: 2px;\n  background-color: ", ";\n  position: relative;\n"], function (props) {
  return props.theme.color.darkGray;
});
var MobileScrollbarProgress = exports.MobileScrollbarProgress = _styledComponents.default.div.attrs(function (_ref) {
  var width = _ref.width;
  return {
    style: {
      width: width + '%'
    }
  };
}).withConfig({
  displayName: "style__MobileScrollbarProgress",
  componentId: "sc-97uzp1-7"
})(["\n  position: absolute;\n  top: -2px;\n  bottom: -2px;\n  left: 0;\n  background-color: ", ";\n  border-radius: 3px;\n"], function (props) {
  return props.theme.color.green;
});