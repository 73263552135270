import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'
import { makeProductCard } from '../lib/utils'

import OpenModal from '../components/OpenModal'
import Router from 'next/router'
import styled from 'styled-components'
import axios from 'axios'
import React, { Component } from 'react'

import { ProductCard, withWrapper } from '@alkemy/components'

const MobileOnly = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
  width: 100%;
`

const ProductCardEnhanced = ({ data }) => (
  <React.Fragment>
    <OpenModal>
      {({ handleOpen, openStoreLocatorModal }) => (
        <ProductCard
          opensubScriberModal={handleOpen}
          openStoreLocatorModal={openStoreLocatorModal}
          {...makeProductCard(data)}
        />
      )}
    </OpenModal>
  </React.Fragment>
)

class ProductCardClass extends Component {
  constructor(props) {
    super(props)
    this.state = { data: undefined }
  }
  componentDidMount = async () => {
    const { slugUrl = '/' } = this.props
    const url = `${process.env.WP_API}/msr/v1/slug/it/?slug=${slugUrl}` || {}
    const data = (await axios.get(url).then(x => x.data.acf)) || {}
    this.setState({ data })
  }

  render() {
    const { mobileOnly } = this.props
    const { data } = this.state

    //Gestione redirecting flag nuovo template prodotto
    if (
      data &&
      data.activate_new_template &&
      data.activate_new_template.length > 0 &&
      data.new_product_page_link &&
      data.new_product_page_link !== ''
    ) {
      Router.push(data.new_product_page_link)
    }

    if (mobileOnly && this.state.data) {
      return (
        <MobileOnly>
          <ProductCardEnhanced data={data} />
        </MobileOnly>
      )
    } else if (this.state.data) {
      return <ProductCardEnhanced data={data} />
    }
    return <div />
  }
}

export default compose(
  withPageBuilder(),
  withFields([
    {
      prop: 'mobileOnly',
      type: 'boolean',
      label: 'Visibile solo Mobile',
    },
    {
      prop: 'slugUrl',
      label: 'Slug Di Pagina',
    },
  ]),
  withWrapper,
)(ProductCardClass)
