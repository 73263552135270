"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledWorkWithUsList = exports.StyledTitle = exports.StyledListItem = exports.StyledList = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledWorkWithUsList = exports.StyledWorkWithUsList = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledWorkWithUsList",
  componentId: "sc-t99h5v-0"
})(["\n  max-width: 335px;\n  margin: 0 auto;\n  padding-top: 60px;\n  color: ", ";\n  font-family: ", ";\n\n  @media (min-width: 1024px) {\n    max-width: 830px;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.regular;
});
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-t99h5v-1"
})(["\n  text-align: center;\n  margin-bottom: 15px;\n  font-size: 22px;\n  line-height: 1;\n  font-family: ", ";\n"], function (props) {
  return props.theme.font.medium;
});
var StyledList = exports.StyledList = _styledComponents.default.ul.withConfig({
  displayName: "styles__StyledList",
  componentId: "sc-t99h5v-2"
})(["\n  padding: 0;\n  list-style: none;\n"]);
var StyledListItem = exports.StyledListItem = _styledComponents.default.li.withConfig({
  displayName: "styles__StyledListItem",
  componentId: "sc-t99h5v-3"
})(["\n  padding-left: 30px;\n  font-size: 16px;\n  line-height: 19px;\n  position: relative;\n\n  &:not(:last-child) {\n    margin-bottom: 22px;\n  }\n\n  &:before {\n    content: '';\n    background-color: ", ";\n    width: 8px;\n    height: 8px;\n    border-radius: 50%;\n    position: absolute;\n    left: 0;\n    top: 7px;\n  }\n"], function (props) {
  return props.theme.color.green;
});