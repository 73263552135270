import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { WorkWithUsList, withWrapper } from '@alkemy/components'

export default compose(
  withPageBuilder(),
  withFields([
    {
      prop: 'title',
      label: 'Titolo',
    },
    {
      prop: 'list',
      type: 'repeater',
      label: 'Lista',
      model: [
        {
          prop: 'text',
          label: 'Testo',
        },
      ],
    },
  ]),
  withWrapper,
)(WorkWithUsList)
