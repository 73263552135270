import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'
import { ConfiguratorEntryPoint, withWrapper } from '@alkemy/components'

export default compose(
  withPageBuilder({
    niceName: 'Configurator',
  }),
  withFields([
    {
      prop: 'wrapped',
      type: 'boolean',
      label: 'Contenuto Boxed',
    },
    {
      prop: 'title',
      label: 'Titolo Configuratore',
    },
    {
      prop: 'CTAlabel',
      label: 'CTA label Configurator',
    },
  ]),
  withWrapper,
)(ConfiguratorEntryPoint)
