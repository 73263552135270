"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitle = exports.StyledTextWrapper = exports.StyledPressRelease = exports.StyledDate = exports.StyledButtonWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledPressRelease = exports.StyledPressRelease = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledPressRelease",
  componentId: "sc-1s7iw1o-0"
})(["\n  width: 100%;\n  height: 240px;\n  padding: 10px 20px;\n  position: relative;\n  box-shadow: 0 0 55px 0 ", ";\n\n  @media (min-width: 768px) {\n    height: 230px;\n    border-radius: 35px;\n    padding: 20px 30px;\n  }\n\n  @media (min-width: 1440px) {\n    height: 160px;\n    padding: 30px 40px;\n  }\n"], function (props) {
  return props.theme.color.shadowBlue;
});
var StyledDate = exports.StyledDate = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDate",
  componentId: "sc-1s7iw1o-1"
})(["\n  margin-bottom: 10px;\n  font-family: ", ";\n  color: ", ";\n  font-size: 14px;\n\n  @media (min-width: 1440px) {\n    max-width: 670px;\n    margin-bottom: 15px;\n  }\n"], function (props) {
  return props.theme.font.italic;
}, function (props) {
  return props.theme.color.gray;
});
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-1s7iw1o-2"
})(["\n  font-family: ", ";\n  color: ", ";\n  margin-bottom: 10px;\n\n  @media (min-width: 1440px) {\n    max-width: 670px;\n    margin-bottom: 15px;\n    font-size: 18px;\n  }\n"], function (props) {
  return props.theme.font.bold;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledTextWrapper = exports.StyledTextWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTextWrapper",
  componentId: "sc-1s7iw1o-3"
})(["\n  font-family: ", ";\n  color: ", ";\n  line-height: 1.4;\n\n  @media (min-width: 1440px) {\n    max-width: 670px;\n    font-size: 14px;\n  }\n"], function (props) {
  return props.theme.font.light;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-1s7iw1o-4"
})(["\n  width: 240px;\n  height: 50px;\n  position: absolute;\n  left: 50%;\n  transform: translateX(-50%);\n  bottom: 20px;\n\n  @media (min-width: 1440px) {\n    width: 180px;\n    left: initial;\n    top: 50%;\n    transform: translateY(-50%);\n    right: 40px;\n  }\n\n  > div {\n    > a {\n      &:first-child {\n        display: none;\n      }\n    }\n  }\n"]);