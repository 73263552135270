import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'
import OpenModal from '../components/OpenModal'

import {
  withWrapper,
  Row,
  LaunchBlock,
  SalesPoints,
  withPadder,
} from '@alkemy/components'

const makeLaunchBlock = item => {
  return {
    ...item,
    text: item.text && item.text.replace('<p>', '').replace('</p>', ''),
    list: item.list && item.list.map(i => i.txt),
  }
}

const MultipleLaunchBlockHome = ({ items }) => {
  return (
    <React.Fragment>
      {items && (
        <Row
          salesPointContained={items.find(i => i.typeCompo === 'sales-point')}
        >
          {items &&
            items.map(item => {
              if (item.typeCompo === 'sales-point') {
                return (
                  <OpenModal>
                    {({ openStoreLocatorModal }) => (
                      <SalesPoints
                        {...makeLaunchBlock(item)}
                        handleStoreLocatorVisibilityToggler={
                          openStoreLocatorModal
                        }
                      />
                    )}
                  </OpenModal>
                )
              }
              return <LaunchBlock {...makeLaunchBlock(item)} />
            })}
        </Row>
      )}
    </React.Fragment>
  )
}

export default compose(
  withPageBuilder({
    niceName: 'Griglia Anteprime - Home',
  }),
  withFields([
    {
      prop: 'wrapped',
      type: 'boolean',
      label: 'Contenuto Boxed',
    },
    {
      prop: 'items',
      label: 'Launch Blocks',
      type: 'repeater',
      model: [
        {
          prop: 'typeCompo',
          type: 'multiple',
          options: ['sales-point:Sales Point', 'launch-block:Launch Block'],
          initialValue: 'launch-block',
          label: 'Tipologia',
        },
        {
          prop: 'full',
          type: 'boolean',
          label: 'Blocco FullWith',
        },
        {
          prop: 'title',
          label: 'Titolo',
        },
        {
          prop: 'text',
          type: 'WYSIWYG',
          label: 'Testo',
        },
        {
          prop: 'img',
          label: 'Immagine (Solo LaunchBlock)',
        },
        {
          prop: 'list',
          type: 'repeater',
          model: [
            {
              prop: 'txt',
              label: 'Testo',
            },
          ],
          label: 'Bullet Points (Solo LaunchBlock)',
        },
        {
          prop: 'ctaHref',
          label: 'Href',
        },
        {
          prop: 'ctaLabel',
          label: 'Link Label',
        },
        {
          prop: 'triangleOrientation',
          type: 'multiple',
          options: ['vertical: Verticale', 'horizontal: Orizzontale'],
          default: 'horizontal',
          label: 'Orientamento Triangolo Bianco (Solo LaunchBlock)',
        },
        {
          prop: 'triangleType',
          type: 'multiple',
          options: [
            'bottom-left: Bottom Left',
            'bottom-right: Bottom Right',
            'top-left: Top Left',
            'top-right: Top Right',
          ],
          label: 'Tipologia Triangolo Bianco (Solo LaunchBlock)',
        },
        {
          prop: 'listItems',
          label: 'Lista con Icone',
          type: 'repeater',
          model: [
            {
              prop: 'label',
              label: 'Label',
            },
            {
              prop: 'icon',
              label: 'Icon',
              type: 'multiple',
              options: [
                'NewActivation: Nuova Attivazione',
                'ContractTransfer: Voltura',
                'Subenter: Subentro',
                'SupplierChange: Cambio Fornitore',
                'Assistance: Assistenza',
                'VasHome: Servizi',
              ],
            },
          ],
        },
      ],
    },
  ]),
  withWrapper,
  withPadder,
)(MultipleLaunchBlockHome)
