import React from 'react'
import OpenModal from '../components/OpenModal'
import {ButtonGradient} from '@alkemy/components'

export default props => (
  <OpenModal>
    {({handleOpen, openStoreLocatorModal}) => {
      const checkSelectedBehaviour = props => {
        const {typeOfButton} = props
        switch (typeOfButton) {
          case 'simple':
            return () => {}
          case 'chat':
            return () => window.Chatra.openChat()
          case 'access':
            return () => handleOpen()
          case 'storeLocator':
            return () => openStoreLocatorModal()
          default:
            return () => {}
        }
      }
      const selectedBehaviour = checkSelectedBehaviour(props)

      return (
        <ButtonGradient
          {...props}
          short={true}
          solo={true}
          handleOnClick={selectedBehaviour}
        />
      )
    }}
  </OpenModal>
)
