import React from 'react'
import {wrapDisplayName} from 'recompose'
import ErrorPage from 'next/error'
import CustomErrorPage from '../pages/_error'

const withError = WrappedComponent => {
  class WithError extends React.Component {
    static async getInitialProps(ctx) {
      let props = {}
      if (WrappedComponent.getInitialProps) {
        props = await WrappedComponent.getInitialProps(ctx)
      }

      const {res} = ctx

      if (props.statusCode) {
        if (res) {
          res.statusCode = props.statusCode
        }
      }

      const {statusCode} = res || ''

      return {statusCode, ...props}
    }

    render() {
      const {statusCode} = this.props

      if (statusCode) {
        switch (statusCode) {
          case 404:
            return <CustomErrorPage {...this.props} />
          case 505:
            return <ErrorPage {...this.props} />
          case 500:
            return <ErrorPage {...this.props} />
          default:
            if (statusCode !== 200) {
              return <ErrorPage {...this.props} />
            }
        }
      }
      return <WrappedComponent {...this.props} />
    }
  }

  WithError.displayName = wrapDisplayName(WrappedComponent, 'WithError')

  return WithError
}

export default withError
