"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Wrapper = exports.Popup = exports.PageSelection = exports.HotPoint = exports.Content = exports.BillContainer = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var Wrapper = exports.Wrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-18oiw4t-0"
})(["\n  padding: 0 10px;\n"]);
var Content = exports.Content = _styledComponents.default.div.withConfig({
  displayName: "styles__Content",
  componentId: "sc-18oiw4t-1"
})(["\n  margin-top: 40px;\n"]);
var PageSelection = exports.PageSelection = _styledComponents.default.div.withConfig({
  displayName: "styles__PageSelection",
  componentId: "sc-18oiw4t-2"
})(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  & > * {\n    padding: 0;\n    width: 180px;\n    opacity: 0.4;\n    transition: opacity 0.3s;\n\n    &[disabled] {\n      opacity: 1;\n      pointer-events: none;\n    }\n\n    &:nth-child(n + 2) {\n      margin-top: 30px;\n    }\n\n    &:hover {\n      opacity: 1;\n    }\n  }\n\n  @media (min-width: 1024px) {\n    flex-direction: row;\n\n    & > *:nth-child(n + 2) {\n      margin-top: 0;\n      margin-left: 30px;\n    }\n  }\n"]);
var BillContainer = exports.BillContainer = _styledComponents.default.figure.withConfig({
  displayName: "styles__BillContainer",
  componentId: "sc-18oiw4t-3"
})(["\n  display: ", ";\n  width: calc(100% - 40px);\n  max-width: 604px;\n  padding: 0 10px;\n  overflow: visible;\n  position: relative;\n  margin: 30px auto;\n  border: solid 1px rgba(0, 0, 0, 0.05);\n\n  @media (min-width: 1024px) {\n    margin: 60px auto;\n  }\n\n  img {\n    width: 100%;\n  }\n"], function (_ref) {
  var active = _ref.active;
  return active ? 'block' : 'none';
});
var HotPoint = exports.HotPoint = _styledComponents.default.div.withConfig({
  displayName: "styles__HotPoint",
  componentId: "sc-18oiw4t-4"
})(["\n  width: 40px;\n  height: 40px;\n  position: absolute;\n  ", "\n  z-index: 2;\n  transform: translate(-50%, -50%);\n  background-image: url('/static/images/bolletta/hot-points/", ".png');\n  background-size: cover;\n  opacity: ", ";\n  transition: opacity .3s;\n  cursor: pointer;\n\n  @media (min-width: 1024px) {\n    width: 80px;\n    height: 80px;\n  }\n\n  &:hover {\n    opacity: 1;\n  }\n"], function (_ref2) {
  var position = _ref2.position;
  return Object.keys(position).map(function (key) {
    return "".concat(key, ": ").concat(position[key], ";");
  });
}, function (_ref3) {
  var number = _ref3.number;
  return number;
}, function (_ref4) {
  var active = _ref4.active;
  return active ? 1 : 0.5;
});
var Popup = exports.Popup = _styledComponents.default.div.withConfig({
  displayName: "styles__Popup",
  componentId: "sc-18oiw4t-5"
})(["\n  width: calc(100% + 40px);\n  height: calc(100% + 40px);\n  position: absolute;\n  top: -20px;\n  left: -20px;\n  z-index: 3;\n  visibility: ", ";\n\n  & > div {\n    width: 100% !important;\n    height: 100%;\n\n    & > div {\n      padding: 0 40px;\n\n      &:first-child {\n        padding: 0;\n      }\n\n      &:last-child {\n        display: none;\n      }\n    }\n\n    p {\n      padding: 0;\n    }\n  }\n\n  @media (min-width: 1024px) {\n    width: 400px;\n    height: auto;\n    ", "\n    transform: translate(-50%, 60px);\n\n    & > div {\n      height: auto;\n    }\n  }\n"], function (_ref5) {
  var active = _ref5.active;
  return active ? 'visible' : 'hidden';
}, function (_ref6) {
  var position = _ref6.position;
  return Object.keys(position).map(function (key) {
    return "".concat(key, ": ").concat(position[key], ";");
  });
});