import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'
import { withPadder, VivipediaArticleList } from '@alkemy/components'
import getConfig from 'next/config'
import axios from 'axios'

export default compose(
  withPageBuilder({
    niceName: 'Articoli Vivipedia',
  }),
  withFields([
    {
      prop: 'paddingTop',
      label: 'Distanza dal Top',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'paddingBottom',
      label: 'Distanza dal Bottom',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'articles',
      label: 'Articoli',
      type: 'api',
      settings: {
        max: 6,
      },
      getData: async (query, locale = 'it') => {
        const { publicRuntimeConfig } = getConfig()
        const endpoint = query
          ? `gsr/v1/search/${locale}/?post_type=vivipedia&s=${query}`
          : 'wp/v2/vivipedia'
        const response = await axios.get(
          `${publicRuntimeConfig.wpApi}/${endpoint}`,
        )
        return response.data.map(post => ({
          id: post.ID || post.id,
          permalink: post.permalink || post.link,
          img: post.featured_image || post.better_featured_image.source_url,
          type: post.acf.icon,
          title: post.post_title || post.title.rendered,
          text:
            post.post_excerpt ||
            post.excerpt.rendered.replace('<p>').replace('</p>'),
          cta: { label: 'Leggi' },
        }))
      },
    },
  ]),
  withPadder,
)(VivipediaArticleList)
