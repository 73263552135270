"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTooltipWrapper = exports.StyledTooltipText = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledTooltipWrapper = exports.StyledTooltipWrapper = _styledComponents.default.div.withConfig({
  displayName: "style__StyledTooltipWrapper",
  componentId: "sc-1n52wsz-0"
})(["\n  position: absolute;\n  top: -35px;\n  left: -55px;\n  background-color: ", ";\n  border-radius: 8px;\n  padding: 4px 12px 6px;\n  animation-name: ", ";\n  animation-duration: 0.3s;\n  animation-fill-mode: forwards;\n  animation-timing-function: ease-in-out;\n  z-index: -1;\n  border: none;\n\n  ", " {\n    display: none;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.visible ? show : hide;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var StyledTooltipText = exports.StyledTooltipText = _styledComponents.default.span.withConfig({
  displayName: "style__StyledTooltipText",
  componentId: "sc-1n52wsz-1"
})(["\n  font-family: ", ";\n  font-size: 14px;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.57;\n  letter-spacing: normal;\n  text-align: center;\n  color: ", ";\n  white-space: nowrap;\n"], function (props) {
  return props.theme.font.light;
}, function (props) {
  return props.theme.color.white;
});
var show = (0, _styledComponents.keyframes)(["\n    0%{\n        transform: translateY(8px);\n        opacity: 0;\n    }\n    100% {\n        transform: translateY(0);\n        opacity: 1;\n    }\n"]);
var hide = (0, _styledComponents.keyframes)(["\n    0%{\n        transform: translateY(0px);\n        opacity: 1;\n    }\n    100% {\n        transform: translateY(8px);\n        opacity: 0;\n    }\n"]);