"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SwitchContainer = exports.Switch = exports.Separator = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _ = require("../../");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var SwitchContainer = exports.SwitchContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__SwitchContainer",
  componentId: "sc-1xoujq1-0"
})(["\n  background: #fff;\n  border-radius: 16px;\n  display: flex;\n  justify-content: space-between;\n  box-shadow: inset 0 0 16px 0 rgba(63, 80, 99, 0.16);\n  font-family: ", ";\n  height: 62px;\n  svg {\n    width: 32px;\n    position: relative;\n    top: 7px;\n    margin: 0 auto;\n  }\n\n  button {\n    max-width: 33%;\n    padding-left: 0;\n    padding-right: 0;\n    cursor: pointer;\n  }\n"], function (props) {
  return props.theme.font.regular;
});
var Switch = exports.Switch = _styledComponents.default.button.withConfig({
  displayName: "styles__Switch",
  componentId: "sc-1xoujq1-1"
})(["\n  font-family: ", ";\n  color: ", ";\n  padding: 20px;\n  height: 62px;\n  flex: 1 1 0px;\n  appearance: none;\n  border: none;\n  background: transparent;\n  text-transform: uppercase;\n  font-size: 14px;\n  cursor: pointer;\n  position: relative;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var Separator = exports.Separator = _styledComponents.default.div.withConfig({
  displayName: "styles__Separator",
  componentId: "sc-1xoujq1-2"
})(["\n  height: 50px;\n  width: 1px;\n  position: absolute;\n  top: 5px;\n  border-style: solid;\n  border-width: 0;\n  border-image-source: linear-gradient(\n    to bottom,\n    rgba(255, 255, 255, 0),\n    #5181ba 48%,\n    rgba(255, 255, 255, 0)\n  );\n  border-image-slice: 1;\n  border-left: 1px solid;\n  opacity: ", ";\n\n  ", " {\n    height: 44px;\n    opacity: ", ";\n  }\n"], function (props) {
  return props.active ? '0' : '0.8';
}, function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  return props.active ? '0' : '0.6';
});