"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTriangle = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledTriangle = exports.StyledTriangle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTriangle",
  componentId: "sc-1usdhkd-0"
})(["\n  position: absolute;\n  border-left: 0 solid transparent;\n  border-right: 0 solid transparent;\n  border-top: 0 solid transparent;\n  border-bottom: 0 solid transparent;\n  \n  ", "\n  \n  ", "\n  \n \n"], function (props) {
  return props.orientation === 'horizontal' && (0, _styledComponents.css)(["\n      border-bottom: 30px solid ", ";\n      bottom: 0;\n     \n      ", "\n      \n      ", "\n      \n    "], function (props) {
    return props.theme.color.white;
  }, function (props) {
    return props.type === 'top-right' && (0, _styledComponents.css)(["\n          border-left: 100vw solid transparent;\n          \n          @media (min-width: 768px) {\n            border-left: 365px solid transparent;  \n          }\n          \n          @media (min-width: 1440px) {\n            border-left: 570px solid transparent;  \n          }\n      "]);
  }, function (props) {
    return props.type === 'top-left' && (0, _styledComponents.css)(["\n          border-right: 320px solid transparent;\n          \n          @media (min-width: 768px) {\n            border-right: 365px solid transparent;  \n          }\n          \n          @media (min-width: 1440px) {\n            border-left: 570px solid transparent;  \n          }\n      "]);
  });
}, function (props) {
  return props.orientation === 'vertical' && (0, _styledComponents.css)(["\n      border-right: 100px solid ", ";\n      bottom: 0;\n      \n      @media (min-width: 1440px) {\n        border-right: 125px solid ", ";\n      }\n     \n      ", "\n      \n      ", "\n      \n    "], function (props) {
    return props.theme.color.white;
  }, function (props) {
    return props.theme.color.white;
  }, function (props) {
    return props.type === 'bottom-left' && (0, _styledComponents.css)(["\n          border-top: 0 solid transparent;\n          \n          @media (min-width: 768px) {\n            border-top: 400px solid transparent;  \n          }\n          \n          @media (min-width: 1440px) {\n            border-top: 460px solid transparent;  \n          }\n      "]);
  }, function (props) {
    return props.type === 'top-left' && (0, _styledComponents.css)(["\n          border-right: 320px solid transparent;\n          \n          @media (min-width: 768px) {\n            border-right: 356px solid transparent;  \n          }\n          \n          @media (min-width: 1440px) {\n            border-left: 570px solid transparent;  \n          }\n      "]);
  });
});