import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'
import { StickyConsumer } from '../lib/withStickyProvider'

import { HeroSlider, Hero, Wrapper } from '@alkemy/components'

const makeHeroSlides = props => ({
  ...props,
  items:
    props.items &&
    props.items.map(slide => ({
      ...slide,
      text: slide.text && slide.text.replace('<p>', '').replace('</p>', ''),
      textMobile:
        slide.textMobile &&
        slide.textMobile.replace('<p>', '').replace('</p>', ''),
      titleMobile:
        slide.titleMobile &&
        slide.titleMobile.replace('<p>', '').replace('</p>', ''),
      img: slide && slide.img && slide.img.map(i => i.txt),
    })),
})

export default compose(
  withPageBuilder({
    niceName: 'Hero Slider (Home)',
  }),
  withFields([
    {
      prop: 'items',
      type: 'repeater',
      label: 'Lista Slides',

      model: [
        {
          prop: 'darkMode',
          label: 'Dark Mode',
          type: 'multiple',
          initialValue: '#ffffff',
          options: ['#000000:On', '#ffffff:Off'],
        },
        {
          prop: 'url',
          label: 'URL',
        },
        {
          prop: 'buttonColorSwitcher',
          label: 'Colore Tasto',
          type: 'multiple',
          initialValue: 'pinkOrange',
          options: [
            'pinkOrange:Arancione',
            'greenBlue:Verde',
            'blueViolet:Blue',
            'yellowPink:Giallo',
          ],
        },
        {
          prop: 'title',
          label: 'Occhiello',
        },
        {
          prop: 'subtitle',
          label: 'Titolo',
        },
        {
          prop: 'titleMobile',
          type: 'WYSIWYG',
          label: 'Titolo per Mobile',
        },
        {
          prop: 'text',
          type: 'WYSIWYG',
          label: 'Testo per Desktop',
        },

        {
          prop: 'textMobile',
          type: 'WYSIWYG',
          label: 'Testo per Mobile',
        },
        {
          prop: 'abstract',
          label: 'Abstract',
        },
        {
          prop: 'ctaLabel',
          label: 'Label CTA',
        },
        {
          prop: 'ctaHref',
          label: 'Link',
        },
        {
          prop: 'bgpos',
          label: 'Posizione Background',
          type: 'multiple',
          initialValue: 'bottom',
          options: ['bottom:Bottom', 'top:Top'],
        },
        {
          prop: 'isVideo',
          label: 'Attiva slide video',
          type: 'boolean',
        },
        {
          prop: 'img',
          type: 'repeater',
          label: 'Immagini',
          model: [
            {
              prop: 'txt',
              label: 'Immagine',
            },
          ],
        },
        {
          prop: 'funnelActive',
          label: 'Attiva funnel',
          type: 'boolean',
        },
        {
          prop: 'funnelTitle',
          label: 'Titolo funnel',
        },
        {
          prop: 'funnelCtaLabel',
          label: 'CTA label funnel',
        },
        {
          prop: 'funnelOptions',
          label: 'Funnel options',
          type: 'repeater',
          model: [
            {
              prop: 'icona',
              label: 'Icona',
            },
            {
              prop: 'label',
              label: 'Label',
            },
            {
              prop: 'link',
              label: 'Link',
            },
            {
              prop: 'active',
              label: 'Active',
              type: 'boolean',
            },
          ],
        },
        {
          prop: 'imgBlock3',
          label: 'Promo Card',
        },
        {
          prop: 'imgBlock4',
          label: 'Promo Card Mobile',
        },
        {
          prop: 'imgBlock1',
          label: 'Bollini',
        },
        {
          prop: 'imgBlock2',
          label: 'Card',
        },
      ],
    },
  ]),
)(props => (
  <StickyConsumer>
    {({ isScrolled }) => (
      <Hero {...props} isSticky={isScrolled}>
        <Wrapper>
          <HeroSlider {...makeHeroSlides(props)} />
        </Wrapper>
      </Hero>
    )}
  </StickyConsumer>
))
