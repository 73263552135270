{
  /*
const product1 = require("./products/product-1.json")
const product2 = require("./products/product-2.json")
const product3 = require("./products/product-3.json")
const product4 = require("./products/product-4.json")
const product5 = require("./products/product-5.json")
const product6 = require("./products/product-6.json")
const product7 = require("./products/product-7.json")
const product8 = require("./products/product-8.json")
const product9 = require("./products/product-9.json")
const product10 = require("./products/product-10.json")
const product11 = require("./products/product-11.json")
const product12 = require("./products/product-12.json")
const product13 = require("./products/product-13.json")
const product14 = require("./products/product-14.json")
const product15 = require("./products/product-15.json")
const product16 = require("./products/product-16.json")
const product17 = require("./products/product-17.json")
const product18 = require("./products/product-18.json")

module.exports = {
  "/casa/formula-fisso-elettrico": product0,
  "/casa/prezzo-fisso-gas": product1,
  "/casa/formula-fisso-gas": product2,
  "/casa/basic-gas": product3,
  "/casa/basic-luce": product4,
  "/casa/placet-luce-fissa": product5,
  "/casa/placet-luce-variabile": product6,
  "/casa/placet-gas-fissa": product7,
  "/casa/placet-gas-variabile": product8,
  "/casa/gas-luce-fisso": product9,
  "/casa/energia-per-due": product10,
  "/casa/viviflex": product11,
  "/casa/vivisereno-gas-domestico": product12,
  "/casa/vivisereno-luce-domestico": product13,
  "/casa/vivisereno-lucegas-domestico": product14,
  "/casa/vivisostenibile-gas-domestico": product15,
  "/casa/vivisostenibile-luce-domestico": product16,
  "/casa/vivisostenibile-lucegas-domestico": product17,
  "/casa/vivisicuro": product18,
}
*/
}
