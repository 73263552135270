"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledBlockRadioLabel = exports.StyledBlockRadioButton = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledBlockRadioButton = exports.StyledBlockRadioButton = _styledComponents.default.input.withConfig({
  displayName: "styles__StyledBlockRadioButton",
  componentId: "sc-o1xsds-0"
})(["\n  -webkit-appearance: none;\n  appearance: none;\n  background-color: var(--form-background);\n  margin: 0;\n  font: inherit;\n  color: currentColor;\n  width: 24px;\n  height: 24px;\n  border: solid 2px #3f5063;\n  border-radius: 50%;\n  transform: translateY(-0.075em);\n  display: grid;\n  place-content: center;\n\n  &:before {\n    content: '';\n    width: 16px;\n    height: 16px;\n    border-radius: 50%;\n    transform: scale(0);\n    background-color: #6ec18a;\n  }\n\n  &:checked::before {\n    transform: scale(1);\n  }\n"]);
var StyledBlockRadioLabel = exports.StyledBlockRadioLabel = _styledComponents.default.label.withConfig({
  displayName: "styles__StyledBlockRadioLabel",
  componentId: "sc-o1xsds-1"
})(["\n  font-family: ", ";\n  font-size: 14;\n  text-transform: uppercase;\n  display: grid;\n  grid-template-columns: 10px auto;\n  gap: 20px;\n"], function (props) {
  return props.theme.font.light;
});