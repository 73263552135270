import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'
import { SectionHighlights, withWrapper } from '@alkemy/components'
import * as SVGIcon from '@alkemy/components/lib/components/Icons/SVG'
import * as SVGIconIllustrate from '@alkemy/components/lib/components/Icons/illustrate'
import { Icons } from '@alkemy/components'
import styled from 'styled-components'

const iconOptionsOld = Object.keys(SVGIcon).map(item => `${item}:${item}`)
const iconOptionsNew = Object.keys(SVGIconIllustrate).map(
  item => `${item}:${item}`,
)

const IconWrapper = styled.div`
  width: 35px;
  height: 35px;
  padding: 5px;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 20px;
  flex: 0 35px;
  svg {
    width: 100% !important;
    height: 100% !important;
  }
  > div {
    width: 100% !important;
    height: 100% !important;
    > svg {
      width: 100% !important;
      height: 100% !important;
    }
    > div {
      > svg {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
`
const OptionContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  > span {
    flex: 1;
  }
`

const optionsComponent = props => (
  <OptionContainer style={{ height: '40px' }}>
    <IconWrapper>
      <Icons icon={props.data.value} style={{ width: '100%' }} color="#000" />
    </IconWrapper>
    <span>{props.data.label}</span>
  </OptionContainer>
)

export default compose(
  withPageBuilder({
    niceName: 'Griglia Highlights',
  }),
  withFields([
    {
      prop: 'title',
      label: 'Titolo',
      initialValue: 'Titolo',
    },
    {
      prop: 'wrapped',
      type: 'boolean',
      label: 'Contenuto Boxed',
    },
    {
      prop: 'topIcon',
      type: 'boolean',
      label: 'Icona al Top',
    },
    {
      prop: 'smallIcon',
      type: 'boolean',
      label: 'Icona Piccola',
    },
    {
      prop: 'cols',
      type: 'multiple',
      label: 'Colonne',
      options: ['1:1 Colonna', '2:2 Colonne', '3:3 Colonne', '4:4 Colonne'],
      initialValue: '2',
    },
    {
      prop: 'center',
      type: 'boolean',
      label: 'Testo Centrato',
    },
    {
      prop: 'items',
      type: 'repeater',
      label: 'Elementi',
      model: [
        {
          prop: 'title',
          label: 'Titolo',
        },
        {
          prop: 'text',
          label: 'Testo',
          type: 'WYSIWYG',
        },
        {
          prop: 'icon',
          type: 'select',
          label: 'Icona',
          optionsComponent,
          options: [...iconOptionsNew, ...iconOptionsOld],
          initialValue: 'Gas',
        },
        {
          prop: 'img',
          type: 'multiple',
          label: 'Icona Immagine',
          options: [
            '/static/images/icons/autoritaIstituzioni.png:Autorita Istituzioni',
            '/static/images/icons/azionisti.png:Azionisti',
            '/static/images/icons/banche.png:Banche',
            '/static/images/icons/dipendenti.png:Dipendenti',
            '/static/images/icons/fornitori.png:Fornitori',
            '/static/images/icons/generazioniFuture.png:Generazioni Future',
            '/static/images/icons/bimestrale.svg:Calendario',
            '/static/images/icons/trustworthy.svg:Trust',
            '/static/images/icons/evening.svg:Sera',
            '/static/images/icons/prezzo-bloccato.svg:Prezzo Bloccato',
            '/static/images/icons/monoraria.svg:Monoraria',
            '/static/images/icons/single-bill.svg:Fattura Singola',
            '/static/images/icons/numVerdeIcon.png:NumeroVerde',
            '/static/images/icons/soddisfatti-o-rimborsati.svg:Sodd. o Rimb.',
            '/static/images/icons/bolletta_online.svg:Bolletta Online',
            '/static/images/icons/appDownload.svg:Download App - Line',
            '/static/images/icons/register.svg:Registrati - Line',
            '/static/images/icons/click.svg:Clicca - Line',
            '/static/images/icons/clickSatispay.svg:Satispay - Line',
            '/static/images/icons/logo-wegreen.png:Logo We Green Circle',
            '/static/images/icons/Icona_Scudetto.svg:Icona Scudetto',
            '/static/images/icons/Icona_Mano.svg:Icona Mano',
            '/static/images/icons/Icona_Operatore.svg:Icona Operatore',
            '/static/images/icons/Icona_Posta.svg:Icona Posta',
            '/static/images/icons/Icona_Privacy.svg:Icona Privacy',
            '/static/images/products/icons/prezzovariabile.png:Prezzo Variabile',
            ':Nessuna',
          ],
          initialValue: '/static/images/icons/bimestrale.svg',
        },
      ],
    },
  ]),
  withWrapper,
)(props => <SectionHighlights {...props} nopad={true} />)
