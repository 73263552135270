import React, { Component } from 'react'
import { Contents } from '@alkemy/common'
import { Footer } from '@alkemy/components'

import OpenModal from '../components/OpenModal'

const withFooter = EnhancedComponent =>
  class extends Component {
    constructor(props) {
      super(props)
      this.state = {}
    }

    static async getInitialProps(ctx) {
      let props

      if (typeof EnhancedComponent.getInitialProps === 'function') {
        props = await EnhancedComponent.getInitialProps(ctx)
      }

      return { ...props }
    }

    render() {
      return (
        <React.Fragment>
          <EnhancedComponent {...this.props} />
          <div id="socialEnd" />

          <OpenModal>
            {({ handleOpen, handleClose }) => (
              <Footer
                contents={Contents.footer}
                copyHtml={Contents.footer.copyHtml}
                handleLoginModalOpen={handleOpen}
              />
            )}
          </OpenModal>
        </React.Fragment>
      )
    }
  }

export default withFooter
