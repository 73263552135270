import React, {useState, useEffect} from 'react'
import {compose} from 'recompose'
import axios from 'axios'
import styled from 'styled-components'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import {Wrapper, ArchiveCalendarList} from '@alkemy/components'

async function getCalendars() {
  const url = `${
    process.env.WP_API
  }/wp/v2/documents?filter[documents_categories]=calendar&_fields[]=id&_fields[]=acf&_fields[]=title&_fields[]=documents_categories&per_page=100`
  const data = await axios.get(url).then(x => x.data)

  return data
}

function makeCalendar(calendar) {
  const {title, acf} = calendar
  const label = title ? title.rendered : ''
  const href = acf ? acf.document_attachment : '#'
  return {
    label,
    href,
  }
}

const ArchiveCalendarListPB = () => {
  const [calendarList, setCalendarList] = useState()

  async function fetchWpData() {
    const wpCalendars = await getCalendars()
    setCalendarList(wpCalendars.map(calendarItem => makeCalendar(calendarItem)))
  }

  useEffect(() => {
    fetchWpData()
  }, [])

  return calendarList ? (
    <Wrapper>
      <ArchiveCalendarList
        title="CALENDARIO DELL'AUTOLETTURA"
        ctaLabel="scopri di più"
        calendarList={calendarList}
      />
    </Wrapper>
  ) : (
    <Loader>Caricamento...</Loader>
  )
}

const Loader = styled.div`
  width: 100%;
  margin: 2rem auto;
  text-align: center;
  font-size: 1rem;
  font-family: ${props => props.theme.font.regular};
  color: ${props => props.theme.color.darkBlue};
`

export default compose(withPageBuilder())(ArchiveCalendarListPB)
