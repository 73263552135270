import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import { ViviescoRiquadro, withWrapper } from '@alkemy/components'

export default compose(
  withPageBuilder({
    niceName: 'Viviesco - Riquardo',
  }),
  withFields([
    {
      prop: 'Claim',
      label: 'Testo introduttivo banner VIVIesco',
    },
    {
      prop: 'TestoBox',
      label: 'Testo Box Viviesco',
      type: 'WYSIWYG',
    },
    {
      prop: 'TestoBoxDetails',
      label: 'Testo Box Dettaglio',
      type: 'WYSIWYG',
    },
    {
      prop: 'LogoVivi',
      label: 'Logo Box Viviesco',
    },
    {
      prop: 'TextLinkVivi',
      label: 'Testo Link VIVIesco',
    },
    {
      prop: 'LinkVivi',
      label: 'Link VIVIesco',
    },
  ]),
  withWrapper,
)(ViviescoRiquadro)
