import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import {
  CardSlider,
  withWrapper,
} from '@alkemy/components'

export default compose(
  withPageBuilder({
    niceName: 'CardSlider',
  }),
  withFields([{
    prop: 'title',
    label: 'Titolo',
  }, {
    prop: 'list',
    label: 'Cards',
    type: 'repeater',
    initialValue: [],
    model: [
      {
        prop: 'img',
        label: 'Image',
      }, {
        prop: 'title',
        label: 'Titolo',
      }, {
        prop: 'text',
        label: 'Testo',
      },
    ],
  }]),
  withWrapper,
)(CardSlider)
