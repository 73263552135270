"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Contents = void 0;
var _footer = _interopRequireDefault(require("../mock/it/footer.json"));
var _footerNoPre = _interopRequireDefault(require("../mock/it/footerNoPre.json"));
var _home = _interopRequireDefault(require("../mock/it/mainmenu/home.json"));
var _contatti = _interopRequireDefault(require("../mock/it/mainmenu/contatti.json"));
var _chiSiamo = _interopRequireDefault(require("../mock/it/mainmenu/chiSiamo.json"));
var _progetti = _interopRequireDefault(require("../mock/it/mainmenu/progetti.json"));
var _news = _interopRequireDefault(require("../mock/it/mainmenu/news.json"));
var _eventi = _interopRequireDefault(require("../mock/it/mainmenu/eventi.json"));
var _assistenzaLanding = _interopRequireDefault(require("../mock/it/mainmenu/assistenzaLanding.json"));
var _home2 = _interopRequireDefault(require("../mock/it/home.json"));
var _submenu = _interopRequireDefault(require("../mock/it/casa/submenu.json"));
var _content = _interopRequireDefault(require("../mock/it/casa/content.json"));
var _form = _interopRequireDefault(require("../mock/it/casa/form.json"));
var _offerte = _interopRequireDefault(require("../mock/it/casa/offerte.json"));
var _products = _interopRequireDefault(require("../mock/it/casa/products"));
var _offersModals = _interopRequireDefault(require("../mock/it/casa/offersModals.json"));
var _submenu2 = _interopRequireDefault(require("../mock/it/business/submenu.json"));
var _content2 = _interopRequireDefault(require("../mock/it/business/content.json"));
var _form2 = _interopRequireDefault(require("../mock/it/business/form.json"));
var _offerte2 = _interopRequireDefault(require("../mock/it/business/offerte.json"));
var _products2 = _interopRequireDefault(require("../mock/it/business/products"));
var _offersModals2 = _interopRequireDefault(require("../mock/it/business/offersModals.json"));
var _submenu3 = _interopRequireDefault(require("../mock/it/assistenza-casa/submenu.json"));
var _content3 = _interopRequireDefault(require("../mock/it/assistenza-casa/content.json"));
var _submenu4 = _interopRequireDefault(require("../mock/it/assistenza-PMI/submenu.json"));
var _content4 = _interopRequireDefault(require("../mock/it/assistenza-PMI/content.json"));
var _submenu5 = _interopRequireDefault(require("../mock/it/assistenza-grandiAziende/submenu.json"));
var _content5 = _interopRequireDefault(require("../mock/it/assistenza-grandiAziende/content.json"));
var _submenu6 = _interopRequireDefault(require("../mock/it/vivigas-per-te-casa/submenu.json"));
var _content6 = _interopRequireDefault(require("../mock/it/vivigas-per-te-casa/content.json"));
var _submenu7 = _interopRequireDefault(require("../mock/it/vivigas-per-te-business/submenu.json"));
var _content7 = _interopRequireDefault(require("../mock/it/vivigas-per-te-business/content.json"));
var _submenu8 = _interopRequireDefault(require("../mock/it/vivipedia/submenu.json"));
var _submenu9 = _interopRequireDefault(require("../mock/it/viviesco/submenu.json"));
var _landingAssistenza = _interopRequireDefault(require("../mock/it/landing-assistenza.json"));
var _landingBusiness = _interopRequireDefault(require("../mock/it/landing-business.json"));
var _paginaProdotto = _interopRequireDefault(require("../mock/it/pagina-prodotto.json"));
var _landingCasaSlider = _interopRequireDefault(require("../mock/it/landing-casa-slider.json"));
var _prodottoBusinessSlider = _interopRequireDefault(require("../mock/it/prodotto-business-slider.json"));
var _generic = _interopRequireDefault(require("../mock/it/generic"));
var _paginaTest = _interopRequireDefault(require("../mock/it/pagina-test.json"));
var _paginaContatti = _interopRequireDefault(require("../mock/it/pagina-contatti.json"));
var _paginaChisiamo = _interopRequireDefault(require("../mock/it/pagina-chisiamo.json"));
var _paginaGrandiaziende = _interopRequireDefault(require("../mock/it/pagina-grandiaziende.json"));
var _paginaReseller = _interopRequireDefault(require("../mock/it/pagina-reseller.json"));
var _paginaPuntivendita = _interopRequireDefault(require("../mock/it/pagina-puntivendita.json"));
var _paginaNormativa = _interopRequireDefault(require("../mock/it/pagina-normativa.json"));
var _tutteLeOfferte = _interopRequireDefault(require("../mock/it/tutte-le-offerte.json"));
var _tvtbSlider = _interopRequireDefault(require("../mock/it/tvtb-slider.json"));
var _tvtb = _interopRequireDefault(require("../mock/it/tvtb.json"));
var _soddisfattiRimborsati = _interopRequireDefault(require("../mock/it/soddisfatti-rimborsati.json"));
var _soddisfattiRimborsati2 = _interopRequireDefault(require("../mock/it/soddisfatti-rimborsati-2019.json"));
var _paginaViviarancione = _interopRequireDefault(require("../mock/it/pagina-viviarancione.json"));
var _paginaConcorso = _interopRequireDefault(require("../mock/it/pagina-concorso.json"));
var _concorsoSifaintre = _interopRequireDefault(require("../mock/it/concorso-sifaintre.json"));
var _paginaConcorsoAncoratipremia = _interopRequireDefault(require("../mock/it/pagina-concorso-ancoratipremia.json"));
var _paginaConcorsoLampada = _interopRequireDefault(require("../mock/it/pagina-concorso-lampada.json"));
var _paginaChiSiamo = _interopRequireDefault(require("../mock/it/pagina-chi-siamo.json"));
var _paginaViviesco = _interopRequireDefault(require("../mock/it/pagina-viviesco.json"));
var _paginaChiSiamoNew = _interopRequireDefault(require("../mock/it/pagina-chi-siamo-new.json"));
var _paginaModulisticaNew = _interopRequireDefault(require("../mock/it/pagina-modulistica-new.json"));
var _seo = _interopRequireDefault(require("../mock/it/seo/seo"));
var _cookies = _interopRequireDefault(require("../mock/it/cookies.json"));
var _storesDetail = _interopRequireDefault(require("../mock/it/store-locator/stores-detail"));
var _info = _interopRequireDefault(require("../mock/it/store-locator/info.json"));
var _login = _interopRequireDefault(require("../mock/it/login/login.json"));
var _newsDetail = _interopRequireDefault(require("../mock/it/news/news-detail"));
var _eventsDetail = _interopRequireDefault(require("../mock/it/events/events-detail"));
var _news2 = _interopRequireDefault(require("../mock/it/news/news.json"));
var _events = _interopRequireDefault(require("../mock/it/events/events.json"));
var _paginaNews = _interopRequireDefault(require("../mock/it/pagina-news.json"));
var _paginaEventi = _interopRequireDefault(require("../mock/it/pagina-eventi.json"));
var _paginaLandingProgetti = _interopRequireDefault(require("../mock/it/pagina-landing-progetti.json"));
var _projectDetail = _interopRequireDefault(require("../mock/it/project/project-detail"));
var _content8 = _interopRequireDefault(require("../mock/it/punti-vendita/content.json"));
var _stores = _interopRequireDefault(require("../mock/it/punti-vendita/stores.json"));
var _stores2 = _interopRequireDefault(require("../mock/it/editorial/stores.js"));
var _index = _interopRequireDefault(require("../mock/it/editorial/index.json"));
var _paginaVivipedia = _interopRequireDefault(require("../mock/it/pagina-vivipedia"));
var _category = _interopRequireDefault(require("../mock/it/vivipedia/category"));
var _articleDetails = _interopRequireDefault(require("../mock/it/vivipedia/article-details"));
var _paginaTvtb = _interopRequireDefault(require("../mock/it/pagina-tvtb"));
var _paginaComunicatiStampa = _interopRequireDefault(require("../mock/it/pagina-comunicati-stampa"));
var _pressReleases = _interopRequireDefault(require("../mock/it/press-releases/press-releases"));
var _pagina = _interopRequireDefault(require("../mock/it/pagina-404"));
var _paginaModulistica = _interopRequireDefault(require("../mock/it/pagina-modulistica"));
var _archiveList = _interopRequireDefault(require("../mock/it/archive/archive-list"));
var _calendar = _interopRequireDefault(require("../mock/it/calendar/calendar"));
var mainMenu = {
  home: _home.default,
  contatti: _contatti.default,
  chiSiamo: _chiSiamo.default,
  progetti: _progetti.default,
  news: _news.default,
  eventi: _eventi.default,
  landingPageAssistenza: _assistenzaLanding.default
};
var casa = {
  submenu: _submenu.default,
  form: _form.default,
  offerte: _offerte.default,
  content: _content.default,
  products: _products.default,
  offersModals: _offersModals.default
};
var business = {
  submenu: _submenu2.default,
  form: _form2.default,
  offerte: _offerte2.default,
  content: _content2.default,
  products: _products2.default,
  offersModals: _offersModals2.default
};
var assistenzaCasa = {
  submenu: _submenu3.default,
  content: _content3.default
};
var vivigasPerTeCasa = {
  submenu: _submenu6.default,
  content: _content6.default
};
var vivigasPerTeBusiness = {
  submenu: _submenu7.default,
  content: _content7.default
};
var vivipedia = {
  submenu: _submenu8.default
};
var viviesco = {
  submenu: _submenu9.default
};
var assistenzaPMI = {
  submenu: _submenu4.default,
  content: _content4.default
};
var assistenzaGrandiAziende = {
  submenu: _submenu5.default,
  content: _content5.default
};
var puntiVendita = {
  general: _content8.default,
  stores: _stores.default
};
var editorialPreview = {
  puntiVendita: _stores2.default,
  index: _index.default
};
var Contents = exports.Contents = {
  footer: _footer.default,
  footerNoPre: _footerNoPre.default,
  mainMenu: mainMenu,
  home: _home2.default,
  casa: casa,
  business: business,
  generic: _generic.default,
  landingAssistenza: _landingAssistenza.default,
  landingBusiness: _landingBusiness.default,
  paginaProdotto: _paginaProdotto.default,
  assistenzaCasa: assistenzaCasa,
  landingCasaSlider: _landingCasaSlider.default,
  paginaTest: _paginaTest.default,
  paginaContatti: _paginaContatti.default,
  paginaChisiamo: _paginaChisiamo.default,
  paginaGrandiAziende: _paginaGrandiaziende.default,
  paginaReseller: _paginaReseller.default,
  paginaPuntivendita: _paginaPuntivendita.default,
  paginaOfferteAll: _tutteLeOfferte.default,
  tvtbSlider: _tvtbSlider.default,
  tvtb: _tvtb.default,
  vivigasPerTeCasa: vivigasPerTeCasa,
  vivigasPerTeBusiness: vivigasPerTeBusiness,
  vivipedia: vivipedia,
  viviesco: viviesco,
  soddisfattiRimborsati: _soddisfattiRimborsati.default,
  assistenzaPMI: assistenzaPMI,
  assistenzaGrandiAziende: assistenzaGrandiAziende,
  paginaNormativa: _paginaNormativa.default,
  seo: _seo.default,
  cookies: _cookies.default,
  viviarancione: _paginaViviarancione.default,
  prodottoBusinessSlider: _prodottoBusinessSlider.default,
  concorso: _paginaConcorso.default,
  soddisfattiRimborsati2019: _soddisfattiRimborsati2.default,
  concorsoSifaintre: _concorsoSifaintre.default,
  concorsoAncoratipremia: _paginaConcorsoAncoratipremia.default,
  concorsoLampada: _paginaConcorsoLampada.default,
  chiSiamo: _paginaChiSiamo.default,
  stores: _storesDetail.default,
  storeLocatorInfo: _info.default,
  login: _login.default,
  news: _newsDetail.default,
  newsList: _news2.default,
  paginaNews: _paginaNews.default,
  paginaEventi: _paginaEventi.default,
  events: _eventsDetail.default,
  eventsList: _events.default,
  landingProgetti: _paginaLandingProgetti.default,
  projects: _projectDetail.default,
  paginaViviesco: _paginaViviesco.default,
  chiSiamoNew: _paginaChiSiamoNew.default,
  modulisticaNew: _paginaModulisticaNew.default,
  puntiVendita: puntiVendita,
  editorialPreview: editorialPreview,
  vivipediaMain: _paginaVivipedia.default,
  vivipediaCategory: _category.default,
  vivipediaArticles: _articleDetails.default,
  paginaTvtb: _paginaTvtb.default,
  paginaComunicatiStampa: _paginaComunicatiStampa.default,
  pressReleases: _pressReleases.default,
  notFound: _pagina.default,
  paginaModulistica: _paginaModulistica.default,
  archiveList: _archiveList.default,
  calendarList: _calendar.default
};