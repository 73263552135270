"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Time = exports.Line = exports.LabelContainer = exports.Label = exports.Container = exports.CircleContainer = exports.Circle = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var Container = exports.Container = _styledComponents.default.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-19gu8pq-0"
})(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0 30px;\n  @media (min-width: 1400px) {\n    flex-direction: column;\n    height: 100%;\n    max-height: 400px;\n  }\n"]);
var CircleContainer = exports.CircleContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__CircleContainer",
  componentId: "sc-19gu8pq-1"
})(["\n  position: relative;\n  cursor: ", ";\n"], function (props) {
  return props.isClickable ? 'pointer' : 'auto';
});
var LabelContainer = exports.LabelContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__LabelContainer",
  componentId: "sc-19gu8pq-2"
})(["\n  display: ", ";\n  @media (min-width: 1024px) {\n    display: block;\n  }\n  position: absolute;\n  left: 50%;\n  bottom: -20px;\n  transform: translateX(-50%);\n  white-space: nowrap;\n  text-align: center;\n  @media (min-width: 1400px) {\n    left: 30px;\n    top: 7px;\n    transform: translateX(0);\n  }\n"], function (props) {
  return props.visible ? 'block' : 'none';
});
var Label = exports.Label = _styledComponents.default.p.withConfig({
  displayName: "styles__Label",
  componentId: "sc-19gu8pq-3"
})(["\n  margin: 0;\n  font-size: 10px;\n  font-family: ", ";\n  color: ", ";\n  font-weight: 500;\n  text-transform: uppercase;\n  opacity: ", ";\n  @media (min-width: 1400px) {\n    color: black;\n    font-size: 12px;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.isOpacity ? 0.5 : 1;
});
var Time = exports.Time = _styledComponents.default.span.withConfig({
  displayName: "styles__Time",
  componentId: "sc-19gu8pq-4"
})(["\n  font-size: 12px;\n  text-align: center;\n  color: ", ";\n"], function (props) {
  return props.theme.color.gray;
});
var Circle = exports.Circle = _styledComponents.default.div.withConfig({
  displayName: "styles__Circle",
  componentId: "sc-19gu8pq-5"
})(["\n  width: 24px;\n  height: 24px;\n  border: ", ";\n  border-radius: 100%;\n  background-color: ", ";\n"], function (props) {
  return props.isCompleted ? 'solid 2px ' + props.theme.color.green : 'solid 2px rgba(63, 80, 99, 0.5)';
}, function (props) {
  return props.isActive ? props.theme.color.darkBlue : props.isCompleted ? props.theme.color.green : props.theme.color.white;
});
var Line = exports.Line = _styledComponents.default.div.withConfig({
  displayName: "styles__Line",
  componentId: "sc-19gu8pq-6"
})(["\n  flex: 1;\n  max-width: 120px;\n  background-color: ", ";\n  height: 2px;\n  margin: 0 4px;\n  @media (min-width: 1400px) {\n    margin: 4px 0px;\n    width: 2px;\n  }\n"], function (props) {
  return props.isCompleted ? props.theme.color.green : 'rgba(63, 80, 99, 0.5)';
});