"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledPrevArrow = exports.StyledNextArrow = exports.StyledMainMenuSecondLevelItem = exports.StyledMainMenuSecondLevel = exports.StyledMainMenuMobileLogin = exports.StyledMainMenuFirstLevelItem = exports.StyledMainMenuFirstLevel = exports.StyledMainMenu = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledMainMenu = exports.StyledMainMenu = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledMainMenu",
  componentId: "sc-xtsth4-0"
})(["\n  pointer-events: all;\n  position: ", ";\n  width: 100%;\n  height: auto;\n\n  display: flex;\n  flex-flow: column;\n  align-items: ", ";\n  align-content: flex-end;\n\n  ", "\n  left: 0;\n  z-index: ", ";\n\n  ", " {\n    ", "\n  }\n\n  ", "\n\n  ", " {\n    position: relative;\n    width: auto;\n    height: auto;\n    bottom: auto;\n    left: auto;\n    top: auto;\n    pointer-events: none;\n  }\n"], function (props) {
  return props.secondLevelSlider ? 'relative' : 'absolute';
}, function (props) {
  return props.isSticky ? 'center' : 'flex-end';
}, function (props) {
  return props.inside ? 'top: 20px;' : 'bottom: -38px;';
}, function (props) {
  return props.light ? 1 : 2;
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.inside ? 'top: 20px;' : 'bottom: -65px;';
}, function (props) {
  return props.secondLevelSlider && (0, _styledComponents.css)(["\n      bottom: -60px;\n    "]);
}, function (props) {
  return props.theme.mediaQueries.ipad;
});
var StyledMainMenuFirstLevel = exports.StyledMainMenuFirstLevel = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledMainMenuFirstLevel",
  componentId: "sc-xtsth4-1"
})(["\n  position: relative;\n  width: 100%;\n  top: 0;\n  left: 0;\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n  justify-content: center;\n  transform: translate(0, 0);\n  transition: all 0.4s linear 0s;\n  transform-origin: right;\n  color: white;\n  border-bottom: 1px solid white;\n  padding: 10px 10px;\n\n  ", " {\n    margin-top: ", "px;\n    margin-left: 175px;\n    top: auto;\n    color: ", ";\n    border-bottom: 0;\n    padding: 0;\n    width: 68%;\n    transform: translate(\n      ", "px,\n      ", "px\n    );\n  }\n  ", " {\n    width: 66%;\n    transform: translate(\n      ", "px,\n      ", "px\n    );\n  }\n\n  @media (max-width: 1100px) {\n    width: auto;\n    transform: translate(\n      ", "px,\n      ", "px\n    );\n  }\n\n  @media (max-width: 767px) {\n    width: 100%;\n    transform: translate(0, 0);\n  }\n"], function (props) {
  return props.theme.mediaQueries.ipad;
}, function (props) {
  return props.isSticky ? -36 : -72;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.isSticky ? 0 : -105;
}, function (props) {
  return props.isSticky ? 58 : 60;
}, function (props) {
  return props.theme.mediaQueries.desktop;
}, function (props) {
  return props.isSticky ? 0 : -105;
}, function (props) {
  return props.isSticky ? 58 : 60;
}, function (props) {
  return props.isSticky ? 0 : -105;
}, function (props) {
  return props.isSticky ? 58 : 60;
});
var StyledMainMenuFirstLevelItem = exports.StyledMainMenuFirstLevelItem = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledMainMenuFirstLevelItem",
  componentId: "sc-xtsth4-2"
})(["\n  position: relative;\n  display: block;\n  width: 100%;\n  font-family: ", ";\n  font-weight: 400;\n  margin: 0 auto 10px;\n  padding: 10px 0;\n  pointer-events: all;\n  //text-transform: uppercase;\n  font-size: ", "px;\n  color: ", ";\n  text-align: center;\n  text-decoration: none;\n  ", "\n\n  ", " {\n    font-size: 21px;\n    transform: translateY(", ");\n    opacity: ", ";\n    margin: 0 auto 5px;\n    padding: 5px 0;\n    transition: all 0.2s linear\n      ", "s;\n  }\n\n  ", " {\n    width: auto;\n    height: 49px;\n    line-height: 49px;\n    margin: 0 20px;\n    padding: 0;\n    font-size: 20px;\n    ", "\n    ", "\n  }\n\n  > span {\n    position: relative;\n    z-index: 1;\n  }\n\n  > canvas {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    z-index: 0;\n    display: none;\n\n    ", " {\n      display: block;\n    }\n  }\n"], function (_ref) {
  var theme = _ref.theme;
  return theme.font.regular;
}, function (props) {
  return props.isActive ? 20 : 18;
}, function (props) {
  return props.isActive ? 'white' : '#3f5063';
}, function (props) {
  return props.isIconWrapper && (0, _styledComponents.css)(["\n      width: 25px;\n      height: 40px;\n      display: flex;\n      cursor: pointer;\n      > svg {\n        height: 100%;\n        width: 100%;\n      }\n    "]);
}, function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  return props.isVisible ? 0 : '-10px';
}, function (props) {
  return props.isVisible ? 1 : 0;
}, function (props) {
  return props.isVisible ? 0.1 + props.delayIn / 10 : 0.1 + props.delayOut / 10;
}, function (props) {
  return props.theme.mediaQueries.ipad;
}, function (props) {
  return props.isIconWrapper && (0, _styledComponents.css)(["\n        width: 25px;\n        height: 40px;\n        display: flex;\n        cursor: pointer;\n        > svg {\n          height: 100%;\n          width: 100%;\n        }\n      "]);
}, function (props) {
  return !props.isSticky && (0, _styledComponents.css)(["\n        margin: 0;\n        padding: 0 13px;\n        width: auto;\n      "]);
}, function (props) {
  return props.theme.mediaQueries.ipad;
});
var StyledMainMenuSecondLevel = exports.StyledMainMenuSecondLevel = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledMainMenuSecondLevel",
  componentId: "sc-xtsth4-3"
})(["\n  font-family: ", ";\n  font-size: 16px;\n  text-transform: uppercase;\n  text-align: center;\n  display: ", ";\n  width: ", ";\n  align-items: center;\n  flex-wrap: wrap;\n  justify-content: center;\n  color: white;\n  max-width: 1920px;\n  text-align: ", ";\n  transform: none;\n  transition: all 0.4s linear 0s;\n  margin: 0 auto;\n  padding-top: ", ";\n  padding-bottom: ", ";\n\n  ", ";\n\n  ", " {\n    width: 100vw;\n    transform: translate(", "vw, 70px);\n    text-align: center;\n    padding: 0;\n    justify-content: center;\n  }\n\n  &:before {\n    display: ", ";\n    content: '';\n    position: absolute;\n    width: ", ";\n    border-radius: ", ";\n    opacity: ", ";\n    height: 100%;\n    top: 0;\n    left: 50%;\n    transform: translate(-50%, 0);\n    background: ", ";\n    transition: all 0.4s linear 0s;\n\n    ", "\n\n    ", " {\n      display: block;\n    }\n  }\n"], function (_ref2) {
  var theme = _ref2.theme;
  return theme.font.regular;
}, function (props) {
  return props.secondLevelSlider ? 'inline-block' : 'flex';
}, function (props) {
  return props.light ? '100vw' : '70%';
}, function (props) {
  return props.light ? 'center' : 'left';
}, function (props) {
  return props.light ? '10px' : '40px';
}, function (props) {
  return props.light ? '10px' : '0';
}, function (props) {
  return props.secondLevelSlider && (0, _styledComponents.css)(["\n      padding-right: 25px;\n      z-index: 10;\n    "]);
}, function (props) {
  return props.theme.mediaQueries.ipad;
}, function (props) {
  return props.isSticky ? 0 : 10;
}, function (props) {
  return props.light ? 'block' : 'none';
}, function (props) {
  return props.light ? props.isSticky ? '100%' : '90%' : props.isSticky ? '100vw' : '210px';
}, function (props) {
  return props.isSticky ? '0' : '40px';
}, function (props) {
  return props.isSticky ? '1' : '1';
}, function (props) {
  return props.theme.color.blueViolet;
}, function (props) {
  return !props.isSticky && (0, _styledComponents.css)(["\n        ", " {\n          opacity: 0;\n        }\n      "], function (props) {
    return props.theme.mediaQueries.tablet;
  });
}, function (props) {
  return props.theme.mediaQueries.ipad;
});
var StyledMainMenuSecondLevelItem = exports.StyledMainMenuSecondLevelItem = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledMainMenuSecondLevelItem",
  componentId: "sc-xtsth4-4"
})(["\n\n  display: ", ";\n\n  ", " {\n    display: block;\n  }\n\n   button span,\n    a span{\n    font-family: ", ";\n    font-weight:400;\n    font-size: 16px;\n    text-transform: uppercase;\n    text-align: center;\n   }\n\n   ", "\n\n   ", "\n\n  width: ", ";\n  margin: 0 14px;\n  pointer-events: all;\n\n  ", " {\n     width: 210px;\n     margin: 0 20px;\n  }\n\n  ", "\n\n  > * {\n\n    ", " {\n      background: transparent;\n      box-shadow: none;\n      padding: 0;\n      text-align: ", ";\n      line-height: normal;\n\n      button > div,\n       a > div{ display: none;}\n    }\n\n    ", "\n  }\n\n\n   ", " {\n      button > div,\n       a > div{ display: none;}\n       button, a { padding: 0 10px;}\n   }\n\n\n"], function (props) {
  return props.mobileOnly ? 'none' : 'block';
}, function (props) {
  return props.theme.mediaQueries.ipad;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.font.regular;
}, function (props) {
  return props.submenu && props.isActive && (0, _styledComponents.css)(["\n       button span,\n       a span {\n         font-size: 18px;\n         font-family: ", ";\n       }\n     "], function (_ref4) {
    var theme = _ref4.theme;
    return theme.font.bold;
  });
}, function (props) {
  return props.isBack && (0, _styledComponents.css)(["\n       &:first-child {\n         position: absolute;\n         left: 0;\n\n         button span,\n         a span {\n           font-size: 18px;\n           font-family: ", ";\n           font-weight: 400;\n         }\n\n         svg {\n           position: relative;\n           transform: rotate(-180deg);\n         }\n       }\n\n       &:first-child + * {\n         button span,\n         a span {\n           font-size: 18px;\n           font-family: ", ";\n           font-weight: 400;\n         }\n       }\n     "], function (_ref5) {
    var theme = _ref5.theme;
    return theme.font.bold;
  }, function (_ref6) {
    var theme = _ref6.theme;
    return theme.font.bold;
  });
}, function (props) {
  return props.light ? 'auto' : '210px';
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return !props.light && (0, _styledComponents.css)(["\n      ", " {\n        display: inline-block;\n        width: 50%;\n        margin: 0 0 20px 0;\n        padding: 0;\n\n        &:nth-child(odd) {\n          transform: translateX(", ");\n          opacity: ", ";\n          transition: all 0.2s linear\n            ", "s;\n        }\n\n        &:nth-child(even) {\n          padding-left: 20px;\n\n          transform: translateX(", ");\n          opacity: ", ";\n          transition: all 0.2s linear\n            ", "s;\n        }\n      }\n    "], function (props) {
    return props.theme.mediaQueries.phone;
  }, function (props) {
    return props.isVisible ? 0 : '-50px';
  }, function (props) {
    return props.isVisible ? 1 : 0;
  }, function (props) {
    return props.isVisible ? 0.1 + props.delayIn / 10 : 0.1 + props.delayOut / 10;
  }, function (props) {
    return props.isVisible ? 0 : '50px';
  }, function (props) {
    return props.isVisible ? 1 : 0;
  }, function (props) {
    return props.isVisible ? 0.1 + props.delayIn / 10 : 0.1 + props.delayOut / 10;
  });
}, function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  return props.light ? 'center' : 'left';
}, function (_ref7) {
  var isSticky = _ref7.isSticky;
  return isSticky && (0, _styledComponents.css)(["\n        background: transparent !important;\n        box-shadow: none !important;\n      "]);
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var StyledMainMenuMobileLogin = exports.StyledMainMenuMobileLogin = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledMainMenuMobileLogin",
  componentId: "sc-xtsth4-5"
})(["\n  width: 72%;\n  margin: 0 auto;\n  padding-top: 20px;\n\n  transform: translateY(", ");\n  opacity: ", ";\n  transition: all 0.2s linear ", "s;\n\n  > * {\n    box-shadow: none;\n    border: 1px solid white;\n  }\n\n  ", " {\n    display: none;\n  }\n"], function (props) {
  return props.isVisible ? 0 : '30px';
}, function (props) {
  return props.isVisible ? 1 : 0;
}, function (props) {
  return props.isVisible ? 0.4 : 0;
}, function (props) {
  return props.theme.mediaQueries.ipad;
});
var StyledNextArrow = exports.StyledNextArrow = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledNextArrow",
  componentId: "sc-xtsth4-6"
})(["\n  width: 36px;\n  height: 36px;\n  position: absolute;\n  float: right;\n  display: block;\n  top: 49%;\n  right: -25px;\n  cursor: pointer;\n  transform: translateY(-50%);\n  z-index: 10;\n\n  &:after {\n    content: '';\n    width: 77px;\n    height: 100%;\n    position: absolute;\n    right: 0;\n    top: 0;\n    background: linear-gradient(to right, transparent 10px, #7e2889);\n    z-index: -1;\n  }\n\n  svg {\n    width: 18px;\n    height: 18px;\n    display: inline-block;\n  }\n"]);
var StyledPrevArrow = exports.StyledPrevArrow = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledPrevArrow",
  componentId: "sc-xtsth4-7"
})(["\n  width: 36px;\n  height: 36px;\n  position: absolute;\n  float: right;\n  display: block;\n  top: 49%;\n  left: 0;\n  cursor: pointer;\n  transform: translateY(-50%);\n  z-index: 10;\n\n  &:after {\n    content: '';\n    width: 77px;\n    height: 100%;\n    position: absolute;\n    left: 0;\n    top: 0;\n    background: linear-gradient(to left, transparent 10px, #1587c8);\n    z-index: -1;\n  }\n\n  svg {\n    width: 18px;\n    height: 18px;\n    display: inline-block;\n  }\n"]);