"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitle = exports.StyledText = exports.StyledMask = exports.StyledIcons = exports.StyledCardsItem = exports.StyledCardsGrid = exports.StyledCardsContent = exports.StyledCallBlock = exports.StyledButtonWrapper = exports.InvertedButton = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledCardsGrid = exports.StyledCardsGrid = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCardsGrid",
  componentId: "sc-1gfq5o0-0"
})(["\n  display: flex;\n  flex-direction: column;\n  padding-left: 20px;\n  padding-right: 20px;\n  margin-left: auto;\n  margin-right: auto;\n  max-width: 969px;\n  ", " {\n    padding-left: 0;\n    padding-right: 0;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: space-between;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledCardsItem = exports.StyledCardsItem = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCardsItem",
  componentId: "sc-1gfq5o0-1"
})(["\n  position: relative;\n  margin: 0;\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  border-radius: 35px;\n  box-shadow: 0 10px 35px 0 rgba(81, 129, 186, 0.25);\n  overflow: hidden;\n\n  ", " {\n    flex-direction: row;\n    margin: 0;\n    flex: 1;\n    min-height: 390px;\n    justify-content: flex-end;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledMask = exports.StyledMask = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledMask",
  componentId: "sc-1gfq5o0-2"
})(["\n  flex: auto;\n  mask-image: url('/static/images/ProductPageInfoCards-mask-mb.svg');\n  mask-repeat: no-repeat;\n  mask-position: bottom;\n  mask-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-image: url(", ");\n  width: 100%;\n  height: 150px;\n\n  ", " {\n    mask-position: right;\n    mask-image: url('/static/images/ProductPageInfoCards-mask.svg');\n    width: auto;\n    flex: 0 0 33.33%;\n    height: 100%;\n  }\n"], function (props) {
  return props.background;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledIcons = exports.StyledIcons = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIcons",
  componentId: "sc-1gfq5o0-3"
})(["\n  position: relative;\n  width: 120px;\n  height: 120px;\n  margin-top: -70px;\n  ", " {\n    position: absolute;\n    width: 200px;\n    height: 200px;\n    left: 18%;\n    top: 50%;\n    transform: translateY(-50%);\n    margin-top: 0;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledCardsContent = exports.StyledCardsContent = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCardsContent",
  componentId: "sc-1gfq5o0-4"
})(["\n  display: flex;\n  flex-direction: column;\n  align-content: center;\n  align-items: center;\n  text-align: center;\n  flex: 0 0 auto;\n  padding: 10px 24px 40px;\n  ", " {\n    max-width: 664px;\n    flex: 0 0 66.66%;\n    padding: 48px 125px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-1gfq5o0-5"
})(["\n  font-family: ", ";\n  font-size: 22px;\n  line-height: 1.36;\n  color: ", ";\n  margin: 0 0 16px;\n  ", " {\n    font-size: 24px;\n    line-height: 1.5;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledText = exports.StyledText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledText",
  componentId: "sc-1gfq5o0-6"
})(["\n  font-family: ", ";\n  font-size: 18px;\n  line-height: 1.44;\n  color: ", ";\n  margin-bottom: 32px;\n\n  ", " {\n    font-size: 20px;\n    line-height: 1.5;\n  }\n\n  p {\n    margin: 0;\n    padding: 0;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-1gfq5o0-7"
})(["\n  display: flex;\n  justify-content: center;\n\n  a,\n  button {\n    color: ", ";\n    background-color: transparent;\n    border: 2px solid ", ";\n    font-family: ", ";\n    font-size: 16px;\n    font-weight: normal;\n    font-style: normal;\n    font-stretch: normal;\n    letter-spacing: normal;\n    text-align: center;\n    text-decoration: none;\n    display: flex;\n    align-items: center;\n    border-radius: 30px;\n    padding: 12px 16px;\n    transition: all 0.2s ease-in 0s;\n    cursor: pointer;\n\n    &:hover {\n      color: ", ";\n      background-color: ", ";\n    }\n\n    ", " {\n      font-size: 20px;\n      padding: 18px 48px;\n    }\n  }\n\n  + span {\n    margin: 20px 0;\n    font-family: ", ";\n    font-size: 12px;\n    line-height: 1.5;\n    color: ", ";\n\n    ", " {\n      margin: 24px 0;\n      font-size: 14px;\n    }\n  }\n"], function (props) {
  return props.theme.color.blue;
}, function (props) {
  return props.theme.color.blue;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.color.blue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var InvertedButton = exports.InvertedButton = _styledComponents.default.span.withConfig({
  displayName: "styles__InvertedButton",
  componentId: "sc-1gfq5o0-8"
})(["\n  color: #e81c69;\n  cursor: pointer;\n  width: fit-content;\n  justify-self: center;\n  font-family: ", ";\n  cursor: pointer;\n  margin: 0;\n  font-size: 20px;\n  font-weight: bold;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: normal;\n  letter-spacing: normal;\n  color: #e81c69;\n\n  ", " {\n    font-size: 16px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var StyledCallBlock = exports.StyledCallBlock = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCallBlock",
  componentId: "sc-1gfq5o0-9"
})(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  gap: 12px;\n\n  ", " {\n    flex-direction: row;\n    align-items: center;\n    gap: 16px;\n  }\n\n  > div {\n    font-family: ", ";\n    font-size: 12px;\n    line-height: 1.5;\n    color: ", ";\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});