"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledWrapper = exports.StyledMainText = exports.HeroWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _styles = require("../PageBanner/styles");
var HeroWrapper = exports.HeroWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__HeroWrapper",
  componentId: "sc-jmpys8-0"
})(["\n  background-image: url(\"", "\");\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n  padding: 0;\n  position: relative;\n  overflow: hidden;\n  canvas {\n  position: absolute;\n  z-index: 0;\n  ", "{\n    transform: scale(1, -1) !important;\n  }\n  }\n\n  ", "{\n    position:relative;\n    width: 100vw;\n    left:50%;\n    transform: translateX(-50%);\n  }\n\n  @media (min-width: 768px) and (max-width: 1024px){\n    margin-top: 55px;\n  }\n\n  @media (min-width: 1024px) and (max-width: 1250px){\n    margin-top: 90px;\n  }\n\n\n  position: relative;\n  margin-top: 123px;\n  height: 180px;\n  padding: 24px;\n\n  ", "\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: bottom;\n\n\n  canvas{\n    position: absolute;\n    z-index: -1;\n    overflow: hidden;\n    bottom: -1px;\n\n    @media (max-width: 768px) {\n      z-index: 999;\n      width: 100%;\n      transform: rotate(0deg) !important;\n    }\n  }\n\n  ", "{\n    ", "\n\n    div{\n      position: absolute !important;\n      bottom: 10px;\n    }\n  }\n\n"], function (props) {
  return props.imgUrl;
}, function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  if (props.desktopImage) {
    return "\n        background-image: url('".concat(props.desktopImage, "?width=1440&auto=format');\n      ");
  }
}, function (props) {
  return props.theme.mediaQueries.phone;
}, function (props) {
  if (props.mobileImage) {
    return "\n          background-image: url(\"".concat(props.mobileImage, "?width=1440&auto=format\");\n        ");
  }
});
var StyledMainText = exports.StyledMainText = _styledComponents.default.p.withConfig({
  displayName: "styles__StyledMainText",
  componentId: "sc-jmpys8-1"
})(["\n  font-family: ", ";\n  margin: 0;\n  margin-top: -10px;\n  font-size: 45px;\n  font-weight: 500;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.5;\n  letter-spacing: normal;\n  color: ", ";\n\n  ", " {\n    max-width: 160px;\n    font-size: 25px;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var StyledWrapper = exports.StyledWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledWrapper",
  componentId: "sc-jmpys8-2"
})(["\n  max-width: 1170px;\n  width: 90%;\n  margin: 0 auto;\n"]);