"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitleWrapper = exports.StyledTextWrapper = exports.StyledMissionList = exports.StyledMissionItemWrapper = exports.StyledItemWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledMissionList = exports.StyledMissionList = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledMissionList",
  componentId: "sc-15ls1qi-0"
})(["\n  max-width: 310px;\n  margin: 0 auto;\n  \n  @media (min-width: 768px) {\n    max-width: 570px;\n  }\n  \n  @media (min-width: 1440px) {\n    max-width: 1170px;\n    margin-bottom: 180px;\n    padding-bottom: 60px;\n  }\n"]);
var StyledTitleWrapper = exports.StyledTitleWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitleWrapper",
  componentId: "sc-15ls1qi-1"
})(["\n  margin-bottom: 15px;\n  text-align: center;\n  font-family: ", ";\n  color: ", ";\n  font-size: 22px;\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledTextWrapper = exports.StyledTextWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTextWrapper",
  componentId: "sc-15ls1qi-2"
})(["\n  text-align: center;\n  font-family: ", ";\n  color: ", ";\n  font-size: 18px;\n  line-height: 1.5;\n  margin-bottom: 50px;\n  \n  @media (min-width: 768px) {\n    max-width: 568px;\n    margin: 0 auto 50px;\n  }\n  \n  @media (min-width: 768px) {\n    margin: 0 auto 80px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledItemWrapper = exports.StyledItemWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledItemWrapper",
  componentId: "sc-15ls1qi-3"
})(["\n  \n  @media (min-width: 1440px) {\n    width: 271px;\n  }\n"]);
var StyledMissionItemWrapper = exports.StyledMissionItemWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledMissionItemWrapper",
  componentId: "sc-15ls1qi-4"
})(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  \n  @media (min-width: 1440px) {\n    flex-direction: row;\n    justify-content: space-evenly;\n  }\n"]);