"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Title = exports.Tag = exports.SubContainer = exports.StyledSeparator = exports.StyledMonth = exports.StyledDay = exports.StyledDate = exports.ImgContainer = exports.IconsWrapper = exports.FaqToggle = exports.Date = exports.Container = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var Container = exports.Container = _styledComponents.default.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-10tcz8i-0"
})(["\n  width: 100%;\n  cursor: pointer;\n  background-color: #fff;\n  box-shadow: 0 0 35px 0 rgba(81, 129, 186, 0.25);\n  border-radius: 10px;\n  display: flex;\n  min-height: 150px;\n  overflow: hidden;\n  margin-bottom: 30px;\n"]);
var IconsWrapper = exports.IconsWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__IconsWrapper",
  componentId: "sc-10tcz8i-1"
})(["\n  right: 0;\n  top: 0;\n  padding: 15px;\n  flex: 0 100px;\n  max-width: 100px;\n  display: flex;\n  > svg {\n    height: 100%;\n  }\n"]);
var ImgContainer = exports.ImgContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__ImgContainer",
  componentId: "sc-10tcz8i-2"
})(["\n  position: relative;\n  right: 0;\n  top: 0;\n  padding: 5px;\n  flex: 0 30%;\n  max-width: 30%;\n  display: flex;\n  background-size: cover;\n  background-position: center;\n  background-image: url(", ");\n"], function (props) {
  return props.featured_image;
});
var Title = exports.Title = _styledComponents.default.div.withConfig({
  displayName: "styles__Title",
  componentId: "sc-10tcz8i-3"
})(["\n  font-size: 25px;\n  font-family: ", ";\n  font-weight: 400;\n  text-transform: uppercase;\n  color: ", ";\n  @media (min-width: 1000px) {\n    font-size: 30px;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var Date = exports.Date = _styledComponents.default.div.withConfig({
  displayName: "styles__Date",
  componentId: "sc-10tcz8i-4"
})(["\n  font-size: 13px;\n  font-style: italic;\n  color: #696969;\n"]);
var FaqToggle = exports.FaqToggle = _styledComponents.default.div.withConfig({
  displayName: "styles__FaqToggle",
  componentId: "sc-10tcz8i-5"
})(["\n  font-size: 13px;\n  span {\n    margin: 10px 0px;\n    display: block;\n    position: relative;\n    clear: both;\n    color: blue;\n    text-decoration: underline;\n  }\n  > div {\n    padding-bottom: 10px;\n    line-height: 17px;\n    height: 0px;\n    opacity: 0;\n    overflow: hidden;\n    ", "\n    ul {\n      margin: 0;\n      padding: 20px;\n    }\n  }\n"], function (props) {
  return props.isOpen && (0, _styledComponents.css)(["\n        opacity: 1;\n        height: auto;\n      "]);
});
var Tag = exports.Tag = _styledComponents.default.span.withConfig({
  displayName: "styles__Tag",
  componentId: "sc-10tcz8i-6"
})(["\n  align-self: flex-end;\n  color: #496c72;\n  margin-bottom: 10px;\n  font-weight: 100;\n"]);
var SubContainer = exports.SubContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__SubContainer",
  componentId: "sc-10tcz8i-7"
})(["\n  flex: 1;\n  display: flex;\n  align-items: flex-start;\n  padding: 10px 20px;\n  flex-direction: column;\n  justify-content: center;\n  p {\n    line-height: 18px;\n    font-size: 13px;\n    @media (min-width: 1000px) {\n      font-size: 13px;\n    }\n  }\n"]);
var StyledDate = exports.StyledDate = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDate",
  componentId: "sc-10tcz8i-8"
})(["\n  width: 44px;\n  height: 66px;\n  position: absolute;\n  top: 12px;\n  left: 12px;\n  background-color: ", ";\n  border-radius: 3.5px;\n  text-align: center;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-evenly;\n  align-items: center;\n\n  @media (min-width: 768px) {\n    top: 20px;\n    left: 20px;\n    border-radius: 5px;\n    width: 56px;\n    height: 83px;\n    top: 20px;\n    left: 20px;\n    border-radius: 5px;\n    display: flex;\n    position: unset;\n    box-shadow: 0px 0px 10px #b9b9b9;\n  }\n"], function (props) {
  return props.theme.color.white;
});
var StyledSeparator = exports.StyledSeparator = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSeparator",
  componentId: "sc-10tcz8i-9"
})(["\n  width: 34px;\n  height: 1px;\n  margin: 0 auto;\n  background-color: ", ";\n\n  @media (min-width: 768px) {\n    width: 50px;\n  }\n"], function (props) {
  return props.theme.color.darkGray;
});
var StyledDay = exports.StyledDay = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDay",
  componentId: "sc-10tcz8i-10"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 34px;\n  line-height: 1.1;\n\n  @media (min-width: 768px) {\n    font-size: 40px;\n  }\n"], function (props) {
  return props.theme.color.magenta;
}, function (props) {
  return props.theme.font.regular;
});
var StyledMonth = exports.StyledMonth = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledMonth",
  componentId: "sc-10tcz8i-11"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n\n  @media (min-width: 768px) {\n    font-size: 22px;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.medium;
});