"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledVivipediaArticleBlock = exports.StyledTitle = exports.StyledText = exports.StyledLinkWrapper = exports.StyledImage = exports.StyledIconWrapper = exports.StyledIconTypeWrapper = exports.StyledDate = exports.StyledContentWrapper = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledImage = exports.StyledImage = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledImage",
  componentId: "sc-m41dw1-0"
})(["\n  width: 34.37%;\n  height: 100%;\n  float: left;\n  background-image: url(", ");\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n\n  @media (min-width: 768px) {\n    width: 100%;\n    height: 220px;\n    float: none;\n    border-radius: 15px;\n  }\n"], function (props) {
  return props.imgUrl;
});
var StyledContentWrapper = exports.StyledContentWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContentWrapper",
  componentId: "sc-m41dw1-1"
})(["\n  width: 65.63%;\n  height: 100%;\n  padding: 13px;\n  float: left;\n\n  @media (min-width: 768px) {\n    width: 100%;\n    height: 231px;\n    padding: 15px 0 0;\n    float: none;\n  }\n"]);
var StyledDate = exports.StyledDate = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDate",
  componentId: "sc-m41dw1-2"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 12px;\n  margin-bottom: 12px;\n\n  @media (min-width: 768px) {\n    margin-bottom: 40px;\n  }\n"], function (props) {
  return props.theme.font.italic;
}, function (props) {
  return props.theme.color.darkGray;
});
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-m41dw1-3"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 16px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  margin-bottom: 20px;\n\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n\n  @media (min-width: 768px) {\n    margin-bottom: 25px;\n    font-size: 18px;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledIconTypeWrapper = exports.StyledIconTypeWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconTypeWrapper",
  componentId: "sc-m41dw1-4"
})(["\n  width: 34px;\n  height: 34px;\n  margin-right: 0px;\n  > div {\n    height: 34px;\n  }\n  @media (min-width: 768px) {\n    width: 65px;\n    height: 65px;\n    > div {\n      height: 65px;\n    }\n  }\n"]);
var StyledText = exports.StyledText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledText",
  componentId: "sc-m41dw1-5"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 12px;\n  line-height: 1.5;\n\n  @media (min-width: 768px) {\n    margin-bottom: 30px;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledLinkWrapper = exports.StyledLinkWrapper = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledLinkWrapper",
  componentId: "sc-m41dw1-6"
})(["\n  display: none;\n  text-decoration: none;\n  color: ", ";\n  font-family: ", ";\n  cursor: pointer;\n\n  &:visited {\n    color: ", ";\n  }\n\n  @media (min-width: 768px) {\n    margin-bottom: 60px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    float: right;\n    pointer-events: all !important;\n  }\n"], function (props) {
  return props.theme.color.red;
}, function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.red;
});
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-m41dw1-7"
})(["\n  @media (min-width: 768px) {\n    width: 15px;\n    margin-left: 15px;\n  }\n"]);
var StyledVivipediaArticleBlock = exports.StyledVivipediaArticleBlock = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledVivipediaArticleBlock",
  componentId: "sc-m41dw1-8"
})(["\n  width: 100%;\n  height: 160px;\n  margin: 0 auto;\n  overflow: hidden;\n  -webkit-font-smoothing: antialiased;\n  display: block;\n  text-decoration: none;\n  background-color: ", ";\n  box-shadow: 0 0 35px 0 ", ";\n\n  @media (min-width: 768px) {\n    width: 330px;\n    height: 465px;\n    pointer-events: none;\n    box-shadow: none;\n\n    .slick & {\n      margin: 0 36px;\n    }\n  }\n\n  @media (min-width: 1440px) {\n    margin: 0 auto;\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.color.shadowBlue;
});