import ActionButton from './ActionButton'
import AdvantagesSlider from './AdvantagesSlider'
import ArchiveList from './ArchiveList'
import ArchiveCalendarList from './ArchiveCalendarList'
import BannerPromo from './BannerPromo'
import CallMeBack from './CallMeBack'
import CallMeBackPage from './CallMeBackPage'
import CardBlock from './CardBlock'
import CheckboxList from './CheckboxList'
import ConfiguratorEntryPoint from './ConfiguratorEntryPoint'
import ContentPrizeBlock from './ContentPrizeBlock'
import DownloadList from './DownloadList'
import DataLayer from './DataLayer'
import MgmHome from './MgmHome'
import Iframe from './Iframe'
import Codice from './Codice'
import DownloadAccordion from './DownloadAccordion'
import EventInfo from './EventInfo'
import Faq from './Faq'
import FaqList from './FaqList'
import FaqDynamic from './FaqDynamic'
import HeroBanner from './HeroBanner'
import HeroBlob from './HeroBlob'
import LaunchBlock from './LaunchBlock'
import ListOfRegulations from './ListOfRegulations'
import MultipleLaunchBlock from './MultipleLaunchBlock'
import OffersForm from './OffersForm'
import OffersFormBusiness from './OffersFormBusiness'
import PageBlock from './PageBlock'
import ProductPageCTA from './ProductPageCTA'
import SectionDownloadApp from './SectionDownloadApp'
import SectionHighlights from './SectionHighlights'
import ListOfIconsCTA from './ListOfIconsCTA'
import SectionPhone from './SectionPhone'
import SectionText from './SectionText'
import SectionTitle from './SectionTitle'
import SectionTitleCanvas from './SectionTitleCanvas'
import SmallHeroBanner from './SmallHeroBanner'
import StoreLocator from './StoreLocator'
import TextBlock from './TextBlock'
import FormWatty from './FormWatty'
import FormPmi from './FormPmi'
import Image from './Image'
import Gallery from './Gallery'
import BannerCarousel from './BannerCarousel'
import CenteredLinks from './CenteredLinks'
import MultipleLaunchBlockHome from './MultipleLaunchBlockHome'
import HeroSlider from './HeroSlider'
import VideoPlayer from './VideoPlayer'
import NewsEvents from './NewsEvents'
import NewsEventsHome from './NewsEventsHome'
import ListOfIcons from './ListOfIcons'
import pageOffers from './aggregate'
import ProductCard from './ProductCard'
import DoubleIcon from './DoubleIcon'
import InfographicList from './InfographicList'
import StaticBlob from './StaticBlob'
import Mission from './Mission'
import TextImgRoundedBorders from './TextImgRoundedBorders'
import GuidaBolletta from './BillGuidelines'
import SectionCustomerArea from './SectionCustomerArea'
import SponsorSlider from './SponsorSlider'
import IconsGrid from './IconsGrid'
import Separator from './Separator'
import PressReleaseList from './PressReleaseList'
import HeroImage from './HeroImage'
import PageBlockWithBlob from './PageBlockWithBlob'
import SectionAbstract from './SectionAbstract'
import SectionSteps from './SectionSteps'
import AbsoluteBlob from './AbsoluteBlob'
import CardSlider from './CardSlider'
import ListWithRoundImg from './ListWithRoundImg'
import ListWithRoundImgPMI from './ListWithRoundImgPMI'
import ViviescoParagraphIcons from './ViviescoParagraphIcons'
import ViviescoProdotti from './ViviescoProdotti'
import ViviescoRiquadro from './ViviescoRiquadro'
import ViviescoForm from './ViviescoForm'
import VivipediaArticles from './VivipediaArticles'
import VivipediaArticlesHome from './VivipediaArticlesHome'
import SalesPointList from './SalesPointList'
import WorkWithUsTitle from './WorkWithUsTitle'
import WorkWithUsList from './WorkWithUsList'
import WorkWithUsBlockList from './WorkWithUsBlockList'
import WorkWithUsForm from './WorkWithUsForm'
import ProductPageHero from './ProductPageHero'
import ProductPageTab from './ProductPageTab'
import ProductPageCards from './ProductPageCards'
import ProductPageInfoCards from './ProductPageInfoCards'
import ProductPageTabContent from './ProductPageTabContent'
import ProductPageRows from './ProductPageRows'
import Recensioni from './Recensioni'
import FaqPremium from './FaqPremium'
import DocumentsPremium from './DocumentsPremium'
import ListOfAggregators from './ListOfAggregators'
import AdvMultipleLaunchBlockHome from './AdvMultipleLaunchBlockHome'
import AdvMultipleLaunchBlockFeatured from './AdvMultipleLaunchBlockFeatured'
import AdvListIconsBlock from './AdvListIconsBlock'
import FormChiamateSospette from './FormChiamateSospette'
import HeroPage from './HeroPage'

export default {
  HeroSlider,
  HeroBanner,
  HeroBlob,
  HeroImage,
  DataLayer,
  MgmHome,
  Iframe,
  Codice,
  SmallHeroBanner,
  PageBlock,
  PageBlockWithBlob,
  CardBlock,
  LaunchBlock,
  TextBlock,
  Image,
  Gallery,
  MultipleLaunchBlock,
  MultipleLaunchBlockHome,
  Faq,
  FaqList,
  FaqDynamic,
  SectionTitle,
  SectionTitleCanvas,
  SectionPhone,
  ContentPrizeBlock,
  SectionDownloadApp,
  SectionCustomerArea,
  SectionText,
  DownloadList,
  DownloadAccordion,
  ListOfIcons,
  IconsGrid,
  ListOfIconsCTA,
  CheckboxList,
  ConfiguratorEntryPoint,
  ActionButton,
  StoreLocator,
  OffersForm,
  OffersFormBusiness,
  ProductPageCTA,
  CallMeBack,
  CallMeBackPage,
  SectionHighlights,
  AdvantagesSlider,
  EventInfo,
  BannerPromo,
  ListOfRegulations,
  FormWatty,
  FormPmi,
  BannerCarousel,
  CenteredLinks,
  VideoPlayer,
  NewsEvents,
  NewsEventsHome,
  ProductCard,
  ArchiveList,
  ArchiveCalendarList,
  DoubleIcon,
  InfographicList,
  StaticBlob,
  Mission,
  TextImgRoundedBorders,
  GuidaBolletta,
  SponsorSlider,
  Separator,
  PressReleaseList,
  SectionAbstract,
  SectionSteps,
  AbsoluteBlob,
  SalesPointList,
  CardSlider,
  ListWithRoundImg,
  ListWithRoundImgPMI,
  ViviescoParagraphIcons,
  ViviescoProdotti,
  ViviescoRiquadro,
  ViviescoForm,
  VivipediaArticles,
  VivipediaArticlesHome,
  WorkWithUsTitle,
  WorkWithUsList,
  WorkWithUsForm,
  WorkWithUsBlockList,
  pageOffers,
  ProductPageHero,
  ProductPageTab,
  ProductPageCards,
  ProductPageInfoCards,
  ProductPageTabContent,
  ProductPageRows,
  Recensioni,
  FaqPremium,
  DocumentsPremium,
  ListOfAggregators,
  AdvMultipleLaunchBlockHome,
  AdvMultipleLaunchBlockFeatured,
  AdvListIconsBlock,
  FormChiamateSospette,
  HeroPage,
}
