import React, {Component} from 'react'

import dynamic from 'next/dynamic'
import axios from 'axios'
import formatBreadcrumbs from '../lib/formatBreadcrumbs'

import components from '../pagebuilder'

const Pagebuilder = dynamic(import('@alkemy/pagebuilder'), {
  ssr: false,
  loading: () => (
    <div
      className="pb-loading"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        top: 0,
        backgroundColor: 'white',
        zIndex: 1000,
      }}
    >
      Loading
    </div>
  ),
})

components['Root'] = ({children}) => children

const Root = ({root, data, breadcrumbs, country, locale}) => (
  <div>
    {root.childIds.map(childId => {
      const node = data.nodes[childId]
      const {component, props, childIds, id} = node
      const Component = components[node.component]

      return (
        <Component
          key={`node-${id}`}
          {...node.props}
          country={country}
          locale={locale}
          breadcrumbs={breadcrumbs}
        >
          {childIds &&
            childIds.map(id => <Root root={data.nodes[id]} data={data} />)}
        </Component>
      )
    })}
  </div>
)

const upload = file => {
  let formData = new FormData()

  formData.append('image', file)

  return axios.post(`${process.env.LARAVEL}/upload/image`, formData, {
    headers: {'Content-Type': 'multipart/form-data'},
  })
}

const getMetaKey = (wpMeta, key) => {
  const metaArray = wpMeta && wpMeta[key]
  return metaArray && metaArray.length > 0 ? metaArray[0] : ''
}

export default ({
  getInitialPageBuilderData,
  savePageBuilderData,
}) => ComposedComponent => {
  return class WithPagebuilder extends Component {
    static async getInitialProps(ctx) {
      const {query, store, req, res} = ctx
      const {locale, slug} = query

      let composedInitialProps = {}
      if (ComposedComponent.getInitialProps) {
        composedInitialProps = await ComposedComponent.getInitialProps(ctx)
      }

      const withPagebuilder =
        req && req.query && typeof req.query.pb !== 'undefined'
      let wpData = []
      if (getInitialPageBuilderData) {
        wpData = await getInitialPageBuilderData({
          locale,
          slug,
        }).catch(() => false)
      } else {
        wpData = composedInitialProps.wpData
      }

      if (!wpData) {
        return {
          statusCode: 404,
        }
      }

      const getMeta = key => getMetaKey(wpData && wpData.meta, key)

      const isRedirectActive = getMeta('_pprredirect_active')
      const submenuDynamic =
        composedInitialProps.submenuDynamic || getMeta('submenu')

      if (isRedirectActive === '1') {
        const redirectUrl = getMeta('_pprredirect_url')
        const redirectType = getMeta('_pprredirect_type')
        return res.redirect(redirectType, redirectUrl)
      }

      let wpContent = wpData && wpData.post_content

      let breadcrumbs = formatBreadcrumbs(wpData)

      try {
        wpContent = JSON.parse(wpContent)
      } catch (err) {
        wpContent = false
      }

      if (withPagebuilder && wpContent) {
        store.dispatch({
          type: 'INIT_NODES',
          nodes: wpContent && wpContent.nodes,
          media: wpContent && wpContent.media,
        })
      }

      return {
        ...composedInitialProps,
        withPagebuilder,
        wpData,
        wpContent,
        breadcrumbs,
        submenuDynamic,
      }
    }

    render() {
      const {
        withPagebuilder,
        wpData,
        breadcrumbs,
        submenuDynamic,
        wpContent,
        country,
        locale,
      } = this.props

      if (withPagebuilder) {
        return (
          <ComposedComponent {...this.props}>
            <Pagebuilder
              onSave={value => {
                return savePageBuilderData(value, wpData.ID)
              }}
              onUpload={upload}
              availableComponents={components}
            />
          </ComposedComponent>
        )
      } else {
        if (wpContent) {
          return (
            <ComposedComponent {...this.props}>
              <Root
                root={wpContent.nodes['root']}
                data={wpContent}
                country={country}
                breadcrumbs={breadcrumbs}
                submenuDynamic={submenuDynamic}
                locale={locale}
              />
            </ComposedComponent>
          )
        }

        return <ComposedComponent {...this.props} />
      }
    }
  }
}
