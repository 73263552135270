"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitleWrapper = exports.StyledSmallHero = exports.StyledIconWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledSmallHero = exports.StyledSmallHero = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSmallHero",
  componentId: "sc-21vsyq-0"
})(["\n  height: 193px;\n  color: #ffffff;\n  margin-top: 103px;\n  font-size: 30px;\n  text-transform: uppercase;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  canvas {\n    position: absolute;\n    z-index: -1;\n    width: 180%;\n\n    @media (min-width: 768px) {\n      width: 100%;\n    }\n  }\n  h1 {\n    font-size: 30px;\n    color: ", ";\n    font-family: ", ";\n    font-weight: 400;\n    @media (max-width: 767px) {\n      font-size: 25px;\n    }\n  }\n"], function (props) {
  return props.text_color === 'darkText' ? '#3F5063' : '#ffffff';
}, function (props) {
  return props.theme.font.medium;
});
var StyledTitleWrapper = exports.StyledTitleWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitleWrapper",
  componentId: "sc-21vsyq-1"
})(["\n  display: flex;\n  position: relative;\n  flex-direction: row;\n  align-items: center;\n  font-size: 20px;\n  color: ", ";\n  font-family: ", ";\n  text-transform: uppercase;\n  top: -36px;\n  @media (min-width: 768px) {\n    font-size: 30px;\n    top: 0;\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.font.medium;
});
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-21vsyq-2"
})(["\n  width: 60px;\n  height: 60px;\n\n  @media (min-width: 768px) {\n    width: 80px;\n    height: 80px;\n  }\n"]);