"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledStepNavItem = exports.StyledStepNav = exports.StyledLabel = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledStepNav = exports.StyledStepNav = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledStepNav",
  componentId: "sc-zsguq2-0"
})(["\n  display: block;\n  flex-direction: column;\n  align-items: center;\n"]);
var StyledLabel = exports.StyledLabel = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledLabel",
  componentId: "sc-zsguq2-1"
})(["\n  position: absolute;\n"]);
var StyledStepNavItem = exports.StyledStepNavItem = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledStepNavItem",
  componentId: "sc-zsguq2-2"
})(["\n  width: 20px;\n  height: 20px;\n  border: 1.5px solid\n    ", ";\n  border-radius: 50%;\n  background-color: ", ";\n  position: relative;\n  cursor: pointer;\n\n  &:not(:last-child) {\n    margin-bottom: 40px;\n\n    &:before {\n      content: '';\n      background-color: ", ";\n      width: 2px;\n      height: 35px;\n      position: absolute;\n      bottom: -38px;\n      left: 50%;\n      transform: translateX(-50%);\n    }\n  }\n"], function (props) {
  return props.past ? props.theme.color.green : props.theme.color.darkBlue;
}, function (props) {
  return props.active ? props.theme.color.darkBlue : props.past ? props.theme.color.green : 'transparent';
}, function (props) {
  return props.past ? props.theme.color.green : props.theme.color.darkBlue;
});