import React from 'react'
import axios from 'axios'
import _ from 'lodash'
import styled from 'styled-components'
import { Formik } from 'formik'

import {
  FormTextInputMaterial,
  FormTextAreaMaterial,
  LegalNote,
  Wrapper,
  ButtonGradient,
} from '@alkemy/components'

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const ErrorDiv = styled.div`
  color: red;
  text-align: center;
  width: 100%;
  margin-top: 30px;
  font-family: ${props => props.theme.font.regular};
`
const Success = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 300px;
  flex-wrap: wrap;
  z-index: 100;
  font-size: 19px;
  color: #0fb935;
  font-family: ${props => props.theme.font.regular};
  background-color: #fff;
  align-items: center;
  justify-content: center;
`
const initialValues = {
  name: '',
  surname: '',
  email: '',
  city: '',
  textArea: '',
  phone: '',
}

const checkRequirements = values => {
  let errors = {}
  if (!values.name) {
    errors.name = 'Richiesto'
  }
  if (!values.surname) {
    errors.surname = 'Richiesto'
  }
  if (!values.email) {
    errors.email = 'Richiesto'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Email non valida'
  }
  if (!values.phone) {
    errors.phone = 'Richiesto'
  } else if (!/^[0-9\s\+\-]{7,20}$/i.test(values.phone)) {
    errors.phone = 'Numero di telefono non valido'
  }
  if (!values.city) {
    errors.city = 'Richiesto'
  }
  if (!values.textArea) {
    errors.textArea = 'Richiesto'
  }
  return errors
}

class FormWatty extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    success: false,
    errorFromServer: null,
  }

  submitForm = async (values, { setSubmitting }) => {
    const { endpoint } = this.props

    try {
      let postedFormData = await axios.post(endpoint, values)
      if (postedFormData) {
        tC.event.formInviato(this, {})

        this.setState({
          success: true,
        })
      }
    } catch (error) {
      this.setState({ errorFromServer: error.response.data })
    }
  }

  render() {
    const { legalNote, noWrap, thankMessage } = this.props
    const MessageTyp = thankMessage
      ? thankMessage
      : 'Messaggio inviato correttamente'
    const { success, errorFromServer } = this.state
    const Wrap = noWrap ? styled.div`` : Wrapper
    return (
      <Wrap>
        <div id="formWatty" style={{ paddingTop: '80px', marginTop: '-80px' }}>
          {success && (
            <Success>
              <div
                dangerouslySetInnerHTML={{
                  __html: MessageTyp,
                }}
              />
            </Success>
          )}
          {!success && (
            <Formik
              initialValues={initialValues}
              validate={checkRequirements}
              onSubmit={this.submitForm}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form
                  onSubmit={handleSubmit}
                  style={{ width: '100%' }}
                  autoComplete="none"
                >
                  <Flex>
                    <FormTextInputMaterial
                      name="name"
                      label="Nome*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      error={errors.name && touched.name}
                      errorMessage={errors.name}
                    />

                    <FormTextInputMaterial
                      name="surname"
                      label="Cognome*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.surname}
                      error={errors.surname && touched.surname}
                      errorMessage={errors.surname}
                    />
                    <FormTextInputMaterial
                      name="email"
                      label="Email*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      error={errors.email && touched.email}
                      errorMessage={errors.email}
                    />

                    <FormTextInputMaterial
                      name="phone"
                      label="Telefono*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      error={errors.phone && touched.phone}
                      errorMessage={errors.phone}
                    />

                    <FormTextInputMaterial
                      name="city"
                      label="Città*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                      error={errors.city && touched.city}
                      errorMessage={errors.city}
                    />

                    <FormTextAreaMaterial
                      name="textArea"
                      label="Motivo del contatto*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.message}
                      error={errors.message && touched.message}
                      errorMessage={errors.message}
                    />

                    <LegalNote text={legalNote} />
                    {errorFromServer && (
                      <ErrorDiv>
                        Errore:{' '}
                        {errorFromServer.error
                          ? errorFromServer.error
                          : 'È avvenuto un errore. Riprovare.'}
                      </ErrorDiv>
                    )}
                    <ButtonGradient
                      label="Invia"
                      mainColor="blueViolet"
                      type="submit"
                      solo="true"
                    />
                  </Flex>
                </form>
              )}
            </Formik>
          )}
        </div>
      </Wrap>
    )
  }
}

export default FormWatty
