"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledPageBuilder = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledPageBuilder = exports.StyledPageBuilder = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledPageBuilder",
  componentId: "sc-1iqsycf-0"
})(["\n  padding: 0;\n  \n  ", "\n"], function (props) {
  return props.hasMarginTop && (0, _styledComponents.css)(["\n    \n    margin-top: 60px;\n    \n    @media (min-width: 768px) {\n      margin-top: 115px;  \n    }\n    \n    @media (min-width: 1440px) {\n      margin-top: 40px;  \n    }\n    \n  "]);
});