import { compose } from "recompose"
import withPageBuilder from "@alkemy/pagebuilder/lib/hoc/withPageBuilder"
import withFields from "@alkemy/pagebuilder/lib/hoc/withFields"

import { CenteredLinks, withWrapper } from "@alkemy/components"

export default compose(
  withPageBuilder({
    niceName: "Link Centrati",
  }),
  withFields([
    {
      prop: "wrapped",
      type: "boolean",
      label: "Contenuto Boxed",
    },
    {
      prop: "items",
      type: "repeater",
      label: "Links",
      model: [
        {
          prop: "img",
          label: "Immagine",
        },
        {
          prop: "link",
          label: "Link",
        },
        {
          prop: "target",
          type: "multiple",
          label: "Target",
          options: ["_blank: Blank", "_self: Self"],
        },
      ],
    },
  ]),
  withWrapper
)(CenteredLinks)
