"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledLinkWrapper = exports.StyledLinkIconWrapper = exports.StyledLink = exports.StyledIconWrapper = exports.StyledHoursWrapper = exports.StyledHoursTitle = exports.StyledHeader = exports.StyledDistance = exports.StyledCityName = exports.StyledBody = exports.StyledAddress = exports.StyledAccordionStore = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledAccordionStore = exports.StyledAccordionStore = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledAccordionStore",
  componentId: "sc-e51ssb-0"
})(["\n  width: 100%;\n  height: 60px;\n  margin-bottom: 13px;\n  padding: 0 20px;\n  border-radius: 20px;\n  box-shadow: 0 0 35px 0 ", ";\n  overflow: hidden;\n  -webkit-font-smoothing: antialiased;\n"], function (props) {
  return props.theme.color.shadowBlue;
});
var StyledHeader = exports.StyledHeader = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledHeader",
  componentId: "sc-e51ssb-1"
})(["\n  height: 60px;\n  padding: 0 8px;\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  align-items: center;\n"]);
var StyledCityName = exports.StyledCityName = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCityName",
  componentId: "sc-e51ssb-2"
})(["\n  padding-right: 10px;\n  font-family: ", ";\n  color: ", ";\n  flex-basis: 60%;\n\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: ", ";\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.ellipsis ? "nowrap" : "initial";
});
var StyledDistance = exports.StyledDistance = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDistance",
  componentId: "sc-e51ssb-3"
})(["\n  font-family: ", ";\n  color: ", ";\n  flex-basis: 40%;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-e51ssb-4"
})(["\n  width: 20px;\n  position: absolute;\n  top: 50%;\n  right: 8px;\n  transform: translateY(-50%);\n  transition: all 0.2s ease-in-out 0s;\n\n  ", "\n"], function (props) {
  return props.isOpen && (0, _styledComponents.css)(["\n      transform: translateY(-50%) rotateX(180deg);\n    "]);
});
var StyledBody = exports.StyledBody = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBody",
  componentId: "sc-e51ssb-5"
})(["\n  padding-bottom: 20px;\n"]);
var StyledAddress = exports.StyledAddress = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledAddress",
  componentId: "sc-e51ssb-6"
})(["\n  margin-bottom: 25px;\n  padding: 0 8px 28px;\n  border-bottom: 1px solid ", ";\n  font-family: ", ";\n  color: ", ";\n"], function (props) {
  return props.theme.color.gray;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.magenta;
});
var StyledHoursTitle = exports.StyledHoursTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledHoursTitle",
  componentId: "sc-e51ssb-7"
})(["\n  margin-bottom: 18px;\n  font-family: ", ";\n  color: ", ";\n  text-align: center;\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledHoursWrapper = exports.StyledHoursWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledHoursWrapper",
  componentId: "sc-e51ssb-8"
})(["\n  padding: 0 8px;\n  margin-bottom: 25px;\n\n  > div {\n    > div {\n      display: flex;\n      flex-direction: row;\n      flex-wrap: wrap;\n\n      span {\n        flex-basis: 50%;\n\n        &:first-child {\n          font-family: ", ";\n        }\n\n        &:last-child {\n          font-family: ", ";\n        }\n      }\n    }\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.font.regular;
});
var StyledLinkWrapper = exports.StyledLinkWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledLinkWrapper",
  componentId: "sc-e51ssb-9"
})(["\n  margin-top: 15px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n"]);
var StyledLink = exports.StyledLink = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledLink",
  componentId: "sc-e51ssb-10"
})(["\n  font-family: ", ";\n  color: ", ";\n  text-decoration: none;\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.magenta;
});
var StyledLinkIconWrapper = exports.StyledLinkIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledLinkIconWrapper",
  componentId: "sc-e51ssb-11"
})(["\n  width: 20px;\n  margin-left: 8px;\n"]);