const concorsoLampada = require("./news-detail/concorso-lampada.json")
const campagnaCommerciale = require("./news-detail/campagna-commerciale.json")
const telethon = require("./news-detail/telethon.json")
const natale2018 = require("./news-detail/natale2018.json")
const aperturaSaronno = require("./news-detail/apertura-saronno.json")
const cartolinaTelethon = require("./news-detail/cartolina-natale-telethon.json")
const desiderioBusto = require("./news-detail/desiderio-busto.json")
const campagnaRadio = require("./news-detail/campagna-radio.json")

module.exports = {
  "/news/concorso-lampada": concorsoLampada,
  "/news/campagna-commerciale": campagnaCommerciale,
  "/news/telethon": telethon,
  "/news/natale2018": natale2018,
  "/news/apertura-saronno": aperturaSaronno,
  "/news/cartolina-natale-telethon": cartolinaTelethon,
  "/news/desiderio-busto": desiderioBusto,
  "/news/campagna-radio": campagnaRadio,
}
