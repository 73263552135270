import React from 'react'
import _ from 'lodash'
import OpenModal from '../components/OpenModal'
import { MeasuresConsumer } from './withMeasureProvider'
import { StickyConsumer } from './withStickyProvider'
import { Contents } from '@alkemy/common'
import { Header } from '@alkemy/components'
import SearchModal from './SearchModal'

const formatSubmenu = (submenuDynamic, current_page_slug) => {
  console.log(current_page_slug)

  return (
    submenuDynamic &&
    submenuDynamic.map(item => {
      return {
        label: item.post_title || item.title,
        url: item.url,
        isActive: _.includes(item.url, current_page_slug),
        icon: false,
      }
    })
  )
}

const withHeader = EnhancedComponent => {
  const WithHeader = props => {
    const {
      submenuDynamic,
      isHome,
      isViviesco,
      stickTopPage,
      secondLevelSlider,
    } = props
    return (
      <React.Fragment>
        <OpenModal>
          {({ handleOpen, handleClose, closeSearchModal, openSearchModal }) => (
            <React.Fragment>
              <SearchModal />
              <MeasuresConsumer>
                {({ addMeasure }) => (
                  <StickyConsumer>
                    {({ isScrolled }) => (
                      <Header
                        {...props}
                        isSticky={(isScrolled && isHome) || !isHome}
                        noLogoLink={isHome}
                        contents={Contents}
                        submenuDynamic={submenuDynamic}
                        handleLoginModalOpen={handleOpen}
                        addHeightToGlobalMeasures={addMeasure}
                        openSearchModal={openSearchModal}
                        closeSearchModal={closeSearchModal}
                        searchEnabled={process.env.SEARCH_FEATURE_FLAG}
                        submenu={isViviesco ? 'viviesco' : ''}
                        stickTopPage={stickTopPage}
                        secondLevelSlider={secondLevelSlider}
                      />
                    )}
                  </StickyConsumer>
                )}
              </MeasuresConsumer>
            </React.Fragment>
          )}
        </OpenModal>
        <EnhancedComponent {...props} />
      </React.Fragment>
    )
  }

  WithHeader.getInitialProps = async ctx => {
    let props = {}
    if (typeof EnhancedComponent.getInitialProps === 'function') {
      props = await EnhancedComponent.getInitialProps(ctx)
    }
    const { query } = ctx
    const isHome = query && query.slug === '/'
    const isViviesco =
      (query && query.slug === '/viviesco/') || query.slug === '/viviesco'
    const submenuDynamic =
      props &&
      props.submenuDynamic &&
      formatSubmenu(props.submenuDynamic, props.wpData.post_name)

    return { ...props, submenuDynamic, isHome, isViviesco }
  }

  return WithHeader
}

export default withHeader
