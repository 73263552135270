import React from "react"
import withGlobalInitialProps from "../lib/withGlobalInitialProps"
import withHeader from "../lib/withHeader"
import withFooter from "../lib/withFooter"
import withLoginModal from "../lib/withLoginModal"
import withStoreLocatorModal from "../lib/withStoreLocatorModal"
import withPagebuilder from "../lib/withPagebuilder"
import withTracking from "../lib/withTracking"
import wpapi from "wpapi"

import axios from "axios"

import { compose } from "recompose"

const Page = props => props.children || null

const getNonce = () => axios.get(`${process.env.WP_NONCE}`).then(x => x.data)

const getInitialPageBuilderData = ({ locale, slug }) => {
  const url = `${process.env.WP_API}/msr/v1/slug/${locale}/?slug=${slug}`
  return axios.get(url).then(x => x.data)
}

const savePageBuilderData = async (value, resourceID) => {
  const nonce = await getNonce()

  const api = new wpapi({
    endpoint: process.env.WP_API,
    nonce,
  })

  return api
    .pages()
    .id(resourceID)
    .update({
      content: JSON.stringify(value),
    })
}

const EnhanchedPage = compose(
  withGlobalInitialProps,
  withTracking,
  withLoginModal,
  withStoreLocatorModal,
  withFooter,
  withHeader,
  withPagebuilder({ getInitialPageBuilderData, savePageBuilderData })
)(Page)

export default EnhanchedPage
