"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitle = exports.StyledIconWrapper = exports.StyledHeaderWrapper = exports.StyledEditorialPreview = exports.StyledCta = exports.StyledButtonWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledEditorialPreview = exports.StyledEditorialPreview = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledEditorialPreview",
  componentId: "sc-14r4b75-0"
})(["\n  margin: 100px 0;\n"]);
var StyledHeaderWrapper = exports.StyledHeaderWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledHeaderWrapper",
  componentId: "sc-14r4b75-1"
})(["\n  position: relative;\n  height: 80px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  > canvas {\n    position: absolute;\n    left: 50%;\n    bottom: -70px;\n    transform: translateX(-50%);\n    z-index: -1;\n  }\n"]);
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-14r4b75-2"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 22px;\n  text-align: center;\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.white;
});
var StyledButtonWrapper = exports.StyledButtonWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonWrapper",
  componentId: "sc-14r4b75-3"
})(["\n  width: 160px;\n  margin: 0 auto;\n"]);
var StyledCta = exports.StyledCta = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledCta",
  componentId: "sc-14r4b75-4"
})(["\n  line-height: 40px;\n  text-align: center;\n  font-family: ", ";\n  color: ", ";\n  font-size: 16px;\n  border-radius: 34px;\n  border: 1px solid ", ";\n  text-decoration: none;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n  align-items: center;\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-14r4b75-5"
})(["\n  width: 8px;\n"]);