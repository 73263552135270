import React from 'react'
import axios from 'axios'
import _ from 'lodash'
import styled from 'styled-components'
import { Formik } from 'formik'

import {
  FormTextInputMaterial,
  FormTextAreaMaterial,
  PreNote,
  LegalNote,
  Wrapper,
  ButtonGradient,
} from '@alkemy/components'

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const CloseModal = styled.div`
  width: 100%;
  padding: 0 35px;
  line-height: 40px;
  position: relative;
  color: #fff;
  border-radius: 30px;
  border: none;
  background: none;
  text-align: center;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
  box-shadow: 0px 0px 20px 2px rgba(95, 11, 43, 0.3);
  outline: none;
  overflow: hidden;
  will-change: box-shadow;
  -webkit-transition: all 0.2s ease-in 0s;
  transition: all 0.2s ease-in 0s;
  white-space: nowrap;
  background: linear-gradient(255deg, #7e2889, #1587c8);
  min-width: 300px;
  max-width: 300px;
  position: relative;
  display: block;
  margin: 40px auto !important;
  margin-top: 40px;
  margin-bottom: 40px;
  @media (max-width: 1024px) {
    max-width: 230px;
    min-width: 230px;
  }
`
const ErrorDiv = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  @media (min-width: 768px) {
    max-width: 770px;
    max-height: 569px;
  }
  border-radius: 35px;
  box-shadow: 0 10px 50px 0 rgba(81, 129, 186, 0.25);
  color: red;
  text-align: center;
  background-color: #ffffff;
  font-family: ${props => props.theme.font.regular};
  z-index: 4;
`
const Success = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  @media (min-width: 768px) {
    max-width: 770px;
    max-height: 569px;
  }

  width: 100%;
  border-radius: 35px;
  box-shadow: 0 10px 50px 0 rgba(81, 129, 186, 0.25);
  background-color: #ffffff;
  text-align: center;
  font-family: ${props => props.theme.font.regular};
  z-index: 4;
`
const Closed = styled.div`
  width: 370px;
  height: 60px;
  border-radius: 31.5px;
  background-image: linear-gradient(260deg, #7e2889 108%, #1587c8 9%);
`
const ClearFix = styled.div`
  width: 100%;
  clear: both;
  float: right;
`

const initialValues = {
  nome: '',
  cognome: '',
  prodotto: 'VIVIEVOLUTION GAS',
  email: '',
  nomeazienda: '',
  city: '',
  textArea: '',
  phone: '',
}

const checkRequirements = values => {
  let errors = {}
  if (!values.nome) {
    errors.nome = 'Richiesto'
  }
  if (!values.cognome) {
    errors.cognome = 'Richiesto'
  }
  if (!values.nomeazienda) {
    errors.nomeazienda = 'Richiesto'
  }
  if (!values.piva) {
    errors.piva = 'Richiesto'
  }

  if (!values.email) {
    errors.email = 'Richiesto'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Email non valida'
  }
  if (!values.phone) {
    errors.phone = 'Richiesto'
  } else if (!/^[0-9\s\+\-]{7,20}$/i.test(values.phone)) {
    errors.phone = 'Numero di telefono non valido'
  }
  if (!values.city) {
    errors.city = 'Richiesto'
  }

  return errors
}

class FormPmi extends React.Component {
  constructor(props) {
    super(props)
  }
  state = {
    success: false,
    errorFromServer: null,
  }

  submitForm = async (values, { setSubmitting }) => {
    var body = document.body
    body.classList.add('ModalOpen')
    const { endpoint } = this.props
    try {
      let postedFormData = await axios.post(endpoint, values)
      if (postedFormData) {
        this.setState({
          success: true,
        })
      }
    } catch (error) {
      this.setState({ errorFromServer: error.response.data })
    }
  }

  handleClickTime = () => {
    //location.reload()
    location.replace(window.location.origin + window.location.pathname)
  }

  componentDidMount() {
    var body = document.body
    body.classList.add('pagina-pmi')
    var element = document.querySelector("input[name='consumo']")
    element.required = false
  }
  componentDidUpdate() {}

  render() {
    const { legalNote, noWrap, preNote, Note } = this.props
    const { success, errorFromServer } = this.state
    const Wrap = noWrap ? styled.div`` : Wrapper
    return (
      <Wrap>
        <div className={'container-form'}>
          {success && (
            <div
              style={{ display: 'block', opacity: '1' }}
              className={'pmi-modal-content success'}
              id={'pmi-modal-content-success'}
            >
              <Success
                className={'pmi-modal'}
                style={{ textAlign: 'center', zIndex: '4' }}
              >
                <div className={'content-text'}>
                  <div
                    style={{
                      fontSize: '26px',
                      fontWeight: '500',
                      color: '#3f5063',
                    }}
                  >
                    La tua richiesta è stata inviata.
                    <br />
                  </div>
                  <div
                    style={{
                      fontSize: '20px',
                      color: '#3f5063',
                    }}
                  >
                    Ti contatteremo il prima possibile.
                    <span
                      className={'closed-pmi'}
                      style={{ Zindex: '999999999' }}
                    ></span>
                    <br />
                    <br />
                    <CloseModal onClick={this.handleClickTime}>
                      Chiudi
                    </CloseModal>
                  </div>
                </div>
              </Success>
            </div>
          )}

          <Formik
            initialValues={initialValues}
            validate={checkRequirements}
            onSubmit={this.submitForm}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form
                onSubmit={handleSubmit}
                style={{ with: '100%' }}
                id={'formpmi'}
              >
                <div className="vg-radio-wrapper">
                  <label className="vg-input-label">
                    <input
                      type="radio"
                      name="prodotto"
                      value="VIVIEVOLUTION GAS"
                      checked={values.prodotto === 'VIVIEVOLUTION GAS'}
                      onChange={handleChange}
                      className="vg-input-radio"
                      checked="checked"
                    />
                    <span>VIVIEVOLUTION GAS</span>
                    <div className="div"></div>
                  </label>

                  <label className="vg-input-label">
                    <input
                      type="radio"
                      name="prodotto"
                      value="VIVIEVOLUTION POWERGreen"
                      checked={values.prodotto === 'VIVIEVOLUTION POWERGreen'}
                      onChange={handleChange}
                      className="vg-input-radio"
                    />
                    <span>VIVIEVOLUTION POWERGreen</span>
                    <div className="div"></div>
                  </label>
                </div>
                <PreNote text={preNote} />
                <Flex>
                  <FormTextInputMaterial
                    name="nome"
                    label="Nome*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.nome}
                    error={errors.nome && touched.nome}
                    errorMessage={errors.nome}
                  />
                  <FormTextInputMaterial
                    name="cognome"
                    label="Cognome*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.cognome}
                    error={errors.cognome && touched.cognome}
                    errorMessage={errors.cognome}
                  />
                  <FormTextInputMaterial
                    name="email"
                    label="Email*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={errors.email && touched.email}
                    errorMessage={errors.email}
                  />
                  <FormTextInputMaterial
                    name="phone"
                    label="Telefono*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    error={errors.phone && touched.phone}
                    errorMessage={errors.phone}
                  />

                  <FormTextInputMaterial
                    name="nomeazienda"
                    label="Nome Azienda*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.nomeazienda}
                    error={errors.nomeazienda && touched.nomeazienda}
                    errorMessage={errors.nomeazienda}
                  />
                  <FormTextInputMaterial
                    name="city"
                    label="Città*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                    error={errors.city && touched.city}
                    errorMessage={errors.city}
                  />
                  <LegalNote text={Note} />
                </Flex>
                <Flex>
                  <FormTextInputMaterial
                    name="piva"
                    label="Partita IVA*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.piva}
                    error={errors.piva && touched.piva}
                    errorMessage={errors.piva}
                  />
                  <FormTextInputMaterial
                    name="consumo"
                    label="Consumo annuo"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.consumo}
                  />
                  <FormTextAreaMaterial
                    name="textArea"
                    label="Motivo del contatto"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.message}
                  />

                  <LegalNote text={legalNote} />
                  {errorFromServer && (
                    <div
                      style={{ display: 'block', opacity: '1' }}
                      className={'pmi-modal-content error'}
                      id={'pmi-modal-content-error'}
                    >
                      <ErrorDiv
                        className={'pmi-modal'}
                        style={{ textAlign: 'center' }}
                      >
                        <div className={'content-text'}>
                          Errore:{' '}
                          {errorFromServer.error
                            ? errorFromServer.error
                            : 'È avvenuto un errore. Riprovare.'}
                        </div>
                        <CloseModal onClick={this.handleClickTime}>
                          Chiudi
                        </CloseModal>
                      </ErrorDiv>
                    </div>
                  )}
                  <ButtonGradient
                    label="Invia"
                    mainColor="blueViolet"
                    type="submit"
                    solo="true"
                  />
                </Flex>
              </form>
            )}
          </Formik>
        </div>
      </Wrap>
    )
  }
}

export default FormPmi
