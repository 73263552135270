"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var theme = {
  assetsUrl: '/static',
  font: {
    bold: '"ApercuBold", sans-serif',
    boldItalic: '"ApercuBoldItalic", sans-serif',
    italic: '"ApercuItalic", sans-serif',
    light: '"ApercuLight", sans-serif',
    lightItalic: '"ApercuLightItalic", sans-serif',
    medium: '"ApercuMedium", sans-serif',
    mediumItalic: '"ApercuMediumItalic", sans-serif',
    mono: '"ApercuMono", sans-serif',
    regular: '"ApercuRegular", sans-serif'
  },
  color: {
    white: '#FFF',
    black: '#000',
    gray: '#CBCBCB',
    darkGray: '#818181',
    red: '#F00',
    magenta: '#e81c69',
    lightBlue: '#077BBE',
    blue: '#204386',
    green: '#6EC18A',
    darkBlue: '#3F5063',
    orange: '#F09724',
    transparent: 'transparent',
    shadowBlue: 'rgba(81, 129, 186, .25)',
    shadowBlueHover: 'rgba(81, 129, 186, .34)',
    shadowRed: 'rgba(95, 11, 43, .3)',
    whiteHover: 'rgba(255, 255, 255, .1)',
    blackPressed: 'rgba(0, 0, 0, .07)',
    blueViolet: 'linear-gradient(255deg, #7E2889, #1587C8)',
    greenBlue: 'linear-gradient(255deg, #0E89CA, #6EC18A)',
    pinkOrange: 'linear-gradient(255deg, #F09724, #E81C69)',
    yellowPink: 'linear-gradient(255deg, #F49593, #F7BF28)',
    loginGradient: 'linear-gradient(to bottom, #f53ca2, #e81c69)'
  },
  gradients: {
    blueViolet: ['#7E2889', '#1587C8'],
    greenBlue: ['#0E89CA', '#6EC18A'],
    pinkOrange: ['#F09724', '#E81C69'],
    yellowPink: ['#F49593', '#F7BF28']
  },
  mediaQueries: {
    phone: '@media (max-width: 767px)',
    tablet: '@media (min-width: 768px)',
    ipad: '@media (min-width: 1024px)',
    desktop: '@media (min-width: 1440px)',
    small: '@media (max-height: 799px) and (min-width: 1280px)'
  },
  transitions: {
    base: '.2 ease-in 0s'
  }
};
var _default = exports.default = theme;