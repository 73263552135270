"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledWrapper = exports.StyledTextInput = exports.StyledLabel = exports.StyledError = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledWrapper = exports.StyledWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledWrapper",
  componentId: "sc-x4zvo9-0"
})(["\n  border: 0;\n  position: relative;\n  margin-bottom: 30px;\n  flex-grow: 1;\n  width: 100%;\n"]);
var StyledLabel = exports.StyledLabel = _styledComponents.default.label.withConfig({
  displayName: "styles__StyledLabel",
  componentId: "sc-x4zvo9-1"
})(["\n  display: block;\n  position: absolute;\n  color: #cbcbcb;\n  font-size: 16px;\n  font-family: ", ";\n  top: 0;\n  margin-top: 10px;\n  margin-left: 0px;\n  transition: 0.2s ease-in-out;\n  pointer-events: none;\n"], function (props) {
  return props.theme.font.medium;
});
var StyledError = exports.StyledError = _styledComponents.default.span.withConfig({
  displayName: "styles__StyledError",
  componentId: "sc-x4zvo9-2"
})(["\n  color: ", ";\n"], function (props) {
  return props.theme.color.red;
});
var StyledTextInput = exports.StyledTextInput = _styledComponents.default.input.withConfig({
  displayName: "styles__StyledTextInput",
  componentId: "sc-x4zvo9-3"
})(["\n  border: 0;\n  border-bottom: 1px solid #cbcbcb;\n  color: #cbcbcb;\n  width: 100%;\n  padding: 10px 10px 10px 7px;\n  outline: none;\n  z-index: 9;\n  background: transparent;\n  &:valid,\n  &:disabled {\n    color: #3f5063;\n    border-color: #3f5063;\n    margin-top: 0;\n  }\n  &:focus ~ label,\n  &:not(:placeholder-shown) ~ label,\n  &:disabled ~ label {\n    margin-top: -20px;\n    margin-left: 0px;\n    font-size: 13px;\n  }\n  ", "\n"], function (props) {
  return props.error && "\n    color: #ce1111!important;\n    border-color:#e38892!important;\n  ";
});