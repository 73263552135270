import React, { useContext } from 'react'
import _ from 'lodash'
import withGlobalInitialProps from '../lib/withGlobalInitialProps'
import formatBreadcrumbs from '../lib/formatBreadcrumbs'
import { compose } from 'recompose'
import axios from 'axios'
import ListOfAggregators from '../components/ListOfAggregator'

const getPageData = ({ locale, slug }) => {
  const url = `${process.env.WP_API}/msr/v1/slug/${locale}/?slug=${slug}`
  return axios.get(url).then(x => x.data)
}
const getOffers = async () => {
  const url = `${process.env.WP_API}/wp/v2/pages?filter[offers_category]=aggregatori&per_page=50&_embed&per_page=100&filter[orderby]=menu_order`
  const data = await axios.get(url).then(x => x.data)
  console.log(data)
  return data
}

const PageOffers = props => {
  const offers = getOffers()
  return (
    <React.Fragment>
      <ListOfAggregators offers={offers} />
    </React.Fragment>
  )
}

export default compose(withGlobalInitialProps)(PageOffers)
