import {compose} from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import {SectionStaticBlob, withWrapper} from '@alkemy/components'

export default compose(
  withPageBuilder(),
  withFields([
    {
      prop: 'blobColor',
      type: 'multiple',
      label: 'Colore Blob',
      options: ['red:Rosso', 'green:Verde', 'blue:Blu'],
      initialValue: 'red',
    },
    {
      prop: 'text',
      label: 'Test',
    },
    {
      prop: 'img',
      label: 'Immagine',
    },
  ]),
  withWrapper,
)(SectionStaticBlob)
