"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledVivipediaLaunchBlock = exports.StyledTitleWrapper = exports.StyledTextWrapper = exports.StyledImg = exports.StyledCtaWrapper = exports.StyledContentWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledVivipediaLaunchBlock = exports.StyledVivipediaLaunchBlock = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledVivipediaLaunchBlock",
  componentId: "sc-oj9fqv-0"
})(["\n  max-width: 300px;\n  min-height: 200px;\n  margin: 0 auto 60px;\n  padding: 25px 15px 0 103px;\n  position: relative;\n  border-radius: 35px;\n  box-shadow: 0 0 35px 0 ", ";\n  -webkit-font-smoothing: antialiased;\n\n  @media (min-width: 768px) {\n    min-height: 430px;\n    margin: 15px;\n    padding: 210px 30px 0;\n  }\n\n  @media (min-width: 1440px) {\n    max-width: 400px;\n    min-height: 460px;\n    padding: 250px 50px 0;\n  }\n"], function (props) {
  return props.theme.color.shadowBlue;
});
var StyledImg = exports.StyledImg = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledImg",
  componentId: "sc-oj9fqv-1"
})(["\n  width: 66px;\n  height: 66px;\n  position: absolute;\n  left: 20px;\n  top: 60px;\n  background-image: url(", ");\n  background-size: contain;\n  background-position: center;\n  background-repeat: no-repeat;\n\n  @media (min-width: 768px) {\n    width: 140px;\n    height: 140px;\n    left: 50%;\n    top: 40px;\n    transform: translateX(-50%);\n  }\n\n  @media (min-width: 1440px) {\n    width: 175px;\n    height: 175px;\n    left: 50%;\n    top: 50px;\n    transform: translateX(-50%);\n  }\n"], function (props) {
  return props.img + '?width=175&auto=format';
});
var StyledContentWrapper = exports.StyledContentWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContentWrapper",
  componentId: "sc-oj9fqv-2"
})([""]);
var StyledTitleWrapper = exports.StyledTitleWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitleWrapper",
  componentId: "sc-oj9fqv-3"
})(["\n  margin-bottom: 15px;\n  font-family: ", ";\n  color: ", ";\n  font-size: 16px;\n\n  @media (min-width: 768px) {\n    margin-bottom: 20px;\n    text-align: center;\n    font-size: 18px;\n  }\n\n  @media (min-width: 1440px) {\n    font-size: 20px;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.orange;
});
var StyledTextWrapper = exports.StyledTextWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTextWrapper",
  componentId: "sc-oj9fqv-4"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 14px;\n  line-height: 1.4;\n\n  @media (min-width: 768px) {\n    font-size: 16px;\n    line-height: 1.5;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledCtaWrapper = exports.StyledCtaWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledCtaWrapper",
  componentId: "sc-oj9fqv-5"
})(["\n  position: absolute;\n  width: 240px;\n  left: 50%;\n  transform: translateX(-50%);\n  bottom: -21px;\n\n  button {\n    padding: 0;\n\n    span {\n      @media (min-width: 1440px) {\n        font-size: 20px;\n      }\n    }\n  }\n\n  @media (min-width: 768px) {\n    width: 180px;\n  }\n\n  @media (min-width: 1440px) {\n    width: 240px;\n    bottom: -31px;\n  }\n"]);