"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitleWrapper = exports.StyledTextWrapper = exports.StyledIconWrapper = exports.StyledCtaWrapper = exports.StyledArchiveCalendarList = exports.StyledArchiveCalendarItem = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StyledArchiveCalendarList = exports.StyledArchiveCalendarList = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledArchiveCalendarList",
  componentId: "sc-13y8zg-0"
})(["\n  width: 100%;\n  max-width: 300px;\n  margin: 0 auto;\n\n  @media (min-width: 768px) {\n    max-width: 650px;\n  }\n\n  @media (min-width: 1440px) {\n    max-width: 970px;\n  }\n"]);
var StyledTitleWrapper = exports.StyledTitleWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitleWrapper",
  componentId: "sc-13y8zg-1"
})(["\n  margin: 50px 0;\n  text-align: center;\n  font-family: ", ";\n  color: ", ";\n  font-size: 18px;\n\n  @media (min-width: 768px) {\n    font-size: 20px;\n  }\n\n  @media (min-width: 1440px) {\n    font-size: 22px;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledArchiveCalendarItem = exports.StyledArchiveCalendarItem = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledArchiveCalendarItem",
  componentId: "sc-13y8zg-2"
})(["\n  width: 100%;\n  height: 70px;\n  margin-bottom: 30px;\n  padding: 0 30px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  position: relative;\n  box-shadow: 0 0 35px 0 ", ";\n  text-decoration: none;\n  border-radius: 35px;\n\n  @media (min-width: 768px) {\n    height: 60px;\n    margin-bottom: 40px;\n    justify-content: space-between;\n  }\n\n  @media (min-width: 1280px) {\n    height: 80px;\n    margin-bottom: 30px;\n  }\n"], function (props) {
  return props.theme.color.shadowBlue;
});
var StyledTextWrapper = exports.StyledTextWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTextWrapper",
  componentId: "sc-13y8zg-3"
})(["\n  font-family: ", ";\n  color: ", ";\n  font-size: 15px;\n  line-height: 1.3;\n\n  @media (min-width: 768px) {\n    width: 460px;\n    font-size: 16px;\n  }\n\n  @media (min-width: 1440px) {\n    width: 670px;\n    font-size: 18px;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledCtaWrapper = exports.StyledCtaWrapper = _styledComponents.default.a.withConfig({
  displayName: "styles__StyledCtaWrapper",
  componentId: "sc-13y8zg-4"
})(["\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n\n  @media (min-width: 768px) {\n    width: 126px;\n    height: 30px;\n    padding: 0 10px 0 20px;\n    position: relative;\n    top: initial;\n    left: initial;\n    text-decoration: none;\n    font-family: ", ";\n    color: ", ";\n    font-size: 12px;\n    border: 2px solid ", ";\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    border-radius: 21px;\n  }\n\n  @media (min-width: 1440px) {\n    width: 180px;\n    height: 42px;\n    font-size: 16px;\n    padding: 0 15px 0 20px;\n  }\n\n  span {\n    display: none;\n\n    @media (min-width: 768px) {\n      display: block;\n    }\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledIconWrapper = exports.StyledIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledIconWrapper",
  componentId: "sc-13y8zg-5"
})(["\n  display: none;\n\n  @media (min-width: 768px) {\n    display: block;\n    width: 12px;\n  }\n\n  @media (min-width: 1440px) {\n    width: 20px;\n  }\n"]);