"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledText = exports.StyledImage = exports.StyledContestPrizeBlock = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledContestPrizeBlock = exports.StyledContestPrizeBlock = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledContestPrizeBlock",
  componentId: "sc-3fpphf-0"
})(["\n  width: 100%;\n  display: flex;\n  -webkit-font-smoothing: antialiased;\n  position: relative;\n  flex-direction: column-reverse;\n  margin-bottom: 120px;\n  padding: 0;\n\n  > canvas {\n    z-index: -1;\n    position: absolute;\n    left: 50%;\n    bottom: -90px;\n    transform: translateX(-50%);\n\n    @media (min-width: 768px) {\n      bottom: initial;\n    }\n  }\n\n  .ie11 & {\n    > canvas {\n      @media (min-width: 768px) {\n        bottom: -25%;\n        transform: translate(-50%, -25%);\n      }\n    }\n  }\n\n  @media (min-width: 768px) {\n    flex-direction: row;\n    align-items: center;\n    padding: 20px 0;\n    margin-bottom: 0;\n  }\n\n  @media (min-width: 1440px) {\n  }\n"]);
var StyledText = exports.StyledText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledText",
  componentId: "sc-3fpphf-1"
})(["\n  text-align: right;\n  padding: 20px;\n\n  p {\n    margin: 5px 0 !important;\n    font-size: 16px !important;\n    font-family: ", "!important;\n    color: ", "!important;\n\n    @media (min-width: 768px) {\n      font-size: 18px !important;\n    }\n  }\n\n  strong {\n    font-family: ", ";\n  }\n\n  @media (min-width: 768px) {\n    font-size: 22px;\n    flex-basis: 62%;\n    line-height: 1.5;\n  }\n\n  @media (min-width: 1440px) {\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.font.medium;
});
var StyledImage = exports.StyledImage = _styledComponents.default.img.withConfig({
  displayName: "styles__StyledImage",
  componentId: "sc-3fpphf-2"
})(["\n  display: block;\n  width: auto;\n  height: 260px;\n  align-self: center;\n  margin: 0 auto 15px;\n\n  @media (min-width: 768px) {\n    display: block;\n    width: 38%;\n    height: auto;\n    align-self: center;\n    margin: 0;\n  }\n\n  @media (min-width: 1440px) {\n  }\n"]);