"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTextLg = exports.StyledText = exports.StyledSelectWrapper = exports.StyledSelectArrowContainer = exports.StyledSelectArrow = exports.StyledSelect = exports.StyledProgressPercent = exports.StyledProgress = exports.StyledOptionsWrapper = exports.StyledOption = exports.StyledModalContent = exports.StyledModalBody = exports.StyledModal = exports.StyledLogo = exports.StyledLabel = exports.StyledInputWrapper = exports.StyledInfo = exports.StyledHeader = exports.StyledError = exports.StyledButtonIcon = exports.StyledButton = exports.LayoutItem = exports.LayoutContainer = exports.FlexCenter = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var FlexCenter = exports.FlexCenter = _styledComponents.default.div.withConfig({
  displayName: "styles__FlexCenter",
  componentId: "sc-na6x2t-0"
})(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  align-content: center;\n"]);
var LayoutContainer = exports.LayoutContainer = (0, _styledComponents.default)(FlexCenter).withConfig({
  displayName: "styles__LayoutContainer",
  componentId: "sc-na6x2t-1"
})(["\n  font-family: ", ";\n  color: ", ";\n  width: 100%;\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var LayoutItem = exports.LayoutItem = _styledComponents.default.div.withConfig({
  displayName: "styles__LayoutItem",
  componentId: "sc-na6x2t-2"
})(["\n  position: relative;\n  background-color: ", ";\n  border-radius: 24px;\n  border-style: solid;\n  border-width: 2px;\n  border-image-source: linear-gradient(40deg, #6ec18a 17%, #0e89ca 157%);\n  border-image-slice: 0;\n  background-image: linear-gradient(to bottom, #fff, #fff),\n    linear-gradient(40deg, #6ec18a 17%, #0e89ca 157%);\n  background-origin: border-box;\n  background-clip: content-box, border-box;\n  cursor: pointer;\n  transition: all 0.2s;\n\n  &:hover {\n    ", " {\n      background-image: linear-gradient(40deg, #6ec18a 17%, #0e89ca 157%),\n        linear-gradient(40deg, #6ec18a 17%, #0e89ca 157%);\n      color: ", ";\n      box-shadow: 0 0 10px 0 rgba(21, 61, 35, 0.4);\n    }\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.color.white;
});
var StyledHeader = exports.StyledHeader = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledHeader",
  componentId: "sc-na6x2t-3"
})(["\n  position: relative;\n  border-bottom: 1px solid ", ";\n\n  > .headerInner {\n    height: 78px;\n    display: flex;\n    justify-content: space-around;\n    align-content: center;\n    align-items: center;\n\n    ", " {\n      button {\n        text-indent: -1000px;\n      }\n    }\n  }\n"], function (props) {
  return props.theme.color.gray;
}, function (props) {
  return props.theme.mediaQueries.phone;
});
var StyledLogo = exports.StyledLogo = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledLogo",
  componentId: "sc-na6x2t-4"
})(["\n  > a {\n    position: static;\n    width: 110px;\n    height: 48px;\n  }\n"]);
var StyledProgress = exports.StyledProgress = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledProgress",
  componentId: "sc-na6x2t-5"
})(["\n  position: absolute;\n  left: 0;\n  bottom: -3px;\n  height: 6px;\n  width: ", ";\n  border-radius: 0 6px 6px 0;\n  transition: width 1s linear 0s;\n  background: linear-gradient(269deg, #f49593, #f7bf28);\n"], function (props) {
  return props.progress;
});
var StyledText = exports.StyledText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledText",
  componentId: "sc-na6x2t-6"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 16px;\n  text-align: ", ";\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.align;
});
var StyledTextLg = exports.StyledTextLg = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTextLg",
  componentId: "sc-na6x2t-7"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 18px;\n  text-align: ", ";\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.align;
});
var StyledProgressPercent = exports.StyledProgressPercent = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledProgressPercent",
  componentId: "sc-na6x2t-8"
})(["\n  position: absolute;\n  right: 0;\n  bottom: 0;\n  transform: translate(50%, 32px);\n  padding: 6px;\n  border-radius: 8px;\n  background: #f4988a;\n  color: ", ";\n  font-family: ", ";\n  font-size: 12px;\n\n  &:before {\n    content: '';\n    position: absolute;\n    top: -4px;\n    left: 50%;\n    transform: translateX(-50%);\n    width: 0;\n    height: 0;\n    border-left: 5px solid transparent;\n    border-right: 5px solid transparent;\n    border-bottom: 5px solid #f4988a;\n  }\n"], function (props) {
  return props.theme.color.white;
}, function (props) {
  return props.theme.font.medium;
});
var StyledInputWrapper = exports.StyledInputWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledInputWrapper",
  componentId: "sc-na6x2t-9"
})(["\n  position: relative;\n\n  > input {\n    width: 100%;\n    padding: 25px 6px 14px;\n    -webkit-appearance: none;\n    border: none;\n    border-bottom: 1px solid ", ";\n    font-family: ", ";\n    font-size: 16px;\n    transition: all 0.2s linear 0s;\n    outline: none;\n    color: ", ";\n\n    ", " {\n      font-size: 18px;\n    }\n\n    &::placeholder {\n      color: ", ";\n      transition: color 0.2s linear 0s;\n    }\n\n    &:focus {\n      border-bottom: 1px solid ", ";\n    }\n\n    ", "\n  }\n"], function (props) {
  return props.theme.color.gray;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.theme.color.gray;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.error && (0, _styledComponents.css)(["\n        border-bottom: 1px solid ", ";\n      "], function (props) {
    return props.theme.color.red;
  });
});
var StyledLabel = exports.StyledLabel = _styledComponents.default.label.withConfig({
  displayName: "styles__StyledLabel",
  componentId: "sc-na6x2t-10"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n\n  ", " {\n    font-size: 16px;\n  }\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledError = exports.StyledError = _styledComponents.default.span.withConfig({
  displayName: "styles__StyledError",
  componentId: "sc-na6x2t-11"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 12px;\n"], function (props) {
  return props.theme.color.red;
}, function (props) {
  return props.theme.font.regular;
});
var StyledInfo = exports.StyledInfo = _styledComponents.default.span.withConfig({
  displayName: "styles__StyledInfo",
  componentId: "sc-na6x2t-12"
})(["\n  float: right;\n  margin-top: 4px;\n  font-family: ", ";\n  font-size: 14px;\n  color: ", ";\n  text-decoration: underline;\n  cursor: pointer;\n\n  ", " {\n    margin: 0;\n    position: absolute;\n    top: 46px;\n    right: 0;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.magenta;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledSelectWrapper = exports.StyledSelectWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSelectWrapper",
  componentId: "sc-na6x2t-13"
})(["\n  width: 100%;\n  position: relative;\n"]);
var StyledSelect = exports.StyledSelect = _styledComponents.default.input.withConfig({
  displayName: "styles__StyledSelect",
  componentId: "sc-na6x2t-14"
})(["\n  width: 100%;\n  padding: 25px 6px 14px;\n  -webkit-appearance: none;\n  border: none;\n  border-bottom: 1px solid ", ";\n  font-family: ", ";\n  font-size: 18px;\n  transition: all 0.2s linear 0s;\n  outline: none;\n"], function (props) {
  return props.theme.color.gray;
}, function (props) {
  return props.theme.font.regular;
});
var StyledSelectArrow = exports.StyledSelectArrow = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSelectArrow",
  componentId: "sc-na6x2t-15"
})(["\n  width: 7px;\n  height: 7px;\n  transform: rotate(-45deg);\n  border-left: solid 2px #3f5063;\n  border-bottom: solid 2px #3f5063;\n\n  &:hover {\n    cursor: pointer;\n  }\n"]);
var StyledSelectArrowContainer = exports.StyledSelectArrowContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledSelectArrowContainer",
  componentId: "sc-na6x2t-16"
})(["\n  position: absolute;\n  right: 5px;\n  bottom: 2px;\n  width: 6px;\n  height: 6px;\n  margin-bottom: 20px;\n  z-index: 8;\n"]);
var StyledButton = exports.StyledButton = _styledComponents.default.button.withConfig({
  displayName: "styles__StyledButton",
  componentId: "sc-na6x2t-17"
})(["\n  position: relative;\n  border: none;\n  background: none;\n  padding: 0;\n  outline: inherit;\n  color: ", ";\n  cursor: pointer;\n  transition: all 0.2s linear;\n  font-family: ", ";\n  font-size: 14px;\n  line-height: 22px;\n  overflow: hidden;\n  outline: none;\n  white-space: nowrap;\n  display: flex;\n  flex-direction: ", ";\n  align-items: center;\n  justify-content: center;\n  align-content: center;\n\n  ", " {\n    font-size: 16px;\n    line-height: 24px;\n  }\n  &:hover {\n    text-decoration: underline;\n  }\n  ", "\n  ", "\n"], function (props) {
  return props.color;
}, function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.invert ? 'row' : 'row-reverse';
}, function (props) {
  return props.theme.mediaQueries.tablet;
}, function (props) {
  return props.type === 'gradient' && (0, _styledComponents.css)(["\n      font-family: ", ";\n      font-size: 16px;\n      line-height: normal;\n      color: ", ";\n      border-radius: 30px;\n      min-width: 270px;\n      padding: 12px 16px;\n      background: ", ";\n      box-shadow: 0px 0px 20px 2px ", ";\n      will-change: box-shadow;\n      ", " {\n        font-size: 20px;\n        padding: 18px 22px;\n      }\n      &:after {\n        content: '';\n        height: 100%;\n        width: 100%;\n        position: absolute;\n        top: 0;\n        left: 0;\n        opacity: 0;\n        will-change: opacity;\n        transition: all 0.2s ease-in 0s;\n      }\n      &:hover {\n        text-decoration: none;\n        @media (min-width: 768px) {\n          box-shadow: 0px 0px 20px 8px ", ";\n        }\n        &:after {\n          background-color: ", ";\n          opacity: 1;\n        }\n      }\n    "], function (props) {
    return props.theme.font.medium;
  }, function (props) {
    return props.theme.color.white;
  }, function (props) {
    return props.theme.color[props.color];
  }, function (props) {
    return props.theme.color.shadowRed;
  }, function (props) {
    return props.theme.mediaQueries.tablet;
  }, function (props) {
    return props.theme.color.shadowRed;
  }, function (props) {
    return props.theme.color.whiteHover;
  });
}, function (props) {
  return props.disabled && (0, _styledComponents.css)(["\n      opacity: 0.5;\n      user-select: none;\n      pointer-events: none;\n    "]);
});
var StyledButtonIcon = exports.StyledButtonIcon = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledButtonIcon",
  componentId: "sc-na6x2t-18"
})(["\n  width: 34px;\n  height: 34px;\n"]);
var StyledOptionsWrapper = exports.StyledOptionsWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledOptionsWrapper",
  componentId: "sc-na6x2t-19"
})(["\n  position: absolute;\n  display: ", ";\n  top: 100%;\n  margin-top: 2px;\n  max-height: 200px;\n  min-width: 100%;\n  overflow-y: scroll;\n  border-right: 1px solid ", ";\n  border-bottom: 1px solid ", ";\n  border-left: 1px solid ", ";\n  border-radius: 0 0 10px 10px;\n  box-shadow: 0 4px 20px 0 rgba(81, 129, 186, 0.15);\n  background-color: white;\n  z-index: 100;\n"], function (props) {
  return props.isVisible ? 'block' : 'none';
}, function (props) {
  return props.theme.color.gray;
}, function (props) {
  return props.theme.color.gray;
}, function (props) {
  return props.theme.color.gray;
});
var StyledOption = exports.StyledOption = _styledComponents.default.button.withConfig({
  displayName: "styles__StyledOption",
  componentId: "sc-na6x2t-20"
})(["\n  border: 0;\n  padding: 10px 10px;\n  margin: 0;\n  display: block;\n  cursor: pointer;\n  width: 100%;\n  text-align: left;\n  transition: background-color 0.25s ease-in-out;\n  background-color: white;\n  &:hover {\n    background-color: ", ";\n  }\n"], function (props) {
  return props.theme.color.gray;
});
var StyledModal = exports.StyledModal = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledModal",
  componentId: "sc-na6x2t-21"
})(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  align-content: center;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  background: rgb(0 0 0 / 80%);\n  z-index: 999;\n"]);
var StyledModalBody = exports.StyledModalBody = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledModalBody",
  componentId: "sc-na6x2t-22"
})(["\n  width: 570px;\n  height: 395px;\n  background: white;\n  border-radius: 24px;\n  text-align: center;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  align-content: center;\n  flex-direction: column;\n"]);
var StyledModalContent = exports.StyledModalContent = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledModalContent",
  componentId: "sc-na6x2t-23"
})(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 24px;\n  text-align: ", ";\n"], function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.bold;
}, function (props) {
  return props.align;
});