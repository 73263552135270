import React, { useState, useEffect } from 'react'
import { compose } from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'
import axios from 'axios'
import { makeOpeningDays } from '../lib/utils'

import { SalesPointList, withWrapper, withPadder } from '@alkemy/components'

const makeRegions = data => {
  let arr = []

  data.map(item => {
    const region = item.post_meta.region[0]
    const found = arr.find(x => x.region === region)

    if (!found) {
      arr.push({
        region: region,
      })
    }
  })

  return arr
}

const makeStores = (regions, data) => {
  let arr = []

  regions.map(({ region }) => {
    let stores = []

    data.map(item => {
      const theRegion = item.post_meta.region[0]

      if (region === theRegion) {
        const city = item.title.rendered
        const address = item.acf.location.address
        const openingDays = makeOpeningDays(item.acf.working_day_time)
        const slug = item.slug
        const serviceInfo = item.acf.alert_text !== ''

        stores.push({
          city,
          address,
          openingDays,
          slug,
          serviceInfo,
        })
      }
    })

    arr.push({
      region,
      stores,
    })
  })

  return arr
}

const makeList = data => {
  let regions = makeRegions(data)
  let list = makeStores(regions, data)

  return list
}

const BuildedSalesPointList = ({ serviceInfo }) => {
  const [data, setData] = useState({})

  useEffect(() => {
    async function fetchData() {
      await axios(`${process.env.WP_API}/wp/v2/shop?per_page=100`).then(res => {
        const list = makeList(res.data)
        setData(list)
      })
    }
    fetchData()
  }, [])

  return (
    <SalesPointList
      serviceInfo={serviceInfo}
      appDomain={process.env.APP_DOMAIN}
      list={data}
    />
  )
}

export default compose(
  withPageBuilder(),
  withFields([
    {
      prop: 'serviceInfo',
      label: 'Informazioni di Servizio',
    },
  ]),
  withPadder,
  withWrapper,
)(BuildedSalesPointList)
