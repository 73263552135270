"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledDataLayer = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledDataLayer = exports.StyledDataLayer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledDataLayer",
  componentId: "sc-5k4hub-0"
})(["\n  border: 1px solid #ddd;\n  padding: 20px;\n  font-family: ", ";\n"], function (props) {
  return props.theme.font.regular;
});