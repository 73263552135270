"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Wrapper = exports.Container = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var Container = exports.Container = _styledComponents.default.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-bk033u-0"
})(["\n  background-color: #212121;\n  color: white;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  width: 100vw;\n"]);
var Wrapper = exports.Wrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-bk033u-1"
})(["\n  border: 1rem solid white;\n\n  font-size: 10em;\n  width: 15rem;\n  height: 15rem;\n\n  text-align: center;\n  text-transform: uppercase;\n  font-family: sans-serif;\n  font-weight: bold;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"]);