import React from 'react'
import {compose} from 'recompose'
import Head from 'next/head'

import withHeader from '../lib/withHeader'
import withFooter from '../lib/withFooter'
import withLoginModal from '../lib/withLoginModal'
import withTracking from '../lib/withTracking'
import withMeasureProvider from '../lib/withMeasureProvider'
import withStickyProvider from '../lib/withStickyProvider'
import withGlobalStyleExceptions from '../lib/withGlobalStyleExceptions'

import {NotFound} from '@alkemy/components'

const Error = props => (
  <React.Fragment>
    <Head>
      <title>404 - Pagina non trovata - VIVIgas</title>
      <meta name="title" content="404 - Pagina non trovata - VIVIgas" />
    </Head>
    <NotFound
      text={[{value: 'OPS!'}, {value: 'Pagina non trovata!'}]}
      cta={{
        label: 'Torna alla Home',
      }}
    />
  </React.Fragment>
)

export default compose(
  withGlobalStyleExceptions({normalScroll: true}),
  withMeasureProvider,
  withStickyProvider,
  withTracking,
  withLoginModal,
  withFooter,
  withHeader,
)(Error)
