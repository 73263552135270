import {compose} from 'recompose'
import withPageBuilder from '@alkemy/pagebuilder/lib/hoc/withPageBuilder'
import withFields from '@alkemy/pagebuilder/lib/hoc/withFields'

import {withWrapper, withPadder, BillGuidelines} from '@alkemy/components'

function buildDetailsFields(data = []) {
  return data.map(({prop, title}, index) => ({
    prop,
    type: 'group',
    label: title,
    model: [
      {
        prop: 'title',
        label: 'Titolo',
      },
      {
        prop: 'text',
        label: 'Descrizione',
        type: 'WYSIWYG',
      },
    ],
  }))
}

export default compose(
  withPageBuilder(),
  withFields([
    {
      prop: 'paddingTop',
      label: 'Distanza dal Top',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    {
      prop: 'paddingBottom',
      label: 'Distanza dal Bottom',
      type: 'multiple',
      options: ['40px:Small', '80px:Medium', '150px:Large', '0:Nessuno'],
    },
    ...buildDetailsFields([
      {prop: 'selfReading', title: 'Autolettura'},
      {prop: 'billInfo', title: 'Caratteristiche fattura e offerta'},
      {prop: 'paymentMethod', title: 'Modalità di pagamento'},
      {prop: 'expenseSummary', title: 'Riepilogo della spesa'},
      {prop: 'contacts', title: 'Canali di comunicazione'},
      {prop: 'identityBar', title: 'Barra identificativa'},
      {prop: 'consumptionDetails', title: 'Dettaglio consumi'},
      {prop: 'supplyProperties', title: 'Caratteristiche fornitura'},
      {prop: 'otherCharges', title: 'Dettaglio altre partite'},
      {prop: 'penalty', title: 'Penalità per ritardo pagamento'},
      {prop: 'recalculations', title: 'Ricalcoli'},
    ]),
  ]),
  withWrapper,
  withPadder,
)(BillGuidelines)
