"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitleStep = exports.StyledHeadingStep = exports.StyledGrid = exports.StyledBanner = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _styles = require("../../styles");
var StyledGrid = exports.StyledGrid = (0, _styledComponents.default)(_styles.FlexCenter).withConfig({
  displayName: "styles__StyledGrid",
  componentId: "sc-wlkstz-0"
})(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  min-height: 100vh;\n  padding: 60px 20px;\n  max-width: 1200px;\n  margin-left: auto;\n  margin-right: auto;\n\n  * {\n    box-sizing: border-box;\n  }\n\n  ", " {\n    display: block;\n    min-height: calc(100vh - 160px);\n    padding: 160px 20px 0 20px;\n  }\n"], function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledTitleStep = exports.StyledTitleStep = _styledComponents.default.h2.withConfig({
  displayName: "styles__StyledTitleStep",
  componentId: "sc-wlkstz-1"
})(["\n  font-family: ", ";\n  font-size: 18px;\n  color: ", ";\n  text-align: center;\n\n  ", " {\n    font-size: 30px;\n    line-height: 1.33;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledHeadingStep = exports.StyledHeadingStep = _styledComponents.default.h1.withConfig({
  displayName: "styles__StyledHeadingStep",
  componentId: "sc-wlkstz-2"
})(["\n  font-family: ", ";\n  font-size: 25px;\n  color: ", ";\n  text-align: left;\n  width: 100%;\n  margin: 0 0 16px 0;\n\n  ", " {\n    font-size: 56px;\n    line-height: 1.33;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.mediaQueries.tablet;
});
var StyledBanner = exports.StyledBanner = _styledComponents.default.img.withConfig({
  displayName: "styles__StyledBanner",
  componentId: "sc-wlkstz-3"
})(["\n  max-width: 960px;\n  width: 100%;\n  height: auto;\n  padding: 20px 0;\n"]);