"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTitle = exports.StyledTextReg = exports.StyledListWrapper = exports.StyledListWithRoundImgNoPad = exports.StyledListWithRoundImg = exports.StyledImg = exports.StyledBoldText = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var StyledListWithRoundImg = exports.StyledListWithRoundImg = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledListWithRoundImg",
  componentId: "sc-1h7jrm3-0"
})(["\n  max-width: 300px;\n  margin: 50px auto;\n  padding-top: 160px;\n  position: relative;\n  -webkit-font-smoothing: antialiased;\n\n  @media (min-width: 768px) {\n    max-width: 486px;\n    margin: 100px auto;\n  }\n\n  @media (min-width: 1440px) {\n    max-width: 770px;\n    margin: 70px auto;\n  }\n"]);
var StyledListWithRoundImgNoPad = exports.StyledListWithRoundImgNoPad = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledListWithRoundImgNoPad",
  componentId: "sc-1h7jrm3-1"
})(["\n  max-width: 300px;\n  margin: -70px auto;\n  padding-top: 0px;\n  position: relative;\n  -webkit-font-smoothing: antialiased;\n\n  @media (min-width: 768px) {\n    max-width: 486px;\n    margin: 100px auto;\n  }\n\n  @media (min-width: 1440px) {\n    max-width: 770px;\n    margin: 70px auto;\n  }\n"]);
var StyledImg = exports.StyledImg = _styledComponents.default.img.withConfig({
  displayName: "styles__StyledImg",
  componentId: "sc-1h7jrm3-2"
})(["\n  position: absolute;\n  left: 50%;\n  top: 0;\n  transform: translateX(-50%);\n"]);
var StyledTitle = exports.StyledTitle = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTitle",
  componentId: "sc-1h7jrm3-3"
})(["\n  margin-bottom: 25px;\n  font-family: ", ";\n  color: ", ";\n  font-size: 22px;\n  text-align: center;\n\n  @media (min-width: 1440px) {\n    margin-bottom: 60px;\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.orange;
});
var StyledTextReg = exports.StyledTextReg = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledTextReg",
  componentId: "sc-1h7jrm3-4"
})(["\n  margin-bottom: 10px;\n  font-family: ", ";\n  color: ", ";\n  font-size: 18px;\n  text-align: left;\n\n  @media (min-width: 1440px) {\n  }\n"], function (props) {
  return props.theme.font.medium;
}, function (props) {
  return props.theme.color.darkBlue;
});
var StyledListWrapper = exports.StyledListWrapper = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledListWrapper",
  componentId: "sc-1h7jrm3-5"
})(["\n  margin-bottom: 50px;\n\n  @media (min-width: 1440px) {\n    margin-bottom: 30px;\n  }\n"]);
var StyledBoldText = exports.StyledBoldText = _styledComponents.default.div.withConfig({
  displayName: "styles__StyledBoldText",
  componentId: "sc-1h7jrm3-6"
})(["\n  font-family: ", ";\n  font-size: 18px;\n  text-align: center;\n  line-height: 1.5;\n  color: ", ";\n\n  strong {\n    font-family: ", ";\n  }\n\n  @media (min-width: 768px) {\n    padding-left: 52px;\n    text-align: left;\n  }\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.theme.color.darkBlue;
}, function (props) {
  return props.theme.font.bold;
});