"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RoundDiv = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var activeBkg = "\n/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#7e2889+0,1587c8+100 */\nbackground: #7e2889; /* Old browsers */\nbackground: -moz-linear-gradient(left, #7e2889 0%, #1587c8 100%); /* FF3.6-15 */\nbackground: -webkit-linear-gradient(left, #7e2889 0%,#1587c8 100%); /* Chrome10-25,Safari5.1-6 */\nbackground: linear-gradient(to right, #7e2889 0%,#1587c8 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */\nfilter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7e2889', endColorstr='#1587c8',GradientType=1 ); /* IE6-9 */\ncolor:#fff\n";
var RoundDiv = exports.RoundDiv = _styledComponents.default.div.withConfig({
  displayName: "styles__RoundDiv",
  componentId: "sc-1mjob5n-0"
})(["\n  padding: 5px 60px;\n  background-color: #fff;\n  margin-bottom: 31px;\n  border-radius: 47px;\n  cursor: pointer;\n  box-shadow: 0px 0px 20px #dbe3ef;\n  color: #3f5063;\n  font-family: ", ";\n  display: inline-block;\n  margin-right: 19px;\n  font-size: 0.9em;\n  ", "\n"], function (props) {
  return props.theme.font.regular;
}, function (props) {
  return props.active && activeBkg;
});